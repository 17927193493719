import React from 'react';
import { RadialChart, DiscreteColorLegend } from 'react-vis';

class IsnNullAndOverride extends React.Component {
    render(){
        const { data } = this.props;
        console.log(data);
        const nulls = data.filter(pax => !pax.lastIsnResultWithHeader && (
            pax.authorization && pax.authorization.brdRestriction_Isn && pax.authorization.brdRestriction_Isn.status !== "OVERRIDDEN"
        ));
        const overs = data.filter(pax => pax.authorization && pax.authorization.brdRestriction_Isn && pax.authorization.brdRestriction_Isn.status === "OVERRIDDEN")
        if (!nulls.length && !overs.length){
            return (
                <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 style={{textAlign: 'center'}}>No Data to Generate Report</h1>
                </div>
            )
        }
        console.log('nulls', nulls)
        console.log('overs', overs)
        const nullColor = 'rgb(255, 128, 128)';
        const overColor = 'rgb(128, 255, 128)';
        const chartData = [
            {
                angle: nulls.length,
                label: `${nulls.length} No ISN Result`,
                style: {
                    fill: nullColor,
                    stroke: nullColor
                }
            },
            {
                angle: overs.length,
                label: `${overs.length} Override`,
                style: {
                    fill: overColor,
                    stroke: overColor
                }
            }
        ];
        return (
            <React.Fragment>
                <RadialChart
                data={chartData}
                width={300}
                height={300}
                showLabels
                labelsRadiusMultiplier={0.8}
                labelsStyle={{
                    fontSize: '1.1rem'
                }}
                />
                <DiscreteColorLegend 
                colors={[
                    nullColor,
                    overColor
                ]}
                items={[
                    `${nulls.length} No ISN Result`,
                    `${overs.length} Override`
                ]}
                orientation="horizontal" />
            </React.Fragment>
        )
    }
}

export default IsnNullAndOverride