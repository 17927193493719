
const fieldNames = {
    dateRange: ['dateRange', 'Date Range'],
    customers: ['customers', 'Customers'],
    customer: ['customer', 'Customer'],
    name: ['name', 'Name'],
    firstName: ['firstName', 'First Name'],
    lastName: ['lastName', 'Last Name'],
    desig: ['desig', 'Flight Designation'],
    employers: ['employers', 'Employers'],
    departures: ['departures', 'Departures'],
    destinations: ['destinations', 'Destinations'],
    status: ['status', 'Status'],
    transporters: ['transporters', 'Transporters'],
    transitType: ['transitType', 'Transit Type']
}

export default fieldNames;

export const getFieldKey = (key) => {
    return fieldNames[key] && fieldNames[key][0];
}

export const getFieldLabel = (key) => {
    return fieldNames[key] && fieldNames[key][1];
}