import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import Credential from './Credential';
import CredentialQuery from './CredentialQuery';
import SaveCredentialMutation from './SaveCredentialMutation';

export default compose(
    graphql(
        CredentialQuery,
        {
            options: props => ({
                variables: {
                    filter: {
                        credentialIds: [props.id]
                    },
                    withPerson: Boolean(props.withPerson)
                },
                fetchPolicy: 'cache-first',
                skip: props => !props.id
            }),
            props: props => {
                var key, type, expiration, person, credLen = 0, cred;
                const { data, ...restProps } = props;
                try{
                    credLen = props.data.credentials.docs.length
                }
                catch(err){}
                if (credLen){
                    cred = props.data.credentials.docs[0]
                }
                if (cred){
                    key = cred.key;
                    type = cred.type;
                    person = cred.personID;
                    expiration = cred.expiration;
                }
                return {
                    ...restProps,
                    loading: data && data.loading,
                    error: data && data.error,
                    credKey: key,
                    type,
                    expiration,
                    person
                }
            }
        }
    ),
    graphql(
        SaveCredentialMutation,
        {
            name: 'save'
        }
    )
)(Credential)