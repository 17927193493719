import {Button, Drawer, Tooltip} from 'antd';
import 'css/Calendar.less';
import 'css/Drawer.css';
import moment from 'moment';
import React, {CSSProperties, HTMLAttributes, useState} from 'react';
import SchedulingCalendar from '../calendar';
import '../calendar/ScheduleCalendar.css';
import './style.less';
import { ScheduledGroup } from '../interface';
import ScheduledGroupsTable from './table';
import pluralize from 'pluralize';

export interface ScheduledGroupsCalendarProps {
    onGroupClicked?(group: { date: string, name: string, lkc: string, departureID: string, destinationID: string }): void,
    value?: moment.Moment,
    data?: Array<any>
}

const BASE_CLS = 'mc-scheduled-groups-calendar';

function getCalendarEventClassName(paxCount: number, paxOnFlightCount: number){
    const base = BASE_CLS + '-pill';
    if (paxOnFlightCount <= 0){
        return `${base} ${base + '-no-pax'}`;
    }
    if (paxCount <= paxOnFlightCount){
        return `${base} ${base + '-all-pax'}`;
    }
    return `${base} ${base + '-some-pax'}`;
}

function getCalendarEventTableRowClassName(paxCount: number, paxOnFlightCount: number){
    const base = BASE_CLS + '-tooltip-tr';
    if (paxOnFlightCount <= 0){
        return `${base} ${base + '-no-pax'}`;
    }
    if (paxCount <= paxOnFlightCount){
        return `${base} ${base + '-all-pax'}`;
    }
    return `${base} ${base + '-some-pax'}`;
}

const ScheduledGroupsCalendar: React.FC<ScheduledGroupsCalendarProps> = (props) => {
    const [ expandedDay, setExpandedDay ] = useState<{ date: moment.Moment, data: Array<any> }>(null);

    function transformGroup(group: ScheduledGroup){
        return {
            date: group.scheduledFlightDate,
            name: group.scheduledGroup,
            lkc: group.lastKnownController && group.lastKnownController._id,
            departureID: group.departureID && group.departureID._id,
            destinationID: group.destinationID && group.destinationID._id
        }
    }

    const thProps: HTMLAttributes<HTMLTableCellElement> = {
        style: {
            paddingRight: '6px'
        }
    }

    return <>
    <SchedulingCalendar<ScheduledGroup>
        data={props.data}
        value={props.value}
        getDataDate={(dataItem) => dataItem.scheduledFlightDate}
        onCellClick={(date, data) => setExpandedDay({ date, data })}
        renderDateCell={(data) => {
            return {
                title: `${data.length} ${pluralize('schedule', data.length)}`,
                content: <ul className="scheduling-events">
                    {data.map((group, idx) => {
                        let tooltipContent = <>
                            <p>Click to view schedule</p>
                            <table className="ant-table mc-table">
                                <tbody>
                                <tr>
                                    <th {...thProps}>Schedule Name</th>
                                    <td>{group.scheduledGroup}</td>
                                </tr>
                                <tr>
                                    <th {...thProps}>Last Known Controller</th>
                                    <td>{group.lastKnownController && group.lastKnownController.name}</td>
                                </tr>
                                {group?.departureID?.name ? (
                                    <tr>
                                        <th {...thProps}>Departure</th>
                                        <td>{group && group.destinationID.name}</td>
                                    </tr>
                                ) : null}
                                {group?.destinationID?.name ? (
                                    <tr>
                                        <th {...thProps}>Destination</th>
                                        <td>{group && group.destinationID.name}</td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <th {...thProps}>Outbound Pax</th>
                                    <td>{group.paxOutbound}</td>
                                </tr>
                                <tr>
                                    <th {...thProps}>Transfer Pax</th>
                                    <td>{group.paxTransfer}</td>
                                </tr>
                                <tr>
                                    <th {...thProps}>Inbound Pax</th>
                                    <td>{group.paxInbound}</td>
                                </tr>
                                <tr>
                                    <th {...thProps}>Outbound Cgo</th>
                                    <td>{group.cgoOutbound}</td>
                                </tr>
                                <tr>
                                    <th {...thProps}>Transfer Cgo</th>
                                    <td>{group.cgoTransfer}</td>
                                </tr>
                                <tr>
                                    <th {...thProps}>Inbound Cgo</th>
                                    <td>{group.cgoInbound}</td>
                                </tr>
                                <tr className={getCalendarEventTableRowClassName(group.paxOutbound, group.paxOutboundOnFlight)}>
                                    <th {...thProps}>Outbound pax on flight</th>
                                    <td>{group.paxOutboundOnFlight}</td>
                                </tr>
                                <tr className={getCalendarEventTableRowClassName(group.paxTransfer, group.paxTransferOnFlight)}>
                                    <th {...thProps}>Transfer pax on flight</th>
                                    <td>{group.paxTransferOnFlight}</td>
                                </tr>
                                <tr className={getCalendarEventTableRowClassName(group.paxInbound, group.paxInboundOnFlight)}>
                                    <th {...thProps}>Inbound pax on flight</th>
                                    <td>{group.paxInboundOnFlight}</td>
                                </tr>
                                <tr className={getCalendarEventTableRowClassName(group.cgoOutbound, group.cgoOutboundOnFlight)}>
                                    <th {...thProps}>Outbound cgo on flight</th>
                                    <td>{group.cgoOutboundOnFlight}</td>
                                </tr>
                                <tr className={getCalendarEventTableRowClassName(group.cgoTransfer, group.cgoTransferOnFlight)}>
                                    <th {...thProps}>Transfer cgo on flight</th>
                                    <td>{group.cgoTransferOnFlight}</td>
                                </tr>
                                <tr className={getCalendarEventTableRowClassName(group.cgoInbound, group.cgoInboundOnFlight)}>
                                    <th {...thProps}>Inbound cgo on flight</th>
                                    <td>{group.cgoInboundOnFlight}</td>
                                </tr>
                                </tbody>
                            </table>
                        </>

                        return <li key={idx}>
                            <Tooltip title={tooltipContent} placement="right"
                                     overlayStyle={{width: 'fit-content', maxWidth: '600px'}}>
                            <Button
                                    type="primary"
                                    size="small"
                                    block
                                    style={{ textAlign: 'left', paddingRight: 2 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (props.onGroupClicked)
                                            props.onGroupClicked(transformGroup(group));
                                    }}
                                    className={BASE_CLS + '-event'}
                                >
                                    <span className={BASE_CLS + '-event-left'}>{group.scheduledGroup}</span>
                                <span className={BASE_CLS + '-event-right'}>
                                        <span title="Outbound" style={{marginRight: 4}}
                                              className={getCalendarEventClassName(group.paxOutbound + group.cgoOutbound, group.paxOutboundOnFlight)}>
                                            <strong>{group.paxOutbound + group.cgoOutbound}</strong> OB
                                        </span>
                                        <span title="Transfer" style={{marginRight: 4}}
                                              className={getCalendarEventClassName(group.paxTransfer + group.cgoTransfer, group.paxTransferOnFlight + group.cgoTransferOnFlight)}>
                                            <strong>{group.paxTransfer + group.cgoTransfer}</strong> TR
                                        </span>
                                        <span
                                            title="Inbound"
                                            className={getCalendarEventClassName(group.paxInbound + group.cgoInbound, group.paxInboundOnFlight + group.cgoInboundOnFlight)}>
                                            <strong>{group.paxInbound + group.cgoInbound}</strong> IB
                                        </span>
                                    </span>
                            </Button>
                            </Tooltip>
                        </li>
                    })}
                </ul>
            }
        }}
    />
        <Drawer
            className="ant-drawer-flex ant-drawer-body-no-padding"
            title={expandedDay && expandedDay.date.format('MMMM DD, YYYY')}
            onClose={() => setExpandedDay(null)}
            visible={expandedDay ? true : false}
            width="1000px"
        >
            {expandedDay && expandedDay.data.length ? (
                <ScheduledGroupsTable
                    data={expandedDay.data}
                    onViewGroup={(group) => props.onGroupClicked && props.onGroupClicked(transformGroup(group))}
                />
        ) : null}
    </Drawer>
    </>
}

export default ScheduledGroupsCalendar