import { Divider, Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import React, { useState } from 'react';
import '../../../css/Tabs.css';
import useControlledState from '../../../hooks/useControlledState';
import CgoSearch from '../CgoSearch';
import { initialSearchState as cgoInitialSearchState, SearchState as CgoSearchState } from '../CgoSearch/index';
import '../combinedSearch.css';
import PaxSearch from '../PaxSearch';
import { initialSearchState as paxInitialSearchState, SearchState as PaxSearchState } from '../PaxSearch/index';

export interface SearchOptions {
    filter?: any,
    excludeCarrier?: string
}

export interface PaxCgoSearchProps extends React.HTMLAttributes<HTMLDivElement> {
    forceRefresh?: boolean,
    onForceRefresh?(fr: boolean): void,
    paxSearchOptions?: SearchOptions,
    cgoSearchOptions?: SearchOptions,
    onEntitySelect?(ss: SelectState): void,
    selectedEntities?: SelectState,
    searchState?: SearchState,
    onSearchStateChange?(ss: SearchState): void,
    tabsProps?: TabsProps,
    currentCarrier?: string
}

export interface SelectState {
    pax: string[],
    cgo: string[]
}

export interface SearchState {
    pax: PaxSearchState,
    cgo: CgoSearchState
}

export type TabState = 'pax' | 'cgo'

export let initialSelectState: SelectState = {
    pax: [],
    cgo: []
}

export let initialSearchState: SearchState = {
    pax: paxInitialSearchState,
    cgo: cgoInitialSearchState
}

const PaxCgoSearch: React.FC<PaxCgoSearchProps> = (props) => {
    const {
        forceRefresh,
        onForceRefresh,
        tabsProps, 
        selectedEntities, 
        searchState: searchStateProp, 
        onEntitySelect, 
        onSearchStateChange, 
        paxSearchOptions,
        cgoSearchOptions,
        currentCarrier,
        ...restProps 
    } = props;
    const [ tab, setTab ] = useState<TabState | string>('pax');
    const [ selectState, setSelectState ] = useControlledState<SelectState>(initialSelectState, selectedEntities, onEntitySelect);
    const [ searchState, setSearchState ] = useControlledState<SearchState>(initialSearchState, searchStateProp, onSearchStateChange);

    if (tabsProps && tabsProps.tabBarExtraContent){
        var tabBarExtraContent = tabsProps.tabBarExtraContent;
    }
    else
    {
        tabBarExtraContent = <>
            {selectState.pax.length > 0 ? (
                <span>{selectState.pax.length} PAX</span>
            ) : null}
            {selectState.pax.length > 0 && selectState.cgo.length > 0 ? (
                <Divider type="vertical" />
            ) : null}
            {selectState.cgo.length > 0 ? (
                <span>{selectState.cgo.length} CGO</span>
            ) : null}
            {selectState.pax.length > 0 || selectState.cgo.length > 0 ? (
                <span> selected</span>
            ) : null}
        </>
    }

    return <div {...restProps} className="combined-search-wrapper">
        <Tabs type="card" className="combined-search-tabs ant-tabs-flex" {...props.tabsProps} tabBarExtraContent={tabBarExtraContent} activeKey={tab} onChange={setTab}>
            <Tabs.TabPane key="pax" tab="PAX">
                <PaxSearch
                    forceRefresh={forceRefresh}
                    onForceRefresh={onForceRefresh}
                    filterOptions={paxSearchOptions && paxSearchOptions.filter}
                    selectedItems={selectState.pax}
                    onSelection={(s) => setSelectState({ ...selectState, pax: s })}
                    searchState={searchState.pax}
                    onSearchStateChange={(ss) => setSearchState({ ...searchState, pax: ss })}
                    includeCarrier={currentCarrier}
                />
            </Tabs.TabPane>
            <Tabs.TabPane key="cgo" tab="CGO">
                <CgoSearch
                    forceRefresh={forceRefresh}
                    onForceRefresh={onForceRefresh}
                    filterOptions={cgoSearchOptions && cgoSearchOptions.filter}
                    selectedItems={selectState.cgo}
                    onSelection={(s) => setSelectState({ ...selectState, cgo: s })}
                    searchState={searchState.cgo}
                    onSearchStateChange={(ss) => setSearchState({ ...searchState, cgo: ss })}
                    includeCarrier={currentCarrier}
                />
            </Tabs.TabPane>
        </Tabs>
    </div>
}

export default PaxCgoSearch