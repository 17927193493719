import { Icon, Menu } from 'antd';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';
import { useHistory } from "react-router-dom";
import React from 'react';
import './style.less';
import useUserGroups from 'hooks/useUserGroups';

const AdminSubMenu: React.FC<SubMenuProps> = (props) => {
    const history = useHistory();
    const [ groups ] = useUserGroups();
    if (groups.findIndex(g => g.startsWith('dataflyt.superuser')) < 0)
      return null;
    return <Menu.SubMenu className="admin-submenu" title={<span><Icon type="setting" /><span>Admin</span></span>} {...props}>
      <Menu.Item key="/app/admin/user" onClick={() => history.push('/app/admin/user')}>
        User Management
      </Menu.Item>
      <Menu.Item key="/app/admin/fw-billing-report" onClick={() => history.push('/app/admin/fw-billing-report')}>
        FlytWatch Billing Report
      </Menu.Item>
      <Menu.Item key="/app/admin/fw-changes-by-console" onClick={() => history.push('/app/admin/fw-changes-by-console')}>
        FlytWatch Changes By Console Report
      </Menu.Item>
  </Menu.SubMenu>
}

export default AdminSubMenu;