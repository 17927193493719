import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { HTMLAttributes } from 'react'
import './style.less';
import cn from 'classnames';

export interface LocationMarkerProps extends HTMLAttributes<HTMLDivElement> {
    label?: React.ReactNode,
    isStart?: boolean,
    subLabel?: React.ReactNode,
    source?: string
}

export const LocationMarker: React.FC<LocationMarkerProps> = (props) => {
    let title = undefined;
    if (props.source){
        title = 'Coordinate Data Source: ' + String(props.source);
    }
    let iconClassName = cn({
        "mc-location-marker-icon": true,
        "mc-location-marker-icon-start": props.isStart ? true : false,
        "mc-location-marker-icon-end": props.isStart ? false : true,
    })
    return (
        <div className="mc-location-marker" title={title}>
            <FontAwesomeIcon icon={faMapMarkerAlt}  className={iconClassName} />
            <div className='mc-location-marker-label-container'>
                {props.label ? (
                    <div className="mc-location-marker-label">{props.label}</div>
                ) : null}
                {props.subLabel ? (
                    <div className="mc-location-marker-sublabel">{props.subLabel}</div>
                ) : null}
            </div>
        </div>
    )
}

export default LocationMarker;