import gql from 'graphql-tag';

export default gql`
mutation SchedulePassengers(
    $tpID: ID!
    $customerID: ID!
    $departureID: ID!
    $destinationID: ID!
    $scheduledFlightDate: AWSDate!
    $lastKnownController: String
    $scheduledGroup: String!
    $persons: [PersonScheduleInput]
    $inboundCargo: [ScheduledInboundCargoInput]
){
    schedulePassengers(
        tpID: $tpID
        customerID: $customerID
        departureID: $departureID
        destinationID: $destinationID
        scheduledFlightDate: $scheduledFlightDate
        lastKnownController: $lastKnownController
        scheduledGroup: $scheduledGroup
        persons: $persons
        inboundCargo: $inboundCargo
    ){
        _id
        lastName
        firstName
        transitType
        scheduledOrder
        paxWeight
        bagWeight
        bagCount
        chargeCode
        customerID {
            _id
        }
        tpID {
            _id
        }
        employerID {
            _id
            name
        }
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        lastKnownController {
            _id
            name
        }
        authorization {
            onWhiteList
            brdRestriction_Isn {
                status
                type
                notes
                reason
            }
        }
        isnCheckError {
            message
        }
        lastIsnResultWithHeader {
            isn_customer
            isn_ownerid
            success
            error_message
            quick_check_status
            reason
            qc_count
            qc_result {
                isnStatus {
                    shortLabel
                }
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
            selectedResult {
                isnStatus {
                    shortLabel
                }
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
        }
    }
}
`