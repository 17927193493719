import { Cell, Column } from '@blueprintjs/table';
import { Button, Input, List } from 'antd';
import { getPilotName, safeGet } from 'common/util';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import gql from 'graphql-tag';
import DeleteEntity from 'Mutations/DeleteEntity';
import React, { useRef } from 'react';
import { DuplicationChecker, MDDetails, MDHeader, MDLayout, MDTable, TableLoader, useMasterDataState } from '..';
import PilotEntryForm from '../entryForms/pilot';
import { getMDTableProps, MasterDataProps, MDStateToDetailsProps, MDStateToHeaderProps, MDStateToLayoutProps, MDStateToTableScrollerProps, renderNoData, trimString } from '../util';

export interface MDPilotProps extends WithOrgDataProps, MasterDataProps {

}

const QUERY = gql`
query PilotsQuery($filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $limit: Int, $skip: Int) {
    MasterDataPilots(filter: $filter, search: $search, limit: $limit, skip: $skip) {
        docs {
            ... on Pilot {
                _id
                _rev
                name {
                    firstName
                    lastName
                }
                phoneNumber {
                    type
                    number
                }
                email {
                    type
                    address
                }
            }
        }
    }
}
`

const MUTATION = gql`
mutation PilotMutation(
        $_id: ID
        $firstName: String!
        $lastName: String!
        $phoneNumber: PhoneInput
        $email: EmailInput
        $tpID: ID!

    ){
    setPilot(
        _id: $_id
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        email: $email
        tpID: $tpID
    ){
        _id
        name {
            firstName
            lastName
        }
        phoneNumber {
            type
            number
        }
        tpID {
            _id
            name
        }
        email {
            type
            address
        }
    }
}
`

const DELETE_USER_GROUPS = ['flytsuite.master.pilot.delete'];
const EDIT_USER_GROUPS = ['flytsuite.master.pilot.edit'];

const MDPilot: React.FC<MDPilotProps> = (props) => {

    const formRef = useRef(null);
    let tpID = props.orgData.getOrgIDByType('transporter');

    const MDState = useMasterDataState({
        getQueryData: data => data.MasterDataPilots.docs,
        getQueryVariables: (searchValues) => {
            let search: Array<{ key: string, value: string }> = [];
            if (searchValues.lastName){
                search.push({
                    key: 'name.lastName',
                    value: JSON.stringify(searchValues.lastName)
                })
            }
            if (searchValues.firstName){
                search.push({
                    key: 'name.firstName',
                    value: JSON.stringify(searchValues.firstName)
                })
            }
            
            return {
                filter: [
                    {
                        key: 'tpID',
                        value: JSON.stringify(tpID)
                    }
                ],
                search: search.length ? search : undefined
            }
        },
        queryGQL: QUERY,
        saveMutationGQL: MUTATION,
        deleteMutationGQL: DeleteEntity,
        paginationLimit: props.dataPaginationLimit,
        pollInterval: props.pollInterval,
        tpID,
        updateQueryAfterFetchMore: (prev, fetchMoreResult) => ({
            ...prev,
            MasterDataPilots: {
                ...prev.MasterDataPilots,
                docs: [
                    ...prev.MasterDataPilots.docs,
                    ...fetchMoreResult.MasterDataPilots.docs
                ]
            }
        }),
        transformEditFromExisting: (values) => {
            return {
                ...values,
                lastName: safeGet(['name', 'lastName'], values),
                firstName: safeGet(['name', 'firstName'], values)
            }
        }
    })

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                lastName: MDState.getSearchValue('lastName'),
                firstName: MDState.getSearchValue('firstName')
            })
        })}
        headerElement={
            <MDHeader
                {...MDStateToHeaderProps(MDState)}
                inputElement={
                    <Input.Group compact>
                        <Input
                            onChange={(e) => MDState.onSearchValueChange('lastName', e.target.value)}
                            placeholder="By last name"
                            value={MDState.getSearchValue('lastName')}
                            style={{ width: '15rem' }}
                            allowClear
                        />
                        <Input
                            onChange={(e) => MDState.onSearchValueChange('firstName', e.target.value)}
                            placeholder="By first name"
                            value={MDState.getSearchValue('firstName')}
                            style={{ width: '15rem' }}
                            allowClear
                        />
                    </Input.Group>
                }
            />
        }
        tableElement={
            <TableLoader mdState={MDState}>
                <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)}>
                    <MDTable
                        {...getMDTableProps(MDState.data, MDState)}
                        onDelete={(idx) => {
                            let entry = MDState.data[idx];
                            MDState.delete({ _id: entry['_id'] });
                        }}
                        deleteUserGroups={DELETE_USER_GROUPS}
                        deleteLoadingIdx={MDState.deleting ? (MDState.data?.findIndex(pil => pil._id === MDState.deletingId)) || -1 : -1}
                    >
                        <Column name="Last Name" cellRenderer={(idx) => {
                            let record = MDState.data[idx];
                            let lastName = safeGet(['name', 'lastName'], record);
                            return <Cell>{lastName}</Cell>
                        }} />
                        <Column name="First Name" cellRenderer={(idx) => {
                            let record = MDState.data[idx];
                            let firstName = safeGet(['name', 'firstName'], record);
                            return <Cell>{firstName}</Cell>
                        }} />
                        <Column name="Phone Type" cellRenderer={(idx) => {
                            let record = MDState.data[idx];
                            let type = safeGet(['phoneNumber', 'type'], record);
                            return <Cell>{type}</Cell>
                        }} />
                        <Column name="Phone Number" cellRenderer={(idx) => {
                            let record = MDState.data[idx];
                            let number = safeGet(['phoneNumber', 'number'], record);
                            return <Cell>{number}</Cell>
                        }} />
                        <Column name="Email" cellRenderer={(idx) => {
                            let record = MDState.data[idx];
                            let address = safeGet(['email', 'address'], record);
                            return <Cell>{address}</Cell>
                        }} />
                    </MDTable>
                </BlueprintTableInfiniteScroller>
            </TableLoader>
        }
        detailsElement={
            <MDDetails
                {...MDStateToDetailsProps(MDState, (entryValues) => {
                    return `${entryValues.lastName}, ${entryValues.firstName}`
                })}
                onSave={() => {
                    formRef.current.validateFieldsAndScroll((err, values) => {
                        if (!err){
                            let saveData = {
                                lastName: trimString(values.lastName),
                                firstName: trimString(values.firstName),
                                phoneNumber: values.phoneNumber && {
                                    number: values.phoneNumber.number,
                                    type: values.phoneNumber.type
                                },
                                email: values.email && {
                                    address: values.email.address,
                                    type: values.email.type
                                },
                                _id: MDState.getEntryFieldValue('_id')
                            }
                            MDState.save(saveData)
                        }
                    })
                }}
                deleteUserGroups={DELETE_USER_GROUPS}
                editUserGroups={EDIT_USER_GROUPS}
                onDelete={() => {
                    let delData = {
                            _id: MDState.getEntryFieldValue('_id'),
                            tpID: props.orgData.getOrgIDByType('transporter')
                    }
                    MDState.delete(delData)
                }}
            >
                <PilotEntryForm
                    formFields={MDState.entryFields}
                    onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                    uppercaseFields={['lastName', 'firstName']}
                    autoFocus
                    ref={formRef}
                    dupeChecker={MDState.isNewEntry ? (
                        <DuplicationChecker
                            skip={
                                !trimString(MDState.getEntryFieldValue('lastName')) ||
                                !trimString(MDState.getEntryFieldValue('firstName'))
                            }
                            getData={data => safeGet(['MasterDataPilots', 'docs'], data)}
                            query={QUERY}
                            variables={{
                                search: [
                                    MDState.getEntryFieldValue('lastName') ? {
                                        key: 'name.lastName',
                                        value: JSON.stringify(trimString(MDState.getEntryFieldValue('lastName')))
                                    } : null,
                                    MDState.getEntryFieldValue('firstName') ? {
                                        key: 'name.firstName',
                                        value: JSON.stringify(trimString(MDState.getEntryFieldValue('firstName')))
                                    } : null
                                ].filter(item => item),
                                filter: [
                                    {
                                        key: 'tpID',
                                        value: JSON.stringify(tpID)
                                    }
                                ]
                            }}
                            renderItems={(items) => {
                                return <List size="small">
                                {items.map((pilot, idx) => 
                                    <List.Item key={idx}>
                                        <Button
                                            className="mc-link-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                MDState.editFromExisting(pilot, false);
                                            }}
                                        >
                                            {getPilotName(pilot)}
                                        </Button>
                                    </List.Item>)}
                                </List>
                            }}
                        />
                    ) : null}
                />
                <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
            </MDDetails>
        }
    />
}

export default WithOrgData(MDPilot)