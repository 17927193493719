import React from 'react';

export interface WrapCtxProviderOptions {

    /** Wrap the child component with a custom provider component. Uses context.Provider if not specified.  */
    providerComponent: any,

    /** Maps props passed to the wrapper to the provider. Necessary if the provider component needs props. */
    wrapperPropsToProviderProps?: (wrapperProps: any) => any
}

export interface ContextProviderWrapperProps {
    /** If true, the child component will use a provider from higher in the tree instead of creating one. */
    useExternalContextProvider?: boolean,

    /** Use a different implementation of the provider. <strong>IMPORTANT: The provider used to override must return the same context specified in overrideContext (or the default context type that the this component is expecting).</strong> */
    overrideProvider?: any,

    /** Use a different implementation of the context: <strong>IMPORTANT: The context used to override must be the same context returned by the provider.</strong> */
    overrideContext?: React.Context<any>
}

export interface WithContextOverrideProps<CtxType> {
    overrideContext?: React.Context<CtxType>
}

/**
 * Wraps a component with a context provider of your choice.
 * If useExternalContextProvider is set to true on the wrapper's props,
 * it will not wrap the child component with a provider so an external provider can be used instead.
 * @param options providerComponent option is required.
 * @returns Function that wraps the child component. Pass the child component variable into here. NOTE: The child component's interface MUST EXTEND WithContextOverrideProps!
 */
function wrapCtxProvider(options: WrapCtxProviderOptions){
    return (Comp: React.FC<WithContextOverrideProps<any>>) => {
        Comp.displayName = 'ContextProviderWrapper(' + Comp.displayName + ')'

        // TODO: Line below to be React.FC<ContextProviderWrapperProps>, but then you could not pass anything other than ContextProviderWrapperProps attributes
        // This should be fixed so prop names and types are properly enforced
        const ContextProviderWrapper: React.FC<any>

        = ({useExternalContextProvider, overrideContext, overrideProvider, ...childProps}) => {
            if (useExternalContextProvider) return <Comp overrideContext={overrideContext} {...childProps} /> // Child component will use external provider instead
            
            // Create a Provider component to wrap the child component and map the props from the wrapper to the provider
            let Provider = overrideProvider ? overrideProvider : options.providerComponent;
            let cp_props = options.wrapperPropsToProviderProps ? 
                options.wrapperPropsToProviderProps(childProps) : {};
            cp_props.children = <Comp overrideContext={overrideContext} {...childProps} />
            return <Provider {...cp_props} />
        }
        return ContextProviderWrapper
    }
}

export default wrapCtxProvider