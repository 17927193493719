import React from 'react';
import { Input } from "antd";
import { InputProps } from 'antd/lib/input';

export interface InputPhoneNumberProps extends Omit<InputProps, 'onChange'> {
    onChange?: (value: string) => void,
    value?: string
}

export function formatPhoneNumber(number: string){
    let x: RegExpMatchArray;
    x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));
}

const InputPhoneNumber: React.FC<InputPhoneNumberProps> = (props) => {
    var { value, onChange, ...restProps } = props;
    const onInputChange = (e) => {
        value = formatPhoneNumber(e.target.value);
        onChange && onChange(value);
    }
    return <Input
        onChange={onInputChange}
        value={value}
        placeholder="(555) 555-5555"
        {...restProps}
    />
}

export default InputPhoneNumber