import {castDraft, produce} from "immer";

export interface UndoRedoState<T> {
    present: T,
    past: T[],
    future: T[]
}

export type UndoRedoAction<T> = {
    type: 'UPDATE',
    payload: T
} | {
    type: 'UNDO' | 'REDO'
}

interface CreateUndoRedoReducerArgs {

    /**
     * Max number of versions kept in memory
     * @default 100
     */
    maxSteps?: number
}

export function CreateUndoRedoReducer<T>(args?: CreateUndoRedoReducerArgs){
    const maxSteps = args?.maxSteps || 100;

    return (prevState: UndoRedoState<T>, action: UndoRedoAction<T>) => {
        return produce(prevState, (state) => {
            switch (action.type){
                case "UPDATE":
                    state.past.push(state.present);
                    if (state.past.length > maxSteps){
                        state.past = state.past.slice(state.past.length - maxSteps, state.past.length);
                    }
                    state.future = [];
                    state.present = castDraft(action.payload);
                    break
                case "UNDO":
                    const nextPast = state.past.pop();
                    state.future.splice(0, 0, state.present);
                    state.present = nextPast;
                    break;
                case "REDO":
                    const nextFuture = state.future.splice(0, 1);
                    state.past.push(state.present);
                    state.present = nextFuture[0];
                    break
            }

            return state;
        })
    }
}