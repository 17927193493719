import { Alert, Button, Col, Divider, Dropdown, Icon, Layout, Menu, Modal, Row, Switch, Tooltip, Typography } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import AboutApp from 'components/AboutApp';
import { AuthContext } from 'context/auth';
import { GlobalAppStateContext } from 'context/global-app-state';
import { ThemeContext } from 'context/theme';
import React, { HTMLAttributes, useContext, useState } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import MenuItem from '../../node_modules/antd/lib/menu/MenuItem';
import TransporterSelector from '../containers/TransporterSelector';
import { ServiceWorkerConsumer } from '../context/serviceworker';
import '../css/App.css';
import CurrentOrg from './CurrentOrg';
import FeedbackButton from './feedback-button';
import { FeedbackFormModal } from './feedback-form';
import MCIcon from './icon';
import { UserGroup } from './userGroup';
import withUserPreferences from './withUserPreferences';
import {useS3Config} from "../hooks/s3config";

const iconStyle = {
  margin: 'auto 0'
}

const DevStage: React.FC<HTMLAttributes<HTMLSpanElement> & { type?: 'dev' | 'beta' }> = (props) => {

  let style = {
    fontSize: '1.5rem',
    color: 'white',
    borderRadius: '4px',
    padding: '0 0.3rem',
    ...props.style
  }
  if (props.type === 'dev'){
    style.backgroundColor = 'red';
  }
  else if (props.type === 'beta'){
    style.backgroundColor = '#00c100';
  }

  return <span {...props} style={style} />
}

const GlobalNavbar: React.FC<any> = (props) => {
  const { user, signOut } = useContext(AuthContext);
  const { toggleSidebarCollapse, state: { sidebarCollapsed } } = useContext(GlobalAppStateContext);
  const { changeTheme, themeName, loading: themeLoading } = useContext(ThemeContext);
  const [ userMenuVisible, setUserMenuVisible ] = useState(false);
  const [ showAboutModal, setShowAboutModal ] = useState(false);
  const [ showFeedbackModal, setShowFeedbackModal ] = useState(false);

  const S3Config = useS3Config();

  const { staticContext, userprefs, ...rest } = props;

  function handleMenuClick(e: ClickParam){
    switch (e.key) {
      case 'theme':
        changeTheme(themeName !== 'dark' ? 'dark' : 'light');
        break;
      case 'user':
        userprefs.open();
        setUserMenuVisible(false);
        break;
      case 'feedback':
        setShowFeedbackModal(true);
        break;
      case 'about':
        setShowAboutModal(true);
        break;
      case 'logout':
        signOut();
        break;
      default:
        setUserMenuVisible(false);
    }
  }

  function renderDevStage(){
    switch (process.env.REACT_APP_STAGE) {
      case 'qas':
        return <DevStage type="beta">BETA</DevStage>
      case 'dev':
        return <DevStage type="dev">DEV</DevStage>
      case 'prd':
      default:
        return null;
    }
  }

  let devStage = renderDevStage();

    const dropdownOverlay = (
        <Menu style={{ width: '12rem' }} onClick={handleMenuClick}>
            <MenuItem key="user">
              <Row type="flex" justify="space-between">
                <span>Account</span>
                <Icon style={iconStyle} type="user"/>
              </Row>
            </MenuItem>
            <MenuItem key="about">
              <Row type="flex" justify="space-between">
                <span>About Manifest Central</span>
                <Icon style={iconStyle} type="exclamation-circle"/>
              </Row>
            </MenuItem>
            <MenuItem key="feedback" className="mc-show-smaller-than-medium-screen">
              <Row type="flex" justify="space-between">
                <span>Feedback</span>
                <MCIcon type="smile-colored" style={iconStyle} />
              </Row>
            </MenuItem>
            <MenuItem key="theme">
              <Row type="flex" justify="space-between">
                <Col>
                  <span>Dark Theme</span>
                </Col>
                <Col>
                  <Switch
                    loading={themeLoading}
                    checked={themeName === 'dark'}
                  />
                </Col>
              </Row>
            </MenuItem>
            <Menu.Divider />
            <MenuItem key="logout">
              <Row type="flex" justify="space-between">
                <span>Logout</span>
                <Icon style={iconStyle} type="logout"/>
              </Row>
            </MenuItem>
        </Menu>
    )

    let maintenanceAnnouncement = S3Config?.maintenance?.announcement;

    return (
      <>
      <Layout.Header {...rest} className="mc-navbar">
        <Row type="flex" align="middle" style={{ width: '100%', flexWrap: 'nowrap' }}>
            <Col style={{ flexShrink: 0, display: 'flex' }}>
              {!props.hideMenuCollapse ? (
                <Tooltip placement="bottomRight" title={sidebarCollapsed ? 'Expand menu' : 'Collapse Menu'} mouseEnterDelay={1}>
                  <Button
                    className="mc-navbar-menu-button mc-btn-transparent mc-btn-hover-outline"
                    icon="menu"
                    style={{ marginLeft: '0.75rem' }}
                    onClick={() => toggleSidebarCollapse()}
                  />
                </Tooltip>
              ) : null}
              <div className="mc-logo" style={{ height: '100%' }} />
            </Col>
          {devStage ? <Col className="mc-stage" style={{ marginLeft: '0.5rem' }}>{devStage}</Col> : null}
          <div style={{ flex: 1 }} />
          <Col style={{ textAlign: 'center', flex: 1 }}>
            <ServiceWorkerConsumer>
            {(serviceWorker) => {
              if (serviceWorker.updateAvailable){
                return <span style={{ whiteSpace: 'nowrap' }}>
                  <Icon type="download" /> A new update is available! <Button style={{ marginLeft: '1rem' }} type="primary" onClick={() => serviceWorker.update()} >RELOAD</Button>
                </span>
              }
              return null;
            }}
            </ServiceWorkerConsumer>
          </Col>
          <Col style={{ flex: 1 }}>
            <Row type="flex" justify="end" gutter={12} style={{alignItems: 'center', flexWrap: 'nowrap'}}>
              <Col className="mc-show-larger-than-medium-screen">
                <FeedbackButton className="mc-btn-hover-outline" />
              </Col>
              <Divider className="mc-show-larger-than-medium-screen" type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }} />
              <CurrentOrg>
              {({ organization }) => {
                return <React.Fragment>
                  {organization && organization.classType === 'flytsuite.customer' ? (
                    <UserGroup.Flytsuite.Default>
                      <Col>
                        <Tooltip title="Choose which transporter to display data from" placement="bottom" mouseEnterDelay={1}>
                          <TransporterSelector className="mc-select" />
                        </Tooltip>
                      </Col>
                    </UserGroup.Flytsuite.Default>
                  ) : null}
                  {/* <Col className="mc-show-larger-than-medium-screen">{organization && organization.name}</Col> */}
                  {user && (
                    <Col>
                      <Dropdown
                        trigger={['click']}
                        overlay={dropdownOverlay}
                        placement="bottomRight"
                        visible={userMenuVisible}
                        onVisibleChange={(visible) => setUserMenuVisible(visible)}
                      >
                        <Button
                          className="mc-btn-transparent mc-btn-hover-outline"
                          style={{
                            padding: '0 0.25rem',
                            margin: '0 0.50rem',
                            display: 'flex',
                            // flexDirection: 'column',
                            alignItems: 'center',
                            // justifyContent: 'center'
                          }}
                        >
                          <div className="mc-show-larger-than-medium-screen" style={{
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignContent: 'center',
                            lineHeight: '14px'
                          }}>
                            <div>
                              {`${user.attributes.given_name} ${user.attributes.family_name}`}
                            </div>
                            <div>
                              {organization ? 
                                <Typography.Text type="secondary" style={{ fontSize: '0.75rem', opacity: 0.7, fontStyle: 'italic', color: 'white' }}>{organization.name}</Typography.Text> : 
                                <Typography.Text type="secondary" style={{ fontSize: '0.75rem', opacity: 0.7, fontStyle: 'italic', color: 'white' }}>Loading</Typography.Text>}
                            </div>
                          </div>
                          <Icon type="down" style={{ marginLeft: '14px' }} className="mc-show-larger-than-medium-screen" />
                          <span className="mc-show-smaller-than-medium-screen"><Icon type="user" style={{ margin: '0 6px' }} /></span>
                        </Button>
                      </Dropdown>
                    </Col>
                  )}
                </React.Fragment>
              }}
              </CurrentOrg>
            </Row>
          </Col>
        </Row>
    </Layout.Header>
    {maintenanceAnnouncement ? (
        <Alert
            message={maintenanceAnnouncement}
            type="warning"
            banner
        />
    ) : null}
    {user ? !user.attributes.email_verified ? (
      <Alert
        type="warning"
        banner
        message="Attention needed"
        description={
          <span>Please verify your email address. <Button onClick={() => userprefs.open()} type="link" size="small">Click here</Button></span>
        }
      />
    ) : null : null}
      <Modal
        title="About Manfest Central"
        visible={showAboutModal}
        onCancel={() => setShowAboutModal(false)}
        footer={null}
      >
        <AboutApp />
      </Modal>
      <FeedbackFormModal
            visible={showFeedbackModal}
            onCancel={() => setShowFeedbackModal(false)}
        />
    </>
  )
}

// @ts-ignore
export default withRouter(withApollo(withUserPreferences(GlobalNavbar)))