import { Button, Card, Col, DatePicker, Form, Icon, Input, List, Row, Spin } from 'antd';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Query } from 'react-apollo';
import { getOrgISNName } from '../../common/util';
import { validateISNNumber } from '../../formValidators';
import ContactInfoForm from '../form/ContactInfoForm';
import { OrganizationSelect } from '../form/select/OrganizationSelect';
import ISNChecker from '../ISNChecker';
import DuplicationChecker from '../masterdata/DuplicationChecker';
import WithOrgData from '../WithOrgData';
import { CredentialInput } from './CredentialInput';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
    style: {
        marginBottom: 0
    }
};

const defaultMessage = 'This field is required';

const defaultContactValues = (person) => {
    console.log("Here 1")
    return {
        "name":{
            "firstName": person.firstName,
            "lastName": person.lastName
        },
        "phone":{
            "type": (person.contactInfo && person.contactInfo.phone && person.contactInfo.phone.type) || "HOME",
            "number": (person.contactInfo && person.contactInfo.phone && person.contactInfo.phone.number) || ""
        },
        "email":{
            "type": (person.contactInfo && person.contactInfo.email && person.contactInfo.email.type) || "PERSONAL",
            "address": (person.contactInfo && person.contactInfo.email && person.contactInfo.email.address) || ""
        },
        "address":{
            "street": (person.contactInfo && person.contactInfo.address && person.contactInfo.address.street) || "",
            "city": (person.contactInfo && person.contactInfo.address && person.contactInfo.address.city) || "",
            "state": (person.contactInfo && person.contactInfo.address && person.contactInfo.address.state) || "",
            "zipCode": (person.contactInfo && person.contactInfo.address && person.contactInfo.address.zipCode) || ""
        }
    }
}

const defaultEmergencyContactValues = (person) => {
    console.log("Here 2")
    return {
        "name":{
            "firstName": person.firstName,
            "lastName": person.lastName
        },
        "phone":{
            "type": (person.emergencyContactInfo && person.emergencyContactInfo.phone && person.emergencyContactInfo.phone.type) || "HOME",
            "number": (person.emergencyContactInfo && person.emergencyContactInfo.phone && person.emergencyContactInfo.phone.number) || ""
        },
        "email":{
            "type": (person.emergencyContactInfo && person.emergencyContactInfo.email && person.emergencyContactInfo.email.type) || "PERSONAL",
            "address": (person.emergencyContactInfo && person.emergencyContactInfo.email && person.emergencyContactInfo.email.address) || ""
        },
        "address":{
            "street": (person.emergencyContactInfo && person.emergencyContactInfo.address && person.emergencyContactInfo.address.street) || "",
            "city": (person.emergencyContactInfo && person.emergencyContactInfo.address && person.emergencyContactInfo.address.city) || "",
            "state": (person.emergencyContactInfo && person.emergencyContactInfo.address && person.emergencyContactInfo.address.state) || "",
            "zipCode": (person.emergencyContactInfo && person.emergencyContactInfo.address && person.emergencyContactInfo.address.zipCode) || ""
        }
    }
}

const initializeOrg = (org, personOrg) => {
    if (!personOrg && !org) return;
    let init;
    if (personOrg){
        init = personOrg;
    }
    if (!init){
        init = org;
    }
    if (init){
        init = {
            key: init._id,
            label: init.name
        }
        return {...init};
    }
    return init
}

function ContactInfoCards({ person, form, ...restProps }){

    const { getFieldDecorator, getFieldValue, setFields } = form;
    const [ tabKey, setTabKey ] = useState('contactInfo');
    const contactFormRef = useRef(null);
    const eContactFormRef = useRef(null);

    const getFormErrors = (formRef) => () => formRef.current.getFieldsError();

    getFieldDecorator('contactInfo');
    getFieldDecorator('emergencyContactInfo');
    getFieldDecorator('contactInfoFields');
    getFieldDecorator('emergencyContactInfoFields');
    const contactInfoFields = getFieldValue('contactInfoFields');
    const eContactInfoFields = getFieldValue('emergencyContactInfoFields');
    
    const updateFields = (key, formErrorsFunc) => (fields) => {
        // console.log(form.getFieldsError());
        let errors;
        if (typeof formErrorsFunc === 'function'){
            let fieldErrors = formErrorsFunc();
            let values = Object.values(fieldErrors);
            for (let index = 0; index < values.length; index++) {
                const value = values[index];
                if (Array.isArray(value) && value.length > 0){
                    if (Array.isArray(errors)){
                        errors.push(...value);
                    }
                    else
                    {
                        errors = value;
                    }
                }   
            }
        }
        setFields({ [key]: {
            value: fields,
            errors
        } });
        // let newFields = {};
        // Object.entries(fields).forEach(([ key, value ]) => {
        //     newFields[key] = value
        // })
        // setFieldsValue({ [key]: { ...getFieldValue(key), ...newFields } });
        // return newFields
    }

    const renderTabWarning = (formName) => {
        if (form.getFieldError(formName)){
            return <Icon style={{ color: 'red' }} type="warning" />
        }
        return null;
    }
    
    return <Card size="small" onTabChange={setTabKey} tabList={[
            {
                tab: <span>{renderTabWarning('contactInfoFields')} Contact Info</span>,
                key: 'contactInfo'
            },
            {
                tab: <span>{renderTabWarning('emergencyContactInfoFields')} Emergency Contact Info</span>,
                key: 'emergencyContactInfo'
            }
        ]} {...restProps}>
            <ContactInfoForm
                style={{ display: tabKey === 'contactInfo' ? 'block': 'none' }}
                ref={contactFormRef}
                defaultValues={defaultContactValues(person)}
                formValues={contactInfoFields}
                removeFields={['name']}
                onValuesChange={updateFields('contactInfo')}
                onFieldsChange={updateFields('contactInfoFields', getFormErrors(contactFormRef))}
            />
            <ContactInfoForm
                style={{ display: tabKey === 'emergencyContactInfo' ? 'block': 'none' }}
                ref={eContactFormRef}
                defaultValues={defaultEmergencyContactValues(person)}
                formValues={eContactInfoFields} 
                onValuesChange={updateFields('emergencyContactInfo')}
                onFieldsChange={updateFields('emergencyContactInfoFields', getFormErrors(eContactFormRef))}
            />
        </Card>
}

const DuplicateSearch = ({ lastName, firstName, employers, onItemClick, style }) => {
    const handleItemClick = (personId) => {
        if (typeof onItemClick === 'function'){
            onItemClick(personId);
        }
    }
    return <DuplicationChecker
        query={gql`
        query SearchPersonDuplicates($filter: PersonFilterInput, $limit: Int=15){
            persons(filter: $filter, limit: $limit){
                docs {
                    ... on Person {
                        _id
                        lastName
                        firstName
                        customerID {
                            _id
                            name
                        }
                        employerID {
                            _id
                            name
                        }
                    }
                }
            }
        }
        `}
        variables={{
            filter: {
                employer: employers,
                lastName: lastName,
                firstName: firstName
            },
            limit: 15
        }}
        skip={!lastName || !firstName}
        getData={(data) => data && data.persons && data.persons.docs}
        style={style}
        renderItems={(items) => <List size="small">
            {items.map((person) =>
                <List.Item>
                    <Button className="mc-link-btn" onClick={() => handleItemClick(person._id)}>{person.lastName} {person.firstName} (Customer: {person.customerID && person.customerID.name}, Employer: {person.employerID && person.employerID.name})</Button>
                </List.Item>
            )}
        </List>}
    Z/>
}

let PersonEditor = ({ form: { getFieldDecorator, getFieldValue }, form, person, orgData={}, employers, onDupeSearchItemClicked }) => {
    const [ dupeSearchState, setDupeSearchState ] = useState({ lastName: null, firstName: null });

    const handleDupeSearchStateUpdate = debounce((state) => setDupeSearchState(state), 1000);

    var credentials = {};
    if (Array.isArray(person && person.credentials)){
        for (let index = 0; index < person.credentials.length; index++) {
            const element = person.credentials[index];
            credentials[element.type] = element;
        }
    }
    let customer = orgData.customer;
    let employer = orgData.employer;
    let orgClassType = orgData.organization.classType;
    let initialCustomer = initializeOrg(customer, person && person.customerID);
    let initialEmployer = initializeOrg(employer, person && person.employerID);

    useEffect(() => {
        handleDupeSearchStateUpdate({
            lastName: form.getFieldValue('lastName'),
            firstName: form.getFieldValue('firstName')
        })
    // eslint-disable-next-line
    }, [ form.getFieldValue('lastName'), form.getFieldValue('firstName') ])

    return (
        <Form>
            <Row gutter={12} style={{ marginBottom: 12 }}>
                <Col>
                    <Card size="small" title="Personal">
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item label="Last Name" {...formItemLayout}>
                                {getFieldDecorator('lastName', {
                                    initialValue: person && person.lastName,
                                    rules: [
                                        {required: true, message: defaultMessage}
                                    ]
                                })(<Input disabled={person} size="small" />)}
                                </Form.Item>
                                <Form.Item label="Customer" {...formItemLayout}>
                                {getFieldDecorator('customerID', {
                                    initialValue: initialCustomer,
                                    rules: [
                                        {required: true, message: defaultMessage}
                                    ]
                                })(
                                    <OrganizationSelect
                                        classTypes={['flytsuite.customer']}
                                        labelInValue={true}
                                        size="small"
                                        allowClear
                                        disabled={orgClassType === 'flytsuite.customer'}
                                    />
                                )}
                                </Form.Item>
                                <Form.Item label="DOB" {...formItemLayout}>
                                {getFieldDecorator('dob', {
                                    initialValue: person && person.dob && moment(person.dob),
                                    rules: [
                                        {required: true, message: defaultMessage}
                                    ]
                                })(
                                    <DatePicker disabled={person} style={{ width: '100%' }} size="small" format="YYYY-MM-DD" />
                                )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="First Name" {...formItemLayout}>
                                    {getFieldDecorator('firstName', {
                                        initialValue: person && person.firstName,
                                        rules: [
                                            {required: true, message: defaultMessage}
                                        ]
                                    })(<Input disabled={person} size="small" />)}
                                </Form.Item>
                                <Form.Item label="Employer" {...formItemLayout}>
                                {getFieldDecorator('employerID', {
                                    initialValue: initialEmployer,
                                    rules: [
                                        {required: true, message: defaultMessage}
                                    ]
                                })(
                                    <OrganizationSelect
                                        classTypes={['flytsuite.employer']}
                                        labelInValue={true}
                                        size="small"
                                        allowClear
                                        disabled={orgClassType === 'flytsuite.employer'}
                                    />
                                )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {!person ? (
                <DuplicateSearch
                    employers={employers}
                    onItemClick={(personId) => {
                        if (typeof onDupeSearchItemClicked === 'function'){
                            onDupeSearchItemClicked(personId);
                        }
                    }}
                    lastName={dupeSearchState.lastName}
                    firstName={dupeSearchState.firstName}
                    style={{ marginBottom: 12 }}
                />
            ) : null}
            <ContactInfoCards person={person} form={form} style={{ marginBottom: 12 }} />
            <Row gutter={12} style={{ marginBottom: 12 }}>
                <Col xl={14}>
                    <Card size="small" title="Credentials" style={{ marginBottom: 12 }}>
                        <Form.Item label="Driver License" {...formItemLayout}>
                            {getFieldDecorator('credential-US_DRIVERS_LICENSE', {
                                initialValue: credentials.US_DRIVERS_LICENSE
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Passport" {...formItemLayout}>
                            {getFieldDecorator('credential-PASSPORT', {
                                initialValue: credentials.PASSPORT
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Passport Card" {...formItemLayout}>
                            {getFieldDecorator('credential-PASSPORT_CARD', {
                                initialValue: credentials.PASSPORT_CARD
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="TWIC" {...formItemLayout}>
                            {getFieldDecorator('credential-TWIC', {
                                initialValue: credentials.TWIC
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Safety Card" {...formItemLayout}>
                            {getFieldDecorator('credential-SAFETY_CARD', {
                                initialValue: credentials.SAFETY_CARD
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                    showKey={false}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Huet Card" {...formItemLayout}>
                            {getFieldDecorator('credential-HUET_CARD', {
                                initialValue: credentials.HUET_CARD
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                    showKey={false}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="EBS Card" {...formItemLayout}>
                            {getFieldDecorator('credential-EBS_CARD', {
                                initialValue: credentials.EBS_CARD
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                    showKey={false}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="ISN Card" {...formItemLayout}>
                            {getFieldDecorator('credential-ISN', {
                                initialValue: credentials.ISN,
                                rules: [
                                    {validator: (rule, value, callback) => validateISNNumber(rule, value && value.key, callback)}
                                ]
                            })(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                    showExpiration={false}
                                />
                            )}
                        </Form.Item>
                    </Card>
                </Col>
                <Col xl={10}>
                    <Card title="ISN Result" size="small">
                        <ISNChecker
                            customerID={getFieldValue('customerID') && getFieldValue('customerID').key}
                            isnNumber={getFieldValue('credential-ISN') && getFieldValue('credential-ISN').key}  
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={12} style={{ marginBottom: 12 }}>
                <Col xl={14}>
                    <Card size="small" title="Miscellaneous Requirements" style={{ marginBottom: 12 }}>
                        <Form.Item label="Extra Broad" {...formItemLayout}>
                            {getFieldDecorator('extraBroadState', {
                                initialValue: (person && person.extraBroadState) || ""
                            })(
                                <Input disabled={person} size="small" />
                            )}
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    )
}

export function getFormCredentials(form, getCredAttrs){
    const fields = Object.entries(form.getFieldsValue());
    let creds = {};
    fields.filter(([field]) => field.startsWith('credential-'))
    .forEach(([field, cred]) => {
        const fieldName = field.replace('credential-', '');
        if (typeof getCredAttrs === 'function' && cred){
            creds[fieldName] = getCredAttrs(cred);
            return;
        }
        creds[fieldName] = cred;
    });
    return creds
}

export default Form.create({
    onFieldsChange(props, _, fields){
        if (typeof props.onFieldsChange !== 'function') return;
        props.onFieldsChange(fields);
    }
})( WithOrgData( PersonEditor ) )