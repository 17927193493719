import gql from "graphql-tag";
import {LocationFragment} from "../../../../Queries/Location";

export default gql`
query LocationQuery(
    $filter: LocationsFilterInput
    $limit: Int = 15
){
    locations(
        filter: $filter
        limit: $limit
    ){
        docs {
            ...LocationFragment
        }
    }
}
${LocationFragment}
`