import { Col, Divider, Icon, Row, Spin, Tag } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import useInterval from '../../useInterval';
import './Criteria.less';

const SiderSection = ({ title, children }) => {
    return (
        <div className="reporting-sider-section" style={{ padding: '1rem' }} >
            <h4>{title}</h4>
            <div>{children}</div>
        </div>
    )
}

const ReportStatus = ({ status, style }) => {
    const textStyle = {
        fontSize: '1.2rem',
        ...style
    }
    switch (status) {
        case 'INITIALIZING':
        case 'IN_PROGRESS':
            return (
                <span style={{ color: '#1890ff', ...textStyle }} >
                    <Spin style={{ marginRight: 6 }} indicator={<Icon type="loading" spin />} />
                    IN PROGRESS
                </span>
            )
        case 'COMPLETE':
            return (
                <span style={{ color: '#52c41a', ...textStyle }} >
                    <Icon style={{ marginRight: 6 }} type="check" />
                    COMPLETE
                </span>
            )
        case 'ERROR':
            return (
                <span style={{ color: '#f5222d', ...textStyle }} >
                    <Icon style={{ marginRight: 6 }} type="cross" />
                    ERROR
                </span>
            )
        default:
            return null;
    }
}

const ReportProperty = ({label, value, style}) => (
    <Row style={style}>
        <Col span={12}>
            <strong>{label}:</strong>
        </Col>
        <Col span={12}>
            {typeof value === 'string' && value}
            {Array.isArray(value) && value.map(val => <Tag style={{ cursor: 'default' }} >{val}</Tag>)}
        </Col>
    </Row>
)

export const Criteria = ({ data, isInProgress, justCriteria=false, criteriaItemStyle={} }) => 
{
    const [ currentTime, setCurrentTime ] = useState(moment());
    
    useInterval(() => {
        setCurrentTime(moment())
    }, 1000);

    let criteria = data ? (
        data.criteria
            .filter((item) => {
                const value = JSON.parse(item.display_value || item.value);
                if (Array.isArray(value) && value.length) return true
                if (!Array.isArray(value) && value) return true
                return false
            })
            .map((item) => {
                var value = JSON.parse(item.display_value || item.value);
                if (Array.isArray(value)){
                    if (!value.length) value = 'N/A';
                }
                if (Array.isArray(value)){
                    var isTime = false;
                    for (var i = 0; i < value.length; i++)
                    {
                        var time = moment(value[i]);
                        if (time.isValid()){
                            isTime = true;
                            value[i] = time.format('YYYY-MM-DD');
                        }
                    }
                    if (isTime){
                        value = value.join(' - ');
                    }
                }
                else if (typeof value === 'object' && value){
                    value = Object.values(value)
                }
                else if (!value){
                    value = 'N/A'
                }
                return (
                    <ReportProperty
                        key={item.key}
                        label={item.label || item.key}
                        value={value}
                        style={{ marginBottom: 6, ...criteriaItemStyle }}
                    />
                )
            })
    ) : null;

    if (justCriteria){
        return criteria;
    }

    const startMoment = moment(data.startTs);
    const endMoment = moment(data.endTs || currentTime);
    const runTime = endMoment.diff(startMoment, 'milliseconds') / 1000;
    const created = moment(data.createdTs).format('YYYY-M-D H:m');

    return (
        <div>
            <SiderSection title="status">
                <ReportStatus status={data.status} style={{ fontSize: 'inherit' }} />
            </SiderSection>
            <Divider style={{ margin: 0 }} />
            <SiderSection title="created">
                {created}
            </SiderSection>
            <Divider style={{ margin: 0 }} />
            <SiderSection title="run time">
                {isInProgress ? <Spin style={{ marginRight: 12 }} /> : null}
                {`${runTime} seconds`}
            </SiderSection>
            <Divider style={{ margin: 0 }} />
            <SiderSection title="criteria">
                {criteria}
            </SiderSection>
        </div>
    )
}