import { Icon, Menu } from 'antd';
import useUserGroups from 'hooks/useUserGroups';
import React from 'react';

function EmployerNav(props){
    const { ...restProps } = props;

    const [ userGroups ] = useUserGroups();

    return <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={['masterdata', 'management']}
        {...restProps}
    >
        <Menu.Item key="passengers">
            <Icon type="team" />
            <span>Passengers</span>
        </Menu.Item>
        <Menu.SubMenu key="masterdata" title={<span><Icon type="database" /><span>Master Data</span></span>}>
            <Menu.Item key="masterdata/personnel">Personnel</Menu.Item>
        </Menu.SubMenu>
        {userGroups.findIndex(g => g.includes('management') || g === 'flytsuite.master.all') > -1 &&
            <Menu.SubMenu className='mc-flights-nav-submenu-management' key="management" title={<span><Icon type="team" /><span>Management</span></span>}>
                {userGroups.findIndex(g => g.includes('management.all') || g.includes('management.emailtriggers.all') || g === 'flytsuite.master.all') > -1 && (
                    <Menu.Item key="management/email-triggers">Email Triggers</Menu.Item>
                )}
            </Menu.SubMenu>
        }
    </Menu>
}

export default EmployerNav