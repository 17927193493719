import React from 'react';
import { Card, Row, Col } from 'antd';
import { Statistic } from 'antd';

const PaxCovidVaxDetailedSummary = ({ data }) => {
    const perc = (data.personnel_count && data.personnel_count.value) || 0;
    const is_vax_count = data.is_vaccinated?.value || 0;
    const no_vax_count = data.is_not_vaccinated?.value || 0;
    const ess = data.essential_personnel?.value || 0;
    return (
        <Row type="flex" gutter={12} style={{ alignItems: '' }}>
            <Col span={6}>
                <Card>
                    <Statistic title="Total personnel" value={perc} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Vaccinated Personnel" value={is_vax_count} suffix={`(${no_vax_count} not vaccinated)`} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Essential Personnel" value={ess} />
                </Card>
            </Col>
        </Row>
    )
}
 
export default PaxCovidVaxDetailedSummary;