import { Auth } from '@aws-amplify/auth';
import { Alert, Button, Form, Input } from 'antd';
import { tailFormItemLayout } from 'common/form';
import CognitoPhoneNumber from 'components/form/InputCognitoPhoneNumber';
import CognitoPhoneNumberClass from 'components/form/InputCognitoPhoneNumber/CognitoPhoneNumber';
import { validateCognitoPhoneNumber } from 'components/form/InputCognitoPhoneNumber/validator';
import OrgSearch from 'components/SignUpOrganizationSearch';
import { composeValidators, validatePassword } from 'formValidators';
import { Component } from 'react';
import { Link } from "react-router-dom";
import '../css/Login.css';
import mcLogo from '../img/mc-logo-wide-white.svg';
import Verify from './Verify';

const FormItem = Form.Item;

class SignUp extends Component {
    constructor(props){
        super(props);
        this.state = {
            confirmDirty: false,
            error: '',
            organizations: [],
            organizationsError: true,
            showVerification: false,
            user: {}
        }
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    
    compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
        callback('Confirm Password and Password are not the same');
    } else {
        callback();
    }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmpassword'], { force: true });
        }
        callback();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log("Submitting form");
                let attr = {
                    username: values.email,
                    password: values.password,
                    attributes: {
                        given_name: values.firstname,
                        family_name: values.lastname,
                        "custom:organization": values.employer,
                        "custom:org_id": String(1)
                    }
                }
                if (values.phone){
                    attr.attributes.phone_number = values.phone && values.phone.countryCode && values.phone.number ? values.phone.toString() : undefined;
                }
                if (values.companyphone){
                    attr.attributes['custom:company_phone_number'] = values.companyphone && values.companyphone.countryCode && values.companyphone.number ? values.companyphone.toString() : undefined;
                }
                Auth.signUp(attr)
                .then(user => this.setState({user: user, showVerification: true, password: values.password}))
                .catch(err => this.setState({
                    error: err.message
                }));
            }
        });
    }
    render(){
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };

        if (!this.state.showVerification)
        {
            return (
                <div className="login-page-wrapper">
                    <div className="container">
                        <img src={mcLogo} className="mc-logo" alt="Manifest Central Logo"/>
                        <div className="login-box">
                            <h2 style={{fontWeight: 100, marginBottom: '1rem'}}>Register New Dataflyt Account</h2>
                            {(this.state.error) ? 
                            <Alert
                                style={{marginBottom: '1rem'}}
                                message="Registration error"
                                description={this.state.error}
                                type="error"
                                closable
                                onClose={evt =>
                                    this.setState({
                                        error: ''
                                    })
                                }
                            /> : ''
                            }
                            <Form onSubmit={this.handleSubmit}>
                                <FormItem {...formItemLayout} label="E-mail">
                                    {getFieldDecorator('email', {
                                        rules: [{
                                            type: 'email', message: 'Please enter a valid email address',
                                            }, {
                                            required: true, message: 'Please enter an email address ',
                                        }],
                                    })(
                                        <Input type="email" autoComplete="email" />
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="Password" style={{ marginBottom: '12px' }}>
                                    {getFieldDecorator('password', {
                                        rules: [{
                                        validator: composeValidators(this.validateToNextPassword, validatePassword),
                                        }],
                                    })(
                                        <Input type="password" autoComplete="new-password" onBlur={this.handleConfirmBlur} />
                                    )}
                                </FormItem>
                                <FormItem {...tailFormItemLayout} style={{ margin: 0 }}>
                                    <h4 style={{ lineHeight: 'initial' }}>Password requirements</h4>
                                    <ul>
                                        <li style={{ lineHeight: 'initial' }}>At least one lowercase letter.</li>
                                        <li style={{ lineHeight: 'initial' }}>At least one uppercase letter.</li>
                                        <li style={{ lineHeight: 'initial' }}>At least one number.</li>
                                    </ul>
                                </FormItem>
                                <FormItem {...formItemLayout} label="Confirm Password">
                                    {getFieldDecorator('confirmpassword', {
                                        rules: [{
                                        validator: composeValidators(this.compareToFirstPassword, validatePassword),
                                        }],
                                    })(
                                        <Input type="password" autoComplete="new-password" />
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="First Name">
                                    {getFieldDecorator('firstname', {
                                        rules: [{
                                            required: true, message: 'Please enter your first name',
                                        }],
                                    })(
                                        <Input autoComplete="given-name" />
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="Last Name">
                                    {getFieldDecorator('lastname', {
                                        rules: [{
                                            required: true, message: 'Please enter your last name',
                                        }],
                                    })(
                                        <Input autoComplete="family-name" />
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="Phone Number">
                                    {getFieldDecorator('phone', {
                                        rules: [{
                                            required: true,
                                            validator: validateCognitoPhoneNumber
                                        }],
                                        initialValue: new CognitoPhoneNumberClass('+1', '')
                                    })(
                                        <CognitoPhoneNumber inputProps={{ autoComplete: 'tel', placeholder: "5556667777" }} />
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="Company Phone Number">
                                        {getFieldDecorator('companyphone', {
                                            rules: [{
                                                required: false,
                                            }],
                                            initialValue: new CognitoPhoneNumberClass('+1', '')
                                        })(
                                            <CognitoPhoneNumber
                                                inputProps={{ autoComplete: 'tel', placeholder: "5556667777" }}
                                            />
                                        )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="Organization">
                                    {getFieldDecorator('employer', {
                                        rules: [{
                                            required: true, message: 'Please choose your organization',
                                        }],
                                    })(
                                        <OrgSearch />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button size="large" htmlType="submit" type="primary" style={{width: '100%'}}>Register Account</Button>
                                </FormItem>
                                <Link to="/login">I already have an account</Link>
                            </Form>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <Verify
                    email={this.state.user.user.username}
                    password={this.state.password}
                    signup={true}
                    redirectTo="/login"
                />
            )
        }
    }
}

export default Form.create()(SignUp);
