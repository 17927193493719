import React, { ReactElement } from 'react';
import { Table, Column, ITableProps, Cell, IColumnProps, SelectionModes } from '@blueprintjs/table';
import { Tooltip, Divider, Popconfirm, Icon, Spin, Button } from 'antd';
import { ACTION_COL_WIDTH } from '.';
import { getColWidths, useColWidths, ColWidthsValues } from 'common/table';
import useUserGroups from 'hooks/useUserGroups';
import { ButtonProps } from 'antd/lib/button';

const DEFAULT_MD_DEL_USR_GRPS = ['flytsuite.master.all', 'flytsuite.master.delete'];

export interface ActionCellRendererOptions {
    deleting: boolean,
    handleDelete(idx: number): void,
    selected: boolean,
    handleEdit(idx: number): void
    idx: number
}

export const ActionColumn: React.FC<IColumnProps> = (props) => {
    return <Column
        key="actions"
        name="Actions"
        {...props}
    />
}

export interface ActionCellProps {
    deleting?: boolean,

    /**
     * Whether or not the row being deleted is not this row (disables other delete buttons)
     */
    deletingOther?: boolean,
    handleDelete?(idx: number): void,
    selected?: boolean,
    handleEdit?(idx: number): void,
    handleCancelEdit?(idx: number): void,
    hideDelete?: boolean,
    deleteBtnProps?: ButtonProps,
    deleteDisabled?: boolean,
    idx: number
}

const ActionBtnStyle: React.CSSProperties = {
    padding: 0,
    width: 'auto',
    height: 'auto',
    fontSize: '11px'
}

export function renderActionCell(props: ActionCellProps){

    function renderDelete(){
        if (props.hideDelete) return null;
        if (props.deleteDisabled){
            return <>
            <Tooltip title="You do not have permission to delete master data" placement="right">
                <Button
                    type="link"
                    size="small"
                    icon="delete"
                    disabled={true}
                    {...props.deleteBtnProps}
                    style={{ ...ActionBtnStyle, ...(props.deleteBtnProps?.style) }}
                />
            </Tooltip>
            <Divider type="vertical" />
            </>
        }
        return (
            <>
            <Tooltip title="Delete" mouseEnterDelay={1}>
                {props.deleting ? (
                    <Spin size="small" indicator={<Icon type="loading" />} />
                ) : (
                <Popconfirm title="Are you sure?" onConfirm={() => props.handleDelete && props.handleDelete(props.idx)}>
                    <Button
                        type="link"
                        size="small"
                        icon="delete"
                        disabled={props.deletingOther}
                        {...props.deleteBtnProps}
                        style={{ ...ActionBtnStyle, ...(props.deleteBtnProps?.style) }}
                    />
                </Popconfirm>
                )}
            </Tooltip>
            <Divider type="vertical" />
            </>
        )
    }

    return <Cell interactive>
        {renderDelete()}
        {!props.selected ? (
            <Tooltip title="Edit" mouseEnterDelay={1}>
                <Button 
                    icon="edit"
                    size="small"
                    type="link"
                    style={ActionBtnStyle}
                    disabled={props.deleting}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.handleEdit && props.handleEdit(props.idx);
                    }}
                />
            </Tooltip>
        ) : (
            <Tooltip title="Cancel" mouseEnterDelay={1}>
                <Button className="mc-link-btn" onClick={(e) => {
                    e.stopPropagation();
                    props.handleCancelEdit && props.handleCancelEdit(props.idx);
                }}><Icon type="close" /></Button>
            </Tooltip>
        )}
    </Cell>
}

export const ActionCell: React.FC<ActionCellProps> = (props) => {
    return <Cell interactive>
        <Tooltip title="Delete" mouseEnterDelay={1}>
            {props.deleting ? (
                <Spin size="small" indicator={<Icon type="loading" />} />
            ) : (
            <Popconfirm title="Are you sure?" onConfirm={() => props.handleDelete && props.handleDelete(props.idx)}>
                <Button className="mc-link-btn"><Icon type="delete" /></Button>
            </Popconfirm>
            )}
        </Tooltip>
        <Divider type="vertical" />
        {!props.selected ? (
            <Tooltip title="Edit" mouseEnterDelay={1}>
                <Button className="mc-link-btn" onClick={() => props.handleEdit && props.handleEdit(props.idx)}><Icon type="edit" /></Button>
            </Tooltip>
        ) : (
            <Tooltip title="Cancel" mouseEnterDelay={1}>
                <Button className="mc-link-btn" onClick={() => props.handleEdit && props.handleEdit(props.idx)}><Icon type="close" /></Button>
            </Tooltip>
        )}
    </Cell>
}

export interface MDTableProps extends ITableProps {
    selectedRowIndex?: number,
    onEditRow?(idx: number): void,
    onEditCancel?(): void,
    hideDelete?: boolean,
    onDelete?: (idx: number) => void,
    deleteLoadingIdx?: number,
    colWidthsHookValues?: ColWidthsValues,
    deleteUserGroups?: string[]
}

const MDTable: React.FC<MDTableProps> = React.forwardRef(({ children, ...props }, ref: React.LegacyRef<Table>) => {
    const [ userGroups ] = useUserGroups();
    let delUserGroups = props.deleteUserGroups || DEFAULT_MD_DEL_USR_GRPS;

    delUserGroups = [ ...delUserGroups, ...DEFAULT_MD_DEL_USR_GRPS ];

    let userHasDeletePerm = false;
    for (const i in userGroups) {
        let group = userGroups[i];
        if (delUserGroups.includes(group)){
            userHasDeletePerm = true;
            break;
        }
    }

    let highlightedRow = props.selectedRowIndex;
    // @ts-ignore TODO: Remove this ts-ignore
    let childrenArray = React.Children.toArray<ReactElement<IColumnProps>>(children);
    childrenArray.unshift(
        <Column name="" cellRenderer={(idx) => {
            return renderActionCell({
                idx,
                selected: props.selectedRowIndex === idx,
                handleEdit: (idx) => {
                    if (props.onEditRow) props.onEditRow(idx)
                    // MDState.editFromExisting(data[idx]);
                    // MDState.setIsEditing(true);
                    // MDState.setEntryFields(data[idx]);
                },
                handleCancelEdit: () => {
                    if (props.onEditCancel) props.onEditCancel()
                },
                hideDelete: props.hideDelete,
                handleDelete: (idx) => props.onDelete?.(idx),
                deleteDisabled: !userHasDeletePerm,
                deleting: props.deleteLoadingIdx === idx,
                deletingOther: props.deleteLoadingIdx && props.deleteLoadingIdx !== -1 && props.deleteLoadingIdx !== idx
            })
        }} />
    )
    let initialColWidths = [ACTION_COL_WIDTH]
    if (props.hideDelete){
        initialColWidths[0] = ACTION_COL_WIDTH - 40;
    }
    if (props.columnWidths){
        initialColWidths.push(...props.columnWidths)
    }
    else {
        initialColWidths.push(...getColWidths(React.Children.count(children)))
    }

    let [ colWidths, , updateColWidth ] = props.colWidthsHookValues ? 
        props.colWidthsHookValues : 
        // eslint-disable-next-line
        useColWidths(initialColWidths.length, initialColWidths);

    return <Table
        {...props}
        ref={ref}
        className="mc-table"
        selectedRegions={![-1, null, undefined].includes(highlightedRow) ? [
            {
                rows: [ highlightedRow, highlightedRow ]
            }
        ] : []}
        onSelection={(regions) => {
            if (regions[0].cols[0] === 0) return;
            let selectedRow = regions[0].rows[0];
            if (selectedRow !== highlightedRow){
                if (props.onEditRow) props.onEditRow(selectedRow)
            }
            else {
                if (props.onEditCancel) props.onEditCancel()
            }
        }}
        columnWidths={colWidths}
        numFrozenColumns={1 + (props.numFrozenColumns || 0)}
        enableMultipleSelection={false}
        selectionModes={SelectionModes.ROWS_AND_CELLS}
        onColumnWidthChanged={updateColWidth}
    >
        {/* @ts-ignore TODO: Remove this ts-ignore */}
        {childrenArray}
    </Table>
})

export default MDTable