import React, { createContext, FC, PropsWithChildren, useReducer, useEffect, useContext } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import { useApolloClient } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from './theme';
// import AWS from 'aws-sdk';

export interface AuthContextProps {
    user: any,
    loading: boolean,
    error: any,
    signOut: () => Promise<any>,
    refreshUser?: () => Promise<any>
}

export const AuthContext = createContext<AuthContextProps>({ user: null, loading: true, error: null, signOut: null, refreshUser: () => null })

const initialState = { user: null, loading: true }

function authReducer(state, action){
    switch (action.type) {
        case 'FETCHING':
            return { user: null, loading: true }
        case 'RECIEVED':
            return { user: action.payload, loading: false }
        case 'ERROR':
            return { user: null, error: action.payload, loading: false }
        case 'UPDATE_USER_ATTRIBUTES':
            if (!state.user) return state;

            const user = cloneDeep(state.user);
            Object.assign(user, { attributes: {
                ...state.user.attributes,
                ...action.payload
            } })
            if (action.payload.email !== state.user.attributes.email){
                // Email address was changed. Needs to turn off email_verified attribute.
                user.attributes.email_verified = false;
            }
            return { ...state, user };
        case 'VERIFY_EMAIL':
            let user_to_verify = { ...state.user }
            user_to_verify.attributes.email_verified = true;
            return { ...state, user: user_to_verify }
        case 'UNVERIFY_EMAIL':
            let user_to_unverify = { ...state.user }
            user_to_unverify.attributes.email_verified = false;
            return { ...state, user: user_to_unverify }
        default:
            break;
    }
}

export const AuthProvider: FC<PropsWithChildren<{}>> = (props) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const apolloClient = useApolloClient();
    const history = useHistory();
    const theme = useContext(ThemeContext);

    function loadUser(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            let promises: Array<Promise<any>> = [];

            promises.push(
            Auth.currentAuthenticatedUser({
                bypassCache: true // Always request user info from cognito
            })
            .then(user => {
                dispatch({ type: 'RECIEVED', payload: user });
                return user;
            })
            .catch(e => {
                console.error(e);
                message.error("Could not retrieve login details");
                dispatch({ type: 'ERROR', payload: e })
            })
            )
            
            // promises.push(
            // Auth.currentUserCredentials().then((cred) => {
            //     AWS.config.credentials = cred;
            // })
            // )

            Promise.all(promises)
                .then((values) => resolve(values[0]))
                .catch((error) => reject(error))
        })
        return promise;
    }

    useEffect(() => {
        loadUser();

        Hub.listen('auth', data => console.log("AUTH EVENT: " + data))
        return () => {
            Hub.remove('auth', null);
        }
    }, [])

    useEffect(() => {
        function listener (data){
            if (!state.user) return;
            console.log('user channel event fired:', data, state);
            switch (data.payload.event) {
                case 'updateUserAttributes':
                    if (!state.user || !state.user.getSession) return;
                    const prevAttr = { ...state.user.attributes };
                    dispatch({ type: 'UPDATE_USER_ATTRIBUTES', payload: data.payload.data.attributes })
                    Auth.updateUserAttributes(state.user, data.payload.data.attributes)
                    .then(() => {
                        message.success(data.payload.data.customSuccessMessage || "Successfully updated user attributes");
                    })
                    .catch((e) => {
                        console.log('Failed to update user attributes:', e);
                        message.error("An error occurred while updating user attributes. Please try again later.");
                        dispatch({ type: 'UPDATE_USER_ATTRIBUTES', payload: prevAttr })
                    })
                    break;
                case 'verifyEmail':
                    Auth.verifyCurrentUserAttributeSubmit('email', data.payload.data.code)
                    .then(() => {
                        message.success("Successfully verified email address.")
                        dispatch({ type: 'VERIFY_EMAIL' });
                    })
                    .catch((err) => {
                        message.error('Failed to verify email address: ' + err.message);
                        console.error(err);
                    })
                    break;
            }
        }
        Hub.remove('user', listener);
        Hub.listen('user', listener);
        return () => {
            Hub.remove('user', listener);
        }
    // eslint-disable-next-line
    }, [state.user])

    console.log(state.user);

    return <AuthContext.Provider value={{
        ...state,
        refreshUser: () => loadUser(),
        signOut: () => {
            return new Promise((resolve, reject) => {
                theme.setThemingEnabled(false);
                Auth.signOut()
                .then(() => {
                    apolloClient.resetStore();
                    message.success('You have been logged out');
                    history.push('/login');
                    resolve(null);
                })
                .catch(err => {
                    theme.setThemingEnabled(true);
                    message.error('Logout failed: ' + err.message);
                    reject(err.message);
                })
            })
        }
    }}>
        {props.children}
    </AuthContext.Provider>
}