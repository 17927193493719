// eslint-disable-next-line import/no-anonymous-default-export
export default `
type Transporter {
  _id: ID!
  name: String
}
type Mutation {
  setTpList(payload: [Transporter]!): [Transporter]
  transporter(id: ID!): Transporter
}
type Query {
  tpList(customerID: ID!): [Transporter]
}
`;