import gql from "graphql-tag";

export default gql`
mutation IsnCheck($_id: ID! $tpID: ID!){
    setPassengerIsnCheck(_id: $_id tpID: $tpID){
        _id
        lastName
        firstName
        lastIsnResultWithHeader {
            isn_customer
            isn_ownerid
            success
            error_message
            quick_check_status
            reason
            qc_count
            qc_result {
                isnStatus {
                    shortLabel
                }
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
            selectedResult {
                isnStatus {
                    shortLabel
                }
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
        }
        authorization {
            onWhiteList
            brdRestriction_Isn {
                type
                status
            }
        }
    }
}
`