import { useEffect, useState } from "react";
import { QueryResult, useQuery } from "react-apollo";
import ReportQuery, { S3ReportQuery } from "../ReportDetails/ReportQuery";
import moment from 'moment';
import { ApolloError } from "apollo-client";

export type ReportState = 'NOT_STARTED' | 'IN_PROGRESS' | 'QUERY_FAILED' | 'DOWNLOADING' | 'DOWNLOAD_FAILED' | 'COMPLETE';

export interface ReportDetailsResult {
    reportData: any,
    couchResult: QueryResult,
    s3Result: QueryResult,
    reportState: ReportState,
    error: ApolloError
}

function useReportDetails(reportId: string, overrideState?: ReportState): ReportDetailsResult{
    const [ reportState, setReportState ] = useState<ReportState>('NOT_STARTED');

    let skipCouch = !reportId;
    let skipS3 = reportState !== 'DOWNLOADING' || !reportId;

    // Get report stub from Couch
    const couchResult = useQuery(ReportQuery, {
        variables: {
            id: reportId,
        },
        skip: skipCouch,
        pollInterval: reportState === 'IN_PROGRESS' ? 1000 : 0,
        onCompleted: () => !skipCouch && setReportState('IN_PROGRESS'),
        onError: () => setReportState('QUERY_FAILED')
    })

    useEffect(() => {
        if (reportId){
            setReportState('IN_PROGRESS');
        }
        else
        {
            setReportState('NOT_STARTED');
        }
    }, [ reportId ])

    useEffect(() => {
        if (['INITIALIZING', 'IN_PROGRESS'].includes(couchResult.data?.report?.status)){
            console.debug('Report has INITIALIZING or IN_PROGRESS status')
            if (reportState !== 'IN_PROGRESS'){
                setReportState('IN_PROGRESS');
            }
        }
        else
        {
            if (reportState === 'IN_PROGRESS'){
                setReportState('DOWNLOADING');
            }
        }
    }, [ couchResult.data?.report?.status ])

    // Get full report from S3 after report finishes in successful state
    const s3Result = useQuery(S3ReportQuery, {
        variables: {
            id: reportId,
            encoding: 'base64'
        },
        fetchPolicy: 'no-cache', // Reports can get huge, so best not to store it in the cache
        skip: skipS3,
        onCompleted: () => !skipS3 && setReportState('COMPLETE'),
        onError: () => setReportState('DOWNLOAD_FAILED')
    })

    if (!reportId){
        return {
            reportData: null,
            couchResult: null,
            s3Result: null,
            reportState: overrideState || reportState,
            error: null
        }
    }

    var mergedReport;
    if ((couchResult.data && couchResult.data.report) || (s3Result.data && s3Result.data.s3Report))
    {
        mergedReport = couchResult.data && couchResult.data.report;
        if (s3Result.data && s3Result.data.s3Report && s3Result.data.s3Report.result && s3Result.data.s3Report.result.docs){
            mergedReport.result.docs = JSON.stringify(s3Result.data.s3Report.result.docs)
        }
    }
    if (mergedReport && mergedReport.result && mergedReport.result.docs){
        if (typeof mergedReport.result.docs === 'string'){
            mergedReport.result.docs = JSON.parse(mergedReport.result.docs);
        }
    }
    console.debug('reportState', reportState);
    console.debug('couchResult', couchResult);
    console.debug('s3Result', s3Result);
    console.debug('mergedReport', mergedReport);
    
    return {
        reportData: mergedReport,
        couchResult,
        s3Result,
        reportState: reportState,
        error: couchResult?.error || s3Result?.error
    };
}

export function getReportRuntimeFromNow(reportData: any){
    if (!reportData) return undefined;
    const startMoment = moment(reportData.startTs);
    return moment().diff(startMoment, 'milliseconds') / 1000;
}

export default useReportDetails;