import { getFormFieldValue } from "common/form";
import { dateMtimeMtoMoment, getDurationHoursMinutes, getMinuteDuration, momentOrNull } from "common/util";
import { DateTimePickerValue } from "components/date-time-picker";
import moment, { Moment } from "moment";

export function formatDateTime(dt: DateTimePickerValue){
    if (!dt) return ''
    let date = dt.date ? dt.date.format('MM/DD/YYYY') : ''
    let time = dt.time ? dt.time.format('HH:mm') : ''
    return date + ' ' + time
}

export function dispatchFormDataToArray(formData: any): any[] {
    let rowIdToFormData = new Map<string, any>();
    let data = []

    Object.entries(formData).forEach((entry) => {
        let k = entry[0];
        let v: any = entry[1];

        let splitK = k.split('::');
        let id = splitK[0]
        let fieldname = splitK[1]
        if (rowIdToFormData.has(id)){
            rowIdToFormData.get(id)[fieldname] = getFormFieldValue(v)
        }
        else
        {
            rowIdToFormData.set(id, { [fieldname]: getFormFieldValue(v) })
        }
    })

    // Filter out newRecords that are not added
    rowIdToFormData.forEach((values, k) => {

        // Remove missing record entries that are not added (checkbox not checked)
        if (k.startsWith('new') && !values.add) return;

        values._id = k;
        values.new = true;

        data.push(values)
    })
    return data
}

interface DT {
    date: Moment,
    time: Moment
}

export function getTotalDurationHoursMinutes(rows: Array<{ ato: DT, ata: DT }>, momentConverter: (t: any) => Moment=dateMtimeMtoMoment){
    let totalMinutes = 0;
    rows.forEach(row => {
        if (momentConverter instanceof dateMtimeMtoMoment){
            if (!row.ata || !row.ato || !row.ata.date || !row.ata.time || !row.ato.date || !row.ato.time){
                return;
            }
        }

        totalMinutes += getMinuteDuration(momentConverter(row.ato), momentConverter(row.ata));
    })
    return getDurationHoursMinutes(moment.duration(totalMinutes, 'minutes'))
}