import React, { useRef, useState, useEffect } from 'react';
import FlightEditorForm, { FlightEditorFormProps } from './FlightEditorForm';
import { Row, Col, Button } from 'antd';

export interface FlightEditorFormWrapperProps extends FlightEditorFormProps {
    onSave?(values: any): void,
    editing?: boolean,
    onEditChange?(editing: boolean): void,
    saving?: boolean,
    disabled?: boolean,
    locationID?: string
}

const FlightEditorFormWrapper: React.FC<FlightEditorFormWrapperProps> = (props) => {
    const form = useRef(null);
    const [ editing, setEditing ] = useState(false);

    useEffect(() => {
        if (props.disabled){
            setEditing(false);
        }
    }, [ props.disabled ])

    useEffect(() => {
        if (props.onEditChange){
            props.onEditChange(editing);
        }
    // eslint-disable-next-line
    }, [ editing ])

    useEffect(() => {
        if (props.editing !== undefined){
            setEditing(props.editing);
        }
    }, [props.editing])

    const { onSave, ...restProps } = props;
    return <Row>
        <Col md={20}>
            <FlightEditorForm ref={form} locationID={props.locationID}{...restProps} />
        </Col>
        {(editing || props.onSave) && !props.disabled ? (
            <Row style={{ position: 'absolute', top: 0, right: 0 }} gutter={12} type="flex">
                {!editing ? (
                    <Col>
                        <Button onClick={() => setEditing(true)} type="primary" ghost>Edit</Button>
                    </Col>
                ) : <>
                    <Col>
                        <Button onClick={() => setEditing(false)}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button
                            loading={props.saving}
                            disabled={!props.onSave}
                            onClick={() => {
                                if (!form) return;
                                form.current.validateFieldsAndScroll((err: any, values: any) => {
                                    if (!err){
                                        props.onSave(values)
                                    }
                                })
                            }}
                            type="primary"
                        >Save</Button>
                    </Col>
                </>}
            </Row>
        ) : null}
    </Row>
}

export default FlightEditorFormWrapper