import React from 'react';
import GlobalFilter from './GlobalFilter';
import { Query } from 'react-apollo';
import GetFiltersQuery from './GetFiltersQuery';
import './Wrapper.less';

const GlobalFilterWithWrapper = (props) => (
    <Query query={GetFiltersQuery}>
    {({ data }) => {
        const visible = data && data.globalFilter && data.globalFilter.visible;
        var classNames = ['mc-global-filter-wrapper'];
        if (!visible){
            classNames.push('mc-global-filter-wrapper-hidden');
        }
        return (
            <div className={classNames.join(' ')} {...props.wrapperProps} >
                <GlobalFilter {...props} />
            </div>
        )
    }}
    </Query>
)

export default GlobalFilterWithWrapper