import { Button, Icon, Spin } from 'antd';
import { classNames } from 'common/util';
import React from 'react';
import {getISNPassFail} from "common/isn";

const buttonStyle = {
    color: 'white',
}

// TODO: Unify with getISNStatus in src/common/isn/index.tsx
const getIsnType = pax => {

    const passFail = getISNPassFail(pax);

    const reason = passFail && passFail.reason;

    switch (reason) {
        case 'disa bypass':
            return {
                children:'DISA',
                style: {
                    ...buttonStyle,
                    color: 'black',
                    backgroundColor: '#bbffff',
                    borderColor: '#bbffff'
                }
            }
        case 'green':
            return {
                children: 'GREEN',
                style: {
                    ...buttonStyle,
                    backgroundColor: '#52c41a',
                    borderColor: '#52c41a'
                }
            }
        case 'green – waived':
        case 'green waived':
            return {
                children: 'GREEN – WAIVED',
                style: {
                    ...buttonStyle,
                    backgroundColor: '#52c41a',
                    borderColor: '#52c41a'
                }
            }
        case 'red':
            return {
                children: 'RED',
                style: {
                    ...buttonStyle,
                    backgroundColor: '#f5222d',
                    borderColor: '#f5222d'
                }
            }
        case 'red – suspended':
        case 'red suspended':
            return {
                children: 'RED – SUSPENDED',
                style: {
                    ...buttonStyle,
                    backgroundColor: '#f5222d',
                    borderColor: '#f5222d'
                }
            }
        case 'multi':
            return {
                children: 'MULTIPLE RESULTS',
                style: {
                    ...buttonStyle,
                    backgroundColor: '#faad14',
                    borderColor: '#faad14'
                }
            }
        case 'overridden':
            return {
                children: 'OVERRIDDEN',
                style: {
                    ...buttonStyle,
                    backgroundColor: '#1890ff',
                    borderColor: '#1890ff'
                },
            }
        case 'whitelisted':
            return {
                children: 'WHITELISTED',
                disabled: true,
                style: {
                    ...buttonStyle,
                    backgroundColor: '#52c41a',
                    borderColor: '#52c41a'
                }
            }
        default:
            let maxQcsLen = 15;
            if (String(reason).includes('red')){
                return {
                    children: String(reason).substring(0, maxQcsLen).toUpperCase(),
                    style: {
                        ...buttonStyle,
                        backgroundColor: '#f5222d',
                        borderColor: '#f5222d'
                    }
                }
            }
            else if (String(reason).includes('green')){
                return {
                    children: String(reason).substring(0, maxQcsLen).toUpperCase(),
                    style: {
                        ...buttonStyle,
                        backgroundColor: '#52c41a',
                        borderColor: '#52c41a'
                    }
                }
            }
            else if (reason){
                // Unknown quick check status result from ISN
                return {
                    children: "UNKNOWN",
                    style: {
                        ...buttonStyle
                    }
                }
            }
            let text = 'NO ISN RESULT';
            if (pax.lastIsnResultWithHeader && pax.lastIsnResultWithHeader.error_message){
                let textStyle = {
                    color: 'red',
                    maxWidth: '5rem',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }
                text = <span style={textStyle}>{pax.lastIsnResultWithHeader.error_message}</span>
            }
            return {
                children: text
            };
    }
}
/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<any> & React.RefAttributes<unknown>>}
 */
const IsnCheckStatusButton = React.forwardRef((props, ref) => {
    let btnProps = getIsnType(props.pax);
    if (props.checking){
        return <Spin indicator={<Icon type="loading" />} />
    }

    if (props.style){
        btnProps = {
            ...btnProps,
            style: {
                ...btnProps?.style,
                ...props.style
            }
        }
    }

    // if (props.pax.lastIsnResultWithHeader && props.pax.lastIsnResultWithHeader.error_message){
    //     var errMessage = props.pax.lastIsnResultWithHeader.error_message
    // }
    return (
        <Button {...props} {...btnProps} ref={ref} className={classNames('mc-isn-status-button', btnProps?.className)} />
    )
})

export default IsnCheckStatusButton