import React from 'react';
import { Query } from 'react-apollo';
import { Alert, Spin, Icon, Button } from 'antd';

const LoadIndicator = () => {
    return <div style={{ padding: '1rem', textAlign: 'center' }}>
        <Spin indicator={<Icon type="loading" />} tip="Checking for existing records" />
    </div>
}

const Error = ({ refetch }) => {
    return <div style={{ padding: '1rem', textAlign: 'center', color: 'red' }}>
        <div><Icon type="exclamation-circle" /></div>
        <div>Could not check for existing records. <Button className="mc-link-btn" onClick={() => refetch()}>Retry?</Button></div>
    </div>
}

const DuplicationChecker = ({ variables, query, skip, getData, renderItems, renderMessage, style }) => {
    if (typeof getData !== 'function'){
        throw Error('You must pass a function to the getData prop.');
    }
    if (typeof renderItems !== 'function'){
        throw Error('You must pass a function to the renderItems prop.');
    }
    return <Query query={query} variables={variables} fetchPolicy="network-only" notifyOnNetworkStatusChange skip={skip}>
    {({ data, error, networkStatus, refetch }) => {
        if (networkStatus <= 4){
            return <LoadIndicator />
        }
        if (error){
            console.error('Duplicate Search failed!', error);
            return <Error refetch={refetch} />
        }
        if (!data) return null;
        let items = getData ? getData(data) : [];
        if (!Array.isArray(items)){
            items = [];
        }
        if (!items.length) return null;
        let messageBeginning = <span><strong>{items.length} records</strong> have been found that are</span>
        if (items.length === 1){
            messageBeginning = <span>A record has been found that is</span>
        }
        if (typeof renderMessage === 'function'){
            var message = renderMessage(items);
        }
        else
        {
            message = <p>{messageBeginning} similar to what you have entered.
            If the record that you are trying to create is in this list below, it is highly recommended that you edit the existing record by clicking an item below.
            </p>
        }
        return <Alert type="warning" message="Duplication Warning" showIcon style={style} description={
            <div>
                {message}
                {renderItems(items)}
            </div>
        } />
    }}
    </Query>
}

export default DuplicationChecker