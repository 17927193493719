import {LoadArgs, InfiniteScrollAdapter} from "../infinite-scroller";
import {QueryResult} from "react-apollo";
import {Dispatch} from "react";
import {DocumentNode} from "apollo-link";

export interface ApolloQueryAdapterArgs {
    queryResult: QueryResult<unknown>,
    query: DocumentNode,
    getLoading?: (queryResult: QueryResult<unknown>) => boolean
}

/**
 * Abstract class that implements storing the query result from an Apollo Graphql query.
 * This class does not implement pagination itself, but it standardizes the storage of the query among child classes.
 */
export class ApolloQueryAdapter<S, A> implements InfiniteScrollAdapter<S, A> {
    initState = null;
    queryResult: QueryResult<unknown> = null;
    query: DocumentNode = null;
    getLoading = (queryResult: QueryResult<unknown>) => queryResult.loading;

    constructor(args: ApolloQueryAdapterArgs) {
        this.queryResult = args.queryResult;
        this.query = args.query;
        this.getLoading = args.getLoading;
    }

    // This implementation doesn't need a state machine.
    reducer(state: S, action: A){
        return state;
    }

    /**
     * Executes when a new page needs to be fetched. This is where you should modify the query in the cache using
     * the "fetchMore" function of the QueryResult.
     * @param state Current state of state machine
     * @param dispatch Action dispatcher for state machine
     */
    onLoadMore(state: S, dispatch: Dispatch<A>){}

    getLoadArgs(state: S, dispatch: Dispatch<A>): LoadArgs {
        return {
            loading: this.getLoading(this.queryResult),
            hasNextPage: false,
            disabled: Boolean(this.queryResult.error),
            onLoadMore: () => this.onLoadMore(state, dispatch)
        };
    }
}