import { Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import useSortableData from "hooks/useSortableData";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";

const getIsnSelectedResult = (pax) => {
  const isnResult = pax.lastIsnResultWithHeader;
  if (isnResult) {
    var selectedResult = isnResult.selectedResult;
    const qc_result = isnResult.qc_result || [];

    if (qc_result.length === 1 && !selectedResult) {
      selectedResult = qc_result[0];
    }
    return selectedResult;
  }
};

const getIsnStatus = (pax) => {
    const isnResult = pax.lastIsnResultWithHeader;
    const authorization = pax.authorization;
    if (authorization){
        const brdIsn = authorization && authorization.brdRestriction_Isn;
        const isnStatus = brdIsn && brdIsn.status;
        const isOverridden = isnStatus === 'OVERRIDDEN';
        if (isOverridden){
            return 'OVERRIDDEN'
        }
    }
    if (isnResult){
        const selectedResult = getIsnSelectedResult(pax);
        const qc_result = isnResult.qc_result || [];

        const qcs = selectedResult && ('' + selectedResult.quickCheckStatus).toUpperCase();


        if (selectedResult?.reason === 'WHITELISTED'){
            return 'WHITELISTED';
        }

        if (!selectedResult && qc_result > 0){
            return 'MULTIPLE RESULTS'
        }
        if (!selectedResult && qc_result.length <= 0){
            return 'NO ISN RESULT'
        }
        return qcs
    }
    else
    {
        return 'NO ISN RESULT'
    }
}

const getISNRestriction = (pax) => {
    const authorization = pax.authorization;
    return authorization && authorization.brdRestriction_Isn;
}

const getOverrideUser = (pax) => {
    const brdIsn = getISNRestriction(pax);
    const isnStatus = brdIsn && brdIsn.status;
    const overriddenBy = brdIsn && (brdIsn.modifiedBy || brdIsn.createdBy);
    const isOverridden = isnStatus === 'OVERRIDDEN';
    if (isOverridden){
        return overriddenBy ? overriddenBy : 'Local user';
    }
}

const ISNDetail = (props) => {
    var hasTrainingDeficiencies = false;
    var hasReasonsForNonCompliance = false;
    var hasISNOverrideNotes = false;
    var hasIsnOverriderReason = false;
    const initialData = props.data.map(row => {
        const getVal = (obj, field) => obj && obj[field];
        const trainingDeficiencies = getIsnSelectedResult(row) && getIsnSelectedResult(row).trainingDeficiencies;
        const reasonsForNonCompliance = getIsnSelectedResult(row) && getIsnSelectedResult(row).reasonsForNonCompliance
        const isnRestriction = getISNRestriction(row);
        if (trainingDeficiencies){
            hasTrainingDeficiencies = true;
        }
        if (reasonsForNonCompliance){
            hasReasonsForNonCompliance = true;
        }
        if (isnRestriction && isnRestriction.notes){
            hasISNOverrideNotes = true;
        }

        if (isnRestriction && isnRestriction.overriderReason){
            hasIsnOverriderReason = true;
        }

        return {
            ...row,
            currentCarrierName: getVal(row.currentCarrier, 'desig'),
            departureName: getVal(row.departure, 'name'),
            destinationName: getVal(row.destination, 'name'),
            customerName: getVal(row.customer, 'name'),
            transporterName: getVal(row.transporter, 'name'),
            employerName: getVal(row.employer, 'name'),
            finalized: row.nodeState === 'FINALIZED',
            isnStatus: getIsnStatus(row),
            overriddenBy: getOverrideUser(row),
            overrideNotes: isnRestriction && isnRestriction.notes,
            overriderReason: isnRestriction?.overriderReason,
            reasonsForNonCompliance,
            trainingDeficiencies,
            isnId: getIsnSelectedResult(row)?.isnId,
            assignedActivities: getIsnSelectedResult(row)?.assignedActivities
        }
    })

    const [ data, { setSortKey, currentSortKey, currentSortDir } ] = useSortableData(initialData);

    const columns = [
        {
            name: 'Last Name',
            key: 'lastName'
        },
        {
            name: 'First Name',
            key: 'firstName'
        },
        {
            name: 'Check-In-Time',
            key: 'checkedInTime',
            formatter: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            name: 'Departure',
            key: 'departureName'
        },
        {
            name: 'Destination',
            key: 'destinationName'
        },
        {
            name: 'Transporter',
            key: 'transporterName'
        },
        {
            name: 'Customer',
            key: 'customerName'
        },
        {
            name: 'Employer',
            key: 'employerName'
        },
        {
            name: 'ISN Status',
            key: 'isnStatus',
            formatter: (value) => {
                const colorMap = {
                    'GREEN': 'green',
                    'RED': 'red',
                    'MULTI': 'purple',
                    'OVERRIDDEN': 'blue'
                }
                return (
                    <Tag color={colorMap[value]} style={{ cursor: 'default', pointerEvents: 'none' }}>{value}</Tag>
                )
            }
        },
        {
            name: 'ISN ID',
            key: 'isnId'
        },
        hasTrainingDeficiencies ? ({
            name: 'Training Deficiencies',
            key: 'trainingDeficiencies'
        }) : null,
        hasReasonsForNonCompliance ? ({
            name: 'Reasons For Non Compliance',
            key: 'reasonsForNonCompliance'
        }) : null,
        {
            name: 'Overriden By',
            key: 'overriddenBy'
        },
        hasISNOverrideNotes ? ({
            name: 'Override Notes',
            key: 'overrideNotes'
        }) : null,
        hasIsnOverriderReason ? ({
            name: 'Overrider Reason',
            key: 'overriderReason'
        }) : null,
        {
            name: 'Assigned Activities',
            key: 'assignedActivities'
        }
    ]
        .filter(col => col)
        .map((col) => {
            const cellRenderer = (i) => {
                const value = col.formatter ?
                    col.formatter(data[i]?.[col.key]) :
                    data[i]?.[col.key]
                return <Cell tooltip={value} style={{ display: 'flex', alignItems: 'center' }}>{value}</Cell>
            }
            return (
                <Column
                    key={col.key}
                    name={col.name}
                    cellRenderer={cellRenderer}
                    columnHeaderCellRenderer={
                        () => (
                            <SortableColumnHeaderCell
                                name={col.name}
                                sortKey={col.key}
                                onSort={setSortKey}
                                currentSortedKey={currentSortKey}
                                currentSortDir={currentSortDir}
                            />
                        )
                    }
                />
            )
        })

    const [ rowHeights, setRowHeights ] = useState(new Array(data?.length).fill(30));

    return (
        <Table
            selectionModes={SelectionModes.NONE}
            className="mc-table"
            numRows={data?.length}
            rowHeights={rowHeights}
            onRowHeightChanged={(i, size) => (
                setRowHeights(curr => [...curr.slice(0, i), size, ...curr.slice(i+1, rowHeights.length)])
            )}
        >
            {columns}
        </Table>
    );
}

export default ISNDetail