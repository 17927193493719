import { Alert, Button, Form, Icon, Input } from 'antd';
import { Auth } from '@aws-amplify/auth';
import ForceChangePassword from 'components/ForceChangePassword';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../css/Login.css';
import mcLogo from '../img/mc-logo-wide-white.svg';
import ForgotPassword from './ForgotPassword';
import Verify from './Verify';

const PAGE_NAMES = {
    Verify: 'VERIFY',
    ForgotPassword: 'FPWD',
    NewPassword: 'NPWD'
}

class SignIn extends Component {
    constructor(props){
        super(props);
        this.state = {
            error: '',
            showPage: null,
            email: '',
            loading: false,
            user: null,
            password: null
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const { email, password } = values;
                this.signIn(email, password);
            }
        });
    }

    signIn = (email, password) => {
        this.setState({ password, email });
        Auth.signIn(email, password)
            .then(user => {
                console.log(user);
                this.setState({ user });
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED'){
                    this.setState({ showPage: PAGE_NAMES.NewPassword })
                    return;
                }
                let urlBeforeLogin = localStorage.getItem('url-before-login');
                if (urlBeforeLogin){
                    localStorage.removeItem('url-before-login');
                    this.props.history.push(urlBeforeLogin);
                }
                else
                {
                    this.props.history.push('/app/passengers');
                }
            })
            .catch(err => {
                if (err.code === 'UserNotConfirmedException')
                {
                    this.setState({
                        showPage: PAGE_NAMES.Verify,
                        email: email
                    });
                }else{
                    this.setState({
                        error: err.message
                    })
                }
            })
            .then(() => this.setState({ loading: false }));
    }

    onChange = (key, value) => {
        this.setState({ [key]: value });
    }
    setForgotPassword = (bool) => {
        this.setState({ showPage: bool ? PAGE_NAMES.ForgotPassword : null });
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        if (this.state.showPage === PAGE_NAMES.ForgotPassword)
        {
            return (<ForgotPassword
                    email={this.props.form.getFieldValue('email')}
                    setforgotpassword={this.setForgotPassword}
                    onBack={() => this.setState({ showPage: null })}
                />)
        }
        else if (this.state.showPage === PAGE_NAMES.NewPassword){
            return <ForceChangePassword
                user={this.state.user}
                onBack={() => this.setState({ showPage: null })}
                onSuccess={(newPassword) => this.signIn(this.state.email, newPassword)}
                loading={this.state.loading}
            />
        }
        else if (this.state.showPage !== PAGE_NAMES.Verify)
        {
            return (
                <div className="login-page-wrapper">
                    <div className="container">
                        <img src={mcLogo} className="mc-logo" alt="Manifest Central Logo"/>
                        <div className="login-box">
                            <h2 style={{fontWeight: 100, marginBottom: '1rem'}}>Dataflyt Account Login</h2>
                            {(this.state.error) ? 
                            <Alert
                                style={{marginBottom: '1rem'}}
                                message="Login error"
                                description={this.state.error}
                                type="error"
                                closable
                                onClose={evt =>
                                    this.setState({
                                        error: ''
                                    })
                                }
                            /> : ''
                            }
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Item>
                                    {getFieldDecorator('email', {
                                        rules: [{required: true, message: 'Email is required'}]
                                    })(
                                        <Input prefix={
                                            <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
                                        } type="email" size="large" placeholder="Email" id="email" />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('password', {
                                        rules: [{required: true, message: 'Password is required'}]
                                    })(
                                        <Input prefix={
                                            <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                                        } type="password" size="large" placeholder="Password" id="password"/>
                                    )}
                                </Form.Item>
                                <Form.Item style={{marginBottom: 0}}>
                                    <Button type="primary" size="large" htmlType="submit" loading={this.state.loading} style={{width: '100%'}}>Login</Button>
                                </Form.Item>
                                <Form.Item style={{marginBottom: 0}}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <Button className="mc-link-btn" onClick={evt => this.setState({ showPage: PAGE_NAMES.ForgotPassword })}>Forgot Password</Button>
                                        <div style={{margin: '0 0.75rem'}}></div>
                                        <Link to="/register">Register New Dataflyt Account</Link>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <Verify
                    email={this.state.email}
                    password={this.state.password}
                    redirectTo="/app"
                />
            )
        }
        
    }
}

export default Form.create()(SignIn);
