import React from 'react';
import { Card, Row, Col } from 'antd';
import { Statistic } from 'antd';

const PaxIsnEventsSummary = ({ data }) => {
    const override_count = data.override_count.value || 0;
    const non_green_count = data.non_green_count.value || 0;
    const overall_count = (data.overall_count && data.overall_count.value) || 0;
    return (
        <Row type="flex" gutter={12}>
            <Col md={24} lg={6} style={{ marginBottom: 12 }}>
                <Card>
                    <Statistic title="Overall Events" value={overall_count} />
                </Card>
            </Col>
            <Col md={24} lg={6} style={{ marginBottom: 12 }}>
                <Card>
                    <Statistic title="Overrides" value={override_count} />
                </Card>
            </Col>
            <Col md={24} lg={6} style={{ marginBottom: 12 }}>
                <Card>
                    <Statistic title="Non-Green ISN Status" value={non_green_count} />
                </Card>
            </Col>
        </Row>
    )
}
 
export default PaxIsnEventsSummary;