import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, Alert } from 'antd';
import debounce from 'lodash/debounce';

const { Option } = Select;

class SearchSelect extends Component {
    constructor(props){
        super(props);
        this.handleSearch = debounce(this.handleSearch, props.searchDebounceTime || 500)
    }
    state = {
        value: ''
    }
    getValue = () => {
        if (this.props.loading || this.props.error) return null
        if (this.props.value){
            if (this.state.value !== this.props.value){
                this.setState({ value: this.props.value });
            }
        }else if(this.props.defaultValue){
            if (this.state.value !== this.props.defaultValue){
                this.setState({ value: this.props.defaultValue });
            }
        }
        return this.state.value
    }
    handleSearch = (value) => {
        this.props.onSearch(value)
    }
    handleChange = (value) => {
        this.setState({ value: value })
        this.props.onChange && this.props.onChange(value)
    }
    render() {
        var {
            onDataItem,
            loading,
            error,
            errorMessage="Failed to load data",
            ...restProps
        } = this.props;
        if (!onDataItem){
            onDataItem = (item) => <Option key={item.value}>{item.text}</Option>
        }
        const options = this.props.data.map(onDataItem);
        var nfContent = null;
        if (!options.length){
            if (loading) nfContent = <Spin />
            if (error) nfContent = <Alert type="error" banner showIcon message={errorMessage} />
        }
        return (
            <Select
                showSearch
                placeholder={this.props.placeholder}
                style={this.props.style}
                defaultActiveFirstOption={true}
                showArrow={true}
                filterOption={false}
                onSearch={this.props.onSearch}
                onChange={this.props.onChange}
                allowClear={true}
                notFoundContent={nfContent}
                showAction={["focus", "click"]}
                {...restProps}
            >
                {options}
            </Select>
        );
    }
}

SearchSelect.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onDataItem: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.object,
    errorMessage: PropTypes.string
}
 
export default SearchSelect;