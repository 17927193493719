import { useState } from 'react';
import config from '../../../config';
import { compressSync, strToU8 } from "fflate";
import { bytesToBase64 } from "byte-base64";

const endpoint = config.manifestPrintoutEndpoint;

// const useFetchState = (defaultState={ fetching: false, error: null }) => {
//     const [fetchState, setFetchState] = useState({ fetching: defaultState.fetching, error: defaultState.error })
//     return [
//         fetchState,
//         (isFetching) => setFetchState({ ...fetchState, fetching: isFetching }),
//         (err) => setFetchState({ ...fetchState, error: err })
//     ]
// }

function useManifestPrintout(type, layout){
    const url = `${endpoint}/${type}/${layout}`;

    // const [ { fetching, error }, setFetching, setError ] = useFetchState();
    const [ fetching, setFetching ] = useState(false);
    const [ error, setError ] = useState(null);

    const fetchPrintout = async function(body, autoDownload=false, fileName){
        setFetching(true);
        setError(null);
        var resp;

        // Compress the request body with gzip
        const buffer = strToU8(JSON.stringify(body));
        const compressed = compressSync(buffer);

        try{
            // Send request to printout microservice
            resp = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/octet-stream',
                    'X-Content-Encoding': 'gzip;base64'
                },
                body: bytesToBase64(compressed)
            })
        }
        catch(err){
            console.error(err);
            setFetching(false);
            setError(err);
            throw err
        }
        setFetching(false);
        if (resp.ok){
            const blob = await resp.blob();
            const objURL = URL.createObjectURL(blob);
            if (autoDownload){
                const link = document.createElement('a');
                link.href = objURL;
                link.setAttribute('download', fileName || 'download.txt');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
            return objURL
        }
        else {
            var msg;
            try{
                const json = await resp.json();
                msg = json.Message;
            }
            catch(err){
                msg = err.message
            }
            await setError(new Error(msg))
            throw error
        }

    }

    function clearError(){
        setError(null);
    }

    return [ fetchPrintout, fetching, error, clearError ]
}

export default useManifestPrintout