import { AvailableNotifier } from "common/types/available-notifier"
import gql from "graphql-tag"
import { QueryHookOptions, useQuery } from "react-apollo"

interface ListAvailableNotifiersResp {
    availableNotifiers: AvailableNotifier[],
    availableNotifierCount: number
}

interface ListAvailableNotifiersData {
    ListAvailableNotifiers: ListAvailableNotifiersResp
}

const query = gql`
query ListAvailableNotifiers($filter: String){
    ListAvailableNotifiers(filter: $filter){
        availableNotifiers {
            notificationType,
            description,
            category,
            triggeringDataType
        }
        availableNotifierCount
    }
}
`

export interface ListAvailableNotifiersVariables {
    filter?: string
}

function useListAvailableNotifiers(
    queryOptions?: QueryHookOptions<ListAvailableNotifiersData, ListAvailableNotifiersVariables>
) {
    const { data, ...rest } = useQuery<ListAvailableNotifiersData, ListAvailableNotifiersVariables>(query, {
        ...queryOptions,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
    });

    let notifiers = data?.ListAvailableNotifiers;

    return {
        notifiers: notifiers,
        ...rest
    }
}

export default useListAvailableNotifiers