import { Alert, Button, Icon, Layout, Popover, Spin, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { capitalize, capitalizeWords, castArray, cleanGraphQLErrorMsg, getPersonFullName } from 'common/util';
import ETable from 'components/enchanced-antd-table';
import { OrgDataContext } from 'context/orgData';
import gql from 'graphql-tag';
import React, { HTMLAttributes, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import useFbShortcuts from './fb-shortcut-listener';
import './flightboard-page.less';
import moment from 'moment';
import useAutoPaginatedQuery from 'hooks/apollo-extensions/useAutoPaginatedQuery';
import { fetchMoreAndGetToken } from 'components/live-2/util';
import { NetworkStatus } from 'apollo-client';
import ShortcutDescriptions from './shortcut-descriptions';

const POLL_INTERVAL = 10000;

const { Header, Footer } = Layout;

const { Title, Text } = Typography;

const BASE_CLS = 'mc-flightboard';

export interface FlightboardPageProps {
    locationID: string
}

const QUERY = gql`
query GetPax(
    $filter: PassengersFilterInput
    $bookmark: String
    $limit: Int
    $skip: Int
){
    passengers(
        filter: $filter
        bookmark: $bookmark
        limit: $limit
        skip: $skip
    ) {
        bookmark
        docs {
            _id,
            ... on PaxNode {
                lastName,
                firstName,
                personID {
                    _id
                    extraBroadState
                }
                customerID {
                    _id
                    name
                }
                employerID {
                    _id
                    name
                },
                tpID {
                    _id
                    name
                }
                paxWeight,
                bagWeight,
                checkInType,
                bagCount,
                checkedInTime,
                scheduledFlightDate,
                destinationID {
                    _id, name
                }
                departureID {
                    _id, name
                },
                transitType
                lastIsnResultWithHeader {
                    success,
                    qc_result {
                        isnStatus {
                            shortLabel
                        }
                        quickCheckStatus
                        errorMessage
                        reason
                    }
                    selectedResult {
                        isnStatus {
                            shortLabel
                        }
                        quickCheckStatus,
                        errorMessage
                        reason
                    }
                },
                authorization {
                    onWhiteList
                    noFlyListed
                    brdRestriction_Isn {
                        status
                    }
                    brdRestriction_SafetyCard {
                        status
                    }
                    brdRestriction_HuetCard {
                        status
                    }
                    brdRestriction_SteelToes {
                        status
                    }
                    brdRestriction_Twic {
                        status
                    }
                    brdRestriction_NonPreferred {
                        status
                    }
                    brdRestriction_Hazmat {
                        status
                    }
                    brdRestriction_NoFlyListed {
                        status
                    }
                    brdRestriction_EbsCard {
                        status
                    }
                    brdRestriction_Illness {
                        status
                    }
                    brdRestriction_Quarantined {
                        status
                    }
                }
                currentCarrierID {
                    _id
                    desig
                    state
                    estDepartureTime
                }
            }
        }
    }
}`;

const FBDataContext = React.createContext({ rows: [], currTime: null });

class FBTableRow extends React.Component<HTMLAttributes<HTMLTableRowElement>> {
    render(): React.ReactNode {
        return <FBDataContext.Consumer>
            {({ rows, currTime }) => {
                let dataRowKey = this.props['data-row-key'];
                let row = rows.find((row) => row._id === dataRowKey);
                if (!row || !row.currentCarrierID || !row.currentCarrierID.estDepartureTime){
                    return <tr className="ant-table-row">{this.props.children}</tr>
                }

                let estTime = moment(row.currentCarrierID.estDepartureTime, 'HH:mm:ss');
                let currTimeMoment = moment(currTime, 'MM/DD/YYYY HH:mm');
                let className = '';
                if (currTimeMoment.isAfter(moment(estTime).subtract(30, 'minutes').subtract(1, 'minute'))){
                    className = BASE_CLS + '-est-in-30-minutes';
                }
                else if (currTimeMoment.isAfter(moment(estTime).subtract(1, 'hour').subtract(1, 'minute'))){
                    className = BASE_CLS + '-est-in-1-hour';
                }
                else if (currTimeMoment.isAfter(moment(estTime).subtract(2, 'hour').subtract(1, 'minute'))){
                    className = BASE_CLS + '-est-in-2-hours';
                }
                return <tr className={"ant-table-row " + className}>{this.props.children}</tr>
            }}
            </FBDataContext.Consumer>
    }
}

const FlightboardPage: React.FC<FlightboardPageProps> = (props) => {
    const { scrollSpeed, autoScrollEnabled, setAutoScroll } = useFbShortcuts();
    const [ currTime, setCurrTime ] = useState("");
    const logoAltRef = useRef(null);

    function updateCurrTime(currTime?: string): string {
        let newCurrTime = moment().format('MM/DD/YYYY HH:mm');
        if (currTime !== newCurrTime){
            setCurrTime(newCurrTime);
            return newCurrTime;
        }
        return currTime
    }

    useEffect(() => {
        updateCurrTime();

        let currTime = '';

        // Repeat updateCurrTime every second
        let interval = window.setInterval(() => {
            currTime = updateCurrTime(currTime);
        }, 1000);

        return () => {

            // On unmount
            window.clearInterval(interval);
        }
    }, [])

    const orgData = useContext(OrgDataContext);

    const queryVars = {
        filter: {
            transporter: orgData.transporterID,
            onlyShowLive: true,
            transitTypes: ['OUTBOUND'],
            checkInTypes: ['CSR', 'KIOSK', 'LIVE_EDIT', 'LOCKED'],
            departures: [props.locationID]
        }
    }

    const { data: locData, loading: locDataLoading } = useQuery(gql`
        query GetLocationName($locID: ID!){
            location: getLocation(_id: $locID){
                _id
                name
            }
        }
    `, {
        variables: {
            locID: props.locationID
        },
        fetchPolicy: 'cache-first'
    });

    const { data, networkStatus, error, refetch, initialPaginationRunning, paginating, paginationError, fetchMore, startPolling, stopPolling } = useAutoPaginatedQuery<any, any>(QUERY, {
        variables: queryVars,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        supressDataUpdates: true,
        // onError: () => dispatch({ type: TYPES.OPEN_ERROR_MSG }),
        // onPaginationError: () => dispatch({ type: TYPES.OPEN_ERROR_MSG }),
        getNextToken: async (token) => await fetchMoreAndGetToken(fetchMore, queryVars, token, 'passengers'),
        getTokenFromData: (data) => {
            let token = data?.passengers?.bookmark;
            if (token === 'nil'){
                token = null;
            }
            return token;
        }
    })

    const rows = castArray(data?.passengers?.docs)
        .sort((a, b) => getPersonFullName(a).localeCompare(getPersonFullName(b)));
    const loc = locData?.location;

    const columns: ColumnProps<any>[] = [
        {
            title: 'PASSENGER NAME',
            key: 'paxName',
            render: (_, record) => getPersonFullName(record)
        },
        {
            title: 'ASSIGNED FLIGHT',
            key: 'assignedFlight',
            dataIndex: 'currentCarrierID',
            render: (carrier) => carrier?.desig
        },
        {
            title: 'DEPARTURE TIME',
            key: 'departureTime',
            dataIndex: 'currentCarrierID',
            render: (carrier) => {
                if (!carrier?.estDepartureTime){
                    return null;
                }
                let estTime = moment(carrier.estDepartureTime, 'HH:mm:ss');
                return <span>{estTime.format('HH:mm')}</span>;
            }
        },
        {
            title: 'DESTINATION',
            key: 'destination',
            dataIndex: 'destinationID',
            render: (dest) => dest?.name
        }
    ]

    return <Layout className={BASE_CLS}>
        <Header className={BASE_CLS + "-header"}>
            <span className={BASE_CLS + '-company-logo-wrapper'}>
                <img
                    className={BASE_CLS + '-company-logo'}
                    src={`/company-logos/${orgData.transporterID}.jpg`}
                    onError={(e: any) => {
                        e.target.style.display = 'none';
                        logoAltRef.current.style.display = 'inline-block' ;
                    }}
                />
                <div ref={logoAltRef} className={BASE_CLS + '-company-logo-alt'} style={{ display: 'none' }}>
                    <span>{orgData.transporter?.name}</span>
                </div>
            </span>
            <Title className={BASE_CLS + '-title'} level={2}>{locDataLoading ? <Spin indicator={<Icon type='loading' />} /> : capitalizeWords(loc?.name, true, /^([A-Z])+[0-9]+/)} Departures</Title>
            <img className={BASE_CLS + '-df-logo'} src="/df-logos/powered-by-dataflyt-logo-white.svg" />
        </Header>
        {error || paginationError ? (
            <Alert
                type='error'
                showIcon
                message="An error has occurred"
                description={cleanGraphQLErrorMsg(error?.message || paginationError?.message)}
                className={BASE_CLS + '-error-alert'}
            />
        ) : null}
        <div className={BASE_CLS + '-table-wrapper'}>
            <FBDataContext.Provider value={{ rows, currTime }}>
                <ETable
                    size="small"
                    className="mc-table borderless"
                    pagination={false}
                    rowKey={record => record._id}
                    dataSource={rows}
                    columns={columns}
                    flexMode
                    stickyHeader
                    groupByColKey='lastName'
                    transformGroupValue={(lastName: string) => lastName?.charAt(0)}
                    components={{
                        body: {
                            row: FBTableRow
                        }
                    }}
                    groupHeaderType={(groupValue, _, idx) => <tbody className='ant-table-tobdy'>
                        <tr>
                            <th
                                className={BASE_CLS + '-group-letter'}
                                colSpan={4}
                                style={{ borderTop: idx === 0 ? 'none' : undefined }}
                            >{String(groupValue).toUpperCase()}</th>
                        </tr>
                    </tbody>}
                    autoScroll={{
                        hideIdleMouse: true,
                        enabled: autoScrollEnabled,
                        speed: scrollSpeed,
                        onScrollReachEnd: () => refetch && refetch(),
                        onScrollNeeded: (needed) => {
                            if (needed){
                                console.debug('Scrolling is needed. Disabling polling.');
                                stopPolling();
                            }
                            else
                            {
                                console.debug('Scrolling is not needed. Enabling polling.');
                                startPolling(POLL_INTERVAL);
                            }
                        }
                    }}
                    loading={{
                        spinning: initialPaginationRunning,
                        indicator: <Icon type="loading" />,
                        size: 'large'
                    }}
                />
            </FBDataContext.Provider>
        </div>
        <Footer className={BASE_CLS + "-footer"}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <Popover trigger={'hover'} content={<ShortcutDescriptions />} placement="topRight">
                    <div className={BASE_CLS + '-controls'} style={{ marginRight: '6px' }}>Controls</div>
                </Popover>
                <Button className={`${BASE_CLS + '-as-btn'} mc-btn-transparent mc-btn-transparent-with-border`} style={{ marginRight: '12px' }} onClick={() => setAutoScroll(!autoScrollEnabled)}>
                    Auto Scroll:{autoScrollEnabled ? <span className={BASE_CLS + '-as-on'}>ON</span> : <span className={BASE_CLS + '-as-off'}>OFF</span>}
                </Button>
                {paginating || networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.fetchMore ? (
                    <span className={BASE_CLS + '-data-refresh-indicator'}>Refreshing data...</span>
                ) : null}
            </div>
            <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
                <Title level={2}>{currTime}</Title>
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <span className={BASE_CLS + '-depart-legend'}>
                    <span className={BASE_CLS + '-depart-legend-title'}>DEPARTURE TIME:</span>
                    <span className={BASE_CLS + '-est-in-1-hour'}>WITHIN 60 MINUTES</span>
                    <span className={BASE_CLS + '-est-in-30-minutes'}>WITHIN 30 MINUTES</span>
                </span>
            </div>
        </Footer>
    </Layout>
}

export default FlightboardPage