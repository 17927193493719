import React, { ReactNode, ReactElement, HTMLAttributes } from 'react';
import './style.less';
import { TabsProps } from 'antd/lib/tabs';
import { classNames } from 'common/util';
import cn from 'classnames';

export interface LiveHeaderProps {
    title?: ReactNode,
    titleWrapperProps?: HTMLAttributes<HTMLSpanElement>,
    subTitle?: ReactNode,
    tabs?: ReactElement<TabsProps>,
    leftContent?: ReactNode | ReactNode[],
    middleContent?: ReactNode | ReactNode[],
    rightContent?: ReactNode | ReactNode[],
    verticallyCenterTitle?: boolean
}

const baseClassName = "mc-live-header"

const LiveHeader: React.FC<LiveHeaderProps> = (props) => {

    function renderContent(content: ReactNode | ReactNode[]){
        let contentItem = [];
        if (Array.isArray(content)){
            contentItem = content;
        }
        else if (content)
        {
            contentItem.push(content);
        }
        else
        {
            return null;
        }

        if (contentItem.length <= 0){
            return null
        }

        return contentItem.map((item: React.ReactNode, idx: number) => (
            <div key={`item-${idx}`} className={
                cn({
                    [`${baseClassName}-contain-item`]: true,
                    'no-content': item ? false : true
                })
            }>{item}</div>
        ))
    }

    let titleWrapperClassName = `${baseClassName}-title-wrapper ${baseClassName}-contain-item`;;

    if (props.verticallyCenterTitle){
        titleWrapperClassName = classNames(titleWrapperClassName, baseClassName + '-title-wrapper-centered');
    }

    let titleWrapperContent = []
    
    if (props.title){
        titleWrapperContent.push(
            <span className={`${baseClassName}-title`}>{props.title}</span>
            )
        }
        if (props.subTitle){
            titleWrapperContent.push(
                <span className={`${baseClassName}-subtitle`}>{props.subTitle}</span>
            )
        }
    
    let titleWrapper = null;
    if (titleWrapperContent.length > 0){
        titleWrapper = <div {...props.titleWrapperProps} className={classNames(titleWrapperClassName, props.titleWrapperProps?.className)}>
            {titleWrapperContent}
        </div>
    }

    let tabs = null;
    if (props.tabs){
        tabs = <div className={`${baseClassName}-tabs`}>{props.tabs}</div>
    }

    let leftContent = renderContent(props.leftContent);
    let middleContent = renderContent(props.middleContent);
    let rightContent = renderContent(props.rightContent);

    return <div className={baseClassName}>
        <div className={baseClassName + '-contain-wrapper'}>
            <div className={`${baseClassName}-contain-left ${(!leftContent) ? 'no-content' : ''}`}>
                {titleWrapper}
                {tabs}
                <div className={`${baseClassName}-contain-padded`}>
                    {leftContent}
                </div>
            </div>
            {props.middleContent ? (
                <div className={`${baseClassName}-contain-middle ${(!middleContent) ? 'no-content' : ''}`}>
                    <div className={`${baseClassName}-contain-padded`}>
                        {middleContent}
                    </div>
                </div>
            ) : null}
            <div className={`${baseClassName}-contain-right ${(!rightContent) ? 'no-content' : ''}`}>
                <div className={`${baseClassName}-contain-padded`}>
                    {rightContent}
                </div>
            </div>
        </div>
    </div>;
}

export default LiveHeader
