import gql from "graphql-tag";

export default gql`
query PobQuery(
    $_id: ID!
){
    getPobNode(
        _id: $_id
    ){
        _id
        firstName
        lastName
        lifeBoatNumber
        offshoreCheckIn
        shiftTimes{
            clockIn
            clockOut
        }
        customerID{
            _id
            name
        }
        employerID{
            _id
            name
        }
        personID{
            _id
            dob
            emergencyContactInfo{
                phone{
                    number
                }
                name{
                    firstName
                    lastName
                }
                email{
                    address
                }
                address{
                    street
                    city
                    state
                    zipCode
                }
            }
            contactInfo{
                phone{
                    number
                }
                email{
                    address
                }
                address{
                    street
                    city
                    state
                    zipCode
                }
            }
        }
    }
}
`