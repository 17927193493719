import {ColumnProps} from "antd/lib/table";
import {ColumnConfig} from "./index";
import {RegisterNodeFunction} from "../util";
import React from 'react';

function createTitleKey(column: ColumnProps<any>){
    return 'title-' + column.key;
}

function createRenderKey(column: ColumnProps<any>, rowIndex: number){
    return 'data-' + column.key + '-row-' + rowIndex
}

/**
 * Converts an AntD column's props into {@link ColumnConfig}.
 * @param column AntD Column
 * @param getNodeTextFunc Callback from {@link reactNodeTextGetter.getNodeText}
 */
export function convertAntdColumn<T=any>(column: ColumnProps<T>, getNodeTextFunc: (nodeId: string) => string): ColumnConfig {

    let titleId = createTitleKey(column);
    let titleText = getNodeTextFunc(titleId);

    return {
        name: titleText,
        path: column.dataIndex ? [column.dataIndex] : [],
        render: column.render ? (value: any, row: any, index: number) => {
            const renderId = createRenderKey(column, index);
            return getNodeTextFunc(renderId);
        } : null
    }
}

/**
 * Registers an AntD column component in the {@link reactNodeTextGetter} function.
 * @param column AntD column.
 * @param registerNode Callback provided from {@link reactNodeTextGetter.registerNode}
 */
export function registerAntdColumn<T=any>(column: ColumnProps<T>, registerNode: RegisterNodeFunction){

    let title = column.title;
    title = registerNode(title, createTitleKey(column));

    let enhancedRender: (value: any, record: any, index: any, ...rest: any[]) => React.ReactNode | undefined;

    // We have to cast this to an any for now. Some of the columns defined in the src/common/table/columns.js pass a fourth
    // parameter for onClick events that the Ant-Design ColumnProps.render interface does not support. (used by 'isnStatus' and 'restrictions' columns.
    const renderFunc = column.render as any;

    if (renderFunc){
        enhancedRender = (value: any, record: any, index: any, ...rest) => {
            const node = renderFunc(value, record, index, ...rest);
            return registerNode(node, createRenderKey(column, index));
        };
    }


    return {
        ...column,
        title: title,
        render: enhancedRender
    }
}