import { Auth } from "@aws-amplify/auth";
import gql from "graphql-tag";
import defaultState from "../defaultState";
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import config from "../config";
import { readableStreamToBuffer } from "common/util-ts";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Mutation: {
        appLoaded: (_, {payload}, { cache }) => {
            const appLoaded = payload ? true : false;
            cache.writeData({
                data: {
                    appLoaded
                }
            });
            return appLoaded
        },
        setPaxSetsOwnerFilter: (_, {payload}, {cache}) => {
            cache.writeData({
                data: {
                    userFilter: {
                        paxSets: {
                            owner: payload,
                            __typename: 'ScheduleFilter'
                        },
                        __typename: 'UserFilter'
                    }
                }
            })
            return payload
        },
        setScheduledPaxOwnerFilter: (_, {payload}, {cache}) => {
            cache.writeData({
                data: {
                    userFilter: {
                        scheduledPax: {
                            owner: payload,
                            __typename: 'ScheduleFilter'
                        },
                        __typename: 'UserFilter'
                    }
                }
            })
            return payload
        },
        setScheduledPaxDateRangeFilter: (_, {startDate, endDate}, {cache}) => {
            cache.writeData({
                data: {
                    userFilter: {
                        scheduledPax: {
                            startDate,
                            endDate,
                            __typename: 'ScheduleFilter'
                        },
                        __typename: 'UserFilter'
                    }
                }
            })
        },
        setScheduledFlightsDateRangeFilter: (_, {startDate, endDate}, {cache}) => {
            cache.writeData({
                data: {
                    userFilter: {
                        scheduledFlights: {
                            startDate,
                            endDate,
                            __typename: 'ScheduleFilter'
                        },
                        __typename: 'UserFilter'
                    }
                }
            })
        },
        setGlobalFilter: (_, args, { cache }) => {
            const query = gql`
            {
                globalFilter @client{
                    visible
                    customer {
                        key
                        label
                    }
                    transporter {
                        key
                        label
                    }
                    departure {
                        key
                        label
                    }
                    destination {
                        key
                        label
                    }
                    employer {
                        key
                        label
                    }
                    pax {
                        lastName
                        firstName
                    }
                    cgo {
                        name
                    }
                }
            }`
            // const { globalFilter } = cache.readQuery({query});
            var globalFilter;
            try{
                const data = cache.readQuery({query});
                globalFilter = data.globalFilter;
            }
            catch(error){
                if (error.message.includes("Can't find field")){
                    console.error(error);
                    console.warn("Failed to query global filter due to the error above. Continuing using default global filter parameters.");
                    globalFilter = defaultState.globalFilter;
                }else{
                    throw error
                }
            }
            const newGlobalFilter = {
                ...globalFilter,
                ...args
            };
            cache.writeData({
                data: {
                    globalFilter: newGlobalFilter
                }
            });
            cache.writeQuery({
                query: gql`
                {
                    getGlobalFilter @client{
                        visible
                        customer {
                            key
                            label
                        }
                        transporter {
                            key
                            label
                        }
                        departure {
                            key
                            label
                        }
                        destination {
                            key
                            label
                        }
                        employer {
                            key
                            label
                        }
                        pax {
                            lastName
                            firstName
                        }
                        cgo {
                            name
                        }
                    }
                }
                `,
                data: {
                    getGlobalFilter: newGlobalFilter
                }
            });
            return newGlobalFilter
        },
        resetGlobalFilter: (_, __, { cache }) => {
            const { globalFilter, customer, transporter, employer } = cache.readQuery({
                query: gql`
                {
                    globalFilter {
                        visible
                        customer {
                            key
                            label
                        }
                        transporter {
                            key
                            label
                        }
                    }
                    customer {
                        _id
                        name
                    }
                    transporter {
                        _id
                        name
                    }
                    employer {
                        _id
                        name
                    }
                }
                `
            });
            const newGlobalFilter = {
                ...defaultState.globalFilter,
                visible: globalFilter.visible,
                customer: globalFilter.customer && customer && globalFilter.customer.key === customer._id ? globalFilter.customer : customer ? {
                    key: customer._id,
                    label: customer.name,
                    __typename: 'SelectItem'
                } : null,
                transporter: globalFilter.transporter && transporter && globalFilter.transporter.key === transporter._id ? globalFilter.transporter : transporter ? {
                    key: transporter._id,
                    label: transporter.name,
                    __typename: 'SelectItem'
                } : null,
                employer: globalFilter.employer && globalFilter.employer.length > 0 && employer && globalFilter.employer[0].key === employer._id ? globalFilter.employer : employer ? [{
                    key: employer._id,
                    label: employer.name,
                    __typename: 'SelectItem'
                }] : null
            }
            cache.writeData({
                data: {
                    globalFilter: newGlobalFilter
                }
            });
            return newGlobalFilter
        },
        setPaginationLimit: (_, { limit }, { cache }) => {
            cache.writeData({
                data: {
                    paginationLimit: limit
                }
            })
            return limit
        },
        tableSettings: async (_, { name, action, payload }, { cache }) => {
            let query = gql`
            {
                tableSettings @client {
                    name
                    settings {
                        visibleColumns
                    }
                }
            }
            `

            function getOrCreateTableSettings(tableSettings, name, defaultSettings){
                let settingsItem = tableSettings.find((item) => item.name === name);
                if (!settingsItem){
                    settingsItem = {
                        name,
                        settings: defaultSettings
                    }
                    tableSettings.push(settingsItem);
                }
                Object.entries(settingsItem.settings).forEach(([ settingName, settingValue ]) => {
                    if (defaultSettings && defaultSettings[settingName] && !settingValue){
                        settingsItem.settings[settingName] = defaultSettings[settingName];
                    }
                })
                return settingsItem;
            }

            function setOrCreateTableSettings(tableSettings, name, settings){
                let settingsItem = tableSettings.find((item) => item.name === name);
                if (!settingsItem){
                    settingsItem = {
                        name,
                        settings,
                        __typename: 'TableSettings'
                    }
                    tableSettings.push(settingsItem);
                }
                else
                {
                    settingsItem.settings = { ...settingsItem.settings, ...settings };
                }

                return tableSettings
            }
            let tableSettings, settingsItem, newTableSettings;
            tableSettings = cache.readQuery({ query }).tableSettings;

            if (!tableSettings){
                await cache.writeData({
                    data: {
                        tableSettings: defaultState.tableSettings
                    }
                })
                tableSettings = defaultState.tableSettings;
            }

            switch (action) {
                case 'SHOW_COLUMN':
                    settingsItem = getOrCreateTableSettings(tableSettings, name, { visibleColumns: [] });
                    newTableSettings = setOrCreateTableSettings(tableSettings, name, {
                        __typename: 'TableSettings',
                        visibleColumns: [ ...settingsItem.settings.visibleColumns, payload ]
                    });

                    cache.writeData({
                        data: {
                            tableSettings: newTableSettings
                        }
                    })
                    return newTableSettings
                case 'REMOVE_COLUMN':
                    settingsItem = getOrCreateTableSettings(tableSettings, name, { visibleColumns: [] });
                    newTableSettings = setOrCreateTableSettings(tableSettings, name, {
                        visibleColumns: settingsItem.settings.visibleColumns.filter(colName => colName !== payload)
                    });

                    cache.writeData({
                        data: {
                            tableSettings: newTableSettings
                        }
                    })
                    return newTableSettings
                default:
                    return tableSettings;
            }
        }
    },
    Query: {
        cognitoUser: async () => {
            const user = await Auth.currentAuthenticatedUser()
            return JSON.stringify(user)
        },
        getGlobalFilter: (_, __, { cache }) => {
            const query = gql`
            {
                globalFilter @client{
                    visible
                    customer {
                        key
                        label
                    }
                    transporter {
                        key
                        label
                    }
                    departure {
                        key
                        label
                    }
                    destination {
                        key
                        label
                    }
                    employer {
                        key
                        label
                    }
                    pax {
                        lastName
                        firstName
                    }
                    cgo {
                        name
                    }
                }
            }`

            var globalFilter;
            try{
                const data = cache.readQuery({query});
                globalFilter = data.globalFilter;
            }
            catch(error){
                if (error.message.includes("Can't find field")){
                    console.error(error);
                    console.warn("Failed to query global filter due to the error above. Continuing using default global filter parameters.");
                    globalFilter = defaultState.globalFilter;
                }else{
                    throw error
                }
            }
            return globalFilter
        },
        s3Report: async (_, { id, encoding, fileext }) => {
            try {
                if (!encoding) { encoding = 'base64' }
                
                const user = await Auth.currentAuthenticatedUser();
                const s3 = new S3Client({
                    region: config.amplify.Auth.region,
                    credentials: await Auth.currentCredentials()
                });
                const comm = new GetObjectCommand({
                    Bucket: config.reportS3Bucket,
                    Key: `cognito/${user.attributes.sub}/${id}.${fileext || 'report'}`
                })
                const resp = await s3.send(comm);
                const contentBuf = await readableStreamToBuffer(resp.Body);
                let body;
                if (encoding == 'base64'){
                    body = atob(contentBuf.toString('utf-8'));
                    body = JSON.parse(body);
                }
                else
                {
                    body = JSON.parse(contentBuf.toString('utf-8'));
                }
                return body;
            }
            catch(error){
                console.error(error);
                throw error
            }
        },
        tableSettings: (_, __, { cache }) => {
            let { tableSettings } = cache.readQuery(gql`
            {
                tableSettings @client {
                    name
                    settings {
                        visibleColumns
                    }
                }
            }`);

            if (!tableSettings){
                tableSettings = defaultState.tableSettings;
                cache.writeData({
                    data: {
                        tableSettings: defaultState.tableSettings
                    }
                })
            };
            return tableSettings;
        }
        // registeredUsers: async () => {
        //     const session = await Auth.currentSession();
        //     let result = await fetch('https://d7ktrukiyg.execute-api.us-east-1.amazonaws.com/dev/users', {
        //         method: 'get',
        //         headers: {
        //             'Authorization': session.getIdToken().getJwtToken(),
        //             'Access-Control-Allow-Origin': '*'
        //         }
        //     })
        //     let json = await result.json();
        //     for (let user of json.users){
        //         console.log(typeof user);
        //         user.mappedAttributes = {};
        //         for (let attr of user.Attributes){
        //             user.mappedAttributes[attr.Name] = attr.Value; 
        //         }
        //     }
        //     return json.users;
        // }
    }
}