import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React, { CSSProperties } from 'react';
import InputAddress, { validator as addressValidator } from './InputAddress';
import InputEmail from './InputEmail';
import InputName from './InputName';
import { InputPhone } from './InputPhone';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
    style: {
        marginBottom: 0
    }
};

export interface ContactInfoFormProps extends FormComponentProps {
    defaultValues?: any,
    removeFields?: Array<any>,
    style?: CSSProperties,
    formValues?: any,
    onFieldsChange?(fields: any, changed: any): void,
    onValuesChange?(values: any, changed: any): void
}

const ContactInfoForm: React.FC<ContactInfoFormProps> = (props) => {
    const { getFieldDecorator } = props.form;
    let { defaultValues={} } = props;
    if (defaultValues == null){
        defaultValues = {};
    }
    const { removeFields=[] } = props;

    const fieldRemoved = (element) => {
        return !removeFields.includes(element.key);
    }

    let fields = [
        <Form.Item key="name" label="Name">
        {getFieldDecorator('name', {
            initialValue: defaultValues.name
        })(<InputName />)}
        </Form.Item>,
        <Form.Item key="address" label="Address">
            {getFieldDecorator('address', {
                initialValue: defaultValues.address,
                rules: [
                    {
                        validator: addressValidator
                    }
                ]
            })(<InputAddress />)}
        </Form.Item>,
        <Form.Item key="email" label="E-mail">
            {getFieldDecorator('email', {
                initialValue: defaultValues.email,
                rules: [
                    {
                        validator: InputEmail.validator
                    }
                ]
            })(<InputEmail />)}
        </Form.Item>,
        <Form.Item key="phone" label="Phone">
            {getFieldDecorator('phone', {
                initialValue: defaultValues.phone,
                rules: [
                    {
                        validator: InputPhone.validator
                    }
                ]
            })(<InputPhone />)}
        </Form.Item>
    ]

    fields = fields.filter(fieldRemoved);

    return <Form {...formItemLayout} style={props.style}>
        {fields}
    </Form>
}

export default Form.create<ContactInfoFormProps>({
    mapPropsToFields(props){
        if (!props.formValues) return null
        return {
            name: Form.createFormField(props.formValues.name),
            address: Form.createFormField(props.formValues.address),
            email: Form.createFormField(props.formValues.email),
            phone: Form.createFormField(props.formValues.phone)
        }
    },
    onFieldsChange(props, changed, fields){
        if (typeof props.onFieldsChange === 'function'){
            props.onFieldsChange(fields, changed);
        }
    },
    onValuesChange(props, changed, values){
        if (typeof props.onValuesChange === 'function'){
            props.onValuesChange(values, changed);
        }
    }
})(ContactInfoForm)