import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { message } from 'antd';

class PrivateRoute extends React.Component {
    state = {
      loaded: false,
      isAuthenticated: false
    }
    componentDidMount() {
      this.authenticate()
      console.log('history', this.props.history);
      this.unlisten = this.props.history.listen(() => {
        Auth.currentAuthenticatedUser()
          .catch(() => {
            if (this.state.isAuthenticated) this.setState({ isAuthenticated: false })
          })
      });
    }
    componentWillUnmount() {
      this.unlisten()
    }
    authenticate() {
      Auth.currentAuthenticatedUser()
        .then(() => {
          this.setState({ loaded: true, isAuthenticated: true })
        })
        .catch(() => {
          window.localStorage.setItem('url-before-login', this.props.location.pathname);
          this.props.history.push('/login') && message.error.push('Please login to continue');
        })
    }
    render() {
      const { component: Component, render, ...rest } = this.props;
      const { loaded , isAuthenticated} = this.state
      if (!loaded) return null
      return (
        <Route
          {...rest}
          render={props => {
            if (isAuthenticated){
              if (Component){
                return <Component {...props} />
              }
              else if (render){
                return render(props);
              }
              return null
            }
            return (
              <Redirect
                to={{
                  pathname: "/login",
                }}
              />
            )
          }}
        />
      )
    }
  }

export default withRouter(PrivateRoute);