import { classNames } from 'common/util';
import React, { PropsWithChildren } from 'react';
import LiveHeader, { LiveHeaderProps } from '../LiveHeader';
import './style.less';

export interface LiveCardProps {
    headerProps?: LiveHeaderProps,
    contentNoBorder?: boolean,
    contentNoPadding?: boolean,
    hasTable?: boolean,
    animation?: {
        slideIn?: boolean
    }
}

const LiveCard: React.FC<PropsWithChildren<LiveCardProps>> = (props) => {

    let cardClasses = ['mc-live-card'];

    let contentClasses = ['mc-live-content'];
    if (!props.contentNoPadding) contentClasses.push('mc-live-content-padded');
    if (props.hasTable) contentClasses.push('mc-live-content-has-table');
    
    if (!props.contentNoBorder) cardClasses.push('mc-live-card-bordered');
    if (props.animation?.slideIn) cardClasses.push('mc-live-card-slide-in');

    return <div className={classNames(cardClasses)}>
        {props.headerProps ? (
            <LiveHeader {...props.headerProps} />
        ) : null}
        <div className={classNames(contentClasses)}>
            {props.children}
        </div>
    </div>
}

export default LiveCard