import { useQuery } from '@apollo/react-hooks';
import { Descriptions, Icon, Menu, message, Select, Tooltip, Typography } from 'antd';
import { OptionProps, SelectProps } from 'antd/lib/select';
import cn from 'classnames';
import { OrgDataContext } from 'context/orgData';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { CSSProperties, HTMLAttributes, ReactElement, useContext, useState } from 'react';
import { OrgTypes } from '../WithOrgData';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';

const { Option } = Select;

const GET_CONTRACTS = gql`
query ContractQuery(
    $filter: ContractsFilterInput
    $limit: Int
){
    contracts(
        filter: $filter
        limit: $limit
    ){
        docs {
            _id
            ... on Contract {
                name
                active
                locationID {
                    _id
                    name
                }
                customerID {
                    _id
                    name
                }
                tpID {
                    _id
                    name
                }
                startDate
                endDate
            }
        }
    }
}
`

export interface ContractDescriptionProps extends HTMLAttributes<HTMLDivElement> {
    name: string,
    locationName: string,
    transporterName: string,
    customerName: string,
    active: boolean,
    startDate: string,
    endDate: string
}

function renderExpiry(endDate: string){
    if (!endDate) return null;
    if (moment().startOf('day').isAfter(moment(endDate).startOf('day'))){
        return <Typography.Text type='danger'>(Expired)</Typography.Text>
    }
    else if (moment().startOf('day').isSame(moment(endDate).startOf('day'))){
        return <Typography.Text type='danger' mark>(Expires TODAY)</Typography.Text>
    }
    else if (moment().add(1, 'month').isAfter(moment(endDate))){
        return <Typography.Text type='warning'>(Expires in {moment(endDate).diff(moment().startOf('day'), 'days')} days)</Typography.Text>
    }
    return null;
}

export const ContractDescription: React.FC<ContractDescriptionProps> = (props) => {

    let spanStyle: CSSProperties = {
        maxWidth: '80px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        overflow: 'hidden'
    }

    let spanStyle2: CSSProperties = {
        display: 'inline-block',
        overflow: 'hidden'
    }

    let dividerStyle: CSSProperties = {
        ...spanStyle2,
        margin: '0 4px'
    }

    let endDate = [<span>{props.endDate}</span>, renderExpiry(props.endDate)]

    return (
        <div className={cn('mc-contract-description', props.className)}>
            <span style={{ display: 'block' }}>{props.name}</span>
            <span style={{fontSize: '0.7rem' }}>
                <Tooltip title={props.locationName}>
                    <span style={spanStyle}>LOC: {props.locationName}</span>
                </Tooltip>
                <span style={dividerStyle}> | </span>
                <Tooltip title={props.transporterName}>
                    <span style={spanStyle}>TP: {props.transporterName}</span>
                </Tooltip>
                <span style={dividerStyle}> | </span>
                <Tooltip title={props.customerName}>
                    <span style={spanStyle}>CUS: {props.customerName}</span>
                </Tooltip>
                <span style={dividerStyle}> | </span>
                <span style={spanStyle2}>Active: {
                    <Typography.Text type={props.active ? 'secondary' : 'danger'}><Icon type={props.active ? "check" : "close"} /></Typography.Text>
                }</span>
                <span style={dividerStyle}> | </span>
                <span style={spanStyle2}>St Dt: {
                    props.startDate ? (
                        moment().isBefore(moment(props.startDate)) ? (
                            <Typography.Text type="warning">{props.startDate} (not effective yet)</Typography.Text>
                        ) : props.startDate
                    ) : null
                }</span>
                <span style={dividerStyle}> | </span>
                <span style={spanStyle2}>Ed Dt: {endDate}</span>
            </span>
        </div>
    )
}

export interface ContractSelectProps extends SelectProps {
    orgType?: OrgTypes,
    locationID?: string,
    extraOptionsBefore?: ReactElement<OptionProps>[],
    showChooseContractOption?: boolean,
    activeOnly?: boolean,
    showLocation?: boolean,
    showInactive?: boolean,
    showExpired?: boolean,
    filterOption?: (contract: any) => boolean,

    modifyQueryFilter?: (currFilter: any) => any,
    disableFilterByOrg?: boolean,
    detailedDescriptions?: boolean
}

const ContractSelect = React.forwardRef((props: ContractSelectProps, ref?: React.LegacyRef<Select>) => {
    const orgData = useContext(OrgDataContext);
    const [ searchValue, setSearchValue ] = useState(null);

    const handleSearch = useDebouncedCallback(setSearchValue, 500)

    let filter = {
        active: props.showInactive ? undefined : true,
        tpID: undefined,
        customerID: undefined,
        locationID: props.locationID,
        name: searchValue,
        filterByDate: props.showExpired ? undefined : moment().format('YYYY-MM-DD')
    };

    if (!props.disableFilterByOrg){
        filter.customerID = props.orgType === 'customer' ? orgData.getOrgIDByType(props.orgType) : undefined;
        filter.tpID = orgData.transporterID;
    }

    if (props.modifyQueryFilter){
        filter = props.modifyQueryFilter(filter);
    }

    const { data, loading } = useQuery(
        GET_CONTRACTS,
        {
            variables: {
                filter: filter,
                limit: 100
            },
            onError: () => message.error('Error: Could not load contracts'),
            fetchPolicy: 'cache-and-network'
        }
    )

    const renderDefaultOption = (noContracts: boolean, locationID: string) => {
        if (noContracts){
            return <Option key="" value="">No Contracts Available</Option>
        }
        if (!locationID && props.showChooseContractOption){
            return <Option key="" value="">Choose a contract</Option>
        }
        if (locationID && props.showChooseContractOption){
            return <Option key="" value="">Choose Heliport</Option>
        }
        return null
    }

    const renderName = (contract: any) => {
        return <Tooltip title={`Starts: ${contract.startDate}, Expires: ${contract.endDate}`}>
            <span>{contract.name}</span> {renderExpiry(contract.endDate)}
        </Tooltip>
    }

    const renderOption = (contract: any) => {
        if (props.filterOption && !props.filterOption(contract)){
            return null;
        }

        if (props.detailedDescriptions){
            return <Option key={contract._id} value={contract._id} label={contract.name}>
                <ContractDescription
                    name={contract.name}
                    locationName={contract.locationID?.name}
                    transporterName={contract.locationID?.name}
                    customerName={contract.customerID?.name}
                    active={contract.active}
                    startDate={contract.startDate}
                    endDate={contract.endDate}
                />
            </Option>
        }
        else if (props.showLocation){
            return <Option key={contract._id} value={contract._id} label={contract.name}>
                {renderName(contract)}
                <Tooltip title={"Location: " + contract.locationID?.name}>
                    <span style={{ float: 'right' }}>
                        <Typography.Text type="secondary"><strong>Loc:</strong> {contract.locationID?.name}</Typography.Text>
                    </span>
                </Tooltip>
            </Option>
        }
        else
        {
            return <Option key={contract._id} value={contract._id} label={contract.name}>{renderName(contract)}</Option>
        }
    }
    
    const noContracts = !loading && data && data.getContracts && data.getContracts.length <= 0;

    const dropdownRender = (menu: Menu) => {
        return <div
            className={cn({
                'mc-contract-select-dropdown': true,
                'mc-contract-select-dropdown-with-descriptions': props.detailedDescriptions
            })}
        >
            {menu}
        </div>
    }

    return (
        <Select
            disabled={noContracts}
            ref={ref}
            defaultValue={""}
            loading={loading}
            optionLabelProp="label"
            placeholder="Choose a contract"
            {...props}
            className={cn({
                'mc-fix-select-selection-width': true,
                [props.className]: props.className ? true : false
            })}
            showSearch
            optionFilterProp="children"
            showAction={["focus", "click"]}
            filterOption={(input, option) => String(option.props.label).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            dropdownRender={dropdownRender}
            onSearch={(v) => handleSearch(v)}
            notFoundContent={!data?.contracts?.length && !loading ? (
                <span>Contract not found. Perhaps it has <strong>expired?</strong></span>
            ) : null}
        >
            {props.extraOptionsBefore}
            {renderDefaultOption(noContracts, props.locationID)}
            {!loading && data?.contracts?.docs?.map(contract => (
                contract && renderOption(contract)
            ))}
        </Select>
    )
})

ContractSelect.defaultProps = {
    showChooseContractOption: true,
    showLocation: false,
    detailedDescriptions: false
}

ContractSelect.displayName = "ContractSelect"

export default ContractSelect
