import { Button, Checkbox, Col, Collapse, Divider, Form, Input, Row, Select } from "antd";
import { CollapsePanelProps } from 'antd/lib/collapse';
import { FormComponentProps, FormProps } from "antd/lib/form";
import { formFieldsIsTouched, formItemLayout, tailFormItemLayout } from 'common/form';
import InputCognitoPhoneNumber from 'components/form/InputCognitoPhoneNumber';
import CognitoPhoneNumber from 'components/form/InputCognitoPhoneNumber/CognitoPhoneNumber';
import { validateCognitoPhoneNumber } from 'components/form/InputCognitoPhoneNumber/validator';
import { entryFormOptions, EntryFormProps, useAutoFocus } from "components/masterdata-2-0/entryForms";
import { UseMasterDataStateReturnNoQueryHookProps } from 'components/masterdata-2-0/hook';
import SignUpOrganizationSearch from 'components/SignUpOrganizationSearch';
import React, { forwardRef, LegacyRef, ReactNode, useEffect, useReducer, useRef } from 'react';
/*
export interface UserEntryFormProps extends EntryFormProps, Pick<FormProps, 'onSubmit' | 'form'> {
    allowCompanyPhoneNumberClear?: boolean
}
*/
export interface UserEntryFormProps extends EntryFormProps, Pick<FormProps, 'onSubmit' | 'form'> {
    allowCompanyPhoneNumberClear?: boolean,
    collapsePanelProps?: { [panelIdx: number]: CollapsePanelProps },
    MDState: UseMasterDataStateReturnNoQueryHookProps,
    saveUserLoading?: boolean,
    collapsePanelChildren?: ReactNode | ReactNode[],
    onStateChange?: (state: UserFormReducerState) => void
}

const FORM_ITEM_CLASS = 'ant-form-item-less-margin'
/*
const UserEntryForm: React.FC<UserEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const { autoFocus } = props;
    const emailRef = useRef(null);
    const { getFieldDecorator } = props.form;
    useAutoFocus(autoFocus, emailRef.current);

    return <Form ref={ref} onSubmit={props.onSubmit} form={props.form}>
        <Form.Item label="Email" {...formItemLayout}  className={FORM_ITEM_CLASS}>
        {getFieldDecorator('email', {
            rules: [
                { required: true, message: 'Email is required' }
            ]
        })(<Input ref={emailRef} type="email" />)}
        </Form.Item>
        <Row type="flex" gutter={12}>
            <Col span={12}>
                <Form.Item label="Given Name" {...formItemLayout} className={FORM_ITEM_CLASS}>
                {getFieldDecorator('given_name', {
                    rules: [
                        { required: true, message: 'Given Name is required' }
                    ]
                })(<Input maxLength={100} style={{ flexGrow: 1 }} />)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Family Name" {...formItemLayout} className={FORM_ITEM_CLASS}>
                {getFieldDecorator('family_name', {
                    rules: [
                        { required: true, message: 'Family Name is required' }
                    ]
                })(<Input maxLength={100} style={{ flexGrow: 1 }} />)}
                </Form.Item>
            </Col>
        </Row>
        <Form.Item label="Organization" {...formItemLayout} className={FORM_ITEM_CLASS}>
            {getFieldDecorator('organization', {
                rules: [
                    { required: true, message: 'Organization is required' }
                ],
                
            })(<SignUpOrganizationSearch labelInValue />)}
        </Form.Item>
        <Form.Item label="Phone Number" {...formItemLayout} className={FORM_ITEM_CLASS}>
            {getFieldDecorator('phone_number', {
                rules: [
                    { validator: validateCognitoPhoneNumber, required: true }
                ]
            })(<InputCognitoPhoneNumber />)}
        </Form.Item>
        <Form.Item label="Company Phone Number" {...formItemLayout} className={FORM_ITEM_CLASS}>
            {getFieldDecorator('company_phone_number', {
                rules: [
                    { validator: validateCognitoPhoneNumber }
                ]
            })(<InputCognitoPhoneNumber />)}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
            <Button htmlType="submit" style={{ display: 'none' }}>
                Save
            </Button>
        </Form.Item>
    </Form>
})*/

export interface UserFormReducerState {
    collapseActiveKeys?: string | string[],
    useCustomTempPass?: boolean,
    pswdVisibility?: boolean
}

const INIT_REDUCER_STATE: UserFormReducerState = {
    collapseActiveKeys: ['ua', 'ot', 'ug'],
    useCustomTempPass: false,
    pswdVisibility: false
}

function reducer(state: UserFormReducerState, action): UserFormReducerState {
    let keys, idx;
    switch (action.type) {
        case 'SET_CUSTOM_TEMP_PASS':
            keys = state.collapseActiveKeys;
            idx = keys.indexOf('pw');
            if (idx > -1 && !action.value){
                keys.splice(idx, idx + 1);
            }
            else if (action.value){
                keys = [...keys, 'pw']
            }
            return { ...state, useCustomTempPass: action.value, collapseActiveKeys: keys }
        
        case 'SET_COLLAPSE_ACTIVE_KEYS':
            keys = action.value;
            idx = keys.indexOf('pw');
            return { ...state, collapseActiveKeys: action.value, useCustomTempPass: idx === -1 ? false : true }
        case 'TOGGLE_PSWD_VISIBILITY':
            return { ...state, pswdVisibility: !state.pswdVisibility }
        default:
            break;
    }
}

const UserEntryForm: React.FC<UserEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const { autoFocus } = props;
    const emailRef = useRef(null);
    const { getFieldDecorator } = props.form;
    useAutoFocus(autoFocus, emailRef.current);
    const [ state, dispatch ] = useReducer(reducer, INIT_REDUCER_STATE);

    useEffect(() => {
        props.onStateChange?.(state);
    // eslint-disable-next-line
    }, [state.pswdVisibility, state.collapseActiveKeys, state.useCustomTempPass])

    const { MDState, saveUserLoading } = props;

    function renderFormExtra(){
        if (MDState.isNewEntry) return [];
        let extra = [];
        let formTouched = formFieldsIsTouched(MDState.entryFields, true);
        if (formTouched && !saveUserLoading){
            extra.push(
                <>
                <Button
                    key="reset"
                    size="small"
                    type="link"
                    style={{ padding: 0 }}
                    onClick={(e) => {
                        e.stopPropagation();
                        MDState.resetEntryFields();
                    }}
                >Reset</Button>
                <Divider type="vertical" />
                </>
            )
        }
        extra.push(
            <Button
                key="save"
                size="small"
                type="primary"
                htmlType="submit"
                disabled={!formTouched || saveUserLoading}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >{saveUserLoading ? 'Saving' : 'Save'}</Button>
        )
        return extra;
    }

    return <Form ref={ref} onSubmit={props.onSubmit} form={props.form}>
        <Collapse
            defaultActiveKey={["ua", "ot", "ct", "ug"]}
            style={{ margin: '0.25rem' }}
            activeKey={state.collapseActiveKeys}
            onChange={(keys) => dispatch({type: 'SET_COLLAPSE_ACTIVE_KEYS', value: keys})}
        >
            <Collapse.Panel key="ua" header="User attributes" extra={renderFormExtra()}>
                <Form.Item label="Email" {...formItemLayout}  className={FORM_ITEM_CLASS}>
                {getFieldDecorator('email', {
                    rules: [
                        { required: true, message: 'Email is required' }
                    ]
                })(<Input ref={emailRef} type="email" />)}
                </Form.Item>
                <Row type="flex" gutter={12}>
                    <Col span={12}>
                        <Form.Item label="Given Name" {...formItemLayout} className={FORM_ITEM_CLASS}>
                        {getFieldDecorator('given_name', {
                            rules: [
                                { required: true, message: 'Given Name is required' }
                            ]
                        })(<Input maxLength={100} style={{ flexGrow: 1 }} />)}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Family Name" {...formItemLayout} className={FORM_ITEM_CLASS}>
                        {getFieldDecorator('family_name', {
                            rules: [
                                { required: true, message: 'Family Name is required' }
                            ]
                        })(<Input maxLength={100} style={{ flexGrow: 1 }} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Organization" {...formItemLayout} className={FORM_ITEM_CLASS}>
                    {getFieldDecorator('organization', {
                        rules: [
                            { required: true, message: 'Organization is required' }
                        ]
                    })(<SignUpOrganizationSearch labelInValue  />)}
                </Form.Item>
                <Form.Item label="Phone Number" {...formItemLayout} className={FORM_ITEM_CLASS}>
                    {getFieldDecorator('phone_number', {
                        rules: [
                            { validator: validateCognitoPhoneNumber, required: true }
                        ],
                        initialValue: new CognitoPhoneNumber('+1', '')
                    })(<InputCognitoPhoneNumber />)}
                </Form.Item>
                <Form.Item label="Company Phone Number" {...formItemLayout} className={FORM_ITEM_CLASS}>
                    {getFieldDecorator('company_phone_number', {
                        rules: [
                            { validator: validateCognitoPhoneNumber }
                        ],
                        initialValue: new CognitoPhoneNumber('+1', '')
                    })(<InputCognitoPhoneNumber />)}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button htmlType="submit" style={{ display: 'none' }}>
                        Save
                    </Button>
                </Form.Item>
            </Collapse.Panel>
            {MDState.isNewEntry ? (
                <Collapse.Panel key="ot" header="Other">
                    <Form.Item {...formItemLayout} label="Preferred Invite Delivery">
                        {getFieldDecorator('preferredDeliveryMediums', {
                            initialValue: ['EMAIL'],
                            rules: [
                                {
                                    required: true, message: 'Please select a delivery medium'
                                }
                            ]
                        })(
                            <Select mode="multiple" showAction={["focus", "click"]}>
                                <Select.Option value="EMAIL">Email</Select.Option>
                                <Select.Option value="SMS">SMS</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </Collapse.Panel>
            ) : null}
            {MDState.isNewEntry ? (
            <Collapse.Panel showArrow={false} key="pw" header={
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch({ type: 'SET_CUSTOM_TEMP_PASS', value: !state.useCustomTempPass });
                }}>
                    <Checkbox checked={state.useCustomTempPass}>Use custom temporary password</Checkbox>
                </div>
            }>
                <Form.Item {...formItemLayout} label="Custom Password">
                    <Input.Group compact style={{ display: 'flex' }}>
                        {getFieldDecorator('tempPassword', {
                            rules: [{
                                required: state.useCustomTempPass ? true : false,
                                message: 'You must type in a password'
                            }]
                        })(
                            <Input type={state.pswdVisibility ? 'text' : 'password'} style={{ flex: 1 }} />
                        )}
                        <Button
                            icon={state.pswdVisibility ? 'eye-invisible' : 'eye'}
                            onClick={() => dispatch({type: 'TOGGLE_PSWD_VISIBILITY'})}
                        />
                    </Input.Group>
                </Form.Item>
            </Collapse.Panel>
            ) : null}
            {props.collapsePanelChildren}
        </Collapse>
    </Form>
})

export default Form.create<UserEntryFormProps & FormComponentProps>(entryFormOptions<UserEntryFormProps>())(UserEntryForm)