import gql from "graphql-tag";

export const PersonFragment = gql`
    fragment PersonFragment on Person {
        _id
        _rev
        lastName
        firstName
        classType
        dob
        nation
        extraBroadState
        credentials {
            _id
            key
            type
            expiration
        }
        contactInfo {
            phone {
                number
                type
            }
        }
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        }
        lastPaxWeight
        lastBagWeight
        lastBagCount
    }
`;

export const ResolvePersonIdsQuery = gql`
query GetPaxNodesFromIds($ids: [ID!]!){
    resolve_entity_ids(
        ids: $ids,
        typename: "Person"
    ){
        ... on Person {
            ...PersonFragment
        }
    }
}
${PersonFragment}
`