import React from 'react';
import CurrentOrg from 'components/CurrentOrg';
import PrivateRoute from 'components/PrivateRoute';
import moment from 'moment';
import Phase2Scheduler from "pages/app/scheduling/phase-2-scheduler";
import Phase1HistoryPage from "pages/app/scheduling/phase-1-history";
import Phase1HistoryEditorPage from "pages/app/scheduling/phase-1-history-editor";

export const basePath = (path) => `/app/scheduling${path ? `/${path}` : ''}`

/** Connects PaxCgoScheduler state with route url data */
const Phase2SchedulerWrapper = (
    {
        match,
        routes,
        editing,
        onEditingChange,
        onCancel,
        onSaveSuccessful,
        onNewSetClicked,
        onDeleteGroupSuccessful,
        onBackToList
    }) => {

    const { params } = match;
    var group;
    if (params && params.date && params.scheduledGroup && params.lastKnownController) {
        group = {
            date: params.date,
            name: decodeURIComponent(params.scheduledGroup),
            lastKnownController: decodeURIComponent(params.lastKnownController)
        }
    }
    const getGroupDataWithOrg = (orgData) => {
        if (!group){
            return group;
        }
        if (!orgData){
            return group;
        }
        const { customer, transporter } = orgData;
        return {
            ...group,
            tpID: transporter && transporter._id,
            customerID: customer && customer._id
        }
    }
    return (
        <CurrentOrg>
            {(orgData) => (
                <Phase2Scheduler
                    groupData={getGroupDataWithOrg(orgData)}
                    mainHeaderRoutes={routes}
                    onScheduleSaved={onSaveSuccessful}
                    editMode={editing}
                    onEditModeChange={onEditingChange}
                    onCancel={onCancel}
                    onNewSetCreated={onNewSetClicked}
                    onScheduleDeleted={onDeleteGroupSuccessful}
                    onFinished={onBackToList}
                />
            )}
        </CurrentOrg>
    )
}

const Phase1Routes = [
    <PrivateRoute
        exact
        path={basePath('history')}
        component={Phase1HistoryPage}
    />,
    <PrivateRoute
        exact
        path={basePath('history/:date/:scheduledGroup/:lastKnownController')}
        component={Phase1HistoryEditorPage}
    />,
    <PrivateRoute
        exact
        path={basePath('history/:date/:scheduledGroup/:lastKnownController/:departureID/:destinationID')}
        component={Phase1HistoryEditorPage}
    />
]

const Phase2Routes = [
    <PrivateRoute
        exact
        path={basePath('schedule/new')}
        render={(props) => (
            <Phase2SchedulerWrapper
                {...props}
                editing={true}
                paxEdit={false}
                onCancel={() => props.history.push(basePath('groups'))}
                onSaveSuccessful={({ variables: v }) => {
                    if (!v) return;
                    let flightDate      = encodeURIComponent( moment(v.scheduledFlightDate).format('YYYY-MM-DD') );
                    let groupName       = encodeURIComponent( v.scheduledGroup );
                    let lkc             = encodeURIComponent( v.lastKnownController );

                    var path = basePath(`schedule/${flightDate}/${groupName}/${lkc}`);
                    props.history.push(path);
                }}

                // Set up breadcrumb on header
                routes={[
                    {
                        path: '/app/scheduling/schedule',
                        title: 'Scheduled Passengers and Cargo'
                    },
                    {
                        title: 'New Schedule'
                    }
                ]}
            />
        )}
    />,
    <PrivateRoute
        exact
        path={basePath('schedule/:date/:scheduledGroup/:lastKnownController')}
        render={(props) => (
            <Phase2SchedulerWrapper
                {...props}
                editing={false}
                paxEdit={true}
                onCancel={() => props.history.push(basePath('schedule'))}
                onSaveSuccessful={() => {
                    props.history.push(basePath('groups'))
                }}
                onDeleteGroupSuccessful={() => {
                    props.history.push(basePath('schedule'))
                }}
                onEditingChange={(editing) => {
                    if (editing === false){
                        return;
                    }
                    var { date, scheduledGroup, departureID, destinationID, lastKnownController } = props.match.params;
                    scheduledGroup = encodeURIComponent(scheduledGroup);
                    departureID = encodeURIComponent(departureID);
                    destinationID = encodeURIComponent(destinationID);
                    lastKnownController = encodeURIComponent(lastKnownController);
                    var path = basePath(`schedule/${date}/${scheduledGroup}/${lastKnownController}/edit`)
                    props.history.push(path)
                }}
                onNewSetClicked={(id) => props.history.push(basePath(`sets/${id}`))}
                onBackToList={() => {
                    props.history.push(basePath('groups'))
                }}
                onToScheduledFlights={() => {
                    props.history.push(basePath('flight'))
                }}

                // Set up breadcrumb on header
                routes={[
                    {
                        path: '/app/scheduling/groups',
                        title: 'Scheduled Passengers and Cargo'
                    },
                    {
                        title: decodeURIComponent(props.match.params.scheduledGroup)
                    }
                ]}
            />
        )}
    />,
    <PrivateRoute
        exact
        path={basePath('schedule/:date/:scheduledGroup/:lastKnownController/edit')}
        render={(props) => {
            const { date, scheduledGroup, departureID, destinationID, lastKnownController } = props.match.params;
            var path = basePath(`schedule/${date}/${scheduledGroup}/${lastKnownController}`);
            return (
                <Phase2SchedulerWrapper
                    {...props}
                    editing={true}
                    onCancel={() => {
                        props.history.push(path)
                    }}
                    onSaveSuccessful={({ modifiedEntities, variables: v }) => {
                        if (modifiedEntities?.length){
                            if (!v) return;
                            let flightDate = encodeURIComponent( moment(v.scheduledFlightDate).format('YYYY-MM-DD') );
                            let groupName = encodeURIComponent( v.scheduledGroup );
                            let lastKnownController = encodeURIComponent( v.lastKnownController );
                            var path = basePath(`schedule/${flightDate}/${groupName}/${lastKnownController}`);
                            props.history.push(path);
                        }
                        else
                        {
                            props.history.push(basePath('groups'))
                        }
                    }}
                    onDeleteGroupSuccessful={() => {
                        props.history.push(basePath('groups'))
                    }}
                    onEditingChange={(editing) => {
                        if (editing === true){
                            return;
                        }
                        props.history.push(path)
                    }}
                    routes={[
                        {
                            path: '/app/scheduling/groups',
                            title: 'Scheduled Passengers and Cargo'
                        },
                        {
                            title: decodeURIComponent(props.match.params.scheduledGroup),
                            path: path
                        },
                        {
                            title: 'Edit'
                        }
                    ]}
                />
            )
        }}
    />
]

const SchedulingRoutes = [
    ...Phase1Routes,
    ...Phase2Routes
]

export default SchedulingRoutes;