import Alert, { AlertProps } from 'antd/lib/alert';
import { isMobile } from 'mobile-device-detect';
import React from 'react';

export interface MobileScreenAlertProps extends AlertProps {}

const MobileScreenAlert: React.FC<MobileScreenAlertProps> = (props) => {
    let newProps = { ...props }
    if (!props.message){
        if (isMobile){
            newProps.message = "This page is not optimized for mobile devices yet."
        }
        else
        {
            newProps.message = "This page is not optimized for small windows/screens yet."
        }
    }
    if (!props.description){
        if (isMobile) {
            newProps.description = "Please use this part of the website on a traditional computer screen."
        }
        else
        {
            newProps.description = "Please use this part of the website on larger screen/window."
        }
    }
    return <Alert
        className="mc-show-smaller-than-small-screen"
        type="warning"
        message="This page is not optimized for mobile device screens yet."
        description="Please use this part of the website on larger screen."
        showIcon
        banner
        {...newProps}
        
    />
}

export default MobileScreenAlert