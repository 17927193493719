import React, { forwardRef, LegacyRef } from 'react';
import { Table, Column, SelectionModes, Cell } from '@blueprintjs/table';
import { Button } from 'antd';
import { useColWidths } from 'common/table';
import { ScheduledGroup } from '../interface';

const tableCellStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
}

export interface ScheduledGroupsTableProps {
    data: Array<ScheduledGroup>,
    onViewGroup?(group: ScheduledGroup): void
}

const ScheduledGroupsTable: React.FC<ScheduledGroupsTableProps> = forwardRef((props, ref: LegacyRef<Table>) => {
    const [ colWidths, , updateColWidth ] = useColWidths(7, [200, 150, 150, 150, 90, 80, 150])
    return <Table
        className="mc-table"
        selectionModes={SelectionModes.NONE}
        numRows={props.data.length}
        defaultRowHeight={30}
        columnWidths={colWidths}
        onColumnWidthChanged={updateColWidth}
        ref={ref}
    >
        <Column name="Group Name" cellRenderer={(idx) => <Cell style={tableCellStyle}>{props.data[idx] && props.data[idx].scheduledGroup}</Cell>} />
        <Column name="Last Known Controller" cellRenderer={(idx) => {
            let lkc = props.data[idx] && props.data[idx].lastKnownController;
            return <Cell style={tableCellStyle}>{lkc && lkc.name}</Cell>
        }} />
        <Column name="Departure" cellRenderer={(idx) => {
            let dep = props.data[idx] && props.data[idx].departureID;
            return <Cell style={tableCellStyle}>{dep && dep.name}</Cell>
        }} />
        <Column name="Destination" cellRenderer={(idx) => {
            let dest = props.data[idx] && props.data[idx].destinationID;
            return <Cell style={tableCellStyle}>{dest && dest.name}</Cell>
        }} />
        <Column name="Outbound" cellRenderer={(idx) => {
            return <Cell style={tableCellStyle}>{props.data[idx] && props.data[idx].paxOutbound}</Cell>
        }} />
        <Column name="Inbound" cellRenderer={(idx) => {
            return <Cell style={tableCellStyle}>{props.data[idx] && props.data[idx].paxInbound + props.data[idx].cgoInbound}</Cell>
        }} />
        <Column name="Action" cellRenderer={(idx) => {
            return <Cell interactive style={tableCellStyle}>
                {props.onViewGroup ? (
                    <Button
                        type="link"
                        onClick={() => props.onViewGroup(props.data[idx])}
                        block
                        size="small"
                    >View Schedule</Button>
                ) : null}
            </Cell>
        }} />
    </Table>
})

export default ScheduledGroupsTable