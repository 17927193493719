import { Button, Checkbox } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { createFormField } from 'common/form';
import { castArray, dateMtimeMtoMoment, getReportCriteriaValue, maxTimeMoment, zeroTimeMoment } from 'common/util';
import { ContextProviderWrapperProps } from 'component-wrappers/wrap-ctx-provider';
import { DateTimePickerValue } from 'components/date-time-picker';
import ETable, { ETableTotals } from 'components/enchanced-antd-table';
import ReportUI from 'components/reporting-2/report-ui';
import { REColumnSet, ReportExporter } from 'components/reporting/export';
import moment from 'moment-timezone';
import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import CriteriaForm, { fieldLabelMap } from './criteria-form';

export interface FuelTakenReportPageProps extends ContextProviderWrapperProps {
    style?: CSSProperties
}

function getReportType(reportData: any){
    let criteria = castArray(reportData?.criteria);

    let c_reportType = criteria.find(c => c.key === "reportType");

    let parsed = 'detail';

    if (c_reportType?.value){
        try{
            parsed = JSON.parse(c_reportType.value);
        }
        catch(e){
            console.error("Failed to parse report type value!!!:", e);
        }
    }

    return parsed;
}

const FuelTakenReportPage: React.FC<FuelTakenReportPageProps> = (props) => {
    
    const history = useHistory();
    
    return <ReportUI
        useExternalContextProvider={props.useExternalContextProvider}
        overrideContext={props.overrideContext}
        overrideProvider={props.overrideProvider}
        reportTitle={<>
            <h2 style={{ margin: 0 }}>Fuel Taken</h2>
        </>}
        contentFlexMode
        headerExtra={{
            left: <Button.Group size='large' style={{ marginLeft: '1rem' }}>
                <a
                    className='ant-btn'
                    href="/app/flytwatch/fuel-taken"
                    onClick={(e) => { e.preventDefault(); history.push('/app/flytwatch/fuel-taken') }}
                >Daily</a>
                <a
                    className='ant-btn ant-btn-primary'
                    href="/app/flytwatch/fuel-taken/report"
                    onClick={(e) => { e.preventDefault(); history.push('/app/flytwatch/fuel-taken/report') }}
                >Report</a>
            </Button.Group>
        }}
        contextProviderProps={{
            persistenceName: 'flytwatch.fuel-taken-report.criteria-form',
            reportName: 'Fuel Taken Report',
            reportType: 'DISPATCH_FUEL_TAKEN_S3',
            submitReportHandler: (formValues, submitFn) => {
                return submitFn({
                    name: 'Fuel Taken Report',
                    criteria: Object.entries<any>(formValues).map(([ key, value ]) => {
                        let display_value;
                        if (key === 'startDateTime' || key === 'endDateTime'){
                            let dt = dateMtimeMtoMoment(value);
                            value = dt.toISOString();
                            display_value = dt.format('MMM Do, YYYY H:mm:ss')
                        }
                        return {
                            key,
                            value,
                            label: fieldLabelMap[key],
                            display_value: display_value
                        }
                    })
                })
            },
            convertPersistentFormData: (unconverted, updateTs) => {
                // moment fields are converted to a string when saved in localstorage.
                // We need to convert them back to a moment.
                let convertedData = unconverted;
                if (convertedData.startDateTime?.value){
                    convertedData.startDateTime.value = new DateTimePickerValue(
                        moment(convertedData.startDateTime.value.date),
                        moment(convertedData.startDateTime.value.time)
                    )
                }
                else if (convertedData.startDateTime){
                    convertedData.startDateTime = createFormField(new DateTimePickerValue(
                        moment(convertedData.startDateTime.date),
                        moment(convertedData.startDateTime.time)
                    ));
                }
                if (convertedData.endDateTime?.value){
                    convertedData.endDateTime.value = new DateTimePickerValue(
                        moment(convertedData.endDateTime.value.date),
                        moment(convertedData.endDateTime.value.time)
                    )
                }
                else if (convertedData.endDateTime){
                    convertedData.endDateTime = createFormField(new DateTimePickerValue(
                        moment(convertedData.endDateTime.date),
                        moment(convertedData.endDateTime.time)
                    ));
                }
                
                if (moment(updateTs).startOf('day').isBefore(moment().startOf('day'))){
                    // If it is the next day, reset the start date/time and end date/time to current day.
                    convertedData.startDateTime = createFormField(new DateTimePickerValue(moment(), zeroTimeMoment(moment())));
                    convertedData.endDateTime = createFormField(new DateTimePickerValue(moment(), maxTimeMoment(moment())));
                }
                return convertedData;
            }
        }}
        renderCriteriaForm={(ref, formFields, onFieldsChange) => {
            return <CriteriaForm
                ref={ref}
                formFields={formFields}
                onFieldsChange={onFieldsChange}
            />
        }}
        renderTable={({ reportDetails }) => {

            let data = castArray(reportDetails?.reportData?.result?.docs);

            let timeZone = 'America/Chicago';

            reportDetails?.reportData?.criteria?.forEach((c) => {
                if (c.key == "tz"){
                    try {
                        timeZone = JSON.parse(c.value);
                    } catch(err){
                        console.error('Failed to parse timeZone criteria:', err);
                        timeZone = c.value;
                    }
                }
            })

            const d_columns: ColumnProps<any>[] = [
                {
                    title: 'Location',
                    key: 'location',
                    dataIndex: 'name'
                },
                {
                    title: 'Amount',
                    key: 'amount',
                    dataIndex: 'amount'
                },
                {
                    title: 'Date/Time Taken (Local Time)',
                    key: 'dateTimeTaken',
                    dataIndex: 'dateTimeTaken',
                    render: (dt) => dt ? moment(dt).format('MMM Do, YYYY H:mm') : null
                },
                {
                    title: 'Flight ID',
                    key: 'flightid',
                    dataIndex: 'flightIdName'
                },
                {
                    title: 'Contract',
                    key: 'contract',
                    dataIndex: 'contractName'
                },
                {
                    title: 'AC Tail',
                    key: 'tail',
                    dataIndex: 'aircraftName'
                },
                {
                    title: 'PSID',
                    key: 'psid',
                    dataIndex: 'psid'
                }
            ]

            const s_columns: ColumnProps<any>[] = [
                {
                    title: 'Location',
                    key: 'location',
                    dataIndex: 'name',
                    width: 40
                },
                {
                    title: 'Total Amount',
                    key: 'totalAmount',
                    dataIndex: 'totalAmount',
                    width: 500
                }
            ]

            const d_totals: ETableTotals = {
                label: 'Total Fuel Taken:',
                masterLabel: 'Grand Total:',
                colNum: 0,
                masterSticky: true,
                includeTotalOnTop: true,
                renderTdFromGroup: (data) => {
                    return [<td>{data.reduce((prev, curr) => prev + curr.amount, 0)}</td>]
                },
                renderMasterTotalTd: (data) => {
                    return [<td>{data.reduce((prev, curr) => prev + curr.amount, 0)}</td>]
                }
            }

            const s_totals: ETableTotals = {
                label: 'Grand Total:',
                colNum: 0,
                sticky: true,
                td: [<td>{data.reduce((prev, curr) => prev + curr.totalAmount, 0)}</td>]
            }

            const reportType = getReportType(reportDetails?.reportData);

            let columns = d_columns;
            let totals = d_totals;

            if (reportType === 'summary'){
                columns = s_columns;
                totals = s_totals;
            }
        
            return <ETable
                rowKey="key"
                dataSource={data}
                columns={columns}
                pagination={false}
                size="small"
                className="mc-table"
                bordered
                flexMode
                stickyHeader
                groupSpacing
                groupByColKey={reportType === 'detail' ? 'name' : undefined}
                totals={totals}
            />
        }}
        setReportExporterProps={(reportData) => {

            let reportType = getReportType(reportData);
            let data = castArray(reportData?.result?.docs);

            const d_columnSets: REColumnSet[] = [{
                setName: 'Billing Report',
                key: 'billing',
                columns: [
                    {
                        label: 'Location',
                        key: 'name'
                    },
                    {
                        label: 'Amount',
                        key: 'amount'
                    },
                    {
                        label: 'Date/Time Taken',
                        key: 'dateTimeTaken'
                    },
                    {
                        label: 'Flight ID',
                        key: 'flightIdName'
                    },
                    {
                        label: 'Contract',
                        key: 'contractName'
                    },
                    {
                        label: 'AC Tail',
                        key: 'aircraftName'
                    },
                    {
                        label: 'PSID',
                        key: 'psid'
                    }
                ]
            }];

            const s_columnSets: REColumnSet[] = [{
                setName: 'Billing Report',
                key: 'billing',
                columns: [
                    {
                        label: 'Location',
                        key: 'name'
                    },
                    {
                        label: 'Total Amount',
                        key: 'totalAmount'
                    }
                ]
            }]

            let columnSets = d_columnSets;

            if (reportType === "summary"){
                columnSets = s_columnSets;
            }

            let startDate = getReportCriteriaValue(reportData, 'startDateTime');
            let endDate = getReportCriteriaValue(reportData, 'endDateTime');

            let startMoment = startDate ? moment(startDate).format('MMMM Do YYYY H:mm') : 'Unknown';
            let endMoment = endDate ? moment(endDate).format('MMMM Do YYYY H:mm') : 'Unknown';

            let title = "Fuel taken report from " + startMoment + " to " + endMoment;

            return {
                report_id: reportData?._id,
                report_type: "DISPATCH_FUEL_TAKEN_S3",
                column_sets: columnSets,
                data: data,
                titleRowText: title,
                export_name: title
            }
        }}
        style={props.style}
    />
}

export default FuelTakenReportPage