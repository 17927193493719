import { SelectProps, SelectValue } from 'antd/lib/select';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { SearchSelectWithData } from '../SearchSelect';
import { Select } from 'antd';
import './style.less';

export interface RigSelectProps<T=SelectValue> extends SelectProps<T> {
}

const QUERY = gql`
    query Rigs {
        getRigs {
            _id
            locationID {
                _id
                name
            }
        }
    }
`

const RigSelect: React.FC<RigSelectProps> = (props) => {
    const [ searchValue, setSearchValue ] = useState(null);

    return (
        <SearchSelectWithData
            {...props}
            query={QUERY}
            getData={(data) => data && data.getRigs && data.getRigs
                .filter(r => {
                    if (!searchValue) return true;
                    let locName = String(r?.locationID?.name).toUpperCase();
                    if (locName){
                        return locName.includes(String(searchValue).toUpperCase());
                    }
                    return false
                })
                .sort((a, b) => {
                    let aName = String(a?.locationID?.name);
                    let bName = String(b?.locationID?.name);
                    return aName.localeCompare(bName)
                })
            }
            onSearch={(v) => setSearchValue(v)}
            onDataItem={(item) => <Select.Option key={item._id} value={item._id} label={item?.locationID?.name}>{item?.locationID?.name}</Select.Option>}

        />
    )
}

export default RigSelect