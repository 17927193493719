import gql from "graphql-tag";

export default gql`
query PassengerQuery(
    $_id: ID!
){
    getPassenger(
        _id: $_id
    ){
        _id
        firstName
        lastName
        personID {
            _id
            dob
            nation
            notes
            contactInfo {
                phone {
                    number
                    type
                }
            }
        }
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        }
    }
}
`