import { IIndexedResizeCallback } from '@blueprintjs/table/lib/esm/interactions/resizable';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';

export const DEFAULT_COL_WIDTH = 134;

export const ColWidths = {
    CHECKBOX: 40
}

export function getColWidths(colCount: number, widths?: { [idx: number]: number }, defaultWidth: number=DEFAULT_COL_WIDTH): number[]{
    let colWidths = new Array(colCount);
    colWidths.fill(defaultWidth);

    function setWidth([ idx, width ]: [ string, number ]): void {
        colWidths[idx] = width;
    }

    if (widths){
        Object.entries(widths).forEach(setWidth);
    }
    return colWidths
}

export type ColWidthsValues = [ Array<number>, Dispatch<SetStateAction<Array<number>>>, IIndexedResizeCallback ]

export function useColWidths(colCount: number, widths?: { [idx: number]: number }, defaultWidth: number=DEFAULT_COL_WIDTH): ColWidthsValues {
    const [ colWidths, setColWidths ] = useState<Array<number>>(getColWidths(colCount, widths, defaultWidth));

    useEffect(() => {
        setColWidths(getColWidths(colCount, colWidths, defaultWidth))
        console.log('default width changed: ' + defaultWidth);
    }, [ defaultWidth ]);

    const updateColWidth: IIndexedResizeCallback = (idx, size) => {
        setColWidths(colWidths.map((item, itemIdx) => {
            if (itemIdx !== idx) return item;
            return size
        }))
    }
    if (colWidths.length !== colCount){
        let newColWidths = getColWidths(colCount, widths, defaultWidth);
        setColWidths(newColWidths);
        return [ newColWidths, setColWidths, updateColWidth ]
    }
    return [ colWidths, setColWidths, updateColWidth ]
}

export function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
