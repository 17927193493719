import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const query = gql`
{
    appLoaded @client
}
`

const withAppLoaded = (Component) => {
    return props => (
        <Query query={query}>
            {
                ({ data, error }) => {
                    if (error) console.error(error)
                    if (data && data.appLoaded === true){
                        return <Component {...props} />
                    }
                    return null
                }
            }
        </Query>
    )
}

export default withAppLoaded