import gql from "graphql-tag";

export default gql`
query GetCgo(
    $filter: CargoFilterInput
    $bookmark: String
    $limit: Int
){
    cargo(
        filter: $filter
        bookmark: $bookmark
        limit: $limit
    ) {
        bookmark
        docs {
            _id,
            ... on CargoNode {
                name,
                customerID {
                    _id
                    name
                }
                approvedBy
                weight
                checkedInTime
                deliveredBy
                attentionTo
                groundPickup
                hazmat
                hazmatUnCode
                destinationID {
                    _id
                    name
                }
                departureID {
                    _id
                    name
                }
                transitType
                currentCarrierID {
                    _id
                    desig
                }
            }
        }
    }
}`