import { Button, DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import credentialTypes from '../../credentialTypes';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
            xs: {
            span: 24,
            offset: 0,
        },
            sm: {
            span: 16,
            offset: 8,
        },
    },
};

const DATE_FORMAT = 'YYYY-MM-DD'

class CredentialForm extends Component {
    validateType = (rule, value, callback) => {
        const { takenTypes=[], typeTakenMessage="This person already has this type of credential" } = this.props;
        if (takenTypes.includes(value)){
            callback(typeTakenMessage)
        }else{
            callback()
        }
    }
    render() { 
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { submitText = 'Submit', resetText = 'Reset', showActions=false, lockType } = this.props;
        const chosenType = credentialTypes[getFieldValue('type')];
        return (
            <Form onSubmit={(data) => this.props.onSave && this.props.onSave(data)}>
                <Form.Item label="Type" {...formItemLayout} >
                {
                    getFieldDecorator('type', {
                        rules: [
                            {
                                required: true, message: 'Type is required'
                            },
                            {
                                validator: this.validateType
                            }
                        ]
                    })(
                        <Select placeholder="Choose a credential type" disabled={lockType ? true : false} showAction={["focus", "click"]}>
                            {Object.entries(credentialTypes).map((entry) => <Option key={entry[0]}>{entry[1].label}</Option>)}
                        </Select>
                    )
                }
                </Form.Item>
                {chosenType && chosenType.hasKey ? <Form.Item label="Key" {...formItemLayout}>
                {
                    getFieldDecorator('key', {
                        rules: [
                            {
                                required: true, message: 'Key is required'
                            }
                        ]
                    })(
                        <Input />
                    )
                }
                </Form.Item> : null}
                {chosenType && chosenType.hasExpiration ? <Form.Item label="Expiration" {...formItemLayout}>
                {
                    getFieldDecorator('expiration', {
                        rules: [
                            {
                                required: true, message: 'Expiration is required'
                            }
                        ]
                    })(
                        <DatePicker format={DATE_FORMAT} />
                    )
                }
                </Form.Item> : null}
                {chosenType && chosenType.needsButton ? <Button onClick={() => {
                        var yr = moment(getFieldValue('expiration')).add(1, 'year')
                        setFieldsValue({'expiration': yr})
                    }}>Add 1 Year</Button> : null}
                {showActions ? (
                    <Form.Item {...tailFormItemLayout}>
                        <Button htmlType="submit" type="primary" style={{ marginRight: 12 }}>{submitText}</Button>
                        <Button onClick={() => this.props.form.resetFields()}>{resetText}</Button>
                    </Form.Item>
                ) : null}
            </Form>
        );
    }
}

const CredentialFormWithForm = Form.create({
    onFieldsChange(props, changedFields) {
        if (props.onChange)
        props.onChange(changedFields)
    },
    mapPropsToFields(props) {
        let { fields={} } = props;
        return {
            type: Form.createFormField({
                ...fields.type,
                value: props.lockType ? props.lockType : fields.type?.value
            }),
            key: Form.createFormField({
                ...fields.key
            }),
            expiration: Form.createFormField({
                ...fields.expiration
            })
        }
    }
})(CredentialForm)

CredentialFormWithForm.propTypes = {
    typeTakenMessage: PropTypes.string,
    takenTypes: PropTypes.arrayOf(PropTypes.string),
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    fields: PropTypes.arrayOf(PropTypes.object)
}
 
export default CredentialFormWithForm;