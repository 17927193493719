import { useMutation } from '@apollo/react-hooks';
import {Cell, Column, ColumnHeaderCell} from '@blueprintjs/table';
import { Button, Divider, Input, List, message, Popconfirm, Select, Tooltip, Typography } from 'antd';
import { getColWidths } from 'common/table';
import { castArray, safeGet } from 'common/util';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import MCIcon from 'components/icon';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import gql from 'graphql-tag';
import _ from 'lodash';
import DeleteEntity from 'Mutations/DeleteEntity';
import React, { useEffect, useRef, useState } from 'react';
import { getDetailsTitle, MDDetails, MDHeader, MDLayout, MDTable, TableLoader, useMasterDataState } from '..';
import DuplicationChecker, { DuplicationCheckerProps } from '../DuplicationChecker';
import LocationEntryForm from '../entryForms/location';
import { getMDTableProps, MasterDataProps, MDStateToDetailsProps, MDStateToHeaderProps, MDStateToLayoutProps, MDStateToTableScrollerProps, renderCellFromKeyIndex, renderNoData, searchValuesToQuerySearchPayload, trimString } from '../util';
import useUserGroups from 'hooks/useUserGroups';
import { NetworkStatus } from 'apollo-client';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export interface MDLocationProps extends WithOrgDataProps, MasterDataProps {
    
}

const QUERY = gql`
query LocationsQuery($filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $limit: Int, $skip: Int, $twicRequired: Boolean, $isOneOff: Boolean, $hasDataflytHardware: Boolean) {
    MasterDataLocations(filter: $filter, search: $search, limit: $limit, skip: $skip, twicRequired: $twicRequired, isOneOff: $isOneOff, hasDataflytHardware: $hasDataflytHardware) {
        docs {
            ... on Location {
                _id
                _rev
                name
                ownerOrg
                block
                field
                type
                twicRequired
                isOneOff
                latitude
                longitude
                hasDataflytHardware
                getRig {
                    _id
                }
            }
        }
    }
}
`

const MUTATION = gql`
mutation LocationMutation(
        $tpID: ID!
        $payload: LocationInput!
    ){
    setLocation(
        tpID: $tpID
        payload: $payload
    ){
        _id
        name
        ownerOrg
        block
        field
        type
        latitude
        longitude
        twicRequired
        hasDataflytHardware
    }
}`

const MAKE_RIG_MUT = gql`
    mutation MakeRig($locationID: ID!){
        CreateRigFromLoc(locationID: $locationID){
            _id
        }
    }
`

const DELETE_USER_GROUPS = ['flytsuite.master.location.delete'];
const EDIT_USER_GROUPS = ['flytsuite.master.location.edit'];

const MDLocation: React.FC<MDLocationProps> = (props) => {

    const formRef = useRef(null);
    let tpID = props.orgData.getOrgIDByType('transporter');
    let routerLocation = useLocation();

    let editIDByDefault: string;
    let autoFocusLatitude: boolean;

    if (routerLocation && routerLocation.search){
        const params = qs.parse(routerLocation.search, { ignoreQueryPrefix: true });
        editIDByDefault = params.locationID;
        autoFocusLatitude = params.autoFocusLatitude === 'true';
    }

    const [ duplicatesFound, setDuplicatesFound ] = useState<Array<any>>([]);
    const [ loadingDone, setLoadingDone ] = useState<boolean>(false);
    const [ userGroups ] = useUserGroups();

    const isSuperUser = userGroups.includes('dataflyt.superuser');

    function findDuplicate(name: string){
        if (!name) return undefined;
        return duplicatesFound.find((loc) => loc && loc.name === trimString(name));
    }

    const MDState = useMasterDataState({
        getQueryData: data => data.MasterDataLocations.docs,
        getQueryVariables: (searchValues) => {
            let newSearchValues = {...searchValues};
            if ('twicRequired' in searchValues){
                var twicRequired = searchValues.twicRequired;
                delete newSearchValues.twicRequired;
            }
            if ("isOneOff" in searchValues){
                var isOneOff = searchValues.isOneOff;
                delete newSearchValues.isOneOff;
            }
            if ("hasDataflytHardware" in searchValues){
                var hasDataflytHardware = searchValues.hasDataflytHardware;
                delete newSearchValues.hasDataflytHardware;
            }
            let search = searchValuesToQuerySearchPayload(newSearchValues);
            return {
                twicRequired,
                isOneOff,
                hasDataflytHardware,
                search
            }
        },
        queryGQL: QUERY,
        saveMutationGQL: MUTATION,
        deleteMutationGQL: DeleteEntity,
        paginationLimit: props.dataPaginationLimit,
        pollInterval: props.pollInterval,
        tpID,
        updateQueryAfterFetchMore: (prev, fetchMoreResult) => ({
            ...prev,
            MasterDataLocations: {
                ...prev.MasterDataLocations,
                docs: [
                    ...prev.MasterDataLocations.docs,
                    ...fetchMoreResult.MasterDataLocations.docs
                ]
            }
        })
    })

    useEffect(() => {
        if (MDState.queryNetworkStatus === NetworkStatus.loading){
            setLoadingDone(false);
        }
        else
        {
            setLoadingDone(true);
        }
    }, [ MDState.queryNetworkStatus ])

    useEffect(() => {
        if (editIDByDefault){
            MDState.onSearchValueChange('_id', editIDByDefault, true);
        }
    }, [ editIDByDefault ])

    useEffect(() => {
        if (editIDByDefault && loadingDone){
            const loc = MDState.data.find((loc) => loc._id === editIDByDefault);
            console.debug('data', MDState.data);
            if (loc){
                MDState.editFromExisting(loc)
            }
        }
    }, [ editIDByDefault, loadingDone ])

    const [ makeRig, { loading: makeRigLoading } ] = useMutation(MAKE_RIG_MUT, {
        onError: () => message.error('Failed to make this location into a rig')
    });

    function renderDupeChecker(searchMode?: boolean, props?: Partial<DuplicationCheckerProps>){
        return <DuplicationChecker
        {...props}
        skip={
            !trimString(MDState.getEntryFieldValue('name'))
        }
        getData={data => {
            let dataArray = safeGet(['MasterDataLocations', 'docs'], data);
            if (Array.isArray(dataArray)){
                if (!_(dataArray).xorWith(duplicatesFound, _.isEqual).isEmpty()){
                    setDuplicatesFound(castArray(dataArray))
                }
            }
            else
            {
                if (duplicatesFound.length > 0){
                    setDuplicatesFound([]);
                }
            }
            return dataArray
        }}
        query={QUERY}
        variables={{
            [searchMode ? 'search' : 'filter']: [
                {
                    key: 'name',
                    value: JSON.stringify(trimString(MDState.getEntryFieldValue('name')))
                }
            ]
        }}
        renderItems={(items) => {
            return <List size="small">
                {items.map((loc) =>
                    <List.Item>
                        <Button
                            className="mc-link-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                MDState.editFromExisting(loc, false)
                            }}
                    >
                        <strong>Name: </strong>{loc.name}
                        <Divider type="vertical" />
                        <strong>Type: </strong>{loc.type}
                        {loc.block ? (<>
                            <Divider type="vertical" />
                            <strong>Area: </strong>{loc.block}
                        </>) : null}
                        {loc.field ? (<>
                            <Divider type="vertical" />
                            <strong>Block: </strong>{loc.field}
                        </>) : null}
                    </Button>
                    </List.Item>
                )}
            </List>
        }}
     />
    }

    const isLocOffShore = MDState.data.findIndex(d => d._id === MDState.getEntryFieldValue('_id') && d.type === 'OFFSHORE') > -1

    const selectedLoc = MDState.data.find(loc => loc._id === MDState.getEntryFieldValue('_id'))

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                name: MDState.getSearchValue('name'),
                ownerOrg: MDState.getSearchValue('ownerOrg'),
                type: MDState.getSearchValue('type'),
                block: MDState.getSearchValue('block'),
                field: MDState.getSearchValue('field'),
                twicRequired: MDState.getSearchValue('twicRequired')
            })
        })}
        headerElement={
            <MDHeader
                {...MDStateToHeaderProps(MDState)}
                inputElement={
                    <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '4rem', minWidth: '4rem' }}>Search: </span>
                        <Input.Group compact style={{ display: 'inline-block', width: 'auto' }}>
                            <Input
                                onChange={(e) => MDState.onSearchValueChange('name', e.target.value)}
                                placeholder="By name"
                                value={MDState.getSearchValue('name')}
                                style={{ width: '15rem' }}
                                allowClear
                            />
                            <Input
                                onChange={(e) => MDState.onSearchValueChange('_id', e.target.value, true)}
                                placeholder="By ID"
                                value={MDState.getSearchValue('_id')}
                                style={{ width: '10rem' }}
                                allowClear
                            />
                            <Input
                                onChange={(e) => MDState.onSearchValueChange('ownerOrg', e.target.value)}
                                placeholder="By Organization"
                                value={MDState.getSearchValue('ownerOrg')}
                                style={{ width: '15rem' }}
                                allowClear
                            />
                            <Input
                                onChange={(e) => MDState.onSearchValueChange('block', e.target.value)}
                                placeholder="By Area"
                                value={MDState.getSearchValue('block')}
                                style={{ width: '8rem' }}
                                allowClear
                            />
                            <Input
                                onChange={(e) => MDState.onSearchValueChange('field', e.target.value)}
                                placeholder="By Block"
                                value={MDState.getSearchValue('field')}
                                style={{ width: '8rem' }}
                                allowClear
                            />
                        </Input.Group>
                    </div>
                    <div style={{ marginTop: '0.5rem', display: 'flex', alignItems: 'center', width: 'auto' }}>
                        <span style={{ width: '4rem', minWidth: '4rem' }}>Filter: </span>
                        <Input.Group compact style={{ display: 'inline-block' }}>
                            <Select
                                onChange={(value) => MDState.onSearchValueChange('type', value)}
                                value={MDState.getSearchValue('type') || null}
                                style={{ width: '15rem' }}
                                placeholder="By type"
                                allowClear
                                showAction={["focus", "click"]}
                            >
                                <Select.Option value={null}>Any Type</Select.Option>
                                <Select.Option value="OFFSHORE">Off Shore</Select.Option>
                                <Select.Option value="ONSHORE">On Shore</Select.Option>
                            </Select>
                            <Select
                                onChange={(value) => {
                                    MDState.onSearchValueChange('twicRequired', typeof value === 'string' ? JSON.parse(value) : undefined);
                                }}
                                value={( typeof MDState.getSearchValue('twicRequired') === 'boolean' && String(MDState.getSearchValue('twicRequired'))) || null}
                                style={{ width: '15rem' }}
                                placeholder="By TWIC"
                                allowClear
                                showAction={["focus", "click"]}
                            >
                                <Select.Option value={null}>Don't filter TWIC</Select.Option>
                                <Select.Option value={String(true)}>TWIC Required</Select.Option>
                                <Select.Option value={String(false)}>TWIC Not Required</Select.Option>
                            </Select>
                            <Select
                                onChange={(value) => {
                                    MDState.onSearchValueChange('isOneOff', typeof value === 'string' ? JSON.parse(value) : undefined);
                                }}
                                value={( typeof MDState.getSearchValue('isOneOff') === 'boolean' && String(MDState.getSearchValue('isOneOff'))) || null}
                                style={{ width: '15rem' }}
                                placeholder="One Off"
                                allowClear
                                showAction={["focus", "click"]}
                            >
                                <Select.Option value={null}>Don't filter One-Off</Select.Option>
                                <Select.Option value={String(true)}>Is One-Off</Select.Option>
                                <Select.Option value={String(false)}>Is Not One-Off</Select.Option>
                            </Select>
                            {isSuperUser ? (
                                <Select
                                    onChange={(value) => {
                                        MDState.onSearchValueChange('hasDataflytHardware', typeof value === 'string' ? JSON.parse(value) : undefined);
                                    }}
                                    value={( typeof MDState.getSearchValue('hasDataflytHardware') === 'boolean' && String(MDState.getSearchValue('hasDataflytHardware'))) || null}
                                    style={{ width: '15rem' }}
                                    placeholder="Has DF Hardware"
                                    allowClear
                                    showAction={["focus", "click"]}
                                >
                                    <Select.Option value={null}>Don't filter DataFlyt Hardware</Select.Option>
                                    <Select.Option value={String(true)}>Has DataFlyt Hardware</Select.Option>
                                    <Select.Option value={String(false)}>No DataFlyt Hardware</Select.Option>
                                </Select>
                            )
                            : null}
                        </Input.Group>
                    </div>
                    </>
                }
            />
        }
        tableElement={
            <TableLoader mdState={MDState}>
                <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)}>
                    <MDTable
                        {...getMDTableProps(MDState.data, MDState)}
                        columnWidths={getColWidths(isSuperUser ? 8 : 7, {
                            0: 200
                        })}
                        onDelete={(idx) => {
                            let entry = MDState.data[idx];
                            MDState.delete({ _id: entry['_id'] });
                        }}
                        deleteUserGroups={DELETE_USER_GROUPS}
                        deleteLoadingIdx={MDState.deleting ? (MDState.data?.findIndex(pil => pil._id === MDState.deletingId)) || -1 : -1}
                    >
                        <Column name="Name" cellRenderer={(idx) => {
                            let isRig = MDState.data[idx].getRig ? true : false;
                            return <Cell>
                                {isRig ? <Tooltip title="This location is a rig" mouseEnterDelay={1}>
                                    <MCIcon type="rig" style={{ marginRight: '6px' }} />
                                </Tooltip> : null}
                                {MDState.data[idx].name}
                            </Cell>
                        }} />
                        <Column name="Organization" cellRenderer={renderCellFromKeyIndex(MDState.data, 'ownerOrg')} />
                        <Column name="Area" cellRenderer={renderCellFromKeyIndex(MDState.data, 'block')} />
                        <Column name="Block" cellRenderer={renderCellFromKeyIndex(MDState.data, 'field')} />
                        {isSuperUser ? (
                            <Column
                                name="Has DF Hardware"
                                columnHeaderCellRenderer={() => (
                                    <ColumnHeaderCell
                                        nameRenderer={() => <Typography.Text type="danger">Has DF Hardware</Typography.Text>}
                                    />
                                )}
                                cellRenderer={(idx) => {
                                    let record = MDState.data[idx];
                                    let hardware = record?.hasDataflytHardware;
                                    let text = hardware ? 'Yes' : 'No';
                                    return <Cell
                                        intent={record.hasDataflytHardware ? 'success' : 'danger'}
                                    >{text}</Cell>
                                }} />
                        ) : null}
                        <Column name="TWIC Required" cellRenderer={(idx) => {
                            let record = MDState.data[idx];
                            let twicRequired = record && record.twicRequired;
                            return <Cell intent={record.twicRequired ? 'success' : 'danger'}>{twicRequired ? 'Yes' : 'No'}</Cell>
                        }} />
                        <Column name="Type" cellRenderer={renderCellFromKeyIndex(MDState.data, 'type')} />
                        <Column name="Is One-Off" cellRenderer={(idx) => {
                            let record = MDState.data[idx];
                            let isOneOff = record && record.isOneOff;
                            return <Cell intent={record.isOneOff ? 'success' : 'danger'}>{isOneOff ? 'Yes' : 'No'}</Cell>
                        }} />
                    </MDTable>
                </BlueprintTableInfiniteScroller>
            </TableLoader>
        }
        detailsElement={
            <MDDetails
                contentWidth="35rem"
                isNewEntry={MDState.isNewEntry}
                disableSave={MDState.isNewEntry && findDuplicate(MDState.getEntryFieldValue('name')) ? true : false}
                {...MDStateToDetailsProps(MDState, 'name')}
                title={<span>
                    {selectedLoc?.getRig ? (
                        <Tooltip title="This location is a rig" mouseEnterDelay={1}>
                            <MCIcon type="rig" style={{ marginRight: '12px' }} />
                        </Tooltip>
                    ) : null}
                    {getDetailsTitle(MDState.getEntryFieldValue('name'), MDState.isNewEntry)}
                </span>}
                strTitle={MDState.getEntryFieldValue('name')}
                deleteUserGroups={DELETE_USER_GROUPS}
                editUserGroups={EDIT_USER_GROUPS}
                onSave={() => {
                    formRef.current.validateFieldsAndScroll((err, values) => {

                        const payload = {
                            ...values,
                            name: trimString(values.name),
                            ownerOrg: trimString(values.ownerOrg),
                            block: trimString(values.block),
                            field: trimString(values.field),
                            twicRequired: values.twicRequired ? true : false,
                            isOneOff: values.isOneOff ? true : false,
                            latitude: values.latitude,
                            longitude: values.longitude,
                            _id: MDState.getEntryFieldValue('_id'),
                        } as any;

                        if (isSuperUser){
                            payload.hasDataflytHardware = values.hasDataflytHardware;
                        }

                        if (!err){
                            let saveData = {
                                payload: payload
                            }
                            MDState.save(saveData)
                        }
                    })
                }}
                onDelete={

                    // Prevent rig locations from being deleted
                    selectedLoc?.getRig ? undefined :

                    () => {
                        let delData = {
                                _id: MDState.getEntryFieldValue('_id'),
                                tpID: props.orgData.getOrgIDByType('transporter')
                        }
                        MDState.delete(delData)
                    }
                }
                extra={
                    !MDState.isNewEntry && !selectedLoc?.getRig && userGroups.includes('dataflyt.superuser') ? [
                    <Tooltip title={isLocOffShore ? "Make this location a rig" : "Location needs to be an OFFSHORE type before you can make this a rig."} placement="bottom">
                        <Popconfirm 
                            okButtonProps={{ type: 'danger' }} 
                            okText="Make rig" 
                            title={
                                <span>
                                    Are you sure you want to make {selectedLoc?.name} a rig? <br />
                                    <Typography.Text type="danger">You cannot undo this action!</Typography.Text><br />
                                    Any edits you made below <strong>WILL BE LOST.</strong>
                                </span>
                            }
                            onConfirm={() => {
                                makeRig({
                                    variables: {
                                        locationID: MDState.getEntryFieldValue('_id')
                                    },
                                    update: (cache, { data: { CreateRigFromLoc } }) => {
                                        MDState.resetEntryFields();
                                        let locID = 'Location:' + MDState.getEntryFieldValue('_id');
                                        let f = gql`
                                            fragment L on Location {
                                                _id
                                                _id
                                                _rev
                                                name
                                                ownerOrg
                                                block
                                                field
                                                type
                                                twicRequired
                                                latitude
                                                longitude
                                                getRig {
                                                    _id
                                                }
                                            }
                                        `;
    
                                        cache.writeFragment({
                                            fragment: f,
                                            id: locID,
                                            data: {
                                                getRig: CreateRigFromLoc
                                            }
                                        })
                                    }
                                });
                            }}
                            >
                            <Button disabled={!isLocOffShore} loading={makeRigLoading}>Make Rig</Button>
                        </Popconfirm>
                    </Tooltip>
                ] : null}
            >
                <LocationEntryForm
                     formFields={MDState.entryFields}
                     onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                     uppercaseFields={['name', 'ownerOrg', 'block', 'field']}
                     autoFocus
                     autoFocusLatitude={autoFocusLatitude}
                     isNewEntry={MDState.isNewEntry}
                     ref={formRef}
                     dupeChecker={MDState.isNewEntry ? <>
                        {!findDuplicate(MDState.getEntryFieldValue('name')) ? renderDupeChecker(true, { title: 'Warning: Possible Duplication' }) : null}
                        {findDuplicate(MDState.getEntryFieldValue('name')) ? renderDupeChecker(false, {
                            alertProps: {
                                type: 'error'
                            },
                            title: "Duplication Error",
                            renderMessage: (items) => {
                                if (!items.length) return null;
                                let messageBeginning = <span><strong>{items.length} locations</strong> have been found that have</span>
                                if (items.length === 1){
                                    messageBeginning = <span>A location has been found that has</span>
                                }
                                let message = <p>{messageBeginning} the same name as what you have entered.
                                Duplicate locations are not allowed. Please edit an existing location by clicking an item below.
                                </p>
                                return message
                            }
                        }) : null}
                     </> : null}
                    areaBlockDuplicationChecker={
                        <DuplicationChecker
                            title="Area/Block Conflict Warning"
                            renderMessage={(items) => {
                                let messageBeginning = <span><strong>{items.length} locations</strong> have been found that have</span>
                                if (items.length === 1){
                                    messageBeginning = <span>A location has been found that has</span>
                                }
                                let message = <p>{messageBeginning} the same area and block as what is entered.
                                    It is highly recommended that you edit an existing location by clicking an item below.
                                </p>
                                return message
                            }}
                            skip={
                                MDState.getEntryFieldValue('type') !== 'OFFSHORE' ||
                                !MDState.getEntryFieldValue('block') ||
                                !MDState.getEntryFieldValue('field')
                            }
                            getData={data => {
                                let dataArray = safeGet(['MasterDataLocations', 'docs'], data);
                                if (Array.isArray(dataArray) && !MDState.isNewEntry){
                                    dataArray = dataArray.filter((loc) => loc._id !== MDState.getEntryFieldValue('_id'));
                                }
                                return dataArray
                            }}
                            query={QUERY}
                            variables={{
                                filter: [
                                    {
                                        key: 'type',
                                        value: JSON.stringify('OFFSHORE')
                                    },
                                    {
                                        key: 'block',
                                        value: JSON.stringify(MDState.getEntryFieldValue('block'))
                                    },
                                    {
                                        key: 'field',
                                        value: JSON.stringify(MDState.getEntryFieldValue('field'))
                                    }
                                ]
                            }}
                            renderItems={(items) => {
                                return <List size="small">
                                    {items.map((loc) =>
                                        <List.Item>
                                            <Button
                                                className="mc-link-btn"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    MDState.editFromExisting(loc, false)
                                                }}
                                        >
                                            <strong>Name: </strong>{loc.name}
                                            <Divider type="vertical" />
                                            <strong>Type: </strong>{loc.type}
                                            {loc.block ? (<>
                                                <Divider type="vertical" />
                                                <strong>Area: </strong>{loc.block}
                                            </>) : null}
                                            {loc.field ? (<>
                                                <Divider type="vertical" />
                                                <strong>Block: </strong>{loc.field}
                                            </>) : null}
                                        </Button>
                                        </List.Item>
                                    )}
                                </List>
                            }}
                        />
                    }
                />
                <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
            </MDDetails>
        }
    />
}

export default WithOrgData(MDLocation)