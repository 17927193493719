import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { ReportList } from '.';
import { Alert, Spin, Icon } from 'antd';
import ReportListQuery from './ReportListQuery';
import withUserInfo from '../../withUserInfo';

class ReportListWithData extends Component {
    static propTypes = {
        type: PropTypes.oneOf(['COVID', 'MANIFEST', 'PAX', 'CGO', 'PAX_COVID19_VAX', 'PERSON_COVID19_VAX_DETAILS']),
        userinfo: PropTypes.object.isRequired
    }
    render() {
        const { type, userinfo, ...restProps } = this.props;
        const username = userinfo.user && userinfo.user.username;
        return (
            <Query
                query={ReportListQuery}
                variables={{
                    filter: {
                        type,
                        username
                    }
                }}
                fetchPolicy="cache-and-network"
                pollInterval={15000}
                skip={!username}
            >
            {({ data, loading, error }) => {
                const reports = (data && data.reports && data.reports.docs) || [];
                return (
                    <div>
                        {error ? (
                            <Alert
                            style={{marginBottom: 12}}
                            type="error"
                            showIcon
                            message="Failed to get reports" />
                        ) : null}
                        <div>
                            <ReportList reports={reports} {...restProps} />
                            {!reports.length ? (
                            <div style={{ textAlign: 'center', marginTop: 36 }}>
                                <Spin
                                    size="large"
                                    spinning={loading}
                                    indicator={<Icon type="loading" spin />}
                                />
                            </div>
                            ):null}
                        </div>
                    </div>
                )
            }}
            </Query>
        );
    }
}
 
export default withUserInfo(ReportListWithData);