import React, { forwardRef } from 'react';
import { Checkbox } from 'antd';

const ColumnSelector = forwardRef(({ columns, onChange, style, disabled }, ref) => {
    const renderColumns = () => columns.map(({ label, key, checked }) => (
        <div key={key} style={{ paddingRight: '1rem' }}>
            <Checkbox
                disabled={disabled}
                checked={checked}
                ref={ref}
                onChange={(e) => {
                    const newColumns = [...columns];
                    var i;
                    for (i = 0; i < newColumns.length; i++){
                        const col = newColumns[i];
                        if (col.key === key){
                            newColumns[i].checked = e.target.checked;
                        }
                    }
                    onChange(newColumns)
                }}
            /> {label}
        </div>
    ))
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxHeight: '15rem', ...style }}>
            {renderColumns()}
        </div>
    )
})

export { ColumnSelector }