import {ColumnHeaderCell, IColumnHeaderCellProps} from "@blueprintjs/table";
import React from "react";
import {Button, Dropdown, Icon, Menu} from "antd";


export interface SortableColumnHeaderCellProps extends IColumnHeaderCellProps {
    name: string,
    sortKey: string,
    onSort: (keyName: string, dir: 'asc' | 'desc') => void,
    currentSortedKey: string,
    currentSortDir: string
}

const SortableColumnHeaderCell: React.FC<SortableColumnHeaderCellProps> =
    React.forwardRef((props, ref: React.LegacyRef<ColumnHeaderCell>) => {

    const { name, onSort, currentSortedKey, currentSortDir, sortKey, ...restProps } = props;

    const keyIsSelected = currentSortedKey === sortKey;

    function handleClick(dir: 'asc' | 'desc'){
        return () => props.onSort(sortKey, dir);
    }

    function isActive(dir: 'asc' | 'desc'){
        return keyIsSelected && currentSortDir === dir;
    }

    const menu = (
        <Menu>
            <Menu.Item disabled={isActive('asc')} onClick={handleClick('asc')}><Icon type="sort-ascending" /> Sort asc</Menu.Item>
            <Menu.Item disabled={isActive('desc')} onClick={handleClick('desc')}><Icon type="sort-descending" /> Sort desc</Menu.Item>
        </Menu>
    );

    const headerTextStyle: React.CSSProperties = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: 0
    }

    const headerContent = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {keyIsSelected ? <strong style={headerTextStyle}>{name}</strong> : <span style={headerTextStyle}>{name}</span>}
            <Dropdown overlay={menu}>
                <Button
                    className="mc-btn-transparent"
                    icon="down"
                    size="small"
                    style={{ marginLeft: 'auto', marginRight: '-6px', pointerEvents: 'auto' }}
                />
            </Dropdown>
        </div>
    )

    return <ColumnHeaderCell
        name={name}
        nameRenderer={() => headerContent}

        {...restProps}
        ref={ref}
    />
})

SortableColumnHeaderCell.displayName = 'SortableColumnHeaderCell'

export default SortableColumnHeaderCell;