import gql from "graphql-tag";

export default gql`
mutation ResetGlobalFilter {
    resetGlobalFilter @client{
        visible
        customer
        transporter
        departure {
            key
            label
        }
        destination {
            key
            label
        }
        employer {
            key
            label
        }
        pax {
            lastName
            firstName
        }
        cgo {
            name
        }
    }
}
`