import gql from "graphql-tag";

export default gql`
query ReportListQuery(
    $filter: ReportsFilterInput
    $limit: Int = 10
    $skip: Int
    $bookmark: String
){
    reports(
        filter: $filter
        limit: $limit
        skip: $skip
        bookmark: $bookmark
    ){
        docs {
            _id
            ... on Report {
                name
                startTs
                endTs
                createdTs
                status
                type
                result {
                    total_docs
                }
            }
        }
        bookmark
    }
}
`