import React, { useRef, forwardRef, LegacyRef } from 'react';
import { EntryFormProps, entryFormOptions, useAutoFocus } from '.';
import { Form, Input } from 'antd';
import { formItemLayout } from 'common/form';
import { FormComponentProps } from 'antd/lib/form';
import OrgNotifEmailListEditor, { validateOrgNotifEmailListEditor } from 'components/org-notif-email-list-editor';
import useUserGroups from 'hooks/useUserGroups';

export interface EmployerEntryFormProps extends EntryFormProps {

}

const EmployerEntryForm: React.FC<EmployerEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const [ userGroups ] = useUserGroups();
    const nameInputRef = useRef(null);
    const { getFieldDecorator } = props.form;
    useAutoFocus(props.autoFocus, nameInputRef.current);
    
    return <Form ref={ref}>
        <Form.Item label="Name" {...formItemLayout}>
        {getFieldDecorator('name', {
            rules: [ { required: true, message: 'Name is required' } ]
        })(<Input ref={nameInputRef} autoFocus />)}
        </Form.Item>
        {userGroups.findIndex(g =>
            g === 'flytsuite.management.emailtriggers.all' ||
            g === 'flytsuite.management.all' ||
            g === 'flytsuite.master.all'
        ) > -1 ?
            <Form.Item label="Email Triggers">
                {getFieldDecorator('notificationEmailList', {
                    rules: [{
                        validator: validateOrgNotifEmailListEditor
                    }]
                })
                (<OrgNotifEmailListEditor />)}
            </Form.Item>
        : null}
        {props.dupeChecker}
    </Form>
})

export default Form.create<EmployerEntryFormProps & FormComponentProps>(entryFormOptions<EmployerEntryFormProps>())(EmployerEntryForm)