import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { Select, Spin } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

class EmployerSelect extends Component {
    constructor(props){
        super(props)
        this.handleSearch = debounce(this.handleSearch, 500)
    }
    state = {
        value: []
    }
    getValue = () => {
        if (this.props.value){
            if (this.state.value !== this.props.value){
                this.setState({ value: this.props.value });
            }
        }else if(this.props.defaultValue){
            if(this.state.value !== this.props.defaultValue){
                this.setState({value: this.props.defaultValue})
            }
        }
        return this.state.value
    }
    handleSearch = (value) => {
        if (this.props.getEmployers)
            this.props.getEmployers({
                name: value
            })
    }
    handleChange = (value) => {
        this.setState({
            value: value
        })
        this.props.onChange && this.props.onChange(value)
    }
    render() { 
        const { data, ...restProps } = this.props;
        const renderNFContent = () => {
            if (data && data.loading){
                return <Spin size="small" />
            }
            if (data && data.error){
                return <span style={{color: 'red'}}>Failed to load employers</span>
            }
            return null
        }
        return (
            <Select
                mode="multiple"
                labelInValue
                placeholder="Select employers"
                notFoundContent={renderNFContent()}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                style={{ width: '100%' }}
                onBlur={this.props.resetEmployers && this.props.resetEmployers()}
                showAction={["focus", "click"]}
                {...restProps}
            >
                {data && data.employers && data.employers.docs.map(({ _id, name }) => <Option key={_id} value={_id}>{name}</Option>)}
            </Select>
        );
    }
}

EmployerSelect.propTypes = {
    data: PropTypes.object,
    getEmployers: PropTypes.func
}
 
export default EmployerSelect;