import ReactDom from 'react-dom';
import React, {useRef} from 'react';

export type RegisterNodeFunction = (node: React.ReactNode, key: string) => React.ReactNode

/**
 * Holds refs to every React Node registered through {@link reactNodeTextGetter.registerNode}. When those nodes are rendered,
     * {@link reactNodeTextGetter.getNodeText} can be called to retrieve the text content of the component.
 */
export function reactNodeTextGetter(){
    const nodeRefs = {};

    /**
     * "Registers" a React Node into a map with a unique key. Used later to get text content onces the component is mounted.
     * @param node ReactNode
     * @param key Unique key
     */
    const registerNode: RegisterNodeFunction = (node: React.ReactNode, key: string) => {
        let clonedNode = node;
        if (React.isValidElement(node)){
            clonedNode = React.cloneElement(node, {
                ref: r => nodeRefs[key] = r
            })
        }
        else {
            nodeRefs[key] = node;
        }
        return clonedNode;
    }

    /**
     * Given a nodeId, return the text content of the component. If the component has a "displayText" property defined in its ref,
     * then that value is used instead. See {@link exportTextInRef}.
     * @param nodeId Unique ID to retrieve node text from
     */
    const getNodeText = (nodeId: string) => {
        let text = '';

        let nodeRef = nodeRefs[nodeId];

        if (!nodeRef){
            return null;
        }
        else if (nodeRef.displayText){
            text = String(nodeRef.displayText);
        }
        else if (typeof nodeRef === 'string' || typeof nodeRef === 'number' || typeof nodeRef === 'boolean'){
            text = String(nodeRef);
        }
        else
        {
            const domNode = ReactDom.findDOMNode(nodeRef);
            if (domNode){
                text = domNode.textContent;
            }
        }

        return text;
    }

    return ({ getNodeText, registerNode })
}