import { Form, Input, InputNumber } from 'antd';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

const FormItem = Form.Item;
export const EditableContext = React.createContext();

function dragDirection(
    dragIndex,
    hoverIndex,
    initialClientOffset,
    clientOffset,
    sourceClientOffset,
  ) {
    const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
    const hoverClientY = clientOffset.y - sourceClientOffset.y;
    if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
      return 'downward';
    }
    if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
      return 'upward';
    }
}

const rowSource = {
    beginDrag(props) {
      return {
        index: props.index,
      };
    },
  };
  
const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
        return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

export class EditableRow extends React.Component {
  render(){
    return (
      <EditableContext.Provider value={this.props.form}>
        <tr {...this.props} />
      </EditableContext.Provider>
    )
  }
}

export class DraggableEditableRow extends React.Component {
    render() {
      const {
        isOver,
        connectDragSource,
        connectDropTarget,
        moveRow,
        dragRow,
        clientOffset,
        sourceClientOffset,
        initialClientOffset,
        form,
        ...restProps
      } = this.props;
      const style = { ...restProps.style, cursor: 'move' };
  
      let className = restProps.className;
      if (isOver && initialClientOffset) {
        const direction = dragDirection(
          dragRow.index,
          restProps.index,
          initialClientOffset,
          clientOffset,
          sourceClientOffset
        );
        if (direction === 'downward') {
          className += ' drop-over-downward';
        }
        if (direction === 'upward') {
          className += ' drop-over-upward';
        }
      }
        return (
            <EditableContext.Provider value={form}>
                {connectDragSource(
                    connectDropTarget(
                        <tr 
                            {...this.props}
                            className={className}
                            style={style}
                        />
                    )
                )
                }
            </EditableContext.Provider>
        )
    }
  }

export const EditableFormRow = Form.create()(EditableRow);

export const DraggableEditableFormRow = Form.create()(DraggableEditableRow);

export const EditableFormRowWithDrag = DropTarget('row', rowTarget, (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      sourceClientOffset: monitor.getSourceClientOffset(),
    }
  })(
    DragSource('row', rowSource, (connect, monitor) => {
      return {
        connectDragSource: connect.dragSource(),
        dragRow: monitor.getItem(),
        clientOffset: monitor.getClientOffset(),
        initialClientOffset: monitor.getInitialClientOffset(),
      }
    })(DraggableEditableFormRow)
  );

export class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      if(this.props.cancelButton){
        return <Input style={{marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0}} size="small" allowClear {...this.props.inputProps} />;
      }
      return <InputNumber {...this.props.inputProps} />;
    }
    return <Input {...this.props.inputProps} />;
  };

  render() {
    const {
      editing,
      required = false,
      message,
      dataIndex,
      title,
      inputType,
      inputProps,
      record,
      index,
      ...restProps
    } = this.props;

    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required,
                      message: message ? message : `Please Input ${title}!`,
                    }],
                    initialValue: record[dataIndex],
                  })(this.getInput())}
                </FormItem>
              ) : restProps.children}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

// const EditableTable = props => (
//     <Table
//     className="draggable-table"
//     components={props.dragSort ? draggableComponents : components}
//     rowClassName="editable-row"
//     {...props}
//     />
// )

// export default EditableTable

// class EditableTable extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { editingKey: '' };
//   }

//   isEditing = (record) => {
//     return record.key === this.state.editingKey;
//   };

//   edit(key) {
//     this.setState({ editingKey: key });
//   }

//   save(form, key) {
//     form.validateFields((error, row) => {
//       if (error) {
//         return;
//       }
//       this.props.onSave(row)
//       const newData = [...this.state.data];
//       const index = newData.findIndex(item => key === item.key);
//       if (index > -1) {
//         const item = newData[index];
//         newData.splice(index, 1, {
//           ...item,
//           ...row,
//         });
//         this.setState({ data: newData, editingKey: '' });
//       } else {
//         newData.push(row);
//         this.setState({ data: newData, editingKey: '' });
//       }
//     });
//   }

//   cancel = () => {
//     this.setState({ editingKey: '' });
//   };

//   render() {
//     const components = {
//       body: {
//         row: this.props.dragSort ? EditableFormRowWithDrag : EditableFormRow,
//         cell: EditableCell,
//       },
//     };

//     return (
//         <Table
//             className="draggable-table"
//             components={components}
//             rowClassName="editable-row"
//             {...this.props}
//         />
//     );
//   }
// }

// export default EditableTable

// ReactDOM.render(<EditableTable />, mountNode);