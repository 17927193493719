import React from 'react';
import { Spin, Icon } from 'antd';

const LoadingContent = ({text = 'Loading', style={}}) => (
    <div style={{textAlign: 'center', margin: '5rem 0', ...style}}>
        <Spin size="large" style={{marginBottom: '1rem'}} indicator={<Icon type="loading" />} />
        <h2>{text}</h2>
    </div>
)

export default LoadingContent