import gql from "graphql-tag";

export default gql`
mutation SetFlight(
    $payload: FlightNodeInput!
    $fetchPaxList: Boolean=true
    $fetchLegs: Boolean=true
    $orgID: ID!
){
    setFlight(payload: $payload, orgID: $orgID){
        _id
        state
        desig
        scheduledFlightDate
        callSign
        paxIDList @include(if: $fetchPaxList) {
            _id
            _rev
            lastName
            firstName
            scheduledOrder
            scheduledGroup
            employerID {
                _id
                name
            }
            transitType
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            paxWeight
            bagWeight
            bagCount
        }
        legsArray @include(if: $fetchLegs) {
            departure
            destination
            paxCount
            cgoCount
            paxIDs {
                _id
                lastName
                firstName
                employerID {
                    _id
                    name
                }
                chargeCode
                paxWeight
                bagWeight
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                personID {
                    _id
                    dob
                }
                transitType
            },
            cgoIDs {
                _id
                name
                weight
                approvedBy
                checkedInTime
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                deliveredBy
                attentionTo
                transitType
            }
        }
        tpID {
            _id
        }
        pilot {
            _id
            name {
                lastName
                firstName
            }
        }
        copilot {
            _id
            name {
                lastName
                firstName
            }
        }
        contract {
            _id
            name
        }
        aircraftID {
            _id
            tailNum
            model
            maxSeats
            maxLoad
        }
        departureID {
            _id
            name
        }
    }
}
`