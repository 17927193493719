import { Modal } from 'antd';
import { Hub } from '@aws-amplify/core';
import InputCognitoPhoneNumber from 'components/form/InputCognitoPhoneNumber';
import CognitoPhoneNumber from 'components/form/InputCognitoPhoneNumber/CognitoPhoneNumber';
import { validateCognitoPhoneNumber } from 'components/form/InputCognitoPhoneNumber/validator';
import { AuthContext } from 'context/auth';
import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import withUserInfo from '../withUserInfo';
import EditablePrefTable from './EditablePrefTable';
import './preferences-style.css';
import './style.less';

const Account = (props) => {
    const { user } = useContext(AuthContext);

    const { data } = useQuery(gql`
        query {
            organization @client {
                _id
                name
            }
        }
    `);

    function getAttr(attrKey){
        if (user && user.attributes){
            return user.attributes[attrKey];
        }
        return;
    }

    function updateUserAttr(key, value){

        // Check if attribute has been changed before performing an update
        if (getAttr(key) === value){
            return; // No changes detected. Exit function.
        }
        Hub.dispatch('user', {
            event: 'updateUserAttributes',
            data: {
                attributes: {
                    ...user.attributes,
                    [key]: value
                }
            },
            message: 'Updating user attribute ' + key
        })
    }

    const defaultRules = [
        {
            required: true,
            message: 'This field is required'
        }
    ]

    return (
        <div>
            <h3>Account Attributes</h3>
            <EditablePrefTable
                size="small"
                onSave={(key, value) => {
                    if (getAttr(key) === value){
                        return;
                    }
                    if (key === 'email'){
                        Modal.confirm({
                            title: 'Are you sure?',
                            content: <div>
                                <p>Are you sure you want to change your email address? Changes to your email address requires re-verification.</p>
                            </div>,
                            onOk: () => {
                                updateUserAttr(key, value);
                            }
                        })
                        return;
                    }
                    updateUserAttr(key, value);
                }}
                data={[
                    {
                        key: 'email',
                        label: 'Email',
                        value: getAttr('email'),
                        inputProps: {
                            type: 'email'
                        },
                        formRules: defaultRules
                    },
                    {
                        key: 'given_name',
                        label: 'First Name',
                        value: getAttr('given_name'),
                        formRules: defaultRules
                    },
                    {
                        key: 'family_name',
                        label: 'Last Name',
                        value: getAttr('family_name'),
                        formRules: defaultRules
                    },
                    {
                        key: 'phone_number',
                        label: 'Phone Number',
                        value: getAttr('phone_number'),
                        formRules: [
                            {
                                required: true, validator: validateCognitoPhoneNumber
                            }
                        ],
                        render: (row, handleSave, handleCancel) => {
                            const convertPN = (value: CognitoPhoneNumber) => value.toString()

                            function handleEscPress(e: React.KeyboardEvent<HTMLInputElement>){
                                e.stopPropagation();
                                if (e.keyCode === 27){
                                    handleCancel();
                                }
                            }
                            function handleEnterPress(){
                                handleSave(row.key, convertPN);
                            }
                            return <InputCognitoPhoneNumber
                                selectProps={{
                                    autoFocus: true,
                                    showAction: ['focus'],
                                    onInputKeyDown: handleEscPress
                                }}
                                onBlur={() => {
                                    handleSave(row.key, convertPN);
                                }}
                                onFocus={() => null}
                                inputProps={{
                                    onKeyDown: handleEscPress,
                                    onPressEnter: handleEnterPress
                                }}
                                allowClear={false}
                            />
                        }
                    },
                    {
                        key: 'custom:company_phone_number',
                        label: 'Company Phone Number',
                        value: getAttr('custom:company_phone_number'),
                        formRules: [
                            {
                                required: false, validator: validateCognitoPhoneNumber
                            }
                        ],
                        render: (row, handleSave, handleCancel) => {
                            const convertPN = (value: CognitoPhoneNumber) => value ? value.toString() : ''

                            function handleEscPress(e: React.KeyboardEvent<HTMLInputElement>){
                                e.stopPropagation();
                                if (e.keyCode === 27){
                                    handleCancel();
                                }
                            }
                            function handleEnterPress(){
                                handleSave(row.key, convertPN);
                            }
                            return <InputCognitoPhoneNumber
                                selectProps={{
                                    autoFocus: true,
                                    showAction: ['focus'],
                                    onInputKeyDown: handleEscPress
                                }}
                                onBlur={() => {
                                    handleSave(row.key, convertPN);
                                }}
                                inputProps={{
                                    onKeyDown: handleEscPress,
                                    onPressEnter: handleEnterPress
                                }}
                            />
                        }
                    },
                    {
                        key: 'organization',
                        label: 'Organization',
                        value: data?.organization?.name,
                        isText: true
                    }
                ]}
            />
        </div>
    )
}

export default withUserInfo( Account )