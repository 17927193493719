import React from 'react';
import { Row, Col, Button, Table, Checkbox } from 'antd';
import { DraggableBodyRow } from '../../TableDragSorting';
import ETable from 'components/enchanced-antd-table';
import {SelectedEntityCounter} from "components/flights/scheduling/FlightScheduleUtils";

class FlightManualScheduleLegs extends React.Component {
    render(){
        const {
            title = 'Flight Legs',
            extra=[],
            data,
            selectedLegs = [0],
            onLegChange
        } = this.props;

        const columns = [{
                title: "[]",
                key: "checkbox",
                render: (_, __, index) => <Checkbox
                    onClick={(e) => {
                        e.stopPropagation();
                        onLegChange?.(index);
                    }}
                    checked={selectedLegs.includes(index)}
                />
            },
            {
                title: '#',
                key: '#',
                render: (_, __, index) => index + 1
            },
            {
                title:  'Dep',
                key: 'dep',
                dataIndex: 'departure'
            },
            {
                title: 'Dest',
                key: 'dest',
                dataIndex: 'destination'
            },
            {
                title: 'Pax',
                key: 'pax',
                dataIndex: 'paxCount'
            },
            {
                title: 'Bags',
                key: 'bags',
                dataIndex: 'bagCount'
            },
            {
                title: 'CGO',
                key: 'cgo',
                dataIndex: 'cgoCount'
            },
            {
                title: 'Total Wt',
                key: 'totalWt',
                render: (_, record, index) => {
                    // Selected entities for just this leg
                    let legSelectedEntities = this.props.selectedEntities
                        .filter(e => record.paxIDs?.includes(e._id) || record.cgoIDs?.includes(e._id));

                    return <>
                        <span style={{ marginRight: 12 }}>
                            {Number(record.paxWeight) + Number(record.bagWeight) + Number(record.cgoWeight)}
                        </span>
                        <SelectedEntityCounter legSelectedEntities={legSelectedEntities} />
                    </>
                }
            }
        ]



        return (
            <React.Fragment>
                <Row type="flex" style={{marginBottom: '1rem'}} gutter={6}>
                    <Col style={{flex: 1}}>
                        <h3 style={{margin: 0}}>{title}</h3>
                    </Col>
                    {Array.isArray(extra) ? (
                        extra.map((node) => <Col>{node}</Col>)
                    ) : null}
                </Row>
                {!data.length && (
                    <h3 style={{marginTop: '8rem', textAlign: 'center', color: 'grey'}}>Create new flight legs by adding scheduled passengers</h3>
                )}
                {data.length ? (
                    <ETable
                        dataSource={data} 
                        className='mc-table'
                        columns={columns} 
                        rowKey={(_, index) => index} 
                        size="small" 
                        pagination={false}
                        components={!this.props.disabled ? {
                            body: {
                                row: DraggableBodyRow
                            }
                        } : undefined}
                        onRow={(row, index) => {
                            const rowProps = {
                                moveRow: (dragIndex, hoverIndex) => {},
                                onClick: () => onLegChange && onLegChange(index),
                                index
                            }
                            if (selectedLegs.includes(index)){
                                return {
                                    ...rowProps,
                                    className: 'selected'
                                }
                            }
                            return {
                                ...rowProps,
                                style: {
                                    cursor: 'pointer'
                                }
                            }
                        }}
                        />
                ) : null
                }
            </React.Fragment>
        )
    }
}

export default FlightManualScheduleLegs