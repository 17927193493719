import React from 'react';
import { Button, Drawer, Alert, Form, Select, Input, message, Popconfirm, Icon } from 'antd';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import LoadingContent from '../LoadingContent';
import WithOrgData from '../WithOrgData';
import { withUserGroups } from '../userGroup';
import NotAuthorized from '../NotAuthorized';

const PASSENGER = gql`
query Passenger($_id: ID!){
    getPassenger(_id: $_id){
        _id
        lastName
        firstName
        authorization {
            onWhiteList
            brdRestriction_SafetyCard {
                status
                type
                notes
            }
            brdRestriction_HuetCard {
                status
                type
                notes
            }
            brdRestriction_SteelToes {
                status
                type
                notes
            }
            brdRestriction_Twic {
                status
                type
                notes
            }
            brdRestriction_NonPreferred {
                status
                type
                notes
            }
            brdRestriction_Hazmat {
                status
                type
                notes
            }
            brdRestriction_NoFlyListed {
                status
                type
                notes
            }
            brdRestriction_EbsCard {
                status
                type
                notes
            }
            brdRestriction_Illness {
                status
                type
                notes
            }
            brdRestriction_Quarantined {
                status
                type
                notes
            }
        }
    }
}
`

const OVERRIDE = gql`
mutation Override($_id: ID! $tpID: ID! $restrictionType: String! $overrideType: BrdRestrictionType! $notes: String){
    overridePassengerRestriction(
        _id: $_id
        tpID: $tpID
        restrictionType: $restrictionType
        overrideType: $overrideType
        notes: $notes
    ){
        _id
        authorization {
            onWhiteList
            brdRestriction_SafetyCard {
                status
                type
                notes
            }
            brdRestriction_HuetCard {
                status
                type
                notes
            }
            brdRestriction_SteelToes {
                status
                type
                notes
            }
            brdRestriction_Twic {
                status
                type
                notes
            }
            brdRestriction_NonPreferred {
                status
                type
                notes
            }
            brdRestriction_Hazmat {
                status
                type
                notes
            }
            brdRestriction_NoFlyListed {
                status
                type
                notes
            }
            brdRestriction_EbsCard {
                status
                type
                notes
            }
            brdRestriction_Illness {
                status
                type
                notes
            }
            brdRestriction_Quarantined {
                status
                type
                notes
            }
        }
    }
}
`

const restrictionMap = {
    'brdRestriction_SafetyCard': 'Safety Card',
    'brdRestriction_HuetCard': 'Huet Card',
    'brdRestriction_SteelToes': 'Steel Toes',
    'brdRestriction_Twic': 'TWIC',
    'brdRestriction_NonPreferred': 'Non-Preferred',
    'brdRestriction_Hazmat': 'Hazmat',
    'brdRestriction_NoFlyListed': 'No-Fly Listed',
    'brdRestriction_EbsCard': 'EBS Card',
    'brdRestriction_Illness': 'Illness',
    'brdRestriction_Quarantined': 'Quarantined'
}

class BoardingRestrictionsOverride extends React.Component {
    state = {
        submitting: false
    }
    submit = () => {
        const { id, form, orgData: { transporter }, restriction } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ submitting: true })
                this.props.override({
                    variables: {
                        _id: id,
                        tpID: transporter._id,
                        restrictionType: restriction,
                        ...values
                    }
                })
                .then(() => {
                    message.success("Boarding restriction updated successfully");
                    if (this.props.onClose) this.props.onClose();
                })
                .catch(error => {
                    message.error("Failed to update boarding restriction")
                    console.error(error)
                })
                .then(() => this.setState({ submitting: false }))
            }
        })
    }
    renderContent = () => {
        if (!this.props.userGroups.includes('flytsuite.restriction.override')) {
            return <NotAuthorized desciption="You are not authorized to initiate overrides." />
        }
        const { getPassenger, loading, error } = this.props.data;
        if (loading) return <LoadingContent />
        if (error) return <Alert type="error" title="Failed to load passenger" description={error.message} showIcon />
        
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };
        const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 20,
                offset: 8,
              },
            },
        };

        const typeMap = [
            ['MANUALLY_VERIFIED', 'Manually Verified'],
            ['MANAGEMENT_OVERRIDE', 'Management Override'],
            ['CUSTOMER_NOT_ENFORCING', 'Customer Not Enforcing'],
            ['DO_NOT_OVERRIDE', 'Do Not Override'],
            ['USER_ERROR', 'User Error']
        ]

        var initialOverrideType = null;
        var initialOverrideNotes = null;
        if (getPassenger.authorization && getPassenger.authorization[this.props.restriction]){
            initialOverrideType = getPassenger.authorization[this.props.restriction].type
            initialOverrideNotes = getPassenger.authorization[this.props.restriction].notes
        }

        const overrideType = getFieldDecorator('overrideType', {
            initialValue: initialOverrideType,
            rules: [
                { required: true, message: 'This field is required!' }
            ]
        })(
            <Select showAction={["focus", "click"]}>
                {typeMap.map(((item, i) => <Select.Option key={i} value={item[0]} >{item[1]}</Select.Option>))}
            </Select>
        )

        const notes = getFieldDecorator('notes', {
            initialValue: initialOverrideNotes
        })(
            <Input.TextArea placeholder="Enter notes here" autosize={{ minRows: 3, maxRows: 10 }}/>
        )

        return (
            <Form>
                <Form.Item {...formItemLayout} label="Override Type">{overrideType}</Form.Item>
                <Form.Item {...formItemLayout} label="Notes" >{notes}</Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    {getFieldValue('overrideType') === 'DO_NOT_OVERRIDE' ?
                    <Button type="danger" loading={this.state.submitting} onClick={() => this.props.onClose()}>CANCEL</Button>
                    :
                    <Popconfirm 
                        title={<span>Are you sure you want to override?<br/>This action <strong>cannot</strong> be undone.</span>} 
                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                        okText="OVERRIDE"
                        onConfirm={() => this.submit()}>
                        <Button type="danger" loading={this.state.submitting}>OVERRIDE</Button>
                    </Popconfirm>}
                </Form.Item>
            </Form>
        )
    }
    render(){
        if (!this.props.data) return null
        const { getPassenger, loading, error } = this.props.data;
        var title = null;
        if (getPassenger && !error){
            title = `Override ${restrictionMap[this.props.restriction]} for ${getPassenger.lastName}, ${getPassenger.firstName}`
        }
        else if (error){
            title = 'Error'
        }
        else if (!getPassenger && loading){
            title = 'Loading'
        }
        if (!this.props.restriction){
            this.props.form.resetFields()
        }
        return (
            <Drawer title={title} width="30rem" closable onClose={this.props.onClose} visible={this.props.id && this.props.restriction}>
                {this.renderContent()}
            </Drawer>
        )
    }
}

export default compose(
    withUserGroups,
    WithOrgData,
    Form.create(),
    graphql(
        PASSENGER,
        {
            options: props => ({
                variables: {
                    _id: props.id
                },
                fetchPolicy: 'network-only'
            }),
            skip: props => !props.id
        }
    ),
    graphql(
        OVERRIDE,
        {
            name: 'override'
        }
    )
)(BoardingRestrictionsOverride)