import React, { useContext } from 'react';
import { Button, Layout, Tooltip } from 'antd';
import cn from 'classnames';
import { MDEmployer, MDLocation, MDVessel } from 'components/masterdata-2-0/pages';
import MobileScreenAlert from 'components/MobileScreenWarningAlert';
import { GlobalAppStateContext } from 'context/global-app-state';
import { Mutation } from 'react-apollo';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CheckInPassengerDocks } from '../../Mutations/CheckInPassenger';
import CurrentOrg from '../CurrentOrg';
import { PaxCheckIn } from '../PaxCheckIn';
import PrivateRoute from '../PrivateRoute';
import DocksNav from './nav';
import BoatDetail from './pages/boats/boatDetail';
import Boats from './pages/boats/boats';

/** Removes padding from child component by using negative margins */
const removePadding = (Cp) => (props) => {
    return <div style={{ margin: -24, height: 'calc(100% + 48px)' }} >
        <Cp {...props} />
    </div>
}

const basePath = '/app/'

const { Content } = Layout;

const renderLayout = (component) => (props) => {
    const cmp = React.cloneElement(component, props);
    return (
        <div style={{ margin: '-24px', height: 'calc(100% + 48px)', display: 'flex' }}>
            {cmp}
        </div>
    )
}

/** Render PaxCheckIn with personId from route url */
const renderCheckIn = (props) => {
    const personId = props.match.params.personId;
    return renderLayout(
        <Mutation
            mutation={CheckInPassengerDocks}
        >
        {(checkIn, { data, loading, error }) => {
            return (
                <CurrentOrg>
                {({ transporter }) => {
                    return (
                        <PaxCheckIn
                            type={'docks'}
                            selectedPerson={personId}
                            onPersonSelect={(id) => props.history.push(basePath + 'checkin/' + id)}
                            renderSubmitBtn={(submitFn) => {
                                return <>
                                    <Button
                                        onClick={() => submitFn({ expected: true })}
                                        style={{ marginLeft: 12 }}
                                        disabled={loading}
                                        className="mc-btn-green"
                                        type="primary">Check In Expected
                                    </Button>
                                    <Button
                                        onClick={() => submitFn()}
                                        style={{ marginLeft: 12 }}
                                        disabled={loading}
                                        type="primary">Check In
                                    </Button>
                                </>
                            }}
                            onSubmit={(values) => {
                                let expected = false;
                                if ('expected' in values){
                                    expected = values.expected;
                                    delete values.expected;
                                }
                                checkIn({
                                    variables: {
                                        payload: {
                                            ...values,
                                            tpID: transporter && transporter._id
                                        },
                                        expected: expected
                                    }
                                })
                            }}
                            onClearSelection={() => props.history.push(basePath + 'checkin')}
                            submitting={loading}
                            submitError={error}
                            submitSuccess={data && !loading && !error}
                            submittedPassengerData={data && data.CheckInPassengerDocks}
                        />
                    )
                }}
                </CurrentOrg>
            )
        }}
        </Mutation>
    )(props)
}

const DocksRoutes = React.memo(() => {
    return <>
    {/* Displays warning when using on a small screen for unoptimized pages */}
    <Route
        path={[
            basePath + 'checkin'
        ]}
        render={() => (
            <MobileScreenAlert
                style={{ margin: '-24px', marginBottom: '24px' }}
            />
        )}
    />
    <Switch>
        <PrivateRoute exact path={basePath + 'boats'} component={ removePadding(Boats)} />
        <PrivateRoute exact path={basePath + 'boats/:boatid'} component={removePadding(BoatDetail)} />
        <PrivateRoute exact path={basePath + 'checkin'} render={renderCheckIn} />
        <PrivateRoute exact path={basePath + 'checkin/:personId'} render={renderCheckIn} />
        <PrivateRoute exact path={basePath + 'masterdata/location'} component={removePadding(MDLocation)} />
        <PrivateRoute exact path={basePath + 'masterdata/employer'} component={removePadding(MDEmployer)} />
        <PrivateRoute exact path={basePath + 'masterdata/vessel'} component={removePadding(MDVessel)} />
        {/* <PrivateRoute component={() => <Redirect to={basePath + 'boats'} />} /> */}
        <Redirect to={basePath + 'boats'} />
    </Switch>
    </>
})

/**
 * Handles navigation and routes of docks branch of the application.
 */
const DocksMain = () => {
    const { state: { sidebarCollapsed }, setState } = useContext(GlobalAppStateContext);

    return <Layout className={cn({
        "mc-main-app-layout": true,
        "mc-main-app-layout-sider-open": (!sidebarCollapsed)
    })}>
        <div className="mc-main-app-layout-click-receiver" onClick={() => setState({ sidebarCollapsed: true })} />
        <DocksNav />
        <Content className="mc-main-app-layout-content" style={{padding: 24, margin: 0}}>
            <DocksRoutes />
        </Content>
    </Layout>
}

export default DocksMain