import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Spin } from 'antd';

const TP_QUERY =  gql`
{
    transporter @client {
        _id
        name
    }
}
`

// eslint-disable-next-line import/no-anonymous-default-export
export default (Component) => {
    return props => (
        <Query query={TP_QUERY}>
            {
                ({ data: {transporter}, loading, error }) => {
                    if (loading) return <Spin />
                    if (error) return <div style={{color: 'red'}}>ERROR: {error}</div>
                    return <Component {...props} transporter={transporter} />
                }
            }
        </Query>
    )
}