import gql from "graphql-tag";

export default gql`
query EmployerQuery(
    $filter: EmployerFilterInput
    $limit: Int
){
    employers(
        filter: $filter
        limit: $limit
    ){
        docs {
            _id
            ... on Employer {
                name
            }
        }
        bookmark
    }
}
`