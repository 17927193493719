import { Card, Icon } from 'antd';
import { CardProps } from 'antd/lib/card';
import { FormComponentProps } from 'antd/lib/form';
import ContactInfoForm from 'components/form/ContactInfoForm';
import React, { useRef, useState } from 'react';

export interface ContactInfoCardsProps extends FormComponentProps, CardProps {

}

const ContactInfoCards: React.FC<ContactInfoCardsProps> = ({ form, ...restProps }) => {

    const { getFieldDecorator, getFieldValue, setFields } = form;
    const [ tabKey, setTabKey ] = useState('contactInfo');
    const contactFormRef = useRef(null);
    const eContactFormRef = useRef(null);

    const getFormErrors = (formRef) => () => formRef.current.getFieldsError();

    getFieldDecorator('contactInfo');
    getFieldDecorator('emergencyContactInfo');
    const contactInfoFields = getFieldValue('contactInfo');
    const eContactInfoFields = getFieldValue('emergencyContactInfo');
    const updateFields = (key, formErrorsFunc?: any) => (fields) => {
        // console.log(form.getFieldsError());
        let errors;
        if (typeof formErrorsFunc === 'function'){
            let fieldErrors = formErrorsFunc();
            let values = Object.values(fieldErrors);
            for (let index = 0; index < values.length; index++) {
                const value = values[index];
                if (Array.isArray(value) && value.length > 0){
                    if (Array.isArray(errors)){
                        errors.push(...value);
                    }
                    else
                    {
                        errors = value;
                    }
                }   
            }
        }
        setFields({ [key]: {
            value: fields,
            errors
        } });
        // let newFields = {};
        // Object.entries(fields).forEach(([ key, value ]) => {
        //     newFields[key] = value
        // })
        // setFieldsValue({ [key]: { ...getFieldValue(key), ...newFields } });
        // return newFields
    }

    const renderTabWarning = (formName) => {
        if (form.getFieldError(formName)){
            return <Icon style={{ color: 'red' }} type="warning" />
        }
        return null;
    }
    
    return <Card size="small" onTabChange={setTabKey} tabList={[
            {
                tab: <span>{renderTabWarning('contactInfo')} Contact Info</span>,
                key: 'contactInfo'
            },
            {
                tab: <span>{renderTabWarning('emergencyContactInfo')} Emergency Contact Info</span>,
                key: 'emergencyContactInfo'
            }
        ]} {...restProps}>
            <ContactInfoForm
                style={{ display: tabKey === 'contactInfo' ? 'block': 'none' }}
                ref={contactFormRef}
                formValues={contactInfoFields}
                defaultValues={contactInfoFields}
                removeFields={['name']}
                onFieldsChange={updateFields('contactInfo', getFormErrors(contactFormRef))}
            />
            <ContactInfoForm
                style={{ display: tabKey === 'emergencyContactInfo' ? 'block': 'none' }}
                ref={eContactFormRef}
                formValues={eContactInfoFields} 
                defaultValues={eContactInfoFields}
                onFieldsChange={updateFields('emergencyContactInfo', getFormErrors(eContactFormRef))}
            />
        </Card>
}

export default ContactInfoCards