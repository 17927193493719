import gql from "graphql-tag";

export default gql`
query OrganizationQuery(
    $filter: OrganizationsFilterInput
    $limit: Int = 15
){
    organizations(
        filter: $filter
        limit: $limit
    ){
        docs {
            _id
            ... on Customer {
                name
            }
            ... on Transporter {
                name
            }
            ... on Employer {
                name
            }
        }
    }
}
`