import React, { useRef } from 'react';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import { MDLayout, MDHeader, useMasterDataState, TableLoader, MDTable, MDDetails, DuplicationChecker } from '..';
import { searchValuesToQuerySearchPayload, MDStateToLayoutProps, MDStateToHeaderProps, renderNoData, MDStateToTableScrollerProps, getMDTableProps, renderCellFromKeyIndex, MDStateToDetailsProps, trimString, MasterDataProps } from '../util';
import gql from 'graphql-tag';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import { Column } from '@blueprintjs/table';
import { getColWidths } from 'common/table';
import EmployerEntryForm from '../entryForms/employer';
import { removeTypename, safeGet } from 'common/util';
import { List } from 'antd';
import DeleteEntity from 'Mutations/DeleteEntity';
import { Button } from 'antd';
import { OrgEmailListItem } from 'common/types/org-email-list';

export interface MDEmployerProps extends WithOrgDataProps, MasterDataProps {

}

const QUERY = gql`
query EmployersQuery($filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $limit: Int, $skip: Int) {
    MasterDataEmployers(filter: $filter, search: $search, limit: $limit, skip: $skip) {
        docs {
            ... on Employer {
                _id
                _rev
                name
                notificationEmailList {
                    email
                    options {
                        optInAll
                        optIn
                    }
                }
            }
        }
    }
}
`

const MUTATION = gql`
mutation EmployerMutation(
        $tpID: ID!
        $payload: EmployerInput!
    ){
    setEmployer(
        tpID: $tpID
        payload: $payload
    ){
        _id
        name
    }
}
`

const DELETE_USER_GROUPS = ['flytsuite.master.employer.delete'];
const EDIT_USER_GROUPS = ['flytsuite.master.employer.edit'];

const MDEmployer: React.FC<MDEmployerProps> = (props) => {

    const formRef = useRef(null);
    let tpID = props.orgData.getOrgIDByType('transporter');

    const MDState = useMasterDataState({
        getQueryData: data => data.MasterDataEmployers.docs,
        getQueryVariables: (searchValues) => ({
            search: searchValuesToQuerySearchPayload(searchValues)
        }),
        queryGQL: QUERY,
        saveMutationGQL: MUTATION,
        deleteMutationGQL: DeleteEntity,
        tpID,
        paginationLimit: props.dataPaginationLimit,
        pollInterval: props.pollInterval,
        updateQueryAfterFetchMore: (prev, fetchMoreResult) => ({
            ...prev,
            MasterDataEmployers: {
                ...prev.MasterDataEmployers,
                docs: [
                    ...prev.MasterDataEmployers.docs,
                    ...fetchMoreResult.MasterDataEmployers.docs
                ]
            }
        })
    })

    const data = MDState.data;

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                name: MDState.getSearchValue('name')
            })
        })}
        headerElement={
            <MDHeader
                {...MDStateToHeaderProps(MDState)}
                searchInputProps={{
                    onChange: e => MDState.onSearchValueChange('name', e.target.value),
                    placeholder: 'Search by name',
                    value: MDState.getSearchValue('name'),
                    style: { width: '30rem' },
                    allowClear: true
                }}
            />
        }
        tableElement={
            <TableLoader mdState={MDState}>
                <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)}>
                    <MDTable
                        {...getMDTableProps(data, MDState)}
                        columnWidths={getColWidths(1, {
                            0: 500
                        })}
                        deleteUserGroups={DELETE_USER_GROUPS}
                        hideDelete
                    >
                        <Column name="Name" cellRenderer={renderCellFromKeyIndex(data, 'name')} />
                    </MDTable>
                </BlueprintTableInfiniteScroller>
            </TableLoader>
        }
        detailsElement={
            <MDDetails
                {...MDStateToDetailsProps(MDState, 'name')}
                onSave={() => {
                    formRef.current.validateFieldsAndScroll((err, values) => {
                        if (!err){
                            let notificationEmailList: OrgEmailListItem[] = values.notificationEmailList;

                            if (notificationEmailList){
                                // Clean any entries that have blank email fields
                                notificationEmailList = notificationEmailList.filter(item => item.email);
                                notificationEmailList = removeTypename(notificationEmailList);
                            }

                            let saveData = {
                                payload: {
                                    ...values,
                                    name: trimString(values.name),
                                    _id: MDState.getEntryFieldValue('_id'),
                                    notificationEmailList: notificationEmailList
                                }
                            }
                            MDState.save(saveData)
                        }
                    })
                }}
                deleteUserGroups={DELETE_USER_GROUPS}
                editUserGroups={EDIT_USER_GROUPS}
            >
                <EmployerEntryForm
                    formFields={MDState.entryFields}
                    onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                    uppercaseFields={['name']}
                    autoFocus
                    ref={formRef}
                    dupeChecker={MDState.isNewEntry ? (
                        <DuplicationChecker
                            skip={!trimString(MDState.getEntryFieldValue('name'))}
                            getData={data => safeGet(['MasterDataEmployers', 'docs'], data)}
                            query={QUERY}
                            variables={{
                                search: [
                                    {
                                        key: 'name',
                                        value: JSON.stringify(trimString(MDState.getEntryFieldValue('name')))
                                    }
                                ]
                            }}
                            renderItems={(items) => {
                                return <List size="small">
                                {items.map((employer, idx) => 
                                    <List.Item key={idx}>
                                        <Button
                                            className="mc-link-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                MDState.editFromExisting(employer, false);
                                            }}
                                        >
                                            {employer.name}
                                        </Button>
                                    </List.Item>)}
                                </List>
                            }}
                        />
                    ) : null}
                />
                <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
            </MDDetails>
        }
    />
}

export default WithOrgData(MDEmployer)