import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import GetFiltersQuery from './GetFiltersQuery';
import { Row, Col, Spin, Divider, Tooltip, Button, Modal } from 'antd';
import SetFilterMutation from './SetFilterMutation';
import { LocationSelect } from '../form/select/LocationSelect';
import { OrganizationSelect } from '../form/select/OrganizationSelect';
import WithOrgData from '../WithOrgData';
import './GlobalFilter.less';
import MCIcon from 'components/icon';
import ResetGlobalFilterMutation from './ResetGlobalFilterMutation';

const fieldsPerOrgType = {
    'flytsuite.customer': ['employer', 'departure', 'destination'],
    'flytsuite.transporter': ['customer', 'employer', 'departure', 'destination'],
    'flytsuite.employer': ['customer', 'transporter', 'departure', 'destination']
}

class GlobalFilter extends Component {
    state = {
        modalOpen: false
    }
    shouldFieldBeShown = (fieldType) => {
        const org = this.props.orgData.organization;
        const showableFields = fieldsPerOrgType[org.classType];
        return Array.isArray(showableFields) && showableFields.includes(fieldType);
    }
    isClearable = () => {
        const {
            customer,
            transporter,
            departure,
            destination,
            employer,
        } = this.props.data.globalFilter;

        return (
            customer?.length || 
            transporter?.length || 
            departure?.length || 
            destination?.length || 
            employer?.length
        );
    }
    getField = (fieldType) => {
        const { globalFilter } = this.props.data;
        const { setFilter } = this.props;
        const {
            customer,
            transporter,
            departure,
            destination,
            employer,
        } = globalFilter;

        switch (fieldType) {
            case 'customer':
                return (
                    <div>
                        <strong style={{margin: 0, marginRight: '6px'}}>Customer:</strong>
                        <OrganizationSelect
                            labelInValue
                            mode="multiple"
                            size="small"
                            style={{ minWidth: '12rem', maxWidth: '20rem' }}
                            defaultActiveFirstOption
                            allowClear
                            value={customer || []}
                            classTypes={['flytsuite.customer']}
                            maxTagCount={5}
                            onChange={(value) => {
                                const newFilter = {
                                    ...globalFilter,
                                    customer: value.map((item) => ({...item, __typename: 'SelectItem'}))
                                };
                                setFilter({
                                    variables: newFilter
                                });
                            }}
                        />
                    </div>
                )
            case 'transporter':
                return (
                    <div>
                        <strong style={{margin: 0, marginRight: '6px'}}>Transporter:</strong>
                        <OrganizationSelect
                            labelInValue
                            mode="multiple"
                            size="small"
                            style={{ minWidth: '12rem', maxWidth: '20rem' }}
                            defaultActiveFirstOption
                            allowClear
                            value={transporter || []}
                            classTypes={['flytsuite.transporter']}
                            maxTagCount={5}
                            onChange={(value) => {
                                const newFilter = {
                                    ...globalFilter,
                                    transporter: value.map((item) => ({...item, __typename: 'SelectItem'}))
                                };
                                setFilter({
                                    variables: newFilter
                                });
                            }}
                        />
                    </div>
                )
            case 'employer':
                return (
                    <div>
                        <strong style={{margin: 0, marginRight: '6px'}}>Employer:</strong>
                        <OrganizationSelect
                            labelInValue
                            mode="multiple"
                            size="small"
                            style={{ minWidth: '12rem', maxWidth: '20rem' }}
                            defaultActiveFirstOption
                            allowClear
                            value={employer || []}
                            classTypes={['flytsuite.employer']}
                            maxTagCount={5}
                            onChange={(value) => {
                                const newFilter = {
                                    ...globalFilter,
                                    employer: value.map((item) => ({...item, __typename: 'SelectItem'}))
                                };
                                setFilter({
                                    variables: newFilter
                                });
                            }}
                        />
                    </div>
                )
            case 'departure':
                return (
                    <div>
                        <strong style={{margin: 0, marginRight: '6px'}}>Departure:</strong>
                        <LocationSelect 
                            labelInValue
                            mode="multiple"
                            size="small"
                            style={{ minWidth: '12rem', maxWidth: '20rem' }}
                            defaultActiveFirstOption
                            allowClear
                            value={departure || []}
                            maxTagCount={5}
                            onChange={(value) => {
                                const newFilter = {
                                    ...globalFilter,
                                    departure: value.map((item) => ({...item, __typename: 'SelectItem'}))
                                };
                                setFilter({
                                    variables: newFilter
                                });
                            }}
                        />
                    </div>
                )
            case 'destination':
                return (
                    <div>
                        <strong style={{margin: 0, marginRight: '6px'}}>Destination:</strong>
                        <LocationSelect 
                            labelInValue
                            mode="multiple"
                            size="small"
                            style={{ minWidth: '12rem', maxWidth: '20rem' }}
                            defaultActiveFirstOption
                            allowClear
                            value={destination || []}
                            maxTagCount={5}
                            onChange={(value) => {
                                const newFilter = {
                                    ...globalFilter,
                                    destination: value.map((item) => ({...item, __typename: 'SelectItem'}))
                                };
                                setFilter({
                                    variables: newFilter
                                });
                            }}
                        />
                    </div>
                )
            default:
                break;
        }
    }

    handleReset = () => {
        const { setFilter } = this.props;
        const newFilter = {
            customer: [],
            transporter: [],
            departure: [],
            destination: [],
            employer: []
        };
        setFilter({
            variables: newFilter
        });
    }

    render() {
        const { globalFilter, error } = this.props.data;
        if (error) {
            console.error(error);
            return <span style={{color: 'red'}} >Error while loading Global Filter</span>
        }
        const showFields = this.props.showFields || [
            'customer',
            'employer',
            'transporter',
            'departure',
            'destination'
        ]
        if (!globalFilter) return <Spin />

        return (
            <Row type="flex" gutter={12} className="mc-global-filter-container">
                <Col className="mc-global-filter-title-wrapper">
                    <Tooltip placement="bottom" title="Filters applied here will persist throughout this application.">
                        <h3 className="mc-global-filter-title">Global Filter</h3>
                        <MCIcon className="mc-global-filter-title-icon" type="external-link" />
                    </Tooltip>
                </Col>
                <Button
                    className="mc-btn-transparent mc-global-filter-title-wrapper mc-global-filter-title-wrapper-small"
                    onClick={() => this.setState({ modalOpen: true })}
                >
                    <span>
                        <h3 className="mc-global-filter-title">Open Global Filter</h3>
                        <MCIcon className="mc-global-filter-title-icon" type="external-link" />
                    </span>
                </Button>
                <span className="mc-global-filter-fields-wrapper">
                    <Divider
                        type="vertical"
                        style={{ height: 'auto', alignSelf: 'stretch', margin: '0 1rem' }}
                        className="mc-global-filter-divider"
                    />
                    <Row type="flex" style={{ alignItems: 'center', gap: '12px' }}>
                        {showFields.map((fieldType, i) => {
                            if (this.shouldFieldBeShown(fieldType)){
                                return (
                                    <Col key={i}>
                                    {
                                        this.getField(fieldType)
                                    }
                                    </Col>
                                )
                            }
                            return null
                        })}
                        {this.isClearable() ? (
                            <Button size="small" onClick={this.handleReset}>Reset</Button>
                        ) : null}
                    </Row>
                </span>
                <Modal
                    title="Global Filter"
                    visible={this.state.modalOpen}
                    centered
                    onCancel={() => this.setState({ modalOpen: false })}
                    bodyStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}
                    footer={<Button block onClick={() => this.setState({ modalOpen: false })}>Close</Button>}
                >
                    {showFields.map((fieldType, i) => {
                            if (this.shouldFieldBeShown(fieldType)){
                                return (
                                    <Col key={i}>
                                    {
                                        this.getField(fieldType)
                                    }
                                    </Col>
                                )
                            }
                            return null
                        })}
                        {this.isClearable() ? (
                            <Button size="small" onClick={this.handleReset}>Reset</Button>
                        ) : null}
                </Modal>
            </Row>
        );
    }
}

GlobalFilter.propTypes = {
    showFields: PropTypes.arrayOf(PropTypes.oneOf([
        'customer',
        'employer',
        'transporter',
        'departure',
        'destination'
    ]))
}
 
export default compose(
    WithOrgData,
    graphql(
        GetFiltersQuery
    ),
    graphql(
        SetFilterMutation,
        {
            name: 'setFilter'
        }
    ),
    graphql(
        ResetGlobalFilterMutation,
        {
            name: 'resetFilter'
        }
    )
)(GlobalFilter);