import React, { Component } from 'react';
import { Row, Col, Icon, Button, Modal } from 'antd';
import { Credential } from '../Credential';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { CredentialForm } from '../CredentialForm';
import moment from 'moment';
import credentialTypes from '../../credentialTypes';

class CredentialManager extends Component {
    state = {
        currentCredId: null,
        showForm: false,
        formFields: {}
    }
    resetFormFields = () => {
        this.setState({ formFields: {} })
    }
    setFormFields = (fields) => {
        this.setState({
            formFields: {
                ...this.state.formFields,
                ...fields
            }
        })
    }
    getCredentials = () => {
        var credentials = this.props.credentials;
        if (!Array.isArray(credentials)){
            credentials = [];
        }
        return credentials
    }
    getCredentialByType = (type) => {
        return this.getCredentials().find(c => c.type === type)
    }
    setFormVisibility = (val) => this.setState({ showForm: val })
    onSave = (data, isNew) => {
        if (this.props.onSave)
            this.props.onSave(data, isNew)
    }
    typeAlreadyExists = () => {
        const type = this.getFormField('type');
        return this.getCredentialByType(type) ? true : false
    }
    getFormField = (field, attr='value') => {
        try{
            return this.state.formFields[field][attr]
        }catch(error){
            return
        }
    }
    render() {
        var { showAdd=true } = this.props;
        const { colProps={}, showCount=false, disabled=false } = this.props;
        const credentials = this.getCredentials();
        return (
            <div>
                {showCount ? (
                    <p>{`${credentials.length} ${pluralize('Credential', credentials.length)}`}</p>
                ) : null}
                <Row gutter={12}>
                    {credentials.map(cred => {
                        const editing = cred._id === this.state.currentCredId;
                        const credType = credentialTypes[cred.type];
                        return (
                            <Col sm={12} xs={24} {...colProps} >
                                <div style={{ marginBottom: 12 }}>
                                    <Credential
                                        style={{ height: '11rem' }}
                                        size="small"
                                        key={cred._id}
                                        credKey={cred.key}
                                        hasKey={credType.hasKey}
                                        hasExpiration={credType.hasExpiration}
                                        needsButton={credType.needsButton}
                                        expiration={cred.expiration}
                                        title={credType.label}
                                        editing={editing}
                                        disableEditing={disabled}
                                        onChange={({ editing }) => {
                                            const currentCredId = editing ? cred._id : null;
                                            this.setState({
                                                ...this.state,
                                                currentCredId
                                            })
                                        }}
                                        onSave={(data) => this.onSave({
                                            ...cred,
                                            ...data
                                        }, false)}
                                        onRemove={() => this.props.onRemove && this.props.onRemove(cred._id)}
                                    />
                                </div>
                            </Col>
                        )
                    })}
                    {showAdd && !disabled ? (
                        <Col sm={12} xs={24} {...colProps}>
                            <Button type="dashed" block style={{ height: '11rem' }} onClick={() => this.setFormVisibility(true)}>
                                <Icon type="plus" style={{ fontSize: '1.2rem' }} />
                                <span>Add Credential</span>
                            </Button>
                        </Col>
                    ) : null}
                </Row>
                <Modal
                    title="Add Credential"
                    visible={this.state.showForm}
                    onCancel={() => {
                        this.setFormVisibility(false);
                        this.resetFormFields();
                    }}
                    footer={[
                        <Button
                            type="primary"
                            onClick={() => {
                                this.refs.form.validateFieldsAndScroll((err, values) => {
                                    if (!err){
                                        this.setFormVisibility(false);
                                        this.onSave({
                                            ...values,
                                            expiration: moment(values.expiration).format('YYYY-MM-DD')
                                        }, true);
                                        this.resetFormFields();
                                    }
                                })
                            }}
                        >Add Credential</Button>,
                        <Button onClick={() => {
                            this.setFormVisibility(false);
                            this.resetFormFields();
                        }} >Cancel</Button>
                    ]}
                >
                    <CredentialForm 
                        takenTypes={credentials.map(({ type }) => type)}
                        fields={this.state.formFields}
                        onChange={(fields) => this.setState({ formFields: {
                            ...this.state.formFields,
                            ...fields
                        } })}
                        ref="form"
                    />
                </Modal>
            </div>
        );
    }
}

CredentialManager.propTypes = {
    credentials: PropTypes.arrayOf(PropTypes.object),
    showAdd: PropTypes.bool,
    onSave: PropTypes.func,
    onRemove: PropTypes.func
}
 
export default CredentialManager;