import gql from "graphql-tag";

export default gql`
mutation PaxMutation($_id: ID!, $_rev: ID!, $payload: AWSJSON!){
    setBoatCrewISNSelection(_id: $_id, _rev: $_rev, payload: $payload){
        _id
        lastIsnResultWithHeader {
            selectedResult {
                isnStatus {
                    shortLabel
                }
                allowed
                companyID
                quickCheckStatus
                trainingStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
            }
        }
    }
}
`