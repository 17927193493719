import React, { Component } from 'react';
import { List } from 'antd';
import PassengerItem from './PassengerItem';

class PassengerList extends Component {
    render() {
        return <List itemLayout="vertical" {...this.props} renderItem={record => (
            <PassengerItem
                key={record._id}
                data={record}
            />
        )} />;
    }
}
 
export default PassengerList;