import React from 'react';
import { Spin, Icon } from 'antd';

const AppLoading = ({ message, subMessage, style }) => (
    <div style={{ textAlign: 'center', ...style }}>
        <Spin size="large" indicator={<Icon type="loading" style={{ fontSize: '3rem' }} />} />
        <div style={{ paddingTop: '2rem' }}>
            <h2>Loading Manifest Central</h2>
            <div style={{ fontSize: '1.2rem' }}>
                {message}
            </div>
            {subMessage ? (
                <div>{subMessage}</div>
            ) : null}
        </div>
    </div>
)

export default AppLoading