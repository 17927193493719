import { useMutation } from '@apollo/react-hooks';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { DataProxy } from 'apollo-cache';
import { ApolloError } from 'apollo-client';
import { makePersonCovidVaxRecordUnique } from 'components/covid-vax-date-form';
import { PersonCovidVaxRecord } from 'components/covid-vax-date-list';
import gql from 'graphql-tag';
import moment from 'moment';
import { MutationFunctionOptions, MutationResult, OperationVariables } from 'react-apollo';

const MUTATION = gql`
mutation UpdatePersonVaxInfo(
    $modifyVaxRecords: Boolean!
    $modifiedVaxRecords: [BulkUpdatePersonCov19VaxRecordInput!]!
    $deleteVaxRecords: Boolean!
    $deletedVaxRecords: [BulkDeletePersonCov19VaxRecordInput!]!

){
    bulkUpdatePersonCovid19VaxDate(input: $modifiedVaxRecords) @include(if: $modifyVaxRecords){ 
        personID
        order
    }
    bulkDeletePersonCovid19VaxDate(input: $deletedVaxRecords) @include(if: $deleteVaxRecords){ 
        personID
        order
    }
}
`

export interface SubmitterOptions {
    onMutationUpdate?: (proxy: DataProxy, newRecords: PersonCovidVaxRecord[]) => void,
    onCompleted?: (data: any) => void,
    onError?: (error: ApolloError) => void,
    personID: string, 
    personName: string, 
    customerID: string,
    customerName: string,
    employerID: string,
    employerName: string,
    useOptimisticResponse?: boolean
}

export async function buildBulkMutation(
    data: {
        originalVaxRecords: PersonCovidVaxRecord[],
        personID: string,
        personName: string,
        customerID: string,
        customerName: string,
        employerID: string,
        employerName: string
    },
    form: WrappedFormUtils,
    onMutationUpdate?:(proxy: DataProxy, newRecords: PersonCovidVaxRecord[]) => void,
    useOptimisticResponse?: boolean
): Promise<MutationFunctionOptions<any, OperationVariables>> {
    let { personID, personName, customerID, customerName, employerID, employerName, originalVaxRecords=[] } = data;
    let mutationPromise = new Promise((resolve, reject) => {
        form.validateFieldsAndScroll((err, values) => {
            try{

                if (err) {
                    reject(err);
                    return;
                };
        
                let origVaxRecordsMapByDateLabel = new Map<String, PersonCovidVaxRecord>(
                    originalVaxRecords?.map((record) => (
                        [
                            record.label + record.date + record.manufacturer + record.comment,
                            record
                        ]
                    ))
                )
        
                let modifiedOrAddedVaxRecords: PersonCovidVaxRecord[] = [];
                let deletedVaxRecords: Pick<PersonCovidVaxRecord, 'personID' | 'order'>[] = [];
        
                let vaxRowIds = Object.entries(values)
                                            .filter(([ k, v ]) => k.startsWith('covidvax::') && k.endsWith('::date') && v)
                                            .map(([ key ]) => key.split("::")[1])
                
                let vaxFormRows: Map<string, PersonCovidVaxRecord> = new Map(
        
                    // Transform ant-design form values to list of PersonCovidVaxRecord objects
                    vaxRowIds.map((id): [string, PersonCovidVaxRecord] => {
                        function getField(name: string){
                            return values['covidvax::' + id + "::" + name];
                        }
                        let date = moment(getField('date')).format('YYYY-MM-DD');
                        let label = getField('label');
                        let manufacturer = getField('manufacturer');
                        let comment = getField('comment');
                        let renderOrder = getField('renderOrder');
        
                        return [
                            personID + '-' + renderOrder,
                            {
                                personID,
                                personName,
                                order: renderOrder,
                                customerID,
                                customerName,
                                employerID,
                                employerName,
                                comment,
                                label,
                                date,
                                manufacturer
                            }
                        ]
                    })
        
                    // Sort everything by renderOrder
                    .sort((a, b) => {
                        let [, aObj] = a;
                        let [, bObj] = b;
                        if (aObj.order > bObj.order) return 1;
                        if (aObj.order < bObj.order) return -1;
                        return 0;
                    })
        
                    // Set the order field to the actual order of each row
                    .map(([, v ], order) => {
                        return [
                            v.personID + "-" + order,
                            {
                                ...v,
                                order
                            }
                        ]
                    })
                )
                // Check if each vax record was modified or added
                Array.from(vaxFormRows.values()).forEach((record) => {
                    let existing = origVaxRecordsMapByDateLabel[record.label + record.date + record.manufacturer + record.comment];
                    if (
                        existing && (
                            existing.manufacturer !== record.manufacturer ||
                            existing.comment !== record.comment ||
                            existing.date !== record.date ||
                            existing.label !== record.label
                        )
                    ){
                        // If there is an existing record and the values are changed, add this record to the modified records list
                        let modRecord = {
                            ...existing,
                            ...record
                        }
                        delete modRecord["__typename"];
        
                        modifiedOrAddedVaxRecords.push(modRecord);
                    }
        
                    if (!existing){
                        // If this is an entirely new vax date, just add it to the modified records list
                        let newRecord = {
                            ...record
                        }
                        delete newRecord["__typename"];
                        modifiedOrAddedVaxRecords.push(newRecord);
                    }
                })
        
                // Now check if there are vax records that were deleted from the form and add it to the deleted records list
                originalVaxRecords?.forEach((record) => {
                    if (!vaxFormRows.has(personID + '-' + record.order)){
                        deletedVaxRecords.push({
                            personID: personID,
                            order: record.order
                        });
                    }
                })
        
                let stripRecord = (record: PersonCovidVaxRecord) => ({
                    personID: record.personID,
                    order: record.order,
                    __typename: 'PersonCov19VaxRecord'
                })
        
                resolve({
                    variables: {
                        modifyVaxRecords: modifiedOrAddedVaxRecords.length > 0,
                        deleteVaxRecords: deletedVaxRecords.length > 0,
                        modifiedVaxRecords: modifiedOrAddedVaxRecords,
                        deletedVaxRecords: deletedVaxRecords
                    },
                    optimisticResponse: (useOptimisticResponse || false) ? {
                        __typename: 'Mutation',
                        bulkUpdatePersonCovid19VaxDate: modifiedOrAddedVaxRecords.map(stripRecord),
                        bulkDeletePersonCovid19VaxDate: deletedVaxRecords.map(stripRecord)
                    } : undefined,
                    update: (proxy) => {
                        if (onMutationUpdate){
                            let updatedRecords = Array.from(vaxFormRows.values())
        
                                // GraphQL requires __typename to be added
                                .map((record) => ({ ...record, __typename: 'PersonCov19VaxRecord' }))
                                .map(record => makePersonCovidVaxRecordUnique(record))
                                .map((record): PersonCovidVaxRecord => {
                                    let newRecord: any = { ...record }
                                    delete newRecord.isNew;
                                    delete newRecord.id;
                                    return newRecord;
                                });
        
                            onMutationUpdate(proxy, updatedRecords);
                        }
                    }
                })
            }
            catch(e){
                console.log('Failed to build mutation:', e)
            }
        })
    })
    
    return mutationPromise;
}

export function useCovidVaxDateFormSubmitter(
    data: PersonCovidVaxRecord[], 
    options: SubmitterOptions
): [(form: WrappedFormUtils) => void, MutationResult] {

    const [ mutate, mutationResult ] = useMutation(MUTATION, {
        onCompleted: options?.onCompleted,
        onError: options?.onError
    });

    return [
        /*submit */
        async (form: WrappedFormUtils) => {
            let mutationOptions = await buildBulkMutation(
                {
                    originalVaxRecords: data,
                    customerID: options.customerID,
                    customerName: options.customerName,
                    employerID: options.employerID,
                    employerName: options.employerName,
                    personID: options.personID,
                    personName: options.personName
                },
                form,
                options.onMutationUpdate
            )
            if (!mutationOptions) return;

            mutate(mutationOptions)
            .then(() => form.resetFields())
            .catch(() => null)
        },
        mutationResult
    ]
}

