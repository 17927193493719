import PaxForm, { formatFields as ff1 } from './PaxForm'
import CgoForm, { formatFields as ff2 } from './CgoForm';
import ManifestForm, { formatFields as ff3 } from './ManifestForm';
import ISNForm, { formatFields as ff4 } from './ISNForm';
import CovidForm, { formatFields as ff5 } from './CovidForm';
import CovidVaccineForm, { formatFields as ff6 } from 'components/reporting/ReportForms/CovidVaccineForm';
import PersonCovidVaccineForm, { formatFields as ff7 } from 'components/reporting/ReportForms/PersonCovidVaccine';
import PersonHotFuelForm, { formatFields as ff8 } from 'components/reporting/ReportForms/PersonHotFuel';
import PaxIsnEventsForm, { formatFields as ff9 } from 'components/reporting/ReportForms/PaxIsnEvents';

export const getReportForm = (type) => {
    switch (type) {
        case 'COVID':
            return CovidForm;
        case 'PAX':
            return PaxForm;
        case 'CGO':
            return CgoForm
        case 'MANIFEST':
            return ManifestForm
        case 'ISN':
            return ISNForm
        case 'PAX_COVID19_VAX':
            return CovidVaccineForm
        case 'PERSON_COVID19_VAX_DETAILS':
            return PersonCovidVaccineForm
        case 'PERSON_HOTFUEL':
            return PersonHotFuelForm
        case 'PAX_ISN_EVENTS':
            return PaxIsnEventsForm
        default:
            return undefined;
    }
}

export const getFieldFormmatter = (type) => {
    switch (type) {
        case 'PAX':
            return ff1;
        case 'CGO':
            return ff2
        case 'MANIFEST':
            return ff3
        case 'ISN':
            return ff4
        case 'COVID':
            return ff5
        case 'PAX_COVID19_VAX':
            return ff6;
        case 'PERSON_COVID19_VAX_DETAILS':
            return ff7
        case 'PERSON_HOTFUEL':
            return ff8
        case 'PAX_ISN_EVENTS':
            return ff9
        default:
            return undefined;
    }
}