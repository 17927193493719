import React from 'react';
import { Breadcrumb, Spin, Alert, Card, Row, Col, Table, Button, Popconfirm, message, Popover, Icon, Badge, Drawer, Modal, Tooltip, Dropdown, Menu } from 'antd';
import gql from 'graphql-tag';
import LoadingContent from '../../../LoadingContent';
import { graphql, Query } from 'react-apollo';
import compose from 'lodash.flowright';
import { Link, withRouter } from 'react-router-dom';
import commonColumns from '../../../../common/table/columns';
import withISNCheck from '../../../withISNCheck';
import IsnCheckStatusButton from '../../../IsnCheckStatusButton';
import WithOrgData from '../../../WithOrgData';
import { PaxISNWithData } from '../../../isn/PaxISN';
import { PassengerEditorWithData } from '../../../PassengerEditor';
import { CrewISN } from '../../../isn/CrewISN';
import pluralize from 'pluralize';
import { withUserGroups } from '../../../userGroup';
import withBoardingRestrictionDrawer from 'components/withBoardingRestrictionDrawer';
import ETable from 'components/enchanced-antd-table';
import LiveColoredButton from 'components/live-2/LiveColoredButton';
import ColorKey from 'components/live-2/ColorKey';
import {getISNPassFail} from "common/isn";

const FINALIZE = gql`
mutation FinalizeBoat($_id: ID! $tpID: ID!){
    finalizeBoat(_id: $_id tpID: $tpID){
        _id
        dockState
        state
        paxIDList {
            _id
            nodeState
        }
        cgoIDList {
            _id
            nodeState
            approvedBy
        }
    }
}
`

const BOAT = gql`
query Boat($_id: ID!){
    getBoat(_id: $_id){
        _id
        desig
        boatCrewIDs {
            _id
            __typename
            contactInfo {
                name {
                    firstName
                    lastName
                }
            }
            locationID {
                _id
                name
            }
            authorization {
                onWhiteList
                brdRestriction_Isn {
                    status
                    type
                    notes
                    reason
                }
                brdRestriction_Illness {
                    status
                    type
                    notes
                    reason
                }
                brdRestriction_Quarantined {
                    status
                    type
                    notes
                    reason
                }
            }
            lastIsnResultWithHeader {
                isn_customer
                isn_ownerid
                success
                error_message
                quick_check_status
                reason
                qc_count
                qc_result {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
                selectedResult {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
            }
        }
        paxIDList {
            _id
            __typename
            employerID {
                _id
                name
            }
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            personID {
                _id
                dob
                nation
            }
            paxWeight
            bagWeight
            bagCount
            lastName
            firstName
            transitType
            checkedInTime
            checkInType
            authorization {
                onWhiteList
                brdRestriction_Isn {
                    status
                    type
                    notes
                    reason
                }
                brdRestriction_Illness {
                    status
                    type
                    notes
                    reason
                }
                brdRestriction_Quarantined {
                    status
                    type
                    notes
                    reason
                }
            }
            lastIsnResultWithHeader {
                isn_customer
                isn_ownerid
                success
                error_message
                quick_check_status
                reason
                qc_count
                qc_result {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
                selectedResult {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
            }
        }
        destinationIDs {
            _id
            name
            type
            block
            field
            ownerOrg
            modifiedTs
        }
        departureIDs {
            _id
            name
            type
            block
            field
            ownerOrg
            modifiedTs
        }
    }
}
`

const REMOVE_PAX = gql`
mutation RemPaxFromBoat(
    $boatID: ID!
    $paxIDs: [ID]!
    $tpID: ID!
){
    removePassengersFromBoat(
        boatID: $boatID
        paxIDs: $paxIDs
        tpID: $tpID
    ) {
        _id
        paxIDList {
            _id
        }
    }
}
`

const MUTATION_MAKE_PAX_ONSITE = gql`
mutation MakePaxOnSite($paxID: ID!){
    ChangePaxCheckInType(paxID: $paxID, newCheckInType: "WEBDOCKS"){
        _id
        checkInType
    }
}
`

class BoatDetail extends React.Component {
    state = {
        tabKey: 'pax',
        finalizing: false,
        isnDrawer: {
            id: null,
            visible : null,
            type: null
        },
        editPaxModal: {
            id: null,
            visible: null
        },
        selectedPax: [],
        makingPaxOnSite: null
    }

    getPaxPassFail = (pax) => {
        let isnCheckPax = this.props.isncheck.results.find(res => res._id === pax);
        return getISNPassFail(isnCheckPax || pax)
    }

    setIsnDrawer = (visible, id, type) => {
        this.setState({
            isnDrawer: {
                id: id || this.state.id,
                visible,
                type
            }
        })
    }
    setPaxModal = (visible, id) => {
        this.setState({
            editPaxModal: {
                id: id || this.state.editPaxModal.id,
                visible
            }
        })
    }
    componentDidMount(){
        this.props.data.startPolling(5000)
    }

    // Validate pax before finalizing
    validatePax = () => {
        if (!this.props.data || !this.props.data.getBoat){
            return null;
        }
        const { getBoat: { paxIDList } } = this.props.data;

        for (const pax of paxIDList){
            const passFail = this.getPaxPassFail(pax);
            if (!passFail.passed){
                return {
                    valid: false,
                    reason: 'One or more passengers has a bad ISN status. All passengers with a bad ISN status must be overridden first.'
                };
            }
        }

        return {
            valid: true
        };
    }

    finalize = () => {
        const { mutate, history } = this.props;
        this.setState({ finalizing: true })

        const paxValResult = this.validatePax();
        if (!paxValResult.valid){
            message.error(paxValResult.reason, 10);
            return;
        }

        mutate()
        .then(() => {
            message.success("Boat successfully finalized")
            history.push('/app/boats')
        })
        .catch(error => {
            message.error("Failed to finalize boat")
        })
        .then(() => this.setState({ finalizing: false }))
    }
    handleISNCheck = async () => {
        const startTab = this.state.tabKey === 'crew' ? 'crew' : 'pax';
        const endtab = startTab === 'pax' ? 'crew' : 'pax';
        const { getBoat: { paxIDList, boatCrewIDs } } = this.props.data;
        this.props.data.stopPolling()
        const runIsnCheck = (currentTab) => {
            this.setState({ tabKey: currentTab });
            if (currentTab === 'pax'){
                return this.props.isncheck.run(paxIDList)
            }else if (boatCrewIDs.length){
                return this.props.isncheck.run(boatCrewIDs)
            }
        }
        await runIsnCheck(startTab);
        await runIsnCheck(endtab);
        this.setState({ tabKey: startTab })
        
        this.props.data.startPolling(5000)
    }
    renderDesig = () => {
        const { data: { getBoat, loading } } = this.props;
        return (loading && !getBoat) ? <span><Spin /> Loading</span> : getBoat && getBoat.desig
    }
    removePassengers = () => {
        const { removePassengers } = this.props;
        const { data: { getBoat: boat } } = this.props;
        const { selectedPax } = this.state;
        removePassengers({
            variables: {
                paxIDs: this.state.selectedPax,
                boatID: this.props.match.params.boatid,
                tpID: this.props.orgData.transporter._id
            },
            optimisticResponse: {
                __typename: 'Mutation',
                removePassengersFromBoat: {
                    __typename: 'BoatNode',
                    _id: boat._id,
                    paxIDList: boat.paxIDList.filter(pax => !selectedPax.includes(pax._id))
                }
            }
        })
        .then(() => {
            message.success('Boat successfully updated');
            this.setState({ selectedPax: [] })
        })
        .catch(() => message.error('Failed to update boat due to error'))
    }
    makePaxOnSite = (paxID) => {
        const { makePaxOnSite } = this.props;
        this.setState({ makingPaxOnSite: paxID });

        makePaxOnSite({
            variables: {
                paxID: paxID
            }
        })
        .then(() => {
            this.setState({ makingPaxOnSite: null });
            message.success("Passenger made on-site successfully.");
        })
        .catch(() => {
            this.setState({ makingPaxOnSite: null });
            message.error("Failed to make passenger on-site due to an error.")
        })
    }
    renderContent = () => {
        const { data: { getBoat, loading, error }, isncheck, userGroups } = this.props;
        if (loading) return <LoadingContent />
        if (error) return <Alert type="error" message="Failed to load boat" description={error.message} showIcon />
        const destLen = getBoat.destinationIDs ? getBoat.destinationIDs.length : 0;
        const departLen = getBoat.departureIDs ? getBoat.departureIDs.length : 0;
        const paxLen = getBoat.paxIDList ? getBoat.paxIDList.length : 0;
        const crewLen = getBoat.boatCrewIDs ? getBoat.boatCrewIDs.length : 0;
        const renderCrew = (crewMembers) => (
            <Table
                dataSource={crewMembers}
                rowKey={record => record._id}
                size="small"
                className="mc-table"
                pagination={false}
                columns={[
                    ...commonColumns.crew.some(['name', 'location']),
                    {
                        ...commonColumns.crew.columns.isn,
                        render: (text, record) => {
                            const isnCheckResult = isncheck.results.find(res => res._id === record._id);
                            if (isnCheckResult){
                                if (isnCheckResult.running){
                                    return (
                                        <span><Spin size="small" />  Checking</span>
                                    )
                                }
                                else if (isnCheckResult.error){
                                    const message = isnCheckResult.error.message.replace('GraphQL error: NotFoundError: ', '')
                                    const content = <span style={{color: 'red'}}>{message}</span>
                                    return (
                                        <React.Fragment>
                                            <Popover content={content} placement="left">
                                                <span style={{color: 'red'}}>Error</span>
                                            </Popover>
                                            <span style={{color: 'red', marginLeft: '0.5rem', cursor: 'pointer'}} onClick={() => {
                                                isncheck.clearResultError(record._id)
                                            }}><Icon type="close-circle" /></span>
                                        </React.Fragment>
                                    )
                                }
                            }
                            if (record.transitType !== 'INBOUND'){
                                return <IsnCheckStatusButton pax={record} size="small" onClick={() => {
                                    this.setIsnDrawer(true, record._id, 'crew')
                                }} />
                            }
                            return null
                        }
                    }
                ]}
            />
        )
        const renderPassengers = (passengers) => {
            const canRemovePax = userGroups.includes('flytsuite.docks.removepassenger');
            const restriction = commonColumns.pax.columns.restrictions;
            return (
                <div>
                    <div style={{ marginBottom: 12, display: 'flex' }}>
                        {canRemovePax ? (
                            <Popconfirm
                            title={
                                <span>
                                    Are you sure you want to remove these passengers from this boat? This action <strong>cannot</strong> be undone!
                                </span>
                            }
                            onConfirm={() => this.removePassengers()}
                            >
                                <Button
                                    disabled={!canRemovePax || !this.state.selectedPax.length}
                                    type="danger"
                                >
                                    {this.state.selectedPax.length ? 
                                        `Remove ${this.state.selectedPax.length} ${pluralize('Passenger', this.state.selectedPax.length)}` :
                                        'Remove Passengers'
                                    }
                                </Button>
                            </Popconfirm>
                        ) : (
                            <span style={{ marginLeft: 12, color: 'red' }}>
                                You do not have permission to remove passengers
                            </span>
                        )}
                        <Dropdown
                            overlay={<Menu>
                                <div style={{ padding: '6px 12px' }}>
                                    <h4>Pax Name Color Legend</h4>
                                    <ColorKey type="overnight" text="Overnight" />
                                    <ColorKey type="expected" text="Expected" />
                                </div>
                            </Menu>
                                
                            }
                        >
                            <Button style={{ marginLeft: 'auto' }}>Legend <Icon type="down" /></Button>
                        </Dropdown>
                    </div>
                    <ETable
                        dataSource={passengers}
                        rowKey={record => record._id}
                        size="small"
                        className="mc-table mc-table-scroll-auto mc-table-header-nowrap"
                        style={{ maxWidth: '100%' }}
                        flexMode
                        bordered
                        pagination={false}
                        rowSelection={ canRemovePax && {
                            selectedRowKeys: this.state.selectedPax,
                            onChange: (keys) => this.setState({ selectedPax: keys })
                        }}
                        columns={[
                            {
                                ...commonColumns.pax.columns.name,
                                render: (_, record) => (
                                    <Tooltip
                                        title={"Passenger's Check In Type is " + record.checkInType}
                                        placement="right"
                                        mouseEnterDelay={1}>
                                        <LiveColoredButton type={record.checkInType} className="mc-link-btn" onClick={() => this.setPaxModal(true, record._id)}>{`${record.lastName}, ${record.firstName}`}</LiveColoredButton>
                                    </Tooltip>
                                )
                            },
                            ...commonColumns.pax.some(['employer', 'weight', 'bagWeight', 'bagCount', 'checkedIn', 'departure', 'destination']),
                            {
                                ...commonColumns.pax.columns.restrictions,
                                render: (text, record, index) => (
                                    restriction.render(text, record, index, () => this.props.boardingRestrictionsDrawer.open(record._id))
                                )
                            },
                            {
                                ...commonColumns.pax.columns.isn,
                                render: (text, record) => {
                                    const isnCheckResult = isncheck.results.find(res => res._id === record._id);
                                    if (isnCheckResult){
                                        if (isnCheckResult.running){
                                            return (
                                                <span><Spin size="small" />  Checking</span>
                                            )
                                        }
                                        else if (isnCheckResult.error){
                                            const message = isnCheckResult.error.message.replace('GraphQL error: NotFoundError: ', '')
                                            const content = <span style={{color: 'red'}}>{message}</span>
                                            return (
                                                <React.Fragment>
                                                    <Popover content={content} placement="left">
                                                        <span style={{color: 'red'}}>Error</span>
                                                    </Popover>
                                                    <span style={{color: 'red', marginLeft: '0.5rem', cursor: 'pointer'}} onClick={() => {
                                                        isncheck.clearResultError(record._id)
                                                    }}><Icon type="close-circle" /></span>
                                                </React.Fragment>
                                            )
                                        }
                                    }
                                    if (record.transitType !== 'INBOUND'){
                                        return <IsnCheckStatusButton pax={record} size="small" onClick={() => {
                                            this.setIsnDrawer(true, record._id, 'pax')
                                        }} />
                                    }
                                    return null
                                }
                            },
                            {
                                title: 'Actions',
                                key: 'actions',
                                width: 40,
                                render: (_, record) => {
                                    if (record.checkInType === 'EXPECTED'){
                                        return <Tooltip title='Change passenger from expected to on-site'>
                                            <Popconfirm
                                                title="Are you sure you want to make this passenger on-site? THIS OPERATION CANNOT BE UNDONE!"
                                                onConfirm={() => this.makePaxOnSite(record._id)}
                                                >
                                                <Button
                                                    size='small'
                                                    type='primary'
                                                    loading={this.state.makingPaxOnSite === record._id}
                                                    disabled={this.state.makingPaxOnSite !== null && this.state.makingPaxOnSite !== record._id}
                                                >Make On-Site</Button>
                                            </Popconfirm>
                                            
                                        </Tooltip>
                                    }
                                }
                            }
                        ]}
                    />
                </div>
            )
        }
        const renderLocations = (locations) => (
            <Table
                dataSource={locations}
                rowKey={record => record._id}
                size="small"
                className="mc-table"
                pagination={false}
                columns={commonColumns.location.all()}
            />
        )
        const tabContent = {
            depart: renderLocations(getBoat.departureIDs),
            dest: renderLocations(getBoat.destinationIDs),
            pax: renderPassengers(getBoat.paxIDList),
            crew: renderCrew(getBoat.boatCrewIDs)
        }
        const Count = (props) => <Badge {...props} style={{
            marginBottom: '-0.25rem',
            marginLeft: '0.4rem',
            background: '#1890ff'
        }} />
        return (
            <Card
                tabList={[
                    {
                        key: 'pax',
                        tab: (
                            <div>
                                <span>Passengers</span>
                                <Count count={paxLen} />
                            </div>
                        )
                    },
                    {
                        key: 'crew',
                        tab: (
                            <div>
                                <span>Crew Members</span>
                                <Count count={crewLen} />
                            </div>
                        )
                    },
                    {
                        key: 'depart',
                        tab: (
                            <div>
                                <span>Departures</span>
                                <Count count={departLen} />
                            </div>
                        )
                    },
                    {
                        key: 'dest',
                        tab: (
                            <div>
                                <span>Destinations</span>
                                <Count count={destLen} />
                            </div>
                        )
                    }
                ]}
                onTabChange={(tabKey) => this.setState({ tabKey })}
                activeTabKey={this.state.tabKey}
            >
            {tabContent[this.state.tabKey]}
            </Card>
        )
    }
    render(){
        const { isncheck } = this.props;

        let cannotFinalizeReason = null;

        const paxValResult = this.validatePax();

        if (paxValResult && !paxValResult.valid){
            cannotFinalizeReason = paxValResult.reason;
        }

        console.debug(paxValResult, cannotFinalizeReason);

        return (
            <div style={{ height: '100%', overflowX: 'auto', padding: '12px' }}>
                <div style={{  minWidth: '600px' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/app/boats" >Live Boats</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.renderDesig()}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Row style={{margin: '0.5rem 0 1rem 0'}}>
                        <Col span={12}>
                            <h2 style={{margin: 0}}>{this.renderDesig()}</h2>
                        </Col>
                        <Col span={12}>
                            <Row type="flex" justify="end" gutter={12}>
                                <Col>
                                    <Button loading={isncheck.running} onClick={this.handleISNCheck} >
                                        Run ISN Check
                                    </Button>
                                </Col>
                                <Col>
                                    <Popconfirm disabled={Boolean(cannotFinalizeReason)} okText="Finalize" title="Are you sure you want to finalize this boat?" placement="bottomLeft" onConfirm={this.finalize}>
                                        <Button
                                            disabled={Boolean(cannotFinalizeReason)}
                                            loading={this.state.finalizing}
                                            type="primary"
                                        >Finalize</Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {cannotFinalizeReason ? (
                        <Alert
                            type="warning"
                            showIcon
                            style={{ marginBlock: '1rem' }}
                            message="Finalize disabled"
                            description={
                                <div>
                                    <p>{cannotFinalizeReason}</p>
                                    <Button
                                        type="primary"
                                        onClick={this.handleISNCheck}
                                        loading={isncheck.running}
                                    >Re-run ISN Check</Button>
                                </div>
                            }
                        />
                    ) : null}
                    <div>
                        {this.renderContent()}
                    </div>
                </div>
                <Modal
                    visible={this.state.editPaxModal.visible}
                    onCancel={() => this.setPaxModal(false)}
                    width="50rem"
                    title="Edit Passenger"
                    footer={false}
                >
                    {this.state.editPaxModal.id ? <PassengerEditorWithData edit={true} paxId={this.state.editPaxModal.id} onCancel={() => this.setPaxModal(false)} /> : null}
                </Modal>
                {this.state.isnDrawer.type === 'pax' ? (
                    <Query 
                    query={gql`
                        query PaxName($id: ID!) {
                            getPassenger(_id: $id){
                                _id
                                lastName
                                firstName
                            }
                        }
                    `}
                    variables={{ id: this.state.isnDrawer.id }}
                    skip={!this.state.isnDrawer.id || !this.state.isnDrawer.visible}>
                    {({ data, loading, error }) => {
                        const crewMember = data && data.getPassenger;
                        var title;
                        title = crewMember ? `${crewMember.lastName}, ${crewMember.firstName}` : '';
                        if (loading){
                            title = 'Loading...'
                        }
                        return (
                            <Drawer
                            title={title}
                            visible={this.state.isnDrawer.visible}
                            width="50rem"
                            onClose={() => this.setIsnDrawer(false)}
                            closable
                            >
                            {error ? <Alert type="error" showIcon message="Failed to load crewMember" /> : null}
                            {crewMember ? <PaxISNWithData paxId={this.state.isnDrawer.id} /> : null}
                            </Drawer>
                        )
                    }}
                    </Query>
                ) : null}
                {this.state.isnDrawer.type === 'crew' ? (
                    <Query 
                    query={gql`
                        query GetBoatCrewName($id: ID!) {
                            getBoatCrew(_id: $id){
                                _id
                                contactInfo {
                                    name {
                                        lastName
                                        firstName
                                    }
                                }
                            }
                        }
                    `}
                    variables={{ id: this.state.isnDrawer.id }}
                    skip={!this.state.isnDrawer.id || !this.state.isnDrawer.visible}>
                    {({ data, loading, error }) => {
                        const crewMember = data && data.getBoatCrew;
                        const contactInfo = crewMember && crewMember.contactInfo;
                        const name = contactInfo && contactInfo.name;
                        var title;
                        title = crewMember ? `${name.lastName}, ${name.firstName}` : '';
                        if (loading){
                            title = 'Loading...'
                        }
                        return (
                            <Drawer
                            title={title}
                            visible={this.state.isnDrawer.visible}
                            width="50rem"
                            onClose={() => this.setIsnDrawer(false)}
                            closable
                            >
                            {error ? <Alert type="error" showIcon message="Failed to load crew member" /> : null}
                            {crewMember ? <CrewISN crewId={this.state.isnDrawer.id} /> : null}
                            </Drawer>
                        )
                    }}
                    </Query>
                ) : null}
            </div>
        )
    }
}

const BoatDetailWithData = compose(
    withRouter,
    withISNCheck,
    WithOrgData,
    withUserGroups,
    withBoardingRestrictionDrawer,
    graphql(
        BOAT,
        {
            options: props => {
                return {
                    variables: {
                        _id: props.match.params.boatid
                    },
                    fetchPolicy: 'network-only'
                }
            }
        }
    ),
    graphql(
        FINALIZE,
        {
            options: props => {
                return {
                    variables: {
                        _id: props.match.params.boatid,
                        tpID: props.orgData.transporter._id
                    }
                }
            }
        }
    ),
    graphql(
        REMOVE_PAX,
        {
            name: 'removePassengers'
        }
    ),
    graphql(
        MUTATION_MAKE_PAX_ONSITE,
        {
            name: 'makePaxOnSite'
        }
    )
)(BoatDetail)

export default BoatDetailWithData