import PrivateRoute from 'components/PrivateRoute';
import AdminUserPage from 'pages/admin/user';
import FWChangesByConsoleReportPage from 'pages/app/admin/changes-by-console-report';
import FWBillingReportPage from 'pages/app/admin/flytwatch-billing-report';
import React from 'react';

export function getAdminRoutes() {
    return [
        <PrivateRoute exact path='/app/admin/user' render={() => <AdminUserPage />} />,
        <PrivateRoute exact path='/app/admin/fw-billing-report' render={() => <FWBillingReportPage style={{ margin: -24, height: 'calc(100% + 48px)' }} />} />,
        <PrivateRoute exact path='/app/admin/fw-changes-by-console' render={() => <FWChangesByConsoleReportPage style={{ margin: -24, height: 'calc(100% + 48px)' }} />} />
    ]
}

// const AdminRoutes = (props: any) => {
//     return <>
//         <PrivateRoute exact path='/app/admin/user' render={() => <AdminUserPage style={props.style} />}/>
//     </>
// }

// export default AdminRoutes;