import React, { Component } from 'react';
import withBoardingRestrictionDrawer from '../withBoardingRestrictionDrawer';
import { FilterableTable } from './FilterableTable';
import withFlightDrawer from '../WithFlightDrawer';
import { compose } from 'recompose';
import { Query } from 'react-apollo';
import { Drawer, Alert } from 'antd';
import { PaxISNWithData } from '../isn/PaxISN';
import gql from 'graphql-tag';
import { classNames } from 'common/util';
import './PassengerTable-style.less';
import { replaceColumn } from 'common/table/columns';

class PassengerTable extends Component {
    state = {
        isnDrawer: {
            visible: false,
            id: null
        }
    }
    ColorizedRow = (props) => {
        let pax = this.props.dataSource && this.props.dataSource.find((pax) => pax?._id === props['data-row-key']);
        let cn = classNames(props.className, 'mc-table-passengers-row-' + String(pax.checkInType).toLowerCase());
        return <tr {...props} className={cn} title={`Passenger check-in type is ` + pax.checkInType} />
    }
    setIsnDrawer = (visible, id) => {
        this.setState({
            ...this.state,
            isnDrawer: {
                visible,
                id: id || this.state.isnDrawer.id
            }
        })
    }
    render() {
        let { columns, ...restProps } = this.props;
        const isn = columns.find(col => col.key === 'isnStatus');
        const restriction = columns.find(col => col.key === 'restrictions');
        if (isn){
            columns = replaceColumn(columns, 'isnStatus', {
                render: (text, record, index) => (isn.render(text, record, index, () => this.setIsnDrawer(true, record._id)))
            })
        }
        if (restriction){
            columns = replaceColumn(columns, 'restrictions', {
                render: (text, record, index) => (
                    restriction.render(text, record, index, () => this.props.boardingRestrictionsDrawer.open(record._id))
                )
            })
        }
        return (
            <React.Fragment>
                <FilterableTable
                    {...restProps}
                    className={classNames(restProps.className, 'mc-table-passengers')}
                    columns={columns}
                    components={{
                        body: {
                            // Uncomment below to enable row coloring
                            // row: this.ColorizedRow
                        }
                    }}
                />
                <Query 
                query={gql`
                    query PaxName($id: ID!) {
                        getPassenger(_id: $id){
                            _id
                            lastName
                            firstName
                        }
                    }
                `}
                variables={{ id: this.state.isnDrawer.id }}
                skip={!this.state.isnDrawer.id || !this.state.isnDrawer.visible}>
                {({ data, loading, error }) => {
                    const passenger = data && data.getPassenger;
                    var title;
                    title = passenger ? `${passenger.lastName}, ${passenger.firstName}` : '';
                    if (loading){
                        title = 'Loading...'
                    }
                    return (
                        <Drawer
                        title={title}
                        visible={this.state.isnDrawer.visible}
                        width="50rem"
                        onClose={() => {
                            this.props.refetch && this.props.refetch()
                            this.setIsnDrawer(false)
                        }}
                        closable
                        >
                        {error ? <Alert type="error" showIcon message="Failed to load passenger" /> : null}
                        {passenger ? <PaxISNWithData paxId={passenger._id} /> : null}
                        </Drawer>
                    )
                }}
                </Query>
            </React.Fragment>
        );
    }
}
 
export default compose(
    withFlightDrawer(),
    withBoardingRestrictionDrawer
)(PassengerTable)