import { MultiSelectReturn } from "../../../hooks/useMultiSelect";
import { ColumnProps } from "antd/lib/table";
import { Badge, Checkbox, Menu, Tooltip } from "antd";
import React from "react";

type TEMP_TYPE_Pax = any;
type TEMP_TYPE_Cgo = any;
type TEMP_TYPE_Entity = TEMP_TYPE_Pax | TEMP_TYPE_Cgo

export function createLegListSelectColumn(multiSelect: NonNullable<MultiSelectReturn<TEMP_TYPE_Entity>>, legEntities: NonNullable<TEMP_TYPE_Entity[]>){

    // Contains ONLY the selectedEntities for this leg
    const selectedEntities = multiSelect.selectedItems
        .filter(e => Boolean(legEntities.find(ce => e._id === ce._id )));

    // Contains everything except the entities in this leg
    const selectedItemsDifference = multiSelect.selectedItems
        .filter(e => !legEntities.find(se => e._id === se._id))

    // Contains everything plus the entities from this leg
    const selectedItemsAddAll = [
        ...selectedItemsDifference,
        ...legEntities
    ]

    // True if there are entities that could be added to selectedItems
    const hasEntitiesToAdd = selectedEntities.length + selectedItemsDifference.length < selectedItemsAddAll.length;

    const toggleAll = () => {
        if (hasEntitiesToAdd){
            multiSelect.setSelectedItems(selectedItemsAddAll);
        }
        else{
            multiSelect.setSelectedItems(selectedItemsDifference);
        }
    }

    const column: ColumnProps<TEMP_TYPE_Entity> = {
        title: <Checkbox
            checked={selectedEntities.length === legEntities.length}
            indeterminate={selectedEntities.length > 0 && selectedEntities.length < legEntities.length}
            onChange={toggleAll}
        />,
        key: 'selection',
        fixed: 'left',
        width: 50,
        render: (record: TEMP_TYPE_Entity) => <Checkbox checked={multiSelect.isSelected(record?._id)} onChange={() => multiSelect.toggleSelection(record)} />
    }

    return column;
}

export function selectByScheduledGroup(
    multiSelect: NonNullable<MultiSelectReturn<TEMP_TYPE_Entity>>,
    allEntities: TEMP_TYPE_Entity[],
    groupName: string
){
    const entitiesToSelect = allEntities.filter(e => e.groupName === groupName);

    // Remove selected items already in the list
    const difference = multiSelect.selectedItems.filter(e => !entitiesToSelect.find(se => se._id === e._id));

    multiSelect.setSelectedItems([...difference, ...entitiesToSelect]);
}

export function getGroupNames(entities: TEMP_TYPE_Entity[]){
    let groups = new Set<string>();

    entities.forEach(e => groups.add(e.scheduledGroup));

    return Array.from(groups)
        .sort();
}

export function renderGroupNameMenuItems(
    multiSelect: MultiSelectReturn<TEMP_TYPE_Entity>,
    entities: TEMP_TYPE_Entity[]
){
    let groupNames = getGroupNames(entities)

    function handleMenuClick(groupName: string){

        const selected = entities.filter(e => e.scheduledGroup === groupName);

        const difference = multiSelect.selectedItems
            .filter(e => !selected.find(se => se._id === e._id));

        multiSelect.setSelectedItems([...difference, ...selected]);
    }

    return groupNames.map((name) => (
        <Menu.Item key={name} onClick={() => handleMenuClick(name)}>{name}</Menu.Item>
    ));
}

export const SelectedEntityCounter: React.FC<{ legSelectedEntities: TEMP_TYPE_Entity[] }> = (props) => {
    if (!props.legSelectedEntities.length){
        return null;
    }
    return (
        <Tooltip title={`${props.legSelectedEntities.length} selected`}>
            <Badge
                style={{ backgroundColor: '#1890ff', color: 'white' }}
                count={props.legSelectedEntities.length}
            />
        </Tooltip>
    )
}