import React from 'react';
import { Table } from 'antd';
import { DragSource, DropTarget } from 'react-dnd';
import ETable from 'components/enchanced-antd-table';

export function dragDirection(
  dragIndex,
  hoverIndex,
  initialClientOffset,
  clientOffset,
  sourceClientOffset,
) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2;
  const hoverClientY = clientOffset.y - sourceClientOffset.y;
  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward';
  }
  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward';
  }
}

export class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: 'grab' };

    let className = restProps.className;
    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      );
      if (direction === 'downward') {
        className += ' drop-over-downward';
      }
      if (direction === 'upward') {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr
          {...restProps}
          className={className}
          style={style}
        />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    console.log('getItem', monitor.getItem())
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

export const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    sourceClientOffset: monitor.getSourceClientOffset(),
  }
})(
  DragSource('row', rowSource, (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      dragRow: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      initialClientOffset: monitor.getInitialClientOffset(),
    }
  })(BodyRow)
);

export const DraggableBodyRowOutbound = DropTarget('outbound', rowTarget, (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    sourceClientOffset: monitor.getSourceClientOffset(),
  }
})(
  DragSource('outbound', rowSource, (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      dragRow: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      initialClientOffset: monitor.getInitialClientOffset(),
    }
  })(BodyRow)
);

export const DraggableBodyRowInbound = DropTarget('inbound', rowTarget, (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    sourceClientOffset: monitor.getSourceClientOffset(),
  }
})(
  DragSource('inbound', rowSource, (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      dragRow: monitor.getItem(),
      clientOffset: monitor.getClientOffset(),
      initialClientOffset: monitor.getInitialClientOffset(),
    }
  })(BodyRow)
);

const DraggableTable = props => {
  function onRow(...args) {
    let rowProps = {
      index: args[1],
      moveRow: () => null
    }

    if (typeof props.onRow === 'function'){
      rowProps = { ...rowProps, ...props.onRow(...args) };
    }

    if (typeof props.onMoveRow === 'function'){
      rowProps.moveRow = props.onMoveRow
    }
    
    return rowProps
  }
  let className;
  if ( typeof props.onMoveRow === 'function' ){
    className = 'draggable-table'
  }
  if (props.className){
    className += ' ' + props.className
  }
  return <ETable 
    {...props}
    className={className}
    components={{
        body: {
            row: DraggableBodyRow
        }
    }}
    onRow={onRow}
    />
}


export const DraggableTableOutbound = props => (
  <ETable 
      className="draggable-table"
      components={{
          body: {
              row: DraggableBodyRowOutbound
          }
      }}
      onRow={(record, index) => ({
          index,
          moveRow: props.onMoveRow
      })} 
      {...props}/>
)


export const DraggableTableInbound = props => (
  <ETable 
      className="draggable-table"
      components={{
          body: {
              row: DraggableBodyRowInbound
          }
      }}
      onRow={(record, index) => ({
          index,
          moveRow: props.onMoveRow
      })} 
      {...props}/>
)



export default DraggableTable