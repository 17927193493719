const organizationResolv = {
    Mutation: {
        organization: (_, {payload}, {cache}) => {
            const organization = {
                _id: payload._id,
                name: payload.name,
                classType: payload.classType,
                __typename: 'Organization'
            };
            const data = {
                organization
            }
            cache.writeData({data})
            return organization
        }
    }
}

export default organizationResolv;