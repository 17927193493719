import { Layout, Button } from 'antd';
import PrivateRoute from 'components/PrivateRoute';
import FlightboardPage from 'pages/app/flightboard/flight-board-page';
import FlightboardLocationSelectPage from 'pages/app/flightboard/location-page';
import qs from 'qs';
import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { getWindowRoutes } from 'routes/window';
import '../css/App.css';
import EmployerMain from '../pages/app/employer';
import DocksMain from './docks/main';
import FlightsMain from './flights/main';
import GlobalNavbar from './GlobalNavbar';
import NotAuthorized from './NotAuthorized';
import PobMain from './pob/main';
import { UserGroup, withUserGroups } from './userGroup';
import WithOrgData from './WithOrgData';
import { AuthContext } from 'context/auth';

const basePath = "/app/";

const NotApproved = () => (
  <NotAuthorized
    title="Account Not Approved" 
    desciption="In order to use Manifest Central, your account must first be approved by a DataFlyt representative."
    />
)

/**
 * Renders main application
 */
class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null
    }
  }

  componentDidMount(){
    if (this.props.themeContext){
      this.props.themeContext.setThemingEnabled(true);
    }
  }

  componentDidCatch(error){
    this.setState({ error })
  }

  /** Choose different sets of routes depending on the organization. Also displays sidebar navigation. */
  renderMain = () => {
    const { organization: org, transporter } = this.props.orgData;
    switch (org && org.classType) {
      case 'flytsuite.transporter':
        return <FlightsMain />
      case 'flytsuite.customer':
        switch(transporter.type){
          case 'boat':
            return <DocksMain/>
          case 'flight':
            return <FlightsMain/>
          case 'pob':
            return <PobMain/>
            default:
              return null;
        }
      case 'flytsuite.employer':
        return <EmployerMain />
      default:
        return null;
    }
  }

  render(){
    if (this.state.error){
      return (
        <AuthContext.Consumer>
          {({ signOut }) => (
            <Layout>
              <NotAuthorized 
                icon="exclamation-circle"
                title="An error occurred"
                desciption="An error occurred causing the application to stop unexpectedly. Please try again later."
                extra={<Button onClick={signOut} type='primary'>Log Out</Button>}
              />
            </Layout>
          )}
        </AuthContext.Consumer>
      )
    }
    return (
      <Layout style={{ height: '100vh', overflow: 'hidden' }}>

        {/* User must have default user group to be allowed access to the application */}
        <UserGroup.Flytsuite.Default renderNotAuthorized={<NotApproved />}>
          <Switch>
            <PrivateRoute
              exact
              path={basePath + "flightboard"}
              render={({ location, history }) => {
                let qsParams = qs.parse(location.search, { ignoreQueryPrefix: true });
                if (qsParams.locationID){
                  return <FlightboardPage locationID={qsParams.locationID} />
                }
                else
                {
                  return <>
                    <GlobalNavbar hideMenuCollapse />
                    <FlightboardLocationSelectPage
                      onSubmit={(locationID) => history.push('/app/flightboard?locationID=' + locationID)}
                    />
                  </>
                }
              }}
            />
            <PrivateRoute
              path={basePath + 'window'}
              render={() => getWindowRoutes()}
            />
            <PrivateRoute render={() => <>
              <GlobalNavbar />
              {this.renderMain()}
            </>} />
          </Switch>
        </UserGroup.Flytsuite.Default>
      </Layout>
    )
  }
}

export default withRouter( withUserGroups( WithOrgData(App) ) );
