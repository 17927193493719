import { RenderMode, SelectionModes, Table } from '@blueprintjs/table';
import { Alert, Col, Icon, Input, Row, Spin } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import gql from 'graphql-tag';
import { compact } from 'lodash';
import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { hasCarrier, renderAssignedFlightColumn, renderCheckboxColumn } from '..';
import { PaxNodeColumnSet } from '../../../common/table/blueprintcolumns';
import { safeGet } from '../../../common/util';
import useControlledState from '../../../hooks/useControlledState';
import { useMultiSelectByID } from '../../../hooks/useMultiSelectByID';
import BlueprintTableInfiniteScroller from '../../BlueprintTableInfiniteScroller';
import NonIdealState from '../../NonIdealState';
import WithOrgData, { WithOrgDataProps } from '../../WithOrgData';
import { SearchProps } from '../interface';
import '../search.less';
import SearchHeader from '../SearchHeader';

const PAX_QUERY = gql`
query PaxSearch($filter: PassengersFilterInput, $limit: Int, $skip: Int){
    passengers(filter: $filter, limit: $limit, skip: $skip){
        docs {
            ... on PaxNode {
                _id
                lastName
                firstName
                checkedInTime
                employerID {
                    _id
                    name
                }
                customerID {
                    _id
                    name
                }
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                currentCarrierID {
                    _id
                    desig
                }
                transitType
            }
        }
    }
}
`

export interface SearchState {
    lastName?: string,
    firstName?: string,
    includeOtherCarriers: boolean,
    offset: number
}

export const initialSearchState: SearchState = {
    lastName: null,
    firstName: null,
    includeOtherCarriers: false,
    offset: 0
}

export interface ForceRefresh {
    onForceRefresh(fr: boolean): void,
    forceRefresh?: boolean
}

const PaxSearch: React.FC<SearchProps & WithOrgDataProps & ForceRefresh & React.HTMLAttributes<HTMLDivElement>> = ({
    forceRefresh,
    onForceRefresh,
    pageLimit=50,
    onSelection,
    filterOptions,
    selectedItems: selectedItemsProps,
    orgData,
    showSelectedItemsCount=true,
    searchState: searchStateProp,
    onSearchStateChange,
    excludeCarrier,
    includeCarrier,
    ...restProps
}) => {
    const [ searchState, setSearchState ] = useControlledState<SearchState>(initialSearchState, searchStateProp, onSearchStateChange);
    const { isSelected, toggleSelection, selectedItems, clearAll } = useMultiSelectByID({ selectedItems: selectedItemsProps, onSelection });

    // const inputDirty = searchState.lastName || searchState.firstName ? true : false;
    let customer = orgData.getOrgIDByType('customer');
    let transporter = orgData.getOrgIDByType('transporter');
    let employer = orgData.getOrgIDByType('employer');
    let variables = {
        filter: {
            lastName: searchState.lastName,
            firstName: searchState.firstName,
            customers: customer ? [customer] : undefined,
            employers: employer ? [employer] : undefined,
            transporter: transporter,
            onlyUnassigned: includeCarrier ? false : !searchState.includeOtherCarriers
        },
        limit: pageLimit
    }
    if (filterOptions){
        variables.filter = {
            ...variables.filter,
            ...filterOptions
        }
    }

    const handleInputValueChange = (key: string, value: string) => {
        setSearchState({ ...searchState, [key]: value })
    }

    const handleInputPressEnter = (key: string, value: string) => {
        setSearchState({ ...searchState, [key]: value })
    }

    function renderTable({ data, networkStatus, error, fetchMore }: QueryResult){
        if (networkStatus === 1 || networkStatus === 4){
            return <div style={{ textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spin size="large" indicator={<Icon type="loading" />} />
            </div>
        }
        // if (!inputDirty || (!data && networkStatus > 3 && !error)){
        //     return <NonIdealState icon="search" title="Search for passengers by typing into the fields above" />
        // }
        if (error) return <Alert
            banner
            type="error"
            showIcon
            message="Failed to search passengers"
            description={error.message}
        />
        let rows = safeGet(['passengers', 'docs'], data);
        if (!Array.isArray(rows)){
            rows = [];
        }
        if (!rows.length){
            return <NonIdealState icon="question" title="No Results" />
        }
        if(includeCarrier){
            rows = rows.filter((row: any) => hasCarrier(row, includeCarrier, true));
        }else {
            rows = rows.filter((row: any) => hasCarrier(row, excludeCarrier));
        }
        const ColumnSet = new PaxNodeColumnSet(rows);
        let colList = ['personName', 'checkedIn', 'employerName','departureName', 'destinationName', 'transitType'];
        if (orgData.userOrgIsOfType('flytsuite.transporter')){
            colList.splice(2, 0, 'customerName');
        }
        let columns = [
            renderCheckboxColumn(
                rows,
                isSelected,
                toggleSelection,
                'You must remove this passenger from their assigned flight (highlighted in red) first before re-assigning.',
                includeCarrier
            ),
            ...ColumnSet.some(colList)
        ]
        columns.splice(2, 0, renderAssignedFlightColumn(rows))
        let colWidths = new Array(columns.length);
        colWidths.fill(200);
        colWidths[0] = 40;
        colWidths[2] = 134;
        colWidths[3] = 134;
        return <BlueprintTableInfiniteScroller
            hasMore={rows.length >= pageLimit + searchState.offset}
            loadMore={() => {
                let nextOffset = searchState.offset + pageLimit;
                return fetchMore({
                    variables: {
                        skip: nextOffset
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        setSearchState({ ...searchState, offset: nextOffset });
                        return {
                            ...prev,
                            passengers: {
                                ...prev.passengers,
                                docs: [
                                    ...prev.passengers.docs,
                                    ...fetchMoreResult.passengers.docs
                                ]
                            }
                        }
                    }
                })
            }}
        >
            <Table
                className="mc-table"
                numRows={rows.length}
                selectionModes={SelectionModes.NONE}
                columnWidths={colWidths}
                renderMode={RenderMode.NONE}
                selectedRegions={compact(rows.map((row, idx) => {
                    if (isSelected(row._id)){
                        return {
                            rows: [ idx, idx ]
                        }
                    }
                    return null
                }))}
            >
                {columns}
            </Table>
        </BlueprintTableInfiniteScroller>
    }

    function renderHeaderContent(){
        return <>
            <Row type="flex" gutter={12} align="middle">
                <Col>  
                    <Input.Group className="search-header-input-group">
                        <Input
                            placeholder="Last Name"
                            onChange={(e) => handleInputValueChange('lastName', e.target.value)}
                            onPressEnter={(e) => handleInputPressEnter('lastName', e.currentTarget.value)}
                            value={searchState.lastName}
                        />
                        <Input
                            placeholder="First Name"
                            onChange={(e) => handleInputValueChange('firstName', e.target.value)}
                            onPressEnter={(e) => handleInputPressEnter('firstName', e.currentTarget.value)}
                            value={searchState.firstName}
                        />
                    </Input.Group>
                </Col>
                <Col>
                    <Checkbox
                        checked={searchState.includeOtherCarriers}
                        onClick={() => {
                            setSearchState({ ...searchState, includeOtherCarriers: !searchState.includeOtherCarriers })
                            onForceRefresh(true)
                        }}
                    >Include PAX on other flights</Checkbox>
                </Col>
            </Row>
        </>
    }

    return <div className="search-wrapper" {...restProps}>
        <Query
            query={PAX_QUERY}
            variables={variables}
            fetchPolicy="cache-and-network"
            context={{
                debounceKey: 'paxsearch',
                debounceTimeout: 1000
            }}
        >
        {(result: QueryResult) => {
            function handleRefresh(){
                setSearchState({ ...searchState, offset: 0 });
                result.refetch()
            }
            return <>
                <SearchHeader
                    forceRefresh={forceRefresh}
                    onForceRefresh={onForceRefresh}
                    selectedItems={selectedItems}
                    showSelectedItemsCount={showSelectedItemsCount}
                    networkStatus={result.networkStatus}
                    onRefresh={handleRefresh}
                    onClear={() => clearAll()}
                >
                    {renderHeaderContent()}
                </SearchHeader>
                <div className="search-body">
                    {renderTable(result)}
                </div>
            </>
        }}
        </Query>
    </div>

}

export default WithOrgData(PaxSearch)