import { Button, Input, message } from 'antd';
import Alert, { AlertProps } from 'antd/lib/alert';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { AuthContext } from 'context/auth';
import React, { useContext } from 'react';

export interface VerifyEmailAlertProps extends AlertProps {

}

const EmailVerifyForm: React.FC<FormComponentProps> = (props) => {
    const { getFieldDecorator } = props.form;

    function handleSubmit(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err){
                Hub.dispatch('user', {
                    event: 'verifyEmail',
                    data: {
                        code: values.code
                    },
                    message: 'Verifying user'
                })
            }
        })
    }

    function resendCode(){
        Auth.verifyCurrentUserAttribute('email')
        .then(() => message.success('Successfully resent verification code'))
        .catch((err) => {
            message.error('Failed to resend verification code')
            console.error(err);
        })
    }

    return <Form layout="inline" onSubmit={handleSubmit}>
        <Form.Item>
            {getFieldDecorator('code', {
                rules: [{
                    required: true, message: 'Please enter a valid verification code',
                }],
            })(
                <Input size="large" placeholder="Verification code" />
            )}
        </Form.Item>
        <Form.Item>
            <Button size="large" htmlType="submit" type="primary">Verify Account</Button>
        </Form.Item>
        <Form.Item>
            <Button size="large" onClick={resendCode}>Resend Code</Button>
        </Form.Item>
    </Form>
}

const EmailVerifyFormWrapped = Form.create<FormComponentProps>()(EmailVerifyForm);

const VerifyEmailAlert: React.FC<VerifyEmailAlertProps> = (props) => {
    const { user } = useContext(AuthContext);

    if (!user.attributes.email_verified){
        return <Alert
            type="warning"
            message="Please verify your email"
            description={<div>
                <p>You have received an email with a code at the address {user.attributes.email}. Please type in your verification code here:</p>
                <EmailVerifyFormWrapped />
            </div>}
            style={{ marginBottom: '1rem' }}
            className="userprefs-email-verify-alert"
            {...props}
        />
    }
    return null;
}

export default VerifyEmailAlert