import gql from "graphql-tag";
import {
    QueryGetPhase1ListDateRange,
    ScheduleHistoryListItemFragment
} from "Queries/Scheduling";
import {QueryListScheduleHistoryArgs, ScheduleHistoryListItem} from "../../../../__generated__/graphql/types";

export interface QueryTypes {
    ListScheduleHistory: {
        args: QueryListScheduleHistoryArgs,
        data: {
            ListScheduleHistory: ScheduleHistoryListItem[]
        }
    }
}

export const Queries = {
    ListScheduleHistory: gql`
        query ListScheduleHistory(
            $tpID: ID!
            $startDate: AWSDate!
            $endDate: AWSDate! 
            $createdBy: String
        ){
            ListScheduleHistory(
                tpID: $tpID
                startDate: $startDate
                endDate: $endDate
                createdBy: $createdBy
            ){
                ...ScheduleHistoryListItemFragment
            }
        }
        ${ScheduleHistoryListItemFragment}
    `,
    GetClientDateRange: QueryGetPhase1ListDateRange
}