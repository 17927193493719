import {Checkbox, Form, Input, InputNumber, Select} from 'antd';
import { FormComponentProps, FormItemProps } from 'antd/lib/form';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import { createFormFields, formItemLayout } from 'common/form';
import React from 'react';
import ContractSelect from './ContractSelect';
import { LocationSelect } from './select/LocationSelect';

export interface CargoFormProps extends FormComponentProps {
    useFields?: (string | FieldDecorator)[],
    excludeFields?: string[],
    formItemProps?: FormItemProps,
    fieldComponentPropsMap?: {
        [id: string]: any
    },
    onFieldsChange?(fields: any): void,
    fields?: any,
    renderExtra?: (form: WrappedFormUtils) => JSX.Element
}

interface FieldDecorator {
    id: string,
    label: string,
    options?: GetFieldDecoratorOptions,
    extraProps?: any,
    component?: JSX.Element
}

const CargoForm: React.FC<CargoFormProps> = (props) => {
    const { getFieldDecorator, getFieldValue } = props.form;
    

    function getComponentProps(id: string){
        if (props.fieldComponentPropsMap){
            return props.fieldComponentPropsMap[id];
        }
        return {}
    }

    function getFormItems(formItemProps: FormItemProps={}): FieldDecorator[] {
        let formItems: FieldDecorator[] = [
            {
                id: 'name',
                label: 'Name',
                options: {
                    rules: [
                        { required: true, message: 'Name is required' }
                    ]
                },
                component: <Input maxLength={100} />,
                extraProps: formItemProps
            },
            {
                id: 'weight',
                label: 'Weight',
                component: <InputNumber min={0} />,
                extraProps: formItemProps,
                options: {
                    initialValue: 0,
                    rules: [
                        {
                            required: true, message: 'Weight is required'
                        },
                        {
                            validator: (_, value, callback) => {
                                if (value < 1){
                                    callback('Weight must be greater than zero');
                                }
                                else{
                                    callback()
                                }
                            }
                        }
                    ]
                }
            },
            {
                id: 'lastKnownController',
                label: 'Heliport',
                extraProps: formItemProps,
                options: {
                    rules: [
                        {
                            required: true, message: 'Please select a heliport'
                        }
                    ]
                },
                component: <LocationSelect
                    type={"ONSHORE"}
                    labelInValue
                    includeOneOff
                    hasDataflytHardware
                    limit={50}
                />
            },{
                id: 'departureID',
                label: 'Departure Location',
                extraProps: formItemProps,
                options: {
                    rules: [
                        {
                            required: true, message: 'Please select a departure location'
                        }
                    ]
                },
                component: <LocationSelect labelInValue />
            },
            {
                id: 'destinationID',
                label: 'Destination Location',
                extraProps: formItemProps,
                options: {
                    rules: [
                        {
                            required: true, message: 'Please select a destination location'
                        }
                    ]
                },
                component: <LocationSelect labelInValue/>
            },
            {
                id: 'contractID',
                label: 'Contract',
                extraProps: formItemProps,
                options: {
                    rules: [
                        {
                            required: false, message: 'Please select a contract'
                        }
                    ]
                },
                component: <ContractSelect 
                                orgType={'customer'}
                                allowClear 
                                locationID={getFieldValue('lastKnownController') && getFieldValue('lastKnownController').key}
                            />
            },
            {
                id: 'transitType',
                label: 'TransitType',
                extraProps: formItemProps,
                options: {
                    initialValue: 'INBOUND',
                    rules: [{ required: true, message: 'Transit Type is required' }]
                },
                component: <Select>
                    <Select.Option value="INBOUND">INBOUND</Select.Option>
                    <Select.Option value="TRANSFER">TRANSFER</Select.Option>
                </Select>
            },
            {
                id: 'hazmat',
                label: 'Hazmat',
                extraProps: formItemProps,
                options: {
                    valuePropName: 'checked',
                    initialValue: false
                },
                component: <Checkbox />
                
            },
            {
                id: 'customer',
                label: 'Customer',
                extraProps: formItemProps,
                options: {
                    rules: [
                        {
                            required: true, message: 'Customer is required'
                        }
                    ]
                }
            },
            {
                id: 'attentionTo',
                label: 'Attention',
                extraProps: formItemProps,
                options: {
                    rules: [
                        {
                            required: true, message: 'Attention is required'
                        }
                    ]
                },
                component: <Input maxLength={100} />
            },
            {
                id: 'approvedBy',
                label: 'Approved',
                extraProps: formItemProps,
                options: {
                    valuePropName: 'checked',
                    initialValue: false
                },
                component: <Checkbox >{getFieldValue('approvedBy')}</Checkbox>
            },
            {
                id: 'notes',
                label: 'Notes',
                extraProps: formItemProps,
                component: <Input.TextArea maxLength={1000} />
            }
        ]

        let hazmatIndex = formItems.findIndex((fi) => fi.id === 'hazmat');
        if (getFieldValue('hazmat') && hazmatIndex !== -1){
            formItems.splice(hazmatIndex + 1, 0,
                {
                    id: 'hazmatUnCode',
                    label: 'Hazmat UN Code',
                    component: <Input maxLength={100} />,
                    extraProps: formItemProps,
                }
            )
        }

        return formItems
    }

    var formItems = getFormItems(props.formItemProps || formItemLayout);
    
    if (props.useFields){
        formItems = formItems.filter((fi) => props.useFields.includes(fi.id))
    }
    if (props.excludeFields){
        formItems = formItems.filter((fi) => !props.excludeFields.includes(fi.id))
    }

    function renderFormItem(fd: FieldDecorator): JSX.Element{
        return <Form.Item key={fd.id} label={fd.label} {...(fd.extraProps || {})}>
            {getFieldDecorator(fd.id, fd.options)(React.cloneElement(fd.component, getComponentProps(fd.id)))}
        </Form.Item>
    }

    var formItemElements = formItems.map(renderFormItem)

    return <Form>
        {formItemElements}
        {props.renderExtra ? props.renderExtra(props.form) : null}
    </Form>
}

export function formatCargoFormValues(values: any) {
    const {
        name,
        weight,
        hazmat,
        hazmatUnCode,
        attentionTo,
        notes,
        customerID,
        contractID,
        departureID,
        destinationID,
        lastKnownController,
        groundPickup,
        approvedBy,
        initials
    } = values;
    const formattedValues = {
        name,
        weight,
        hazmat,
        hazmatUnCode,
        attentionTo,
        notes,
        customerID: customerID && customerID.key,
        departureID: departureID && departureID.key,
        destinationID: destinationID && destinationID.key,
        contractID,
        lastKnownController: lastKnownController && lastKnownController.key,
        deliveredBy: departureID && departureID.label,
        groundPickup: groundPickup || false,
        approvedBy: approvedBy || null,
        initials: initials
    }
    return formattedValues;
}

CargoForm.defaultProps = {
    useFields: [
        'name',
        'weight',
        'lastKnownController',
        'departureID',
        'destinationID',
        'contractID',
        'hazmat',
        'customer',
        'attentionTo',
        'approvedBy',
        'notes',
        'hazmat'
    ]
}

export default Form.create<CargoFormProps>({
    mapPropsToFields(props){
        if (!props.fields){
            return undefined;
        }
        return createFormFields(props.fields)
    },
    onFieldsChange(props, _, fields){
        if (props.onFieldsChange){
            props.onFieldsChange(fields);
        }
    },
    onValuesChange(props, changedValues, allValues){
        if(changedValues["lastKnownController"]){
            props.form.setFieldsValue({"contractID": ""})
        }
    }
})(CargoForm)

