import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { Form, Input, Alert, Button } from 'antd';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
};

class ChangePassword extends React.Component {
    state = {
        submitted: false,
        error: null,
        submitting: false
    }
    hasFailed = () => (
        this.state.submitted && this.state.error
    )
    hasSucceeded = () => (
        this.state.submitted && !this.state.error
    )
    submit = (e) => {
        e.preventDefault();
        this.setState({ submitting: true })
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              Auth.currentAuthenticatedUser()
              .then(user => {
                  return Auth.changePassword(user, values.oldPassword, values.password)
                  .then(() => this.setState({ submitted: true, error: null }));
              })
              .catch(error => this.setState({ error, submitted: true }))
              .then(() => this.setState({ submitting: false }));
            }
        });
    }
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
          callback('Password and confirm password must be the same');
        } else {
          callback();
        }
      }
    
    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
          form.validateFields(['confirm'], { force: true });
        }
        callback();
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.submit}>
                { this.hasFailed() ? <Alert 
                    type="error" 
                    message="Could not change password" 
                    description={this.state.error.message} 
                    showIcon 
                    style={{marginBottom: '1rem'}} 
                /> : null}
                { this.hasSucceeded() ? <Alert type="success" message="Password changed successfully" showIcon /> : null}
                <Form.Item {...formItemLayout} label="Old Password">
                    {getFieldDecorator('oldPassword', {
                        rules: [
                            {required: true, message: 'You must enter your old password to change your password.'}
                        ]
                    })(
                        <Input type="password" />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="New Password">
                    {getFieldDecorator('password', {
                        rules: [
                            {required: true, message: 'You must enter your old password to change your password.'}
                        ],
                        validator: this.validateToNextPassword
                    })(
                        <Input type="password" />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Confirm Password">
                    {getFieldDecorator('confirm', {
                        rules: [
                            {required: true, message: 'You must enter your old password to change your password.'}
                        ],
                        validator: this.compareToFirstPassword
                    })(
                        <Input type="password" />
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button loading={this.state.submitting} type="primary" htmlType="submit">Change Password</Button>
                </Form.Item>
            </Form>
        )
    }
}

export default Form.create()(ChangePassword)