import React from 'react';
import { ResultProps } from 'antd/lib/result';
import { Result, Icon, Button } from 'antd';

export interface NoResultsProps extends Omit<ResultProps, 'status' | 'icon' | 'title' | 'extra'> {
    onNewEntryButtonClicked?(): void
}

const NoResults: React.FC<NoResultsProps> = (props) => {
    return <Result
    {...props}
    status="info"
    icon={<Icon type="question-circle" theme="twoTone" />}
    title="Your search query returned no results"
    extra={props.onNewEntryButtonClicked ? (
        <Button type="primary" onClick={() => props.onNewEntryButtonClicked()}>
            Create New Entry
        </Button>
    ) : null
    }
/>
}

export default NoResults