import { RenderMode, SelectionModes, Table } from '@blueprintjs/table';
import { Alert, Col, Icon, Input, Row, Spin } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import gql from 'graphql-tag';
import { compact } from 'lodash';
import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { hasCarrier, renderAssignedFlightColumn, renderCheckboxColumn } from '..';
import { CargoNodeColumns } from '../../../common/table/blueprintcolumns';
import { safeGet } from '../../../common/util';
import useControlledState from '../../../hooks/useControlledState';
import { useMultiSelectByID } from '../../../hooks/useMultiSelectByID';
import BlueprintTableInfiniteScroller from '../../BlueprintTableInfiniteScroller';
import NonIdealState from '../../NonIdealState';
import WithOrgData, { WithOrgDataProps } from '../../WithOrgData';
import { SearchProps } from '../interface';
import '../search.less';
import SearchHeader from '../SearchHeader';

const CGO_QUERY = gql`
query SearchCgo($filter: CargoFilterInput, $skip: Int, $limit: Int){
    cargo(filter: $filter, skip: $skip, limit: $limit){
        docs {
            _id
            ... on CargoNode {
                name
                currentCarrierID {
                    _id
                    desig
                }
                approvedBy
                customerID {
                    _id
                    name
                }
                tpID {
                    _id
                    name
                }
                checkedInTime
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                deliveredBy
                attentionTo
                weight
                transitType
                hazmat
                hazmatUnCode
                transitType
                approvedBy
            }
        }
    }
}
`

export interface SearchState {
    name?: string,
    includeOtherCarriers: boolean,
    offset: number
}

export const initialSearchState = {
    name: null,
    includeOtherCarriers: false,
    offset: 0
}


export interface ForceRefresh {
    onForceRefresh(fr: boolean): void,
    forceRefresh?: boolean
}

const CgoSearch: React.FC<SearchProps & WithOrgDataProps & ForceRefresh & React.HTMLAttributes<HTMLDivElement>> = ({
    forceRefresh,
    onForceRefresh,
    pageLimit=50,
    onSelection,
    filterOptions,
    selectedItems: selectedItemsProps,
    orgData,
    showSelectedItemsCount=true,
    onSearchStateChange,
    searchState: searchStateProp,
    excludeCarrier,
    ...restProps
}) => {
    const [ searchState, setSearchState ] = useControlledState<SearchState>(initialSearchState, searchStateProp, onSearchStateChange);
    const { isSelected, toggleSelection, selectedItems, clearAll } = useMultiSelectByID({ selectedItems: selectedItemsProps, onSelection });
    // const inputDirty = searchState.name ? true : false;
    let customer = orgData.getOrgIDByType('customer');
    let transporter = orgData.getOrgIDByType('transporter');
    let employer = orgData.getOrgIDByType('employer');
    let variables = {
        filter: {
            name: searchState.name,
            customers: customer ? [customer] : undefined,
            employers: employer ? [employer] : undefined,
            transporter: transporter,
            onlyUnassigned: !searchState.includeOtherCarriers
        },
        limit: pageLimit
    }
    if (filterOptions){
        variables.filter = {
            ...variables.filter,
            ...filterOptions
        }
    }

    function setName(value: string){
        setSearchState({ ...searchState, name: value });
    }

    const handleInputValueChange = (value: string) => {
        setName(value);
    }

    const handleInputPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    }

    function renderTable({ data, networkStatus, error, fetchMore }: QueryResult){
        if (networkStatus === 1 || networkStatus === 4){
            return <div style={{ textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spin size="large" indicator={<Icon type="loading" />} />
            </div>
        }
        // if (!inputDirty || (!data && networkStatus > 3 && !error)){
        //     return <NonIdealState icon="search" title="Search for cargo by typing into the field above" />
        // }
        if (error) return <Alert
            banner
            type="error"
            showIcon
            message="Failed to search passengers"
            description={error.message}
        />
        let rows = safeGet(['cargo', 'docs'], data);
        if (!Array.isArray(rows)){
            rows = [];
        }
        if (!rows.length){
            return <NonIdealState icon="question" title="No Results" />
        }
        rows = rows.filter((row: any) => hasCarrier(row, excludeCarrier));
        const ColumnSet = new CargoNodeColumns(rows);
        let colList = ['basicName', 'checkedIn', 'departureName', 'destinationName', 'transitType', 'approvedBy', 'deliveredBy'];
        if (orgData.userOrgIsOfType('flytsuite.transporter')){
            colList.splice(1, 0, 'customerName');
        }
        let columns = [
            renderCheckboxColumn(
                rows,
                isSelected,
                toggleSelection,
                'You must remove this cargo from it\'s assigned flight (highlighted in red) first before re-assigning.'
            ),
            ...ColumnSet.some(colList)
        ]
        columns.splice(2, 0, renderAssignedFlightColumn(rows))
        let colWidths = new Array(columns.length);
        colWidths.fill(200);
        colWidths[0] = 40;
        colWidths[2] = 134;
        colWidths[3] = 134;
        colWidths[4] = 120;
        colWidths[5] = 120;
        return <BlueprintTableInfiniteScroller
            hasMore={rows.length >= pageLimit + searchState.offset}
            loadMore={() => {
                let nextOffset = searchState.offset + pageLimit;
                return fetchMore({
                    variables: {
                        skip: nextOffset
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        setSearchState({ ...searchState, offset: nextOffset });
                        return {
                            ...prev,
                            cargo: {
                                ...prev.cargo,
                                docs: [
                                    ...prev.cargo.docs,
                                    ...fetchMoreResult.cargo.docs
                                ]
                            }
                        }
                    }
                })
            }}
        >
            <Table
                className="mc-table"
                numRows={rows.length}
                selectionModes={SelectionModes.NONE}
                columnWidths={colWidths}
                renderMode={RenderMode.NONE}
                selectedRegions={compact(rows.map((row, idx) => {
                    if (isSelected(row._id)){
                        return {
                            rows: [ idx, idx ]
                        }
                    }
                    return null
                }))}
            >
                {columns}
            </Table>
        </BlueprintTableInfiniteScroller>
    }

    function renderHeaderContent(){
        return <Row type="flex" gutter={12} align="middle">
            <Col>
                <Input
                    placeholder="Cargo name"
                    onChange={(e) => handleInputValueChange(e.target.value)}
                    onPressEnter={handleInputPressEnter}
                    value={searchState.name}
                />
            </Col>
            <Col>
                <Checkbox
                    checked={searchState.includeOtherCarriers}
                    onClick={() => {
                        setSearchState({ ...searchState, includeOtherCarriers: !searchState.includeOtherCarriers })
                        onForceRefresh(true)
                    }}
                >Include CGO on other flights</Checkbox>
            </Col>
        </Row>
    }

    return <div className="search-wrapper" {...restProps}>
        <Query
            query={CGO_QUERY}
            variables={variables}
            fetchPolicy="cache-and-network"
            notifyOnNetworkStatusChange
            context={{
                debounceKey: 'paxsearch',
                debounceTimeout: 1000
            }}
        >
        {(result: QueryResult) => {
            function handleRefresh(){
                setSearchState({ ...searchState, offset: 0 });
                result.refetch()
            }
            return <>
                <SearchHeader
                    forceRefresh={forceRefresh}
                    onForceRefresh={onForceRefresh}
                    selectedItems={selectedItems}
                    showSelectedItemsCount={showSelectedItemsCount}
                    networkStatus={result.networkStatus}
                    onRefresh={handleRefresh}
                    onClear={() => clearAll()}
                >
                    {renderHeaderContent()}
                </SearchHeader>
                <div className="search-body">
                    {renderTable(result)}
                </div>
            </>
        }}
        </Query>
    </div>
}

export default WithOrgData(CgoSearch)