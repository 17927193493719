import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import LoadingContent from './LoadingContent';
import {Form, Alert, List, Checkbox, Icon, Input, Row, Col, Button, DatePicker, Divider, Card} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import {formItemLayout, FormItemClassNames} from "../common/form";

const SEARCH_EMPLOYERS = gql`
query SearchEmployers(
    $query: String!
    $limit: Int
    $bookmark: String
){
    searchEmployers(
        query: $query
        limit: $limit
        bookmark: $bookmark
    ){
        bookmark
        docs {
            _id
            ... on Employer {
                name
            }
        }
    }
}
`

const SearchPrompt = () => (
    <div style={{textAlign: 'center', marginTop: '10rem', color: 'grey'}}>
        <Icon type="search" style={{fontSize: '4rem', marginBottom: '1rem'}} />
        <div>Search for employers by typing into the search bar</div>
    </div>
)

const default_limit = 15;

class EmployerResults extends Component {
    state = {
        error: null,
        selected: []
    }
    componentDidUpdate(prevProps){
        const { selected } = this.props;
        if (Array.isArray(selected) && Array.isArray(prevProps.selected)){
            if (selected.length !== prevProps.selected.length){
                this.setState({ selected })
            }
        }
    }
    onSelectedChange = (employerID, checked) => {
        var selected;
        if (checked) {
            selected = _.union(this.state.selected, [employerID])
        }else{
            selected = this.state.selected.filter(item => item !== employerID)
        }
        this.setState({ selected })
        if (this.props.onChange){
            this.props.onChange(selected)
        }
    }
    clearSelected = () => this.setState({ selected: [] })
    render (){
        const { searchEmployers, loading, error, networkStatus } = this.props.data;
        const isFetchingMore = networkStatus === 3;
        if (loading && !isFetchingMore) return <LoadingContent text="Searching" />;
        if (error) return (
            <Alert 
                type="error"
                message="An error occured"
                description="An error occured while searching employers"
                showIcon />
        )
        return (
            <React.Fragment>
                <Row type="flex">
                    <Col style={{ flex: 1 }} >
                        {this.state.selected.length ? (
                            <div>
                                <span>{this.state.selected.length} Selected</span>
                                <Button className="mc-link-btn" style={{ marginLeft: 6 }} onClick={this.clearSelected} >Clear</Button>
                            </div>
                        ) : null}
                    </Col>
                    {
                        this.props.action && (
                            <Col>
                                {this.props.action}
                            </Col>
                        )
                    }
                </Row>
                <div style={{ maxHeight: '40rem', overflow: 'auto' }} >
                    <List 
                        size="small" 
                        dataSource={searchEmployers.docs}
                        loadMore={this.onLoadMore}
                        loading={isFetchingMore}
                        bordered
                        renderItem={(employer) => {
                            const isChecked = this.state.selected.find(item => item === employer._id) ? true : false
                            return (
                                <List.Item>
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={(e) => this.onSelectedChange(employer._id, e.target.checked)}
                                        style={{ width: '100%' }}
                                    >
                                        {employer.name}
                                    </Checkbox>
                                </List.Item>
                            )
                        }} />
                    {searchEmployers.docs.length >= (this.props.limit || default_limit) ? <div style={{ textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px' }}>
                        <Button loading={isFetchingMore} onClick={this.props.onLoadMore}>More</Button>
                    </div> : null}
                </div>
            </React.Fragment>
        )
    }
}

const EmployerResultsWithData = graphql(
    SEARCH_EMPLOYERS,
    {
        options: props => ({
            variables: {
                query: props.query,
                limit: props.limit || default_limit,
                bookmark: props.bookmark
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true
        }),
        props: (props) => ({
            ...props,
            onLoadMore: () => {
                const { fetchMore, searchEmployers: { bookmark } } = props.data;
                fetchMore({
                    variables: {
                        bookmark
                    },
                    updateQuery: (prevResult, { fetchMoreResult }) => { 
                        return {
                            ...prevResult,
                            searchEmployers: {
                                __typename: 'MangoQueryResult',
                                bookmark: fetchMoreResult.searchEmployers.bookmark,
                                docs: [...prevResult.searchEmployers.docs, ...fetchMoreResult.searchEmployers.docs]
                            }
                        }
                    }
                })
            }
        })
    }
)(EmployerResults)

class NonPreferredEmployerSearch extends Component {
    state = {
        query: '',
        error: null
    }
    componentDidCatch(error) {
        this.setState({ error })
    }
    onSearch = (query) => {
        this.setState({ query, error: null })
    }
    renderContent = () => {
        if (this.state.error){
            return (
                <Alert 
                    type="error"
                    message="An error occured"
                    description="An error occured while searching employers"
                    showIcon
                />
            )
        }
        if (this.state.query){
            return (
                <EmployerResultsWithData
                    query={this.state.query}
                    limit={15}
                    {...this.props}
                />
            )
        }else{
            return <SearchPrompt />
        }
    }

    renderForm = () => {

        const { form } = this.props;

        return <Form>
            <Form.Item
                label="Start Date"
                {...formItemLayout}
                className={FormItemClassNames.NoMargin}
            >
                {form.getFieldDecorator('startDate')(
                    <DatePicker />
                )}
            </Form.Item>
            <Form.Item
                label="Reason To Add"
                {...formItemLayout}
                className={FormItemClassNames.NoMargin}
            >
                {form.getFieldDecorator('reason', {
                    rules: [{
                        required: true, message: 'Reason is required'
                    }]
                })(
                    <Input
                        placeholder="Type a reason"
                    />
                )}
            </Form.Item>
        </Form>
    }

    render() {
        return (
            <div style={{padding: 24}}>
                <Card size="small" style={{ marginBottom: '12px' }}>
                    {this.renderForm()}
                </Card>
                <Input.Search placeholder="Search for employers" onSearch={this.onSearch} enterButton style={{ marginBottom: '1rem' }} />
                {this.renderContent()}
            </ div>
        )
    }
}
 
export default Form.create()(NonPreferredEmployerSearch)