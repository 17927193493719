import { WrappedFormUtils } from "antd/lib/form/Form";

const invalidMsg = 'Must have at least Amount and/or PSID filled in.';

let nully = [undefined, null, ''];

export function getValidateAmountValidator(form: WrappedFormUtils){
    return function validateAmount(rule, value, callback) {
        if (nully.includes(value) && nully.includes(form.getFieldValue('psid'))){
            callback(invalidMsg)
        }
        else
        {
            callback();
        }
    }
}

export function getValidatePSIDValidator(form: WrappedFormUtils){
    return function validatePSID(rule, value, callback) {
        if (nully.includes(value) && nully.includes(form.getFieldValue('amount'))){
            callback(invalidMsg)
        }
        else
        {
            callback();
        }
    }
}