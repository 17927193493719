import gql from "graphql-tag";

export default gql`
query GetCrewMember($_id: ID!){
    getBoatCrew(_id: $_id){
        _id
        _rev
        contactInfo {
            name {
                firstName
                lastName
            }
        }
        lastIsnResultWithHeader {
            success
            qc_count
            isn_customer
            isn_ownerid
            qc_result {
                isnStatus {
                    shortLabel
                }
                trainingStatus
                allowed
                companyID
                quickCheckStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
            }
            selectedResult {
                isnStatus {
                    shortLabel
                }
                allowed
                companyID
                quickCheckStatus
                trainingStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
            }
        }
        authorization {
            onWhiteList
            brdRestriction_Isn {
                status
                type
                notes
                reason
            }
        }
    }
}
`