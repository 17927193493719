import { Button, Col, Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import WithOrgData, { WithOrgDataProps } from '../../WithOrgData';
import { getFieldLabel } from '../fieldTypes';
import useCachedDateRange from '../ReportForms/hooks/useCachedDateRange';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

export interface ReportFormProps extends FormComponentProps, WithOrgDataProps {
    renderFormItems?: (form: any, formItemLayout: any, customer: any, transporter: any, employer: any) => React.ReactNode[],
    onSubmit: (data: { name: string, criteria: any }) => void,
    submitting?: boolean,
    fieldFormatter?: (values: any) => any,
    generatedReportDateTimeFormat?: string
}

const ReportForm: React.FC<ReportFormProps> = (props) => {
    const { dateRange, setDateRange, loaded } = useCachedDateRange();
    let dateRangeFormValue = props.form.getFieldValue('dateRange');

    let isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current === false) {
            isMounted.current = true;
        }
        else if (loaded) {
            setDateRange(dateRangeFormValue);
        }
        // eslint-disable-next-line
    }, [dateRangeFormValue, loaded])

    useEffect(() => {
        if (loaded) {
            props.form.setFieldsValue({ dateRange: dateRange })
        }
        // eslint-disable-next-line
    }, [loaded])

    function getFormattedFields() {
        const values = props.form.getFieldsValue();
        return (props.fieldFormatter && props.fieldFormatter(values)) || values
    }
    function generateReportName() {
        let format = props.generatedReportDateTimeFormat || 'MMM D, YYYY HH:mm:ss A'
        let orgName = props.orgData.getActiveOrgName();
        const reportName = `${orgName} ${moment().format(format)}`
        return reportName
    }
    const handleSubmit = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            const formattedFields = getFormattedFields();
            const { reportName, ...restValues } = formattedFields;
            const criteria = Object.entries(restValues).map(([key, value]) => ({
                key,
                value,
                label: getFieldLabel(key)
            }));
            if (!err) {
                props.onSubmit({
                    name: reportName || generateReportName(),
                    criteria: criteria
                });
            }
        })
    }

    const { getFieldDecorator } = props.form;
    const { renderFormItems, orgData: { customer, transporter, employer }, submitting } = props;
    const formItems = renderFormItems && renderFormItems(props.form, formItemLayout, customer, transporter, employer);
    return (
        <Form>
            <Row>
                <Col lg={6} md={12} sm={24}>
                    <Form.Item label="Report Name" {...formItemLayout}>
                        {getFieldDecorator('reportName')(<Input placeholder="Enter a report name" />)}
                    </Form.Item>
                    {formItems}
                    <Form.Item {...tailFormItemLayout}>
                        <Button loading={submitting} onClick={() => handleSubmit()} type="primary">Run Report</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default WithOrgData(Form.create<ReportFormProps & FormComponentProps>()(ReportForm));