import { State, Dispatcher } from "./state";
import {Button, Col, Row} from "antd";
import {HTMLAttributes} from "react";
import * as Util from './util';

export interface ToolbarProps extends HTMLAttributes<HTMLDivElement> {
    state: State,
    dispatch: Dispatcher
}

const Toolbar: React.FC<ToolbarProps> = ({ state, dispatch, ...rest }) => {

    function addEmpty(){
        dispatch({ type: 'ADD_EMPTY_NOTE' });
    }

    function clear(){
        dispatch({ type: 'CLEAR' });
    }

    function reset(){
        dispatch({ type: 'RESET' });
    }

    function openNoteAssigner(){
        dispatch({ type: 'OPEN_NOTE_ASSIGNER', open: true });
    }

    let assignNoteBtn = null;
    if (Util.getValidNotes(state.notes).size){
        assignNoteBtn = (
            <Col>
                <Button
                    icon="plus"
                    type="primary"
                    ghost
                    onClick={openNoteAssigner}
                >Assign PAX/CGO</Button>
            </Col>
        )
    }

    return <Row type="flex" gutter={12} {...rest}>
        <Col>
            <Button
                icon="plus"
                type="primary"
                onClick={addEmpty}
            >Add Note</Button>
        </Col>
        {assignNoteBtn}
        <Col style={{ flex: 1 }} />
        <Col>
            <Button
                type="danger"
                icon="delete"
                ghost
                onClick={clear}
            >Delete All</Button>
        </Col>
        <Col>
            <Button
                onClick={reset}
            >Reset</Button>
        </Col>
    </Row>
}

export default Toolbar;