import React from 'react';
import './Maintenance.css';

interface MaintenanceProps {
    message?: string
}

const Maintenance: React.FC<MaintenanceProps> = (props) => {
    return <div className="mc-maintenance-fullscreen">
        <h1>Manifest Central is currently down for maintenance</h1>
        {props.message ? (
            <p>{props.message}</p>
        ) : null}
        <p>If you have any questions or concerns, please call 337-912-0948 or email <a href="mailto:support@dataflyt.com">support@dataflyt.com</a></p>
    </div>
}

export default Maintenance