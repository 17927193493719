import { Button, Checkbox, Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import { castArray } from 'common/util';
import ContractSelect, { ContractDescription } from 'components/form/ContractSelect';
import React, { ReactElement, useEffect, useState } from 'react';
import './style.less';

export interface UserContractManagerProps {
    username?: string,
    loading?: boolean,
    contractData?: any[],
    onAddContracts?: (contractId: string[]) => Promise<any>,
    onRemoveContracts?: (contractIds: string[]) => Promise<any>,
    updatingData?: boolean,
    isAdding?: boolean,
    isRemoving?: boolean

    extraHeaderComponents?: ReactElement[]
}

const UserContractManager: React.FC<UserContractManagerProps> = (props) => {

    const [ selSearchContracts, setSelSearchContracts ] = useState<string[]>([]);
    const [ selListContracts, setSelListContracts ] = useState<string[]>([]);
    const [ adding, setAdding ] = useState(false);

    async function handleContractsAdd() {
        if (!selSearchContracts.length) return;

        setAdding(true);
        try {
            await props.onAddContracts?.(selSearchContracts);
        }
        catch(err){
            setAdding(false);
        }
        setAdding(false);
        setSelSearchContracts([]);
    }

    useEffect(() => {
        if (!props.contractData && !props.loading && !props.username){
            console.warn('UserContractManager must at least have the \'username\' prop defined or have the \'contractData\' prop set as an array.')
        }
    }, [props.contractData, props.loading, props.username])

    let contracts = castArray(props.contractData);
    let contractIds = contracts.map((ct) => ct?._id);

    function toggleSelectAll(){
        if (selListContracts.length > 0){
            setSelListContracts([]);
        }
        else
        {
            setSelListContracts(contractIds);
        }
    }

    return (
        <>
        <Row type="flex" gutter={12}>
            <Col style={{ flex: 1 }}>
                <ContractSelect
                    placeholder="Select contract"
                    mode="multiple"
                    value={selSearchContracts}
                    onChange={(sc: string[]) => setSelSearchContracts(sc)}
                    loading={adding}
                    filterOption={({ _id }) => !contractIds.includes(_id)}
                    detailedDescriptions
                    onInputKeyDown={(e) => {
                        if (e.key === 'Enter'){
                            handleContractsAdd();
                        }
                    }}
                    disabled={props.isAdding}
                    disableFilterByOrg
                    allowClear
                />
            </Col>
            <Col>
                <Tooltip title='Add selected contracts'>
                    <Button
                        icon='plus'
                        type='primary'
                        disabled={selSearchContracts.length === 0 || props.isAdding || props.isRemoving}
                        onClick={() => handleContractsAdd()}
                    />
                </Tooltip>
            </Col>
        </Row>
        <Row type="flex" gutter={12} style={{ marginTop: '12px' }}>
            {props.extraHeaderComponents ? props.extraHeaderComponents.map((comp) => <Col>{comp}</Col>) : null}
            <div style={{ margin: '0 auto' }}></div>
            {selListContracts.length > 0 ? (
                <Button
                    type='danger'
                    onClick={() => props.onRemoveContracts?.(selListContracts)
                        .then(() => setSelListContracts([]))
                    }
                    loading={props.isRemoving}
                    disabled={props.isAdding}
                >Remove Contracts</Button>
            ) : null}
            <Col>
                <Button onClick={toggleSelectAll} disabled={props.isAdding || props.isRemoving}>
                    {selListContracts.length > 0 ? 'Deselect All' : 'Select All'}
                </Button>
            </Col>
        </Row>
        <Divider type="horizontal" style={{ margin: '12px 0 0 0' }} />
        <List
            loading={props.loading}
            dataSource={contracts}
            size="small"
            renderItem={(item) => {
                return <List.Item
                    key={item._id}
                    actions={[
                        <Tooltip title="Remove contract">
                            <Checkbox
                                checked={selListContracts.includes(item._id)}
                                onChange={(e) => {
                                    let sel = e.target.checked;
                                    if (sel){
                                        setSelListContracts([...selListContracts, item._id ]);
                                    }
                                    else
                                    {
                                        setSelListContracts(selListContracts.filter(id => id !== item._id));
                                    }
                                }}
                            />
                        </Tooltip>
                    ]}
                >
                    {item.name === 'Deleted contract' ? (
                        <span><Typography.Text>Deleted contract</Typography.Text> ({item._id})</span>
                    ) : (
                        <ContractDescription
                            name={item.name}
                            locationName={item.locationID?.name}
                            transporterName={item.tpID?.name}
                            customerName={item.customerID?.name}
                            active={item.active}
                            startDate={item.startDate}
                            endDate={item.endDate}
                        />
                    )}
                </List.Item>
        }} />
        </>
    )
}

export default UserContractManager