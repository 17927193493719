import { Form, Input, Popover, Table } from 'antd';
import { FormComponentProps, ValidationRule } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { InputProps } from 'antd/lib/input';
import { ColumnProps, TableProps } from 'antd/lib/table';
import useControlledState from 'hooks/useControlledState';
import React, { cloneElement, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import './editable-pref-table-style.less';

type HandleSaveType = (key: string, getValue?: (value: any) => any) => void

export interface PrefRow {
    key: string,
    label: string,
    value: any,
    formRules?: ValidationRule[],
    inputProps?: InputProps,
    isText?: boolean,
    render?: (row: PrefRow, saveFn: HandleSaveType, cancelFn: () => void, form: WrappedFormUtils) => ReactElement<any>,
    renderText?: (text: any) => ReactNode,
    renderedElementRefPropName?: string
}

export interface EditablePrefTableProps<T> extends Pick<TableProps<T>, 'size'> {
    data?: Array<PrefRow>,
    editingKey?: string,
    onEditingKeyChange?: (key: string) => void,
    onSave?: (key: string, value: string) => void
}

// const PrevValueCell: React.FC<any> = (props) => {

// }

const EditablePrefTable: React.FC<EditablePrefTableProps<PrefRow> & FormComponentProps> = (props) => {
    
    const { getFieldDecorator } = props.form;
    const [ editingKey, setEditingKey ] = useControlledState('', props.editingKey, props.onEditingKeyChange);
    const [ isCancelled, setIsCancelled ] = useState(false);
    const inputRef = useRef<any>(null);

    useEffect(() => {
        if (inputRef.current && editingKey && inputRef.current.focus){
            inputRef.current.focus();
        }
        else
        {
            console.warn('Cannot find input ref');
            console.log('ref:', inputRef);
        }

        if (isCancelled){
            setIsCancelled(false);
        }
    // eslint-disable-next-line
    }, [ editingKey ])

    function handleSave(key: string, getValue?: (value: any) => any){
        if (isCancelled) return;

        let validationStarted = false; // Needed because validateFieldsAndScroll doesn't work when field hasn't been changed.

        props.form.validateFieldsAndScroll((err, values) => {
            validationStarted = true;
            console.log('validating fields:', {err, values})
            if (!err){
                props.onSave?.(key, getValue ? getValue(values[key]) : values[key]);
                setEditingKey('');
            }
        })
        if (!validationStarted){
            setEditingKey('');
        }
    }

    function handleCancel(){
        setIsCancelled(true);
        setEditingKey('');
    }

    function renderValueCell(text: any, row: PrefRow): ReactNode {
        if (row.key === editingKey){
            let element: ReactElement<any>;
            if (row.render){
                element = row.render(row, handleSave, handleCancel, props.form);
                element = cloneElement(element, { [row.renderedElementRefPropName || 'ref']: inputRef })
            }
            else if (row.isText){
                element = row.value;
            }
            else
            {
                element = <Input 
                    {...row.inputProps} 
                    ref={inputRef} 
                    onPressEnter={() => handleSave(row.key)} 
                    onBlur={() => handleSave(row.key)} 
                    onKeyDown={(e) => {
                        e.stopPropagation();
                        if (e.keyCode === 27){
                            handleCancel();
                        }
                    }}
                />
            }
            return <Form.Item style={{ margin: 0 }}>
                <Popover placement="right" content="ESC: Cancel | Enter/Click away: Save" visible={true}>
                    {getFieldDecorator(row.key, { rules: row.formRules, initialValue: row.value })(element)}
                </Popover>
            </Form.Item>
        }
        if (row.isText){
            return row.value;
        }
        return <div
            className="editable-cell-value-wrap"
            onClick={() => setEditingKey(row.key)}
            style={{ paddingRight: 24 }}
        >
            {row.renderText ? row.renderText(text) : text}
        </div>
    }

    const columns: ColumnProps<PrefRow>[] = [
        {
            dataIndex: 'label',
            key: 'key',
            render: (txt) => <strong className="editable-pref-table-attr-key">{txt}</strong>
        },
        {
            dataIndex: 'value',
            key: 'value',
            render: renderValueCell
        }
    ]
    return <Table
        className="editable-pref-table"
        dataSource={props.data}
        bordered
        rowClassName={() => "editable-row"}
        columns={columns}
        showHeader={false}
        pagination={false}
        rowKey="key"
        size={props.size}
    />
}

export default Form.create<EditablePrefTableProps<PrefRow> & FormComponentProps>()(EditablePrefTable)