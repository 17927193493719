import gql from "graphql-tag";

export default gql`
query ISNResultQuery($_id: ID!){
    getPassenger(_id: $_id){
        _id
        _rev
        lastName
        firstName
        authorization{
            onWhiteList
            brdRestriction_Isn {
                type
                notes
                status
                reason
                createdBy
                createdTs
            }
        }
        lastIsnResultWithHeader {
            success
            qc_count
            isn_customer
            isn_ownerid
            error_message
            qc_result {
                isnStatus {
                    shortLabel
                }
                trainingStatus
                allowed
                companyID
                quickCheckStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
                reasonsForNonCompliance
                scCompanyGrade
                scCompanyGradeSince
                trainingQualificationProject {
                    id
                    name
                }
                operatorQualificationProject {
                    id
                    name
                }
                onlineTrainingQualificationProject {
                    id
                    name
                }
            },
            selectedResult {
                isnStatus {
                    shortLabel
                }
                allowed
                companyID
                quickCheckStatus
                trainingStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
                reasonsForNonCompliance
                scCompanyGrade
                scCompanyGradeSince
                trainingQualificationProject {
                    id
                    name
                }
                operatorQualificationProject {
                    id
                    name
                }
                onlineTrainingQualificationProject {
                    id
                    name
                }
            }
        }
    }
}
`