import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { RadialChart, DiscreteColorLegend } from 'react-vis/dist';
import { Alert } from 'antd';

class IsnCardStatus extends React.Component {
    render(){
        const { persons } = this.props;
        const { getIsnCredential, loading, error } = this.props.data;
        if (loading) return "Loading";
        if (error) return <Alert type="error" message="Failed to get ISN Cards" description={error.message} showIcon />
        const noIsnCard = persons.length - getIsnCredential.length;
        const hasIsnCard = persons.length - noIsnCard;
        const red = 'rgb(255, 128, 128)';
        const green = 'rgb(128, 255, 128)';
        const data = [
            hasIsnCard && {
                angle: hasIsnCard,
                label: `${hasIsnCard} Has ISN Card`,
                style: {
                    fill: green,
                    stroke: green
                }
            },
            noIsnCard && (
                {
                    angle: noIsnCard,
                    label: `${noIsnCard} No ISN Card`,
                    style: {
                        fill: red,
                        stroke: red
                    }
                }
            )
        ]

        if (!data.length) {
            return (
                <div style={{width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <h1 style={{textAlign: 'center'}}>No Data to Generate Report</h1>
                </div>
            )
        }

        return (
            <React.Fragment>
                <RadialChart
                height={300}
                width={300}
                showLabels
                labelsRadiusMultiplier={0.8}
                labelsStyle={{
                    fontSize: '1.1rem'
                }}
                data={data}
                />
                <DiscreteColorLegend 
                colors={[
                    red,
                    green
                ]}
                items={[
                    `${noIsnCard} No ISN Card`,
                    `${hasIsnCard} has ISN Card`
                ]}
                orientation="horizontal" />
            </React.Fragment>
        )
    }
}

const CREDS = gql`
query Creds($persons: [ID]!){
    getIsnCredential(personIDs: $persons){
        _id
    }
}
`

export default graphql(
    CREDS,
    {
        options: props => {
            const persons = props.persons
            return {
                variables: {
                    persons
                }
            }
        }
    }
)(IsnCardStatus)