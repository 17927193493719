
const contractsResolv = {
    Mutation: {
        contracts: (_, {payload: contracts}, {cache}) => {
            cache.writeData({data: {contracts}})
            return contracts
        }
    }
}

export default contractsResolv;