import React, { useState } from 'react';
import { SearchSelectWithData } from '../SearchSelect';
import VesselQuery from './VesselQuery';
import { Select } from 'antd';

const { Option } = Select;

export const VesselSelect = ({
    value,
    customerID,
    tpID,
    ...restProps
}) => {
    const [ searchValue, setSearchValue ] = useState(null);
    return (
        <SearchSelectWithData
            {...restProps}
            query={VesselQuery}
            variables={{
                filter: {
                    name: searchValue,
                    customerID,
                    tpID
                }
            }}
            value={value}
            getData={(data) => data && data.vessels && data.vessels.docs}
            onDataItem={item => <Option key={item._id}>{item.name}</Option>}
            onSearch={(v) => setSearchValue(v)}
        />
    )
}