import { Alert, Button, Card, Icon, Input, List, Typography, message } from 'antd'
import { OrgEmailListItem } from 'common/types/org-email-list'
import { removeTypename } from 'common/util'
import CenteredLoadingScreen from 'components/CenteredLoadingScreen'
import OrgNotifEmailListEditor from 'components/org-notif-email-list-editor'
import 'css/Border.less'
import gql from 'graphql-tag'
import React, { Component, LegacyRef, MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { MutationResult, QueryResult, useMutation, useQuery } from 'react-apollo'
import { debounce } from 'lodash'
import { NetworkStatus } from 'apollo-client'
import NonIdealState from 'components/NonIdealState'
import { OrgNotifEmailListEditorProps } from 'components/org-notif-email-list-editor/org-notif-email-list-editor'
import { FormComponentProps } from 'antd/lib/form'
import { OrgDataContext } from 'context/orgData'
import DebouncedField from 'components/decounced-field/debounced-field'

interface EmployerStub {
    _id: string,
    name: string,
    notificationEmailList: OrgEmailListItem[]
}

const EMPLOYER_QUERY = gql`
query GetEmployersEmailLists(
    $limit: Int
    $skip: Int
    $bookmark: Int
    $search: [FilterByKeyValueInput!]
    $filter: [FilterByKeyValueInput!]
){
    MasterDataEmployers(
        limit: $limit
        skip: $skip
        bookmark: $bookmark
        search: $search
        filter: $filter
    ){
        docs {
            ... on Employer {
                _id
                name
                notificationEmailList {
                    email
                    options {
                        optInAll
                        optIn
                    }
                }
            }
        }
        bookmark
    }
}
`

const SET_EMPLOYER = gql`
mutation SetEmployerEmailList($payload: EmployerInput!, $tpID: ID!){
    setEmployer(payload: $payload, tpID: $tpID){
        _id
        name
        notificationEmailList {
            email
            options {
                optIn
                optInAll
            }
        }
    }
}
`

function useGetEmployersEmailList(search: string): [ EmployerStub[], QueryResult ]{
    const result = useQuery(EMPLOYER_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            limit: 20,
            search: [
                {
                    key: 'name',
                    value: JSON.stringify(String(search).toLowerCase())
                }
            ]
        }
    })

    let employerEmailList = result.data?.MasterDataEmployers.docs || []
    
    return [ employerEmailList, result ]
}

function useSetEmployerEmailList(): [(employerID: string, emailList: OrgEmailListItem[]) => Promise<any>, MutationResult] {
    const [ mutate, result ] = useMutation(SET_EMPLOYER);
    const orgData = useContext(OrgDataContext);

    function updateEmailList(employerID: string, emailList: OrgEmailListItem[]){
        return mutate({
            variables: {
                payload: {
                    _id: employerID,
                    notificationEmailList: emailList
                },
                tpID: orgData.getOrgIDByType('transporter')
            },
            update: (cache, { data: { SetEmployer } }) => {
                const data = cache.readQuery<any>({ query: EMPLOYER_QUERY });
                let empList = data?.MasterDataEmployers || [];

                empList = empList.map((e) => {
                    if (e._id === SetEmployer.payload._id){
                        return {
                            ...e,
                            ...SetEmployer
                        }
                    }
                    return e
                })

                cache.writeQuery({
                    query: EMPLOYER_QUERY,
                    data: {
                        MasterDataEmployers: empList
                    }
                })
            }
        })
    }

    return [ updateEmailList, result ]
}

interface EmployerEmailTriggersEditorProps {
    data: {
        _id: string,
        name: string,
        notificationEmailList: OrgEmailListItem[]
    },
    editorRef?: MutableRefObject<any>
}

const EmailListContext = React.createContext<{
    emailListUpdating?: boolean,
    data: {
        dirty: boolean,
        data: OrgEmailListItem[],
    },
    setData: (data: {
        dirty: boolean,
        data: OrgEmailListItem[],
    }) => void
}>
({ data: { dirty: false, data: [] }, setData: null })

const EmployerEmailTriggersEditor: React.FC<EmployerEmailTriggersEditorProps> = (props) => {

    const { data: emailListState, setData: setEmailListState } = useContext(EmailListContext);

    function resetEmailListState(){
        setEmailListState({ dirty: false, data: props.data.notificationEmailList });
    }

    useEffect(() => {
        // Whenever the emailList query changes, update what the user sees
        if (props.data){
            resetEmailListState();
        }
    }, [ props.data ])

    function handleEmailListChange(_emailList: OrgEmailListItem[]){
        setEmailListState({ dirty: true, data: _emailList })
    }

    return (
        <OrgNotifEmailListEditor
            listProps={{ bordered: false }}
            onChange={handleEmailListChange}
            value={emailListState.data}
            collapseProps={{
                style: {
                    overflow: 'auto'
                }
            }}
            addEmailButtonProps={{
                type: 'primary',
                ghost: true
            }}
            style={{
                height: '40rem'
            }}
            ref={props.editorRef}
        />
    )
}

const EmployerEmailTriggers: React.FC<{}> = (props) => {
    const [ selectedEmp, _setSelectedEmp ] = useState<EmployerStub>(null);
    const [ employerSearch, setEmployerSearch ] = useState<string>('');

    const [ emailListState, setEmailListState ] =
        useState<{ dirty: boolean, data: OrgEmailListItem[] }>({ dirty: false, data: [] });

    const [ employers, { networkStatus, error, refetch } ] = useGetEmployersEmailList(employerSearch);

    const [ setEmailList, { loading: emailListUpdating } ] = useSetEmployerEmailList();

    const emailEditorRef = useRef<any>(null);

    function handleSave(){
        // When user saves, update the email list in the db
        if (!emailEditorRef.current) return 
        
        emailEditorRef.current.validateFieldsAndScroll((err: any) => {
            if (!err){
                let processed = emailListState.data.filter(i => i.email);
                processed = removeTypename(processed);
                setEmailList(selectedEmp._id, processed)
                .then(() => {
                    message.success("Email triggers for " + selectedEmp.name + " updated successfully");
                    clearSelectedEmp();
                })
                .catch(() => message.error("Failed to update email triggers for " + selectedEmp.name))
            }
            else
            {
                console.log(err)
                message.warning(<div>
                    <p>No can do. You have some issues with the form you need to fix first.</p>
                    {Object.keys(err).map((key) => {
                        let idx = Number(key.split('-')[0]);
                        let item = emailListState.data[idx];
                        return <p>A field for '{item.email}' is invalid'</p>
                    })}
                </div>, 5)
            }
        })
    }

    function setSelectedEmp(empData: EmployerStub){
        _setSelectedEmp(empData);
        setEmailListState({ dirty: false, data: [] });
    }

    function clearSelectedEmp(){
        _setSelectedEmp(null);
        setEmailListState({ dirty: false, data: [] });
    }

    let extra = []

    const refetchBtn = <Button key="reload" icon='reload' style={{ marginLeft: '12px' }} onClick={() => refetch()} />

    let cardBody = null;

    if ((networkStatus === NetworkStatus.loading && !employers.length) || networkStatus === NetworkStatus.refetch){
        cardBody = <CenteredLoadingScreen />
    }
    else if (error){
        cardBody = <Alert type='error' showIcon message="Failed to load employer list due to error" />
        extra = [refetchBtn];
    }
    else if (selectedEmp){
        cardBody = <EmailListContext.Provider value={{
            setData: setEmailListState,
            data: emailListState,
            emailListUpdating: emailListUpdating
        }}>
            <EmployerEmailTriggersEditor
                data={selectedEmp}
                editorRef={emailEditorRef}
            />
        </EmailListContext.Provider>
        extra = [
            <Button key="cancel" type='link' onClick={clearSelectedEmp}>Cancel</Button>,
            <Button key="save" type='primary' onClick={handleSave} loading={emailListUpdating}>Save Changes</Button>
        ]
    }
    else {
        cardBody = <div style={{ display: 'flex', flexDirection: 'column', height: '40rem' }}>
            <DebouncedField
                value={employerSearch}
                onChange={setEmployerSearch}
                getValueFromChangeEvent={e => String(e.target.value).toUpperCase()}
                initializeValueOnce={true}
            >
                <Input.Search
                    placeholder='Search employer'
                    style={{ marginBottom: 12, flexShrink: 0 }}
                    allowClear
                />
            </DebouncedField>
            {employerSearch ? <List
                bordered
                style={{
                    overflow: 'auto'
                }}
            >
                {employers.map(e => (
                    <List.Item style={{ padding: 0 }} key={e._id} onClick={() => setSelectedEmp(e)}>
                        <Button block style={{ border: 'none', borderRadius: 'none', textAlign: 'left', padding: '12px 24px', height: 'fit-content' }}>
                            <span>{e.name}</span>
                            <span style={{ float: 'right' }}>
                                <Typography.Text type={!e.notificationEmailList.length ? 'secondary' : undefined}>
                                    {e.notificationEmailList.length} emails
                                </Typography.Text>
                                <Icon style={{ marginLeft: 12 }} type='right' />
                            </span>
                        </Button>
                    </List.Item>
                ))}
            </List> : (
                <NonIdealState
                    title="Use the search box to find an employer"
                    icon="search"
                    iconProps={{
                        style: {
                            fontSize: '2rem'
                        }
                    }}
                    style={{
                        height: '100%'
                    }}
                />)}
        </div>
        extra = [refetchBtn];
    }

    return (
        <Card
            size='small'
            title={<span id="email-triggers-employers">Email triggers for {selectedEmp ? selectedEmp.name : 'employers'}</span>}
            style={{ marginBottom: 12 }}
            extra={[
                <div style={{ display: 'flex', alignItems: 'center' }}>{extra}</div>
            ]}
            children={cardBody}
        />
    )
}

export default EmployerEmailTriggers