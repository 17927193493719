import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import packageJson from '../../package.json';

const LOCALSTORAGE_KEY = "manifestcentral-persistence";

export interface PersistentData { [key: string]: any }

function getLocalStorage(json?: string){
    let unparsed;
    if (json){
        unparsed = json;
    }
    else
    {
        unparsed = localStorage.getItem(LOCALSTORAGE_KEY)
    }
    let parsed = {};
    try {
        parsed = JSON.parse(unparsed);
    }
    catch(e){
        console.error('Failed to parse ' + LOCALSTORAGE_KEY + ' local storage. Defaulting to {}');
    }
    return parsed;
}

const PersistenceContext = createContext({ lastUpdate: new Date().toISOString(), storage: undefined, update: undefined })

export const PersistenceProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [ lastUpdate, setLastUpdate ] = useState(new Date().toISOString());
    const [ storage, setStorage ] = useState(getLocalStorage());

    useEffect(() => {
        setStorage(getLocalStorage());
    // eslint-disable-next-line
    }, [ lastUpdate ])

    return <PersistenceContext.Provider value={{ lastUpdate, storage, update: () => setLastUpdate(new Date().toISOString()) }}>
        {children}
    </PersistenceContext.Provider>
}

class PersistentGroup<T extends PersistentData> {
    updateTs: string // Will be used to determine if this entry was written to
    rev: number = 0; // Number of times this group has been changed
    data: T;
    appVersion: string

    constructor(data?: any, updateTs?: any, rev?: number, appVersion?: string){
        if (rev){
            this.rev = rev;
        }
        if (appVersion){
            this.appVersion = appVersion;
        }
        else
        {
            this.appVersion = packageJson.version;
        }
        if (!updateTs){
            this.updateUpdateTs();
        }
        else
        {
            this.updateTs = updateTs;
        }
        if (typeof data === 'object'){
            this.data = data;
        }
        else if (typeof data === 'string'){
            this.data = JSON.parse(data);
        }
    }

    updateUpdateTs(){
        this.updateTs = new Date().toISOString();
        this.rev++;
    }

    getValue(key: string){
        return this.data[key];
    }

    setValue(key: string, value: any){
        const newData = { ...this.data, [key]: value };
        this.data = newData;
        this.updateUpdateTs();
    }

    // Merges data in dataToMerge argument with existing data
    updateData(dataToMerge: T){
        this.data = {
            ...this.data,
            ...dataToMerge
        }
        this.updateUpdateTs();
    }

    // Replaces existing data with new data
    setData(newData: T){
        this.data = {
            ...newData
        }
        this.updateUpdateTs();
    }

    static fromObject<T>(obj: any){
        let updateTs = obj?.updateTs;
        let rev = obj?.rev;
        let appVersion = obj?.appVersion;
        let data = obj?.data;
        let entry = new PersistentGroup<T>(data, updateTs, rev, appVersion);
        return entry
    }

    static fromJson(json: string){
        let parsed = JSON.parse(json);
        return this.fromObject(parsed);
    }
}

function usePersistence<T extends PersistentData>(groupid: string, defaultValues: PersistentData={}){


    const { lastUpdate, update, storage } = useContext(PersistenceContext);
    const [ initialized, setInitialized ] = useState(false);

    // Inits persistence object in local storage if none exists
    function initLocalStorage(){
        let data = localStorage.getItem(LOCALSTORAGE_KEY);
        if (!data){
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({}))
            update();
        }
    }

    function setLocalStorage(storage: any){
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(storage));
        update();
    }

    function getLocalStorage(){
        let json = localStorage.getItem(LOCALSTORAGE_KEY);
        try {
            return JSON.parse(json);
        }
        catch(e){
            console.error('Failed to parse localStorage value: ' + e);
            return {}
        }
    }

    function initPersistentGroup(){
        setPersistentGroup(new PersistentGroup(defaultValues))
        update();
    }

    function isGroupOutdated(group: PersistentGroup<T>){
        if (group.appVersion !== packageJson.version){
            return true;
        }
        return false
    }

    function getPersistentGroup(ls?: any): PersistentGroup<T> {
        if (!ls){
            ls = storage;
        }
        if (ls && groupid in ls){
            let group = PersistentGroup.fromObject<T>(ls[groupid]);
            if (isGroupOutdated(group)){
                initPersistentGroup();
                const newLs = getLocalStorage();
                return getPersistentGroup(newLs);
            }
            return group
        }
        else
        {
            initPersistentGroup()
            const newLs = getLocalStorage();
            return getPersistentGroup(newLs);
        }
    }

    function setPersistentGroup(group: PersistentGroup<T>){
        let ls = storage;
        if (!ls){
            ls = {}
        }
        ls[groupid] = {
            updateTs: group.updateTs,
            data: group.data,
            rev: group.rev,
            appVersion: group.appVersion
        };
        setLocalStorage(ls);
    }

    function getPersistentGroupValue(key: string){
        let group = getPersistentGroup();
        return group.getValue(key);
    }

    function setPersistentGroupValue(key: string, value: any){
        let group = getPersistentGroup();
        group.setValue(key, value);
        setPersistentGroup(group);
    }

    // Merges values from values arg with existing values in PersistentGroup
    function updatePersistentGroupValues(values: T){
        let group = getPersistentGroup();
        group.updateData(values);
        setPersistentGroup(group);
    }

    // Replaces values of PersistentGroup with values from newValues arg
    function setPersistentGroupValues(newValues: T){
        let group = getPersistentGroup();
        group.setData(newValues);
        setPersistentGroup(group);
    }

    // Create entry in localstorage if none exists
    useEffect(() => {
        initLocalStorage();
        update();
        if (!initialized){
            setInitialized(true);
        }
    // eslint-disable-next-line
    }, [ groupid ])
    
    // Retrieves the local storage data before this hook's state is initialized.
    // That way the persistentData is not null the first time
    if (!initialized){
        initLocalStorage();
    }
    let persistentGroup = getPersistentGroup();

    return {
        lastUpdate,
        persistentData: persistentGroup?.data,
        updateTs: persistentGroup?.updateTs,
        getPersistentValue: getPersistentGroupValue,
        setPersistentValue: setPersistentGroupValue,
        setPersistentValues: setPersistentGroupValues,
        updatePersistentValues: updatePersistentGroupValues
    }
}

export default usePersistence