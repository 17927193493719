import React from 'react';
import { Card, Row, Col } from 'antd';
import { Statistic } from 'antd';

const PaxCovidVaxSummary = ({ data }) => {
    const finalize_count = data.pax_finalized.value || 0;
    const not_finalized_count = data.pax_not_finalized.value || 0;
    const paxc = (data.pax_count && data.pax_count.value) || 0;
    const vax_count = data.is_vaccinated?.value || 0;
    const no_vax_count = data.is_not_vaccinated?.value || 0;
    const ess = data.essential_pax?.value || 0;
    return (
        <Row type="flex" gutter={12} style={{ alignItems: '' }}>
            <Col span={6}>
                <Card>
                    <Statistic title="Total PAX" value={paxc} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Finalized PAX" value={finalize_count} suffix={`(${not_finalized_count} not finalized)`} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Vaccinated PAX" value={vax_count} suffix={`(${no_vax_count} not vaccinated)`} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Essential PAX" value={ess} />
                </Card>
            </Col>
        </Row>
    )
}
 
export default PaxCovidVaxSummary;