import gql from 'graphql-tag';
import { deDupeSort } from '../common/util';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Query: {
        customerList: (obj, args, { cache }, info) => {
            const query = gql`
            query {
              contracts{
                customerID {
                    _id, name
                }
              }
            }
            `
            const {contracts} = cache.readQuery({ query });
            var customerList = contracts.map(c => c.customerID);
            customerList = deDupeSort(customerList, c => c._id, c => c.name);
            return customerList;
        },
        customer: (_,__,{cache}) => {
            const query = gql`
                query{
                    organization {
                        _id
                        name
                        classType
                    }
                }
            `
            const { organization } = cache.readQuery({query});
            if (organization.classType === 'flytsuite.customer'){
                return {
                    _id: organization._id,
                    name: organization.name,
                    __typename: 'Customer'
                }
            }
            return null
        }
    }
}