import gql from "graphql-tag";

export default gql`
query PersonSearch(
    $filter: PersonFilterInput
    $bookmark: String
    $limit: Int!
) {
    persons(
        filter: $filter
        bookmark: $bookmark
        limit: $limit
    ){
        bookmark
        docs {
            _id
            ... on Person {
                lastName
                firstName
                customerID {
                    _id
                    name
                }
                employerID {
                    _id
                    name
                }
            }
        }
    }
}
`