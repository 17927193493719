import Button, { ButtonProps } from 'antd/lib/button';
import MCIcon from 'components/icon';
import React from 'react';
import cn from 'classnames';
import './style.less';

export interface LiveColoredButtonProps extends Omit<ButtonProps, 'type' | 'size' | 'block'> {
    clickable?: boolean,
    type?: string
}

const LiveColoredButton: React.FC<LiveColoredButtonProps> = ({ type, clickable=true, children, className, ...props }) => {
    let clsName = 'mc-live-colored-button';
    if (type){
        clsName += (' mc-live-colored-button-' + type)
    }
    if (!clickable){
        clsName += ' mc-live-colored-button-unclickable';
    }
    let btn;
    if (clickable){
        btn = <Button {...props} block size="small" className={cn(clsName, className)}>
            <span className="mc-live-colored-button-children">{children}</span>
            <MCIcon className="mc-live-colored-button-icon" type="external-link" />
        </Button>
    }
    else
    {
        btn = <div className={clsName}>
                <span className="mc-live-colored-button-children">{children}</span>
            </div>
    }
    return btn;
}

export default LiveColoredButton