import React from 'react';
import { Alert } from 'antd';

const withError = (options) => (Component) => (props) => {
    const dataIndex = (options && options.dataIndex) || 'data';
    const error = props[dataIndex] && props[dataIndex].error;
    const render = options && options.render;
    if (error){
        if (typeof render === 'function'){
            const renderedError = render(props[dataIndex], options)
            if (renderedError) return renderedError
        }
        const errorProps = options && options.errorProps;
        const message = (options && options.message) || 'An error occurred'
        const description = (options && options.description) || error.message;
        return <Alert {...errorProps} type="error" message={message} description={description} showIcon {...errorProps} />
    }
    return <Component {...props} />
}

export default withError