import React from 'react';
import { TransferProps } from 'antd/lib/transfer';
import { Transfer } from 'antd';
import { useQuery } from 'react-apollo';
import { Spin } from 'antd';
import CountryCodeQuery from 'components/form/select/CountryCodeSelect/CountryCodeQuery';
// import './style.less';

const CountryCheckTransfer: React.FC<Omit<TransferProps, 'dataSource' | 'render' | 'titles'>> = (props) => {

    const { data, loading } = useQuery(CountryCodeQuery, {
        onError: error => {console.error(error); throw Error("Failed to fetch countries")}
    });

    if (loading) return <Spin><Transfer /></Spin>
    const dataSource = data.getCountryCodes.countries.map(({ Name }) => ({
        key: Name,
        name: Name
    }))

    return <Transfer
        {...props}
        className="mc-country-check-transfer-fallback"
        targetKeys={props.targetKeys || []}
        showSearch
        filterOption={(inputValue, option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
        dataSource={dataSource}
        titles={['Available', 'Selected']}
        render={item => item.name}
    />
}

export default CountryCheckTransfer