import { Card, Col, Row, Statistic } from 'antd';
import React from 'react';

const ManifestSummary = ({ data }) => {
    const fc = (data.flight_count && data.flight_count.value) || 0;
    const tpax = (data.total_pax && data.total_pax.value) || 0;
    const tcgo = (data.total_cgo && data.total_cgo.value) || 0;
    return (
        <Row type="flex" gutter={12} style={{ alignItems: '' }}>
            <Col span={6}>
                <Card>
                    <Statistic title="Flight Count" value={fc} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Total Passengers" value={tpax} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Total Cargo" value={tcgo} />
                </Card>
            </Col>
        </Row>
    )
}
 
export default ManifestSummary;