import {useApolloClient, useQuery} from "react-apollo";
import gql from "graphql-tag";
import moment from 'moment';
import _ from 'lodash';
import {ScheduleNode} from "./types";

export function zerofixed(v: number) {
    if (v < 10) {
      return `0${v}`;
    }
    return `${v}`;
}

type MomentRange = [ moment.Moment, moment.Moment ]

export function usePersistentDateRange(): [MomentRange, (range: MomentRange) => void]{
    const client = useApolloClient();
    var { data } = useQuery(gql`
    query GetScheduleDateRange {
        schedulingDateRange @client
    }
    `)

    function setDateRange(range: MomentRange){
        client.writeData({
            data: {
                schedulingDateRange: range.map(date => date.format('YYYY-MM-DD'))
            }
        })
    }

    let dateRange: MomentRange;
    if (Array.isArray(data && data.schedulingDateRange) && data.schedulingDateRange.length > 0 && _.every(dateRange, _.isString)){
        dateRange = data.schedulingDateRange.map((dateStr: string) => dateStr && moment(dateStr));
    }
    else
    {
        dateRange = [ moment(), moment().add(1, 'month') ]
    }
    // Check if date range in store is out of bounds. If it is, offset the range until within bounds.
    if (dateRange[0].isBefore(moment(), 'month')){
        let dayDiff = dateRange[1].diff(dateRange[0], 'days');
        let newDateRange: MomentRange = [ moment(), moment().add(dayDiff, 'days') ];
        setDateRange(newDateRange);
        dateRange = newDateRange
    }
    return [
        dateRange,
        range => setDateRange(range)
    ]
}

// Gets the ID of a ScheduleNode (could be __temp_id or _id)
export function getSNodeID(node: ScheduleNode) {
    return node?._id || node?.__temp_id;
}