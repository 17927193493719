import React from "react";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import useSortableData from "hooks/useSortableData";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";

const PersonHotFuelDetail = (props) => {
  const [data, { setSortKey, currentSortKey, currentSortDir }] =
    useSortableData(props.data);

  const columns = [
    {
      name: "Last Name",
      key: "lastName",
    },
    {
      name: "First Name",
      key: "firstName",
    },
    {
      name: "Customer",
      key: "customerName",
    },
    {
      name: "Employer",
      key: "employerName",
    },
    {
      name: "HotFuel Expiration Date",
      key: "hotfuelExpirationDate",
    },
  ].map((col) => {
    const cellRenderer = (i) => {
      const value = data[i]?.[col.key];
      return (
        <Cell tooltip={value} style={{ display: "flex", alignItems: "center" }}>
          {value}
        </Cell>
      );
    };
    return (
      <Column
        key={col.key}
        name={col.name}
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={() => (
          <SortableColumnHeaderCell
            name={col.name}
            sortKey={col.key}
            onSort={setSortKey}
            currentSortedKey={currentSortKey}
            currentSortDir={currentSortDir}
          />
        )}
      />
    );
  });

  return (
    <Table
      selectionModes={SelectionModes.NONE}
      className="mc-table"
      numRows={data?.length}
    >
      {columns}
    </Table>
  );
};

export default PersonHotFuelDetail