import Button, { ButtonProps } from 'antd/lib/button';
import React, { useState } from 'react';
import cn from 'classnames';
import MCIcon from 'components/icon';
import './style.less';
import { FeedbackFormModal } from 'components/feedback-form';

export interface FeedbackButtonProps extends Omit<ButtonProps, 'children' | 'onClick'> {

}

const FeedbackButton: React.FC<FeedbackButtonProps> = (props) => {
    const [ modalOpen, setModalOpen ] = useState(false);
    return <>
        <Button {...props} className={cn("mc-feedback-button mc-btn-transparent", props.className)} onClick={() => setModalOpen(true)}>
            <MCIcon className="mc-icon-smile" type="smile-colored" /><span style={{ marginLeft: '0.4rem' }}>Feedback</span>
        </Button>
        <FeedbackFormModal
            visible={modalOpen}
            onCancel={() => setModalOpen(false)}
        />
    </>
}

export default FeedbackButton