import gql from "graphql-tag";

export const CheckInPassengerFlight = gql`
mutation CheckInPassengerFlight(
    $payload: CheckInPaxFlightPayload!
){
    CheckInPassengerFlight(
        payload: $payload
    ){
        _id
        lastName
        firstName
    }
}
`

export const CheckInPassengerDocks = gql`
mutation CheckInPassengerDocks(
    $payload: CheckInPaxDocksPayload!
    $expected: Boolean
){
    CheckInPassengerDocks(
        payload: $payload
        expected: $expected
    ){
        _id
        lastName
        firstName
    }
}
`