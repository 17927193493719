import { Button, Icon, Tooltip, Typography } from "antd";
import React, { PropsWithChildren } from "react";
import './editable-cell.less';

const BASE_CLS = 'mc-editable-cell';

export interface EditableCellProps {
    children?: React.ReactNode,
    initValue?: any,
    changedValue?: any,
    onReset?: () => void,
    maxTextWidth?: number,
    showChanges?: boolean
}

const EditableCell: React.FC<PropsWithChildren<EditableCellProps>> = (props) => {

    let initValue = props.initValue || null;

    let typogStyle: React.CSSProperties = {
        maxWidth: props.maxTextWidth,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        position: 'relative',
        top: 4
    }
    let text = <Typography.Text style={props.children ? { fontSize: '0.7rem' } : undefined}>{initValue}</Typography.Text>

    if (props.changedValue && initValue && props.changedValue !== initValue && props.showChanges){
        text = <span style={props.children ? { fontSize: '0.7rem', marginBottom: '3px' } : null}>
            <Tooltip title={`Changed from "${props.initValue}" to "${props.changedValue}"`}>
                <span>
                    <Typography.Text type="danger" style={typogStyle}>{props.initValue}</Typography.Text>
                    <Icon type="arrow-right" style={{ margin: '0 6px' }} />
                    <strong>
                        <Typography.Text className={BASE_CLS + "-text-after"} style={typogStyle}>{props.changedValue}</Typography.Text>
                    </strong>
                </span>
            </Tooltip>
            {props.onReset ? (
                <Tooltip title="Reset">
                    <Button
                        icon="undo"
                        className="mc-btn-transparent"
                        size="small"
                        onClick={() => props.onReset()}
                        style={{ marginLeft: '6px', height: '20px' }}
                    />
                </Tooltip>
            ) : null}
        </span>
    }
    else if (props.changedValue && !initValue && props.showChanges){
        text = <span style={props.children ? { fontSize: '0.7rem', marginBottom: '3px' } : undefined}>
            <Tooltip title={`Changed from no value to "${props.changedValue}"`}>
                <strong>
                    <Typography.Text className={BASE_CLS + "-text-after"}>
                        <span style={typogStyle}>{props.changedValue}</span> (new)</Typography.Text>
                </strong>
            </Tooltip>
            {props.onReset ? (
                <Tooltip title="Reset">
                    <Button
                        icon="undo"
                        className="mc-btn-transparent"
                        size="small"
                        onClick={() => props.onReset?.()}
                        style={{ marginLeft: '6px', height: '20px' }}
                    />
                </Tooltip>
            ) : null}
        </span>
    }

    return <div className={BASE_CLS}>
        {React.Children.count(props.children) === 0 || (
            (props.changedValue && initValue && props.changedValue !== initValue) || 
            (props.changedValue && !initValue)
        ) ? (
            <div style={props.children ? { marginLeft: '8px', marginBottom: '3px' } : undefined} >
                {text}
            </div>
        ) : null}
        {props.children}
    </div>
}

EditableCell.defaultProps = {
    maxTextWidth: 70,
    showChanges: true
}

export default EditableCell