import React from 'react';
import { Card, Row, Col } from 'antd';
import { Statistic } from 'antd';

const CgoSummary = ({ data }) => {
    const finalize_count = data.cgo_finalized.value || 0;
    const not_finalized_count = data.cgo_not_finalized.value || 0;
    const cgoc = (data.pax_count && data.cgo_count.value) || 0;
    return (
        <Row type="flex" gutter={12} style={{ alignItems: '' }}>
            <Col span={6}>
                <Card>
                    <Statistic title="Total Cargo" value={cgoc} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Finalized Cargo" value={finalize_count} suffix={`(${not_finalized_count} not finalized)`} />
                </Card>
            </Col>
        </Row>
    )
}
 
export default CgoSummary;