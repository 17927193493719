import React from 'react';
import * as Phase2State from "./state";
import {Alert, Icon, Layout, Spin} from 'antd';
import './phase-2-scheduler.less';
import {BASE_CLS} from "./util";
import CarriableSelector from "./carriable-selector";
import Phase2Editor from "./editor";
import {Route} from "antd/lib/breadcrumb/Breadcrumb";
import {usePhase2SchedulerApi} from "./api";
import {GroupDataProps, ScheduleVariables} from "./types";
import MainLayout from "../../../../components/scheduling/MainLayout";
import Phase2MainHeader from "./main-header";
import {cleanGraphQLErrorMsg} from "../../../../common/util";
import {ScheduleNode} from "../../../../components/scheduling/types";

const { Sider } = Layout;

// TODO: Integrate this component with components/scheduling/editor/schedule-editor.
//       Most functionality has been copied there, but has been kept here to prevent breaking the phase-2-scheduler page.

// Page refreshes every 10 seconds when not in edit mode


export interface Phase2SchedulerPageProps {
    editMode: boolean,
    onEditModeChange: (editing: boolean) => void,
    groupData?: GroupDataProps,
    mainHeaderRoutes?: Route[],
    onCancel: () => void,
    onNewSetCreated: (setID: string) => void,
    onScheduleSaved: (data: { variables: ScheduleVariables, modifiedEntities: ScheduleNode[] }) => void,
    onScheduleDeleted: () => void,

    // User clicked the "Finish" button
    onFinished: () => void
}

/**
 * Phase 2 of the scheduling process. Handles scheduling of passengers and cargo before putting them on a flight.
 */
export const Phase2SchedulerPage: React.FC<Phase2SchedulerPageProps> = (props) => {

    const api = usePhase2SchedulerApi(props);

    // Child components will have access to these
    const provider: Phase2State.ContextType =
        {
            api: api
        }

    let carriableSelector;

    {
        const collapsed = api.carriableSelector.hideCarriablePanel.get();
        const width = collapsed ? 0 : 450;
        let className = BASE_CLS + '-sider ';
        if (collapsed){
            className += BASE_CLS + '-sider-collapsed';
        }
        carriableSelector = (
            <Sider width={width} className={className}>
                <CarriableSelector
                    cargoFormRef={api.forms.cargoForm.ref}
                />
            </Sider>
        )
    }

    // Query error alert ----------------------------------------------------------------------------------------------
    let queryErrorAlert = null;
    if (api.queryInfo.error){
        queryErrorAlert = (
            <Alert
                type="error"
                showIcon
                message="Failed to fetch schedule data"
                description={cleanGraphQLErrorMsg(api.queryInfo.error)}
                style={{ margin: 12 }}
            />
        )
    }
    // -----------------------------------------------------------------------------------------------------------------

    let pageContent = null;

    if (!api.queryInfo.error){
        pageContent = (
            <Spin
                indicator={<Icon type="loading" />}
                spinning={api.queryInfo.loading}
                wrapperClassName={BASE_CLS + '-spinner'}
            >
                <Layout className={BASE_CLS + '-layout'}>
                    {carriableSelector}
                    <Phase2Editor
                        groupFormRef={api.forms.groupForm.ref}
                    />
                </Layout>
            </Spin>
        )
    }

    return <Phase2State.Provider value={provider}>
        <MainLayout>
            <Phase2MainHeader />
            {queryErrorAlert}
            {pageContent}
        </MainLayout>
    </Phase2State.Provider>
}

export default Phase2SchedulerPage;