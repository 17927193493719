import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Alert } from 'antd';

const query = gql`
    query {
        customer @client {
            _id
            name
        }
    }
`

// eslint-disable-next-line import/no-anonymous-default-export
export default (Component) => {
    return props => (
        <Query query={query}>
            {({data, loading, error}) => {
                if (loading) return null
                if (error) return <Alert type="error" title="Error while getting customer" description={error.message} />
                console.log('customer data', data);
                return <Component {...props} customer={data.customer} />
            }}
        </Query>
    )
}