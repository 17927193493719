import gql from "graphql-tag";

export default gql`
query GetScheduledPaxInbCgo(
    $tpID: ID!
    $customerID: ID!
    $scheduledFlightDate: AWSDate!
    $scheduledGroup: String!
    $departureID: ID!
    $destinationID: ID!
    $lastKnownController: ID!
){
    getTransporter(
        _id: $tpID
    ){
        _id
        name
    }
    getCustomer(
        _id: $customerID
    ){
        _id
        name
    }
    getDeparture: getLocation(
        _id: $departureID
    ){
        _id
        name
    }
    getDestination: getLocation(
        _id: $destinationID
    ){
        _id
        name
    }
    getLastKnownController: getLocation(
        _id: $lastKnownController
    ){
        _id
        name
    }
    getScheduledPaxInbCgo(
        tpID: $tpID
        customerID: $customerID
        scheduledFlightDate: $scheduledFlightDate
        scheduledGroup: $scheduledGroup
        departureID: $departureID
        destinationID: $destinationID
    ){
        cgonodes {
            _id
            _rev
            name
            classType
            transitType
            scheduledOrder
            weight
            hazmat
            hazmatUnCode
            departureID {
                _id
                name
            }
            lastKnownController {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            attentionTo
            initials
            notes
            customerID {
                _id
                name
            }
            tpID {
                _id
                name
            }
            currentCarrierID {
                _id
                desig
            }
        }
        paxnodes {
            _id
            _rev
            lastName
            firstName
            transitType
            scheduledOrder
            classType
            paxWeight
            bagWeight
            bagCount
            chargeCode
            personID {
                _id
                dob
                nation
                extraBroadState
                credentials {
                    _id
                    key
                    type
                    expiration
                }
                contactInfo {
                    phone {
                        number
                        type
                    }
                }
            }
            customerID {
                _id
                name
            }
            tpID {
                _id
                name
            }
            employerID {
                _id
                name
            }
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            lastKnownController {
                _id
                name
            }
            authorization {
                onWhiteList
                brdRestriction_Isn {
                    status
                    type
                    notes
                    reason
                }
            }
            isnCheckError {
                message
            }
            currentCarrierID {
                _id
                desig
            }
            lastIsnResultWithHeader {
                isn_customer
                isn_ownerid
                success
                error_message
                quick_check_status
                reason
                qc_count
                qc_result {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
                selectedResult {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
            }
        }
    }
}
`