import React, { useRef, forwardRef, LegacyRef } from 'react';
import { EntryFormProps, entryFormOptions, useAutoFocus } from '.';
import { Form, Input } from 'antd';
import { formItemLayout } from 'common/form';
import { FormComponentProps } from 'antd/lib/form';

export interface VesselEntryFormProps extends EntryFormProps {

}

const VesselEntryForm: React.FC<VesselEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const nameInputRef = useRef(null);
    const { getFieldDecorator } = props.form;
    useAutoFocus(props.autoFocus, nameInputRef.current);
    
    return <Form ref={ref}>
        <Form.Item label="Name" {...formItemLayout}>
        {getFieldDecorator('name', {
            rules: [ { required: true, message: 'Name is required' } ]
        })(<Input ref={nameInputRef} />)}
        </Form.Item>
        {props.dupeChecker}
    </Form>
})

export default Form.create<VesselEntryFormProps & FormComponentProps>(entryFormOptions<VesselEntryFormProps>())(VesselEntryForm)