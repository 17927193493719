import React, { HTMLAttributes } from 'react';

//---------------------------------------------------
//> THIS IS WHERE WE ADD THE 'OVAL' ALERT HIGHLIGHTS
export interface QmAlertProps extends HTMLAttributes<HTMLSpanElement>{
    type?: 'none' | 'warning' | 'critical',
    children?: React.ReactNode
}

//---------------------------------------------------
const QmAlert: React.FC<QmAlertProps> = ({type, children, ...restProps})=> {
    let className="destinfo"
    if(restProps){
        className = className + " " + type
    }
    return <span {...restProps} className = {className}> {children}</span>
}

export default QmAlert