import { deDupeSort } from '../common/util';
import gql from 'graphql-tag';
// import { withRouter } from 'react-router-dom';
// import qs from 'qs';

// const getTpFromRoute = withRouter(
//   ({ location: { search } }, { customerID }, { cache }) => {
//     const params = qs.parse(search, { ignoreQueryPrefix: true });
//     const query = gql`
//     query tpList($customerID: ID!){
//       tpList(customerID: $customerID) @client{
//         _id
//         name
//       }
//     }
//     `
//     console.log('params', params)
//     if (params.tp){
//       const {tpList: tpList } = cache.readQuery({ query, variables: {customerID} });
//       console.log('tpList', tpList)
//       return tpList.find(tp => tp._id === params.tp)
//     }
//     return null;
//   }
// )

// const getTpIDFromRoute = withRouter(
//   ({ location: { search } }) => {
//     const params = qs.parse(search, { ignoreQueryPrefix: true });
//     return params.tp;
//   }
// )

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Query: {
    tpList: (obj, args, { cache }, info) => {
      const query = gql`
      query {
        contracts{
          tpID {
              _id, name
          }
        }
      }
      `
      const {contracts} = cache.readQuery({ query });
      var tpList = contracts.map(c => c.tpID);
      tpList = deDupeSort(tpList, tp => tp._id, tp => tp.name);
      return tpList;
    },
    // getTpFromRoute: (_, __, { cache, getCacheKey }) => {
    //   if (tpID){
    //     const {tp: tpID} = qs.parse(search, { ignoreQueryPrefix: true });
    //     const id = getCacheKey({ __typename: 'Transporter', _id: tpID });
    //     const fragment = gql`
    //     fragment tp on Transporter {
    //       _id
    //       name
    //     }
    //     `;
    //     const tp = cache.readFragment({ fragment, id });
    //     cache.writeData({data: {transporter: tp}});
    //     return tp;
    //   }
    //   return null;
    // }
  },
  Mutation: {
    transporter: (obj, args, { cache, getCacheKey }, info) => {
      const id = 'Transporter:' + args.id;
      const fragment = gql`
      fragment tp on Transporter {
        _id
        name
        type
      }
      `;
      const tp = cache.readFragment({ fragment, id });
      cache.writeData({data: {transporter: tp}});
      return tp;
    }
    // transporter: (obj, payload, { cache }, info) => {
    //   // const id = getCacheKey({ __typename: 'Transporter', _id: args.id });
    //   // const fragment = gql`
    //   // fragment tp on Transporter {
    //   //   _id
    //   //   name
    //   // }
    //   // `;
    //   // const tp = cache.readFragment({ fragment, id });
    //   cache.writeData({data: {transporter: payload}});
    //   return payload;
    // }
  }
}