import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverrideCard } from '../OverrideCard';
import { Tabs, Button } from 'antd';
import { QCResultWithData } from '../QCResult';
import Override from '../../Override';
import WithOrgData from '../../WithOrgData';

const renderOverrideTab = (data) => {
    try{
        var isGreen = false;
        var isOverridden = false;
        try{
            const quickCheckStatus = data.lastIsnResultWithHeader.selectedResult.quickCheckStatus;
            try{
                isGreen = quickCheckStatus.toLowerCase() === 'green';
            }
            catch(err){
                console.error(err.message);
            }
        }catch(err){
            console.error(err.message)
        }

        try{
            const brdStatus = data.authorization.brdRestriction_Isn.status;
            isOverridden = brdStatus === 'OVERRIDDEN';
        }catch(err){
            console.error(err.message)
        }

        if (!(isGreen || isOverridden)){
            return (
                <Tabs.TabPane key="override" tab="Override">
                    <Override isnData={data}/>
                </Tabs.TabPane>
            )
        }
    }
    catch(err){console.error(err.message);}
    return null;
}

class PaxISN extends Component {
    hasOverride = () => {
        try{
            return this.props.data.authorization.brdRestriction_Isn.status === 'OVERRIDDEN'
        }
        catch(err){
            return false
        }
    }
    getOverride = () => {
        try{
            return this.props.data.authorization.brdRestriction_Isn
        }
        catch(err){
            return null
        }
    }
    render() {
        const { data, showISNCheck=true, orgData } = this.props;
        return (
            <div>
                {this.hasOverride() ? (
                    <OverrideCard
                        data={this.getOverride()}
                        paxData={data}
                        size="small"
                    />
                ) : null}
                <Tabs
                    type="card"
                    defaultActiveKey="isn"
                    tabBarExtraContent={
                        this.props.runISNCheck ? (
                            <Button
                                onClick={() => this.props.runISNCheck(data._id)}
                                loading={this.props.isnCheckRunning}
                            >Run ISN Check</Button>
                        ) : null
                    }
                >
                    <Tabs.TabPane key="isn" tab="ISN Result">
                        <QCResultWithData paxId={data._id} fetchPolicy="cache-and-network" showISNCheck={showISNCheck} />
                    </Tabs.TabPane>
                    {orgData.transporter ? renderOverrideTab(data) : null}
                </Tabs>
            </div>
        );
    }
}

PaxISN.propTypes = {
    data: PropTypes.shape({
        _id: PropTypes.string.isRequired
    }).isRequired,
    showISNCheck: PropTypes.bool,
    runISNCheck: PropTypes.func,
    isnCheckRunning: PropTypes.bool
}
 
export default WithOrgData(PaxISN);