import React from 'react';
import { Auth } from '@aws-amplify/auth';

class UserInfoWrapper extends React.Component {
    state = {
        loading: false
    }
    componentDidMount(){
        this.getUser()
    }
    getUser = () => {
        this.setState({ loading: true })
        Auth.currentUserInfo()
        .then(user => this.setState({ user }))
        .catch(error => this.setState({ error }))
        .then(() => this.setState({ loading: false }))
    }
    render(){
        const { component: Component } = this.props;
        return (
            <Component {...this.props} userinfo={{
                user: this.state.user,
                error: this.state.error,
                loading: this.state.loading
            }} />
        )
    }
}

const withUserInfo = (component) => {
    return props => (
        <UserInfoWrapper {...props} component={component} />
    )
}

export default withUserInfo