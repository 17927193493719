import * as React from 'react';
import { safeGet } from '../common/util';
import { OrgDataContext } from '../context/orgData';

export type OrgTypes = 'employer' | 'transporter' | 'customer';
type ClassTypes = 'flytsuite.customer' | 'flytsuite.transporter' | 'flytsuite.employer'

export interface OrgDataProps {
    /**
     * _id and name of current customer account.
     */
    customer?: any,

    /**
     * _id and name of transporter account (or transporter selected as active for customer accounts.)
     */
    transporter?: any,

    /**
     * List of transporters the current customer is contracted with
     */
    tpList?: any[]

    /**
     * _id and name of employer account.
     */
    employer?: any,

    /**
     * _id, name, type, and classType of user's organization. (The organization from the user's custom:organization Cognito user attribute).
     */
    organization: any,

    /**
     * Checks if the user's organization is of a certain type.
     * @param classType classType of the organization.
     */
    userOrgIsOfType: (classType: ClassTypes) => boolean,

    /**
     * _id and name of current selected organization (transporter, customer, or employer depending on the user who is signed in)
     */
    activeOrg: any,

    /**
     * Get the active organization ID if available.
     */
    getActiveOrgID: () => string,

    /**
     * Get the active organization Object if available.
     */
    getActiveOrg: () => any,

    /**
     * Get the active organization name if available.
     */
    getActiveOrgName: () => string,

    /**
     * Checks if the active organization is of a certain type.
     * @param classType classType of the organization.
     */
    activeOrgIsOfType: (classType: ClassTypes) => boolean,
    /**
     * Get organization object by type of organization.
     * If no type is provided the plain organization is returned.
     * @param type Org type.
     */
    getOrgByType: (type?: OrgTypes) => any,

    /**
     * Get organization ID by type of organization.
     * If no type is provided the plain organization ID is returned.
     * @param type Org type.
     */
    getOrgIDByType: (type?: OrgTypes) => any,

    /**
     * Get organization name by type of organization.
     * If no type is provided the plain organization name is returned.
     * @param type Org type.
     */
    getOrgNameByType: (type?: OrgTypes) => any
}

export interface WithOrgDataProps {
    /**
     * Contains all organization information such as the current customer, transporter, employer, etc.
     */
    orgData?: OrgDataProps
}

/**
 * Higher-order-component that allows any component to read current organization information.
 * 
 * <b>IMPORTANT:</b> In TypeScript, the wrapped component must extend the interface WithOrgDataProps.
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default <P extends object>(Component: React.ComponentType<P>) => {
    const withOrgData: React.FC<P & WithOrgDataProps> = (props) => <OrgDataContext.Consumer>
        {(data) => {
            let getActiveOrg = () => {
                switch (safeGet(['organization', 'classType'], data)) {
                    case 'flytsuite.employer':
                        return data.employer;
                    case 'flytsuite.transporter':
                    case 'flytsuite.customer':
                        return data.transporter;
                    default:
                        return data.organization;
                }
            }
            const getOrgByType = (type?: OrgTypes) => {
                switch (type) {
                    case 'employer':
                        return data.employer
                    case 'transporter':
                        return data.transporter
                    case 'customer':
                        return data.customer
                    default:
                        return data.organization;
                }
            }
            if (!data) return null
            Component.displayName = 'withOrgData(' + Component.displayName + ')'
            return <Component {...props} orgData={{
                customer: data.customer,
                transporter: data.transporter,
                employer: data.employer,
                organization: data.organization,
                activeOrg: getActiveOrg(),
                tpList: data.tpList,
                userOrgIsOfType: (classType: ClassTypes) => {
                    const org = data.organization;
                    return (org && org.classType) === classType;
                },
                getActiveOrgID: () => {
                    const activeOrg = getActiveOrg();
                    return activeOrg && activeOrg._id;
                },
                getActiveOrg,
                getActiveOrgName: () => {
                    const activeOrg = getActiveOrg();
                    return activeOrg && activeOrg.name;
                },
                activeOrgIsOfType: (classType: ClassTypes) => {
                    const activeOrg = getActiveOrg();
                    return (activeOrg && activeOrg.classType) === classType;
                },
                getOrgByType: getOrgByType,
                getOrgNameByType: (type: OrgTypes) => {
                    let org = getOrgByType(type);
                    return org && org.name
                },
                getOrgIDByType: (type: OrgTypes) => {
                    let def:OrgTypes = 'transporter';
                    let org = getOrgByType(type ? type : def);
                    return org && org._id
                }
            }} />
        }}
    </OrgDataContext.Consumer>
    return withOrgData
}