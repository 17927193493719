import React from 'react';
import PaxSummary from './Pax';
import CgoSummary from './Cgo';
import ManifestSummary from './Manifest';
import ISNSummary from './ISN';
import CovidSummary from 'components/reporting/summaries/Covid';
import PaxCovidVaxSummary from 'components/reporting/summaries/PaxCovidVax';
import PaxCovidVaxDetailedSummary from 'components/reporting/summaries/PaxCovidVaxDetailed';
import PersonHotFuelSummary from 'components/reporting/summaries/PersonHotFuel';
import PaxIsnEventsSummary from 'components/reporting/summaries/PaxIsnEvents';


export const renderSummary = (report_type, summary_data) => {
    switch (report_type) {
        case 'PAX':
            return <PaxSummary data={summary_data} />
        case 'CGO':
            return <CgoSummary data={summary_data} />
        case 'MANIFEST':
            return <ManifestSummary data={summary_data} />
        case 'ISN':
            return <ISNSummary data={summary_data} />
        case 'COVID':
            return <CovidSummary data={summary_data} />
        case 'PAX_COVID19_VAX':
            return <PaxCovidVaxSummary data={summary_data} />
        case 'PERSON_COVID19_VAX_DETAILS':
            return <PaxCovidVaxDetailedSummary data={summary_data} />
        case 'PERSON_HOTFUEL':
            return <PersonHotFuelSummary data={summary_data} />
        case 'PAX_ISN_EVENTS':
            return <PaxIsnEventsSummary data={summary_data} />
        default:
            return undefined
    }
}