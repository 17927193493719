import React, { Component } from 'react';
import { Button, Divider, Popconfirm } from 'antd';
import PropTypes from 'prop-types';

class EditControl extends Component {
    save = () => {
        this.props.onSave();
        this.props.onChange(false);
    }
    cancel = () => {
        this.props.onCancel()
        this.props.onChange(false);
    }
    setEditing = (editing) => this.props.onChange(editing)
    render() {
        const { editing } = this.props;
        if (!editing){
            return (
                <div>
                    <Button
                        className="mc-link-btn"
                        onClick={() => this.setEditing(true)}
                    >Edit</Button>
                    <Divider type="vertical" />
                    <Popconfirm 
                        title="Are you sure you want to remove this credential?"
                        onConfirm={() => this.props.onRemove && this.props.onRemove()}
                    >
                        <Button className="mc-link-btn">Remove</Button>
                    </Popconfirm>
                </div>
            )
        }
        return (
            <div>
                <Button
                    className="mc-link-btn"
                    onClick={() => this.save()}
                >Save</Button>
                <Divider type="vertical" />
                <Button
                    className="mc-link-btn"
                    onClick={() => this.cancel()}
                >Cancel</Button>
            </div>
        );
    }
}

EditControl.propTypes = {
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    editing: PropTypes.bool,
    onRemove: PropTypes.func
}
 
export default EditControl;