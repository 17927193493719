import {CgoNode, PaxNode} from "../../../common/types/carriable";

export function calcTotalWeight(entities: (PaxNode | CgoNode)[]): number {

    function reducer(currWt: number, entity: PaxNode | CgoNode){
        if (entity.classType === 'flytsuite.paxnode'){
            return currWt + (entity.paxWeight || 0) + (entity.bagWeight || 0);
        }
        else if (entity.classType === 'flytsuite.cgonode'){
            return currWt + (entity.weight || 0)
        }
        return currWt;
    }

    return entities.reduce(reducer, 0);
}

export function calcBagWeight(pax: PaxNode[]){

    function reducer(prevWeight: number, node: PaxNode){
        if (node.classType === 'flytsuite.paxnode'){
            return prevWeight + node.bagWeight;
        }
        return prevWeight;
    }

    return pax.reduce(reducer, 0);
}

export function calcBagCount(pax: PaxNode[]){

    function reducer(prevCount: number, node: PaxNode){
        if (node.classType === 'flytsuite.paxnode'){
            return prevCount + node.bagCount;
        }
        return prevCount;
    }

    return pax.reduce(reducer, 0);
}