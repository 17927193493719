import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';


export interface InTransitListProps {
    data: any[]
    style?: React.CSSProperties,
    onInTransitLocClick?: (id: string, name: string, classType: string) => void,
    selectedID?: string
}

/* ------------------  LOOP OVER LOCATION QUERY --------------------*/
const InTransitList: React.FC<InTransitListProps> = (props) => {
    return <>
        <div className="dashboard" style={props.style}>
            <dl className="list-group bordered">
                <dt className="list-header"><span className="title">In Transit</span>
                    <div className="destinfowrap">
                        <span>
                            <FontAwesomeIcon icon={["fas", "users"]} />
                        </span>
                    </div>
                </dt>

                {/* ------------------ CREATE CLICK LISTENER FOR EACH ROW ITEM --------------------*/}
                {props.data.map(item => (
                    <dd key={item?.carrierID} className={`list-item ${props.selectedID === item.carrierID ? "active" : ""}`} onClick={() => props.onInTransitLocClick?.(item.carrierID, item.carrierName, item.classType)}>
                        <FontAwesomeIcon icon={["fas", "helicopter"]} className="mc-qm-icon" />
                        <span className="title">{item.carrierName}</span>
                        <div className="destinfowrap">
                            <span className="destinfo">{item.expectedCount}</span>
                        </div>
                    </dd>
                ))}
            </dl>
        </div>
    </>
}


export default InTransitList


