import gql from "graphql-tag";

export default gql`
mutation SaveCredential(
    $_id: ID
    $key: String
    $expiration: AWSDate
    $type: CredentialType
    $personID: ID!
    $tpID: ID!
    $customerID: ID
    $paxID: ID
){
    setCredential(
        _id: $_id
        key: $key
        expiration: $expiration
        type: $type
        personID: $personID
        tpID: $tpID
        customerID: $customerID
        paxID: $paxID
    ){
        _id
        key
        expiration
        personID {
            _id
        }
        type
    }
}
`