import React, { Component } from 'react';
import { List, Avatar, Row, Col } from 'antd';
import Attribute from '../Attribute';
import moment from 'moment';
import IsnCheckStatusButton from '../../IsnCheckStatusButton';
import BoardingRestrictionButton from '../../boarding-restrictions/BoardingRestrictionButton';

class PassengerItem extends Component {
    render() { 
        const { data } = this.props;
        const name = `${data.lastName}, ${data.firstName}`;
        const { checkedInTime } = data;
        var citDisplay;
        if (checkedInTime){
            const citMoment = moment(checkedInTime)
            citDisplay= citMoment.isSameOrBefore(moment('1970-01-01')) ? null : `Checked in ${citMoment.format('YYYY-MM-DD HH:mm:ss')}`
        }
        return (
            <List.Item key={data.key}>
                <Row gutter={12}>
                    <Col span={2}>
                        <Avatar />
                    </Col>
                    <Col span={22}>
                        <Row>
                            <Col span={8}>
                                <h4>{name}</h4>
                            </Col>
                            <Col span={8}>
                                <Attribute label="Pax Weight" value={data.paxWeight} altVal={0} />
                            </Col>
                            <Col span={8}>
                                <Attribute label="Transit Type" value={data.transitType} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                {(data.employerID && data.employerID.name) || 'Unknown Employer'}
                            </Col>
                            <Col span={8}>
                                <Attribute label="Bag Weight" value={data.bagWeight} altVal={0} />
                            </Col>
                            <Col span={8}>
                                <Attribute label="Departure" value={data.departureID && data.departureID.name} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                {citDisplay}
                            </Col>
                            <Col span={8}>
                                <Attribute label="Bag Count" value={data.bagCount} altVal={0} />
                            </Col>
                            <Col span={8}>
                                <Attribute label="Destination" value={data.destinationID && data.destinationID.name} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>

                            </Col>
                            <Col span={8}>
                                <IsnCheckStatusButton pax={data} />
                            </Col>
                            <Col span={8}>
                                <BoardingRestrictionButton data={data} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </List.Item>
        );
    }
}
 
export default PassengerItem;