import {FlightLeg} from "schema";
import {computeLegWeightsAndCounts} from "./util";
import {FlightPath, FlightPathOptionalArgs} from "./flight-path";
import {union} from "lodash";

export interface FlightPax {
    _id: string,
    departureID: string,
    destinationID: string,
    paxWeight: number,
    bagWeight: number,
    bagCount: number
}

export interface FlightCgo {
    _id: string,
    departureID: string,
    destinationID: string,
    weight: number
}

/**
 * Algorithm that finds the shortest subset of locations a pax can visit.
 * @param path List of locations a flight will visit
 * @param startLoc Departing location of the pax
 * @param endLoc Destination of the pax
 * @returns [startIdx, endIdx]
 */
export function getOptimalFlightPathSubset(path: string[], startLoc: string, endLoc: string){
    // start index and end index
    let si = 0;
    let ei = 1;

    // Last start index and last end index
    let lsi = -Infinity;
    let lei = 0;

    let moveStart = false;

    let loops = 0;
    let maxLoops = 10000;

    while (si < path.length - 1 && ei < path.length){
        loops++;
        if (loops > maxLoops){
            throw Error("Max loops exceeded.");
        }
        if (path[ei] === endLoc && ei > lei && moveStart === false){
            moveStart = true;
        }
        if (si === ei-1 && moveStart === true){
            // Start index is right behind end index. Cannot move further.
            moveStart = false;
        }
        if (path[ei] === endLoc && path[si] === startLoc && ei-si < lei-lsi){
            lsi = si;
            lei = ei;
            continue;
        }

        if (moveStart)
            si++;
        else
            ei++;
    }

    return [lsi, lei]
}

function updateEntityDepDest(originalOrigin: string, overrideOrigin: string, entity: FlightPax | FlightCgo){
    let entityCopy = {...entity};
    if (originalOrigin === overrideOrigin) return entityCopy;

    if (entityCopy.departureID === originalOrigin){
        entityCopy.departureID = overrideOrigin;
    }
    if (entityCopy.destinationID === originalOrigin){
        entityCopy.destinationID = overrideOrigin;
    }
    return entityCopy;
}

type FlightLegManagerArgsBase  = {
    /**
     * Maps the location ID to a human-readable name. REQUIRED FOR 'departure' and 'destination' fields in flight legs to be populated!!!
     */
    locationIDToNameMap?: Map<string, string>,

    /**
     * Optional arguments to pass to the FlightPath
     */
    flightPathOptionalArgs?: FlightPathOptionalArgs,

    /**
     * Override the origin location of the flight path.
     */
    overrideOrigin?: string
}

export type FlightLegManagerArgs = ({

    /**
     * Set the initial flight legs
     */
    initialLegs: FlightLeg[],

    /**
     * Pax objects in initialLegs
     */
    paxObjs: FlightPax[],

    /**
     * Cgo objects in initialLegs
     */
    cgoObjs: FlightCgo[],
} & FlightLegManagerArgsBase) | FlightLegManagerArgsBase

class FlightLegManager {

    flightPath: FlightPath;
    initialLegs: FlightLeg[];
    allPax: Map<string, FlightPax> = new Map();
    allCgo: Map<string, FlightCgo> = new Map();
    private locIDToNameMap: Map<string, string> = new Map();

    private manAssignedPax = new Map<string, [string, string]>();
    private manAssignedCgo = new Map<string, [string, string]>();

    constructor(args: FlightLegManagerArgs){

        if (args.locationIDToNameMap){
            this.locIDToNameMap = args.locationIDToNameMap;
        }

        if ('initialLegs' in args){
            this.initialLegs = args.initialLegs;
            this.flightPath = FlightPath.fromFlightLegs(args.initialLegs, args.overrideOrigin, args.flightPathOptionalArgs);
            args.paxObjs.forEach((obj) => {
                let newObj = updateEntityDepDest(this.flightPath.getOriginalOrigin(), args.overrideOrigin, obj) as FlightPax;
                this.allPax.set(obj._id, newObj);
            })
            args.cgoObjs.forEach((obj) => {
                let newObj = updateEntityDepDest(this.flightPath.getOriginalOrigin(), args.overrideOrigin, obj) as FlightCgo;
                this.allCgo.set(obj._id, newObj);
            })
        }
        else {
            this.flightPath = new FlightPath(args.overrideOrigin, args.flightPathOptionalArgs);
        }
    }

    // public insertPaxCgo(
    //     departureID: string,
    //     destinationID: string,
    //     paxObjs: FlightPax[],
    //     cgoObjs: FlightCgo[]
    //     ){
    //         this.flightPath.insertNode(departureID, destinationID);
    //         paxObjs.forEach((obj) => this.allPax.set(obj._id, obj))
    //         cgoObjs.forEach((obj) => this.allCgo.set(obj._id, obj))
    // }

    // Sees if a passenger's departure and destination includes this flight leg
    isOnLeg(pax: FlightPax | FlightCgo, legDepIdx: number, legDestIdx: number){
        let paxDep = pax.departureID;
        let paxDest = pax.destinationID;
        const path = this.flightPath.getRoundTripPath();
        const [ startIdx, endIdx ] = getOptimalFlightPathSubset(path, paxDep, paxDest);

        const isOnLeg = (
            startIdx > -1 && endIdx > -1 &&
            legDepIdx >= startIdx &&
            endIdx >= legDestIdx
        );

        return isOnLeg;
    }

    private cleanRedundantNodes(){
        let scanned = new Set();
        Array.from(this.allPax.values()).forEach((pax) => {
            scanned.add(pax.departureID);
            scanned.add(pax.destinationID);
        })
        Array.from(this.allCgo.values()).forEach((cgo) => {
            scanned.add(cgo.departureID);
            scanned.add(cgo.destinationID);
        })

        let redundantNodes = [];

        let path = this.flightPath.getPath();
        path.forEach((node) => {
            if (!scanned.has(node)){
                redundantNodes.push(node);
            }
        })

        redundantNodes.forEach((node) => this.flightPath.removeNode(node))
    }

    /**
     * Checks if a location in the flight path is no longer needed.
     * @param locID 
     * @returns true if the leg is redundant. False if not redundant.
     */
    public isLocationRedundant(locID: string){
        if (!locID) return false;
        let scanned = new Set();

        if (this.getOrigin() === locID){
            // Locations that are the origin of the flight path
            // are not redundant, even though no pax/cgo are flying on it
            return false;
        }

        Array.from(this.allPax.values()).forEach((pax) => {
            scanned.add(pax.departureID);
            scanned.add(pax.destinationID);
        })
        Array.from(this.allCgo.values()).forEach((cgo) => {
            scanned.add(cgo.departureID);
            scanned.add(cgo.destinationID);
        })
        if (!scanned.has(locID)){
            return true;
        }
        return false;
    }

    /**
     * Removes a node from the flight path if it is not in anyone's flight path
     * @param locID 
     * @returns true if leg was removed. False if leg still remains.
     */
    public removeLocationIfRedundant(locID: string){
        if (this.isLocationRedundant(locID)){
            this.flightPath.removeNode(locID);
            return true;
        }
        return false;
    }

    addPassenger(pax: FlightPax, args?: { updateFlightPath?: boolean }){
        this.allPax.set(pax._id, pax);
        if (typeof args?.updateFlightPath === 'boolean' ? args.updateFlightPath : true){
            this.flightPath.insertNode(pax.departureID, pax.destinationID);
        }
    }

    addCgo(cgo: FlightCgo, args?: { updateFlightPath?: boolean }){
        this.allCgo.set(cgo._id, cgo);
        if (typeof args?.updateFlightPath === 'boolean' ? args.updateFlightPath : true) {
            this.flightPath.insertNode(cgo.departureID, cgo.destinationID);
        }
    }

    removePassenger(id: string){
        // let pax = this.allPax.get(id);
        this.allPax.delete(id);
        // this.removeLocationIfRedundant(pax.destinationID);
    }

    removeCgo(id: string){
        // let cgo = this.allCgo.get(id);
        this.allCgo.delete(id);
        // this.removeLocationIfRedundant(cgo.destinationID);
    }

    doesEntityHaveALeg(entity: FlightPax | FlightCgo) {
        let subset = this.flightPath.getNodeSubset(entity.departureID, entity.destinationID);
        if (!subset || subset.length === 0){
            return false;
        }
        return true;
    }

    // If two destinations, A → B, are reordered to B → A, and a TRANSFER pax/cgo travels from A → B, and A and B do not contain an origin,
    // then this pax/cgo no longer has a viable path in the flight path.
    // What we need to do is detect these pax/cgo that are in this situation and add a NEW leg that goes from A → B for them.
    // Thus, this will create a third destination with the path B → A → B
    private findAndCreateMissingLegs(){
        const putDestConditionally = (entity: FlightPax | FlightCgo) => {
            let hasLeg = this.doesEntityHaveALeg(entity);
            if (!hasLeg){
                this.flightPath.insertNode(entity.departureID, entity.destinationID);
            }
        }

        let pax = Array.from(this.allPax.values());
        let cgo = Array.from(this.allCgo.values());

        pax.forEach(putDestConditionally);
        cgo.forEach(putDestConditionally);
    }

    findUnassigned(){
        let paxList = Array.from(this.allPax.values());
        let cgoList = Array.from(this.allCgo.values());

        return {
            pax: paxList.filter((pax) => !this.doesEntityHaveALeg(pax)),
            cgo: cgoList.filter((cgo) => !this.doesEntityHaveALeg(cgo))
        }
    }

    buildFlightLegs(args?: {
        disableCreateMissingLegs: boolean,
        getInfo?: (infoData: {
            paxIds?: string[],
            cgoIds?: string[]
        }) => void
    }): FlightLeg[] {

        if (!args?.disableCreateMissingLegs){
            this.findAndCreateMissingLegs();
        }

        let paxIdsOnFlight = [];
        let cgoIdsOnFlight = [];

        function addEntityInfo(entityType: 'PaxNode' | 'CargoNode'){
            return (entity: FlightPax | FlightCgo) => {
                if (!args?.getInfo){
                    return;
                }
                if (entityType === 'PaxNode'){
                    paxIdsOnFlight = union(paxIdsOnFlight, [entity._id]);
                }
                else
                {
                    cgoIdsOnFlight = union(cgoIdsOnFlight, [entity._id]);
                }
            }
        }

        let path = this.flightPath.getRoundTripPath();

        if (path.length <= 1){
            return [];
        }

        let legs: FlightLeg[] = [];

        path.forEach((dest, idx) => {
            if (idx === 0) return;

            let departIdx = idx-1;
            let paxOnLeg = Array.from(this.allPax.values())
                .filter((pax) => this.isOnLeg(pax, departIdx, idx));
            let cgoOnLeg = Array.from(this.allCgo.values())
                .filter((cgo) => this.isOnLeg(cgo, departIdx, idx));

            paxOnLeg.forEach(addEntityInfo('PaxNode'));
            cgoOnLeg.forEach(addEntityInfo('CargoNode'));

            legs.push({
                order: idx-1,
                paxIDs: paxOnLeg.map(obj => obj._id),
                cgoIDs: cgoOnLeg.map(obj => obj._id),
                departureID: path[departIdx],
                departure: this.locIDToNameMap.get(path[departIdx]),
                destinationID: dest,
                destination: this.locIDToNameMap.get(dest),
                ...computeLegWeightsAndCounts(paxOnLeg as any[], cgoOnLeg as any[])
            })
        })

        if (args?.getInfo){
            args.getInfo({
                paxIds: paxIdsOnFlight,
                cgoIds: cgoIdsOnFlight
            })
        }

        return legs;
    }

    getPaxIDList(){
        return Array.from(this.allPax.values())
            .map(obj => obj._id)
    }

    getCgoIDList(){
        return Array.from(this.allCgo.values())
            .map(obj => obj._id)
    }

    moveDestination(grabDest: string, hoverDest: string){
        // let error = this.flightPath.moveNode(grabDest, hoverDest);
        // if (!error){
        //     // Reodering may cause some nodes in the flight route to become redundant.
        //     this.cleanRedundantNodes();
        // }
        return this.flightPath.moveNode(grabDest, hoverDest);
    }

    getOrigin(){
        return this.flightPath.getOrigin();
    }

    setOrigin(locID: string){
        this.flightPath.setOrigin(locID);
    }

    getLocationName(locID: string){
        return this.locIDToNameMap.get(locID);
    }
}

export default FlightLegManager