import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import ReportQuery, { S3ReportQuery } from './ReportQuery';
import ReportDetails from './ReportDetails';
import { Alert, Icon, Spin } from 'antd';
import DetailHeader from './DetailHeader';

class ReportDetailsWithData extends Component {
    state = {
        isInProgress: false
    }
    static propTypes = {
        id: PropTypes.string.isRequired,
    }
    render() {
        const { id, ...restProps } = this.props;
        return (
            <Query
                query={ReportQuery}
                variables={{ id }}
                fetchPolicy="network-only"
            >
            {({ data, loading, error, startPolling, stopPolling }) => {
                if (loading) return (
                    <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                        <Spin indicator={<Icon type="loading" />} size="large" />
                    </div>
                )
                if (error) return (
                    <Alert type="error" showIcon message="Failed to load report" />
                )
                if (data && 
                    data.report
                ){
                    if (data.report.status === 'INITIALIZING' || data.report.status === 'IN_PROGRESS') {
                        if (!this.state.isInProgress){
                            this.setState({ isInProgress: true });
                            startPolling(5000);
                        }
                    }
                    else
                    {
                        if (this.state.isInProgress){
                            this.setState({ isInProgress: false });
                            stopPolling();
                        }
                    }
                }
                return (
                    <React.Fragment>
                        {data && data.report ? (
                            <Query
                                query={S3ReportQuery}
                                variables={{ id: data.report._id, encoding: this.props.reportencoding || 'base64' }}
                                fetchPolicy="no-cache"
                                skip={this.state.isInProgress}
                            >
                            {({ data: s3Data, loading: s3Loading, error: s3Error }) => {
                                var mergedReport;
                                if ((data && data.report) || (s3Data && s3Data.s3Report))
                                {
                                    mergedReport = data && data.report;
                                    if (s3Data && s3Data.s3Report && s3Data.s3Report.result && s3Data.s3Report.result.docs){
                                        mergedReport.result.docs = JSON.stringify(s3Data.s3Report.result.docs)
                                    }
                                }
                                if (mergedReport && mergedReport.result && mergedReport.result.docs){
                                    if (typeof mergedReport.result.docs === 'string'){
                                        mergedReport.result.docs = JSON.parse(mergedReport.result.docs);
                                    }
                                }
                                return (
                                    <React.Fragment>
                                        {error || (s3Error && !this.state.isInProgress) ? <Alert banner type="error" showIcon message="Failed to load report" /> : null}
                                        {mergedReport ? (
                                            <ReportDetails
                                                data={mergedReport}
                                                loading={loading || s3Loading}
                                                {...restProps}
                                            />
                                        ) : null}
                                        {!data || !data.report ? (
                                            <DetailHeader
                                                loading={loading || s3Loading}
                                                title={data && data.name}
                                                onBackClicked={this.props.onBackClicked}
                                            />
                                        ) : null}
                                    </React.Fragment>
                                )
                            }}
                            </Query>
                        ) : null}
                    </React.Fragment>
                )
            }}
            </Query>
        );
    }
}
 
export default ReportDetailsWithData;