import gql from "graphql-tag";

export default gql`
query GetPax(
    $filter: PassengersFilterInput
    $bookmark: String
    $limit: Int
    $skip: Int
){
    passengers(
        filter: $filter
        bookmark: $bookmark
        limit: $limit
        skip: $skip
    ) {
        docs {
            _id,
            ... on PaxNode {
                lastName,
                firstName,
                personID {
                    _id
                    extraBroadState
                }
                customerID {
                    _id
                    name
                }
                employerID {
                    _id
                    name
                },
                tpID {
                    _id
                    name
                }
                paxWeight,
                bagWeight,
                bagCount,
                checkedInTime,
                scheduledFlightDate,
                destinationID {
                    _id, name
                }
                departureID {
                    _id, name
                },
                transitType
                lastIsnResultWithHeader {
                    success,
                    qc_result {
                        isnStatus {
                            shortLabel
                        }
                        quickCheckStatus
                        errorMessage
                        reason
                    }
                    selectedResult {
                        isnStatus {
                            shortLabel
                        }
                        quickCheckStatus,
                        errorMessage
                        reason
                    }
                },
                authorization {
                    onWhiteList
                    noFlyListed
                    brdRestriction_Isn {
                        status
                    }
                    brdRestriction_SafetyCard {
                        status
                    }
                    brdRestriction_HuetCard {
                        status
                    }
                    brdRestriction_SteelToes {
                        status
                    }
                    brdRestriction_Twic {
                        status
                    }
                    brdRestriction_NonPreferred {
                        status
                    }
                    brdRestriction_Hazmat {
                        status
                    }
                    brdRestriction_NoFlyListed {
                        status
                    }
                    brdRestriction_EbsCard {
                        status
                    }
                    brdRestriction_Illness {
                        status
                    }
                    brdRestriction_Quarantined {
                        status
                    }
                }
                currentCarrierID {
                    _id
                    desig
                }
            }
        }
    }
}`