import gql from "graphql-tag";

export default gql`
query GetCustomerManifestPrintout($id: ID!){
    getCustomer(_id: $id){
        _id
        name
        manifestPrintout {
            type
            layouts {
                name
                logo
            }
        }
    }
}
`