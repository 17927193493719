import React, { Component } from 'react';
import { Collapse, Icon, Divider, Row, Col, Tooltip } from 'antd';
import LegItem from './LegItem';

const { Panel } = Collapse;

const PanelHeader = (props) => (
    <Row type="flex">
        <Col style={{ flex: 1 }}>
            <span>Leg {props.order || 1}</span>
            <Divider type="vertical" />
            <span>{props.departure}</span>
            <Icon style={{ margin: '0 1rem' }} type="arrow-right" />
            <span>{props.destination}</span>
        </Col>
        <Col style={{ marginRight: '1rem' }}>
            <Tooltip title="Passenger Count/Weight">
                <Icon type="team" /> {props.paxCount || 0}/{props.paxWeight || 0}
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Baggage Count/Weight">
                <Icon type="shopping" /> {props.bagCount || 0}/{props.bagWeight || 0}
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Cargo Count/Weight">
                <Icon type="right-square" /> {props.cgoCount || 0}/{props.cgoWeight || 0}
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Total Weight">
                <span>Total Wt: </span> {(props.paxWeight || 0) + (props.bagWeight || 0) + (props.cgoWeight || 0)}
            </Tooltip>
        </Col>
    </Row>
)

class CollapsibleLegs extends Component {
    render() { 
        let { data } = this.props;
        if (!data) data = []
        return (
            <Collapse>
                {data.map((leg, idx) => (
                    <Panel key={idx} header={
                        <PanelHeader 
                            order={idx + 1} 
                            departure={leg.departure} 
                            destination={leg.destination} 
                            paxWeight={leg.paxWeight || 0}
                            bagCount={leg.bagCount || 0}
                            bagWeight={leg.bagWeight || 0}
                            cgoWeight={leg.cgoWeight || 0}
                            paxCount={(leg.paxIDs && leg.paxIDs.length) || 0}
                            cgoCount={(leg.cgoIDs && leg.cgoIDs.length) || 0}
                            />
                    }>
                        <LegItem data={leg} />
                    </Panel>
                ))}
            </Collapse>
        );
    }
}
 
export default CollapsibleLegs;