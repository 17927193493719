import { MutationResult, QueryResult } from '@apollo/react-common';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Checkbox, Form, Icon, Input, InputNumber, message, Spin, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ColumnProps } from 'antd/lib/table';
import { NetworkStatus } from 'apollo-client/core/networkStatus';
import { createFormField, createFormFields, getFieldKey, validateLabelInValue } from 'common/form';
import { cleanGraphQLErrorMsg, dateMtimeMtoMoment, dateMtimeMtoZuluStr, dateToZulu, detectInvalidDate, getMinuteDurationAsHrMin, getMomentIntervalsWithOverlaps, getPilotName, momentOrNull } from 'common/util';
import DateTimePicker, { DateTimePickerValue, strToDateTimePickerValue } from 'components/date-time-picker';
import EditableCell from 'components/editable-cell';
import ETable from 'components/enchanced-antd-table';
import ContractSelect from 'components/form/ContractSelect';
import LocationSelect from 'components/form/select/LocationSelect/LocationSelect2';
import PilotSelect from 'components/form/PilotSelect';
import { OrgDataContext } from 'context/orgData';
import { ThemeContext } from 'context/theme';
import gql from 'graphql-tag';
import useUserGroups from 'hooks/useUserGroups';
import moment, { Moment } from 'moment';
import React, { MutableRefObject, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import uuid from 'uuid/v4';
import { dispatchFormDataToArray, formatDateTime, getTotalDurationHoursMinutes } from 'components/flytwatch/aircraft-history/util';

export interface FWHistoryEditorProps {
    aircraftID: string,
    flightDate: string,
    /**
     * By default, this component will use a context provider declared within itself to handle state.
     * However, if you need to handle this component's higher up in the hierarchy, set this to true.
     * Just make sure that you use the Provider component in a parent component when setting this to true.
     */
    useExternalContextProvider?: boolean,
    onFormFieldsChange?: (fields: any) => void,
    formFields?: any,
    onFieldReset?: (fieldName: string) => void,
    onDiscard?: () => void
}

export interface FWHistoryEditorContextProps {
    data: Array<any>,
    queryResult: QueryResult,
    formRef: MutableRefObject<any>,
    newRecords: NewRecord[],
    // setNewRecordAdded: (id: string, added: boolean) => void,
    formData: any,
    setFormData: (data: any) => void,
    initFormData: any,

    // All new records that have data in them
    changedNewRecords: Set<string>,

    // All new records that are completely blank
    notChangedNewRecords: Set<string>,

    // Mapping of new record IDs to number of fields that were changed
    addableRecordsChangedFieldCount: Map<string, number>,

    // Calls mutation and returns changedHistory and returns a promise with results
    approve: () => Promise<{ changedHistory: any[], deletedHistoryIDs: string[] }>,

    // Calls mutation and returns deletedHistoryIDs and returns a promise with results
    remove: () => Promise<{ deletedHistoryIDs: string[] }>,

    approveMutationResult: MutationResult,
    deleteMutationResult: MutationResult,
    isApproved?: boolean
}

export const FWHistoryEditorContext = React.createContext<FWHistoryEditorContextProps>(null);

export interface FWHistoryEditorContextProviderProps {
    aircraftID: string,
    flightDate: string,
    numNewEmptyRows?: number,
    onApproved?: (flightDate: string) => void,
    onDiscard?: () => void
}

const QUERY = gql`
query DispatchHistoryFlights($aircraftID: ID!, $dateStart: AWSDateTime!, $dateEnd: AWSDateTime!, $tpID: ID!) {
    DispatchHistoryFlights(aircraftID: $aircraftID, dateStart: $dateStart, dateEnd: $dateEnd, tpID: $tpID, limit: 1000) {
        bookmark
        warning
        warningCode
        docs {
            _id
            ... on DispatchHistory {
                _id
                name
                ato
                eta
                ata
                pob
                scheduledFlightDate
                approvedBy
                dispatchDoc {
                    _id
                    getTzAbbr
                }
                aircraftDoc {
                    _id
                    tailNum
                }
                contractID
                contractDoc {
                    _id
                    name
                }
                departingID
                departingDoc {
                    _id
                    name
                    areaBlockName
                }
                destinationID
                destinationDoc {
                    _id
                    name
                    areaBlockName
                }
                pilotID
                pilotDoc {
                    _id
                    name {
                        firstName
                        lastName
                    }
                }
                coPilotID
                coPilotDoc {
                    _id
                    name {
                        firstName
                        lastName
                    }
                }
                _r_latestRemark {
                    remark
                    remarkTime
                }
                remark {
                    remark
                    remarkTime
                }
                getTzAbbr
            }
        }
    }
    getAircraft(_id: $aircraftID){
        _id
        tailNum
    }
}
`

const MUTATION_APPROVE = gql`
mutation ApproveDispatchHistory(
    $aircraftID: ID!
    $startTime: AWSDateTime!
    $endTime: AWSDateTime!
    $changedHistory: [ApproveDispatchFlightHistoryInput!]
    $deleteHistoryIDs: [ID!]
    $tpID: ID!
    $tzAbbr: String!
){
    ApproveDispatchFlightHistory(
        aircraftID: $aircraftID,
        startTime: $startTime,
        endTime: $endTime,
        tpID: $tpID,
        changedHistory: $changedHistory,
        deleteHistoryIDs: $deleteHistoryIDs,
        tzAbbr: $tzAbbr
    ){
        changedOrAdded {
            _id
            name
            ato
            eta
            ata
            pob
            aircraftDoc {
                _id
                tailNum
            }
            contractID
            contractDoc {
                _id
                name
            }
            departingDoc {
                _id
                name
                areaBlockName
            }
            destinationDoc {
                _id
                name
                areaBlockName
            }
            pilotDoc {
                _id
                name {
                    firstName
                    lastName
                }
            }
            coPilotDoc {
                _id
                name {
                    firstName
                    lastName
                }
            }
            _r_latestRemark {
                remark
                remarkTime
            }
        }
        deletedIDs
    }
}
`

const MUTATION_DELETE = gql`
mutation ApproveDispatchHistory(
    $deleteHistoryIDs: [ID!]
    $tzAbbr: String!
){
    DeleteDispatchFlightHistory(
        deleteHistoryIDs: $deleteHistoryIDs,
        tzAbbr: $tzAbbr
    ){
        deletedIDs
    }
}
`

interface NewRecord {
    _id: string,
    new: boolean,
    added: boolean
}

const FormValueGetters = {
    dep: (record) => ({ key: record.departingDoc?._id, label: record.departingDoc?.areaBlockName }),
    dest: (record) => ({ key: record.destinationDoc?._id, label: record.destinationDoc?.areaBlockName }),
    ato: (record) => strToDateTimePickerValue(record.ato),
    ata: (record) => strToDateTimePickerValue(record.ata),
    pob: (record) => record.pob || 0,
    contract: (record) => ({ key: record.contractDoc?._id, label: record.contractDoc?.name }),
    pilot: (record) => ({ key: record.pilotDoc?._id, label: getPilotName(record.pilotDoc) }),
    coPilot: (record) => ({ key: record.coPilotDoc?._id, label: getPilotName(record.coPilotDoc) }),
    remarks: (record) => record.remarks
}

const FormValueGettersNewRecords = {
    dep: () => ({ key: '' }),
    dest: () => ({ key: '' }),
    ato: (lastRecord) => ({ date: momentOrNull(lastRecord?.ato) }),
    ata: (lastRecord) => ({ date: momentOrNull(lastRecord?.ata) }),
    pob: () => 0,
    contract: (lastRecord) => ({ key: lastRecord?.contractDoc?._id, label: lastRecord?.contractDoc?.name }),
    pilot: (lastRecord) => ({ key: lastRecord?.pilotDoc?._id, label: getPilotName(lastRecord?.pilotDoc) }),
    coPilot: (lastRecord) => ({ key: lastRecord?.coPilotDoc?._id, label: getPilotName(lastRecord?.coPilotDoc) }),
    remarks: () => ''
}

function genNewRecordId(){
    return 'new-' + uuid()
}

/**
 * Finds index of the last row that is partially filled out
 */
 function lastFilledRecordIdx(form: WrappedFormUtils, newRecords: NewRecord[]){
    let fieldValues = form?.getFieldsValue() || {}; // Sometimes form is null on first render. Need to check for that.
    let fieldKeys = Object.keys(fieldValues);

    for (let i = newRecords.length-1; i !== 0; i--){
        let r = newRecords[i];
        for (let j = 0; j < fieldKeys.length; j++){
            let k = fieldKeys[j];

            if (k.startsWith(r._id) && (k.endsWith('add') || k.endsWith('pob') || k.endsWith('contract') || k.endsWith('pilot') || k.endsWith('coPilot'))){
                continue
            }

            if (k.startsWith(r._id) && (k.endsWith('ato') || k.endsWith('ata'))){
                if (fieldValues[k]?.time){
                    return i
                }
                else{
                    continue
                }
            }
            if (k.startsWith(r._id) && getFieldKey(fieldValues[k])){
                return i
            }
        }
    }

    return -1
}

function initNewRecords(len: number): Map<String, NewRecord>{
    let data = new Map<String, NewRecord>();

    for (let i = 0; i < len; i++){
        let newR = {
            "_id": genNewRecordId(),
            "new": true,
            "added": false
        }
        data.set(newR._id, newR);
    }

    return data
}

/**
 * Builds a list of all new records where the value of each field has changed by the user.
 * @param formState Current state of the form fields
 * @param initFormState Initial state of the form fields (will be checked against by formState)
 * @param sideValues Other results computed, such as notChangedNewRecords (inverse of the main return)
 * @returns Set of all ids of the new records
 */
function getChangedNewRecordIDs(formState: any, initFormState: any, sideValues?: {
    notAddableRecords: Set<string>,
    addableRecordsChangedFieldCount: Map<string, number>
}){
    let formStateEntries = Object.entries(formState || {});

    // Build a list of all row ids
    let uniqueIDs = new Set<string>();

    // Build a list of all fieldNames to look for
    let uniqueFields = new Set<string>();

    for (let i = 0; i < formStateEntries.length; i++) {
        const [ key ] = formStateEntries[i];
        let id = key.split("::")[0]
        let fieldName = key.split("::")[1]
        uniqueIDs.add(id);
        uniqueFields.add(fieldName);
    }

    uniqueFields.delete('add')

    let addableRecordIds = new Set<string>();

    let uniqueIDsArray = Array.from(uniqueIDs);
    let uniqueFieldsArray = Array.from(uniqueFields);

    for (let i = 0; i < uniqueIDsArray.length; i++) {
        const id = uniqueIDsArray[i]

        let isAddable = false;

        let fieldCountMap = new Map<string, number>();

        for (let j = 0; j < uniqueFieldsArray.length; j++) {
            const fieldName = uniqueFieldsArray[j];
            
            // Does this id and field combination have a different value from initial?
            if (formState[id + "::" + fieldName]?.value !== initFormState[id + "::" + fieldName]){
                addableRecordIds.add(id);
                isAddable = true;

                if (sideValues){
                    fieldCountMap[id] = (fieldCountMap[id] || 0) + 1;
                }
            }
        }

        if (isAddable === false && sideValues?.notAddableRecords){
            sideValues.notAddableRecords.add(id);
        }

        if (sideValues){
            sideValues.addableRecordsChangedFieldCount = fieldCountMap
        }

    }

    return addableRecordIds
}

export const FWHistoryEditorContextProvider: React.FC<PropsWithChildren<FWHistoryEditorContextProviderProps>> = props => {

    const orgData = useContext(OrgDataContext);

    const formRef = useRef(null);
    const [ newRecords, setNewRecords ] = useState(initNewRecords(props.numNewEmptyRows))
    const [ formData, setFormData ] = useState({});
    const [ initFormData, setInitFormData ] = useState({});
    const [ approved, setApproved ] = useState(false);

    // Expand and shrink the newRecords array depending on if the last record is filled in or not
    useEffect(() => {
        let newRecordsArray = Array.from(newRecords.values());
        let lastFilledRecord = lastFilledRecordIdx(formRef.current, newRecordsArray);

        if (lastFilledRecord === newRecords.size-1){
            // Add a new empty row
            let n = {
                "_id": genNewRecordId(),
                "added": false,
                "new": true
            }
            let newRecordsCopy = new Map(newRecords);
            newRecordsCopy.set(n._id, n);
            setNewRecords(newRecordsCopy);
        }

        if (newRecords.size > props.numNewEmptyRows && lastFilledRecord < newRecords.size-2){
            // There are extra empty rows that can be removed
            let entries = Array.from(newRecords.entries());

            let newRecordsCopy = new Map(newRecords);
            let deleteOccurred = false;

            for (let i = Math.max(props.numNewEmptyRows, lastFilledRecord+1); i < entries.length; i++){
                let key = entries[i][0];
                newRecordsCopy.delete(key);
                deleteOccurred = true;
            }
            
            if (deleteOccurred){
                setNewRecords(newRecordsCopy);
            }
        }
    // eslint-disable-next-line
    }, [newRecords, formData])

    let sideValues = {
        notAddableRecords: new Set<string>(),
        addableRecordsChangedFieldCount: new Map<string, number>()
    }

    // getChangedNewRecordIDs should be recalculated only when the form changes (i hope)
    // eslint-disable-next-line
    let changedNewRecords = useMemo(() => getChangedNewRecordIDs(formData, initFormData, sideValues), [ formData, initFormData ]);
    let notChangedNewRecords = sideValues.notAddableRecords;

    let queryVars = {
        aircraftID: props.aircraftID,
        dateStart: dateToZulu(moment(props.flightDate)),
        dateEnd: dateToZulu(moment(props.flightDate).add(1, 'day')),
        tpID: orgData.getOrgIDByType('transporter')
    }

    const result = useQuery(QUERY, {
        variables: queryVars,
        skip: !props.flightDate,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: 15000,
        onError: (err) => {
            let notFoundAircraft = err.graphQLErrors.find(e => e.path?.[0] === "getAircraft" && e.errorType === "404");
            // We cannot deal with flight history with an aircraft that doesn't exist! Get out of here!
            if (notFoundAircraft){
                message.error("This aircraft could not be found in the database. Exiting this page...");
                props.onDiscard(); // We have no business being here
            }
        }
    })

    const [ approveMutation, approveMutationRes ] = useMutation(MUTATION_APPROVE);

    const [ deleteMutation, deleteMutationRes ] = useMutation(MUTATION_DELETE);

    let hisData: any[] = result.data?.DispatchHistoryFlights?.docs || [];

    // Show only the historical data
    hisData = hisData.filter((doc) => String(doc._id).endsWith("--HIST"))

    hisData.sort((a, b) => {
        if (a.ato < b.ato){
            return -1;
        }
        else if (a.ato === b.ato){
            return 0;
        }
        else {
            return 1;
        }
    })

    let hisTzAbbr = null;
    if (hisData.length > 0){
        for (let i = 0; i < hisData.length; i++) {
            if (hisData[i]?.dispatchDoc){
                hisTzAbbr = hisData[i].dispatchDoc.getTzAbbr
                break;
            }
            else
            {
                hisTzAbbr = hisData[i].getTzAbbr
            }
        }   
    }

    useEffect(() => {
        if (approved){
            props.onApproved?.(dateToZulu(hisData[0].ato));
        }
    // eslint-disable-next-line
    }, [ approved ])

    // Simplify remarks field
    let tableData = hisData.map((item) => ({
        ...item,
        remarks: item._r_latestRemark?.remark
    }))

    let lastRecord = tableData[tableData.length-1]

    function toFormData(){
        let data = {};
        hisData.forEach((record: any) => {
            Object.entries(FormValueGetters).forEach(([key, func]) => {
                let dataKey = buildFieldKey(record, key);
                let dataValue = func(record);
                data[dataKey] = createFormField(dataValue);
            })
        })
        newRecords.forEach((record: any) => {
            Object.entries(FormValueGettersNewRecords).forEach(([key, func]) => {
                let dataKey = buildFieldKey(record, key);
                let dataValue = func(lastRecord);
                data[dataKey] = createFormField(dataValue);
            })
        })
        return data
    }

    useEffect(() => {
        if (result.networkStatus === NetworkStatus.ready || result.networkStatus === NetworkStatus.loading){
            setInitFormData(toFormData())
        }
    // eslint-disable-next-line
    }, [result.networkStatus, newRecords])

    if (!result) return null

    return <FWHistoryEditorContext.Provider value={{
        data: tableData,
        queryResult: result,
        formRef,
        newRecords: Array.from(newRecords.values()),
        formData,
        setFormData,
        initFormData,
        changedNewRecords,
        notChangedNewRecords,
        addableRecordsChangedFieldCount: sideValues.addableRecordsChangedFieldCount,
        remove: () => {
            return new Promise(async (resolve, reject) => {
                let deletedIDs = [];
                if (Object.keys(formData).length > 0){
                    dispatchFormDataToArray(formData)
                    .forEach((item) => {
                        if (item.remove){
                            deletedIDs.push(item._id);
                            return false
                        }
                        return true
                    })
                }

                if (!deletedIDs){
                    message.error("Cannot delete. There is nothing to delete.");
                    return;
                }

                deleteMutation({
                    variables: {
                        tzAbbr: hisTzAbbr,
                        deleteHistoryIDs: deletedIDs.length > 0 ? deletedIDs : undefined
                    },
                    update: (cache, result) => {
                        cache.writeQuery({
                            query: QUERY,
                            variables: queryVars,
                            data: {
                                ...result.data,
                                DispatchHistoryFlights: {
                                    ...result.data.DispatchHistoryFlights,
                                    docs: hisData.filter((record) => !deletedIDs.includes(record._id) )
                                }
                            }
                        })
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                    if (String(err.message).includes('permission')){
                        message.error(cleanGraphQLErrorMsg(err.message));
                    }
                    else
                    {
                        message.error("Failed to delete due to an error")
                    }
                })
            })
        },
        approve: () => {
            const findHis = (id) => (his) => his._id === id
            return new Promise(async (resolve, reject) => {
                let changedData: any[] = undefined;
                let deletedIDs = [];
                if (Object.keys(formData).length > 0){
                    changedData = dispatchFormDataToArray(formData)
                    .filter((item) => {
                        if (item.remove){
                            deletedIDs.push(item._id);
                            return false
                        }
                        return true
                    })
                    .map((item) => {
                        return {
                            _id: item._id,
                            ata: detectInvalidDate(
                                dateMtimeMtoZuluStr(item.ata.date, item.ata.time),
                                hisData.find(findHis(item._id))?.ata
                            ),
                            ato: detectInvalidDate(
                                dateMtimeMtoZuluStr(item.ato.date, item.ato.time),
                                hisData.find(findHis(item._id))?.ato
                            ),
                            contractID: item.contract?.key,
                            departingID: item.dep?.key,
                            destinationID: item.dest?.key,
                            pilotID: item.pilot?.key,
                            coPilotID: item.coPilot?.key,
                            pob: item.pob,
                            remark: item.remarks
                        }
                    })
                }
                if (!hisTzAbbr){
                    message.error("Cannot approve. This flight history has no timezone information.")
                    return;
                }
                approveMutation({
                    variables: {
                        aircraftID: props.aircraftID,
                        startTime: dateToZulu(moment(props.flightDate)),
                        endTime: dateToZulu(moment(props.flightDate).add(1, 'day')),
                        tpID: orgData.getOrgIDByType('transporter'),
                        tzAbbr: hisTzAbbr,
                        changedHistory: changedData,
                        deleteHistoryIDs: deletedIDs.length > 0 ? deletedIDs : undefined
                    }
                })
                .then((res) => {
                    resolve(res.data);
                    setApproved(true);
                })
                .catch((err) => {
                    reject(err);
                    if (String(err.message).includes('permission')){
                        message.error(cleanGraphQLErrorMsg(err.message));
                    }
                    else
                    {
                        message.error("Failed to submit approval due to an error")
                    }
                })
            })
        },
        approveMutationResult: approveMutationRes,
        deleteMutationResult: deleteMutationRes,
        isApproved: approved
        // setNewRecordAdded: (id: string, added: boolean) => {
        //     let modifiedNewRecs = new Map(newRecords);
        //     if (modifiedNewRecs.has(id)){
        //         modifiedNewRecs.get(id).added = added || false
        //     }
        //     else
        //     {
        //         modifiedNewRecs.set(id,
        //             {
        //                 "_id": genNewRecordId(),
        //                 "added": added || false,
        //                 "new": true
        //             }
        //         )
        //     }

        //     setNewRecords(modifiedNewRecs);
        // }
    }}>
        {props.children}
    </FWHistoryEditorContext.Provider>
}

FWHistoryEditorContextProvider.defaultProps = {
    numNewEmptyRows: 5
}

function validateDateTimePicker(rule, value: DateTimePickerValue, callback){
    if (rule.required && !value){
        callback(rule.message)
    }
    else if (!value.date){
        callback('Please enter a date')
    }
    else if (!value.time){
        callback('Please enter a time')
    }
    else
    {
        callback()
    }
}

class AtoAtaMap extends Map<string, { ato: Moment, ata: Moment }>{
    put(id: string, ato?: Moment, ata?: Moment){
        if (this.has(id)){
            let val = this.get(id);
            if (ata)
                val.ata = ata;
            if (ato)
                val.ato = ato;
        }
        else
        {
            super.set(id, { ato, ata })
        }
    }
}

function validateReverseTimeRange(form: WrappedFormUtils, docID: string, fieldType: 'ato' | 'ata'){
    return (_, value, callback) => {
        let ato: Moment, ata: Moment;
        if (fieldType === 'ato'){
            ato = dateMtimeMtoMoment(value);
            ata = dateMtimeMtoMoment(form.getFieldValue(buildFieldKey(docID, 'ata')));
        }
        else
        {
            ato = dateMtimeMtoMoment(form.getFieldValue(buildFieldKey(docID, 'ato')));
            ata = dateMtimeMtoMoment(value);
        }

        // Seconds don't matter
        ato.set({ seconds: 0, ms: 0 });
        ata.set({ seconds: 0, ms: 1 });

        if (ato && ata){
            if (fieldType === 'ata' && ata.isBefore(ato)){
                console.debug('ATA cannot be before ATO.', ato.format(), ata.format());
                callback('ATA cannot be before ATO.');
                return
            }
            else if (fieldType === 'ato' && ato.isAfter(ata)){
                console.debug('ATO cannot be after ATA.', ato.format(), ata.format());
                callback('ATO cannot be after ATA.');
                return
            }
        }
        callback();
    }
}

function validateTimeOverlaps(form: WrappedFormUtils, docID: string, fieldType: 'ata' | 'ato'){
    return (_, value, callback) => {

        let atoAtaMap = new AtoAtaMap();
        let allValuesEntries = Object.entries(form.getFieldsValue());

        for (let i = 0; i < allValuesEntries.length; i++) {
            const [ k, v ] = allValuesEntries[i];
            let splitK = k.split('::');
            let fieldName: string;
            if (splitK.length > 1){
                fieldName = splitK[1];
            }

            if (form.getFieldValue(splitK[0] + '::remove')){
                continue
            }

            // Skip if is a new record and is not added
            if ((fieldName === 'ato' || fieldName === 'ata') && k.startsWith('new') && !form.getFieldValue(splitK[0] + '::add')){
                continue
            }
            if (fieldName === 'ato'){
                atoAtaMap.put(splitK[0], dateMtimeMtoMoment(v));
            }
            if (fieldName === 'ata'){
                atoAtaMap.put(splitK[0], undefined, dateMtimeMtoMoment(v));
            }
        }

        let currAtoAta = atoAtaMap.get(docID); // Get a ref of the current ato and ata before we remove it from the map

        let docIDToIntervalIdxMap = new Map<string, number>();

        // Convert to list of time ranges
        let timeRanges: Array<[Moment, Moment]> = Array.from(atoAtaMap.entries())
            .map(([docId, { ato, ata }], i) => {
                docIDToIntervalIdxMap.set(docId, i);
                return [ato, ata];
            });

        // If we are ato, find the ata, and vice versa
        let ato: Moment, ata: Moment;
        if (fieldType === 'ato'){
            ato = dateMtimeMtoMoment(value);
            ata = currAtoAta?.ata
        }
        else
        {
            ata = dateMtimeMtoMoment(value);
            ato = currAtoAta?.ato
        }
        if (!ata){
            callback('Please enter a corresponding ATA');
            return
        }
        else if (!ato){
            callback('Please enter a corresponding ATO');
            return
        }
        
        let overlappingIntervals = getMomentIntervalsWithOverlaps(timeRanges);

        let currentIntervalIdx = docIDToIntervalIdxMap.get(docID) || -1;

        if (overlappingIntervals.get(currentIntervalIdx) === 0 && fieldType === 'ato'){
            callback('Overlapping ATA detected!');
        }
        else if (overlappingIntervals.get(currentIntervalIdx) === 1 && fieldType === 'ata'){
            callback('Overlapping ATO detected!');
        }
        else {
            callback()
        }
    }
}

function buildFieldKey(record: any, fieldName: string){
    let id: string;
    if (typeof record === 'object'){
        id = record._id;
    }
    else{
        id = record;
    }
    return id + '::' + fieldName
}

function validateRow(enable: boolean, additionalValidator?: Function){
    return (rule, value, callback) => {
        if (enable && rule.required && !getFieldKey(value)){
            console.debug('Field validation failed: ' + rule.message)
            callback(rule.message)
        }
        else if (enable && additionalValidator){
            additionalValidator(rule, value, callback);
        }
        else
        {
            callback()
        }
    }
}

const FWHistoryEditor_Internal: React.FC<FWHistoryEditorProps & FormComponentProps> = (props) => {
    const { form: { getFieldDecorator, getFieldValue, resetFields, setFieldsValue } } = props;

    let { data, newRecords, changedNewRecords, notChangedNewRecords, initFormData, formData, setFormData, queryResult } = React.useContext(FWHistoryEditorContext);
    const [ userGroups ] = useUserGroups();

    const userCanEdit = userGroups.includes('flytsuite.flytwatch.history.edit');
    const userCanDelete = userGroups.includes('flytsuite.flytwatch.history.delete');
    const { themeName } = useContext(ThemeContext);

    function renderFlightDuration(_, record) {
        let ato = dateMtimeMtoMoment(getFieldValue(buildFieldKey(record, 'ato')));
        let ata = dateMtimeMtoMoment(getFieldValue(buildFieldKey(record, 'ata')));
    
        if (!ata || !ato) return null;
    
        return getMinuteDurationAsHrMin(ato, ata);
    }

    useEffect(() => {
        notChangedNewRecords.forEach((id) => {
            if (getFieldValue(id + '::add')){
                setFieldsValue({
                    [id + '::add']: false
                })
            }
        })
    // eslint-disable-next-line
    }, [ notChangedNewRecords.size ])

    function resetField(record: any, fieldName: string){
        let key = buildFieldKey(record, fieldName);
        resetFields([key])
        setFormData({
            ...formData,
            [key]: initFormData[key]
        })
        props.onFieldReset?.(key);
    }

    function isRecordAdded(record: any){
        let id = record?._id;
        if (!id) return false
        if (formData[id + '::add']?.value)
            return true
        else
            return false
    }

    const handleNewRecordFieldChange = (id: string) => {
        if (!changedNewRecords.has(id)){
            setFieldsValue({
                [id + '::add']: true
            })
        }
    }

    function isRecordRemoved(record: any){
        return formData[buildFieldKey(record, 'remove')]?.value === true;
    }

    let columns: ColumnProps<any>[] = [
        {
            key: 'flt',
            title: 'FLT',
            width: 40,
            render: (_,__,i) =>  i+1
        },
        {
            key: 'aircraft',
            title: 'Aircraft',
            width: 80,
            render: (_, record) => record.aircraftDoc?.tailNum
        },
        {
            key: 'dep',
            title: 'Departing',
            width: 140,
            render: (_, record) => <EditableCell
                initValue={record.departingDoc?.areaBlockName}
                changedValue={getFieldValue(buildFieldKey(record, 'dep'))?.label}
                onReset={() => resetField(record, 'dep')}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'dep'), {
                        rules: [ { required: true, message: 'Please select a departing location', validator: validateRow(!isRecordRemoved(record), validateLabelInValue) } ],
                        initialValue: FormValueGetters.dep(record)
                    })(
                        <LocationSelect
                            includeOneOff
                            size="small"
                            style={{ width: 140 }}
                            labelInValue
                            disabled={!userCanEdit || isRecordRemoved(record)}
                        />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'dest',
            title: 'Destination',
            width: 140,
            render: (_, record) =>  <EditableCell
                initValue={record.destinationDoc?.areaBlockName}
                changedValue={getFieldValue(buildFieldKey(record, 'dest'))?.label}
                onReset={() => resetField(record, 'dest')}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'dest'), {
                        rules: [ { required: true, message: 'Please select a destination location', validator: validateRow(!isRecordRemoved(record), validateLabelInValue) } ],
                        initialValue: FormValueGetters.dest(record)
                    })(
                        <LocationSelect
                            includeOneOff
                            size="small"
                            style={{ width: 140 }}
                            labelInValue
                            disabled={!userCanEdit || isRecordRemoved(record)}
                        />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'ato',
            title: 'ATO',
            width: 190,
            render: (_, record) => <EditableCell
                initValue={formatDateTime(strToDateTimePickerValue(record.ato))}
                changedValue={formatDateTime(getFieldValue(buildFieldKey(record, 'ato')))}
                onReset={() => resetField(record, 'ato')}
                maxTextWidth={120}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'ato'), {
                        rules: [
                            { required: true, message: 'Please enter an ATO', validator: validateRow(!isRecordRemoved(record), validateDateTimePicker) },
                            { validator: validateRow(!isRecordRemoved(record), validateTimeOverlaps(props.form, record._id, 'ato')) },
                            { validator: validateRow(!isRecordRemoved(record), validateReverseTimeRange(props.form, record._id, 'ato')) }
                        ],
                        initialValue: FormValueGetters.ato(record)
                    })(
                        <DateTimePicker datePickerProps={{ disabled: !userCanEdit || isRecordRemoved(record) }} timePickerProps={{ disabled: !userCanEdit || isRecordRemoved(record) }} />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'ata',
            title: 'ATA',
            width: 190,
            render: (_, record) => <EditableCell
                initValue={formatDateTime(strToDateTimePickerValue(record.ata))}
                changedValue={formatDateTime(getFieldValue(buildFieldKey(record, 'ata')))}
                onReset={() => resetField(record, 'ata')}
                maxTextWidth={120}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'ata'), {
                        rules: [
                            { required: true, message: 'Please enter an ATA', validator: validateRow(!isRecordRemoved(record), validateDateTimePicker) },
                            { validator: validateRow(!isRecordRemoved(record), validateTimeOverlaps(props.form, record._id, 'ata')) },
                            { validator: validateRow(!isRecordRemoved(record), validateReverseTimeRange(props.form, record._id, 'ata')) }
                        ],
                        initialValue: FormValueGetters.ata(record)
                    })(
                        <DateTimePicker datePickerProps={{ disabled: !userCanEdit || isRecordRemoved(record) }} timePickerProps={{ disabled: !userCanEdit || isRecordRemoved(record) }} />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'time',
            title: 'Time',
            width: 75,
            render: renderFlightDuration
        },
        {
            key: 'pob',
            title: 'POB',
            dataIndex: 'pob',
            width: 50,
            render: (value, record) => <EditableCell
                initValue={value}
                changedValue={getFieldValue(buildFieldKey(record, 'pob'))}
                onReset={() => resetField(record, 'pob')}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'pob'), {
                        rules: [ { required: true, message: 'Please enter POB', validator: validateRow(!isRecordRemoved(record))  } ],
                        initialValue: FormValueGetters.pob(record)
                    })(
                        <InputNumber size="small" style={{ width: 50 }} min={0} max={1000} disabled={!userCanEdit || isRecordRemoved(record)} />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'contract',
            title: 'Contract',
            width: 180,
            render: (_, record) => <EditableCell
                initValue={record.contractDoc?.name}
                changedValue={getFieldValue(buildFieldKey(record, 'contract'))?.label}
                onReset={() => resetField(record, 'contract')}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'contract'), {
                        rules: [ { required: true, message: 'Please select a contract', validator: validateRow(!isRecordRemoved(record), validateLabelInValue) } ],
                        initialValue: FormValueGetters.contract(record)
                    })(
                        <ContractSelect
                            size="small"
                            style={{ width: 180 }}
                            labelInValue
                            disabled={!userCanEdit || isRecordRemoved(record)}
                        />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'pilot',
            title: 'Pilot',
            width: 180,
            render: (_, record) => <EditableCell
                initValue={getPilotName(record.pilotDoc)}
                changedValue={getFieldValue(buildFieldKey(record, 'pilot'))?.label}
                onReset={() => resetField(record, 'pilot')}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'pilot'), {
                        rules: [ { required: true, message: 'Please select a pilot', validator: validateRow(!isRecordRemoved(record), validateLabelInValue) } ],
                        initialValue: FormValueGetters.pilot(record)
                    })(
                        <PilotSelect
                            size="small"
                            style={{ width: 180 }}
                            labelInValue
                            disabled={!userCanEdit || isRecordRemoved(record)}
                        />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'coPilot',
            title: 'Co-Pilot',
            width: 180,
            render: (_, record) => <EditableCell
                initValue={getPilotName(record.coPilotDoc)}
                changedValue={getFieldValue(buildFieldKey(record, 'coPilot'))?.label}
                onReset={() => resetField(record, 'coPilot')}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'coPilot'), {
                        initialValue: FormValueGetters.coPilot(record)
                    })(
                        <PilotSelect
                            size="small"
                            style={{ width: 180 }}
                            labelInValue
                            disabled={!userCanEdit || isRecordRemoved(record)}
                        />
                    )}
                </Form.Item>
            </EditableCell>
        },
        {
            key: 'remarks',
            title: 'Remarks',
            dataIndex: 'remarks',
            width: 140,
            render: (value, record) => <EditableCell
                initValue={value}
                changedValue={getFieldValue(buildFieldKey(record, 'remarks'))}
                onReset={() => resetField(record, 'remarks')}
            >
                <Form.Item>
                    {getFieldDecorator(buildFieldKey(record, 'remarks'), {
                        initialValue: FormValueGetters.remarks(record)
                    })(
                        <Input size="small" max={1000} style={{ width: 140 }} disabled={!userCanEdit || isRecordRemoved(record)} />
                    )}
                </Form.Item>
            </EditableCell>
        }
    ]

    if (userCanDelete){
        columns.unshift({
            key: 'remove',
            title: <Tooltip title="Remove"><Icon type='delete' /></Tooltip>,
            width: 33,
            render: (_, record) => getFieldDecorator(buildFieldKey(record, 'remove'), {
                valuePropName: 'checked'
            })(
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Checkbox key={'remove-' + record._id} />
                </div>
            )
        })
    }
    else
    {
        columns.unshift({
            key: 'remove',
            title: <Tooltip title="Remove"><Icon type='delete' /></Tooltip>,
            width: 70,
            render: (_, record) => <Tooltip title="You do not have permission to remove history records.">
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Checkbox disabled key={'remove-' + record._id} />
                </div>
            </Tooltip>
        })
    }

    let lastRecord = data[data.length-1]

    let atoAtaList = []

    let fullData = [...data, ...newRecords];

    fullData.forEach(row => {

        // Exclude new records that are not checked
        if (row.new && !getFieldValue(buildFieldKey(row, 'add'))) return

        atoAtaList.push({
            ato: getFieldValue(buildFieldKey(row, 'ato')),
            ata: getFieldValue(buildFieldKey(row, 'ata'))
        })
    })

    return <Spin size="large" spinning={queryResult.networkStatus === NetworkStatus.loading} indicator={<Icon type="loading" />}>
        <div className='mc-flytwatch-history-editor-content-wrapper'>
            <ETable
                className={"mc-table"}
                dataSource={data}
                totals={{
                    label: <div style={{ textAlign: 'right', lineHeight: '15px' }}>
                        <span>Total time</span><br/>
                        <span style={{ fontSize: '0.7rem', opacity: 0.75 }}>Includes missing records</span>
                    </div>,
                    colNum: 6,
                    td: [<td>{getTotalDurationHoursMinutes(atoAtaList)}</td>],
                    sticky: true
                }}
                rowKey="_id"
                size="small"
                bordered
                columns={columns}
                stickyHeader
                pagination={false}
            />
            <h3 style={{ marginTop: "0.75rem" }}>Add missing flight records</h3>
            <ETable
                className={"mc-table"}
                dataSource={newRecords}
                rowKey="_id"
                size="small"
                bordered
                columns={[
                    {
                        key: 'add',
                        title: 'Add',
                        width: 45,
                        render: (_, record) => getFieldDecorator(buildFieldKey(record, 'add'), {
                            valuePropName: 'checked'
                        })(
                            <Checkbox disabled={!changedNewRecords.has(record._id) || !userCanEdit} />
                        )
                    },
                    {
                        key: 'aircraft',
                        title: 'Aircraft',
                        width: 80,
                        render: () => lastRecord?.aircraftDoc?.tailNum
                    },
                    {
                        key: 'dep',
                        title: 'Departing',
                        width: 140,
                        render: (_, record) => <EditableCell
                            changedValue={getFieldValue(buildFieldKey(record, 'dep'))?.label}
                            onReset={() => resetField(record, 'dep')}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'dep'), {
                                    rules: [ { required: true, message: 'Please select a departing location', validator: validateRow(isRecordAdded(record)) } ],
                                    initialValue: FormValueGettersNewRecords.dep()
                                })(
                                    <LocationSelect
                                        includeOneOff
                                        size="small"
                                        style={{ width: 140 }}
                                        labelInValue
                                        allowClear
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                        disabled={!userCanEdit}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'dest',
                        title: 'Destination',
                        width: 140,
                        render: (_, record) =>  <EditableCell
                            changedValue={getFieldValue(buildFieldKey(record, 'dest'))?.label}
                            onReset={() => resetField(record, 'dest')}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'dest'), {
                                    rules: [ { required: true, message: 'Please select a destination location', validator: validateRow(isRecordAdded(record)) } ],
                                    initialValue: FormValueGettersNewRecords.dest()
                                })(
                                    <LocationSelect
                                        includeOneOff
                                        size="small"
                                        style={{ width: 140 }}
                                        labelInValue
                                        allowClear
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                        disabled={!userCanEdit}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'ato',
                        title: 'ATO',
                        width: 190,
                        render: (_, record) => <EditableCell
                            changedValue={formatDateTime(getFieldValue(buildFieldKey(record, 'ato')))}
                            onReset={() => resetField(record, 'ato')}
                            maxTextWidth={120}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'ato'), {
                                    rules: [
                                        { required: true, message: 'Please enter an ATO', validator: validateRow(isRecordAdded(record), validateDateTimePicker) },
                                        { validator: validateRow(isRecordAdded(record), validateTimeOverlaps(props.form, record._id, 'ato')) },
                                        { validator: validateRow(isRecordAdded(record), validateReverseTimeRange(props.form, record._id, 'ato')) }
                                    ],
                                    initialValue: FormValueGettersNewRecords.ato(lastRecord)
                                })(
                                    <DateTimePicker
                                        datePickerProps={{ allowClear: true, disabled: !userCanEdit }}
                                        timePickerProps={{ allowClear: true, disabled: !userCanEdit }}
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'ata',
                        title: 'ATA',
                        width: 190,
                        render: (_, record) => <EditableCell
                            changedValue={formatDateTime(getFieldValue(buildFieldKey(record, 'ata')))}
                            onReset={() => resetField(record, 'ata')}
                            maxTextWidth={120}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'ata'), {
                                    rules: [
                                        { required: true, message: 'Please enter an ATA', validator: validateRow(isRecordAdded(record), validateDateTimePicker) },
                                        { validator: validateRow(isRecordAdded(record), validateTimeOverlaps(props.form, record._id, 'ata')) },
                                        { validator: validateRow(isRecordAdded(record), validateReverseTimeRange(props.form, record._id, 'ata')) }
                                    ],
                                    initialValue: FormValueGettersNewRecords.ata(lastRecord)
                                })(
                                    <DateTimePicker
                                        datePickerProps={{ allowClear: true, disabled: !userCanEdit }}
                                        timePickerProps={{ allowClear: true, disabled: !userCanEdit }}
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'time',
                        title: 'Time',
                        width: 75,
                        render: renderFlightDuration
                    },
                    {
                        key: 'pob',
                        title: 'POB',
                        dataIndex: 'pob',
                        width: 50,
                        render: (value, record) => <EditableCell
                            initValue={value}
                            changedValue={getFieldValue(buildFieldKey(record, 'pob'))}
                            onReset={() => resetField(record, 'pob')}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'pob'), {
                                    rules: [ { required: true, message: 'Please enter POB', validator: validateRow(isRecordAdded(record)) } ],
                                    initialValue: FormValueGettersNewRecords.pob()
                                })(
                                    <InputNumber
                                        size="small"
                                        style={{ width: 50 }}
                                        min={0}
                                        max={1000}
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                        disabled={!userCanEdit}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'contract',
                        title: 'Contract',
                        width: 180,
                        render: (_, record) => <EditableCell
                            changedValue={getFieldValue(buildFieldKey(record, 'contract'))?.label}
                            onReset={() => resetField(record, 'contract')}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'contract'), {
                                    rules: [ { required: true, message: 'Please select a contract', validator: validateRow(isRecordAdded(record)) } ],
                                    initialValue: FormValueGettersNewRecords.contract(lastRecord)
                                })(
                                    <ContractSelect
                                        size="small"
                                        style={{ width: 180 }}
                                        labelInValue
                                        allowClear
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                        disabled={!userCanEdit}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'pilot',
                        title: 'Pilot',
                        width: 180,
                        render: (_, record) => <EditableCell
                            changedValue={getFieldValue(buildFieldKey(record, 'pilot'))?.label}
                            onReset={() => resetField(record, 'pilot')}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'pilot'), {
                                    rules: [ { required: true, message: 'Please select a pilot', validator: validateRow(isRecordAdded(record)) } ],
                                    initialValue: FormValueGettersNewRecords.pilot(lastRecord)
                                })(
                                    <PilotSelect
                                        size="small"
                                        style={{ width: 180 }}
                                        labelInValue
                                        allowClear
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                        disabled={!userCanEdit}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'coPilot',
                        title: 'Co-Pilot',
                        width: 180,
                        render: (_, record) => <EditableCell
                            changedValue={getFieldValue(buildFieldKey(record, 'coPilot'))?.label}
                            onReset={() => resetField(record, 'coPilot')}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'coPilot'), {
                                    initialValue: FormValueGettersNewRecords.coPilot(lastRecord)
                                })(
                                    <PilotSelect
                                        size="small"
                                        style={{ width: 180 }}
                                        labelInValue
                                        allowClear
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                        disabled={!userCanEdit}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    },
                    {
                        key: 'remarks',
                        title: 'Remarks',
                        dataIndex: 'remarks',
                        width: 140,
                        render: (value, record) => <EditableCell
                            changedValue={getFieldValue(buildFieldKey(record, 'remarks'))}
                            onReset={() => resetField(record, 'remarks')}
                            showChanges={false}
                        >
                            <Form.Item>
                                {getFieldDecorator(buildFieldKey(record, 'remarks'), {
                                    initialValue: FormValueGettersNewRecords.remarks()
                                })(
                                    <Input
                                        size="small"
                                        max={1000}
                                        style={{ width: 140 }}
                                        allowClear
                                        onChange={() => handleNewRecordFieldChange(record._id)}
                                        disabled={!userCanEdit}
                                    />
                                )}
                            </Form.Item>
                        </EditableCell>
                    }
                ]}
                pagination={false}
            />
        </div>
    </Spin>
}

const FWHistoryEditorWithForm = Form.create<FWHistoryEditorProps & FormComponentProps>({
    mapPropsToFields(props){
        if (!props.formFields) return;
        return createFormFields(props.formFields)
    },
    onFieldsChange(props, _, fields){
        props.onFormFieldsChange?.(fields);
    }
})(FWHistoryEditor_Internal)

let FWHistoryEditor: React.FC<FWHistoryEditorProps> = (props) => {

    function renderWithConsumer(){
        return <FWHistoryEditorContext.Consumer>
            {({ formRef, formData, setFormData }) => (
                <FWHistoryEditorWithForm
                    {...props}
                    ref={formRef}
                    onFormFieldsChange={(fields) => {
                        setFormData(fields);
                    }}
                    formFields={formData}
                    onFieldReset={(fieldName) => {
                        let newFormData = { ...formData }
                        delete newFormData[fieldName]
                        setFormData(newFormData);
                    }}
                />
            )}
        </FWHistoryEditorContext.Consumer>
    }

    if (props.useExternalContextProvider){
        return renderWithConsumer()
    }
    else
    {
        return <FWHistoryEditorContextProvider
            aircraftID={props.aircraftID}
            flightDate={props.flightDate}
            onDiscard={props.onDiscard}
        >
            {renderWithConsumer()}
        </FWHistoryEditorContextProvider>
    }
}

FWHistoryEditor.displayName = 'FWHistoryEditor'

export default FWHistoryEditor