import gql from "graphql-tag";

const flightFields = `
_id
state
desig
scheduledFlightDate
callSign
liveEditedState
paxIDList @include(if: $fetchPaxList) {
    _id
    _rev
    lastName
    firstName
    scheduledOrder
    scheduledGroup
    chargeCode
    employerID {
        _id
        name
    }
    transitType
    departureID {
        _id
        name
    }
    destinationID {
        _id
        name
    }
    paxWeight
    bagWeight
    bagCount
    classType
}
cgoIDList @include(if: $fetchPaxList) {
    _id
    name
    scheduledOrder
    scheduledGroup
    transitType
    chargeCode
    departureID {
        _id
        name
    }
    destinationID {
        _id
        name
    }
    weight
    attentionTo
    initials
    notes
    classType
}
legs
legsArray @include(if: $fetchLegs) {
    bagCount
    bagWeight
    cgoCount
    cgoWeight
    chosen
    departure
    departureID
    destination
    destinationID
    order
    paxCount
    paxWeight
    usefulLoad
    seats
    paxIDs {
        _id
        lastName
        firstName
        classType
        employerID {
            _id
            name
        }
        chargeCode
        paxWeight
        bagWeight
        bagCount
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        personID {
            _id
            dob
            nation
            credentials {
                _id
                key
                type
                expiration
            }
        }
        transitType
    },
    cgoIDs {
        _id
        name
        weight
        approvedBy
        checkedInTime
        classType
        chargeCode
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        deliveredBy
        attentionTo
        transitType
    }
}
tpID {
    _id
}
pilot {
    _id
    name {
        lastName
        firstName
    }
}
copilot {
    _id
    name {
        lastName
        firstName
    }
}
contract {
    _id
    name
}
aircraftID {
    _id
    tailNum
    model
    maxSeats
    maxLoad
}
departureID {
    _id
    name
}
`

export default gql`
query GetFlight(
        $flightId: ID!
        $fetchLegs: Boolean=true
        $fetchPaxList: Boolean=true
        $cachedOnly: Boolean=false
    ){
    getFlight(_id: $flightId) @client @include(if: $cachedOnly){
        ${flightFields}
    }
    getFlight(_id: $flightId) @skip(if: $cachedOnly){
        ${flightFields}
    }
}
`