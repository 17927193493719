import React from 'react';
import { Query, QueryResult, QueryComponentOptions } from 'react-apollo';
import gql from 'graphql-tag';


export interface ResolveEntityIDResult extends QueryResult {
    data: any | any[]
}

export interface ResolveEntityIDProps {
    db?: string,
    typename: string,
    gqlFields: string,
    ids: string[] | string,
    children(result: ResolveEntityIDResult): JSX.Element,
    queryProps?: QueryComponentOptions
}

const ResolveEntityID: React.FC<ResolveEntityIDProps> = (props) => {
    let isArray = Array.isArray(props.ids);
    return <Query
        {...props.queryProps}
        query={gql`
            query Resolve${props.typename}ID($ids: [ID!]!, $db: String, $typename: String!){
                resolve_entity_ids(ids: $ids, db: $db, typename: $typename){
                    _id
                    ... on ${props.typename} {
                        ${props.gqlFields}
                    }
                }
            }
        `}
        variables={{
            ids: isArray ? props.ids : [props.ids],
            db: props.db,
            typename: props.typename
        }}
        onError={(error) => console.error('Failed to resolve entitities: ', error)}
    >
    {(result: QueryResult) => {
        let data: any | any[];
        if (result.data && result.data.resolve_entity_ids){
            if(isArray){
                data = result.data.resolve_entity_ids;
            }
            else if (Array.isArray(result.data.resolve_entity_ids))
            {
                data = result.data.resolve_entity_ids[0];
            }
        }
        return props.children({
            ...result,
            data
        })
    }}
    </Query>
}

export default ResolveEntityID