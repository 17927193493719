import React, { Component } from 'react';
import { List } from 'antd';
import CargoItem from './CargoItem';

class CargoList extends Component {
    render() {
        return <List itemLayout="vertical" {...this.props} renderItem={record => (
            <CargoItem
                key={record._id}
                data={record}
            />
        )} />;
    }
}
 
export default CargoList;