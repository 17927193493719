import { Cell, Column, IColumnProps } from "@blueprintjs/table";
import React from 'react';

export type CellElementRenderer = (row: any, index?: number) => JSX.Element

export interface RenderCellElementMap {
    [key: string]: CellElementRenderer
}

export interface CellPropsMap {
    [key: string]: any
}

export interface BlueprintColumnSetOptions {
    renderCellElement?: CellElementRenderer,
    renderCellElementMap?: RenderCellElementMap
}

export default class BlueprintColumnSet {
    constructor(data: any[], options?: BlueprintColumnSetOptions, paxEdit?: boolean){
        this.data = data;
        if (options){
            if (options.renderCellElement){
                this.renderCellElement = options.renderCellElement;
            }
            if (options.renderCellElementMap){
                this.renderCellElementMap = options.renderCellElementMap
            }
        }
        this.paxEdit = paxEdit
    }

    protected paxEdit = null;
    protected renderCellElement: CellElementRenderer = () => <Cell />
    protected renderCellElementMap = null
    protected renderColumn(props: IColumnProps): JSX.Element{
        return <Column {...props} key={props.id} />
    }
    protected getCellElement(row: any, index: number, columnID?: string): JSX.Element {
        if (!row) return <Cell />;
        if (columnID && this.renderCellElementMap !== null && this.renderCellElementMap[columnID]){
            return this.renderCellElementMap[columnID](row, index);
        }
        return this.renderCellElement(row, index);
    }
    protected getRow(idx: number){
        return this.data[idx];
    }

    protected basicCellRenderer = (colID: string, renderRow: (row: any) => any, valuePropName='children') => {
        return (idx: number) => {
            let row = this.getRow(idx);
            let cellProps = {
                [valuePropName]: row ? renderRow(row) : null
            }
            let cell = React.cloneElement(this.getCellElement(row, idx, colID), cellProps);
            return cell
        }
    }

    data: any[] = [];
    columns: IColumnProps[] = []
    get(id: string | number){
        return this.columns.find((col) => col.id === id);
    }
    all(): JSX.Element[]{
        return  this.columns.map(this.renderColumn);
    }
    some(colIDs: (string | number)[]): JSX.Element[]{
        return colIDs.map((id) => this.columns.find((col) => col.id === id)).filter((col) => colIDs.includes(col && col.id)).map(this.renderColumn);
    }
    except(colIDs: (string | number)[]): JSX.Element[]{
        return this.columns.filter((col) => !colIDs.includes(col && col.id)).map(this.renderColumn);
    }
}