import { Alert, Form, Input, Radio, Select, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import FeedbackMood from 'components/feedback-mood';
import UserContentUploader from 'components/UserContentUploader';
import { AuthContext } from 'context/auth';
import React, { PropsWithChildren, useContext, useEffect } from 'react';

export type FeedbackType = 'bug' | 'featurerequest' | 'generalfeedback' | 'outage';

export interface FeedbackFormProps extends Omit<FormComponentProps, 'children'> {
    defaultType?: FeedbackType,
    lockToType?: FeedbackType,
    displayOutageWarning?: boolean,
    defaultDescription?: string,
    onValuesChange?: (values) => void,
    submitButtonProps?: ButtonProps
}

export interface BaseFeedbackFormProps extends Omit<FeedbackFormProps, 'submitButtonProps'>, PropsWithChildren<any> {}

function renderBugForm<V=any>(form: WrappedFormUtils<V>) {
    const { getFieldDecorator } = form;
    return <>
        <Form.Item label="What you expected to happen">
            {getFieldDecorator('expected', {
                rules: [{ required: true, message: 'Please explain what you expected to happen' }]
            })(
                <Input.TextArea autoSize={{ minRows: 5 }} />
            )}
        </Form.Item>
        <Form.Item label="What actually happened">
            {getFieldDecorator('actual', {
                rules: [{ required: true, message: 'Please explain what actually happened' }]
            })(
                <Input.TextArea autoSize={{ minRows: 5 }} />
            )}
        </Form.Item>
    </>
}

function renderOutageForm<V=any>(form: WrappedFormUtils<V>, displayWarning=true) {
    const { getFieldDecorator } = form;
    return <>
        {displayWarning ? (
            <Alert
                type="warning"
                message="Before you submit an outage report please make sure your internet connection is functioning properly."
                style={{ marginBottom: '12px' }}
                />
        ) : null}
        <Form.Item label="Describe what happened">
            {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Please describe what happened.' }]
            })(
                <Input.TextArea autoSize={{ minRows: 5 }} />
            )}
        </Form.Item>
    </>
}

function renderGeneralFeedbackForm<V=any>(form: WrappedFormUtils<V>, displayWarning=true) {
    const { getFieldDecorator } = form;
    return <>
        <Form.Item label="What do you like, or not like, about Manifest Central?">
            {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Please answer this question.' }]
            })(
                <Input.TextArea autoSize={{ minRows: 5 }} />
            )}
        </Form.Item>
    </>
}

function renderFeatureRequestForm<V=any>(form: WrappedFormUtils<V>, displayWarning=true) {
    const { getFieldDecorator } = form;
    return <>
        <Form.Item label="What would you like to see added or changed to Manifest Central?">
            {getFieldDecorator('description', {
                rules: [{ required: true, message: 'Please answer this question.' }]
            })(
                <Input.TextArea autoSize={{ minRows: 5 }} />
            )}
        </Form.Item>
    </>
}

function renderResponseType<V=any>(form: WrappedFormUtils<V>, userAttributes: any) {

    const { getFieldDecorator } = form;

    switch (form.getFieldValue('responseType')) {
        case 'phone':
            return <Form.Item label="Preferred phone number">
                {getFieldDecorator('responsePhone', {
                    initialValue: userAttributes?.phone_number,
                    rules: [{ required: true, message: 'Please enter a phone number' }]
                })(<Input type="tel" />)}
            </Form.Item>
        case 'email':
            return <Form.Item label="Preferred email">
                {getFieldDecorator('responseEmail', {
                    initialValue: userAttributes?.email,
                    rules: [{ required: true, message: 'Please enter a phone number' }]
                })(<Input type="email" />)}
            </Form.Item>
        default:
            return null;
    }
}

const _Internal_BaseFeedbackForm: React.FC<BaseFeedbackFormProps> = (props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;
    const { user } = useContext(AuthContext);

    let typeValue = getFieldValue('type');

    function renderForm(){
        switch (props.lockToType ? props.lockToType : getFieldValue('type')) {
            case 'bug':
                return renderBugForm(props.form)
            case 'outage':
                return renderOutageForm(props.form, props.displayOutageWarning)
            case 'generalfeedback':
                return renderGeneralFeedbackForm(props.form)
            case 'featurerequest':
                return renderFeatureRequestForm(props.form)
            default:
                return null;
        }
    }

    useEffect(() => {
        if (props.lockToType){
            setFieldsValue({ type: props.lockToType });
        }
    // eslint-disable-next-line
    }, [ props.lockToType ])

    useEffect(() => {
        if (props.defaultDescription && getFieldValue('description') === undefined){
            setFieldsValue({ description: props.defaultDescription });
        }
    // eslint-disable-next-line
    }, [ props.defaultDescription ])

    

    return <Form>
        {!props.lockToType ? (
            <Form.Item label="Please choose a feedback type">
                {getFieldDecorator('type', {
                    initialValue: props.defaultType,
                    rules: [{ required: true, message: 'Please select a feedback type' }],
                })(
                    <Select optionLabelProp="label" showAction={["focus", "click"]}>
                        <Select.Option value="bug" label="Report a Bug">
                            <div>Report a Bug</div>
                            <Typography.Text type="secondary">Something isn't working as expected</Typography.Text>
                        </Select.Option>
                        <Select.Option value="featurerequest" label="Feature Request">
                            <div>Feature Request</div>
                            <Typography.Text type="secondary">Request a feature that you want in Manifest Central</Typography.Text>
                        </Select.Option>
                        <Select.Option value="generalfeedback" label="General Feedback">
                            <div>General Feedback</div>
                            <Typography.Text type="secondary">Tell us what you like or don't like about Manifest Central</Typography.Text>
                        </Select.Option>
                        <Select.Option value="outage" label="Outage">
                            <div>Report an Outage</div>
                            <Typography.Text type="secondary">Report when the website starts experiencing connection issues</Typography.Text>
                        </Select.Option>
                    </Select>
                )}
            </Form.Item>
        ) : null}
        {typeValue === 'generalfeedback' ? (
            <Form.Item>
                {getFieldDecorator('mood')(<FeedbackMood />)}
            </Form.Item>
        ) : null}
        {renderForm()}
        {(getFieldValue('type') !== undefined || props.lockToType) ? <>
            <Form.Item label="How should we respond to you?">
                {getFieldDecorator('responseType', {
                    rules: [{ required: true, message: 'Please choose an option' }]
                })(
                    <Radio.Group>
                        <Radio value={'phone'}>By Phone</Radio>
                        <Radio value={'email'}>By Email</Radio>
                        <Radio value={'none'}>No Response Required</Radio>
                    </Radio.Group>
                )}
            </Form.Item>
            {renderResponseType(props.form, user?.attributes)}
            <Form.Item label="Job position (optional)">
                {getFieldDecorator('jobPosition')(<Input />)}
            </Form.Item>
            <Form.Item label={"Attach files that will better help us track down the issue (optional)"}>
                {getFieldDecorator('attachments', {
                    valuePropName: 'fileList',
                    getValueFromEvent: (e) => e.fileList
                })(<UserContentUploader />)}
            </Form.Item>
        </> : null}
    </Form>
}

/** Only the form. Does not include ability to send the feedback through a mutation. */
export const BaseFeedbackForm = Form.create<BaseFeedbackFormProps & FormComponentProps>({
    onValuesChange: (props, values) => props.onValuesChange?.(values)
})(_Internal_BaseFeedbackForm)

/**  */
// const FeedbackForm: React.FC<FeedbackFormProps> = React.forwardRef((props, innerRef: any) => {
//     const [ submit, { loading } ] = useFeedbackSubmitter();

//     const formRef = useRef(null);
//     console.log('formRef', formRef.current);

//     return <BaseFeedbackForm ref={innerRef || formRef}>
//         {formRef.current ? (
//             <Form.Item {...tailFormItemLayout}>
//                 <Button
//                     type="primary"
//                     children={'Send Feedback to Dataflyt'}
//                     {...props.submitButtonProps}
//                     onClick={()}
//                 />
//             </Form.Item>
//         ) : null}
//     </BaseFeedbackForm>
// })

// export default FeedbackForm;