import React, {Component} from 'react';
import {graphql} from 'react-apollo';
import compose from 'lodash.flowright';
import moment from 'moment';
import gql from 'graphql-tag';
import WithOrgData from '../../../WithOrgData';
import LoadingContent from '../../../LoadingContent';
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Icon, Input,
    message, Modal,
    Popconfirm,
    Row,
    Spin,
    Table,
    Typography
} from 'antd';
import commonColumns from '../../../../common/table/columns';
import PersonSearch from 'components/search/PersonSearch';
import './index.less';
import WhitelistBypassForm, { WhitelistBypassFormUtils } from "components/form/whitelist-bypass-form";
import SelectionCount from "../../../search/SelectionCount";
import pluralize from 'pluralize';
import ResolveEntityID from "../../../ResolveEntityID";
import {QueryGetWhiteList, WhiteListFragment} from "../../../../Queries/WhiteList";
import ConditionCount from "./ConditionCount";
import {NonComplyBypassCategoryHuman} from "../../../../common/types/non-comply-bypass";
import {momentOrNull} from "../../../../common/util";

const SET_PERSON_ON_WHITE_LIST = gql`
mutation SetPersonOnWhiteList(
    $modifyList: [SetPersonOnWhiteListModifyAction!],
    $deleteList: [SetPersonOnWhiteListDeleteAction!]
){
    SetPersonOnWhiteList(
        modifyList: $modifyList,
        deleteList: $deleteList
    ){
        ...WhiteListFragment
    }
}
${WhiteListFragment}
`

class Person extends Component {
    state = {
        // List is built from the "Add Personnel" panel
        personsToAdd: [],

        /**
         * The current step of the "Add Personnel" panel.
         * 0 = Renders personnel search.
         * 1 = Renders whitelist conditions form.
         * @type {0|1}
         */
        addPersonStep: 0,

        // True if the request for adding personnel is in flight
        addingPersons: false,

        // True if user checked the "Whitelist on condition option" in the "Add Personnel" panel.
        enableWhiteListBypass: false,

        // Form state of the WhiteListBypassFormCombined component.
        whiteListBypassFormFields: {},

        // List of personIDs selected on the right "Personnel on White List" panel
        selectedPersons: [],

        // Current state of the "Edit WhiteList Conditions" pop-up modal.
        editBypassesModal: {
            open: false,
            personID: null,
            executing: false,
            formFields: {}
        },

        // Value of the search bar in the "Personnel on White List" panel.
        searchBarValue: null,

        // Filter only conditions checkbox state
        filterOnlyConditions: false,

        // Show only conditions that are expired
        filterOnlyExpired: false,

        // Loading state when removing persons.
        removingPersons: false
    }

    constructor(props) {
        super(props);

        // Ref to White List bypass form seen in the "Add Personnel" panel.
        this.whitelistBypassFormLeftPanel = React.createRef();

        // Ref to White List bypass form seen in the "Edit WhiteList Conditions" pop-up modal.
        this.whitelistBypassFormRightPanel = React.createRef();
    }

    /**
     * Gets the conditions/bypasses for a personID
     * @param personID
     * @returns {NonComplyBypass[]}
     */
    getPersonNonComplyBypasses = (personID) => {
        const whiteList = this.props.data.getWhiteList;
        if (!whiteList) return [];

        const nonComplyBypasses = [];

        // Scan all fields that begin with noncomplyPassList_ and find the personID.
        Object.entries(whiteList)
            .forEach(([ key, personToBypassList ]) => {
                if (!key.startsWith("noncomplyPassList_") || !personToBypassList) return;

                const bypass = personToBypassList.find((item) => item.personID === personID)?.nonComplyBypass;
                if (bypass){
                    nonComplyBypasses.push(bypass);
                }
            })

        return nonComplyBypasses;
    }

    /**
     * Handles adding new personnel to the user's organization's whitelist.
     * Reads the data from the "WhiteListBypassFormCombined" form and uploads it.
     */
    handleAddPersons = () => {
        const { personsToAdd } = this.state;

        const submit = (bypassFields = {}) => {
            const modifyList = personsToAdd.map((personId) => (
                {
                    personID: personId,
                    ...bypassFields
                }
            ))

            // Upload to the SetPersonOnWhiteList lambda function
            this.setState({ addingPersons: true });
            this.props.setPersonOnWhiteList({
                variables: {
                    modifyList: modifyList
                }
            })
                .then(() => {
                    message.success(`Successfully added/modified ${personsToAdd.length} personnel to whitelist`);
                    this.setState({ personsToAdd: [], addPersonStep: 0, whiteListBypassFormFields: {} });
                    this.props.data.refetch();
                })
                .catch((error) => {
                    console.error(error)
                    message.error("Failed to add personnel to whitelist");
                })
                .then(() => this.setState({ addingPersons: false }))
        }

        if (this.state.enableWhiteListBypass)
        {
            this.whitelistBypassFormLeftPanel.current.validateFieldsAndScroll((err, values) => {
                if (err) return;

                const bypasses = WhitelistBypassFormUtils.parseFormValues(values);

                /**
                 * Fields to insert into the modifyList input.
                 * @type {{ [fieldName: string]: NonComplyBypassInput }}
                 */
                const bypassFields = {};

                bypasses.forEach((bypass) => {
                    // Create the key of the element in bypassFields
                    const fieldName = "nonComplyBypass_" + String(bypass.nonComplyCategory);
                    delete bypass.nonComplyCategory;
                    bypassFields[fieldName] = bypass
                })

                submit(bypassFields);
            })
        }
        else
        {
            submit();
        }

    }

    /**
     * Handles editing the condition/bypasses of a person in this whitelist.
     * @param {string} personID
     */
    handleEditConditions = (personID) => {
        this.whitelistBypassFormRightPanel.current.validateFieldsAndScroll((err, values) => {
            if (err) return;

            const bypasses = WhitelistBypassFormUtils.parseFormValues(values);

            /**
             * Fields to insert into the modifyList input.
             * @type {{ [fieldName: string]: NonComplyBypassInput }}
             */
            const bypassFields = {};

            // Builds bypassFields
            bypasses.forEach((bypass) => {
                const keyName = "nonComplyBypass_" + bypass.nonComplyCategory;

                // Insert the expected NonComplyBypassInput into bypassFields
                bypassFields[keyName] = {
                    reason: bypass.reason,
                    expiresOn: bypass.expiresOn
                }
            })

            const modifyList = [
                {
                    personID: personID,
                    ...bypassFields
                }
            ]

            // Loading state of modal
            this.setState({ editBypassesModal: {
                ...this.state.editBypassesModal,
                    executing: true
                } });

            // Upload to the SetPersonOnWhiteList lambda function
            this.props.setPersonOnWhiteList({
                variables: {
                    modifyList: modifyList
                }
            })
                .then(() => {
                    message.success(`Successfully modified personnel whitelist`);
                    this.setState({ personsToAdd: [], addPersonStep: 0, editBypassesModal: { open: false, personID: null, executing: false, formFields: {} } });
                })
                .catch((error) => {
                    console.error(error)
                    message.error("Failed to add personnel to whitelist");
                })
                .then(() => this.setState({ modifyingPersonID: null, editBypassesModal: { ...this.state.editBypassesModal, executing: false } }))
        })
    }

    /**
     * Handles deleting personnel from the user's organization's whitelist.
     */
    removePersons = () => {
        const { data: { getWhiteList } } = this.props;
        const { selectedPersons } = this.state;
        const newPersonList = getWhiteList.personIds.filter(per => !selectedPersons.includes(per._id));

        this.setState({ removingPersons: true });
        this.props.setPersonOnWhiteList({
            variables: {
                deleteList: selectedPersons.map((personID) => ({ personID: personID }))
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setWhiteList: {
                    __typename: 'WhiteList',
                    ...getWhiteList,
                    _id: getWhiteList._id,
                    personIds: newPersonList
                }
            }
        })
        .then(() => {
            message.success(`Successfully removed ${selectedPersons.length} personnel from white list`);
            this.setState({ selectedPersons: [] });
        })
        .catch(error => {
            console.error(error);
            message.error('Failed to remove personnel from white list');
        })
        .then(() => {
            this.setState({ removingPersons: false });
        })
    }

    /**
     * Renders the left panel responsible for searching for and adding personnel.
     */
    renderSearchCol = () => {

        const { getWhiteList } = this.props.data;

        let header = (
            <Row type="flex" style={{ padding: '0 0.5rem', marginBottom: '0.5rem' }}>
                <Col><h3 style={{ margin: 0, transform: 'translate(0px, 2px)' }}>Add Personnel</h3></Col>
                <div style={{ flex: 1 }} />
                <Col>
                    {this.state.addPersonStep === 0 ? (
                        <Button
                            type="primary"
                            disabled={!this.state.personsToAdd.length}
                            onClick={() => this.setState({ addPersonStep: 1 })}
                        >Next <Icon type="arrow-right" /></Button>
                    ) : (
                        <>
                            <Button
                                icon="arrow-left"
                                onClick={() => this.setState({ addPersonStep: 0 })}
                                style={{ marginRight: '0.5rem' }}
                            >Back</Button>
                            <Button
                                type="primary"
                                disabled={!this.state.personsToAdd.length}
                                loading={this.state.addingPersons}
                                onClick={this.handleAddPersons}
                            >Add to White List <Icon type="plus" /></Button>
                        </>
                    )}
                </Col>
            </Row>
        )

        let content = null;

        if (this.state.addPersonStep === 0){
            // Display Person Search list
            content = <PersonSearch
                onSelection={(rows) => this.setState({ personsToAdd: rows })}
                selectedItems={this.state.personsToAdd}
                filterResults={(rows) => rows.filter((row) => !getWhiteList.personIds.map(p => p._id).includes(row._id))}
                style={{ flex: 1, minHeight: 0 }}
                showCustomerColumn={false}
            />
        }
        else if (this.state.addPersonStep === 1){
            // Display White List condition form
            content = (
                <Row className="whitelist-left-panel-conditional" style={{ padding: "0.5rem" }}>
                    <Col>
                        {/* Optional White List Conditions form, User clicks the checkbox to show the form. */}
                        <Card size="small" title={
                            <div
                                style={{ display: 'flex', cursor: 'pointer' }}
                                onClick={() => this.setState({ enableWhiteListBypass: !this.state.enableWhiteListBypass })}
                            >
                                <div style={{ marginRight: '0.75rem' }}>
                                    <Checkbox
                                        onChange={(e) => this.setState({ enableWhiteListBypass: e.target.checked })}
                                        checked={this.state.enableWhiteListBypass}
                                    />
                                </div>
                                <div>
                                    <Typography.Text strong style={{ display: 'block' }}>
                                        Whitelist with condition (optional)
                                    </Typography.Text>
                                    <Typography.Text type="secondary" style={{ display: 'block' }}>
                                        Personnel will only be whitelisted under these conditions
                                    </Typography.Text>
                                </div>
                            </div>
                        }
                              headStyle={this.state.enableWhiteListBypass ? undefined : {
                                  borderBottom: 'none'
                              }}
                              bodyStyle={this.state.enableWhiteListBypass ? undefined : {
                                  display: 'none'
                              }}
                        >
                            {this.state.enableWhiteListBypass ? (
                                <WhitelistBypassForm
                                    ref={this.whitelistBypassFormLeftPanel}
                                    formLayout="horizontal"
                                    onFormFieldsChange={(fields) => {
                                        this.setState({ whiteListBypassFormFields: fields });
                                    }}
                                    formFields={this.state.whiteListBypassFormFields}
                                />
                            ) : null}
                        </Card>
                    </Col>
                    <Col style={{ marginTop: '0.5rem' }}>
                        {/* Describes how these conditions will apply to these personnel */}
                        <Alert message={
                            <>
                                <SelectionCount
                                    items={this.state.personsToAdd}
                                    onClear={() =>
                                        this.setState({ personsToAdd: [], addPersonStep: 0 })
                                    }
                                    itemName="person"
                                    style={{ marginBottom: "0.5rem" }}
                                    renderMessage={(count) => {
                                        let msg = `${count} ${pluralize("person", this.state.personsToAdd.length)} will be whitelisted to pass all ISN checks:`;

                                        if (this.state.enableWhiteListBypass){
                                            let values = this.whitelistBypassFormLeftPanel.current?.getFieldsValue() || {};

                                            const bypasses = WhitelistBypassFormUtils.parseFormValues(values);

                                            const bypassNames = bypasses
                                                .map((bypass) => NonComplyBypassCategoryHuman[bypass.nonComplyCategory])
                                                .join(', ');

                                            const expirations = bypasses
                                                .map((bypass) => bypass.expiresOn)
                                                .join(', ');


                                            if (bypasses.length && bypassNames && expirations) {
                                                msg = `This whitelist will apply to ${count} ${pluralize("person", this.state.personsToAdd.length)} for ${bypassNames} until ${expirations} respectively.`
                                            }
                                        }

                                        return (
                                            <span>{msg}</span>
                                        )
                                    }}
                                />
                                <ol>
                                    <ResolveEntityID
                                        typename="Person"
                                        gqlFields={`
                                _id
                                lastName
                                firstName
                            `}
                                        ids={this.state.personsToAdd}
                                    >
                                        {({ data, loading }) => {
                                            if (loading)
                                                return <Spin indicator={<Icon type="loading" />} />;
                                            return data?.map((per) => (
                                                <li key={per._id}>
                                                    <span>{per.lastName}, {per.firstName}</span>
                                                    <Divider type="vertical" />
                                                    <Button
                                                        className="mc-link-btn"
                                                        onClick={() => {
                                                            const filteredPersonsToAdd = this.state.personsToAdd
                                                                .filter((personId) => personId !== per._id);
                                                            this.setState({ personsToAdd: filteredPersonsToAdd })
                                                            if (!filteredPersonsToAdd.length) {
                                                                this.setState({addPersonStep: 0, whiteListBypassFormFields: {}});
                                                            }
                                                        }}
                                                    >Remove</Button>
                                                </li>
                                            ));
                                        }}
                                    </ResolveEntityID>
                                </ol>
                            </>
                        } />
                    </Col>
                </Row>
            );
        }

        return <>
            {header}
            {content}
        </>
    }

    getColumns = () => {
        const columns = commonColumns.person.all();

        columns.push(...[
            {
                key: 'conditions',
                title: 'Conditions',
                render: (_, record) => {
                    const bypasses = this.getPersonNonComplyBypasses(record._id);
                    return <ConditionCount
                        personID={record._id}
                        nonComplyBypasses={bypasses}
                        tooltipProps={{
                            placement: 'leftTop'
                        }}
                        onEdit={() => this.setState({ editBypassesModal: { open: true, personID: record._id } })}
                    />
                }
            }
        ])

        return columns
    }

    getFilteredData = () => {
        const { getWhiteList } = this.props.data;

        let data = getWhiteList?.personIds || [];
        if (this.state.searchBarValue){
            const hasMatch = (text) => {
                if (!text) return false;
                return !!String(text).toLowerCase().includes(String(this.state.searchBarValue).toLowerCase())
            }
            data = data.filter((person) => {
                return hasMatch(person.lastName) || hasMatch(person.firstName) || hasMatch(person?.employerID?.name)
            })
        }

        if (this.state.filterOnlyConditions){
            data = data.filter(this.doesPersonHaveCondition);
        }

        if (this.state.filterOnlyExpired){
            data = data.filter(this.isPersonExpired);
        }

        return data;
    }

    doesPersonHaveCondition = (person) => {
        const nonComplyBypasses = this.getPersonNonComplyBypasses(person._id);
        return !!nonComplyBypasses?.length;
    }

    isPersonExpired = (person) => {
        const now = moment();
        const nonComplyBypasses = this.getPersonNonComplyBypasses(person._id);
        for (let nonComplyBypass of nonComplyBypasses) {
            const exp = momentOrNull(nonComplyBypass.expiresOn)?.endOf('day');
            const isExpired = exp?.isBefore(now);
            if (isExpired) return true;
        }
        return false;
    }

    render(){
        const { loading, error } = this.props.data;
        if (loading) return <LoadingContent style={{margin: '10rem 0'}} />
        if (error){
            const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
            if (!notFoundError){
                return <Alert type="error" message="Failed to load white list" description={error.message} showIcon />
            }
        }

        return (
            <Row>
                <Col span={8}>
                    <div className="whitelist-person-search-col">
                        {this.renderSearchCol()}
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{ padding: 24 }}>
                        <Row type="flex" style={{marginBottom: '1rem', alignItems: 'center'}} gutter={12}>
                            <Col style={{flex: 1}}>
                                <h3>Personnel on White List</h3>
                            </Col>
                            <Col>
                                <Checkbox
                                    checked={this.state.filterOnlyExpired}
                                    onChange={(e) => this.setState({ filterOnlyExpired: e.target.checked })}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <div style={{ lineHeight: '14px' }}>
                                        <span>Show only expired</span><br/>
                                        <Typography.Text
                                            type="secondary"
                                        >({(this.props.data?.getWhiteList?.personIds.filter(this.isPersonExpired) || []).length} found)</Typography.Text>
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col>
                                <Checkbox
                                    checked={this.state.filterOnlyConditions}
                                    onChange={(e) => this.setState({ filterOnlyConditions: e.target.checked })}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <div style={{ lineHeight: '14px' }}>
                                        <span>Show only with conditions</span><br/>
                                        <Typography.Text
                                            type="secondary"
                                        >({(this.props.data?.getWhiteList?.personIds.filter(this.doesPersonHaveCondition) || []).length} found)</Typography.Text>
                                    </div>
                                </Checkbox>
                            </Col>
                            <Col>
                                <Input
                                    placeholder="Search here"
                                    allowClear
                                    onChange={(e) => this.setState({ searchBarValue: e.target.value })}
                                    value={this.state.searchBarValue}
                                />
                            </Col>
                            <Col>
                                <Popconfirm
                                    title={`Are you sure you want to remove ${this.state.selectedPersons.length} personnel?`}
                                    okText="Remove"
                                    onConfirm={this.removePersons}
                                    placement="bottomLeft"
                                >
                                    <Button
                                        type="danger"
                                        disabled={this.state.selectedPersons.length ? false : true}
                                        loading={this.state.removingPersons}
                                    >
                                        Remove {this.state.selectedPersons.length} Personnel
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Table
                            dataSource={this.getFilteredData()}
                            rowKey={record => record._id}
                            size="small"
                            className="mc-table"
                            pagination={false}
                            columns={this.getColumns()}
                            rowSelection={{
                                selectedRowKeys: this.state.selectedPersons,
                                onChange: (selectedPersons) => this.setState({ selectedPersons })
                            }}
                        />
                        <Modal
                            visible={this.state.editBypassesModal.open}
                            title={
                                <ResolveEntityID
                                    typename="Person"
                                    ids={[this.state.editBypassesModal.personID]}
                                    gqlFields={`
                                        _id
                                        firstName
                                        lastName
                                    `}
                                >
                                    {({ data, loading }) => {
                                        if (loading || !data[0]) return <Spin indicator={<Icon type={"loading"} />} />
                                        return `Edit White-List Conditions for ${data[0].firstName} ${data[0].lastName}`
                                    }}
                                </ResolveEntityID>
                            }
                            onCancel={() => this.setState({ editBypassesModal: { open: false, personID: this.state.editBypassesModal.personID } })}
                            width={800}
                            destroyOnClose
                            okText="Save"
                            onOk={() => this.handleEditConditions(this.state.editBypassesModal.personID)}
                            closable={!this.state.editBypassesModal.executing}
                            cancelButtonProps={{
                                disabled: this.state.editBypassesModal.executing
                            }}
                            okButtonProps={{
                                loading: this.state.editBypassesModal.executing
                            }}
                        >
                            <Alert
                                message="White List conditions will allow personnel to be whitelisted until a certain date for a specific ISN non-compliance reason."
                                style={{ marginBottom: 12 }}
                            />
                            <WhitelistBypassForm
                                initialData={this.getPersonNonComplyBypasses(this.state.editBypassesModal.personID)}
                                ref={this.whitelistBypassFormRightPanel}
                                formFields={this.state.editBypassesModal.formFields}
                                onFormFieldsChange={(formFields) => this.setState({ editBypassesModal: { ...this.state.editBypassesModal, formFields } })}
                            />
                        </Modal>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default compose(
    WithOrgData,
    graphql(
        QueryGetWhiteList,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'WHL-')
                },
                pollInterval: ['cache-only', 'cache-first'].includes(props.fetchPolicy) ? undefined : 15000,
                fetchPolicy: props.fetchPolicy || 'network-only'
            }),
            props: props => {
                const defaultWl = {
                    _id: props.ownProps.orgData.customer._id.replace('CUS-', 'WHL-'),
                    personIds: [],
                    __typename: 'WhiteList'
                }
                if (props.ownProps.useDefault){
                    return {
                        ...props,
                        data: {
                            ...props.data,
                            useDefault: undefined,
                            error: undefined,
                            getWhiteList: defaultWl
                        }
                    }
                }
                return props
            }
        }
    ),
    graphql(
        SET_PERSON_ON_WHITE_LIST,
        {
            name: 'setPersonOnWhiteList'
        }
    )
)(Person);