import gql from "graphql-tag";

export default gql`
query PersonCredentials(
    $personId: ID!
){
    personCredentials(
        personId: $personId
    ){
        _id
        key
        type
        expiration
    }
}
`