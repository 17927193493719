import { Col, DatePicker, Row } from 'antd';
import FlightTimes from 'components/flights/times/FlightTimes';
import { LocationSelect } from 'components/form/select/LocationSelect';
import moment from 'moment';
import React from 'react';

const { RangePicker } = DatePicker;

class FlightTimesHeader extends React.Component {
    state = {
        dateRange: [moment().subtract(1, 'day'), moment() ],
        departureID: null
    }
    render() {
        return (
            <div>
                <Row type="flex" gutter={{xs:8}}>
                    <Col>
                        <RangePicker
                            value={this.state.dateRange}
                            onChange={(dates) => {
                                if(dates.length === 0){
                                    dates = [moment().subtract(1, 'day'), moment()]
                                }
                                this.setState({dateRange: dates})
                            }}
                            format="MMM DD, YY"
                            disabledDate={(current) => moment().isBefore(current, 'day')}
                            ranges={{
                                'This Month': [
                                    moment().startOf('month'),
                                    moment().endOf('month')
                                ],
                                'This Week': [
                                    moment().startOf('week'),
                                    moment().endOf('week')
                                ],
                                'Last Month': [
                                    moment().subtract(1, 'month').startOf('month'),
                                    moment().subtract(1, 'month').endOf('month')
                                ],
                                'Last Week': [
                                    moment().subtract(1, 'week').startOf('week'),
                                    moment().subtract(1, 'week').endOf('week')
                                ],
                            }}
                        />
                    </Col>
                    <Col offset={1}>
                         Departure Location: <LocationSelect onChange={value => this.setState({departureID: value})} value={this.state.departureID} labelInValue style={{minWidth: '10rem'}}/> 
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FlightTimes dateRange={this.state.dateRange} departureID={this.state.departureID} />
                    </Col>
                </Row>
        </div>
        )
    }
}

export default FlightTimesHeader;