import React, { cloneElement, useEffect, useRef, useState } from "react";
import { Button, Col, Icon, Row, Tooltip } from "antd";
import makeCancelable from "makecancelable";
import "./BlueprintTableInfiniteScroller.css";
import { ITableProps } from "@blueprintjs/table";
import usePromise from "../../hooks/usePromise";

interface LoaderProps {
  loadMoreMessage: string;
}

interface ErrorMsgProps {
    title?: string,
    message: string
}

const Loader: React.FC<LoaderProps> = ({ loadMoreMessage }) =>{
    const style: React.CSSProperties = {
        margin: 12,
        background: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        borderRadius: '3px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        minWidth: '12rem',
        padding: '6px 12px',
        boxShadow: '1px 0 3px rgba(0,0,0,0.1)'
    }
    return <div style={style}>
        <Icon type="loading" spin style={{ marginRight: 12 }} />
        <span>{loadMoreMessage ? loadMoreMessage :'Loading more data...'}</span>
    </div>
}

const ErrorMsg: React.FC<ErrorMsgProps> = ({ title, message }) => {
    const style: React.CSSProperties = {
        margin: 12,
        background: 'rgba(255, 0, 0, 0.7)',
        color: 'white',
        borderRadius: '3px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        minWidth: '12rem',
        padding: '6px 12px',
        boxShadow: '1px 0 3px rgba(0,0,0,0.1)'
    }
    const titleText = title ? title : 'Failed to get next page';
    return <div style={style}>
        <Row type="flex">
            <Col style={{ marginRight: 12 }}>
                <Icon type="exclamation-circle" />
            </Col>
            <Col>
                <strong>{titleText}</strong>
                <div>{message}</div>
            </Col>
        </Row>
    </div>
}

function scrollElementToBottom(element: HTMLElement, offset: number=0){
    element.scrollTop = element.scrollHeight - offset;
}

export interface BlueprintTableInfiniteScrollerProps extends React.HTMLAttributes<HTMLDivElement> {
    loading?: boolean,
    loadMore?: () => Promise<any>,
    loadMoreMessage?: string,
    hasMore?: boolean,
    children: React.ReactElement<ITableProps>,
    offset?: number
}

const BlueprintTableInfiniteScroller: React.FC<BlueprintTableInfiniteScrollerProps> = ({
    loading = false,
    loadMore: _loadMore,
    loadMoreMessage,
    hasMore,
    children: tableComponent,
    offset=1000,
    ...restProps
}) => {
    const tableRef = useRef(null);
    const [ prevScroll, setPrevScroll ] = useState(0);
    const [ shouldLoadMore, setShouldLoadMore ] = useState(false);
    // const [ canScroll, setCanScroll ] = useState(false);
    const [ startedNextPageWithBtn, setStartedNextPageWithBtn ] = useState(false);
    const [ error, setError ] = useState(null);

    const loadMore = usePromise(_loadMore);

    console.debug('hasMore', hasMore);
    
    const handleError = (err, timeout=5000) => {
        setError(err);
        new Promise(resolve => setTimeout(resolve, timeout))
        .then(() => setError(null));
    }

    const isEmpty = () => {
        console.log(tableRef);
        if (tableRef.current){
            return tableRef.current.props.numRows <= 0;
        }
        return false;
    }

    const handleSuccess = () => {
        console.debug('successful response');
    }

    const nextPage = () => {
        if (loadMore.loading){
            return;
        }
        setShouldLoadMore(true);
    }

    // useEffect(() => {
    //     const allowScroll = () => {
    //         setCanScroll(true);
    //     }
    //     const waitForTimeout = () => new Promise(resolve => {
    //         setTimeout(resolve, 1000);
    //     })
    //     const cancelTimeout = makeCancelable(
    //         waitForTimeout(),
    //         allowScroll
    //     )
    //     return () => cancelTimeout();
    // }, [])

    useEffect(() => {
        if (shouldLoadMore){
            setShouldLoadMore(false);
            setError(null);
            if (typeof loadMore.call !== 'function') return;

            const cancelRequest = makeCancelable(
                loadMore.call(),
                handleSuccess,
                handleError
            )
            return () => cancelRequest();
        }
    // eslint-disable-next-line
    }, [ shouldLoadMore ])

    // eslint-disable-next-line
    useEffect(() => {
        if (!loadMore.loading && startedNextPageWithBtn){
            setStartedNextPageWithBtn(false);
            scrollElementToBottom(tableRef.current.scrollContainerElement, 50);
        }
    })

    const handleScroll = () => {
        const tableState = tableRef.current.state;
        const totalHeight = tableState.rowHeights.reduce((total: number, num: number) => total + num, 0);
        const viewportTop = tableState.viewportRect.top;
        const viewportHeight = tableState.viewportRect.height;
        const scrollDelta = viewportTop - prevScroll;

        const viewportBottom = totalHeight - (viewportTop + viewportHeight) - offset;

        if (scrollDelta === 0){
            return;
        }
        const scrollingDown = scrollDelta >= 0;
        if (viewportTop !== prevScroll){
            setPrevScroll(viewportTop);
        }

        if (viewportBottom <= 0 && scrollingDown && !loadMore.loading && hasMore && !isEmpty()){
            nextPage();
        }
    }

    const renderMsg = () => {
        if (loading){
            return <Loader loadMoreMessage={"Loading..."} />
        }
        if (loadMore.loading){
            return <Loader loadMoreMessage={loadMoreMessage} />
        }
        if (error){
            return <ErrorMsg message={error.message} />
        }
        return null;
    }

    const renderNextPageBtn = () => {
        if (!hasMore || loadMore.loading) return null;
        return <Tooltip
            title="Load next page"
            placement="topLeft"
        >
            <Button
                icon="step-forward"
                onClick={() => {
                    scrollElementToBottom(tableRef.current.scrollContainerElement);
                    setStartedNextPageWithBtn(true);
                    nextPage();
                }}
                style={{
                    position: 'absolute',
                    bottom: '1rem',
                    right: '1rem'
                }}
            />
        </Tooltip>
    }

    useEffect(() => {
        if (tableRef.current?.scrollContainerElement){
            tableRef.current.scrollContainerElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            tableRef.current?.scrollContainerElement.removeEventListener('scroll', handleScroll);
        }
    // So this was a PINA to find. The handleScroll function was always using a stale version of the "hasMore" prop.
    // To solve the issue, I had to add hasMore as a dependency to this useEffect so the handleScroll function is properly
    // refreshed on the event listener
    }, [tableRef.current?.scrollContainerElement, hasMore, loadMore])

    if (tableRef.current){
        tableRef.current.handleRootScroll(e => console.debug('handleRootScroll', e));
    }

    return <div className="blueprint-table-infinite-scroller" {...restProps}>
        {cloneElement(tableComponent, { ref: tableRef })}
        {renderMsg()}
        {renderNextPageBtn()}
    </div>
}

export default BlueprintTableInfiniteScroller