import {CognitoUserInfo} from "./cognito-user-info";

export enum NonComplyBypassCategory {
    NONE="NONE",
    TRAINING="TRAINING",
    DRUGANDALCOHOL="DRUGANDALCOHOL",
    COMPANYGRADE="COMPANYGRADE"
}

// Human-readable version of the NonComplyBypassCategory enum
export enum NonComplyBypassCategoryHuman {
    NONE = "None",
    TRAINING = "Training",
    DRUGANDALCOHOL = "Drug and Alcohol",
    COMPANYGRADE = "Company Grade"
}

export interface NonComplyBypassInput {
    expiresOn?: string
    reason: string
}

export interface NonComplyBypass extends NonComplyBypassInput{
    customerID: string
    personID: string
    timestamp: string
    cognitoUser: CognitoUserInfo
    nonComplyCategory: NonComplyBypassCategory
}