import gql from "graphql-tag";
import {GlobalState} from "../defaultState";
import {Phase1StepQuery} from "../Queries/Scheduling";

type Phase1Step = GlobalState['scheduling']['phase1Step']

const SchedulingResolvers = {
    Query: {
        getPhase1Step: (obj, args, { cache }, info) => {
            const query = gql`
                {
                    scheduling @client {
                        phase1Step
                    }
                }
            `;

            const data = cache.readQuery({ query });
            console.log('getPhase1Step', data);
            return data?.scheduling?.phase1Step as Phase1Step;
        }
    },
    Mutation: {
        setPhase1Step: (_, args, { cache }) => {
            const step: Phase1Step = args.step;

            cache.writeData({
                data: {
                    scheduling: {
                        __typename: 'SchedulingState',
                        phase1Step: step
                    }
                }
            })

            // cache.writeQuery({
            //     query: Phase1StepQuery,
            //     data: step
            // })

            return step;
        }
    }
}

export default SchedulingResolvers;