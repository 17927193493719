import { Alert, Button, Card, Col, message, Modal, Row } from 'antd';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import { EditableCell, EditableFormRow } from 'components/EditableTable';
import { withUserGroups } from 'components/userGroup';
import withOrgData from 'components/WithOrgData';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';
import commonColumns from '../../../common/table/columns';
import { GlobalFilterWithWrapper } from '../../GlobalFilter';
import { Paginator } from '../../Paginator';
import { PassengerEditorWithData } from '../../PassengerEditor';
import PassengerTable from '../../tables/PassengerTable';

/**
 * Displays a real-time filterable table of the current active passengers for the selected transportation provider and customer.
 */

const SET_PASSENGER = gql`
mutation SetPassenger($payload: PaxNodeInput!){
    setPaxNode(
        payload: $payload
    ){
        _id
        lastName,
        firstName,
        personID {
            _id
            extraBroadState
        }
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        },
        tpID {
            _id
            name
        }
        paxWeight,
        bagWeight,
        bagCount,
        checkedInTime,
        scheduledFlightDate,
        destinationID {
            _id, name
        }
        departureID {
            _id, name
        },
        transitType
        lastIsnResultWithHeader {
            success,
            qc_result {
                isnStatus {
                    shortLabel
                }
                quickCheckStatus
                errorMessage
                reason
            }
            selectedResult {
                isnStatus {
                    shortLabel
                }
                quickCheckStatus,
                errorMessage
                reason
            }
        },
        authorization {
            onWhiteList
            noFlyListed
            brdRestriction_Isn {
                status
            }
            brdRestriction_SafetyCard {
                status
            }
            brdRestriction_HuetCard {
                status
            }
            brdRestriction_SteelToes {
                status
            }
            brdRestriction_Twic {
                status
            }
            brdRestriction_NonPreferred {
                status
            }
            brdRestriction_Hazmat {
                status
            }
            brdRestriction_NoFlyListed {
                status
            }
            brdRestriction_EbsCard {
                status
            }
            brdRestriction_Illness {
                status
            }
            brdRestriction_Quarantined {
                status
            }
        }
        currentCarrierID {
            _id
            desig
        }
    }
}`
class LivePassengers extends Component {
    state = {
        editing: "",
        editPaxModal: {
            visible: false, // Modal will appear when true
            id: null // Passenger ID of pax to display in modal
        }
    }
    getActiveTab = () => {
        return this.props.activeTab || 'outbound'
    }
    onTableChange = (_, filters) => {
        this.setState({ filters })
    }
    onTabChange = (activeTab) => this.props.onTabChange && this.props.onTabChange(activeTab)
    getColumns = () => {
        const activeTab = this.getActiveTab();
        const { customer, employer, transporter } = this.props.orgData;

        // Create custom set of columns for display in the table
        const columns = [
            {
                ...commonColumns.pax.columns.assignedFlight,
                defaultSortOrder: 'ascend'
            },
            {
                ...commonColumns.pax.columns.name,
                render: (text, record) => {
                    const name = commonColumns.pax.columns.name.render(text, record);
                    return <Button className="mc-link-btn" onClick={() => this.setPaxModal(true, record._id)}>{name}</Button>
                }
            },
            !employer && commonColumns.pax.columns.employer,
            !customer && commonColumns.pax.columns.customer,
            !customer && !transporter ? commonColumns.pax.columns.transporter : undefined,
            ...commonColumns.pax.except(['name', 'assignedFlight', 'transitType', 'isn', 'restrictions', 'customer', 'employer', 'transporter']),
            {
                ...commonColumns.pax.columns.restrictions,
                width: 100
            },

            // ISN Column will only display when the Outbound tab is selected.
            // Inbound/Transfer passengers do not require an ISN status.
            activeTab === 'outbound' && {
                ...commonColumns.pax.columns.isn
            }
        ].filter(col => col)
        return columns
    }

    // Set visibility and current passenger ID to be displayed in popup modal.
    setPaxModal = (visible, id) => {
        this.setState({
            ...this.state,
            editPaxModal: {
                visible,
                id: id || this.state.editPaxModal.id
            }
        })
    }
    render() {
        const { passengers=[], loading=false, error, refetch, skip, setSkip, userGroups } = this.props;
        const activeTab = this.getActiveTab();
        const onKeyDown = (e) => {
            const specialCharRegex = new RegExp("[a-zA-Z]");
            const pressedKey = e.key
            if ((pressedKey !== "Tab" && pressedKey !== "Backspace" && pressedKey !== "Delete") && specialCharRegex.test(pressedKey)) {
               e.preventDefault();
               return false;
            }
        }
        const columns = this.getColumns().map(col => {
            if (activeTab !== "inbound" || !col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: record => ({
                inputProps: {
                    onKeyDown,
                    onBlur: e => {
                        if(!e.target.value){
                            e.target.value = record[col.dataIndex]
                            return;
                        }
                        if(e.target.value === record[col.dataIndex]){
                            return;
                        }
                        if(e.target.value !== 0 && e.target.value < 90){
                            e.target.value = record[col.dataIndex]
                            message.warn(record['lastName'] + ", " + record["firstName"] + " weight must be 0 or greater than 90.")
                            return;
                        }
                        const payload = {
                            _id: record['_id'],
                            paxWeight: e.target.value,
                            tpID: this.props.orgData.transporter._id
                        }

                        this.props.client.mutate({
                            mutation: SET_PASSENGER,
                            variables: {payload},
                            optimisticResponse:{
                                __typename: 'Mutation',
                                setPaxNode: {
                                    __typename: 'PaxNode',
                                    ...record,
                                    paxWeight: e.target.value
                                }
                            }
                        })
                        .then(() => {
                            message.success(record['lastName'] + ", " + record["firstName"] + " has been successfully saved.")
                        })
                        .catch((err) => {
                            message.error("Error saving " + record['lastName'] + ", " + record["firstName"] + ": " + err)
                        })
                    }
                },
                record,
                ...col,
                cancelButton: true,
                editable: col.editable,
                editing: true,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: this.handleSave,
              })
            };
          });;
        return (
            <div>
                {/* Displays global filter banner. Controls filtering of data in PassengerTable */}
                <GlobalFilterWithWrapper />

                <h2>Passengers</h2>
                {error ? (
                    <Alert style={{ marginBottom: 12 }} showIcon type="error" message="Failed to load passengers" />
                ) : null}

                {/* OUTBOUND/TRANSFER/INBOUND tabs and other actions. */}
                <Row type="flex" gutter={12} style={{ marginBottom: 12 }}>
                    <Col>
                        <RadioGroup onChange={(e) => this.onTabChange(e.target.value)} value={activeTab} buttonStyle="solid">
                            <RadioButton value="outbound">
                                Outbound
                            </RadioButton>
                            <RadioButton value="inbound">
                                Inbound/Transfer
                            </RadioButton>
                        </RadioGroup>
                    </Col>
                    <Col>
                        <Button onClick={() => refetch()} icon="reload" loading={loading}>Refresh</Button>
                    </Col>
                    {userGroups.includes('flytsuite.live.passenger') ?
                    <Col>
                        <Button onClick={() => this.props.history.push('/app/passengers/checkin')}>Add Passenger</Button>
                    </Col> : null}
                    
                </Row>

                {/* Paginator to control the current page displayed in the PassengerTable below */}
                <Card size="small" style={{ marginBottom: 12 }}>
                    <Paginator
                    count={passengers.length}
                    skip={skip}
                    onChange={({ skip }) => setSkip(skip)}
                    size="small"
                    loading={loading}
                    />
                </Card>

                {/* Displays table of passengers that can be filtered and paginated. */}
                <PassengerTable
                    className="mc-table"
                    components={{
                        body: {
                            row: EditableFormRow,
                            cell: EditableCell
                        }
                    }} 
                    pagination={false}
                    rowKey={record => record._id}
                    dataSource={passengers} 
                    onChange={this.onTableChange}
                    columns={columns}
                    scroll={{ x: 'max-content' }}
                    size="small"
                />
                <Modal
                    visible={this.state.editPaxModal.visible}
                    onCancel={() => this.setPaxModal(false)}
                    width="50rem"
                    title="Edit Passenger"
                    footer={false}
                >
                    {this.state.editPaxModal.id ? <PassengerEditorWithData edit={false} paxId={this.state.editPaxModal.id} onCancel={() => this.setPaxModal(false)}  /> : null}
                </Modal>
            </div>
        );
    }
}

LivePassengers.propTypes = {
    passengers: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    activeTab: PropTypes.any,
    onTabChange: PropTypes.func,
    refetch: PropTypes.func
}
 
export default compose(
    withApollo,
    withOrgData,
    withUserGroups
)(LivePassengers);