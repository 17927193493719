import React, { ReactElement } from 'react';
import { UseMasterDataStateReturnProps } from './hook';
import TableLoading from './TableLoading';
import { Spin, Icon } from 'antd';

export interface TableLoaderProps {
    mdState: UseMasterDataStateReturnProps,
    children?: ReactElement
}

const TableLoader: React.FC<TableLoaderProps> = (props) => {
    const { mdState: MDState, children } = props;
    if (MDState.queryError){
        return null
    }
    if (MDState.loading){
        return <TableLoading />
    }
    if (MDState.queryNetworkStatus === 2){
        return <Spin spinning={true} size="large" indicator={<Icon type="loading" />} >
            {children}
        </Spin>
    }
    return children
}

export default TableLoader