import { createFormField, getFormFieldValue, getLabelInValue, getLabelInValueKey } from 'common/form';
import { personToPassenger } from 'common/person';
import { getMaxScheduledOrder } from 'common/util';
import useControlledState from 'hooks/useControlledState';
import { isEqual, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { GroupData } from '.';

export interface QueryData {
    loading: boolean,
    entities: any[],
    departure: any,
    lastKnownController: any,
    destination: any,
    customer: any,
    transporter: any,
    error?: any
}

export interface UseSchedulerParams {
    groupData?: GroupData,
    queryData?: QueryData,
    editing?: boolean,
    onEditingChange?(editing: boolean): void,
    onRefetch?(): void
}

export type AddPersonnelType = (persons: any[], transitType: 'OUTBOUND' | 'INBOUND') => void

export interface UseSchedulerStateReturnProps {
    editing: boolean,
    setEditing(editing: boolean): void,
    saving: boolean,
    saveError: Error,
    data: any[],
    setData(newData: any): void,
    initialData: any[],
    groupFormData: any,
    setGroupFormData(fields: any): void,
    resetGroupFormData(): void,
    groupData: GroupData,
    addPersonnel: AddPersonnelType,
    removeEntities(ids: string[]): void,
    movePassengers(ids: string[], transitType: 'INBOUND' | 'OUTBOUND'): void,
    defaultChargeCode: string,
    setDefaultChargeCode(value: string): void,
    clearChargeCodes(): void,
    deletedData: any[],
    refetch(): void
}

export function useSchedulerState(params: UseSchedulerParams): UseSchedulerStateReturnProps {
    const [ editing, setEditing ] = useControlledState<boolean>(params.editing || false, params.editing, params.onEditingChange);
    const [ data, setData ] = useState<any[]>([]);
    const [ deletedData, setDeletedData ] = useState<any[]>([]);
    const [ groupFormData, setGroupFormData ] = useState<any>(null);

    const [ saving ] = useState<boolean>(false);
    const [ saveError ] = useState<Error>(null);
    const [ defaultChargeCode, setDefaultChargeCode ] = useState(null);

    // function isGroupFormDataTouched(){
    //     function compareGDProperty(property: string){
    //         let formValue = groupFormData && groupFormData[property];
    //         if (formValue){
    //             formValue = formValue.value;
    //         }
    //         return (params.groupData && params.groupData[property] === formValue) || groupFormData === null || groupFormData === undefined
    //     }
    //     return compareGDProperty('tpID') &&
    //         compareGDProperty('customerID') &&
    //         compareGDProperty('date') &&
    //         compareGDProperty('name') &&
    //         compareGDProperty('departureID') &&
    //         compareGDProperty('destinationID')
    // }

    const { queryData, groupData } = params;

    function resetGroupFormData(){
        setGroupFormData({
            date: createFormField(groupData && groupData.date && moment(groupData.date), true),
            name: createFormField(groupData && groupData.name, true),
            departure: createFormField(getLabelInValue(queryData && queryData.departure, 'name'), true),
            lastKnownController: createFormField(getLabelInValue(queryData && queryData.lastKnownController, 'name'), true),
            destination: createFormField(getLabelInValue(queryData && queryData.destination, 'name'), true)
        })
    }

    useEffect(() => {
        // While not edit mode this makes sure that the data state is equal to the query entities list.
        if (!editing && !isEqual(queryData.entities, data)){
            setData(queryData.entities);
        }
    // eslint-disable-next-line
    }, [ editing, queryData && queryData.entities ])

    useEffect(() => {
        if (queryData && !queryData.loading && queryData.entities){
            resetGroupFormData();
            setData(queryData.entities);
        }
    // eslint-disable-next-line
    }, [ queryData && queryData.loading ])

    useEffect(() => {
        if (!editing && queryData && queryData.entities){
            setData(queryData.entities);
        }
    // eslint-disable-next-line
    }, [ editing ])

    const addPersonnel: AddPersonnelType = (persons, transitType) => {
        const largestScheduledOrder = getMaxScheduledOrder(data.filter(item => item && item.classType === 'flytsuite.cgonode'))
        const pax = persons.map((p, i) => {
            let order = i;
            if (largestScheduledOrder !== null){
                order = (largestScheduledOrder + 1) + order;
            }
            return personToPassenger(
                p,
                {
                    scheduledOrder: order,
                    transitType,
                    customerID: queryData && queryData.customer,
                    tpID: queryData && queryData.transporter,
                    departureID: queryData && queryData.departure,
                    destinationID: queryData && queryData.destination,
                    paxWeight: 0,
                    bagWeight: 0,
                    bagCount: 0,
                    chargeCode: null
                }
            )
        });
        setData(
            uniqBy([
                ...data,
                ...pax
        ], (item: any) => item && item._id))
    }

    function deleteData(keys: string[]){
        let newData = data.filter((entity) => entity && !keys.includes(entity._id));
        setDeletedData(data.filter((entity => entity && keys.includes(entity._id))))
        setData(newData);
        return newData
    }

    function removeEntities(ids: string[]){
        return deleteData(ids);
    }

    function movePassengers(ids: string[], currentTransitType: 'INBOUND' | 'OUTBOUND'){
        let destTransitType = currentTransitType === 'OUTBOUND' ? 'INBOUND' : 'OUTBOUND';

        function changeTransitType(pax: any){
            return {
                ...pax,
                transitType: destTransitType
            }
        }

        let pax = data.filter((entity) => entity && ids.includes(entity._id));
        let newData = pax.map(changeTransitType)

        setData(uniqBy([
            ...newData,
            ...data
        ], (item: any) => item && item._id));
    }

    function clearChargeCodes(){
        setData(
            data.map((entity) => {
                if (entity.classType === 'flytsuite.paxnode'){
                    return {
                        ...entity,
                        chargeCode: null
                    }
                }
                return entity
            })
        )
    }

    function handleRefetch(){
        if (params.onRefetch){
            params.onRefetch();
        }
    }

    return {
        editing,
        setEditing,
        data,
        setData,
        initialData: queryData && queryData.entities,
        saving,
        saveError,
        groupData: groupFormData ? {
            date: moment(getFormFieldValue(groupFormData.date)).toISOString(),
            name: getFormFieldValue(groupFormData.name),
            departureID: getLabelInValueKey(getFormFieldValue(groupFormData.departure)),
            destinationID: getLabelInValueKey(getFormFieldValue(groupFormData.destination)),
            lastKnownController: getLabelInValueKey(getFormFieldValue(groupFormData.lastKnownController)),
            tpID: groupData && groupData.tpID,
            customerID: groupData && groupData.customerID
        }: groupData,
        groupFormData,
        setGroupFormData,
        // isGroupFormDataTouched,
        resetGroupFormData,
        addPersonnel,
        removeEntities,
        movePassengers,
        defaultChargeCode,
        setDefaultChargeCode,
        clearChargeCodes,
        deletedData,
        refetch: handleRefetch
    }
}

export interface WithSchedulerProps {
    schedulerProps: UseSchedulerStateReturnProps
}

export type getParams<P> = (props: P) => UseSchedulerParams

export interface SchedulerStateWrapperProps<P> {
    component: React.ComponentType<P>,
    componentProps: P,
    getParams: getParams<P>
}

export function SchedulerStateWrapper<P extends object>(props: SchedulerStateWrapperProps<P>) {
    const schedulerProps = useSchedulerState(props.getParams(props.componentProps));
    const Comp = props.component;
    return <Comp
        {...props.componentProps}
        schedulerProps={schedulerProps}
    />
}

export const withSchedulerState = <P extends Partial<WithSchedulerProps>>(getParams: ((props: P) => UseSchedulerParams)) => (Component: React.ComponentType<P>) => {
    return (props: P) => <SchedulerStateWrapper<P>
        component={Component}
        getParams={getParams}
        componentProps={props}
    />
}