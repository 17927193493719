import {useEffect, useState} from "react";

export type StateWrapperFunc<S> = (options: { state: S, setState: (s: S) => void }) => JSX.Element

export type StateWrapperProps<S> = {
    children: StateWrapperFunc<S>,
    onStateChanged?: (newState: S) => void,
    state?: S,
    defaultState?: S
}

/**
 * Wrapper around JSX code that can add statefulness without having to move that code to a new component.
 */
const StateWrapper = <S,>(props: StateWrapperProps<S>) => {
    const [ state, setState ] = useState<S>(props?.defaultState);

    useEffect(() => {
        if (props.state){
            setState(props.state);
        }
    }, [props.state]);

    return props.children?.({
        state: state,
        setState: (newState) => {
            setState(currState => {
                const merged = { ...currState, ...newState };
                props?.onStateChanged(merged);
                return merged;
            });
        }
    })
}

export default StateWrapper;