import { Button, Col, Input, Row } from 'antd';
import useControlledState from 'hooks/useControlledState';
import React from 'react';
import './PaxCgoScheduler.less';

export interface CargoFooterProps {
    onSubmit?(initials: string): void,
    buttonDisabled?: boolean,
    editMode?: boolean,
    onEditCancel?(): void,
    initials?: string,
    onInitialsChange?(value: string): void,
    isSubmitting?: boolean
}

const CargoFooter: React.FC<CargoFooterProps> = (props) => {
    const [ initials, setInitials ] = useControlledState<string>(undefined, props.initials, props.onInitialsChange);

    function handleInitialsChange(e: React.ChangeEvent<HTMLInputElement>){
        let value = e.target.value;
        value = value.toUpperCase().replace(/[^a-zA-Z]/, '').trim();
        setInitials(value);
    }

    function handleButtonClick(){
        if (props.onSubmit && initials){
            props.onSubmit(initials);
        }
    }

    return <Row
        type="flex"
        justify="end"
        gutter={12}
        className="paxcgo-scheduler-cargo-footer"
    >
        <Col>
            <label className='ant-form-item-required' style={{ fontWeight: 'bold', fontSize: '14px', marginRight: '1rem' }}>Initials: </label>
            <Input
                onChange={handleInitialsChange}
                value={initials}
                style={{ width: '4rem' }}
                maxLength={10}
                onPressEnter={handleButtonClick}
            />
        </Col>
        <Col>
            {props.isSubmitting ? (
                <Button disabled>Submitting...</Button>
            ) : (
                <Button
                    type="primary"
                    disabled={!initials || props.buttonDisabled}
                    onClick={handleButtonClick}
                >{props.editMode ? 'Apply Changes' : 'Add Cargo'}</Button>
            )}
        </Col>
        {props.editMode ? (
            <Col>
                <Button onClick={props.onEditCancel}>Cancel</Button>
            </Col>
        ) : null}
    </Row>
}

export default CargoFooter