import React, { Component } from 'react';
import { Query } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import moment from 'moment';
import WithOrgData from '../../WithOrgData';
import LoadingContent from '../../LoadingContent';
import { Alert, DatePicker, Card, Select, Divider, Row, Col, Icon, Checkbox, Spin, Button } from 'antd';
import withErrorCatch from '../../withErrorCatch';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import ISNChartSelector from './ISNChartSelector';

const { RangePicker } = DatePicker;

const QUERY = gql`
query DashboardQuery(
    $type: String!
    $variables: DashboardInputVariables!
    $filter_result: [FilterResultInput]
    $filter: FilterInput
    $summary: SummaryInput
) {
    dashboard(
        type: $type
        variables: $variables
        filter_result: $filter_result
        filter: $filter
        summary: $summary
    ) @client {
        filter {
            __typename
            label
            choices {
                __typename
                key
                value
            }
        }
        summary {
            __typename
            label
            total {
                __typename
                id
                name
                type
                legend {
                    __typename
                    key
                    value
                }
            }
            content {
                __typename
                id
                name
                type
                legend {
                    __typename
                    key
                    value
                }
            }
        }
    }
}
`

// const FilterHeader = ({ title="Filtering", startTime=moment().subtract(1, 'month'), endTime=moment() }) => (
//     <div>
//         <span>{title}</span>
//         <Divider type="vertical" />
//         <span>Time span: {startTime.format(TIME_FORMAT)} to {endTime.format(TIME_FORMAT)}</span>
//     </div>
// )

const FilterSelect = ({ filterData, dataLabel, ...restProps }) => {
    const filterItem = filterData.find(item => item.label === dataLabel);
    var options = null;
    if (filterItem){
        options = filterItem.choices.map(({ key, value }) => (
            <Select.Option key={key} value={key}>{value}</Select.Option>
        ))
    }
    return (
        <Select {...restProps} defaultValue="" showAction={["focus", "click"]}>
            <Select.Option value="">Do not filter</Select.Option>
            {options}
        </Select>
    )
}

const FilterSelectSearch = (props) => (
    <FilterSelect
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...props}
    />
)

class Dashboard extends Component {
    state = {
        start: moment().subtract(15, 'days'),
        end: moment(),
        filter_result: {},
        filter_visible: true,
        activeTabKey: 'Destination',
        include_total: true,
        dateRangeState: null
    }
    onRangeChange = ([start, end]) => {
        this.setState({ start, end }, this.clearFilter())
    }
    setFilter = (key, value) => {
        const filter_result = this.state.filter_result
        const new_filter = {
            ...filter_result,
            [key]: {
                key,
                value
            }
        }
        if (!value) delete new_filter[key]
        this.setState({ filter_result: new_filter })
    }
    getFilter = (key) => {
        try{
            return this.state.filter_result[key].value
        }catch(err){
            return null
        }
    }
    clearFilter = () => this.setState({ filter_result: {} })
    toggleFilterVisibility = () => this.setState({ filter_visible: !this.state.filter_visible })
    render() {
        const { customer, transporter } = this.props.orgData;
        var filter_result = Object.values(this.state.filter_result)
        if (!filter_result.length){
            filter_result = undefined;
        }
        const variables = {
            type: 'PaxNode',
            variables: {
                tpID: transporter._id,
                customerID: customer && customer._id,
                startDate: this.state.start.toISOString(),
                endDate: this.state.end.toISOString()
            },
            filter_result,
            filter: [
                {
                    label: "destination",
                    key: "destinationID._id",
                    value: "destinationID.name"
                },
                {
                    label: "employer",
                    key: "employerID._id",
                    value: "employerID.name"
                },
                {
                    label: "company",
                    key: "lastIsnResultWithHeader.selectedResult.companyName"
                },
                {
                    label: "passenger",
                    key: "_id",
                    value: ["lastName", "firstName"],
                    value_delimiter: ", "
                }
            ],
            summary: [
                {
                    label: "Destination",
                    type: "percentage",
                    key: "destinationID._id",
                    value: "destinationID.name",
                    sort: [
                        {
                            "destinationID.name": "ascend"
                        }
                    ],
                    group_by: "lastIsnResultWithHeader.selectedResult.quickCheckStatus",
                    reduce: "count",
                    force_capitalization_type: "capitalize",
                    default_group_if_null: "No Status",
                    include_total: true
                },
                {
                    label: "Employer",
                    type: "percentage",
                    key: "employerID._id",
                    value: "employerID.name",
                    sort: [
                        {
                            "employerID.name": "ascend"
                        }
                    ],
                    group_by: "lastIsnResultWithHeader.selectedResult.quickCheckStatus",
                    reduce: "count",
                    force_capitalization_type: "capitalize",
                    default_group_if_null: "No Status",
                    include_total: true
                },
                {
                    label: "Company",
                    type: "percentage",
                    key: "lastIsnResultWithHeader.selectedResult.companyName",
                    value: "lastIsnResultWithHeader.selectedResult.companyName",
                    sort: [
                        {
                            "lastIsnResultWithHeader.selectedResult.companyName": "ascend"
                        }
                    ],
                    group_by: "lastIsnResultWithHeader.selectedResult.quickCheckStatus",
                    reduce: "count",
                    force_capitalization_type: "capitalize",
                    default_group_if_null: "No Status",
                    include_total: true
                }
            ]
        }
        return (
            <div>
                <h2>Dashboard</h2>
                {/* <Row gutter={12}>
                    <Col span={6}>
                        <DashCard title="Pax Checked In" value={14} />
                    </Col>
                </Row>
                <Divider /> */}
                <h3>Passenger ISN Status</h3>
                <Query 
                    query={QUERY} 
                    variables={variables} 
                    fetchPolicy="no-cache"
                    >
                {
                    ({ data, loading, error }) => {
                        // if (error) return <Alert type="error" message="Failed to fetch dashboard" description={error.message} showIcon />
                        return (
                            <Row type="flex" gutter={12} style={{
                                flexWrap: 'nowrap'
                            }}>
                                {
                                    this.state.filter_visible ? (
                                        <Col>
                                            <Card title="Filter" extra={
                                                Object.keys(this.state.filter_result).length ? (
                                                    <Button className="mc-link-btn" onClick={this.clearFilter}>Clear filter</Button>
                                                ) : null
                                            }>
                                                <div style={{marginBottom: 12}} >
                                                    <h4>Time Range:</h4>
                                                    <RangePicker 
                                                        value={[this.state.start, this.state.end]}
                                                        onCalendarChange={(dates) => this.setState({ dateRangeState: dates })}
                                                        format="YYYY-MM-DD"
                                                        disabledDate={(curr) => {
                                                            if (curr && this.state.dateRangeState && this.state.dateRangeState[0]){
                                                                let afterMonth = curr.isSameOrAfter(this.state.dateRangeState[0].clone().subtract(1, 'month'));
                                                                let beforeMonth = curr.isSameOrBefore(this.state.dateRangeState[0].clone().add(1, 'month'));
                                                                return !(afterMonth && beforeMonth)
                                                            }
                                                            return false
                                                        }}
                                                        onOpenChange={(open) => {
                                                            if (!open) {
                                                                this.setState({ dateRangeState: null });
                                                            }
                                                        }}
                                                        onChange={this.onRangeChange}
                                                        ranges={{
                                                            'Past day': [
                                                                moment().subtract(1, 'day'),
                                                                moment()
                                                            ],
                                                            'Past 3 days': [
                                                                moment().subtract(3, 'days'),
                                                                moment()
                                                            ],
                                                            'Past 15 days': [
                                                                moment().subtract(15, 'days'),
                                                                moment()
                                                            ],
                                                            'Past month': [
                                                                moment().subtract(1, 'month'),
                                                                moment()
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                                {loading || !(data && data.dashboard) ? (
                                                    <div style={{ textAlign: 'center', margin: '5rem 0' }}>
                                                        <Spin indicator={<Icon type="loading" />} />
                                                    </div>
                                                ) : (
                                                <>
                                                    <Divider />
                                                    <div style={{marginBottom: 12}} >
                                                        <h4>Destination</h4>
                                                        <FilterSelectSearch
                                                            style={{width: '100%'}}
                                                            value={this.getFilter('destinationID._id') || ''}
                                                            filterData={data.dashboard.filter}
                                                            dataLabel="destination"
                                                            onChange={(value) => this.setFilter('destinationID._id', value)}
                                                        />
                                                    </div>
                                                    <div style={{marginBottom: 12}} >
                                                        <h4>Employer</h4>
                                                        <FilterSelectSearch
                                                            style={{width: '100%'}}
                                                            value={this.getFilter('employerID._id') || ''}
                                                            filterData={data.dashboard.filter}
                                                            dataLabel="employer"
                                                            onChange={(value) => this.setFilter('employerID._id', value)}
                                                        />
                                                    </div>
                                                    <div style={{marginBottom: 12}} >
                                                        <h4>Company</h4>
                                                        <FilterSelectSearch
                                                            style={{width: '100%'}}
                                                            value={this.getFilter('lastIsnResultWithHeader.selectedResult.companyName') || ''}
                                                            filterData={data.dashboard.filter}
                                                            dataLabel="company"
                                                            onChange={(value) => this.setFilter('lastIsnResultWithHeader.selectedResult.companyName', value)}
                                                        />
                                                    </div>
                                                    <div style={{marginBottom: 12}} >
                                                        <h4>Passenger</h4>
                                                        <FilterSelectSearch
                                                            style={{width: '100%'}}
                                                            value={this.getFilter('_id') || ''}
                                                            filterData={data.dashboard.filter}
                                                            dataLabel="passenger"
                                                            onChange={(value) => this.setFilter('_id', value)}
                                                        />
                                                    </div>
                                                </>    
                                                )}
                                            </Card>
                                        </Col>
                                    ) : null
                                }
                                <Col style={{flex: 1}}>
                                <div style={{marginBottom: 12}} >
                                    {error ? <Alert
                                        type="error"
                                        message="Failed to fetch dashboard"
                                        description={error.message}
                                        showIcon
                                        style={{ marginBottom: 12 }}
                                    /> : null}
                                    <Card>
                                        <div style={{margin: '-12px'}}>
                                            <Row type="flex" gutter={12} style={{alignItems: 'center'}}>
                                                <Col>
                                                    <Button className="mc-link-btn" onClick={this.toggleFilterVisibility}>
                                                        {
                                                            this.state.filter_visible ? 
                                                                <span><Icon type="menu-fold" style={{marginRight: '0.5rem'}} />HIDE FILTER</span> : 
                                                                <span><Icon type="menu-unfold"  style={{marginRight: '0.5rem'}} />SHOW FILTER</span>
                                                        }
                                                    </Button>
                                                </Col>
                                                <Divider type="vertical" />
                                                <Col>
                                                    <Checkbox
                                                        style={{marginRight: '0.25rem'}}
                                                        checked={this.state.include_total}
                                                        onChange={(e) => this.setState({ include_total: e.target.checked })} />
                                                    Include Total
                                                </Col>
                                                <Col>
                                                    <RadioGroup value={this.state.activeTabKey} onChange={(e) => this.setState({ activeTabKey: e.target.value })}>
                                                        <RadioButton value="Destination">Destination</RadioButton>
                                                        <RadioButton value="Employer">Employer</RadioButton>
                                                        <RadioButton value="Company">Company</RadioButton>
                                                    </RadioGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </div>
                                {
                                    loading ? (
                                        <Card>
                                            <LoadingContent title="Generating Report" style={{margin: '10rem 0'}} />
                                        </Card>
                                    ) : (
                                        <div>
                                            {data && data.dashboard ? (
                                                <div style={{marginBottom: 12}} >
                                                    <ISNChartSelector data={data.dashboard.summary.find(i => i.label === this.state.activeTabKey)} includeTotal={this.state.include_total} />
                                                </div>
                                            ) : null}
                                        </div>
                                    )
                                }
                                </Col>
                            </Row>
                        )
                    }
                }
                </Query>
            </div>
        );
    }
}
 
export default compose(
    withErrorCatch(),
    WithOrgData
)(Dashboard);