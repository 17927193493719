import React from 'react';
import Transfer, { TransferProps } from 'antd/lib/transfer';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Spin } from 'antd';
// import './style.less';

const ALL_LOCATIONS = gql`
query AllLocations {
    getLocations {
        _id
        name
    }
}
`

const SteelToeDestinationsTransfer: React.FC<Omit<TransferProps, 'dataSource' | 'render' | 'titles'>> = (props) => {

    const { data, loading } = useQuery(ALL_LOCATIONS, {
        onError: error => {console.error(error); throw Error("Failed to fetch locations")}
    });

    if (loading) return <Spin><Transfer /></Spin>
    const dataSource = data.getLocations.map(({ _id, name }) => ({
        key: _id,
        name
    }))

    return <Transfer
        {...props}
        className="mc-steel-toe-destinations-transfer-fallback"
        targetKeys={props.targetKeys || []}
        showSearch
        filterOption={(inputValue, option) => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
        dataSource={dataSource}
        titles={['Available', 'Selected']}
        render={item => item.name}
    />
}

export default SteelToeDestinationsTransfer