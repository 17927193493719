import React from "react";
import { momentOrNull } from "../../../common/util";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import useSortableData from "hooks/useSortableData";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";

const CgoDetail = (props) => {
  const initialData = props.data.map((row) => {
    const getVal = (obj, field) => obj && obj[field];
    //console.log(row.groundPickup);
    //console.log(row);
    return {
      ...row,
      currentCarrierName: getVal(row.currentCarrier, "desig")
        ? getVal(row.currentCarrier, "desig")
        : row.groundPickup
        ? "Ground Pickup"
        : " ",
      departureName: getVal(row.departure, "name"),
      destinationName: getVal(row.destination, "name"),
      customerName: getVal(row.customer, "name"),
      transporterName: getVal(row.transporter, "name"),
      finalized: row.nodeState === "FINALIZED",
    };
  });

  const [data, { setSortKey, currentSortKey, currentSortDir }] =
    useSortableData(initialData);

  const columns = [
    ["Name", "name"],
    ["Flight", "currentCarrierName"],
    ["Departure", "departureName"],
    ["Destination", "destinationName"],
    ["Weight", "weight"],
    ["Transporter", "transporterName"],
    ["Customer", "customerName"],
    [
      "Check-In-Time",
      "checkedInTime",
      (i) => (
          momentOrNull(data[i]?.checkedInTime)?.format("YYYY-MM-DD HH:mm:ss")
      ),
    ],
    ["Initials", "initials"],
    ["Finalized", "nodeState"],
    ["Last Modified", "modifiedTs"],
    ["Delivered By", "deliveredBy"]
  ].map((col) => {
    const cellRenderer = (i) => {
      const value =
        col.length > 2 ? col[2](i) : data[i]?.[col[1]];
      return (
        <Cell tooltip={value} style={{ display: "flex", alignItems: "center" }}>
          {value}
        </Cell>
      );
    };
    return (
      <Column
        key={col[1]}
        name={col[0]}
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={() => (
          <SortableColumnHeaderCell
            name={col[0]}
            sortKey={col[1]}
            onSort={setSortKey}
            currentSortedKey={currentSortKey}
            currentSortDir={currentSortDir}
          />
        )}
      />
    );
  });

  return (
    <Table
      selectionModes={SelectionModes.NONE}
      className="mc-table"
      numRows={data?.length}
    >
      {columns}
    </Table>
  );
};

// const CgoDetail = ({ data }) => {
//     const tableRef = useRef(null);
//     return (
//         <Layout style={{ height: '100%' }} >
//             <Layout.Header style={{ background: 'white', padding: 0, lineHeight: '24px', height: '45px'}}>
//                 <Button onClick={() => tableRef.current.table.clearFilter(true)}>Clear Filters</Button>
//             </Layout.Header>
//             <Layout.Content>
//                 <ReactTabulator
//                     ref={tableRef}
//                     height="100%"
//                     data={data}
//                     layout="fitDataFill"
//                     columns={[
//                         {
//                             title: '#',
//                             formatter: 'rownum',
//                             width: 50
//                         },
//                         {
//                             title: 'Name',
//                             field: 'name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Customer',
//                             field: 'customer.name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Weight',
//                             field: 'weight',
//                             headerFilter: 'number'
//                         },
//                         {
//                             title: 'Departure',
//                             field: 'departure.name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Destination',
//                             field: 'destination.name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Check-In-Time',
//                             field: 'checkInTime',
//                             formatter: (cell) => {
//                                 return moment(cell.getValue()).format('YYYY-MM-DD hh:mm:ss')
//                             }
//                         },
//                         {
//                             title: 'State',
//                             field: 'nodeState',
//                             headerFilter: 'select',
//                             headerFilterParams: {
//                                 values: true
//                             }
//                         }
//                     ]}
//                 />
//             </Layout.Content>
//     </Layout>
//     )
// }

export default CgoDetail