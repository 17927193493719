import React from "react";
import useSortableData from "hooks/useSortableData";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";

const CovidVaccineDetailedDetail = (props) => {
  const initialData = props.data;

  const [data, { setSortKey, currentSortKey, currentSortDir }] =
    useSortableData(initialData);

  const columns = [
    {
      name: "Last Name",
      key: "lastName",
    },
    {
      name: "First Name",
      key: "firstName",
    },
    {
      name: "DOB",
      key: "dob",
    },
    {
      name: "Employer",
      key: "employerName",
    },
    {
      name: "Shot Manufacturer",
      key: "shotManufacturer",
    },
    {
      name: "Shot 1",
      key: "shot_1",
    },
    {
      name: "Shot 2",
      key: "shot_2",
    },
    {
      name: "Vaccinated on",
      key: "vaccinated_on",
    },
    {
      name: "POS Test",
      key: "pos_test",
    },
    {
      name: "RTW",
      key: "rtw",
    },
    {
      name: "RTW 2",
      key: "rtw_2",
    },
    {
      name: "Essential On",
      key: "essentialOn",
    },
  ].map((col) => {
    const cellRenderer = (i) => {
      const value = col.formatter
        ? col.formatter(data[i]?.[col.key])
        : data[i]?.[col.key];
      return (
        <Cell tooltip={value} style={{ display: "flex", alignItems: "center" }}>
          {value}
        </Cell>
      );
    };
    return (
      <Column
        key={col.key}
        name={col.name}
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={() => (
          <SortableColumnHeaderCell
            name={col.name}
            sortKey={col.key}
            onSort={setSortKey}
            currentSortedKey={currentSortKey}
            currentSortDir={currentSortDir}
          />
        )}
      />
    );
  });

  return (
    <Table
      selectionModes={SelectionModes.NONE}
      className="mc-table"
      numRows={data?.length}
    >
      {columns}
    </Table>
  );
};

export default CovidVaccineDetailedDetail