import { Button, Drawer } from 'antd';
import 'css/Calendar.less';
import 'css/Drawer.css';
import moment from 'moment';
import React, { useState } from 'react';
import SchedulingCalendar from '../calendar';
import '../calendar/ScheduleCalendar.css';
import { ScheduledFlight } from '../interface';
// import ScheduledGroupsTable from './table';
import pluralize from 'pluralize';
import { getFlightDeparture, getFlightDestinations } from 'common/flight';
import ScheduledFlightsTable from './table';

export interface ScheduledFlightsCalendarProps {
    onFlightClicked?(flight: ScheduledFlight): void,
    value?: moment.Moment,
    data?: Array<any>
}

const ScheduledFlightsCalendar: React.FC<ScheduledFlightsCalendarProps> = (props) => {
    const [ expandedDay, setExpandedDay ] = useState<{ date: moment.Moment, data: Array<any> }>(null);

    return <>
    <SchedulingCalendar<ScheduledFlight>
        data={props.data}
        value={props.value}
        getDataDate={(dataItem) => dataItem.scheduledFlightDate}
        onCellClick={(date, data) => setExpandedDay({ date, data })}
        renderDateCell={(data) => {
            return {
                title: `${data.length} ${pluralize('schedule', data.length)}`,
                content: <ul className="scheduling-events">
                    {data.map((flight, idx) => {
                        let buttonTooltip = `Flight Designation: ${flight.desig}`;
                        buttonTooltip += `\nAircraft Tail Num: ${flight.aircraftID && flight.aircraftID.tailNum}`
                        buttonTooltip += `\nDeparture: ${getFlightDeparture(flight) && getFlightDeparture(flight).name}`;
                        buttonTooltip += `\nDestinations: ${getFlightDestinations(flight).map(dest => dest.name).join(', ')}`;
                        buttonTooltip += '\n\nClick to view schedule'
                        return <li key={idx}>
                            <Button
                                type="primary"
                                size="small"
                                block
                                style={{ textAlign: 'left' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (props.onFlightClicked)
                                        props.onFlightClicked(flight);
                                }}
                                title={buttonTooltip}
                            >{flight.desig}</Button>
                        </li>
                    })}
                </ul>
            }
        }}
    />
    <Drawer
        className="ant-drawer-flex ant-drawer-body-no-padding"
        title={expandedDay && expandedDay.date.format('MMMM DD, YYYY')}
        onClose={() => setExpandedDay(null)}
        visible={expandedDay ? true : false}
        width="1000px"
    >
        {expandedDay && expandedDay.data.length ? (
            <ScheduledFlightsTable
                    data={expandedDay.data}
                    onViewFlight={(flight) => props.onFlightClicked && props.onFlightClicked(flight)}
                />
        ) : null}
    </Drawer>
    </>
}

export default ScheduledFlightsCalendar