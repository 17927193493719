import gql from "graphql-tag";

export default gql`
query ISNQuickCheck(
    $customerID: ID!
    $isnNumber: String!
){
    ISNQuickCheck(
        customerID: $customerID
        isnNumber: $isnNumber
    ){
        success
        qc_count
        isn_customer
        isn_ownerid
        error_message
        qc_result {
            trainingStatus
            allowed
            companyID
            quickCheckStatus
            errorMessage
            isnId
            firstName
            lastName
            companyName
            mostRecentTrainingQualificationExpirationName
            mostRecentTrainingExpirationDate
            assignedActivities
            dataAsOf
            reason
            reasonsForNonCompliance

            trainingQualificationProject {
                id
                name
            }
            operatorQualificationProject {
                id
                name
            }
            onlineTrainingQualificationProject {
                id
                name
            }
        }
    }
}
`