import gql from "graphql-tag";

export default gql`
query VesselQuery(
    $filter: VesselsFilterInput
    $limit: Int
){
    vessels(
        filter: $filter
        limit: $limit
    ){
        docs {
            _id
            ... on Vessel {
                name
            }
        }
    }
}
`