import React, { createContext, PropsWithChildren, useState, useEffect } from 'react';
import config from 'config.js';

import darkVars from '../dark.json';
import lightVars from '../light.json';

const DARK_THEME_STORAGE_KEY = 'app-dark-theme';
const ENABLE_THEME_STORAGE_KEY = 'app-theming-enabled';

const initialValue = {
    vars: undefined,
    themeName: undefined,
    applied: false,
    changeTheme: undefined,
    loading: false,
    themingEnabled: true
}

type ThemeName = 'light' | 'dark'

export interface ThemeContextValue {
    vars?: any,
    themeName: ThemeName,
    applied: boolean,
    loading: boolean,
    changeTheme?: (name: (ThemeName | ((currThemeName: ThemeName) => ThemeName))) => void,
    themingEnabled: boolean,
    setThemingEnabled?(enabled: boolean): void
}

export const ThemeContext = createContext<ThemeContextValue>(initialValue);

export interface CurrentTheme {
    theme: any,
    name: ThemeName
}

function getThemeVars(dark: boolean) {
    return dark ? darkVars : lightVars;
}

function isDarkFromName(name: ThemeName) {
    return name === 'dark';
}

export interface ThemeProviderProps {
    /**
     * Enables application theming when provider is mounted.
     * Default: false
     */
    enableThemingOnMount?: boolean
}

/**
 * Provides dark theming context to the rest of the application.
 * Used to enable and disable dark theme.
 */
export const ThemeProvider: React.FC<PropsWithChildren<ThemeProviderProps>> = (props) => {
    let defaultTheme: any = 'dark';
    if (config.defaultTheme === 'light') {
        defaultTheme = 'light';
    }

    const { enableThemingOnMount = false } = props;

    const [themeVars, setThemeVars] = useState(getThemeVars(isDarkFromName(defaultTheme)));
    const [themingEnabled, setThemingEnabled] = useState(true);
    const [darkApplied, setDarkApplied] = useState(isDarkFromName(defaultTheme));
    const [themeApplied, setThemeApplied] = useState(false);
    const [themeLoading, setThemeLoading] = useState(false);

    const themeName = darkApplied ? 'dark' : 'light';

    function parseDarkThemeStorage(val: string) {
        try {
            return JSON.parse(val);
        }
        catch (e) {
            console.error("Failed to parse local storage: ", val);
            return isDarkFromName(defaultTheme);
        }
    }

    function parseThemingEnabledStorage(val: string) {
        try {
            return JSON.parse(val);
        }
        catch (e) {
            console.error("Failed to parse local storage: ", val);
            return true;
        }
    }

    // Will listen to theme changes from other windows.
    function listenToStorageChanges(e: StorageEvent) {
        if (e.key !== DARK_THEME_STORAGE_KEY) return;
        console.log("Theme changed in other window. Switching theme...");
        applyTheme(Boolean(parseDarkThemeStorage(e.newValue)));
    }

    // Re-apply theme when themingEnabled is changed.
    useEffect(() => {
        applyTheme(darkApplied);
        // eslint-disable-next-line
    }, [themingEnabled])

    useEffect(() => {

        // Get theme value from local storage
        let themeValue = parseDarkThemeStorage(localStorage.getItem(DARK_THEME_STORAGE_KEY));
        let themeEnabled = parseThemingEnabledStorage(localStorage.getItem(ENABLE_THEME_STORAGE_KEY));

        // Set default value in local storage if not found.
        if (themeValue !== true && themeValue !== false) {
            themeValue = isDarkFromName(defaultTheme);
            localStorage.setItem(DARK_THEME_STORAGE_KEY, JSON.stringify(themeValue));
        }

        // Set default value of theming enabled if not found.
        if ((themeEnabled !== true && themeEnabled !== false) || (enableThemingOnMount && !themeEnabled)) {
            themeEnabled = true;
            localStorage.setItem(ENABLE_THEME_STORAGE_KEY, JSON.stringify(themeEnabled));
        }

        // Apply light/dark theme.
        applyTheme(themeValue);

        // Add listener for storage changes.
        window.addEventListener('storage', listenToStorageChanges);
        return () => window.removeEventListener('storage', listenToStorageChanges);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (themeVars) {
            setThemeApplied(false)
            setThemeLoading(true)

            // Modifies LESS variables at runtime.
            window['less']
                .modifyVars(themeVars)
                .then(() => {
                    console.log('Theme changed:', themeVars);
                    setThemeApplied(true);
                })
                .catch((e) => {
                    console.error('FAILED TO APPLY THEME:', e)
                })
                .then(() => setThemeLoading(false))
        }
        // eslint-disable-next-line
    }, [themeVars, window])

    // Apply theme variables
    function applyTheme(dark: boolean) {
        const useDark = themingEnabled ? dark : false;
        if (useDark)
            console.log('Applying dark theme...');
        else
            console.log('Removing dark theme...');
        setDarkApplied(useDark);
        const vars = getThemeVars(useDark);
        setThemeVars(vars);
    }

    // Start theme change process
    function changeTheme() {
        let name: ThemeName;
        if (typeof arguments[0] === 'function') {
            name = arguments[0](themeName);
        }
        else {
            name = arguments[0];
        }

        console.log(name, isDarkFromName(name));
        localStorage.setItem(DARK_THEME_STORAGE_KEY, JSON.stringify(isDarkFromName(name)));
        applyTheme(isDarkFromName(name));
    }

    function enableTheming(enabled: boolean) {
        localStorage.setItem(ENABLE_THEME_STORAGE_KEY, JSON.stringify(enabled));
        setThemingEnabled(enabled);
    }

    return <ThemeContext.Provider value={{
        vars: themeVars,
        themeName: themeName,
        applied: themeApplied,
        loading: themeLoading,
        changeTheme,
        setThemingEnabled: enableTheming,
        themingEnabled
    }}>
        {props.children}
    </ThemeContext.Provider>;
}