import {DefaultState, Reducer, State} from "./state";
import {Dispatch, ReducerAction, useEffect, useReducer} from "react";

export interface UseRouteEditorArgs {
    initFlightPath?: string[],
    overrideState?: State,
    overrideDispatch?: Dispatch<ReducerAction<typeof Reducer>>
}

function useRouteEditorApi(args?: UseRouteEditorArgs){
    const [ _state, _dispatch ] = useReducer(Reducer, DefaultState);

    const state = args?.overrideState || _state;
    const dispatch = args?.overrideDispatch || _dispatch;

    useEffect(() => {
        if (args?.initFlightPath){
            dispatch({ type: 'INIT', payload: { flightPath: args.initFlightPath } });
        }
    }, []);

    return {
        state: state,
        dispatch: dispatch,
        route: {
            get: () => state.path.present,
            reset: () => {
                dispatch({ type: 'RESET' })
            },
            remove: (index: number) => (
                dispatch({ type: 'UPDATE_ROUTE', payload: { removeIndex: index } })
            ),
            add: (locId: string, insertIndex?: number) => {
                if (!locId) return;

                dispatch({type: 'UPDATE_ROUTE', payload: {add: { locId, insertIndex } }});
            },
            move: (startIndex: number, endIndex: number) => {
                dispatch({ type: 'MOVE', payload: { startIndex, endIndex } })
            },
            undo: () => dispatch({ type: 'UNDO' }),
            redo: () => dispatch({ type: 'REDO' })
        }
    }
}

export type Api = ReturnType<typeof useRouteEditorApi>

export default useRouteEditorApi;