import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Select, Button, Tooltip, Spin } from 'antd';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import PaginationLimit from '../../Queries/PaginationLimit';

const DEFAULT_SKIP = 0;

class Paginator extends Component {
    static propTypes = {
        count: PropTypes.number.isRequired,
        skip: PropTypes.number,
        size: PropTypes.oneOf(['small', 'default', 'large']),
        onChange: PropTypes.func.isRequired,
        loading: PropTypes.bool
    }
    getSkip = () => {
        return this.props.skip || DEFAULT_SKIP
    }
    getLimit = () => {
        const { data } = this.props;
        return (data && data.paginationLimit) || 50;
    }
    getCurrentPage = () => {
        const skip = this.getSkip();
        return (skip / this.getLimit()) + 1
    }
    getDataRange = () => {
        const { count, loading } = this.props;
        const skip = this.getSkip();
        if (loading){
            return (
                <span>
                    <span>{`${skip + 1} - `}</span>
                    <Spin size="small" />
                </span>
            )
        }
        return `${skip + 1} - ${skip + count}`
    }
    handleChange = (obj) => {
        if (obj.limit){
            this.props.setPaginationLimit({
                variables: {
                    limit: obj.limit
                }
            })
        }
        this.props.onChange &&
        this.props.onChange({
            limit: this.getLimit(),
            skip: this.getSkip(),
            ...obj
        })
    }
    hasPrev = () => {
        return this.getCurrentPage() > 1
    }
    hasNext = () => {
        return this.props.count >= this.getLimit() ? true : false
    }
    nextPage = () => {
        this.handleChange({ skip: this.getSkip() + this.getLimit() })
    }
    prevPage = () => {
        var skip = this.getSkip() - this.getLimit();
        if (skip < 0) skip = 0
        this.handleChange({ skip })
    }
    render() {
        const { size, loading } = this.props;
        return (
            <Row type="flex" gutter={24} style={{ alignItems: 'baseline' }}>
                <Col style={{ borderRight: '1px solid #e8e8e8' }}>
                <Tooltip title="First Page">
                    <Button
                    icon="double-left"
                    onClick={() => this.handleChange({ skip: 0 })}
                    style={{ marginRight: 12 }}
                    disabled={!this.hasPrev() || loading}
                    size={size}
                    />
                </Tooltip>
                    <Button.Group>
                        <Tooltip title="Previous Page">
                            <Button
                                type="primary"
                                icon="left"
                                disabled={!this.hasPrev() || loading}
                                onClick={this.prevPage}
                                size={size}
                            />
                        </Tooltip>
                        <Tooltip title="Next Page">
                            <Button
                                type="primary"
                                icon="right"
                                disabled={!this.hasNext() || loading}
                                onClick={this.nextPage}
                                size={size}
                            />
                        </Tooltip>
                    </Button.Group>
                </Col>
                <Col style={{ borderRight: '1px solid #e8e8e8' }}>
                    {`Showing items `}
                    {this.getDataRange()}
                </Col>
                <Col>
                    <span style={{ marginRight: 12 }}>Items per page</span>
                    <Select
                        size={size}
                        value={this.getLimit()}
                        onChange={(value) => this.handleChange({ limit: Number(value) })}
                        style={{ minWidth: '3rem' }}
                        showAction={["focus", "click"]}
                    >
                        <Select.Option key="50">50</Select.Option>
                        <Select.Option key="100">100</Select.Option>
                        <Select.Option key="200">200</Select.Option>
                        <Select.Option key="300">300</Select.Option>
                        <Select.Option key="400">400</Select.Option>
                        <Select.Option key="500">500</Select.Option>
                    </Select>
                </Col>
            </Row>
        );
    }
}
 
export default compose(
    graphql(
        PaginationLimit
    ),
    graphql(
        gql`
        mutation SetPaginationLimit($limit: Int!){
            setPaginationLimit(limit: $limit) @client
        }
        `,
        {
            name: 'setPaginationLimit'
        }
    )
)(Paginator);