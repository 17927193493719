import React from 'react';
import { Query } from 'react-apollo';
import { Spin } from 'antd';
import LocationBlockQuery from '../../Queries/LocationBlock';

export const LocationBlock = ({ locationId }) => {
    return (
        <Query
            query={LocationBlockQuery}
            variables={{ id: locationId.key }}
        >
        {({ data, loading, error }) => {
            if (loading) return <Spin />
            if (error) return <div style={{ color: 'red' }}>Error getting block</div>
            if (!data || !data.getLocation) return null;
            if(!data.getLocation.block) return '-'
            return data.getLocation.block + "-" + data.getLocation.field
        }}
        </Query>
    )
}