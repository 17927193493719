import React from 'react';
import { Input, Button, Select } from 'antd';
import { getMonthOptions } from '.';
import moment from 'moment';
import useControlledState from 'hooks/useControlledState';

export interface MonthSelectProps {
    value?: any,
    onChange?(value: moment.Moment): void,
    style?: React.CSSProperties
}

const MonthSelect: React.FC<MonthSelectProps> = (props) => {
    const [ value, setValue ] = useControlledState<moment.Moment>(moment(), props.value, props.onChange);

    return <Input.Group compact style={{ display: 'flex', ...props.style }}>
        <Button
            icon="left"
            onClick={() => {
                let offset = value.clone().subtract(1, 'month');
                if (offset.isSameOrAfter(moment(), 'month')){
                    setValue(offset)
                }
            }}
            disabled={value.clone().subtract(1, 'month').isBefore(moment(), 'month')}
        />
            <Select
                dropdownMatchSelectWidth={false}
                value={value.format('YYYY-MM')}
                style={{ flex: 1, minWidth: '10rem' }}
                onChange={selectedMonth => {
                    // const newValue = value.clone();
                    // newValue.month(parseInt(selectedMonth, 10) - 1);
                    let newValue = moment(selectedMonth, 'YYYY-MM');
                    setValue(value.clone().month(newValue.month()).year(newValue.year()));
                }}
                showAction={["focus", "click"]}
            >
            {getMonthOptions(value)}
            </Select>
        <Button icon="right" onClick={() => setValue(value.clone().month(value.month() + 1))} />
    </Input.Group>
}

export default MonthSelect