// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        "key": "WORK",
        "label": "Work"
    },
    {
        "key": "HOME",
        "label": "Home"
    },
    {
        "key": "MOBILE",
        "label": "Mobile"
    },
    {
        "key": "OTHER",
        "label": "Other"
    }
]