import React, { Component } from 'react';
import { Alert, Button } from 'antd';

class ErrorCatch extends Component {
    state = {
        error: null
    }
    componentDidCatch(error){
        this.setState({ error })
    }
    resetState = () => {
        this.setState({ error: null })
    }
    render() { 
        const { error } = this.state;
        const { message, description, children, ...restProps } = this.props;
        if (error){
            const alertProps = {
                message: message || 'An error occurred in the application',
                description: (
                    <div>
                        <p>{description || error.message}</p>
                        <Button onClick={this.resetState}>Try Again</Button>
                    </div>
                ),
                showIcon: true,
                onClose: this.resetState,
                closable: true,
                type: 'error',
                ...restProps
            }
            return (
                <Alert {...alertProps} />
            )
        }
        return children
    }
}
 
// eslint-disable-next-line import/no-anonymous-default-export
export default (options={}) => (Component) => props => (
    <ErrorCatch {...options}>
        <Component {...props} />
    </ErrorCatch>
);