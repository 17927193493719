import PropTypes from 'prop-types';
import WithOrgData from '../WithOrgData';

const CurrentCustomer = ({ children, ...restProps }) => {
    return (
       children(restProps.orgData.customer)
    )
}

CurrentCustomer.propTypes = {
    children: PropTypes.func.isRequired
}

export default WithOrgData(CurrentCustomer)