import React from 'react';
import { Row, Col, Form, Alert, Card, Table, Spin, Tabs, List } from 'antd';
import gql from 'graphql-tag';
import moment from 'moment';
import { Query } from 'react-apollo';
import { filterNull, deDupeSort, YesNo, get } from '../../../common/util';
import { withApollo } from 'react-apollo';
import {
    CustomerFormItem, 
    DateRangeFormItem, 
    GenerateReportFormItem, 
    ReportLayout, 
    ReportLoading,
    ReportResult,
    ReportNoData,
    ReportError,
    EmployerFormItem
} from './Report';

import '../../../css/Report.css';
import WithOrgData from '../../WithOrgData';
import IsnNullAndOverride from '../../charts/isn/IsnNullAndOverride';
import IsnCardStatus from '../../charts/isn/IsnCardStatus';
import _ from 'lodash';

const FORM_QUERY = gql`
query FormQuery($tpID: ID!, $customerID: ID!){
    NodeDepartureList(
        tpID: $tpID
        customerID: $customerID
        classType: "flytsuite.paxnode"
    ){
        _id
        name
    }
    NodeDestinationList(
        tpID: $tpID
        customerID: $customerID
        classType: "flytsuite.paxnode"
    ){
        _id
        name
    }
    PaxEmployerList(
        tpID: $tpID
        customerID: $customerID
    ){
        _id
        name
    }
    customerList @client {
        _id
        name
    }
    customerList @client {
        _id
        name
    }
}
`

const REPORT_QUERY = gql`
query ReportQuery(
    $tpID: ID!
    $customerID: ID!
    $startDate: AWSDate!
    $endDate: AWSDate!
    $employerID: ID
){
    reportIsn(
        tpID: $tpID
        customerID: $customerID
        startDate: $startDate
        endDate: $endDate
        employerID: $employerID
    ){
        _id
        personID {
            _id
        }
        currentCarrierID {
            _id
            desig
            scheduledFlightDate
        }
        departureID {
            _id
            name
        }
        checkInCred {
            type
            expiration
        }
        firstName
        lastName
        paxWeight
        bagCount
        bagWeight
        checkedInTime
        employerID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        customerID {
            _id
            name
        }
        authorization {
            onWhiteList
            brdRestriction_Isn {
                status
                type
                notes
                reason
            }
        }
        lastIsnResultWithHeader {
            error_message
            isn_customer
            quick_check_status
            reason
            isn_ownerid
            success
            selectedResult {
                isnStatus {
                    shortLabel
                }
                allowed
                companyID
                quickCheckStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
                reasonsForNonCompliance
                trainingDeficiencies
                trainingStatus
            }
        }
    }
    getOrganization(_id: $customerID){
        _id
        name
    }
}
`

const ISNTable = ({ data, ...rest }) => {
    const filteredData = filterNull(data);
    if (!filteredData.length) return null
    const dataSource = filteredData.map(cgo => ({
            ...cgo,
            key: cgo._id
        }
    ))
    const columns = [
        {
            title: 'Passenger Name',
            key: 'passengerName',
            render: (_, record) => {
                const { firstName, lastName } = record;
                return `${lastName}, ${firstName}`
            }
        },
        {
            title: 'Customer',
            key: 'customer',
            dataIndex: 'customerID',
            render: cust => cust.name
        },
        {
            title: 'Employer',
            key: 'employer',
            dataIndex: 'employerID',
            render: emp => emp.name
        },
        {
            title: 'Base',
            key: 'base',
            dataIndex: 'departureID',
            render: dep => dep.name
        },
        {
            title: 'Destination',
            key: 'destination',
            dataIndex: 'destinationID',
            render: dest => dest.name
        },
        {
            title: 'First Name',
            key: 'firstName',
            dataIndex: 'firstName'
        },
        {
            title: 'First Name',
            key: 'lastName',
            dataIndex: 'lastName'
        },
        {
            title: 'Allowed',
            key: 'allowed',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return YesNo(lastIsn.selectedResult.allowed)
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Credential Type',
            key: 'credentialType',
            dataIndex: 'checkInCred',
            render: cred => {
                if (!cred) return null
                try{
                    return cred.type.replace(/_/g, " ")
                }catch(error){
                    console.warn(error)
                    return null
                }
            }
        },
        {
            title: 'Credential Expires',
            key: 'credentialExpires',
            dataIndex: 'checkInCred',
            render: cred => {
                if (!cred) return null
                try{
                    return cred.expiration
                }catch(error){
                    console.warn(error)
                    return null
                }
            }
        },
        {
            title: 'ISN ID',
            key: 'isnID',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.isnId || <span style={{color: 'red'}}>No ISN Number</span>
                }
                catch(err){
                    console.warn(err.message)
                    return <span style={{color: 'red'}}>No ISN Number</span>
                }
            }
        },
        {
            title: <span>Most Recent Training<br/> Expiration Qualification Name</span>,
            key: 'mostRecentTrainingExpirationQualificationName',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.mostRecentTrainingQualificationExpirationName 
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Error Message',
            key: 'errorMessage',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.errorMessage
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Success',
            key: 'success',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.success
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Company ID',
            key: 'companyID',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.companyID
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Company Name',
            key: 'companyName',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.companyName
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Received',
            key: 'received',
            dataIndex: 'lastIsnResultWithHeader',
            render: () => <span style={{color: 'purple'}}>Not in data model</span>
        },
        {
            title: <span>Most Recent Training <br/> Expiration Date</span>,
            key: 'mostRecentTrainingExpirationDate',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.mostRecentTrainingExpirationDate
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Reason',
            key: 'reason',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.reason
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Data As Of',
            key: 'dataAsOf',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.dataAsOf
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Training Deficiencies',
            key: 'trainingDeficiencies',
            dataIndex: 'lastIsnResultWithHeader',
            render: (lastIsn) => {
                try{
                    return lastIsn.selectedResult.trainingDeficiencies
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Override Reason',
            key: 'overrideReason',
            dataIndex: 'authorization',
            render: (auth) => {
                try{
                    return auth.brdRestriction_Isn.type.replace(/_/g, " ")
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Override Authorizing User',
            key: 'overrideAuthorizingUser',
            dataIndex: 'authorization',
            render: () => <span style={{color: 'purple'}}>Not in data model</span>
        },
        {
            title: 'Override Created',
            key: 'overrideCreated',
            dataIndex: 'authorization',
            render: (auth) => {
                try{
                    return moment(auth.brdRestriction_Isn.createdTs).format('YYYY-MM-DD HH:mm:ss')
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Override Notes',
            key: 'overrideNotes',
            dataIndex: 'authorization',
            render: (auth) => {
                try{
                    return auth.brdRestriction_Isn.notes
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Override Expires',
            key: 'overrideExpires',
            dataIndex: 'authorization',
            render: (auth) => {
                try{
                    return auth.brdRestriction_Isn.scheduledFlightDate
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Quick Check Status',
            key: 'quickCheckStatus',
            dataIndex: 'authorization',
            render: (lastIsn) => {
                try{
                    const { quickCheckStatus } = lastIsn.selectedResult;
                    var color = 'initial';
                    if (quickCheckStatus.toLowerCase() === 'green'){
                        color = 'green'
                    }
                    else if (quickCheckStatus.toLowerCase() === 'red'){
                        color = 'red'
                    }
                    return <span style={{color}}>{quickCheckStatus}</span>
                }
                catch(err){
                    console.warn(err.message)
                    return null
                }
            }
        },
        {
            title: 'Documentation',
            key: 'documentation',
            render: () => <span style={{color: 'purple'}}>Not in data model</span>
        },
    ]
    return (
        <React.Fragment>
            <Table dataSource={dataSource} columns={columns} className="mc-report" size="small" bordered {...rest} pagination={false} />
        </React.Fragment>
    )
}

const getEmployersWithNoIsnCard = (data) => {
    // const employers = data.map(item => item.employer && item.employer._id).filter(item => item);
    const employers = data.filter(item => {
        if (!item.employerID) return false
        const lastIsn = item.lastIsnResultWithHeader;
        const selectedResult = lastIsn && lastIsn.selectedResult;
        const isnId = selectedResult && selectedResult.isnId;
        if (isnId){
            return false
        }
        return true
    }).map(pax => pax.employerID);
    const uniqueEmployers = _(employers)
        .groupBy('name')
        .map((items, name) => ({ name, count: items.length }))
        .value()
        .sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })
    return uniqueEmployers
}

class ISNReport extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            report: null
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { transporter } = this.props.orgData;
                const { customerID, daterange, departureID, destinationID, cgoname, finalized, employerID } = values;
                const variables =  {
                    name: cgoname,
                    tpID: transporter._id,
                    customerID,
                    departureID,
                    destinationID,
                    employerID,
                    finalized: finalized === 'FINALIZED',
                    startDate: daterange[0].toISOString().split("T")[0],
                    endDate: daterange[1].toISOString().split("T")[0]
                }
                this.query(variables)
            }
        });
    }
    query = async (variables) => {
        this.setState({ report: {loading: true }});
        try{
            const { data, error } = await this.props.client.query({
                query: REPORT_QUERY,
                variables,
                fetchPolicy: 'network-only'
            })
            this.setState({ report: {data, error, variables} });
        }catch(error){
            console.error(error);
            this.setState({ report: {data: null, error, variables} });
        }
        this.setState({ loading: false })
    }
    renderReport = () => {
        const { report } = this.state;
        if (report){
            const { transporter } = this.props.orgData;
            var transporterName;
            const customerName = get(report, r => r.data.getOrganization.name) || 'Customer not found';
            try{
                transporterName = transporter.name;
            }
            catch(error){
                transporterName = <span style={{color: 'red'}}>Transporter not found</span>
            }
            const { data, loading, error } = report;
            try{
                if (loading) return <ReportLoading />
                if (error) throw Error(error)
                if (!data) return null 
                const { reportIsn } = data;
                const { startDate, endDate } = report.variables;
                if (reportIsn.length){
                    var employers = getEmployersWithNoIsnCard(reportIsn);
                    return <ReportResult header={{
                        title: <span>{transporterName} ISN Report for {customerName}</span>,
                        dateRange: {
                            start: startDate,
                            end: endDate
                        }
                    }} render={
                        <React.Fragment>
                            <Tabs defaultActiveKey="charts">
                                <Tabs.TabPane key="charts" tab="Charts" >
                                <Row gutter={12} type="flex">
                                    <Col span={8}>
                                        <Card title="ISN - No Result and Overrides" style={{height: '100%'}}>
                                            <Row type="flex" justify="center">
                                                <IsnNullAndOverride data={reportIsn} />
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="ISN Card Status" style={{height: '100%'}}>
                                            <Row type="flex" justify="center">
                                                <IsnCardStatus persons={reportIsn.map(pax => pax.personID && pax.personID._id).filter(pax => pax)} />
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card title="Companies with no ISN Card" style={{height: '100%'}}>
                                            <div style={{overflow: 'auto', maxHeight: '22rem'}}>
                                                <List dataSource={employers} renderItem={(item, i) => (
                                                    <List.Item key={i}>
                                                        {item.name} ({item.count})
                                                    </List.Item>
                                                )} />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="report" tab="Report" style={{overflow: 'auto'}}>
                                    <ISNTable data={reportIsn} />
                                </Tabs.TabPane>
                            </Tabs>
                        </React.Fragment>
                    } />
                }
                return <ReportNoData />
            }
            catch(error){
                return <ReportError error={error} />
            }
        }
        return null
    }
    resetReport = () => {
        this.setState({ report: null });
        this.props.form.resetFields()
    }
    renderSiderContent = () => {
        const { orgData: {customer, transporter}, form } = this.props;
        const customerID = get(customer, c => c._id);
        return (
        <Query query={FORM_QUERY} variables={{ tpID: transporter._id, customerID}}>
            {({ data, loading, error }) => {
                try{
                    if (loading) return <Spin />
                    if (error) throw Error(error)
                    const { PaxEmployerList, customerList } = data;
                    const employerList = deDupeSort(
                        filterNull(PaxEmployerList),
                        emp => emp._id,
                        emp => emp.name
                    )
                    return (
                        <React.Fragment>
                            <Form onSubmit={this.handleSubmit}>
                                <CustomerFormItem form={form} customerID={customerID} customerList={customerList} />
                                <DateRangeFormItem form={form} />
                                <EmployerFormItem form={form} employerList={employerList} />
                                <GenerateReportFormItem resetReport={this.resetReport} />
                            </Form>
                        </React.Fragment>
                    )
                }
                catch(error){
                    console.error(error)
                    return <Alert type="error" message="An error occurred while creating the form" description={error.message} closable showIcon />
                }
            }}
        </Query>
    )}
    render(){
        const { report } = this.state;
        var csv = false;
        try{
            csv = report.data.reportIsn.length ? true : false
        }
        catch(TypeError){
            csv = false;
        }
        return (
            <ReportLayout 
                title="ISN History Report"
                renderSiderContent={this.renderSiderContent} 
                renderReport={this.renderReport} 
                csv={csv}
                print={csv}/>
        )
    }
}

export default withApollo( WithOrgData( Form.create()(ISNReport) ) )