import { Form, Alert, Button, Col, message, Popconfirm, Row, Table } from 'antd';
import NonPreferredPersonSearch from 'components/search/NonPreferredPersonSearch';
import gql from 'graphql-tag';
import _ from 'lodash';
import compose from 'lodash.flowright';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import commonColumns from '../../../../common/table/columns';
import LoadingContent from '../../../LoadingContent';
import WithOrgData from '../../../WithOrgData';
import GetNonPreferredQuery from "Queries/GetNonPreferred";
import SetNonPreferredMutation from "Mutations/SetNonPreferred";
import {momentOrNull} from "../../../../common/util";

const NON_PREFERRED = gql`
query NonPreferredList($_id: ID!){
    getNonPreferredList(_id: $_id){
        _id
        personIds {
            id {
                _id
                lastName
                firstName
                employerID {
                    _id
                    name
                }
                contactInfo {
                    address {
                        city
                        state
                    }
                }
            }
            startDate
            reason
        }
    }
}
`

const SET_NON_PREFERRED = gql`
mutation SetNonPreferredList(
    $_id: ID
    $customerID: ID!
    $tpID: ID!
    $personIds: [NonPreferredPersonInput]!
){
    setNonPreferred(
        _id: $_id
        customerID: $customerID
        tpID: $tpID
        personIds: $personIds
    ){
        _id
        personIds {
            id {
                _id
                lastName
                firstName
                employerID {
                    _id
                    name
                }
                contactInfo {
                    address {
                        city
                        state
                    }
                }
            }
            reason
            startDate
        }
    }
}
`

class Person extends Component {

    constructor(props) {
        super(props);

        this.npfSearchRef = React.createRef();
    }

    state = {
        personsToAdd: [],
        addingPersons: false,
        selectedPersons: []
    }
    addPersons = () => {

        this.npfSearchRef.current.validateFieldsAndScroll((err, values) => {
            if (err) return;

            const startDate = momentOrNull(values.startDate).format("YYYY-MM-DD");
            const reason = values.reason;

            const { orgData: { customer, transporter }, data: { getNonPreferredList } } = this.props;
            const { personsToAdd } = this.state;
            const prevPersonIds = getNonPreferredList ? getNonPreferredList.personIds : [];
            const personIds = _.union(prevPersonIds.map(per => {
                delete per.__typename
                if(!per.startDate){
                    delete per.startDate
                }
                return {
                    ...per,
                    id: per.id._id
                }
            }), personsToAdd.map(per => {
                if(values.startDate){
                    return {
                        id: per,
                        reason: reason,
                        startDate: startDate
                    }
                }
                return {
                    id: per,
                    reason: reason
                }
            }));

            this.setState({ addingPersons: true });

            this.props.setNonPreferred({
                variables: {
                    _id: customer._id.replace('CUS-', 'NPF-'),
                    customerID: customer._id,
                    tpID: transporter._id,
                    personIds
                }
            })
                .then(() => {
                    message.success(`Successfully added ${personsToAdd.length} personnel to non-preferred list`);
                    this.setState({ personsToAdd: []})
                    this.npfSearchRef.current.resetFields();
                })
                .catch((error) => {
                    console.error(error)
                    message.error("Failed to add personnel to non-preferred list");
                })
                .then(() => this.setState({ addingPersons: false }))
        })
    }
    removePersons = () => {
        const { orgData: { customer, transporter }, data: { getNonPreferredList } } = this.props;
        const { selectedPersons } = this.state;
        const persons = getNonPreferredList.personIds.filter(per => !selectedPersons.includes(per.id._id));
        const personIds = persons.map(per => {
            delete per.__typename
            if(!per.startDate){
                delete per.startDate
            }
            return {
                ...per,
                id: per.id._id
            }
        });

        this.props.setNonPreferred({
            variables: {
                _id: customer._id.replace('CUS-', 'NPF-'),
                customerID: customer._id,
                tpID: transporter._id,
                personIds
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setNonPreferredList: {
                    __typename: 'NonPreferredList',
                    _id: getNonPreferredList._id,
                    personIds: persons
                }
            }
        })
        .then(() => {
            message.success(`Successfully removed ${selectedPersons.length} personnel from non-preferred list`);
            this.setState({ selectedPersons: [] });
        })
        .catch(error => {
            console.error(error);
            message.error('Failed to remove personnel from white list');
        })
    }
    render(){
        const { getNonPreferredList, loading, error } = this.props.data;
        if (loading) return <LoadingContent style={{margin: '10rem 0'}} />
        if (error){
            const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
            if (!notFoundError){
                return <Alert type="error" message="Failed to load non-preferred personnel" description={error.message} showIcon />
            }
        }
        return (
            <Row>
                <Col span={8}>
                <div style={{
                        padding: '12px 0 0 8px',
                        minHeight: '40rem',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                        <Row type="flex" style={{ padding: '0 0.5rem' }}>
                            <Col><h3 style={{ margin: 0 }}>Add Personnel</h3></Col>
                            <div style={{ flex: 1 }} />
                            <Col>
                                <Button
                                    type="primary"
                                    disabled={!this.state.personsToAdd.length}
                                    loading={this.state.addingPersons}
                                    onClick={this.addPersons}
                                >Add to Non-Preferred List</Button>
                            </Col>
                        </Row>
                        <NonPreferredPersonSearch
                            ref={this.npfSearchRef}
                            onSelection={(rows) => this.setState({ personsToAdd: rows })}
                            selectedItems={this.state.personsToAdd}
                            filterResults={(rows) => rows.filter((row) => !((getNonPreferredList && getNonPreferredList.personIds) || []).map(p => p._id).includes(row._id))}
                            style={{ flex: 1, minHeight: 0 }}
                            showCustomerColumn={false}
                        />
                    </div>
                </Col>
                <Col span={16} className="mc-border-left">
                    <div style={{ padding: 24 }}>
                        <Row type="flex" style={{marginBottom: '1rem'}} >
                            <Col style={{flex: 1}}>
                                <h3>Personnel on Non-Preferred List</h3>
                            </Col>
                            <Col>
                                <Popconfirm 
                                    title={`Are you sure you want to remove ${this.state.selectedPersons.length} personnel?`}
                                    okText="Remove"
                                    onConfirm={this.removePersons}
                                    placement="bottomLeft"
                                >
                                    <Button
                                        type="danger"
                                        disabled={this.state.selectedPersons.length ? false : true}
                                    >
                                        Remove {this.state.selectedPersons.length} Personnel
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Table
                            dataSource={getNonPreferredList ? getNonPreferredList.personIds: []}
                            rowKey={record => record.id._id}
                            size="small"
                            className="mc-table"
                            pagination={false}
                            columns={commonColumns.nonPreferred.some(["personName", "employer", "reason", "startDate"])}
                            rowSelection={{
                                selectedRowKeys: this.state.selectedPersons,
                                onChange: (selectedPersons) => this.setState({ selectedPersons })
                            }}
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default compose(
    WithOrgData,
    graphql(
        GetNonPreferredQuery,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'NPF-')
                },
                pollInterval:  15000,
                fetchPolicy: 'network-only'
            })
        }
    ),
    graphql(
        SetNonPreferredMutation,
        {
            name: 'setNonPreferred'
        }
    )
)(Person);