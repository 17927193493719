import { Alert, Badge, Breadcrumb, Button, Card, Col, Modal, Row, Spin, Table } from 'antd';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import gql from 'graphql-tag';
import compose from 'lodash.flowright';
import React from 'react';
import { graphql } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import commonColumns from '../../../common/table/columns';
import LoadingContent from '../../LoadingContent';
import { PobViewerWithData } from '../../PobEditor';
import WithOrgData from '../../WithOrgData';

class RigDetail extends React.Component {
    state = {
        activeTab: 'passengers',
        tabKey: 'onboard',
        editPaxModal: {
            id: null,
            visible: null
        },
        viewPobModal:{
            id: null,
            visible: null
        }
    }
    
    setPaxModal = (visible, id) => {
        this.setState({
            editPaxModal: {
                id: id || this.state.editPaxModal.id,
                visible
            }
        })
    }
    setPobModal = (visible, id) => {
        this.setState({
            viewPobModal: {
                id: id || this.state.viewPobModal.id,
                visible
            }
        })
    }
    componentDidMount(){
        this.props.data.startPolling(5000)
    }
    onTabChange = (activeTab) => this.setState({ activeTab })
    
    renderDesig = () => {
        const { data: { getRig, loading } } = this.props;
        return (loading && !getRig) ? <span><Spin /> Loading</span> : getRig && getRig.locationID.name
    }
    renderPassengers = () => {
        const { data: {getPobNodesOnRig, getPaxNodesToRig, getPaxNodesLeavingRig, loading, error }} = this.props;
            if (loading) return <LoadingContent />
            if (error) return <Alert type="error" message="Failed to load passengers" description={error.message} showIcon />
            const onboardLen = getPobNodesOnRig ? getPobNodesOnRig.length : 0;
            const inboundLen = getPaxNodesToRig ? getPaxNodesToRig.length : 0;
            const outboundLen = getPobNodesOnRig ? getPaxNodesLeavingRig.length : 0;
            
            const renderOnboard = (onboardPob) => (
                <Table
                    dataSource={onboardPob}
                    rowKey={record => record._id}
                    size="middle"
                    pagination={false}
                    columns={[
                            {
                                ...commonColumns.onboardpob.columns.name,
                                render: (_, record) => <Button className="mc-link-btn" onClick={() => this.setPobModal(true, record._id)}>{`${record.lastName}, ${record.firstName}`}</Button>
                            },
                                ...commonColumns.onboardpob.some(['customer', 'employer', 'checkedIn',  'offshoreCheckedIn']),
                    ]}
                    
                />
            )
            const renderInbound = (inboundPax) => (
                <Table
                    dataSource={inboundPax}
                    rowKey={record => record._id}
                    size="middle"
                    pagination={false}
                    columns={commonColumns.onboardpob.all()}
                />
            )
            const renderOutbound = (outboundPob) => (
                <Table
                    dataSource={outboundPob}
                    rowKey={record => record._id}
                    size="middle"
                    pagination={false}
                    columns={commonColumns.onboardpob.all()}
                />
            )
            const tabContent = {
                onboard: renderOnboard(getPobNodesOnRig),
                inbound: renderInbound(getPaxNodesToRig),
                outbound: renderOutbound(getPaxNodesLeavingRig)
            }
            const Count = (props) => <Badge {...props} style={{
                marginBottom: '-0.25rem',
                marginLeft: '0.4rem',
                background: '#1890ff'
            }} />
            
            return (
                <Card
                    tabList={[
                        {
                            key: 'onboard',
                            tab: (
                                <div>
                                    <span>Onboard</span>
                                    <Count count={onboardLen} /> 
                                </div>
                            )
                        },
                        {
                            key: 'inbound',
                            tab: (
                                <div>
                                    <span>Inbound</span>
                                    <Count count={inboundLen} /> 
                                </div>
                            )
                        },
                        {
                            key: 'outbound',
                            tab: (
                                <div>
                                    <span>Outbound</span>
                                    <Count count={outboundLen} /> 
                                </div>
                            )
                        }
                    ]}
                    onTabChange={(tabKey) => this.setState({ tabKey })}
                    activeTabKey={this.state.tabKey}
                    >
                    {tabContent[this.state.tabKey]}
                </Card>
            )
    }

    renderCargo = () => {
        const { data: {getDocksCargoOnRig, getCargoToRig, getCargoLeavingRig, loading, error }} = this.props;
            if (loading) return <LoadingContent />
            if (error) return <Alert type="error" message="Failed to load cargo" description={error.message} showIcon />
            const onboardLen = getDocksCargoOnRig ? getDocksCargoOnRig.length : 0;
            const inboundLen = getCargoToRig ? getCargoToRig.length : 0;
            const outboundLen = getCargoLeavingRig ? getCargoLeavingRig.length : 0;
        
            const renderOutboundCgo = (outboundCgo) => (
                <Table
                    dataSource={outboundCgo}
                    rowKey={record => record._id}
                    size="middle"
                    pagination={false}
                    columns={commonColumns.dockscgo.all()}
                />
            )
            const renderInboundCgo = (inboundCgo) => (
                <Table
                    dataSource={inboundCgo}
                    rowKey={record => record._id}
                    size="middle"
                    pagination={false}
                    columns={commonColumns.dockscgo.all()}
                />
            )
            const renderOnboardCgo = (onboardCgo) => (
                <Table
                    dataSource={onboardCgo}
                    rowKey={record => record._id}
                    size="middle"
                    pagination={false}
                    columns={commonColumns.dockscgo.all()}
                />
            )
            const tabContent = {
                onboard: renderOnboardCgo(getDocksCargoOnRig),
                inbound: renderInboundCgo(getCargoToRig),
                outbound: renderOutboundCgo(getCargoLeavingRig)
            }
            
            const Count = (props) => <Badge {...props} style={{
                marginBottom: '-0.25rem',
                marginLeft: '0.4rem',
                background: '#1890ff'
            }} />
            
            return (
                <Card
                    tabList={[
                        {
                            key: 'onboard',
                            tab: (
                                <div>
                                    <span>Onboard</span>
                                    <Count count={onboardLen} /> 
                                </div>
                            )
                        },
                        {
                            key: 'inbound',
                            tab: (
                                <div>
                                    <span>Inbound</span>
                                    <Count count={inboundLen} /> 
                                </div>
                            )
                        },
                        {
                            key: 'outbound',
                            tab: (
                                <div>
                                    <span>Outbound</span>
                                    <Count count={outboundLen} /> 
                                </div>
                            )
                        }
                    ]}
                    onTabChange={(tabKey) => this.setState({ tabKey })}
                    activeTabKey={this.state.tabKey}
                    >
                    {tabContent[this.state.tabKey]}
                </Card>
            )
    }
    renderContent = () => {
        if(this.state.activeTab === 'passengers'){
            return this.renderPassengers()
        }
        else if (this.state.activeTab === 'cargo'){
            return this.renderCargo()
        } 
    }
    render(){
        return (
            <React.Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/app/pob" >POB</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {this.renderDesig()}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Row style={{margin: '0.5rem 0 1rem 0'}}>
                    <Col span={12}>
                        <h2 style={{margin: 0}}>{this.renderDesig()}</h2>
                    </Col>
                </Row>
                <div>
                    <RadioGroup onChange={(e) => this.onTabChange(e.target.value)} value={this.state.activeTab} buttonStyle="solid">
                        <RadioButton value="passengers">
                            Passengers
                        </RadioButton>
                        <RadioButton value="cargo">
                            Cargo
                        </RadioButton>
                    </RadioGroup>
                </div>
                <div>
                    {this.renderContent()}
                </div>
                <Modal
                    visible={this.state.viewPobModal.visible}
                    onCancel={() => this.setPobModal(false)}
                    width="50rem"
                    title="Pob Information"
                    footer={false}
                >
                    {this.state.viewPobModal.id ? <PobViewerWithData pobID={this.state.viewPobModal.id} /> : null}
                </Modal>
            </React.Fragment>
        )
    }
}

const RIG = gql`
query Rig($_id: ID! $locID: ID!){
    getRig(_id: $_id){
        _id
        locationID {
            _id
            name
        }
    }
    getPobNodesOnRig(_id: $locID){
        _id
        lastName
        firstName
        checkedInTime
        offshoreCheckIn
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        }
    }
    getPaxNodesToRig(destinationID: $locID){
        _id
        lastName
        firstName
        checkedInTime
        departureID {
            _id
            name
        }
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        }
    }
    getPaxNodesLeavingRig(departureID: $locID){
        _id
        lastName
        firstName
        checkedInTime
        departureID {
            _id
            name
        }
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        }
    }
    getDocksCargoOnRig(_id: $locID){
        _id
        assetID {
            _id
            name
        }
        description
        name
        lifts
        liftWeight
        totalWeight
        weight
    }
    getCargoToRig(destinationID: $locID){
        _id
        assetID {
            _id
            name
        }
        description
        name
        lifts
        liftWeight
        totalWeight
        weight
        classType
    }
    getCargoLeavingRig(departureID: $locID){
        _id
        assetID {
            _id
            name
        }
        description
        name
        lifts
        liftWeight
        totalWeight
        weight
        classType
    }
}
`

const RigDetailWithData = compose(
    withRouter,
    WithOrgData,
    graphql(
        RIG,
        {
            options: props => {
                return {
                    variables: {
                        _id: props.match.params.rigid,
                        locID: props.match.params.rigid.replace("RIG-", "LOC-")
                    },
                    fetchPolicy: 'network-only'
                }
            }
        }
    )
)(RigDetail)
export default RigDetailWithData;