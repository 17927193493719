import {useEffect, useReducer} from "react";
import {DEFAULT_STATE, Reducer} from "./state";
import {ScheduleNode} from "../../../../scheduling/types";
import {difference, union} from "lodash";
import {NotesForPilotEditorProps} from "./notes-for-pilot-editor";

export type NotesForPilotEditorApiProps = Pick<NotesForPilotEditorProps, 'overrideState' | 'entities' | 'readOnly'>
export type NotesForPilotEditorApi = ReturnType<typeof useNotesForPilotEditorApi>

export function useNotesForPilotEditorApi(props: NotesForPilotEditorApiProps){

    let [ state, dispatch ] = useReducer(Reducer, DEFAULT_STATE);

    if (props.overrideState){
        state = props.overrideState.state;
        dispatch = props.overrideState.dispatch;
    }

    useEffect(() => {
        dispatch({ type: 'INIT', entities: props.entities });
    }, [props.entities, props.readOnly]);

    const api = {
        state: state,
        dispatch: dispatch,
        readOnly: props.readOnly,
        updateNote: (noteId: number, message: string) => {
            dispatch({
                type: 'UPDATE_NOTE',
                noteId: noteId,
                newMessage: message
            })
        },
        removeNote: (noteId: number) => {
            dispatch({
                type: 'REMOVE_NOTE',
                noteId: noteId
            })
        },
        getNotes: () => state.notes,
        getNoteById: (noteId: number) => state.notes.get(noteId),
        getEntitiesWithNote: (noteId: number) => {
            const entityEntries = Array.from(state.entityIdToNote.entries());
            const entityList: ScheduleNode[] = [];

            for (let [ entityId, entityNoteId ] of entityEntries) {
                if (entityNoteId !== noteId)
                    continue;

                const entity = state.origEntities.get(entityId);
                if (entity){
                    entityList.push(entity);
                }
            }

            return entityList;
        },
        noteAssigner: {
            open: (noteId?: number) => dispatch({ type: 'OPEN_NOTE_ASSIGNER', open: true, noteId }),
            close: () => dispatch({ type: 'OPEN_NOTE_ASSIGNER', open: false }),
            isOpen: () => state.noteAssigner.open
        },
        visibleNotes: {
            get: () => state.visibleNotes as number[],
            set: (keys: number[]) => dispatch({ type: 'SET_VISIBLE_NOTES', noteIds: keys }),
            add: (keys: number[]) => api.visibleNotes.set(union(state.visibleNotes, keys)),
            remove: (keys: number[]) => api.visibleNotes.set(difference(state.visibleNotes, keys)),
            toggle: (key: number) => {
                if (state.visibleNotes.includes(key)){
                    api.visibleNotes.remove([key]);
                    return;
                }
                api.visibleNotes.add([key]);
            }
        },
        getComponentProps: () => {
            return {
                entities: props.entities,
                readOnly: props.readOnly
            } as NotesForPilotEditorProps
        }
    }

    return api;
}