import React from 'react';
import { Spin, Icon, Input, InputNumber } from 'antd';
import DraggableTable from '../../TableDragSorting';
import './SetsTable.less';

const SetsTable = ({
    data,
    isScheduling=false,
    selectedRowKeys,
    isEditing=false,
    previewData=[],
    previewLoading=false,
    onMoveRow=() => null,
    onRowSelectChange=() => null,
    onPersonsToScheduleChange=() => null,
    defaultChargeCode
}) => {
    const filterPreview = (item) => {
        return !data.find(dItem => dItem._id === item._id);
    }
    const mapPreview = item => ({ ...item, preview: true });
    let dataWithPreview = [
        ...data,
        ...previewData.filter(filterPreview).map(mapPreview)
    ]

    const handleInputChange = (person_id, field, value) => {
        const newData = data.map((per) => {
            if (per._id === person_id) {
                return {
                    ...per,
                    [field]: value
                }
            }
            return per
        });
        onPersonsToScheduleChange(newData);
    }

    const renderNumberInput = (personId, field, val) => (
        <InputNumber
            min={0}
            onChange={(value) => handleInputChange(personId, field, value)}
            size="small"
            value={val || 0}
        />
    )

    const columns = [
        {
            title: 'Order',
            key: 'order',
            render: (_, __, index) => {
                return index + 1
            }
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, { lastName, firstName }) => {
                return `${lastName}, ${firstName}`
            }
        },
        {
            title: 'Employer',
            key: 'employer',
            dataIndex: 'employerID',
            render: (obj) => obj && obj.name
        }
    ]
    if (isScheduling){
        columns.push(...[
            {
                title: 'Weight',
                key: 'paxWeight',
                dataIndex: 'paxWeight',
                render: (val, record) => renderNumberInput(record._id, 'paxWeight', val)
            },
            {
                title: 'Bag Weight',
                key: 'bagWeight',
                dataIndex: 'bagWeight',
                render: (val, record) => renderNumberInput(record._id, 'bagWeight', val)
            },
            {
                title: 'Bag Count',
                key: 'bagCount',
                dataIndex: 'bagCount',
                render: (val, record) => renderNumberInput(record._id, 'bagCount', val)
            },
            {
                title: 'Charge Code',
                key: 'chargeCode',
                dataIndex: 'chargeCode',
                render: (val, record) => (
                    <Input
                        onChange={e => handleInputChange(record._id, 'chargeCode', e.target.value && e.target.value.split(" ").join("").toUpperCase())}
                        size="small"
                        value={val}
                        placeholder={defaultChargeCode && defaultChargeCode.toUpperCase()}
                    />
                )
            }
        ])
    }
    return (
        <React.Fragment>
            <DraggableTable
                dataSource={dataWithPreview}
                columns={columns}
                pagination={false}
                size="middle"
                rowKey={record => record._id}
                onRow={(record, index) => (isEditing ? {
                    index,
                    moveRow: onMoveRow,
                    className: record.preview ? "scheduling-sets-table-row-highlighted" : undefined
                } : null)}
                rowSelection={isEditing && previewData.length <= 0 ? {
                    selectedRowKeys: selectedRowKeys,
                    onChange: onRowSelectChange
                } : null}
            />
            {previewLoading ? (
                <div style={{ textAlign: 'center', marginTop: 12 }}>
                    <Spin indicator={<Icon type="loading" spin />} />
                </div>
            ) : null}
        </React.Fragment>
    )
}

export default SetsTable