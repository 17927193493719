import {Location, LocationType} from "../types/location";
import {TransitType} from "../types/carriable";

/**
 * Returns the transit type given the departure and destination.
 * Reads the "type" field specifically ot infer the transitType.
 */
export function getTransitType(departure: Location, destination: Location) {
    if (departure.type === destination.type)
        return TransitType.TRANSFER

    if (departure.type === LocationType.ONSHORE && destination.type === LocationType.OFFSHORE) {
        return TransitType.OUTBOUND;
    }

    return TransitType.INBOUND;
}