import React, {useReducer} from 'react';
import {produce} from "immer";
import {Moment} from "moment";
import moment from "moment-timezone";

export const DefaultState = {
    endDate: moment().startOf('month').subtract(1, 'day'),
    startDate: moment().subtract(1, 'month').startOf('month'),
    searchValue: ''
}

export type State = typeof DefaultState;

export type Action = {
    type: 'SET_START_DATE',
    payload: Moment
} | {
    type: 'SET_END_DATE',
    payload: Moment
} | {
    type: 'SET_SEARCH',
    payload: string
}

const Reducer: React.Reducer<State, Action> = (state, action) => {
    return produce(state, (draft) => {
        switch (action.type){
            case "SET_START_DATE":
                draft.startDate = action.payload;
                if (state.endDate.isBefore(draft.startDate)){
                    draft.endDate = state.startDate.clone().add(1, 'day');
                }
                if (draft.startDate.isAfter(moment().endOf('day'))){
                    draft.startDate = moment();
                }
                break;
            case "SET_END_DATE":
                draft.endDate = action.payload;
                if (state.startDate.isAfter(draft.endDate)){
                    draft.startDate = state.endDate.clone().subtract(1, 'day');
                }
                if (draft.endDate.isAfter(moment().endOf('day'))){
                    draft.endDate = moment();
                }
                break;
            case "SET_SEARCH":
                draft.searchValue = action.payload;
                break;
        }
    })
}

export function usePhase1HistoryState(){
    const [ state, dispatch ] = useReducer(Reducer, DefaultState);

    const startDate = state.startDate.clone();
    const endDate = state.endDate.clone();

    return {
        dateRange: {
            get: () => [state.startDate.clone(), state.endDate.clone()] as [startDate: Moment, endDate: Moment],
            start: () => startDate.clone(),
            end: () => endDate.clone(),
            set: (range: [startDate: Moment, endDate: Moment]) => {
                dispatch({ type: 'SET_START_DATE', payload: range[0] });
                dispatch({ type: 'SET_END_DATE', payload: range[1] });
            },
            prevMonth: () => {
                dispatch({
                    type: 'SET_START_DATE',
                    payload: startDate.clone().subtract(1, 'month').startOf('month')
                });
                dispatch({
                    type: 'SET_END_DATE',
                    payload: endDate.clone().subtract(1, 'month').endOf('month')
                });
            },
            nextMonth: () => {
                dispatch({
                    type: 'SET_START_DATE',
                    payload: startDate.clone().add(1, 'month').startOf('month')
                });
                dispatch({
                    type: 'SET_END_DATE',
                    payload: endDate.clone().add(1, 'month').endOf('month')
                });
            }
        },
        searchValue: {
            get: () => state.searchValue,
            set: (value: string) => dispatch({ type: 'SET_SEARCH', payload: value })
        },
    }
}