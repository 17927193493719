import { Button, DatePicker, Form, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { createFormFields, FormField, tailFormItemLayout } from 'common/form';
import { OrganizationSelect } from 'components/form/select/OrganizationSelect';
import TimezonePicker from 'components/form/select/timezone-picker';
import moment from 'moment-timezone';
import React from 'react';

export interface FWBRCriteriaFormProps extends FormComponentProps {
    formFields?: { [key: string]: any },
    onFieldsChange?: (fields: { [key: string]: FormField }, changedFields: { [key: string]: FormField }) => void
}

export const fieldLabelMap = {
    "startDate": "Starting Date",
    "endDate": "Ending Date",
    "tz": "Time Zone",
    "aircraftID": "Aircraft",
    "pilotID": "Pilot",
    "departingID": "Departing",
    "destinationID": "Destination",
    "contractID": "Contract",
    "groupAndSort": "Group and sort by",
    "reportType": "Report Type",
    "transporter": "Transporter"
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

export const GroupAndSortMap = {
    contract: "contract",
    aircraft: "aircraft",
    // timeOff: "timeOff"
}

export const ReportTypeMap = {
    summary: "summary",
    detail: "detail"
}

const FWBRCriteriaForm_Internal: React.FC<FWBRCriteriaFormProps> = (props) => {
    let { form: { getFieldDecorator, getFieldValue, setFieldsValue } } = props;

    function thisweek(){
        setFieldsValue({
            startDate: moment().subtract(1, 'week'),
            endDate: moment()
        })
    }

    return <Form>
        <Form.Item label={fieldLabelMap.transporter} className="ant-form-item-no-margin" {...formItemLayout}>
            {getFieldDecorator('transporter', {
                rules: [ { required: true, message: 'Transporter is required' } ]
            })(
                <OrganizationSelect labelInValue classTypes={['flytsuite.transporter']} />
            )}
        </Form.Item>
        <Form.Item label={fieldLabelMap.startDate} className="ant-form-item-no-margin" {...formItemLayout}>
            {getFieldDecorator('startDate', {
                rules: [ { required: true, message: 'Start date is required' } ],
                initialValue: moment().subtract(1, 'week')
            })(
                <DatePicker
                    size="default"
                />
            )}
        </Form.Item>
        <Form.Item label={fieldLabelMap.endDate} className="ant-form-item-no-margin" {...formItemLayout}>
            {getFieldDecorator('endDate', {
                rules: [ { required: true, message: 'Ending date is required' } ],
                initialValue: moment()
            })(
                <DatePicker
                    size="default"
                />
            )}
        </Form.Item>
        <Form.Item label={fieldLabelMap.tz} className="ant-form-item-no-margin" {...formItemLayout}>
            {getFieldDecorator('tz', {
                rules: [ { required: true, message: 'Time zone is required' } ],
                initialValue: moment.tz.guess() || "America/Chicago"
            })(
                <TimezonePicker date={moment(getFieldValue('startDate')?.date)?.utc().format()} noDST />
            )}
        </Form.Item>
        <Form.Item className="ant-form-item-no-margin" {...tailFormItemLayout}>
            <Tooltip title="Set Starting Date and Ending Date to this past week">
                <Button size="small" shape="round" onClick={thisweek}>This week</Button>
            </Tooltip>
        </Form.Item>
    </Form>
}

FWBRCriteriaForm_Internal.displayName = "FWBRCriteriaForm"

const FWBRCriteriaForm = Form.create<FWBRCriteriaFormProps>({
    mapPropsToFields(props){
        return createFormFields(props.formFields)
    },
    onFieldsChange(props, changedFields, allFields){
        props.onFieldsChange(allFields, changedFields);
    }
})(FWBRCriteriaForm_Internal);

export default FWBRCriteriaForm