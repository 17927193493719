import React, { Component } from 'react';
import { Form, Input, DatePicker, Button } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
    style: {
        margin: 0
    }
};

class EditForm extends Component {
    render() {
        const { dateFormat="YYYY-MM-DD", hasKey=true, hasExpiration=true, needsButton=false } = this.props;
        const { getFieldDecorator, setFieldsValue, getFieldValue } = this.props.form;
        return (
            <Form>
                {hasKey ? <Form.Item label="Key" {...formItemLayout}>
                {getFieldDecorator('key')(<Input />)}
                </Form.Item> : null}
                {hasExpiration ? <Form.Item label="Expiration" {...formItemLayout}>
                {getFieldDecorator('expiration')(<DatePicker format={dateFormat} />)}
                </Form.Item> : null}
                {needsButton ? <Button onClick={() => {
                        var yr = moment(getFieldValue('expiration')).add(1, 'year')
                        setFieldsValue({'expiration': yr})
                    }}>Add 1 Year</Button> : null}
            </Form>
        );
    }
}

const WithForm = Form.create({
    mapPropsToFields: ({ credKey, expiration }) => ({
        key: credKey && Form.createFormField({
            ...credKey,
            value: credKey.value
        }),
        expiration: expiration && Form.createFormField({
            ...credKey,
            value: expiration.value
        })
    }),
    onFieldsChange(props, changedFields){
        if (props.onChange)
            props.onChange(changedFields)
    }
})(EditForm);

WithForm.propTypes = {
    fields: PropTypes.object,
    credKey: PropTypes.object,
    expiration: PropTypes.object,
    onChange: PropTypes.func,
    hasKey: PropTypes.bool,
    hasExpiration: PropTypes.bool,
    needsButton: PropTypes.bool
}
 
export default WithForm;