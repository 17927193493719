import { Button, DatePicker, Dropdown, Input, Menu, Popconfirm, Tooltip } from 'antd';
import Form from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import DateLabelSelect from 'components/covid-vax-date-form/date-label-select';
import { PersonCovidVaxRecord } from 'components/covid-vax-date-list';
import CovidVaxManufacturerSelect from 'components/covid-vax-manufacturer-select';
import { OrgDataContext } from 'context/orgData';
import { ThemeContext } from 'context/theme';
import { pickBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import uuid4 from 'uuid/v4';
import './style.less';

export interface UniquePersonCovidVaxRecord extends Omit<PersonCovidVaxRecord, 'personID' | 'personName'> {
    id: string,
    isNew: boolean
}

export function makePersonCovidVaxRecordUnique(record: PersonCovidVaxRecord): UniquePersonCovidVaxRecord {
    return {
        ...record,
        isNew: false,
        id: record.personID + '-' + record.order
    }
}

export interface CovidVaxDatesFormProps {
    form: WrappedFormUtils,
    data?: PersonCovidVaxRecord[],
    disabled?: boolean,
    allowRemoveOrClearRecord?: boolean,
    resetFormFunc?: (resetFunc: () => void) => void
}

function validateDuplicateLabel(recordId: string, form: WrappedFormUtils){
    return (_, __, callback) => {
        let allFields = form.getFieldsValue();
        let currLabel = allFields['covidvax::' + recordId + '::label'];
        delete allFields['covidvax::' + recordId + '::label'];

        let duplicateDateTimes = Object.entries(allFields)
            .filter(([k]) => k.split('::')[2] === 'label')
            .map(([k, v]) => {
                let id = k.split('::')[1];
                return {
                    id,
                    label: v
                }
            })
            .filter(({ id, label }) => {
                return currLabel && id !== recordId && label === currLabel
            })
        
        if (duplicateDateTimes.length > 0){
            callback("You cannot have two entries with the same label.")
        }
        else
        {
            callback();
        }
    }
}

function requireIfNonEmpty(recordId: string, form: WrappedFormUtils){
    return (rule, value, callback) => {
        let prefix = 'covidvax::' + recordId
        if (typeof value === 'string'){
            value = value.trim();
        }
        if (
            rule.required && !value && (
                form.getFieldValue(prefix + '::date') ||
                form.getFieldValue(prefix + '::type') ||
                form.getFieldValue(prefix + '::comment')
            )
        ){
            callback(rule.message || 'This field is required')
        }
        else
        {
            callback();
        }
    }
}

export function sortVaxRecordsByDate(vaxRecords: UniquePersonCovidVaxRecord[], form: WrappedFormUtils){
    let newVaxRecords = vaxRecords.sort((a, b) => {
        let vaxOn = "Vaccinated on";

        let aDate = form.getFieldValue('covidvax::' + a.id + '::date');
        let bDate = form.getFieldValue('covidvax::' + b.id + '::date');

        // It is likely that two rows, one with "Shot 2", and the other with "Vaccinated on", will have the same date.
        // So, we need to make sure that "Vaccinated on" will sort after "Shot 2"
        let aLabel = form.getFieldValue('covidvax::' + a.id + '::label');
        let bLabel = form.getFieldValue('covidvax::' + b.id + '::label');

        if (aDate) aDate = aDate && moment(aDate).format('YYYY-MM-DD');
        if (bDate) bDate = bDate && moment(bDate).format('YYYY-MM-DD');

        if (aDate && !bDate) return -1;
        if (!aDate && bDate) return 1;

        if (aDate > bDate) return 1;
        if (aDate < bDate) return -1;

        if (aDate === bDate && aLabel === vaxOn && String(bLabel).startsWith("Shot")){
            return 1;
        }

        if (aDate === bDate && bLabel === vaxOn && String(aLabel).startsWith("Shot")){
            return -1;
        }
        
        return 0;
    })
    return newVaxRecords
}

// List of all labels that do not require a manufacturer to be entered
const noManuLabels = [
    'POS Test',
    'RTW',
    'RTW 2'
]

const CovidVaxDatesForm: React.FC<CovidVaxDatesFormProps> = ({
    form,
    data,
    disabled=false,
    allowRemoveOrClearRecord=true,
    resetFormFunc
}) => {

    const theme = React.useContext(ThemeContext);
    const orgData = React.useContext(OrgDataContext);

    // Stores the initial values of vax record rows
    let [ initialVaxRecords, setInitialVaxRecords ] = useState<UniquePersonCovidVaxRecord[]>([])


    let [ , setUpdateId ] = useState<string>(''); // Need this because sorting the initialVaxRecords list doesn't trigger a re-render as expected.

    function sortRecords(){
        let newVaxRecords = sortVaxRecordsByDate(initialVaxRecords, form);
        setInitialVaxRecords(newVaxRecords)
        setUpdateId(uuid4());
    }

    function swapRecords(idxA: number, idxB: number){
        let a = initialVaxRecords[idxA];
        let b = initialVaxRecords[idxB];

        let newInitialVaxRecords = [...initialVaxRecords];

        newInitialVaxRecords[idxA] = b;
        newInitialVaxRecords[idxB] = a;

        setInitialVaxRecords(newInitialVaxRecords);
    }

    /*
      WARNING: If the data prop changes, the initialVaxRecords will be updated
    */
    useEffect(() => {
        // --------------------------------------
        // Populates initialVaxRecords with existing data passed in from props. Fills in the remaining 5 rows with empty rows.
        // --------------------------------------

        let dataLength = data ? data.length : 0

        let newVaxRecords: UniquePersonCovidVaxRecord[] = [];
        if (dataLength > 0){
            newVaxRecords = data.map((record, i) => {
                return {
                    ...record,
                    id: record.personID + '-' + record.order,
                    order: i,
                    isNew: false
                }
            });
        }
        let numNewRows = Math.max(5-dataLength, 0);

        // Add empty rows for the remaining numNewRows
        for (let i=0; i < numNewRows; i++){
            let id = "new-" + i;
            newVaxRecords.push({
                id: id,
                comment: null,
                date: null,
                label: null,
                order: null,
                customerID: orgData.customerID,
                customerName: orgData.customerName,
                employerID: orgData.employerID,
                employerName: orgData.employerName,
                manufacturer: null,
                isNew: true
            })
        }

        setInitialVaxRecords(newVaxRecords);
        setUpdateId(uuid4());
    // eslint-disable-next-line
    }, [ data ])

    // This allows parent components to trigger a reset of 
    useEffect(() => {

        function reset(){
            form.resetFields();
            sortRecords();
        }

        if (resetFormFunc){
            resetFormFunc(reset);
        }
    // eslint-disable-next-line   
    }, [ resetFormFunc ])

    function getValuedRowCount(){
        let allFields = form.getFieldsValue();

        // List of booleans for each row whether or not it is not empty.
        let initialVaxRecordsHaveValue = initialVaxRecords.map(record => {
            let recordFieldMap = pickBy(allFields, (_, key) => String(key).startsWith("covidvax::" + record.id) &&
                !key.endsWith('renderOrder') //Skips renderOrder field because this will always have a value
            );
            return Object.values(recordFieldMap).findIndex((val) => val) > -1
        })
        
        let numVaxRecordsWithValue = initialVaxRecordsHaveValue.reduce((acc, hasVal) => hasVal === true ? acc + 1 : acc, 0);
        return numVaxRecordsWithValue;
    }

    function checkHasValue(recordId: string){
        if (!recordId) return false;
        let fieldNames = [
            'covidvax::' + recordId + "::date",
            'covidvax::' + recordId + "::comment",
            'covidvax::' + recordId + "::manufacturer",
            'covidvax::' + recordId + "::label"
        ]
        return Object.values(form.getFieldsValue(fieldNames)).find(val => val) ? true : false
    }
    
    function renderFormItem(record: UniquePersonCovidVaxRecord, index: number){
        let id = record.id;

        let fieldNames = [
            'covidvax::' + id + "::date",
            'covidvax::' + id + "::comment",
            'covidvax::' + id + "::manufacturer",
            'covidvax::' + id + "::label"
        ]

        let isTouched = form.isFieldsTouched(fieldNames);

        let hasValue = checkHasValue(record.id);

        function reset(){
            form.setFieldsValue({
                ['covidvax::' + id + "::comment"]: record.comment,
                ['covidvax::' + id + "::manufacturer"]: record.manufacturer,
                ['covidvax::' + id + "::label"]: record.label,
                ['covidvax::' + id + "::date"]: record.date ? moment(record.date) : null
            });
        }

        function remove(){
            let newVaxRecords = [ ...initialVaxRecords ];
            newVaxRecords.splice(index, 1);
            setInitialVaxRecords(newVaxRecords);
        }

        function clear(){
            let val = { value: null, touched: true }
            form.setFields({
                ['covidvax::' + id + "::comment"]: val,
                ['covidvax::' + id + "::manufacturer"]: val,
                ['covidvax::' + id + "::date"]: val,
                ['covidvax::' + id + "::label"]: val
            });
        }

        let menuItems = [];

        if (isTouched){
            menuItems.push(<Menu.Item key="reset">Reset</Menu.Item>)
        }
        if (!disabled && allowRemoveOrClearRecord && index >= 5) {
            menuItems.push(<Menu.Item key="remove">Remove</Menu.Item>)
        }
        if (!disabled && allowRemoveOrClearRecord && hasValue && !record.isNew){
            menuItems.push(<Menu.Item key="clear">Clear</Menu.Item>)
        }

        return <div key={index} className="mc-covid-vax-dates-form-item">
            {form.getFieldDecorator('covidvax::' + id + '::renderOrder', {
                initialValue: index,
                preserve: false
            })}
            <Form.Item label="Label">
                {form.getFieldDecorator('covidvax::' + id + "::label", {
                    initialValue: record.label,
                    preserve: false,
                    rules: [
                        {
                            validator: validateDuplicateLabel(record.id, form)
                        },
                        { 
                            required: true,
                            message: 'You must specify a label',
                            validator: requireIfNonEmpty(record.id, form)
                        }
                    ]
                })(
                    <DateLabelSelect style={{ width: '6rem' }} dropdownMatchSelectWidth={false} disabled={disabled} />
                )}
            </Form.Item>
            <Form.Item label="Date">
                {form.getFieldDecorator('covidvax::' + id + "::date", {
                    rules: [
                        {
                            required: true,
                            message: 'You must specify a date',
                            validator: requireIfNonEmpty(record.id, form)
                        }
                    ],  
                    preserve: false,
                    initialValue: record.date ? moment(record.date) : null
                })(
                    <DatePicker
                        placeholder="YYYY-MM-DD"
                        disabled={disabled}
                    />
                )}
            </Form.Item>
            <Form.Item label="Type">
                {noManuLabels.includes(form.getFieldValue('covidvax::' + id + '::label')) ? (
                    <CovidVaxManufacturerSelect style={{ width: '10rem', opacity: 0.5 }} allowClear disabled placeholder={null} value="N/A" />
                ) : (
                    form.getFieldDecorator('covidvax::' + id + "::manufacturer", {
                        initialValue: record.manufacturer,
                        preserve: false,
                        rules: [ { required: true, message: 'You must specify a type', validator: requireIfNonEmpty(record.id, form) } ]
                    })(
                        <CovidVaxManufacturerSelect style={{ width: '10rem' }} allowClear disabled={disabled} />
                    )
                )}
            </Form.Item>
            <Form.Item label="Comment" className="mc-covid-vax-dates-form-item-with-clear">
                {form.getFieldDecorator('covidvax::' + id + "::comment", {
                    initialValue: record.comment,
                    preserve: false
                })(
                    <Input max={100} disabled={disabled} />
                )}
                {!disabled && allowRemoveOrClearRecord ? (

                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu onClick={({ key }) => {
                                switch (key) {
                                    case 'swap-up':
                                        swapRecords(index, index-1);
                                        break;
                                    case 'swap-down':
                                        swapRecords(index, index+1);
                                        break;
                                    case 'reset':
                                        reset();
                                        break;
                                    case 'remove':
                                        remove();
                                        break;
                                    case 'clear':
                                        clear();
                                        break;
                                    default:
                                        break;
                                }
                            }}>
                                <Menu.Item key="swap-up" disabled={index < 1}>
                                    Swap with above
                                </Menu.Item>
                                <Menu.Item key="swap-down" disabled={index >= dataToRender.length-1}>
                                    Swap with below
                                </Menu.Item>
                                {menuItems.length > 0 ? (
                                    <Menu.Divider />
                                ) : null}
                                {menuItems}
                            </Menu>
                        }
                    >
                        <Button
                            icon="more"
                            className="mc-btn-transparent"
                            style={{ marginLeft: '6px' }}
                        />
                    </Dropdown>
                ) : null}
                {(isTouched) ? (
                    <Tooltip mouseEnterDelay={1} title="Reset">
                        <Button
                            ghost={theme.themeName === "dark"}
                            style={{
                                marginLeft: '6px'
                            }}
                            type="danger"
                            shape="circle"
                            icon="rollback"
                            size="small"
                            onClick={reset}
                        />
                    </Tooltip>
                ) : null}
                {(!disabled && allowRemoveOrClearRecord && hasValue && !record.isNew) ? (
                    <Tooltip mouseEnterDelay={1} title="Clear">
                        <Button
                            ghost={theme.themeName === "dark"}
                            style={{
                                marginLeft: '6px'
                            }}
                            type="danger"
                            shape="circle"
                            icon="close"
                            size="small"
                            onClick={clear}
                        />
                    </Tooltip>
                ) : null}
                {!disabled && allowRemoveOrClearRecord && index >= 5 ? (
                    <Tooltip mouseEnterDelay={1} title="Remove">
                        <Popconfirm
                            title="Are you sure you want to remove this row?"
                            okButtonProps={{
                                type: 'danger'
                            }}
                            onConfirm={remove}
                        >
                            <Button
                                type="danger"
                                ghost={theme.themeName === "dark"}
                                shape="circle"
                                icon="delete"
                                size="small"
                                style={{ marginLeft: '6px' }}
                            />
                        </Popconfirm>
                    </Tooltip>
                ) : null}
            </Form.Item>
        </div>
    }

    let dataToRender: UniquePersonCovidVaxRecord[] = [ ...initialVaxRecords ]; // Copy initialVaxRecords array

    let renderedFormItems = dataToRender.map(renderFormItem);

    return (
        <div className="mc-covid-vax-dates-form">
            {!disabled ? (
                <Tooltip placement="right" title="Sort in ascending order by date">    
                    <Button
                        shape="round"
                        icon="sort-ascending"
                        size="small"
                        style={{ marginBottom: '6px' }}
                        onClick={sortRecords}
                    >
                        Sort By Date
                    </Button>
                </Tooltip>
            ) : null}
            {renderedFormItems}
            {getValuedRowCount() >= 5 && !disabled ? (   
                <Button
                    type="primary"
                    icon="add"
                    shape="round"
                    style={{ marginTop: '6px' }}
                    onClick={() => {
                        let newRecord = {
                            id: 'new-' + initialVaxRecords.length,
                            comment: null,
                            date: null,
                            label: null,
                            order: initialVaxRecords.length,
                            customerID: orgData.customerID,
                            customerName: orgData.customerName,
                            employerID: orgData.employerID,
                            employerName: orgData.employerName,
                            manufacturer: null,
                            isNew: true
                        }
                        setInitialVaxRecords([
                            ...initialVaxRecords,
                            newRecord
                        ])
                    }}
                >
                    Add Another
                </Button>
            ) : null}
        </div>
    )
}

export default CovidVaxDatesForm;