import React, { useContext } from 'react';
// import { connect } from 'react-redux';
import { Select } from 'antd';
// import { selectTransporter } from '../actions/index';
// import config from '../config';
// import { API, graphqlOperation } from 'aws-amplify';
// import { getUniqueTransporters, getTransporter, setTpQueryRoute } from '../util';
// import queryString from 'qs';
// import { withRouter } from 'react-router-dom';

import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import withAppLoaded from '../components/WithAppLoaded';
import { withRouter } from 'react-router-dom';
import { GlobalAppStateContext } from 'context/global-app-state';

const Option = Select.Option;

// const getTransporterQuery = `query GetTransporter($id: ID!){
//     getTransporter(_id: $id){
//         _id, name
//     }
// }`

const TP_QUERY = gql`
query tpList{
    tpList @client{
        _id
        name
    }
    transporter @client {
        _id
        name
    }
    customer @client{
        _id
        name
    }
}
`

const TP_SELECTED_MUTATION = gql`
mutation transporterMutation($id: ID!){
    transporter(id: $id) @client{
        _id
        name
    }
}
`

// class TpSelect extends React.Component {
//     constructor(props){
//         super(props);
//         this.state = {
//             tpSelectedID: null
//         }
//     }
//     render(){
//         <Mutation mutation={tpSelectedMutation} variables={{props}}>
        
//         </Mutation>
//     }
// }

const TransporterSelector = (props) => {

    const { setUserSettings, getUserSettings } = useContext(GlobalAppStateContext);

    const featureName = 'generalSettings';
    let settings = getUserSettings(featureName);

    function setSavedTp(id){
        setUserSettings(featureName, { ...settings, savedTpId: id });
    }

    const { pathname } = props.location;
    if (!pathname.startsWith('/app')) return null
    return (
        <Query query={TP_QUERY}>
            {
                ({loading, error, data}) => {
                    if (loading) return "Loading...";
                    if (error) {console.error(error); return null}
                    const {transporter, tpList} = data;
                    if (!transporter) return null

                    return (
                        <Mutation
                            mutation={TP_SELECTED_MUTATION}
                        >
                            {
                                (mutate) => {
                                    return <Select
                                    {...props}
                                    value={transporter._id}
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={(id) => { mutate({variables: {id}}); setSavedTp(id); }}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    showAction={["focus", "click"]}
                                    >
                                        {tpList.map((t, i) => (<Option key={t._id} value={t._id}>{t.name}</Option>))}
                                    </Select>
                                }
                            }
                        </Mutation>
                    )
                }
            }
        </Query>
    )
}

export default withRouter( withAppLoaded( TransporterSelector ) )