import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { cleanGraphQLErrorMsg } from "common/util";
import { AuthContext } from "context/auth";
import gql from "graphql-tag";
import { useContext } from "react";

const MUTATION = gql`
mutation SendFeedback(
    $type: String!
    $responseType: String!
    $expected: String
    $actual: String
    $description: String
    $responseEmail: AWSEmail
    $responsePhone: AWSPhone
    $attachmentKeyList: [String!]
    $jobPosition: String
    $mood: String
    $appUrlPath: String
){
    SendUserFeedback(
        type: $type
        responseType: $responseType
        expected: $expected
        actual: $actual
        description: $description
        responseEmail: $responseEmail
        responsePhone: $responsePhone
        attachmentKeyList: $attachmentKeyList
        jobPosition: $jobPosition
        mood: $mood
        appUrlPath: $appUrlPath
        appName: "Manifest Central"
    )
}
`

// Response types
export interface PhoneResponse {
    responseType: 'phone',
    responsePhone: string
}

export interface EmailResponse {
    responseType: 'email',
    responseEmail: string
}

export interface BugInput {
    type: 'bug',
    expected: string,
    actual: string
}

export interface OutageInput {
    type: 'outage',
    description: string
}

export interface GeneralFeedbackInput {
    type: 'generalfeedback',
    mood: string,
    description: string
}

export interface FeatureRequestInput {
    type: 'featurerequest',
    description: string
}

export interface BaseFeedbackInput {
    jobPosition?: string,
    attachments: Array<UploadFile>
}

export type FeedbackInput = (PhoneResponse | EmailResponse) & (BugInput | OutageInput | GeneralFeedbackInput | FeatureRequestInput) & BaseFeedbackInput

export interface FeedBackSubmitterOptions {
    onSubmitted?: () => void
}

export function useFeedbackSubmitter(options?: FeedBackSubmitterOptions): [(input: FeedbackInput) => void, { loading: boolean }] {
    const { user } = useContext(AuthContext);
    const [ invokeMutation, { loading } ] = useMutation(MUTATION, {
        onCompleted: () => options.onSubmitted?.(),
        onError: (error) => message.error("Failed to submit feedback. " + cleanGraphQLErrorMsg(error), 5)
    });

    function handleSubmit(input: FeedbackInput){
        let vars = { ...input }
        let attachments = input.attachments;
        delete vars.attachments;


        invokeMutation({
            variables: {
                ...vars,
                appUrlPath: window.location.href,
                attachmentKeyList: attachments && attachments.map(file => `/${user.username}/${file.name}`)
            },
        })
    }

    return [
        handleSubmit,
        {
            loading: loading
        }
    ]
}