import {Alert, Button, Col, DatePicker, Icon, Input, Layout, Row, Spin, Tooltip} from 'antd';
import {castArray} from 'common/util';
import MainHeader from 'components/scheduling/MainHeader';
import MainLayout from 'components/scheduling/MainLayout';
import {WithOrgDataProps} from 'components/WithOrgData';
import moment from 'moment';
import React, {useContext} from 'react';
import {useQuery} from 'react-apollo';
import {OrgDataContext} from "context/orgData";
import {Queries, QueryTypes} from "./graphql";
import {usePhase1HistoryState} from "./state";
import './style.less';
import {useHistory} from "react-router-dom";
import {QueryListScheduleHistoryArgs, ScheduleHistoryListItem} from "../../../../__generated__/graphql/types";
import {ScheduleHistoryListItemFragment} from "../../../../Queries/Scheduling";
import ScheduleHistoryList from "./list";

const basePath = (path) => `/app/scheduling${path ? `/${path}` : ''}`

const BASE_CLS = "mc-phase-1-history";

const { Content } = Layout;
const { RangePicker } = DatePicker;

export interface ScheduledGroupsListProps extends WithOrgDataProps {
    onGroupClicked?(group: { date: string, name: string, lkc: string, departureID: string, destinationID: string })
}

function formatDate(date: moment.Moment){
    return date.format('YYYY-MM-DD')
}

const Phase1HistoryPage: React.FC<ScheduledGroupsListProps> = (props) => {

    const routeHistory = useHistory();

    function handleGroupClicked(group: ScheduleHistoryListItem){

        const date = encodeURIComponent(group.date);
        const lkc = encodeURIComponent(group.lastKnownController);
        const name = encodeURIComponent(group.groupName);

        routeHistory.push(basePath(`history/${date}/${name}/${lkc}`));
    }

    const state = usePhase1HistoryState();
    const orgData = useContext(OrgDataContext);

    const startDate = formatDate(state.dateRange.start());
    const endDate = formatDate(state.dateRange.end());

    const { loading, error, data: queryData } = useQuery<
        QueryTypes['ListScheduleHistory']['data'],
        QueryTypes['ListScheduleHistory']['args']
    >(Queries.ListScheduleHistory, {
        fetchPolicy: 'network-only',
        pollInterval: 10000,
        variables: {
            tpID: orgData.getOrgIDByType('transporter'),
            startDate: startDate,
            endDate: endDate
        }
    })

    let data: ScheduleHistoryListItem[] = castArray(queryData?.ListScheduleHistory);

    if (state.searchValue.get().length > 0){
        data = data.filter((item) => item.groupName.toLowerCase().includes(state.searchValue.get().toLowerCase().trim()))
    }

    const datePicker = (
        <Row type="flex" style={{ flexWrap: 'nowrap', alignItems: 'center' }}>
            <Col>
                <Tooltip placement="bottom" title="Previous month" mouseEnterDelay={1}>
                    <Button
                        icon="left"
                        style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                        }}
                        onClick={state.dateRange.prevMonth}
                    />
                </Tooltip>
            </Col>
            <Col>
                <RangePicker
                    value={state.dateRange.get()}
                    onChange={(value) => state.dateRange.set([value[0], value[1]])}
                    disabledDate={(date) => date.isAfter(moment().startOf('day'))}
                    ranges={{
                        'Last Month': [
                            moment().subtract(1, 'month').startOf('month'),
                            moment().startOf('month').subtract(1, 'day')
                        ]
                    }}
                    className={BASE_CLS + '-daterange'}
                    allowClear={false}
                />
            </Col>
            <Col>
                <Tooltip placement="bottom" title="Next month" mouseEnterDelay={1}>
                    <Button
                        icon="right"
                        disabled={state.dateRange.end().endOf('month').isAfter(moment())}
                        style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                        }}
                        onClick={state.dateRange.nextMonth}
                    />
                </Tooltip>
            </Col>
        </Row>
    )

    return <MainLayout>
        <MainHeader
            routes={[
                {
                    path: '/app/scheduling/history',
                    breadcrumbName: 'Historical Scheduled Passengers and Cargo'
                }
            ]}
            actions={
                <Row type="flex" gutter={12} align="middle" style={{ maxWidth: 500 }}>
                    <Col style={{ flex: 1.5 }}>
                        {datePicker}
                    </Col>
                    <Col style={{ flex: 1, minWidth: 160 }}>
                        <Input
                            placeholder="Search schedules"
                            prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            value={state.searchValue.get()}
                            onChange={(e) => state.searchValue.set(e.target.value)}
                            allowClear
                        />
                    </Col>
                </Row>
            }
        />
        <Content>
            {error ? <Alert
                message="Failed to load scheduled groups"
                description={error.message}
                type="error"
                showIcon
                banner
            /> : null}
            <div className="ant-spin-fill-height">
                <Spin spinning={loading} size="large" indicator={<Icon type="loading" />}>
                    <div style={{ padding: '0 1rem' }}>
                        <ScheduleHistoryList
                            data={data}
                            onGroupClicked={handleGroupClicked}
                        />
                    </div>
                </Spin>
            </div>
        </Content>
    </MainLayout>
}

export default Phase1HistoryPage