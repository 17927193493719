import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QmAlert from '../qmAlert';
import { Tooltip } from 'antd';

export interface OffshoreLocListProps {
    data: any[]
    style?: React.CSSProperties
    onOffshoreLocClick?: (id: string, name: string, classType: string) => void,
    selectedID?: string
}


const OffshoreLocList: React.FC<OffshoreLocListProps> = (props) => {
    return (
        <div className="dashboard" style={props.style}>
            <dl className="list-group bordered">
                <dt className="list-header"><span className="title">Offshore Location</span>
                    <div className="destinfowrap">
                        <span className="mc-qm-loc-list-header mc-qm-loc-list-header-onboard">
                            <Tooltip title="Onboard Passengers">
                                <FontAwesomeIcon icon={["fas", "users"]} />
                            </Tooltip>
                        </span>
                        <span className="mc-qm-loc-list-header mc-qm-loc-list-header-bed">
                            <Tooltip title="Available Beds">
                                <FontAwesomeIcon icon={["fas", "bed"]} />
                            </Tooltip>
                        </span>
                        <span className="mc-qm-loc-list-header mc-qm-loc-list-header-expected">
                            <Tooltip title="Expected Passengers">
                                <FontAwesomeIcon icon={["fas", "level-down-alt"]} />
                            </Tooltip>
                        </span>
                        <span className="mc-qm-loc-list-header mc-qm-loc-list-header-departing">
                            <Tooltip title="Departing Passengers">
                                <FontAwesomeIcon icon={["fas", "level-up-alt"]} />
                            </Tooltip>
                        </span>
                    </div>
                </dt>

                {/* ------------------  LOOP OVER LOCATION QUERY -------------------- */}
                {props.data.map(item => {

                    let bedCountAlert;
                    let expectedAlert;
                    let departingAlert;
                    let onboardAlert;

                    let bedCountTooltip = "";

                    /* ---------------------------- */
                    if (item.expectedCount === 0) {
                        expectedAlert = 'none'
                    }
                    /* ---------------------------- */
                    if (item.departingCount === 0) {
                        departingAlert = 'none'
                    }
                    /* ---------------------------- */
                    if (item.onboardCount === 0) {
                        onboardAlert = 'none'
                    }
                    /* ---------------------------- */
                    if (item.bedCount === 0) {
                        bedCountAlert = 'critical'
                        bedCountTooltip = "No Beds Available"
                    } else if (item.bedCount < 5) {
                        bedCountAlert = 'warning'
                        bedCountTooltip = "Few Beds Available"
                    }

                    /* ------------------------------------------------------ */
                    /* item list (ACTIVE) for highlighting) */
                    return <dd key={item?.locationID} className={`list-item ${props.selectedID === item.locationID ? "active" : ""}`} onClick={() => props.onOffshoreLocClick?.(item.locationID, item.locationName, item.classType)}>
                        <FontAwesomeIcon icon={["fas", "map-marker-alt"]} className="mc-qm-icon" />
                        <span className="title">{item.locationName}</span>
                        <div className="destinfowrap">

                            <QmAlert type={onboardAlert}>{item.onboardCount}</QmAlert>

                            <Tooltip title={bedCountTooltip}>
                                <QmAlert type={bedCountAlert}>{item.bedCount}</QmAlert>
                            </Tooltip>

                            <QmAlert type={expectedAlert}>{item.expectedCount}</QmAlert>
                            <QmAlert type={departingAlert}>{item.departingCount}</QmAlert>
                        </div>
                    </dd>
                })}
            </dl>
        </div>
    )
}


export default OffshoreLocList


