import React, { Component } from 'react';
import '../css/Login.css';
import { withRouter } from "react-router-dom";
//import { withAuthenticator } from '@aws-amplify/auth-react';
import { Auth } from '@aws-amplify/auth';
import { Input, Button, Form, Alert } from 'antd';
import mcLogo from '../img/mc-logo-wide-white.svg';

const FormItem = Form.Item;

class Verify extends Component {
    constructor(props){
        super(props);
        this.state = {
            error: '',
            codeResent: false
        }
    }
    redirect = () => {
        const redirect = this.props.redirectTo || '/login';
        if (redirect)
        {
            this.props.history.push(redirect);
        }
    }
    signIn = (username, password) => {
        Auth.signIn(username, password)
        .then(() => this.props.history.push(this.props.redicrectToAfterSignIn || '/app'))
        .catch(e => {
            console.error('Failed to login automatically:', e);
            this.redirect();
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                Auth.confirmSignUp(this.props.email, values.code)
                    .then(user => this.signIn(this.props.email, this.props.password))
                    .catch(err => this.setState({
                        error: err.message
                    }));
            }
        });
    }
    resendCode = () => {
        const email = this.props.email;
        Auth.resendSignUp(email)
        .then(user => this.setState({ codeResent: true, error: '' }))
        .catch(err => this.setState({ error: err.message }))
    }
    render(){
        const email = this.props.email;
        const signup = this.props.signup || false;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="login-page-wrapper">
                <div className="container">
                    <img src={mcLogo} className="mc-logo" alt="Manifest Central Logo"/>
                    <div className="login-box">
                        <h2 style={{fontWeight: 100, marginBottom: '1rem'}}>Confirm Dataflyt Account</h2>
                        {
                            this.state.codeResent && (
                                <Alert 
                                    message="Verification code successfully resent"
                                    description={`A verification code has been resent to ${email}.`}
                                    type="success"
                                    closable={true}
                                    onClose={ evt => this.setState({ codeResent: false }) }
                                    style={{marginBottom: '1rem'}}
                                />
                            )
                        }
                        {(this.state.error) ? 
                        <Alert
                            style={{marginBottom: '1rem'}}
                            message="Verificaiton error"
                            description={this.state.error}
                            type="error"
                            closable
                            onClose={evt =>
                                this.setState({
                                    error: ''
                                })
                            }
                        /> : ''
                        }
                        {signup === true && <p>Your Dataflyt account has been successfully created but has not been activated yet.</p>}
                        <p>A DataFlyt account verification code was sent to <strong>{ email }</strong>. Please enter the verification code below to activate your account. If you did not receive your verification code, click <Button className="mc-link-btn" onClick={this.resendCode}>Resend Code</Button> to resend the code. If you have not received the code, please check your spam filter.</p>
                        <Form layout="inline" onSubmit={this.handleSubmit}>
                            <FormItem>
                                {getFieldDecorator('code', {
                                    rules: [{
                                        required: true, message: 'Please enter a valid verification code',
                                    }],
                                })(
                                    <Input size="large" placeholder="Verification code" />
                                )}
                            </FormItem>
                            <FormItem>
                                <Button size="large" htmlType="submit" type="primary">Verify Account</Button>
                            </FormItem>
                            <FormItem>
                                <Button size="large" onClick={this.resendCode}>Resend Code</Button>
                            </FormItem>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create()(withRouter(Verify));
