import { Form, Input } from "antd";
import React from 'react';
import { OrganizationSelect } from '../../form/select/OrganizationSelect';
import { getFieldKey } from '../fieldTypes';

// const CUSTOMER = getFieldKey('customer');
const FIRSTNAME = getFieldKey('firstName');
const LASTNAME = getFieldKey('lastName');
const EMPLOYERS = getFieldKey('employers');

export const formatFields = (fields) => {
    return fields
}

const CovidVaccineForm = (form, fieldProps={}, currentCustomer, currentTransporter) => {

    const { getFieldDecorator } = form;

    return [
        // <Form.Item key={CUSTOMER} label="Customer" {...fieldProps} >
        //     {getFieldDecorator(CUSTOMER, {
        //     })(
        //         <Select allowClear>
        //             <Select.Option value={currentCustomer._id}>{currentCustomer.name}</Select.Option>
        //         </Select>
        //     )}
        // </Form.Item>,
        <Form.Item key={EMPLOYERS} label="Employer" {...fieldProps}>
            {getFieldDecorator(EMPLOYERS, {
                initialValue: []
            })(<OrganizationSelect
                classTypes={['flytsuite.employer']}
                mode="multiple"
                labelInValue={false}
            />)}
        </Form.Item>,
        <Form.Item key={FIRSTNAME} label="First Name" {...fieldProps} >
        {getFieldDecorator(FIRSTNAME)(<Input />)}
        </Form.Item>,
        <Form.Item key={LASTNAME} label="Last Name" {...fieldProps} >
        {getFieldDecorator(LASTNAME)(<Input />)}
        </Form.Item>
    ]
}

export default CovidVaccineForm