import { Button, Divider } from 'antd';
import pluralize from 'pluralize';
import React from 'react';

export interface ISelectionCountProps extends React.HTMLAttributes<HTMLSpanElement> {
    onClear?(): void,
    items?: any[] | number,
    itemName?: string,
    renderMessage?: (count: number) => React.ReactNode
}

const SelectionCount: React.FC<ISelectionCountProps> = (props) => {
    const {
        onClear,
        items,
        itemName="item",
        renderMessage = (count) => <span>{count} Selected {pluralize(itemName, count)}</span>,
        ...restProps
    } = props;

    let count = 0;
    if (Array.isArray(items)){
        count = items.length;
    }
    else if (typeof items === 'number'){
        count = items;
    }
    function handleClear(){
        if (onClear){
            onClear();
        }
    }
    return <span {...restProps}>
        {renderMessage(count)}
        {onClear ? <>
            <Divider type="vertical" />
            <Button className="mc-link-btn" onClick={handleClear}>Clear</Button>
        </> : null}
    </span>
}

export default SelectionCount