import { Alert, Icon, Input, Radio, Spin } from "antd";
import _debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-apollo";
import PersonSearchQuery from "./PersonSearchQuery";
import BlueprintTableInfiniteScroller from "components/BlueprintTableInfiniteScroller";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import { isOnLastPage } from "common/couchdb/utils";
import { useColWidths } from "common/table";

const PersonSearch = ({
  style = {},
  selectedPerson,
  onPersonSelect = (id) => null,
  onSearch,
  queryLimit = 100,
}) => {
  const [filter, setFilter] = useState(null);
  const [currentBookmark, setCurrentBookmark] = useState(null);
  const [lastBookmark, setlastBookmark] = useState(null);
  const [isInitialQuery, setIsInitialQuery] = useState(true);

  const paginationHasMore = !isOnLastPage(currentBookmark, lastBookmark);

  useEffect(() => {
    setCurrentBookmark(null);
    setlastBookmark(null);
    setIsInitialQuery(true);
  }, [filter]);

  const setName = ({ firstName, lastName }) => {
    if (firstName) setFilter({ ...filter, firstName });
    if (lastName) setFilter({ ...filter, lastName });
  };
  const setNameDebounce = _debounce(setName, 1000);

  const mounted = useRef(false);

  const { data, loading, error, fetchMore } = useQuery(PersonSearchQuery, {
    variables: {
      filter,
      limit: queryLimit,
    },
    skip: !filter,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (isInitialQuery) {
        setIsInitialQuery(false);
        setlastBookmark(currentBookmark);
        setCurrentBookmark(data?.persons?.bookmark);
      }
    },
  });

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (typeof onSearch === "function") {
      onSearch(filter);
    }
    // eslint-disable-next-line
  }, [filter?.lastName, filter?.firstName]);

  const records =
    data?.persons?.docs?.map((row) => {
      return {
        ...row,
        customerName: row.customerID && row.customerID.name,
        employerName: row.employerID && row.employerID.name,
      };
    }) || [];

  const columns = [
    {
      name: "",
      key: "checkbox",
      formatter: (row) => (
        <div style={{ display: "relative" }}>
          <Radio
            checked={row._id === selectedPerson}
            onClick={() => onPersonSelect(row._id)}
            style={{ top: "-2px" }}
          />
        </div>
      ),
    },
    {
      name: "Last Name",
      key: "lastName",
    },
    {
      name: "First Name",
      key: "firstName",
    },
    {
      name: "Customer",
      key: "customerName",
    },
    {
      name: "Employer",
      key: "employerName",
    },
  ].map((col) => {
    const cellRenderer = (i) => {
      const value = col.formatter
        ? col.formatter(records?.[i])
        : records?.[i]?.[col.key];
      return (
        <Cell tooltip={value} style={{ display: "flex", alignItems: "center" }}>
          {value}
        </Cell>
      );
    };
    return <Column key={col.key} name={col.name} cellRenderer={cellRenderer} />;
  });
  const [colWidths, , updateColWidth] = useColWidths(
    columns.length,
    {
      0: 40,
    },
    450 / 4 - 11
  );

  const selectedRowIdx = records?.findIndex(
    (row) => row._id === selectedPerson
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", ...style }}>
      <div
        style={{ paddingBottom: "1rem", display: "flex", alignItems: "center" }}
      >
        <strong style={{ marginRight: "6px" }}>Search:</strong>
        <Input.Group compact style={{ display: "flex", flexWrap: "nowrap" }}>
          <Input
            style={{ flex: 1 }}
            onPressEnter={(e) => setName({ lastName: e.target.value })}
            onChange={(e) => setNameDebounce({ lastName: e.target.value })}
            placeholder="by last name"
            allowClear
          />
          <Input
            style={{ flex: 1 }}
            onPressEnter={(e) => setName({ firstName: e.target.value })}
            onChange={(e) => setNameDebounce({ firstName: e.target.value })}
            placeholder="by first name"
            allowClear
          />
        </Input.Group>
      </div>
      {error ? (
        <Alert
          style={{ marginBottom: "1rem" }}
          type="error"
          showIcon
          message="Failed to get persons"
        />
      ) : null}
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "5rem" }}>
          <Spin
            indicator={<Icon type="loading" style={{ fontSize: "2rem" }} />}
          />
        </div>
      ) : null}
      {filter && !loading ? (
        <div
          className="mc-border"
          style={{ flex: 1, minHeight: 0, display: "flex" }}
        >
          <BlueprintTableInfiniteScroller
            hasMore={paginationHasMore}
            loadMore={() => {
              return fetchMore({
                variables: {
                  bookmark: currentBookmark,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  setlastBookmark(currentBookmark);
                  setCurrentBookmark(fetchMoreResult?.persons?.bookmark);
                  return {
                    ...prev,
                    persons: {
                      ...prev?.persons,
                      docs: [
                        ...prev?.persons?.docs,
                        ...fetchMoreResult?.persons?.docs,
                      ],
                    },
                  };
                },
              });
            }}
          >
            <Table
              enableMultipleSelection={false}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              className="mc-table"
              numRows={data?.persons?.docs?.length}
              columnWidths={colWidths}
              onColumnWidthChanged={updateColWidth}
              enableRowHeader={false}
              selectedRegions={
                selectedRowIdx > -1
                  ? [
                      {
                        rows: [selectedRowIdx, selectedRowIdx],
                      },
                    ]
                  : []
              }
              onSelection={(regions) => {
                if (regions[0].cols[0] === 0) return;
                let rowIdx = regions[0].rows[0];

                let person = records[rowIdx];
                if (person) {
                  onPersonSelect(person._id);
                }
              }}
            >
              {columns}
            </Table>
          </BlueprintTableInfiniteScroller>
        </div>
      ) : null}
      {!loading && !filter ? (
        <div style={{ textAlign: "center", marginTop: "10rem", color: "grey" }}>
          <Icon
            type="search"
            style={{ fontSize: "4rem", marginBottom: "1rem" }}
          />
          <div>Search for personnel by typing into the search bar</div>
        </div>
      ) : null}
    </div>
  );
};

// const PersonSearchResults = ({
//         persons=[],
//         style={},
//         selectedPerson,
//         onPersonSelect,
//         tableMinHeight,
//         onScroll=() => null
//     }) => {
//     const data = persons.map(row => {
//         const getVal = (obj, field) => obj && obj[field];
//         return {
//             ...row,
//             customerName: getVal(row.customerID, 'name'),
//             employerName: getVal(row.employerID, 'name'),
//         }
//     })
//     // const [data, setData] = useState(initialData);
//     // useEffect(() => {
//     //     setData(persons);
//     // },
//     // [persons]
//     // )
//     // const sortData = (initialRows, sortColumn, sortDirection) => rows => {
//     //     const comparer = (a, b) => {
//     //       if (sortDirection === "ASC") {
//     //         return a[sortColumn] > b[sortColumn] ? 1 : -1;
//     //       } else if (sortDirection === "DESC") {
//     //         return a[sortColumn] < b[sortColumn] ? 1 : -1;
//     //       }
//     //     };
//     //     return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
//     //   };
//     return (
        
//     )
// }

export default PersonSearch