import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import './ISNChecker.css';
import { Spin, Alert } from 'antd';
import ISNQuickCheck from '../../Queries/ISNQuickCheck';
import { QCResult } from '../isn/QCResult';

const ISNChecker = ({
    customerID,
    isnNumber
}) => {
    if (!customerID){
        return <Alert type="warning" message="No Customer" />
    }
    if (!isnNumber){
        return <Alert type="warning" message="No ISN Number" />
    }
    const formattedIsnNumber = ('' + isnNumber).replace('ISN-', '');
    if (formattedIsnNumber.length !== 8){
        return (
            <Alert
                showIcon
                type="error"
                message="Invalid ISN Number"
                description="ISN number must be 8 digits"
            />
        )
    }
    if (!/^\d+$/.test(formattedIsnNumber)){
        return (
            <Alert
                showIcon
                type="error"
                message="Invalid ISN Number"
                description="ISN number must be digits 0-9"
            />
        )
    }
    return (
        <Query
            query={ISNQuickCheck}
            variables={{
                customerID,
                isnNumber
            }}
            skip={!customerID || !isnNumber}
            fetchPolicy="network-only"
        >
        {({ data, loading, error }) => {
            if (loading) return <Spin />
            if (error) return <Alert type="error" showIcon message="Failed to run ISN check" />
            if (!data || !data.ISNQuickCheck) return null;
            return (
                <QCResult data={data.ISNQuickCheck} allowSelection={false} />
            )
        }}
        </Query>
    )
}

ISNChecker.propTypes = {
    customer: PropTypes.string,
    isnNumber: PropTypes.string
}

export default ISNChecker