import CognitoPhoneNumber from "./CognitoPhoneNumber";

function validateNumberChars(value: string, callback: Function){
    if (!value.match(/^([0-9]|[-()]| )*$/g)){
        callback("Must only be numbers or '-', '(', and ')'");
        return false
    }
    callback();
    return true
}

export function validateCognitoPhoneNumber(rule: any, value: CognitoPhoneNumber, callback: Function){
    if (!rule.required && (!value || (!value.number && !value.countryCode))){
        callback();
        return;
    }
    if (rule.required && !value){
        callback("Phone number is required");
        return;
    }
    if (!value.number && value.countryCode){
        callback("A phone number is required");
        return;
    }
    if (!value.countryCode && value.number){
        callback("Please select a calling code");
        return;
    }
    if (!validateNumberChars(value.number, callback)){
        callback();
        return;
    }
}