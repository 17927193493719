import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import SearchSelect from './SearchSelect';

class SearchSelectWithData extends Component {
    render() { 
        const { query, variables, fetchPolicy, ...restProps } = this.props;
        return (
            <Query
            query={query}
            variables={variables}
            fetchPolicy={fetchPolicy || 'cache-and-network'}
            >
            {({ data, loading, error }) => {
                return (
                    <SearchSelect
                    data={this.props.getData(data) || []}
                    loading={loading}
                    error={error}
                    {...restProps}
                    />
                )
            }}
            </Query>
        );
    }
}

SearchSelectWithData.propTypes = {
    query: PropTypes.object.isRequired,
    getData: PropTypes.func.isRequired,
    variables: PropTypes.object,
    fetchPolicy: PropTypes.string
}

export default SearchSelectWithData;