import {ScheduleEditorApi} from "./api";
import {Button, Dropdown, Icon, Menu} from "antd";
import React from "react";

export interface ViewButtonProps {
    api: ScheduleEditorApi
}

const ViewButton: React.FC<ViewButtonProps> = (props) => {

    const { api } = props;

    return (
        <Dropdown
            trigger={['click']}
            overlay={
                <Menu /*onClick={handleAction}*/>
                    <Menu.Item
                        key="selectall"
                        onClick={api.editor.visibleLegs.showAll}
                    >Expand All Tables</Menu.Item>
                    <Menu.Item
                        key="deselectall"
                        onClick={api.editor.visibleLegs.hideAll}
                    >Collapse All Tables</Menu.Item>
                </Menu>
            }
        >
            <Button>
                View <Icon type="down" />
            </Button>
        </Dropdown>
    )
}

export default ViewButton;