import React from 'react';
import {DatePicker, Form, Input} from 'antd';
import {FormComponentProps, FormProps} from 'antd/lib/form';
import {createFormFields, formItemLayout} from 'common/form';
import moment from 'moment';
import {CloneFormFields} from "./state";

export interface CloneFormProps extends FormComponentProps {
    formElementProps?: FormProps,
    disabled?: boolean,
    formData?: any,
    onFieldsChange?: (fields: CloneFormFields) => void
}

const CloneForm_Internal: React.FC<CloneFormProps> = React.forwardRef((props, ref: React.LegacyRef<Form>) => {
    const { getFieldDecorator } = props.form;
    return <Form ref={ref} {...props.formElementProps} form={props.form}>
        <Form.Item label="Flight Date" {...formItemLayout}>
            {getFieldDecorator('date', {
                rules: [
                    {required: true, message: 'You must enter a flight date'}
                ]
            })(<DatePicker disabled={props.disabled}
                           disabledDate={(current) => current && current < moment().startOf('day')}/>)}
        </Form.Item>
        <Form.Item label="Schedule Name" {...formItemLayout}>
            {getFieldDecorator('name', {
                rules: [
                    {required: true, message: 'You must enter a group name'}
                ]
            })(<Input onChange={e => {
                e.target.value = e.target.value.trimStart().replace(/[^\S ]/gm, '')
            }} disabled={props.disabled}/>)}
        </Form.Item>
    </Form>
})

const CloneForm = Form.create<CloneFormProps>({
    mapPropsToFields(props){
        if (!props.formData) return undefined
        return createFormFields(props.formData);
    },
    onFieldsChange(props, _, fields){
        if (props.onFieldsChange){
            props.onFieldsChange(fields);
        }
    }
})(CloneForm_Internal);

CloneForm.displayName = 'CloneForm';

export default CloneForm;