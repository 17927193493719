
function getGenericStateReducer<State=any, Payload=any>(extendTypeHandling?: (state: State, type: string, payload: Payload) => State){
    function reducer(
        state: State,
        action: { type?: string, payload?: Payload, newState?: Partial<State> }
    ): State {
        switch (action.type) {
            default:
                let newState = state;
                if (action.newState){
                    newState = { ...state, ...action.newState }
                }
                if (extendTypeHandling){
                    newState = extendTypeHandling(newState, action.type, action.payload);
                }
                return newState;
        }
    }
    return reducer
}

export default getGenericStateReducer