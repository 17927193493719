import { OrgEmailListItem } from "common/types/org-email-list";
import emailValidator from 'email-validator';

export function validateOrgNotifEmailListEditor(_, value: OrgEmailListItem[], callback: Function) {

    let errors = [];

    if (!value) {
        callback();
        return;
    }

    for (let item of value) {
        if (item?.email && !emailValidator.validate(item?.email)) {
            errors.push(`'${item.email}' is not a valid email`);
        }

        if (item?.options) {
            validateOptIn(null, item.options, (msg) => msg && errors.push(msg));
        }
    }

    if (errors.length > 0) {
        callback(errors.join('\n'));
        return false
    }
    callback();
    return true
}

export function validateOptIn(_, value, callback) {
    if (value?.optInAll) { callback(); return; }
    if (!value?.optIn || (Array.isArray(value?.optIn) && value.optIn.length === 0)) { callback("You must choose at least one notification type.") }
    else callback();
}