import parsePhoneNumber from 'libphonenumber-js';

class CognitoPhoneNumber {
    public countryCode: string
    public number: string

    constructor(countryCode: string, number: string){
        this.countryCode = countryCode;
        this.setCountryCode(countryCode);
        this.number = number;
    }

    setCountryCode(cc: number | string){
        if (typeof cc == 'string'){
            cc = cc.replaceAll('+', '');
        }
        this.countryCode = '+' + cc
    }

    /**
     * Parse from a string in the standard AWS Cognito phone format. Ex: +15554443333
     * @param phonenumber 
     */
    public static parseString(phonenumber: string): CognitoPhoneNumber {
        if (typeof phonenumber !== 'string') return;
        let phone = parsePhoneNumber(phonenumber);
        if (!phone){
            // Parsing failed
            return;
        }
        return new CognitoPhoneNumber(phone.countryCallingCode.toString(), phone.nationalNumber.toString());
    }

    public static clean(phoneNumber: string): string{
        if (!phoneNumber) return undefined;
        const matched = Array.from(phoneNumber.matchAll(/\d/g));
        return '+' + matched.join('');
    }

    /**
     * Convert to standard AWS Cognito phone number format. Ex: +15554443333
     */
    public toString(): string {
        if (!this.number){
            return '';
        }
        let cc = this.countryCode;
        if (!cc.includes('+')){
            // Shouldn't not have a + in the countryCode.
            // Add it back just in case.
            cc = '+' + cc;
        }
        return CognitoPhoneNumber.clean(cc + this.number);
    }
}

export default CognitoPhoneNumber