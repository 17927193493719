import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import SubmitReport from './SubmitReport';
import WithOrgData from '../../WithOrgData';

class ReportFormSubmitter extends Component {
    static propTypes = {
        onCompleted: PropTypes.func,
        onError: PropTypes.func,
        type: PropTypes.oneOf(['COVID', 'MANIFEST', 'PAX', 'CGO', 'PAX_COVID19_VAX', 'PERSON_COVID19_VAX_DETAILS']),
        children: PropTypes.func.isRequired,
        onChange: PropTypes.func
    }
    render() {
        const renderReport = this.props.children;
        const {
            type,
            orgData: { transporter },
            onCompleted=() => null,
            onError=() => null,
        } = this.props;
        return (
            <Mutation
                mutation={SubmitReport}
            >
            {(submitReport, result) => {
                const submitFunc = ({ name, criteria }) => {
                    submitReport({
                        variables: {
                            name,
                            criteria: criteria.map(item => ({...item, value: JSON.stringify(item.value)})),
                            type,
                            tpID: transporter._id
                        }
                    })
                    .then(({ data }) => onCompleted({
                        id: data.run_report,
                        name
                    }))
                    .catch((error) => {
                        onError(error);
                    })
                };
                return renderReport(
                    submitFunc,
                    result
                )
            }}
            </Mutation>
        );
    }
}
 
export default WithOrgData(ReportFormSubmitter);