import React from 'react';
import { Form, Input, Row, Col, DatePicker } from 'antd';
import { LocationSelect } from '../../form/select/LocationSelect';

const SetForm = ({ form, showDate=false, dateFormat="YYYY-MM-DD", useGroupName=false, disabled=false }) => {
    const { getFieldDecorator } = form;
    return (
        <Form>
            <Row type="flex" gutter={12}>
                {showDate ? (
                    <Col style={{ flex: 1 }}>
                        <Form.Item label="Flight Date">
                            {getFieldDecorator('date', {
                                rules: [
                                    { required: true, message: 'Flight date is required' }
                                ]
                            })(<DatePicker style={{ width: '100%' }} format={dateFormat} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                ) : null}
                <Col style={{ flex: 1 }}>
                    <Form.Item label={useGroupName ? "Schedule Name" : "Name"}>
                        {getFieldDecorator('name', {
                            rules: [
                                { required: true, message: 'Set name is required' }
                            ]
                        })(<Input onChange={e => {
                            e.target.value = e.target.value.trimStart().replace(/[^\S ]/gm, '')}} style={{ width: '100%' }} disabled={disabled} />)}
                    </Form.Item>
                </Col>
                <Col style={{ flex: 1 }}>
                <Form.Item label="Controlling Heliport">
                    {getFieldDecorator('lastKnownController', {
                        rules: [
                            { required: true, message: 'You must enter a controlling heliport' }
                        ]
                    })(<LocationSelect type="ONSHORE" disabled={disabled} labelInValue style={{ width: '100%' }}  showAction={["focus", "click"]} />)}
                </Form.Item>
                </Col>
                <Col style={{ flex: 1 }}>
                    <Form.Item label="Departure Location">
                        {getFieldDecorator('departure', {
                            rules: [
                                { required: true, message: 'Departure Location is required' }
                            ]
                        })(<LocationSelect style={{ width: '100%' }} labelInValue disabled={disabled}  showAction={["focus", "click"]} />)}
                    </Form.Item>
                </Col>
                <Col style={{ flex: 1 }}>
                    <Form.Item label="Destination Location">
                        {getFieldDecorator('destination', {
                            rules: [
                                { required: true, message: 'Destination Location is required' }
                            ]
                        })(<LocationSelect style={{ width: '100%' }} labelInValue disabled={disabled}  showAction={["focus", "click"]} />)}
                    </Form.Item>                   
                </Col>
            </Row>
        </Form>
    )
}

export default Form.create({
    onFieldsChange(props, changedFields, allFields){
        if (!props.onChange) return;
        props.onChange(changedFields, allFields);
    },
    mapPropsToFields(props) {
        const date = props.date || {};
        const name = props.name || {};
        const lkc = props.lastKnownController || {};
        const departure = props.departure || {};
        const destination = props.destination || {};
        return {
            date: Form.createFormField({
                ...date,
                value: date.value
            }),
            name: Form.createFormField({
                ...name,
                value: name.value
            }),
            lastKnownController: Form.createFormField({
                ...lkc,
                value: lkc.value
            }),
            departure: Form.createFormField({
                ...departure,
                value: departure.value
            }),
            destination: Form.createFormField({
                ...destination,
                value: destination.value
            })
        }
    }
})(SetForm)