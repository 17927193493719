import { ApolloError } from 'apollo-client';
import { OrgDataContext } from 'context/orgData';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { MutationHookOptions, MutationResult, OperationVariables, useMutation } from 'react-apollo';

const MUTATION = gql`
mutation DeleteFuelTaken($tpID: String!, $fuelTaken: FuelTakenInput!){
        DeleteFuelTaken(
            tpID: $tpID
            fuelTaken: $fuelTaken
        ){
            locationId
            dateTimeTaken
        }
    }
`

interface UseDeleteFuelTakenOptions {
    onCompleted?: () => void,
    onError?: (err: ApolloError) => void,
    additionalMutationOptions?: MutationHookOptions<any, OperationVariables>
}

type FuelTakenObj = any & { locationId: string, dateTimeTaken: string };

interface ItemDeleted {
    locationId: string,
    dateTimeTaken: string
}

type ReturnType = [ (fuelTaken: FuelTakenObj) => void, MutationResult<any>, ItemDeleted ];

function useDeleteFuelTaken(options?: UseDeleteFuelTakenOptions): ReturnType {

    const { transporterID } = useContext(OrgDataContext);
    const [ itemDeleted, setItemDeleted ] = useState(null);
    
    const [ mutate, mutationResult ] = useMutation(MUTATION, {
        onCompleted: options?.onCompleted,
        onError: options?.onError,
        ...options.additionalMutationOptions
    });

    function deleteFunc(fuelTaken: FuelTakenObj){
        setItemDeleted({
            locationId: fuelTaken.locationId,
            dateTimeTaken: fuelTaken.dateTimeTaken
        })
        mutate({
            variables: {
                tpID: transporterID,
                fuelTaken: {
                    ...fuelTaken,
                    dateTimeTaken: fuelTaken.dateTimeTaken
                }
            },
            optimisticResponse: 'optimisticResponse' in options.additionalMutationOptions ? options.additionalMutationOptions.optimisticResponse :
            {
                __typename: 'Mutation',
                DeleteFuelTaken: fuelTaken
            }
        })
    }

    return [ deleteFunc, mutationResult, itemDeleted ]
}

export default useDeleteFuelTaken