import { Form, Input, Select } from "antd";
import { OrganizationSelect } from '../../form/select/OrganizationSelect';
import { getFieldKey } from '../fieldTypes';

const CUSTOMERS = getFieldKey('customers');
const EMPLOYERS = getFieldKey('employers');
const FIRST_NAME = getFieldKey('firstName')
const LAST_NAME = getFieldKey('lastName')

export const formatFields = (fields) => {
    // Nothing to format
    return fields
}

const PersonHotFuelForm = (form, fieldProps={}, currentCustomer) => {

    const { getFieldDecorator } = form;

    return [
        <Form.Item key={CUSTOMERS} label="Customers" {...fieldProps} >
            {getFieldDecorator(CUSTOMERS, {
                initialValue: currentCustomer ? currentCustomer._id : [],
                rules: [
                    {required: currentCustomer ? true : false, message: 'Customer is required'}
                ]
            })(currentCustomer ? (
                <Select showAction={["focus", "click"]}>
                    <Select.Option value={currentCustomer._id}>{currentCustomer.name}</Select.Option>
                </Select>
            ) : (
            <OrganizationSelect
                classTypes={['flytsuite.customer']}
                mode="multiple"
                labelInValue={false}
            />)
            )}
        </Form.Item>,
        <Form.Item key={EMPLOYERS} label="Employers" {...fieldProps} >
            {getFieldDecorator(EMPLOYERS)(
                <OrganizationSelect
                    classTypes={['flytsuite.employer']}
                    mode="multiple"
                    labelInValue={false}
                />
            )}
        </Form.Item>,
        <Form.Item key={LAST_NAME} label="Passenger Last Name" {...fieldProps} >
        {getFieldDecorator(LAST_NAME)(<Input />)}
        </Form.Item>,
        <Form.Item key={FIRST_NAME} label="Passenger First Name" {...fieldProps} >
        {getFieldDecorator(FIRST_NAME)(<Input />)}
        </Form.Item>
    ]
}

export default PersonHotFuelForm