import LocationSelect2 from 'components/form/select/LocationSelect/LocationSelect2';
import React from 'react';

class LocationOnshoreSelect extends React.Component{
    render(){
        return (
            <LocationSelect2 {...this.props} type='ONSHORE' />
        )
    }
}

export default LocationOnshoreSelect