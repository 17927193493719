import { Col, Form, Input, Row } from 'antd';
import { GetFieldDecoratorOptions, WrappedFormUtils } from 'antd/lib/form/Form';
import ContractSelect from 'components/form/ContractSelect';
import React from 'react';
import AircraftSelect from '../form/AircraftSelect';
import PilotSelect from '../form/PilotSelect';

export interface FlightEditorFormProps {
    scheduledFlightDate?: any,
    desig?: any,
    contract?: any,
    pilot?: any,
    copilot?: any,
    aircraft?: any,
    form: WrappedFormUtils,
    showScheduledFlightDate?: boolean,
    onChange?(fields: any): void,
    disabled?: boolean,
    editing?: boolean,
    locationID?: string
}

function getFieldOptions(label: string, required: boolean): GetFieldDecoratorOptions {
    return {
        rules: [
            {
                required: required, message: label + ' is required'
            }
        ]
    }
}

const FlightEditorForm: React.FunctionComponent<FlightEditorFormProps> = (props) => {
    const renderColumns = (formItems: JSX.Element[]): JSX.Element[] => {
        return formItems.map((item) => <Col style={{ width: '15rem' }}>{item}</Col>)
    }
    const { getFieldDecorator } = props.form;
    return <Form>
        <Row type="flex" gutter={12}>
            {renderColumns([
                <Form.Item label={<strong>Designation:</strong>} colon={false}>
                    {getFieldDecorator('desig', getFieldOptions('Designation', true))(
                        <Input disabled={props.disabled || !props.editing} />
                    )}
                </Form.Item>,
                <Form.Item label={<strong>Aircraft:</strong>} colon={false}>
                    {getFieldDecorator('aircraft', getFieldOptions('Aircraft', true))(
                        <AircraftSelect labelInValue disabled={props.disabled || !props.editing} />
                    )}
                </Form.Item>,
                <Form.Item label={<strong>Contract:</strong>} colon={false}>
                    {getFieldDecorator('contract', getFieldOptions('Contract', true))(
                        <ContractSelect
                            labelInValue
                            orgType={'customer'}
                            disabled={props.disabled || !props.editing}
                        />
                    )}
                </Form.Item>,
                <Form.Item label={<strong>Pilot:</strong>} colon={false}>
                    {getFieldDecorator('pilot', getFieldOptions('Pilot', false))(
                        <PilotSelect labelInValue disabled={props.disabled || !props.editing} />
                    )}
                </Form.Item>,
                <Form.Item label={<strong>Co-Pilot:</strong>} colon={false}>
                    {getFieldDecorator('copilot', getFieldOptions('Copilot', false))(
                        <PilotSelect labelInValue disabled={props.disabled || !props.editing} />
                    )}
                </Form.Item>
            ])}
        </Row>
    </Form>
}

export default Form.create({
    mapPropsToFields(props: FlightEditorFormProps){
        const createFormFieldFromProp = (field: any) => {
            if (typeof field === 'object'){
                return Form.createFormField({
                    ...field,
                    value: field.value
                })
            }
        }
        return {
            scheduledFlightDate: createFormFieldFromProp(props.scheduledFlightDate),
            desig: createFormFieldFromProp(props.desig),
            contract: createFormFieldFromProp(props.contract),
            pilot: createFormFieldFromProp(props.pilot),
            copilot: createFormFieldFromProp(props.copilot),
            aircraft: createFormFieldFromProp(props.aircraft)
        }
    },
    onFieldsChange(props, changedFields){
        props.onChange && props.onChange(changedFields)
    }
})(FlightEditorForm)