import gql from "graphql-tag";

export default gql`
mutation CreatePersonSet(
    $payload: PersonSetInput!
){
    setPersonSet(
        payload: $payload
    ){
        _id
    }
}
`