import config from '../config'
import { Auth } from '@aws-amplify/auth'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Query: {
        dashboard: async (_, args) => {
            const session = await Auth.currentSession();
            console.log('args', args);
            const response = await fetch(config.dashboardAPI + '/dashboard', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': session.idToken.jwtToken
                },
                body: JSON.stringify(args)
            });
            const respData = await response;
            if (respData.ok){
                return respData.json();
            }
            else{
                var json;
                try{
                    json = await respData.json();
                }
                catch(e){}
                const message = json ? json.Message : await respData.text();
                const error = Error(message);
                error.name = json ? json.Code : 'Error';
                console.error(error);
                throw error
            }
        }
    }
}