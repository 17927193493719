/*
Written by Austin Thibodeaux
Input that handles the phone number format +<COUNTRYCODE><NUMBER> that Amazon Cognito expects
*/

import { Button, Tooltip } from 'antd';
import Input, { InputProps } from 'antd/lib/input';
import Select, { SelectProps } from 'antd/lib/select';
import countryCodes from 'country-codes-list';
import React, { forwardRef, LegacyRef } from 'react';
import CognitoPhoneNumber from './CognitoPhoneNumber';

export interface InputCognitoPhoneNumberProps {
    inputProps?: Omit<InputProps, 'value'>,
    selectProps?: Omit<SelectProps, 'value'>,
    value?: CognitoPhoneNumber | string,
    onChange?: (value: CognitoPhoneNumber) => void,
    onBlur?: (event: React.FocusEvent<HTMLSpanElement>) => void,
    onFocus?: (event: React.FocusEvent<HTMLSpanElement>) => void,
    allowClear?: boolean
}

const COUNTRY_CODES = countryCodes.customList('countryCallingCode', '{countryNameLocal}');

const InputCognitoPhoneNumber: React.FC<InputCognitoPhoneNumberProps> = forwardRef((props, ref: LegacyRef<Input>) => {

    let unFormattedValue = props.value;
    let valueProp: CognitoPhoneNumber;
    if (typeof unFormattedValue === 'string'){
        valueProp = CognitoPhoneNumber.parseString(unFormattedValue);
    }
    else
    {
        valueProp = unFormattedValue;
    }

    function updateCountryCode(value: string){
        props.onChange?.(new CognitoPhoneNumber(value, valueProp?.number));
    }

    function updateNumber(value: string){
        props.onChange?.(new CognitoPhoneNumber(valueProp?.countryCode, value))
    }

    function clear(){
        props.onChange?.(undefined);
    }

    function renderClearBtn(){
        let allowClear = (typeof props.allowClear === 'boolean') ? props.allowClear : true
        if (allowClear){
            return <Tooltip title="Clear">
                <Button
                    icon="close"
                    onClick={clear}
                />
            </Tooltip>
        }
        return null;
    }

    function handleInputGroupBlur(e: any){
        if (!e.currentTarget.contains(e.relatedTarget)){ // Leaving parent
            props.onBlur?.(e);
        }
    }

    return <Input.Group
        compact
        style={{ display: 'flex' }}
        onBlur={handleInputGroupBlur}
        onFocus={props.onFocus}
    >
        <Tooltip title="Country calling code (+1 if US)">
            <Select
                showSearch
                onChange={(value: string) => updateCountryCode(value)}
                value={valueProp?.countryCode}
                defaultValue="1"
                style={{ width: '6rem', flexShrink: 0 }}
                showAction={["focus", "click"]}
                {...props.selectProps}
            >
                {Object.keys(COUNTRY_CODES).map((cc) => <Select.Option key={cc} value={cc}>+{cc}</Select.Option>)}
            </Select>
        </Tooltip>
        <Input
            onChange={(e) => updateNumber(e.target.value)}
            value={valueProp?.number}
            max={100}
            ref={ref}
            type="tel"
            {...props.inputProps}
        />
        {renderClearBtn()}
    </Input.Group>
})

export default InputCognitoPhoneNumber