import {Ref} from "react";

/**
 * Adds a "displayText" property to the ref of component's element for purposes of converting it into a human-readable string.
 * One of the modules that uses this property is {@link reactNodeTextGetter}" in its getNodeText function.
 * @param text Text to display
 * @param ref Ref to enhance
 */
export function exportTextInRef<T>(text: string, ref: Ref<T>){
    function enhanceElement(e: T){
        if (!e) return;
        (e as any).displayText = text;
    }

    if (typeof ref === 'function'){
        return (e: T) => {
            enhanceElement(e);
            return ref(e);
        }
    }
    else
    {
        enhanceElement(ref?.current);
        return ref;
    }
}