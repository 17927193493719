import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import EmployerQuery from './EmployerQuery';
import EmployerSelect from './EmployerSelect';

export default compose(
    graphql(
        EmployerQuery,
        {
            options: {
                variables: {
                    limit: 0
                },
                fetchPolicy: 'network-only'
            },
            props: props => {
                return {
                    ...props,
                    getEmployers: (filter) => {
                        if (!filter.name){
                            props.data.updateQuery((prev) => ({
                                ...prev,
                                employers: {
                                    ...prev.employers,
                                    docs: [],
                                    bookmark: null
                                }
                            }))
                            return
                        }
                        props.data.refetch({
                            limit: 15,
                            filter: {
                                name: filter && filter.name
                            }
                        })
                    }
                }
            }
        }
    )
)(EmployerSelect)
