
class AutoScroll {
    
    element: Element;
    enabled = false;
    waitMsBeforeScroll: number;
    waitMsOnReachBottom: number;
    repeat: boolean;

    // Callback for when scroll cycle is completed
    finishCb: () => void;

    // Callback for when scrolling is needed or not needed due to scroll height being smaller than element height
    scrollNeededCb: (needed: boolean) => void;
    
    // Computed on auto scroll start
    private computedDuration: number;
    private scrollSpeed: number; // Pixels per second
    private scrollNeeded: boolean; // Whether scrolling is needed or not.
    private stepping: boolean = false;
    private finished: boolean = false;

    currScrollTop = 0;

    animation: Animation = null;

    constructor(element: Element, defaultScrollSpeed=5, repeat=true, waitMsBeforeScroll=8000, waitMsOnReachBottom=8000){
        console.debug('AutoScroll class instantiated. Element: ', element);
        this.element = element;
        this.scrollSpeed = defaultScrollSpeed;
        this.waitMsBeforeScroll = waitMsBeforeScroll;
        this.waitMsOnReachBottom = waitMsOnReachBottom;
        this.repeat = repeat;
    }

    // Computes the duration of the scroll by dividing the remaining scroll distance by scroll speed and multiplied by a constant
    // If the height of the element is greater than or equal to the scroll height, then there is no need for a scroll. Therefore it returns false.
    // Otherwise it returns true
    computeDuration = () => {
        let clientRect = this.element.getBoundingClientRect();
        if ((clientRect.height + 1 >= this.element.scrollHeight && this.enabled) || !this.enabled){
            this.enabled = false;
            if (this.scrollNeededCb && this.scrollNeeded !== false){
                this.scrollNeededCb(false);
            }
            this.scrollNeeded = false;
            return false;
        }
        else
        {
            if (this.scrollNeededCb && this.scrollNeeded !== true){
                this.scrollNeededCb(true);
            }
            this.scrollNeeded = true;
        }

        const scrollHeight = this.element.scrollHeight - (this.element.scrollTop + clientRect.height);
        const scrollSpeed = this.scrollSpeed;

        let result = (scrollHeight / scrollSpeed) * 100
        this.computedDuration = result;
        return true;
    }

    start = async (backToTop=false, delay=false) => {
        if (this.finished){
            return;
        }
        if (this.stepping){
            // Already stepping going on
            return;
        }
        this.enabled = true;

        if (backToTop){
            this.element.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (delay){
            await this.sleep(this.waitMsBeforeScroll);
        }

        if (!this.computeDuration()){
            return;
        }

        const startY = this.element.scrollTop;
        const difference = this.element.scrollHeight - startY;
        const startTime = performance.now();
        console.log('difference:', difference);

        const step = () => {
            const progress = (performance.now() - startTime) / this.computedDuration;
            this.element.scrollTo({ top: startY + progress * difference });
            if (progress <= 1 && this.enabled) {
                // console.log('progress: ' + progress);
                window.requestAnimationFrame(step);
                this.stepping = true;
            }
            else if (this.enabled)
            {
                this.stepping = false;
                this.onFinish();
            }
            else
            {
                this.stepping = false;
            }
        };

        step();
    }

    onFinish = async () => {
        if (this.finished){
            return;
        }
        console.debug('Auto scroll animation finished. Waiting ' + this.waitMsOnReachBottom + " ms to restart animation.");
        this.finished = true;
        await this.sleep(this.waitMsOnReachBottom);
        this.finished = false;
        
        if (this.finishCb){
            // Run external callback if available
            // Allows external code to fire when scroll finishes
            this.finishCb();
        }
        if (this.enabled && this.repeat){
            console.debug('Restarting scroll animation.');
            this.start(true, true);
        }
        else
        {
            console.debug('Could not restart animation because repeat=' + this.repeat + " and enabled=" + this.enabled);
        }
    }

    stop = () => {
        this.enabled = false;
        this.computeDuration();
    }

    sleep(ms: number): Promise<boolean> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    setScrollSpeed = (speed: number) => {
        this.scrollSpeed = speed;
        console.debug('setScrollSpeed:', speed);
        this.computeDuration();
    }

    getScrollSpeed = () => this.scrollSpeed;

    onFinishCallback = (callback) => {
        this.finishCb = callback;
    }

    scrollNeededCallback = (callback) => {
        this.scrollNeededCb = callback;
    }
}

export default AutoScroll