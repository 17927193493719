import {ScheduleHistoryListItem} from "../../../../__generated__/graphql/types";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import {groupBy} from "lodash";
import {castArray} from "../../../../common/util";
import {ColumnProps} from "antd/lib/table";
import {Icon, List, Table} from "antd";
import moment from "moment";
import React from "react";
import ETable from "../../../../components/enchanced-antd-table";

export interface ScheduleHistoryListProps {
    onGroupClicked?: (group: ScheduleHistoryListItem, index: number) => void,
    data: ScheduleHistoryListItem[]
}

const ScheduleHistoryList: React.FC<ScheduleHistoryListProps> = (props) => {
    const bp = useBreakpoints();

    let grouped = groupBy(castArray(props.data), 'date');
    let data: [ string, ScheduleHistoryListItem[] ][] = Object.entries(grouped);

    const columns: ColumnProps<ScheduleHistoryListItem>[] = [
        {
            key: 'name',
            title: 'Schedule Name',
            dataIndex: 'groupName',
            width: bp.min("medium") ? 400 : undefined,
        }
    ]

    if (bp.min('medium')){
        columns.push(...[
            {
                key: 'outbound',
                title: 'Outbound',
                width: 150,
                render: (_, record: ScheduleHistoryListItem) => {
                    const pax = record.paxOutboundCount;
                    return <span>
                        <strong>{pax}</strong> pax
                    </span>;
                }
            },
            {
                key: 'transfer',
                title: 'Transfer',
                width: 150,
                render: (_, record: ScheduleHistoryListItem) => {
                    const pax = record.paxInboundCount;
                    return <span>
                        <strong>{pax}</strong> pax
                    </span>;
                }
            },
            {
                key: 'inbound',
                title: 'Inbound',
                render: (_, record: ScheduleHistoryListItem) => {
                    const pax = record.paxTransferCount;
                    return <span>
                        <strong>{pax}</strong> pax
                    </span>;
                }
            }
        ])
    }

    return <List
        dataSource={data}
        renderItem={(item) => {
            let itemData = item[1];
            return <List.Item>
                <div style={{ width: '100%' }}>
                    <h3>{item[0] && moment(item[0]).format('MMMM DD, YYYY (dddd)')}</h3>
                    <ETable
                        className="mc-table mc-table"
                        rowKey={(record) => {
                            if (!record) return undefined;
                            return `${record.date}::${record.groupName}::${record.lastKnownController}`
                        }}
                        pagination={false}
                        dataSource={itemData}
                        onRow={() => {
                            return {
                                style: {
                                    cursor: props.onGroupClicked ? 'pointer' : 'default'
                                }
                            }
                        }}
                        onRowClick={(record, index) => {
                            if (props.onGroupClicked){
                                props.onGroupClicked(record, index);
                            }
                        }}
                        size="small"
                        columns={columns}
                    />
                </div>
            </List.Item>
        }}
    />
}

export default ScheduleHistoryList;