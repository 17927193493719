import { Layout } from 'antd';
import Alert, { AlertProps } from 'antd/lib/alert';
import { LayoutProps } from 'antd/lib/layout';
import { ResultProps } from 'antd/lib/result';
import React, { ReactElement, useEffect, useState } from 'react';
import { MDDetailsProps } from './details';
import { MDHeaderProps } from './header';
import './layout.less';
import { MDTableProps } from './table';
import cn from 'classnames';

export interface MDLayoutProps extends LayoutProps {
    alertProps?: AlertProps,
    headerElement?: ReactElement<MDHeaderProps> | Array<ReactElement<MDHeaderProps>>,
    tableElement?: ReactElement<MDTableProps> | Array<ReactElement<MDHeaderProps>>,
    detailsElement?: ReactElement<MDDetailsProps> | Array<ReactElement<MDDetailsProps>>,
    noDataElement?: ReactElement<ResultProps> | Array<ReactElement<ResultProps>>,
    detailsVisible?: boolean,
    displayNoData?: boolean
}

const MDLayout: React.FC<MDLayoutProps> = (props) => {
    const [ alertClosed, setAlertClosed ] = useState<boolean>(false);
    useEffect(() => {
        if (alertClosed){
            setAlertClosed(false);
        }
    // eslint-disable-next-line
    }, [
        // eslint-disable-next-line
        props.alertProps && props.alertProps.message,
        // eslint-disable-next-line
        props.alertProps && props.alertProps.description,
        // eslint-disable-next-line
        props.alertProps && props.alertProps.type
    ])

    let bodyCls = cn({
        "mc-masterdata-body": true,
        "mc-masterdata-body-details-open": props.detailsVisible
    })

    return <Layout style={props.style} className="mc-masterdata-layout ant-layout-flex-row">
        <div className={bodyCls}>
            {props.headerElement}
            {props.alertProps ? (
                <Alert showIcon banner type="error" {...props.alertProps} closable onClose={() => setAlertClosed(true)} />
            ) : null}
            {!props.displayNoData && props.tableElement}
            {props.displayNoData && props.noDataElement}
        </div>
        {props.detailsVisible ? (
            <div className="mc-masterdata-details-wrapper">
                {props.detailsElement}
            </div>
        ) : null}
    </Layout>
}

export default MDLayout