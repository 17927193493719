import { Cell, ICellProps, IColumnProps } from '@blueprintjs/table';
import { Button, Modal } from 'antd';
import { PassengerEditor } from 'components/PassengerEditor';
import moment from 'moment';
import React, { useState } from 'react';
import { safeGet } from "../util";
import IsnStatusCell from './blueprintcells/IsnStatusCell';
import BlueprintColumnSet from "./blueprintcolumnset";

export class CommonColumnSet extends BlueprintColumnSet {
    columns: IColumnProps[] = []
    commonColumns: IColumnProps[] = [
        {
            id: 'nation',
            name: 'Nation',
            cellRenderer: this.basicCellRenderer('nation', row => {
                if (row.personID) {
                    return safeGet(['personID', 'nation'], row);
                } else {
                    return row.nation;
                }
            })
        },
        {
            id: 'transporterName',
            name: 'Transporter',
            cellRenderer: this.basicCellRenderer('transporterName', row => safeGet(['tpID', 'name'], row))
        },
        {
            id: 'customerName',
            name: 'Customer',
            cellRenderer: this.basicCellRenderer('customerName', row => safeGet(['customerID', 'name'], row))
        },
        {
            id: 'employerName',
            name: 'Employer',
            cellRenderer: this.basicCellRenderer('employerName', row => safeGet(['employerID', 'name'], row))
        },
        {
            id: 'departureName',
            name: 'Departure',
            cellRenderer: this.basicCellRenderer('departureName', row => safeGet(['departureID', 'name'], row))
        },
        {
            id: 'destinationName',
            name: 'Destination',
            cellRenderer: this.basicCellRenderer('destinationName', row => safeGet(['destinationID', 'name'], row))
        },
        {
            id: 'transitType',
            name: 'Transit Type',
            cellRenderer: this.basicCellRenderer('transitType', row => row.transitType)
        },
        {
            id: 'assignedFlight',
            name: 'Assigned Flight',
            cellRenderer: (idx) => {
                let row = this.getRow(idx);
                let flight = row && row.currentCarrierID;
                let cellProps = {
                    children: flight && flight.desig
                }
                return React.cloneElement(this.getCellElement(row, idx, 'assignedFlight'), cellProps)
            }
        },
        {
            id: 'checkedIn',
            name: 'Checked In',
            cellRenderer: (idx) => {
                let row = this.getRow(idx);
                let time = row && row.checkedInTime;
                let cellProps = {
                    children: moment(time).format('YYYY-MM-DD HH:mm:ss')
                }
                return React.cloneElement(this.getCellElement(row, idx, 'checkedIn'), cellProps);
            }
        },
        {
            id: 'personName',
            name: 'Name',
            cellRenderer: (idx) => {
                return <PaxEditCell paxEdit={this.paxEdit} row={this.getRow(idx)}></PaxEditCell>
            }
        },
        {
            id: 'basicName',
            name: 'Name',
            cellRenderer: this.basicCellRenderer('name', (row) => row.name)
        },
        {
            id: 'isnStatus',
            name: 'ISN Status',
            cellRenderer: (idx) => {
                let row = this.getRow(idx);
                if (this.renderCellElementMap && this.renderCellElementMap.isnStatus) {
                    return this.renderCellElementMap.isnStatus(row);
                }
                return <IsnStatusCell data={row} />
            }
        }
    ]

    getCommonColumnSet(colId: React.ReactText): IColumnProps {
        return this.commonColumns.find(({ id }) => id === colId);
    }

    getCommonColumnSets(colIds: React.ReactText[]): IColumnProps[] {
        if (colIds.length < 1) {
            return this.commonColumns
        }
        return this.commonColumns.filter(({ id }) => colIds.includes(id));
    }
}

export interface PaxEditCellProps {
    row: any,
    paxEdit: boolean
}

const PaxModalState = (visible = false, paxId = null) => {
    return { visible, paxId }
}

const PaxEditChild = props => (
    props.paxEdit ? <div><Button className="mc-link-btn" onClick={() => {
        props.setPaxModal(PaxModalState(true, props.data._id))
    }}>{ExtraBroadFlair(props)}</Button>{props.modal}</div>
        :
        <div>{ExtraBroadFlair(props)}</div>
);

const ExtraBroadFlair = (props) => {
    let name = `${props.row.lastName}, ${props.row.firstName}`
    let xbr = props.row.extraBroadState;

    if (props.row.personID) {
        xbr = props.row.personID.extraBroadState;
    }
    if (xbr === 'FAIL') {
        name = `\u2194 ${props.row.lastName}, ${props.row.firstName}`
    } else if (xbr === 'PASS') {
        name = `\uc6c3 ${props.row.lastName}, ${props.row.firstName}`
    }

    return name;
}

const PaxEditCell: React.FC<PaxEditCellProps & ICellProps> = (props) => {
    const { row, paxEdit, ...cellProps } = props;
    const [paxModal, setPaxModal] = useState(PaxModalState());
    var data;
    let children;

    if (row) {
        if (row.__typename === 'Person') {
            data = {
                ...row,
                personID: { ...row }
            }
        } else {
            data = row
        }

        if (paxEdit) {
            children = <PaxEditChild paxEdit={paxEdit} row={row} data={data} setPaxModal={setPaxModal} modal={<Modal
                title="Edit Passenger"
                visible={paxModal.visible}
                onCancel={() => setPaxModal(PaxModalState(false, paxModal.paxId))}
                footer={null}
                width="50rem"
            >
                <PassengerEditor
                    edit={true}
                    data={row}
                    onCancel={() => setPaxModal(PaxModalState(false, paxModal.paxId))}
                />
            </Modal>} />;
        } else {
            children = <PaxEditChild row={row} />
        }
    }

    return <Cell {...cellProps}>
        {children}
    </Cell>
}

export class PersonColumnSet extends CommonColumnSet {
    columns: IColumnProps[] = this.getCommonColumnSets([
        'personName',
        'customerName',
        'transporterName',
        'employerName'
    ])
}

export class PaxNodeColumnSet extends CommonColumnSet {
    columns: IColumnProps[] = [
        ...this.getCommonColumnSets([
            'personName',
            'checkedIn',
            'assignedFlight',
            'customerName',
            'employerName',
            'departureName',
            'destinationName',
            'transitType'
        ]),
        {
            id: 'paxWeight',
            name: 'Passenger Weight',
            cellRenderer: this.basicCellRenderer('paxWeight', row => row.paxWeight || 0, 'value')
        },
        {
            id: 'bagWeight',
            name: 'Bag Weight',
            cellRenderer: this.basicCellRenderer('bagWeight', row => row.bagWeight || 0, 'value')
        },
        {
            id: 'bagCount',
            name: 'Bag Count',
            cellRenderer: this.basicCellRenderer('bagCount', row => row.bagCount || 0, 'value')
        },
        {
            id: 'chargeCode',
            name: 'Charge Code',
            cellRenderer: this.basicCellRenderer('chargeCode', row => row.chargeCode, 'value')
        }
    ]
}

export class CargoNodeColumns extends CommonColumnSet {
    columns: IColumnProps[] = [
        ...this.getCommonColumnSets([
            'basicName',
            'checkedIn',
            'assignedFlight',
            'transporterName',
            'customerName',
            'departureName',
            'destinationName',
            'transitType'
        ]),
        {
            id: 'weight',
            name: 'Weight',
            cellRenderer: this.basicCellRenderer('weight', (row) => row.weight || 0, 'value')
        },
        {
            id: 'isHazmat',
            name: 'Hazmat',
            cellRenderer: this.basicCellRenderer('isHazmat', row => row.hazmat ? 'Yes' : 'No')
        },
        {
            id: 'hazmat',
            name: 'Hazmat',
            cellRenderer: this.basicCellRenderer('hazmat', row => {
                let text = row.hazmat ? 'Yes' : 'No';
                let code = row.hazmatUnCode;
                if (code) {
                    text = text.concat(` (${code})`)
                }
                return text
            })
        },
        {
            id: 'hazmatUnCode',
            name: 'Hazmat UN Code',
            cellRenderer: this.basicCellRenderer('hazmatUnCode', row => row.hazmatUnCode, 'value')
        },
        {
            id: 'attentionTo',
            name: 'Attention',
            cellRenderer: this.basicCellRenderer('attentionTo', row => row.attentionTo)
        },
        {
            id: 'approvedBy',
            name: 'Approved By',
            cellRenderer: this.basicCellRenderer('approvedBy', row => row.approvedBy)
        },
        {
            id: 'deliveredBy',
            name: 'Delivered By',
            cellRenderer: this.basicCellRenderer('deliveredBy', row => row.deliveredBy)
        },
        {
            id: 'initials',
            name: 'Initials',
            cellRenderer: this.basicCellRenderer('initials', row => row.initials)
        },
        {
            id: 'notes',
            name: 'Notes',
            cellRenderer: (idx) => {
                let row = this.getRow(idx);
                let cellProps = {
                    children: row && row.notes,
                    tooltip: row.notes,
                    truncated: true
                }
                return React.cloneElement(this.getCellElement(row, idx, 'notes'), cellProps);
            }
        }
    ];
}

export class LivePaxColumns extends CommonColumnSet {
    columns: IColumnProps[] = [
        ...this.getCommonColumnSets([
            'assignedFlight',
            'personName',
            'employerName'
        ]),
        {
            id: 'paxWeight',
            name: 'PAX WT',
            cellRenderer: this.basicCellRenderer('paxWeight', row => row.paxWeight || 0, 'value')
        },
        {
            id: 'bagWeight',
            name: 'Bag WT',
            cellRenderer: this.basicCellRenderer('bagWeight', row => row.bagWeight || 0, 'value')
        },
        {
            id: 'bagCount',
            name: 'Bag Count',
            cellRenderer: this.basicCellRenderer('bagCount', row => row.bagCount || 0, 'value')
        },
        ...this.getCommonColumnSets([
            'checkedIn',
            'departureName',
            'destinationName'
        ]),
        {
            id: 'tsa',
            name: 'TSA',
            cellRenderer: (idx) => {
                let row = this.getRow(idx);
                let cellProps: any = {}
                cellProps.children = 'Not checked';
                if (row.authorization) {
                    cellProps.intent = row.authorization.noFlyListed ? 'danger' : 'success'
                    cellProps.children = row.authorization.noFlyListed ? 'On No-Fly List' : 'Allowed'
                }
                return React.cloneElement(this.getCellElement(row, idx, 'tsa'), cellProps)
            }
        },
        ...this.getCommonColumnSets([
            'isnStatus'
        ]),
    ]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    PaxNodeColumnSet,
    CargoNodeColumns,
    PersonColumnSet,
    LivePaxColumns
}