import { Alert, Button, Switch, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { deepEqual } from 'assert';
import compose from 'lodash.flowright';
import sortedUniqBy from 'lodash/sortedUniqBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import FilterDropdown from './FilterDropdown';
import GetHighlightStateQuery from './GetHighlightStateQuery';
import highlightText from './highlightText';

class FilterableTable extends Component {
    state = {
        filters: null,
        visibleData: []
    }
    componentDidUpdate(prevProps){
        if (this.props.filters){
            try{
                deepEqual(this.props.filters, prevProps.filters)
            }
            catch(error){
                if (error.name === 'AssertionError'){
                    this.setState({ filters: this.props.filters })
                }
            }
        }
    }
    highlightEnabled = () => {
        const { highlightState } = this.props;
        if (highlightState){
            return Boolean(highlightState.highlightFiltered)
        }
        return false
    }
    setHighlight = (value) => {
        const { client } = this.props;
        client.writeData({
            data: {
                highlightFiltered: value
            }
        })
    }
    getFilters = () => this.state.filters || {}
    hasFilters = () => {
        const { filters } = this.state;
        if (!filters) return false
        return Object.values(this.state.filters).find(x => x ? x.length : null)
    }
    onFilterDropdownVisibleChange = (filterType) => (visible) => {
        if (visible) {
          setTimeout(() => {
            const input = this[filterType + 'SearchInput'];
            if (input) input.focus()
          });
        }
    }
    clearFilters = () => {
        this.onTableChange(
            this.state.pagination,
            null,
            this.state.sorter
        )
    }
    onTableChange = (pagination, filters, sorter, extra) => {
        if (this.props.onChange) this.props.onChange(pagination, filters, sorter, extra);
        this.setState({ pagination, filters, sorter, visibleData: extra ? extra.currentDataSource: []})
    }
    setSearchInputRef = (filterType) => (ref) => this[filterType + 'SearchInput'] = ref
    getColumns = () => {
        const filters = this.getFilters();
        const { columns, dataSource=[] } = this.props;
        if (!columns) return null;
        return columns.map(col => {
            
            const newCol = {
                ...col,
                render: (text, record, index) => {
                    var name;
                    if (col.render){
                        name = col.render(text, record, index);
                    }else{
                        name = text;
                    }
                    if (col.useSearchFilter && typeof name === 'string' && this.highlightEnabled() && this.state.filters && this.state.filters[col.key]){
                        return highlightText(name, this.state.filters[col.key][0])
                    }
                    return name;
                },
                filteredValue: filters[col.key] || null
            }
            if (col.onFilter){ //If a column can be filtered then a list of filters will be built from the dataSource
                
                if (col.useSearchFilter){
                    newCol.onFilterDropdownVisibleChange = this.onFilterDropdownVisibleChange(col.key);
                    newCol.filterDropdown = (props) => (
                        <FilterDropdown 
                            setSearchInputRef={this.setSearchInputRef(col.key)}
                            placeholder={col.dropdownPlaceholder || `Filter ${col.title}`}
                            {...props}
                        />
                    )
                }else if (!col.filters){
                    var getValueFunc = (record) => record[col.key];
                        // Get a specific value from a table record.
                    if (col.getValue) { 
                        getValueFunc = col.getValue;
                        // Override default getValue function if the column has a getValue function.
                    }else {
                        if (col.dataIndex) getValueFunc = (record) => record[col.dataIndex];
                        // Use dataIndex field if column does not have a getValue function defined.
                    }
                    const colData = dataSource
                        .map((record) => {
                            const val = getValueFunc(record);
                            return val;
                        })
                            // Use the getValue to narrow down the dataSource to a basic type that the sortedUniq function can use.
                        .filter(item => {
                            return item
                        })
                            // Filter out null values.
                        .sort((a, b) => ('' + a).toLowerCase().localeCompare(('' + b).toLowerCase()));
                            // Sort results
                    const filterChoices = sortedUniqBy(colData, (item) => {
                        if (typeof item === 'string') return item.toLowerCase();
                        return item
                    });
                        // Use lodash to remove duplicate filter choices from dataSource
                    if (filterChoices.length){
                        newCol.filters = filterChoices.map(text => ({
                            text,
                            value: text
                        }));
                    }
                }
            }
            return newCol
        })
    }
    render() {
        const { showFilterBar=true, ...restProps } = this.props;
        return (
            <React.Fragment>
                {this.hasFilters() && showFilterBar ? (
                    <Alert 
                        type="info"
                        style={{ marginBottom: '1rem' }} 
                        message={
                            <span>
                                <span>Filters are applied.</span> <Button className="mc-link-btn" onClick={() => this.clearFilters()} >Clear filters</Button>
                                {this.props.applyChargeCode ? 
                                <span style={{ marginLeft: '2rem' }}>
                                    <Search
                                        enterButton="Apply Charge Code"
                                        size="small"
                                        style={{maxWidth: 300}}
                                        onSearch={(value) => this.props.applyChargeCode(value, this.state.visibleData)}
                                    />
                                </span>
                                :
                                this.props.showHighlightMatches === undefined || this.props.showHighlightMatches === true ? (
                                    <span style={{ marginLeft: '2rem' }}>
                                        Highlight matches 
                                        <Switch style={{ marginLeft: '0.5rem' }} size="small" checked={this.highlightEnabled()} onChange={this.setHighlight} />
                                    </span>
                                ) : null}
                                
                            </span>
                        }
                        {...this.props.filterBarProps}
                    />
                ) : null}
                <Table {...restProps} columns={this.getColumns()} onChange={this.onTableChange} />
            </React.Fragment>
        )
    }
}

FilterableTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    showFilterBar: PropTypes.bool
}

export default compose(
    withApollo,
    graphql(
        GetHighlightStateQuery,
        {
            options: {
                errorPolicy: 'ignore'
            },
            name: 'highlightState'
        }
    )
)(FilterableTable)