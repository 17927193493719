import {ReactElement, useEffect, useRef} from "react";
import {ETableProps} from "./index";
import './enhanced-antd-table-group.less';

export interface ETableGroupProps {
    children: ReactElement<ETableProps> | ReactElement<ETableProps>[]
}

/**
 * A kind of nasty hack to make the fixed columns of the tables stay fixed only on the X-axis.
 * Y-axis coordinates are computed by the table offset.
 * Without this, the fixed columns will all attach to the top-left corner of the ETableGroup component's body.
 */
export function offsetFixedColumnsInTableGroup(tableGroupContainer: HTMLDivElement) {

    if (!tableGroupContainer) return;

    const groupElements = Array.from(tableGroupContainer.children);

    for (let groupElement of groupElements) {
        const tables = Array.from(groupElement.getElementsByClassName('ant-table')) as HTMLElement[];

        let idx = 0;
        for (let table of tables) {
            let offset = table.offsetTop;
            if (idx > 0) {
                offset += 0.5;
            }
            const fixedTables = [
                ...Array.from(table.getElementsByClassName('ant-table-fixed-left')),
                ...Array.from(table.getElementsByClassName('ant-table-fixed-right')),
            ];

            for (let fixedTable of fixedTables) {
                fixedTable.setAttribute('style', `top:${offset}px`);
            }
            idx++;
        }
    }
}

/**
 * Used to group ETable components vertically with scrolling.
 * Fixed columns in the table will display correctly within the group.
 */
const ETableGroup: React.FC<ETableGroupProps> = ({ children }) => {

    const containerRef = useRef<HTMLDivElement>(null);

    offsetFixedColumnsInTableGroup(containerRef.current);

    return <div className="mc-enhanced-table-group">
        <div className="mc-enhanced-table-group-scroll" ref={containerRef}>
            {children}
        </div>
    </div>
}

export default ETableGroup;