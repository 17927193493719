import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Popconfirm, Card, Icon } from 'antd';
import moment from 'moment';

const qcStatusColor = (status) => {
    if (typeof status === 'string'){
        switch (status.toUpperCase()) {
            case 'RED':
            case 'RED – SUSPENDED':
            case 'RED SUSPENDED':
                return '#f5222d'
            case 'GREEN':
            case 'GREEN – WAIVED':
            case 'GREEN WAIVED':
                return '#52c41a'
            case 'MULTI':
                return '#faad14'
            case 'OVERRIDDEN':
                return '#1890ff'
            default:
                return null
        }
    }
    return 'inherit'
}

const companyGradeColor = (grade) => {
    switch (grade) {
        case 'F':
            return '#f5222d'
        default:
            return null;
    }
}

class QCCard extends Component {
    setActive = () => {
        this.props.onActivated(this.props.data)
    }
    render() {
        const { data, active, allowSetActive } = this.props;
        const selectedColor = '#1890ff';
        var style = {
            marginBottom: '1rem'
        }
        if (active){
            style = {
                ...style,
                borderColor: selectedColor
            }
        }
        
        return (
            <Card style={style}>
                <Row>
                    <Col span={18}>
                        <Row><Col span={8}><h4>Quick Check Status:</h4></Col><Col span={16}>
                            <span style={{ color: qcStatusColor(data.quickCheckStatus) }} >{data.quickCheckStatus}</span>
                        </Col></Row>
                        {(!data.allowed) ? <Row><Col span={8}><h4>Reason:</h4></Col><Col span={16}>{data.reasonsForNonCompliance || data.reason || 'Not specified'}</Col></Row>: null}
                        {(data.errorMessage) ? <Row><Col span={8}><h4>Error Message:</h4></Col><Col span={16}>{data.errorMessage}</Col></Row>: null}
                        <Row><Col span={8}><h4>Name:</h4></Col><Col span={16}>{`${data.lastName}, ${data.firstName}`}</Col></Row>
                        <Row><Col span={8}><h4>Training Status:</h4></Col><Col span={16}>{data.trainingStatus || 'N/A'}</Col></Row>
                        {data.scCompanyGrade ? (
                            <Row><Col span={8}><h4>Company Grade:</h4></Col><Col span={16}>
                                <span
                                    style={{ color: companyGradeColor(data.scCompanyGrade) }}
                                >{data.scCompanyGrade}{` `}</span>
                                {data.scCompanyGradeSince ? <span>
                                    as of {moment(data.scCompanyGradeSince).format('MMM Do YYYY')}
                                </span> : null}
                            </Col></Row>
                        ) : null}
                        <Row><Col span={8}><h4>ISN ID:</h4></Col><Col span={16}>{data.isnId}</Col></Row>
                        <Row><Col span={8}><h4>Company:</h4></Col><Col span={16}>{data.companyName}{data.companyID && ` (${data.companyID})`}</Col></Row>
                        <Row><Col span={8}><h4>Next Expiration:</h4></Col><Col span={16}>
                            {(data.mostRecentTrainingQualificationExpirationName) ? 
                                `${data.mostRecentTrainingQualificationExpirationName} expires ${data.mostRecentTrainingExpirationDate}` : 'Not defined'
                            }
                        </Col></Row>
                        {data.trainingQualificationProject ? (
                            <Row>
                                <Col span={8}><h4>Training Qualification Project: </h4></Col><Col span={16}>{data.trainingQualificationProject.name}</Col>
                            </Row>
                        ) : null}
                        {data.operatorQualificationProject ? (
                            <Row>
                                <Col span={8}><h4>Operator Qualification Project: </h4></Col><Col span={16}>{data.operatorQualificationProject.name}</Col>
                            </Row>
                        ) : null}
                        {data.onlineTrainingQualificationProject ? (
                            <Row>
                                <Col span={8}><h4>Online Training Qualification Project: </h4></Col><Col span={16}>{data.onlineTrainingQualificationProject.name}</Col>
                            </Row>
                        ) : null}
                    </Col>
                    <Col span={6}>
                        <Row type="flex" justify="end">
                            {(!active) ? (
                                allowSetActive ? (
                                    <Popconfirm 
                                        placement="left"
                                        title="Are you sure you want to set this ISN result to active?"
                                        onConfirm={this.setActive}
                                        okText="Set Active" cancelText="Cancel">
                                        <Button type="primary">Set Active</Button>
                                    </Popconfirm>
                                ) : null
                            )
                            : <div style={{color: selectedColor}}><Icon type="check" /> Active</div>}
                        </Row>
                    </Col>
                </Row>
                {data.dataAsOf &&
                    <Card.Meta style={{marginTop: '1rem', textAlign: 'right'}} description={data.dataAsOf} />
                }
            </Card>
        )
    }
}

QCCard.propTypes = {
    data: PropTypes.object.isRequired,
    onActivated: PropTypes.func.isRequired,
    active: PropTypes.bool
}

export default QCCard