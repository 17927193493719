import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const withGlobalFilter = (Component) => ({ data, ...restProps }) => {
    return (
        <Query query={
            gql`
            {
                globalFilter @client {
                    visible
                    customer {
                        key
                        label
                    }
                    transporter {
                        key
                        label
                    }
                    departure {
                        key
                        label
                    }
                    destination {
                        key
                        label
                    }
                    employer {
                        key
                        label
                    }
                    pax {
                        lastName
                        firstName
                    }
                    cgo {
                        name
                    }
                }
            }
            `
        }>
        {({ data }) => {
            return <Component {...restProps} globalFilter={data.globalFilter} />
        }}
        </Query>
    )
}

export default withGlobalFilter