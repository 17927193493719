import {ScheduledGroup, ScheduledPaxCgoGroupV2Leg} from "common/types/schedule";
import React from "react";
import {produce, WritableDraft} from "immer";
import {difference, union} from "lodash";
import {PaxNode} from "common/types/carriable";

// ---------------------------------------------------------------------------------------------------------------------
// State Machine for the scheduled-pax-cgo-adder component
// ---------------------------------------------------------------------------------------------------------------------

export const SearchEventTypes = {
    'LOCATION': 'Location',
    'NAME': 'Name',
    'EMPLOYER': 'Employer'
} as const;

// Events when a user clicks a button to perform an action
export type ActionEvent =
{
    type: 'FLIGHT',
    action: 'UPDATE',
    payload: {
        paxnodeIds: string[],
        cgonodeIds: string[]
    }
} |
{
    type: 'COLLAPSE.GROUPS',
    action: 'SET',
    payload: {
        panelKeys: string | string[]
    }
} |
{
    type: 'COLLAPSE.LEGS',
    action: 'SET',
    payload: {
        group: ScheduledGroup,
        panelKeys: string | string[]
    }
} |
{
   type: 'MODAL.PAX' | 'DRAWER.ISN',
   action: 'OPEN',
   payload: {
       paxData: PaxNode
   }
} |
{
    type: 'MODAL.PAX' | 'DRAWER.ISN',
    action: 'CLOSE'
} |
{
    type: 'ACTIVE.SEARCH',
    action: 'SET',
    payload: {
        activeSearch: SearchEvent['type']
    }
} |
{
    // Clear all search
    type: 'SEARCH',
    action: 'CLEAR'
}

// Events when a user types into a search box
export type SearchEvent = {
    type: keyof typeof SearchEventTypes,
    action: 'SET',
    payload: {
        text: string
    }
} |
{
    type: keyof typeof SearchEventTypes,
    action: 'CLEAR'
}

// Events when a user selects something
export type SelectionEvent = {
    type: 'ENTITY',
    action: 'ADD' | 'REMOVE' | 'SET' | 'TOGGLE'
    payload: {
        keys: string[]
    }
} |
{
    type: 'ENTITY',
    action: 'CLEAR'
}

export type AbstractEvent = {
    type: string,
    action: string,
    payload?: any
}

export const DEFAULT_STATE = {
    selectedEntities: [] as string[],
    search: {} as Record<SearchEvent['type'], string>,
    activeSearch: 'LOCATION' as SearchEvent['type'],
    openGroupPanels: [] as string[],
    openLegPanels: {} as {
        [groupID: string]: string[]
    },
    paxModal: {
        open: false,
        paxData: null as PaxNode
    },
    isnDrawer: {
        open: false,
        paxData: null as PaxNode
    }
}

export type State = typeof DEFAULT_STATE

export type Event =
    { category: 'SELECTION' } & SelectionEvent |
    { category: 'SEARCH' } & SearchEvent |
    { category: 'ACTION' } & ActionEvent

var EventHandlers = {
    SearchEventHandler(state: WritableDraft<State>, event: SearchEvent){
        if (event.action === 'SET'){
            state.search[event.type] = event.payload.text;
        }
        if (event.action === 'CLEAR' && state.search[event.type]){
            delete state.search[event.type];
        }
    },
    SelectionEventHandler(state: WritableDraft<State>, event: SelectionEvent){

        switch (event.type){
            case 'ENTITY':
            {
                if (event.action === 'CLEAR'){
                    state.selectedEntities = Helper.ApplyCSRAT(event.action, state.selectedEntities);
                }
                else {
                    state.selectedEntities = Helper.ApplyCSRAT(event.action, state.selectedEntities, event.payload.keys);
                }
            }
        }
        return;
    },
    ActionEventHandler: (state: WritableDraft<State>, event: ActionEvent) => {

        function getPanelKeys(keys: string | string[]){
            if (Array.isArray(keys))
                return keys;
            else
                return [keys];
        }

        switch (event.type){
            case 'COLLAPSE.GROUPS':
            {
                if (event.action === 'SET'){
                    state.openGroupPanels = getPanelKeys(event.payload.panelKeys);
                    const legPanelsCopy = [].concat(state.openLegPanels);
                    for (const key in legPanelsCopy){
                        // Collapse any leg panels if not found in panelKeys
                        if (!state.openGroupPanels.includes(key)){
                            delete state.openLegPanels[key]
                        }
                    }
                }
                return;
            }
            case 'COLLAPSE.LEGS':
            {
                if (event.action === 'SET'){
                    const group = event.payload.group;
                    const groupID = Helper.panelKey.stringify(group);

                    state.openLegPanels[groupID] = getPanelKeys(event.payload.panelKeys);
                }
                return;
            }
            case 'MODAL.PAX':
            case 'DRAWER.ISN':
            {
                const keyNames = {
                    'MODAL.PAX': 'paxModal',
                    'DRAWER.ISN': 'isnDrawer'
                };

                // If payload.paxData is null, then close the modal/drawer

                if (event.action === 'OPEN' && event.payload?.paxData){
                    state[keyNames[event.type]] = {
                        open: true,
                        paxData: event.payload.paxData
                    }
                }
                if (event.action === 'CLOSE' || !event.payload?.paxData){
                    state[keyNames[event.type]] = {
                        open: false,
                        paxData: state[keyNames[event.type]].paxData
                    }
                }
                return;
            }
            case 'ACTIVE.SEARCH':
            {
                if (event.action === 'SET'){
                    state.activeSearch = event.payload.activeSearch;
                }
                return;
            }
            case 'SEARCH':
            {
                if (event.action === 'CLEAR'){
                    state.search = DEFAULT_STATE.search;
                }
                return;
            }
        }
    }
}

export class Helper {

    // Applies the clear, set, remove, and add operations to a list of strings.
    static ApplyCSRAT(action: 'CLEAR' | 'SET' | 'REMOVE' | 'ADD' | 'TOGGLE', stringArray: string[], payload?: string[]){
        if (!Array.isArray(payload) && action !== 'CLEAR') return stringArray;

        switch (action){
            case 'CLEAR':
                stringArray = [];
                break;
            case 'SET':
                stringArray = payload;
                break;
            case 'REMOVE':
                stringArray = difference(stringArray, payload);
                break;
            case 'ADD':
                stringArray = union(stringArray, payload);
                break;
            case 'TOGGLE':
                payload.forEach(str => {
                    if (stringArray.includes(str)){
                        stringArray = difference(stringArray, payload);
                    }
                    else {
                        stringArray = union(stringArray, payload);
                    }
                })
                break;
        }
        return stringArray;
    };

    static panelKey = {
        stringify: (group: ScheduledGroup) => [group.scheduledGroup, group.scheduledFlightDate, group.lastKnownController?._id].join('::'),
        parse: (str: string) => {
            const data = str.split("::");
            if (data.length >= 3){
                return {
                    groupName: data[0],
                    groupDate: data[1],
                    lastKnownController: data[2]
                }
            }
        }
    }

    static legKey = {
        stringify: (leg: ScheduledPaxCgoGroupV2Leg) => [leg.departureDoc?.name, leg.destinationDoc?.name].join('::'),
        parse: (str: string) => {
            const data = str.split("::");
            if (data.length >= 3){
                return {
                    departureName: data[0],
                    destinationName: data[1],
                }
            }
        }
    }
}

export const Reducer: React.Reducer<State, Event> = (state, action) => {
    return produce(state, (draftState) => {
        switch (action.category) {
            case 'SELECTION': {
                EventHandlers.SelectionEventHandler(draftState, action);
                return;
            }
            case 'SEARCH': {
                EventHandlers.SearchEventHandler(draftState, action);
                return;
            }
            case 'ACTION':
                EventHandlers.ActionEventHandler(draftState, action);
                return;
        }
    });
}