import { Button, Col, Icon, Row, Tooltip } from 'antd';
import Input, { SearchProps } from 'antd/lib/input';
import React from 'react';
import './header.css';
import './masterdata.less';

export interface MDHeaderProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'style'> {
    searchInputProps?: SearchProps,
    inputElement?: JSX.Element,
    showReloadButton?: boolean,
    reloading?: boolean,
    newEntryText?: string,
    onNewEntry?(): void,
    onReload?(): void,
    hasFiltersApplied?: boolean,
    onFiltersClear?(): void,
    title?: string
}

const MDHeader: React.FC<MDHeaderProps> = (props) => {
    return <Row type="flex" gutter={12} className={`mc-masterdata-header ${props.className || ''}`} style={props.style}>
        {props.title ? (
            <Col style={{ marginRight: '2rem', marginLeft: '2rem' }}>
                <h3 style={{ margin: 0 }}>{props.title}</h3>
            </Col>
        ) : null}
        <Col>
            {props.inputElement ? props.inputElement : <Input.Search className="mc-masterdata-header-search" allowClear {...props.searchInputProps} />}
        </Col>
        {props.hasFiltersApplied && props.onFiltersClear ? (
            <Col>
                <Button type="link" onClick={() => props.onFiltersClear()}>Clear Filters</Button>
            </Col>
        ) : null}
        <div style={{ flex: 1 }} />
        {props.showReloadButton || true ? (
            <Col>
                <Tooltip placement="bottom" title="Refresh" mouseEnterDelay={1}>
                    <Button
                        className="mc-masterdata-header-refresh"
                        onClick={() => props.onReload && props.onReload()}
                    >
                        <Icon type={props.reloading ? 'loading' : 'sync'} />
                    </Button>
                </Tooltip>
            </Col>
        ) : null}
        {props.onNewEntry ? (
            <Col>
                <Button
                    onClick={() => props.onNewEntry()}
                    type="primary"
                >{props.newEntryText ? props.newEntryText : 'New Entry'}</Button>
            </Col>
        ) : null}
    </Row>
}

export default MDHeader