import gql from "graphql-tag";

export const OrganizationFragment = gql`
fragment OrganizationFragment on OrganizationInterface {
    _id
    name
    type
    classType
    requireOverrideFields1
    useV1Scheduler
}
`