import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { GlobalAppStateContext } from 'context/global-app-state';
import windowSizes from 'common/windowSizes';

const { Sider } = Layout;

const PobNav = () => {
    const { state: { sidebarCollapsed }, setState } = useContext(GlobalAppStateContext);
    const [ hasExpanded, setHasExpanded ] = useState(false);

    useEffect(() => {
      if (!sidebarCollapsed && !hasExpanded){
        setHasExpanded(true);
      }
    }, [ sidebarCollapsed ])


    const history = useHistory();
    const { pathname } = useLocation();

    function handleMenuClick({ key }) {
        history.push(`/app/${key}`);
        if (window.innerWidth <= windowSizes.small){
            setState({ sidebarCollapsed: true })
          }
    }

    const pathArray = pathname.split('/').filter(path => path);
    var key = pathArray[1];

    let menu = <Menu
        selectedKeys={[key]}
        defaultOpenKeys={['pob']}
        mode="inline"
        style={{height: '100%'}}
        onClick={handleMenuClick}
        theme="dark"
    >
        <Menu.Item key="pob">
            Stations
        </Menu.Item>
    </Menu>

    if (!hasExpanded) menu = null;

    return (
        <Sider
        collapsed={sidebarCollapsed}
        breakpoint="lg"
        >
            {menu}
        </Sider>
    )
}

export default PobNav