import { Column } from '@blueprintjs/table';
import { Button, Divider, Input, List } from 'antd';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import gql from 'graphql-tag';
import React, { useRef } from 'react';
import { DuplicationChecker, MDDetails, MDHeader, MDLayout, MDTable, useMasterDataState } from '..';
import VesselEntryForm from '../entryForms/vessel';
import TableLoader from '../TableLoader';
import { getMDTableProps, MasterDataProps, MDStateToDetailsProps, MDStateToHeaderProps, MDStateToLayoutProps, MDStateToTableScrollerProps, renderCellFromKeyIndex, renderNoData, searchValuesToQuerySearchPayload, trimString } from '../util';
import DeleteEntity from 'Mutations/DeleteEntity';



export interface MDVesselProps extends WithOrgDataProps, MasterDataProps {

}

const QUERY = gql`
    query VesselQuery($filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $limit: Int, $skip: Int) {
        MasterDataVessels(filter: $filter, search: $search, limit: $limit, skip: $skip) {
            docs {
                ... on Vessel {
                    _id
                    _rev
                    name
                }
            }
        }
    }
`

const MUTATION = gql`
    mutation VesselMutation(
        $_id: ID
        $name: String!
        $tpID: ID!
        $customerID: ID!
    ){
        setVessel(
            _id: $_id
            name: $name
            tpID: $tpID
            customerID: $customerID
        ){
            _id
            _rev
            name
            tpID
            customerID
        }
    }
`

const MDVessel: React.FC<MDVesselProps> = (props) => {

    const formRef = useRef(null);

    let tpID = props.orgData.getOrgIDByType('transporter');

    //>  THIS WILL FILTER OUR LIST to ONLY ALLOW (Our USERS COMPANY 'VESSELS' to SHOW in LIST)
    let customerID = props.orgData.getOrgIDByType('customer');


    const MDState = useMasterDataState({
        getQueryData: (data: any) => data.MasterDataVessels.docs,
        getQueryVariables: (searchValues) => ({
            filter: [
                {
                    key: 'tpID',
                    value: JSON.stringify(tpID)
                },
                {
                    key: 'customerID',
                    value: JSON.stringify(customerID)
                }
            ],
            search: searchValuesToQuerySearchPayload(searchValues)
        }),
        queryGQL: QUERY,
        saveMutationGQL: MUTATION,
        deleteMutationGQL: DeleteEntity,
        tpID: tpID,
        paginationLimit: props.dataPaginationLimit,
        pollInterval: props.pollInterval,
        updateQueryAfterFetchMore: (prev, fetchMoreResult) => {
            return {
                ...prev,
                MasterDataVessels: {
                    ...prev.MasterDataVessels,
                    docs: [
                        ...prev.MasterDataVessels.docs,
                        ...fetchMoreResult.MasterDataVessels.docs
                    ]
                }
            }
        }
    })

    // const [ save, { loading: saving, error: saveError, data: savedData } ] = useMutation(MUTATION, {
    //     onCompleted: () => {
    //         MDState.cancelEditing(false);
    //     }
    // });

    const data = MDState.data;

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                name: MDState.getSearchValue('name')
            })
        })}
        headerElement={<MDHeader
            {...MDStateToHeaderProps(MDState)}
            inputElement={
                <Input.Group compact>
                    <Input
                        onChange={(e) => MDState.onSearchValueChange('name', e.target.value)} placeholder="Search by Name"
                        value={MDState.getSearchValue('name')}
                        style={{ width:'15rem' }}
                        allowClear
                    />
                </Input.Group>
            }
        />}
        tableElement={<TableLoader mdState={MDState}>
                <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)}>
                    <MDTable
                        {...getMDTableProps(data, MDState)}
                        hideDelete
                    >
                        <Column name="Name" cellRenderer={renderCellFromKeyIndex(data, 'name')} />
                    </MDTable>
                </BlueprintTableInfiniteScroller>
            </TableLoader>}
        detailsElement={<MDDetails
            {...MDStateToDetailsProps(MDState, 'name')}
            onSave={() => {
                formRef.current.validateFieldsAndScroll((err, values) => {
                    if (!err){
                        MDState.save({
                            ...values,
                            name: trimString(values.name),
                            _id: MDState.getEntryFieldValue('_id'),
                            customerID: customerID
                        })
                    }
                })
            }}
        >
            <VesselEntryForm
                formFields={MDState.entryFields}
                onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                uppercaseFields={['name']}
                autoFocus
                ref={formRef}
                dupeChecker={MDState.isNewEntry ? (
                    <DuplicationChecker
                        skip={!trimString(MDState.getEntryFieldValue('name'))}
                        getData={data => data && data.MasterDataVessels && data.MasterDataVessels.docs}
                        renderItems={(items) => {
                            return <List size="small">
                                {items.map((craft) =>
                                    <List.Item>
                                        <Button
                                            className="mc-link-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                MDState.editFromExisting(craft, false)
                                            }}
                                    >
                                        <strong>Name: </strong>{craft.name}
                                        <Divider type="vertical" />
                                    </Button>
                                    </List.Item>
                                )}
                            </List>
                        }}
                        query={QUERY}
                        variables={{
                            filter: [
                                {
                                    key: 'tpID',
                                    value: JSON.stringify(props.orgData.getOrgIDByType('transporter'))
                                }
                            ],
                            search: [
                                {
                                    key: 'name',
                                    value: JSON.stringify(trimString(MDState.getEntryFieldValue('name')))
                                }
                            ]
                        }}
                    />
                ) : null
                }
            />
            <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
        </MDDetails>}
    />
}

export default WithOrgData(MDVessel)