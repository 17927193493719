import { Alert, Button, Icon, Spin } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import React, { HTMLAttributes } from 'react';
import { useQuery } from 'react-apollo';

const LoadIndicator = () => {
    return <div style={{ padding: '1rem', textAlign: 'center' }}>
        <Spin indicator={<Icon type="loading" />} tip="Checking for existing records" />
    </div>
}

const Error = ({ refetch }) => {
    return <div style={{ padding: '1rem', textAlign: 'center', color: 'red' }}>
        <div><Icon type="exclamation-circle" /></div>
        <div>Could not check for existing records. <Button className="mc-link-btn" onClick={() => refetch()}>Retry?</Button></div>
    </div>
}

export interface DuplicationCheckerProps extends Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> {
    variables: any,
    query: any,
    getData: (data: any) => Array<any>,
    renderItems(items: Array<any>): JSX.Element,
    skip?: boolean,
    renderMessage?(items: Array<any>): JSX.Element,
    title?: string,
    alertProps?: Partial<AlertProps>
}

const DuplicationChecker: React.FC<DuplicationCheckerProps> = (props) => {
    const { variables, query, skip, getData, renderItems, renderMessage, style, className } = props;

    const { data, error, networkStatus, refetch } = useQuery(query, {
        variables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        skip
    })

    if (networkStatus <= 4){
        return <LoadIndicator />
    }
    if (error){
        console.error('Duplicate Search failed!', error);
        return <Error refetch={refetch} />
    }
    if (!data) return null;
    let items = getData ? getData(data) : [];
    if (!Array.isArray(items)){
        items = [];
    }
    if (!items.length) return null;
    let messageBeginning = <span><strong>{items.length} records</strong> have been found that are</span>
    if (items.length === 1){
        messageBeginning = <span>A record has been found that is</span>
    }
    if (typeof renderMessage === 'function'){
        var message = renderMessage(items);
    }
    else
    {
        message = <p>{messageBeginning} similar to what you have entered.
        It is highly recommended that you edit an existing record by clicking an item below.
        </p>
    }
    return <Alert
        type="warning"
        message={props.title || "Duplication Warning"}
        showIcon
        style={style}
        className={`mc-duplication-checker ${className || ''}`} 
        description={
            <div>
                {message}
                {renderItems(items)}
            </div>
        }
        {...props.alertProps}
    />
}

export default DuplicationChecker