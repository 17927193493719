import gql from "graphql-tag";

export default gql`
mutation RemoveCredential(
    $_id: ID
    $tpID: ID!
){
    setCredential(
        _id: $_id
        deleted: true
        tpID: $tpID
    ){
        _id
        key
        expiration
        personID {
            _id
        }
        type
    }
}
`