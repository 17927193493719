import { concat } from "lodash";
import appSchem from "./app";
import transporterSchem from "./transporter";
import customerSchem from "./customer";
import authSchem from "./auth";
import dashboardSchem from "./dashboard";
import SchedulingSchema from "./scheduling";

export const TypeDefs = concat(appSchem, transporterSchem, customerSchem, authSchem, dashboardSchem, SchedulingSchema);

export default TypeDefs;