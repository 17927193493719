import React from 'react';
import MainLayout from '../MainLayout';
import { Layout, Row, Button, Col, Form, Select, Input, Table, Popconfirm, Tooltip, Alert, message } from 'antd';
import MainHeader from '../MainHeader';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import LoadingContent from '../../LoadingContent';
import WithOrgData from '../../WithOrgData';
import withCognitoUser from '../../WithCognitoUser';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ButtonLink from '../../ButtonLink';
import DeleteEntity from '../../../Queries/DeleteEntity';
import NotAuthorized from '../../NotAuthorized';
import { UserGroup } from '../../userGroup';

const ALL_SETS = gql`
query AllSets($customerID: ID! $tpID: ID! $owner: String){
    getPersonSets(
        customerID: $customerID
        tpID: $tpID
        owner: $owner
    ){
        _id
        name
        outbound {
            _id
        }
        inbound {
            _id
        }
        owner
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        owner
        modifiedTs
    }
}
`
const OWNER_FILTER = gql`
{
    userFilter @client {
        paxSets {
            owner
        }
    }
}
`

const SET_OWNER_FILTER = gql`
mutation SetOwnerFilter($payload: String!){
    setPaxSetsOwnerFilter(payload: $payload) @client
}
`

const { Content } = Layout;

const SetTable = ({ customerID, tpID, owner, filterOwner, org, searchFilter }) => {
    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (name, record) => <Link to={`/app/scheduling/sets/${record._id}`}>{name}</Link>
        },
        {
            title: 'Departure',
            key: 'departure',
            dataIndex: 'departureID',
            render: dep => dep && dep.name
        },
        {
            title: 'Destination',
            key: 'destination',
            dataIndex: 'destinationID',
            render: dest => dest && dest.name
        },
        {
            title: 'PAX Outbound',
            key: 'outbound',
            dataIndex: 'outbound',
            render: items => Array.isArray(items) && items.length
        },
        {
            title: 'PAX Inbound',
            key: 'inbound',
            dataIndex: 'inbound',
            render: items => Array.isArray(items) && items.length
        },
        {
            title: 'Modified',
            key: 'modified',
            dataIndex: 'modifiedTs',
            render: modified => moment(modified).format('DD-MMM-YYYY HH:mm:ss')
        },
        {
            title: 'Owned By',
            key: 'owner',
            dataIndex: 'owner',
            render: email => email === owner ? 'You' : email
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Row type="flex" gutter={12}>
                    <Col>
                        <Tooltip title="Edit">
                            <ButtonLink to={`/app/scheduling/sets/${record._id}/edit`} icon="edit" shape="circle" size="small" />
                        </Tooltip>
                    </Col>
                    <Col>
                            <Mutation
                                mutation={DeleteEntity} 
                                variables={{_id: record._id, tpID}}
                                refetchQueries={[
                                    {
                                        query: ALL_SETS,
                                        variables: {
                                            customerID, 
                                            tpID, 
                                            owner: filterOwner ? owner : null
                                        }
                                    }
                                ]}
                                onCompleted={() => {
                                    message.success('Set deleted successfully');
                                }}
                                onError={(error) => {
                                    console.error(error)
                                    message.error('Failed to delete set')
                                }}>
                            {
                                (deleteEntity, { loading }) => (
                                    <Popconfirm title="Are you sure?" okText="Delete" onConfirm={() => deleteEntity()} >
                                        <Tooltip title="Delete" >
                                            <Button loading={loading} icon="delete" shape="circle" type="danger" size="small" />
                                        </Tooltip>
                                    </Popconfirm>
                                )
                            }
                            </Mutation>
                    </Col>
                    <Col>
                        <ButtonLink to={`/app/scheduling/sets/${record._id}/schedule`} size="small">Schedule</ButtonLink>
                    </Col>
                </Row>
            )
        }
    ]
    return (
        <Query query={ALL_SETS} variables={{customerID, tpID, owner: filterOwner ? owner : null}} fetchPolicy="network-only" pollInterval={5000}>
            {
                ({ data, loading, error }) => {
                    if (loading) return <LoadingContent />
                    if (error) return <Alert type="error" message="An error occurred" description={error.message} showIcon />
                    const { getPersonSets: personSets } = data;
                    const dataSource = personSets.filter(row => {
                        if (row.name && row.name.toLowerCase().includes(searchFilter)) return true
                        if (row.departureID && row.departureID.name.toLowerCase().includes(searchFilter)) return true
                        if (row.destinationID && row.destinationID.name.toLowerCase().includes(searchFilter)) return true
                        return false
                    }).sort((a, b) => {
                        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    })
                    return (
                        <Table
                            className="mc-table"
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            size="small"
                            rowKey={record => record._id}
                        />
                    )
                }
            }
        </Query>
    )
}

class SetsList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            search: ''
        }
    }
    search = (value) => this.setState({ search: value.toLowerCase() })
    renderContent = (filterOwner) => {
        const mainHeaderProps = {
            routes: [
                {
                    path: '/app/scheduling/sets',
                    title: 'Passenger Sets'
                }
            ],
            formitems: (
                <React.Fragment>
                    <Form.Item label="Filter">
                        <Mutation mutation={SET_OWNER_FILTER} onError={console.error}>
                            {
                                (setFilter) => (
                                    <Select showAction={["focus", "click"]} value={filterOwner} style={{width: '10rem'}} onChange={v => {
                                        setFilter({
                                            variables: {
                                                payload: v
                                            }
                                        })
                                    }}>
                                        <Select.Option value="own">Your Sets</Select.Option>
                                        <Select.Option value="all">All Sets</Select.Option>
                                    </Select>
                                )
                            }
                        </Mutation>
                    </Form.Item>
                    <Form.Item>
                        <Input.Search placeholder="Filter Sets" onChange={(e) => this.search(e.target.value)} />
                    </Form.Item>
                </React.Fragment>
            ),
            actions: (<ButtonLink to={`/app/scheduling/sets/new`} type="primary">New Set</ButtonLink>)
        }

        const customerID = this.props.orgData.customer._id;
        const tpID = this.props.orgData.transporter._id;
        return (
            <MainLayout >
                <MainHeader {...mainHeaderProps} />
                <Content className="sets-list-content">
                    <SetTable 
                        customerID={customerID} 
                        tpID={tpID} 
                        owner={this.props.cognitoUser.attributes.email} 
                        filterOwner={filterOwner === 'own'} 
                        org={this.props.orgData.transporter.name}
                        searchFilter={this.state.search} />
                </Content>
            </MainLayout>
        )
    }
    render(){
        return (
            <Query query={OWNER_FILTER}>
                {
                    ({ data, loading, error }) => {
                        if (error) return <Alert type="error" message="An error occurred" description={error.message} showIcon />
                        if (!loading && data) {
                            return this.renderContent(data.userFilter.paxSets.owner)
                        }
                        return null
                    }
                }
            </Query>
        )
    }
}

const NotAuth = () => (
    <MainLayout>
        <NotAuthorized />
    </MainLayout>
)

const withPermissions = props => (
    <UserGroup.Flytsuite.Schedule.Sets renderNotAuthorized={<NotAuth />}>
        <SetsList {...props} />
    </UserGroup.Flytsuite.Schedule.Sets>
)

export default withCognitoUser( WithOrgData( withPermissions ) )