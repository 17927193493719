import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.less';
import './css/MCTable.less';
import Main from './components/Main';

// Imports needed for these styles to apply on popped out windows
import './components/flytwatch/aircraft-details/style.less';
import './pages/app/flytwatch/style.less';
// --------------------------------------------------------------

// import 'antd/dist/antd.css';
import 'antd/lib/transfer/style';
import '../node_modules/react-vis/dist/style.css';
import './css/BlueprintTableFix.css';
import '@blueprintjs/table/lib/css/table.css';
import 'react-reflex/styles.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import Amplify from '@aws-amplify/core';
import config from './config';
import ServiceWorkerProvider from './context/serviceworker';
import BackspaceDisabler from 'backspace-disabler';
// import AWS from 'aws-sdk';


// AWS.config.region = 'us-east-1';

Amplify.configure(config.amplify);
BackspaceDisabler.disable();


const root = <ServiceWorkerProvider>
    <Main />
</ServiceWorkerProvider>

ReactDOM.render(root, document.getElementById('root'));