import {ColumnProps} from "antd/lib/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxOpen} from "@fortawesome/free-solid-svg-icons";
import {Icon, Tooltip, Typography} from "antd";
import React from "react";
import {NotesForPilotQuickEdit} from "./notes-for-pilot/quickedit/notes-for-pilot-quick-edit";
import {FlightLeg} from "../../../schema";

type TEMP_TYPE_Pax = any;
type TEMP_TYPE_Cgo = any;
type TEMP_TYPE_Entity = TEMP_TYPE_Pax | TEMP_TYPE_Cgo

export function createScheduledEntityColumns(leg: FlightLeg=null, options?: { containerRef?: HTMLElement, reviewMode?: boolean }){

    const { containerRef, reviewMode } = options;

    const columns: ColumnProps<TEMP_TYPE_Entity>[] = [
        {
            title: 'Scheduled Group',
            key: 'scheduledGroup',
            dataIndex: 'scheduledGroup'
        },
        {
            title: 'Name',
            key: 'name',
            render: (_, record: TEMP_TYPE_Entity) => {
                let iconStyle = {
                    marginRight: 6
                }

                let icons = [];

                const name = record.__typename === 'CargoNode' ? 'CGO' : 'PAX';
                let paxDep = record.departureID?._id;
                let paxDest = record.destinationID?._id;
                // if (record.transitType === 'INBOUND'){
                //     let tmp = paxDest;
                //     paxDest = paxDep;
                //     paxDep = tmp;
                // }

                if (leg && paxDep === leg.departureID){
                    icons.push(<Tooltip title={name + ' gets on this leg.'}>
                        <Icon type="arrow-up" style={{ color: 'lime' }} />
                    </Tooltip>)
                }

                if (leg && paxDest === leg.destinationID){
                    icons.push(<Tooltip title={name + ' gets off this leg.'}>
                        <Icon type="arrow-down" style={{ color: 'red' }} />
                    </Tooltip>)
                }

                let text;

                if(record.__typename === 'CargoNode'){
                    text = <span><FontAwesomeIcon icon={faBoxOpen} style={iconStyle} /> {record.name}</span>
                }
                else {
                    text = <span><Icon type="user" style={iconStyle}/>{record.lastName}, {record.firstName}</span>
                }
                return <>
                    {icons.map((el, index) => <span style={{ marginRight: index + 1 === icons.length ? '6px' : '0' }}>{el}</span>)}
                    {text}
                </>
            }
        },
        {
            title: 'Employer',
            key: 'employer',
            render: (_, record: TEMP_TYPE_Entity) => {
                if (record.classType === 'flytsuite.paxnode'){
                    return record.employerID ? record.employerID.name : <span style={{color: 'red'}}>Not Found</span>
                }
                else if (record.classType === 'flytsuite.cgonode'){
                    return <Typography.Text type="secondary">N/A</Typography.Text>
                }
                return null
            }
        },
        {
            title: 'Controlling Heliport',
            key: 'lastKnownController',
            dataIndex: 'lastKnownController',
            render: (doc) => doc?.name
        },
        {
            title: 'Weight',
            key: 'weight',
            render: (_, record: TEMP_TYPE_Entity) => {
                if(record.__typename === 'CargoNode'){
                    return `${record.weight}`
                }
                return `${record.paxWeight}`
            }
        },
        {
            title: 'Bag Weight',
            key: 'bagWeight',
            dataIndex: 'bagWeight'
        },
        {
            title: 'Bag Count',
            key: 'bagCount',
            dataIndex: 'bagCount'
        },
        {
            title: 'Departure',
            key: 'departure',
            render: (_, record: TEMP_TYPE_Entity) => record.departureID ? record.departureID.name : <span style={{color: 'red'}}>Not Found</span>
        },
        {
            title: 'Destination',
            key: 'destination',
            render: (_, record: TEMP_TYPE_Entity) => record.destinationID ? record.destinationID.name : <span style={{color: 'red'}}>Not Found</span>
        },
        {
            title: 'Transit Type',
            key: 'transitType',
            dataIndex: 'transitType'
        },
        {
            title: 'Pilot Notes',
            key: 'pilotNotes',
            render: (_, row: TEMP_TYPE_Entity) => {
                return <NotesForPilotQuickEdit
                    scheduleNodeData={row}
                    readOnly
                    disabled={reviewMode}
                    popupContainer={containerRef}
                />
            }
        }
    ]

    return columns;
}