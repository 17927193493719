import React, { Component } from 'react';
import { List, Row, Col } from 'antd';
import Attribute from '../Attribute';
import moment from 'moment';

class CargoItem extends Component {
    render() { 
        const { data } = this.props;
        const { checkedInTime } = data;
        var citDisplay;
        if (checkedInTime){
            const citMoment = moment(checkedInTime)
            citDisplay= citMoment.isSameOrBefore(moment('1970-01-01')) ? null : `Checked in ${citMoment.format('YYYY-MM-DD HH:mm:ss')}`
        }
        var hazmat;
        const renderHazmat = (text) => <span style={{color: 'red'}} >{text}</span>
        if (data.hazmat){
            if (data.hazmatUnCode){
                hazmat = renderHazmat(data.hazmatUnCode)
            }else{
                hazmat = renderHazmat('Yes (No Code)')
            }
        }else{
            hazmat = 'No'
        }
        return (
            <List.Item key={data.key}>
                <Row gutter={12}>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>
                                <h4>{data.name}</h4>
                            </Col>
                            <Col span={8}>
                                <Attribute label="Weight" value={data.weight} altVal={0} />
                            </Col>
                            <Col span={8}>
                                <Attribute label="Transit Type" value={data.transitType} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                {(data.customerID && data.customerID.name) || 'Unknown Customer'}
                            </Col>
                            <Col span={8}>
                                <Attribute label="Hazmat" value={hazmat} />
                            </Col>
                            <Col span={8}>
                                <Attribute label="Departure" value={data.departureID && data.departureID.name} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                {citDisplay}
                            </Col>
                            <Col span={8}>
                                <Attribute label="Attention To" value={data.attentionTo} />
                            </Col>
                            <Col span={8}>
                                <Attribute label="Destination" value={data.destinationID && data.destinationID.name} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {data.deliveredBy && `Delivered By ${data.deliveredBy}`}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </List.Item>
        );
    }
}
 
export default CargoItem;