import React, { CSSProperties } from 'react';
import { Row, Col, Icon } from 'antd';

const defTitle = 'Not Authorized'
const defDesciption = 'You do not have the permissions needed to access this page'
const defStyle = {
  maxWidth: '30rem',
  margin: '5rem auto'
}

export interface NotAuthorizedProps {
  title?: React.ReactNode,
  desciption?: React.ReactNode,
  icon?: string,
  style?: CSSProperties,
  extra?: React.ReactNode
}

const NotAuthorized: React.FC<NotAuthorizedProps> = ({ title=defTitle, desciption = defDesciption, icon = "lock", style, extra }) => (
    <Row style={{...defStyle, ...style}}>
      <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '3rem' }}>
        <Icon type={icon} theme="outlined" style={{fontSize: '5rem'}} />
      </Col>
      <Col span={18}>
        <h1>{title}</h1>
        <p style={{fontSize: '1rem'}}>
          {desciption}
        </p>
        {extra ? <div>{extra}</div> : null}
    </Col>
  </Row>
)

export default NotAuthorized