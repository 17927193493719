import {useEffect, useState} from "react";
import config from "../config";
import useInterval from "../components/useInterval";

export function useS3Config(){
    const [ S3Config, setS3Config ] = useState(null);


    /**
     * Fetches public application config file from S3.
     */
    function getConfig() {
        fetch(`https://${config.configS3Bucket}.s3.amazonaws.com/${config.configKey}`, {
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            mode: 'cors',
            cache: 'no-cache'
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (!S3Config || (data.maintenance.enabled !== S3Config.maintenance.enabled) || S3Config.maintenance.announcement !== data.maintenance.announcement){
                    setS3Config(data)
                }
            })
            .catch((err) => console.error('Could not get config file from S3!', err));
    }

    // Repeatedly call getConfig. Allows the maintenange page to be turned on without a page refresh.
    useInterval(() => {
        getConfig();
    }, 60000)

    useEffect(() => {
        if(!S3Config){
            getConfig();
        }
        // eslint-disable-next-line
    }, [])

    return S3Config
}