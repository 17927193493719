import React from 'react';
import { Input, Row, Col, Button } from 'antd';

const FilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, placeholder, ...restProps }) => (
    <div className="search-filter-dropdown">
        <div style={{ marginBottom: '0.5rem' }}>
            <Input
                ref={ele => restProps.setSearchInputRef && restProps.setSearchInputRef(ele)}
                placeholder={placeholder}
                value={selectedKeys ? selectedKeys[0] : null}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
                style={{ width: "100%" }}
            />
        </div>
        <Row gutter={6} >
            <Col span={12}>
                <Button type="primary" block onClick={confirm}>Search</Button>
            </Col>
            <Col span={12}>
                <Button block onClick={clearFilters}>Reset</Button>
            </Col>
        </Row>
    </div>
)

export default FilterDropdown