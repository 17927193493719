import gql from "graphql-tag";

export default gql`
query QueryQuickReport(
    $name: String!
    $type: ReportType!
    $criteria: [ReportCriteriaInput!]!
    $tpID: ID!
){
    run_quick_report(
        name: $name
        type: $type
        criteria: $criteria
        tpID: $tpID
    ){
        classType
        createdBy
        createdTs
        criteria {
            display_value
            key
            label
            value
        }
        endTs
        error {
            args
            message
            detailMessage
            type
        }
        modifiedBy
        modifiedTs
        name
        result {
            docs
            refs
            summary
            total_docs
            total_pages
        }
        startTs
        status
        tpID
        type
    }
}
`