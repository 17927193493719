import React, { Component } from 'react';
import { Query, graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import PaxQuery from './PaxQuery';
import WithOrgData from '../../WithOrgData';
import LivePassengers from './LivePassengers';
import withGlobalFilter from '../../withGlobalFilter';
import PaginationLimit from '../../../Queries/PaginationLimit';

/**
 * Wrapper for LivePassengers component. Handles querying of data and inserting that data into LivePassengers.
 * Handles loading and error states of current query and passes it to LivePassengers.
 */
class LivePassengersWithData extends Component {
    state = {
        activeTab: 'outbound', // Current selected tab. Possible values: 'outbound', 'inbound', 'transfer'.
        skip: 0 // Used for pagination. How many passengers to skip from the beginning of the data set.
    }

    // Convert current tab selection to a valid transitType to use in query
    getTransitType = () => {
        const { activeTab } = this.state;
        switch (activeTab) {
            case 'outbound':
                return ['OUTBOUND']
            case 'inbound':
                return ['INBOUND', 'TRANSFER']
            default:
                return null;
        }
    }
    render() {
        const { customer, transporter } = this.props.orgData;
        const { globalFilter, paginationLimit } = this.props;
        return (
            <Query
                query={PaxQuery}
                variables={{
                    filter: {
                        customers: globalFilter.customer && globalFilter.customer.length ? globalFilter.customer.map(({ key }) => key) : customer && [customer._id],
                        transporters: globalFilter.transporter && globalFilter.transporter.length ? globalFilter.transporter.map(({ key }) => key) : transporter && [transporter._id],
                        employers: globalFilter.employer && globalFilter.employer.map(({ key }) => key),
                        departures: globalFilter.departure && globalFilter.departure.map(({ key }) => key),
                        destinations: globalFilter.destination && globalFilter.destination.map(({ key }) => key),
                        transitTypes: this.getTransitType(),
                        onlyShowLive: true
                    },
                    skip: this.state.skip,
                    limit: paginationLimit
                }}
                fetchPolicy="cache-and-network"
                pollInterval={60000}
            >
            {({ data, loading, error, refetch }) => {
                const passengers = (data && data.passengers && data.passengers.docs) || [];
                return (
                    <LivePassengers
                        {...this.props}
                        passengers={passengers}
                        loading={loading}
                        error={error}
                        activeTab={this.state.activeTab}
                        onTabChange={(activeTab) => this.setState({ activeTab })}
                        refetch={refetch}
                        skip={this.state.skip}
                        setSkip={(skip) => this.setState({ skip })}
                    />
                )
            }}
            </Query>
        );
    }
}

export default compose(
    withGlobalFilter,
    WithOrgData,
    graphql(
        PaginationLimit,
        {
            props: props => ({
                paginationLimit: (props.data && props.data.paginationLimit) || 200
            })
        }
    )
)(LivePassengersWithData)