
export { default as MDDetails, getDetailsTitle } from './details';
export { default as DuplicationChecker } from './DuplicationChecker';
export { default as MDHeader } from './header';
export { default as useMasterDataState } from './hook';
export { default as MDLayout } from './layout';
export { default as NoResults } from './NoResults';
export { default as MDTable } from './table';
export { default as TableLoader } from './TableLoader';
export { default as TableLoading } from './TableLoading';

export const ACTION_COL_WIDTH = 75
export const DEFUALT_POLL_INTERVAL = undefined