import { merge } from "lodash";
import appResolv from "./app";
import transporterResolv from "./transporter";
import customerResolv from "./customer";
import authResolv from "./auth";
import dashboardResolv from "./dashboard";
import contractsResolv from "./contracts";
import organizationResolv from "./organization";
import SchedulingResolvers from "./scheduling";

const Resolvers = merge(
    appResolv,
    transporterResolv,
    contractsResolv,
    organizationResolv,
    customerResolv,
    authResolv,
    dashboardResolv,
    SchedulingResolvers
);
export default Resolvers;