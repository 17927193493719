import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PobViewer from './pobViewer';
import PropTypes from 'prop-types';
import LoadingContent from '../LoadingContent';
import { Alert } from 'antd';
import PobQuery from 'components/PobEditor/pobQuery';

class PobViewerWithData extends Component {
    render() {
        const { pobID } = this.props;
        return (
            <Query
                query={PobQuery}
                variables={{ _id: pobID }}
                fetchPolicy="network-only"
            >
            {({ data, loading, error }) => {
                if (loading) return <LoadingContent />
                return (
                    <div>
                        {error ? (
                            <Alert style={{ marginBottom: 12 }} showIcon type="error" message="Failed to load the POB node" />
                        ) : null}
                        {data && data.getPobNode ? <PobViewer data={data.getPobNode} /> : null}
                    </div>
                )
            }}
            </Query>
        );
    }
}

PobViewerWithData.propTypes = {
    pobID: PropTypes.string.isRequired
}
 
export default PobViewerWithData;