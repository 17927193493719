import React from 'react';
import SchedulingStepsHeader from '../SchedulingStepsHeader';
import { Layout } from 'antd';
import './MainLayout.less';

const MainLayout = ({ children }) => (
    <Layout className="main-layout">
        <SchedulingStepsHeader />
        {children}            
    </Layout>
)

export default MainLayout