import gql from "graphql-tag";

export default gql`
mutation SubmitReport(
    $name: String!
    $type: ReportType!
    $criteria: [ReportCriteriaInput!]!
    $tpID: ID!
    $contractIDs: [ID!]
){
    run_report(
        name: $name
        type: $type
        criteria: $criteria
        tpID: $tpID
        contractIDs: $contractIDs
    )
}
`