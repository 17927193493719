import React from 'react';
import { FlightLeg } from '../../schema';
import { Card, Icon, Tooltip, Typography } from 'antd';
import { CardProps } from 'antd/lib/card';
import { TableRowSelection } from 'antd/lib/table';
import DraggableTable from '../TableDragSorting';

export interface FlightLegsTableProps {
    legs: FlightLeg[],
    legsCardProps?: CardProps,
    rowSelection?: TableRowSelection<FlightLeg>,
    onRow?(record: any, index: number): any,
    onMoveRow?(dragIndex: number, hoverIndex: number): void,
    checkLegIsRedundant?: (leg: any) => boolean
}

const FlightLegsTable: React.FC<FlightLegsTableProps> = (props) => {
    return <Card
        title="Flight Legs"
        size="small"
        className="ant-card-with-table ant-card-shadow"
        {...(props.legsCardProps || {})}
    >
        <DraggableTable
            dataSource={props.legs}
            className="mc-table"
            size="small"
            pagination={false}
            rowKey={(_, index) => String(index)}
            onMoveRow={props.onMoveRow}
            columns={[
                {
                    title: 'Dep',
                    key: 'dep',
                    dataIndex: 'departure'
                },
                {
                    title: 'Dest',
                    key: 'dest',
                    dataIndex: 'destination'
                },
                {
                    title: 'Pax',
                    key: 'pax',
                    dataIndex: 'paxCount'
                },
                {
                    title: 'Cgo',
                    key: 'cgo',
                    dataIndex: 'cgoCount'
                },
                {
                    title: '',
                    key: 'redundantWarning',
                    render: (_, record) => props.checkLegIsRedundant?.(record) ? 
                        <Tooltip title={`No PAX/CGO are planned to visit ${record.destination}. Removal is recommended.`}>
                            <Typography.Text type='warning'><Icon type='warning' /></Typography.Text>
                        </Tooltip> 
                        : null
                }
            ]}
            onRow={props.onRow}
            rowSelection={props.rowSelection}
        />
    </Card>
}

export default FlightLegsTable