import { Alert, Button, Col, message, Popconfirm, Row, Table } from 'antd';
import commonColumns from 'common/table/columns';
import NonPreferredEmployerSearch from 'components/NonPreferredEmployerSearch';
import _ from 'lodash';
import compose from 'lodash.flowright';
import pluralize from 'pluralize';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import LoadingContent from '../../../LoadingContent';
import WithOrgData from '../../../WithOrgData';
import GetNonPreferredQuery from "Queries/GetNonPreferred";
import SetNonPreferredMutation from "Mutations/SetNonPreferred";
import {momentOrNull} from "../../../../common/util";

export class Employer extends Component {

    constructor(props) {
        super(props);

        this.npfEmployerSearchRef = React.createRef();
    }

    state = {
        selected: [],
        employersToAdd: [],
        addingEmployers: false,
        removingEmployers: false
    }
    addOrgs = () => {

        this.npfEmployerSearchRef.current.validateFieldsAndScroll((err, values) => {

            if (err) return;

            const startDate = momentOrNull(values.startDate).format("YYYY-MM-DD");
            const reason = values.reason;

            const { getNonPreferredList } = this.props.data;
            const { customer, transporter } = this.props.orgData;
            const organizationIds = _.union(getNonPreferredList && getNonPreferredList.organizationIds.map(org => {
                delete org.__typename
                if(!org.startDate){
                    delete org.startDate
                }
                return {
                    ...org,
                    id: org.id._id
                }
            }), this.state.employersToAdd.map(id => {
                if(values.startDate){
                    return {
                        id: id,
                        reason: reason,
                        startDate: startDate
                    }
                }

                return {
                    id: id,
                    reason: reason
                }
            }));

            this.setState({ addingEmployers: true });
            this.props.setNonPreferred({
                variables: {
                    _id: customer._id.replace('CUS-', 'NPF-'),
                    tpID: transporter._id,
                    customerID: customer._id,
                    organizationIds
                }
            })
                .then(() => {
                    message.success(`Successfully added ${this.state.employersToAdd.length} organizations to the non preferred list`);
                    this.setState({ employersToAdd: [] })
                    this.npfEmployerSearchRef.current.resetFields();
                })
                .catch((error) => {
                    console.error(error);
                    message.error("Failed to add organizations to the non preferred list")
                })
                .then(() => this.setState({ addingEmployers: false }))
        })
    }
    removeOrgs = () => {
        const { getNonPreferredList } = this.props.data;
        const { customer, transporter } = this.props.orgData;
        const organizations = getNonPreferredList.organizationIds.filter(org => !this.state.selected.includes(org.id._id));
        this.setState({ removingEmployers: true });
        this.props.setNonPreferred({
            variables: {
                _id: customer._id.replace('CUS-', 'NPF-'),
                tpID: transporter._id,
                customerID: customer._id,
                organizationIds: organizations.map(org => {
                    delete org.__typename
                    if(!org.startDate){
                        delete org.startDate
                    }
                    return {
                        ...org,
                        id: org.id._id
                    }
                })
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setNonPreferredList: {
                    __typename: 'NonPreferredList',
                    _id: getNonPreferredList._id,
                    organizationIds: organizations.map(org => {
                        return {
                            ...org,
                            id: org.id._id
                        }
                    })
                }
            }
        })
        .then(() => {
            message.success(`Successfully removed ${this.state.selected.length} organizations from the non preferred list`);
            this.setState({ selected: [] })
        })
        .catch((error) => {
            console.error(error);
            message.error("Failed to remove organizations from the non preferred list")
        })
        .then(() => this.setState({ removingEmployers: false }))
    }
    
    clearSelected = () => this.setState({ selected: [] })
    render() {
        const { data: { getNonPreferredList, loading, error }} = this.props;
        if (loading) return <LoadingContent style={{margin: '10rem 0'}} />
        if (error){
            const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
            if (!notFoundError){
                return <Alert type="error" message="Failed to load non preferred list" description={error.message} showIcon />
            }
        }
        const employers = getNonPreferredList && getNonPreferredList.organizationIds.filter(
            org => org.id.classType === 'flytsuite.employer'
        )
        .sort((a, b) => {
            if (a.id.name < b.id.name) return -1
            if (a.id.name > b.id.name) return 1
            return 0
        });
        return (
            <Row>
                <Col span={8}>
                    <div style={{ borderRight: '1px solid #e8e8e8', minHeight: '40rem' }}>
                        <div style={{ padding: '24px 24px 0 24px', marginBottom: '-10px' }}>
                            <Row type="flex" style={{ alignItems: 'baseline' }}>
                                <Col style={{ flex: 1 }}>
                                    <h3 style={{ margin: 0 }} >Add Employers to non preferred list</h3>
                                </Col>
                                <Col>
                                    <Button
                                        disabled={!this.state.employersToAdd.length}
                                        loading={this.state.addingEmployers}
                                        type="primary"
                                        onClick={() => this.addOrgs()}
                                    > Add {this.state.employersToAdd.length} Employers</Button>
                                </Col>
                            </Row> 
                        </div>
                        <NonPreferredEmployerSearch
                            ref={this.npfEmployerSearchRef}
                            selected={this.state.employersToAdd}
                            onChange={(employersToAdd) => this.setState({ employersToAdd })}
                        />
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{ padding: 24 }}>
                        <Row type="flex" style={{marginBottom: '1rem'}} >
                            <Col style={{flex: 1}}>
                                <Row type="flex" style={{ alignItems: 'baseline' }} >
                                    <h3 style={{margin: 0}} >Employers on Non Preferred List</h3>
                                    <div style={{ marginLeft: '2rem' }}>
                                        {this.state.selected.length ? (
                                            <div>
                                                <span>{this.state.selected.length} Selected</span>
                                                <Button className="mc-link-btn" style={{ marginLeft: 6 }} onClick={this.clearSelected} >Clear</Button>
                                            </div>
                                        ) : null}
                                    </div>
                                </Row>
                            </Col>
                            <Col>
                                <Popconfirm 
                                    title={`Are you sure you want to remove ${this.state.selected.length} ${pluralize('employer', this.state.selected.length)}?`}
                                    okText="Remove"
                                    onConfirm={this.removeOrgs}
                                    placement="bottomLeft"
                                >
                                    <Button
                                        type="danger"
                                        disabled={this.state.selected.length ? false : true}
                                        loading={this.state.removingEmployers}
                                    >
                                        Remove {this.state.selected.length} Employers
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Table
                            dataSource={employers}
                            rowKey={record => record.id._id}
                            size="small"
                            className="mc-table"
                            pagination={false}
                            columns={commonColumns.nonPreferred.some(["employerName", "reason", "startDate"])}
                            rowSelection={{
                                selectedRowKeys: this.state.selected,
                                onChange: (selected) => {
                                    this.setState({selected})
                                    if (this.props.onChange){
                                        this.props.onChange(selected)
                                    }
                                } 
                            }}
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}
 
export default compose(
    WithOrgData,
    graphql(
        GetNonPreferredQuery,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'NPF-')
                },
                pollInterval:  15000,
                fetchPolicy: 'network-only'
            })
        }
    ),
    graphql(
        SetNonPreferredMutation,
        {
            name: 'setNonPreferred'
        }
    )
)(Employer);