import {useScheduleEditorApi} from "components/scheduling/editor/schedule-editor-v2/api";
import {useContext, useEffect, useReducer, useRef} from "react";
import {OrgDataContext} from "../../../../context/orgData";
import {useMutation, useQuery} from "react-apollo";
import {Mutations, Queries} from "./graphql";
import {useHistory, useParams} from "react-router-dom";
import {
    CargoNode,
    Customer,
    Location,
    MutationSchedulePaxCgo_V2Args,
    PaxNode,
    QueryGetScheduleHistoryArgs,
    ScheduledCargoInput_V2,
    ScheduledPassengerInput_V2,
    ScheduleHistoryDetail,
    TransitType,
    Transporter
} from "__generated__/graphql/types";
import {CloneFormFields, DefaultState, Reducer} from './state';
import {basePath} from "../../../../routes/scheduling";
import {isTruey} from "../../../../common/util-ts";
import {message} from "antd";
import {Moment} from "moment";

type RouteParams = {
    date: string,
    scheduledGroup: string,
    lastKnownController: string,
    departureID?: string,
    destinationID?: string
}

export function usePhase1HistoryEditorApi() {

    const orgData = useContext(OrgDataContext);
    const [ state, dispatch ] = useReducer(Reducer, DefaultState);
    const history = useHistory();

    const routeParamsEncoded = useParams<RouteParams>();
    let routeParams: RouteParams = {} as RouteParams;
    Object.entries(routeParamsEncoded).forEach(([k, v]) => {
        routeParams[k] = decodeURIComponent(v);
    })

    // -----------------------------------------------------------------------------------------------------------------

    // Read schedule ---------------------------------------------------------------------------------------------------
    let queryVars = {
        tpID: orgData.transporterID,
        date: routeParams.date,
        groupName: routeParams.scheduledGroup,
        lastKnownController: routeParams.lastKnownController,
        customerID: orgData.customerID
    }

    const queryResult = useQuery<
        {
            GetScheduleHistory: ScheduleHistoryDetail,
            getTransporter: Transporter,
            getCustomer: Customer,
            getLastKnownController: Location
        },
        { customerID: string } & QueryGetScheduleHistoryArgs
    >(Queries.GetScheduleHistory, {
        variables: queryVars,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        pollInterval: 10000
    })
    const data = queryResult.data?.GetScheduleHistory;
    // -----------------------------------------------------------------------------------------------------------------

    // Clone Schedule form ---------------------------------------------------------------------------------------------
    const cloneScheduleFormRef = useRef(null);
    // -----------------------------------------------------------------------------------------------------------------

    const [ cloneScheduleMutation, cloneScheduleMutationResult ] = useMutation(Mutations.SaveSchedulePaxCgoV2, {
        onCompleted: () => {
            history.push(basePath('schedule'));
        }
    })

    function initiateClone(){

        function clone(formValues: { date: Moment, name: string }){
            function processEntity(entity: PaxNode): ScheduledPassengerInput_V2;
            function processEntity(entity: CargoNode): ScheduledCargoInput_V2;
            function processEntity<T extends PaxNode | CargoNode>(entity: T){

                let common = {
                    _id: undefined,
                    _rev: undefined,
                    scheduledOrder: entity.scheduledOrder,
                    transitType: entity.transitType as TransitType,
                    notesForPilot: entity.notesForPilot,
                    departureID: entity.departureID?._id,
                    destinationID: entity.destinationID?._id
                };

                if (entity.classType === 'flytsuite.paxnode'){
                    const pax = entity as PaxNode;
                    const cloned: ScheduledPassengerInput_V2 = {
                        ...common,
                        personID: pax.personID?._id,
                        paxWeight: pax.paxWeight,
                        bagWeight: pax.bagWeight,
                        chargeCode: pax.chargeCode,
                        bagCount: pax.bagCount
                    }
                    return cloned;
                }
                else if (entity.classType === 'flytsuite.cgonode'){
                    const cgo = entity as CargoNode;
                    const cloned: ScheduledCargoInput_V2 = {
                        ...common,
                        name: cgo.name,
                        initials: cgo.initials,
                        weight: cgo.weight,
                        approvedBy: cgo.approvedBy,
                        hazmat: cgo.hazmat,
                        hazmatUnCode: cgo.hazmatUnCode,
                        notes: cgo.notes,
                        attentionTo: cgo.attentionTo
                    }
                    return cloned;
                }

                return null;
            }

            const vars: MutationSchedulePaxCgo_V2Args = {
                customerID: orgData.customerID,
                tpID: orgData.transporterID,
                scheduledFlightDate: formValues.date.format('YYYY-MM-DD'),
                scheduledGroup: formValues.name,
                lastKnownController: routeParams.lastKnownController,
                modifiedPax: data.paxList.map((e) => processEntity(e)).filter(isTruey),
                modifiedCgo: [],
                deletedEntities: []
            }

            cloneScheduleMutation({
                variables: vars,
            })
                .then(() => {
                    history.push(basePath(`schedule/${vars.scheduledFlightDate}/${vars.scheduledGroup}/${vars.lastKnownController}/edit`));
                })
                .catch((err) => {
                    console.error('Failed to clone schedule:', err);
                    message.error('Failed to clone schedule. Read console for details.');
                })
        }

        cloneScheduleFormRef.current?.validateFieldsAndScroll((err, values) => {
            if (!err){
                clone(values);
            }
        })
    }

    const groupData = {
        name: routeParams.scheduledGroup,
        lastKnownController: routeParams.lastKnownController,
        date: routeParams.date,
        lastKnownControllerObj: queryResult.data?.getLastKnownController as Location,
        tpID: orgData.transporterID,
        customerID: orgData.customerID
    };

    const editorApi = useScheduleEditorApi({
        data: data,
        groupData: groupData,
        isLoading: queryResult.loading,
        error: queryResult.error,
        refetch: () => queryResult.refetch(),
        editMode: false
    });

    useEffect(() => {
        dispatch({
            type: 'INIT_FORM',
            payload: {
                date: null,
                name: groupData.name
            }
        })
    }, [groupData.name, groupData.date]);

    type CloneScheduleFormKeys = keyof typeof state.cloneScheduleForm;

    return {
        ...editorApi,
        cloneSchedule: {
            ref: cloneScheduleFormRef,
            drawer: {
                isOpen: () => state.cloneScheduleOpen,
                open: () => dispatch({ type: 'SET_CLONE_OPEN', payload: true }),
                close: () => dispatch({ type: 'SET_CLONE_OPEN', payload: false }),
            },
            form: {
                get: () => state.cloneScheduleForm,
                getValue: <T extends CloneScheduleFormKeys>(name: T) => (
                    state.cloneScheduleForm[name]?.value as typeof state.cloneScheduleForm[T]['value']
                ),
                set: (formFields: CloneFormFields) => dispatch({ type: 'UPDATE_FORM', payload: formFields }),
                reset: () => dispatch({ type: 'RESET_FORM' })
            },
            clone: {
                initiate: initiateClone,
                isCloning: cloneScheduleMutationResult.loading
            }
        }
    }
}

export type Phase1HistoryEditorApi = ReturnType<typeof usePhase1HistoryEditorApi>