// import { AUTH_TYPE } from "aws-appsync/lib/link/auth-link";
import { LayerProps } from 'react-map-gl';

const AUTH = {
  IDENTITY_POOL_ID: 'us-east-1:7e643d76-8027-4747-b34a-365de2b204d7',
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_gW9HU38wx',
  USER_POOL_WEB_CLIENT_ID: '7ukil613rp4vm81gsnvvgpqaeo'
}

/**
 * @type { style: any, availableLayers: LayerProps[] }
 */
let REACT_MAP_GL_CONFIG = {
  style: {
    version: 8,
    sources: {
      'raster-tiles': {
        type: 'raster',
        tiles: [
          "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
          "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
          "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"
        ],
        tileSize: 256
      },
      'nexrad': {
        type: 'raster',
        tiles: [
          "https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi?&REQUEST=GetMap&TRANSPARENT=true&FORMAT=image/png&BGCOLOR=0x000000&VERSION=1.1.1&LAYERS=nexrad-n0r&STYLES=default&CRS=EPSG:3857&SRS=EPSG:3857&WIDTH=256&HEIGHT=256&BBOX={bbox-epsg-3857}"
        ],
        tileSize: 256
      }
    },
    layers: [
      {
        id: "osm-tiles",
        type: "raster",
        source: "raster-tiles",
        minzoom: 0,
        maxzoom: 19,
        permanent: true,
        paint: {}
      }
    ]
  },
  availableLayers: [
    {
      id: 'nexrad',
      beforeId: 'dummylayer',
      label: 'NEXRAD Reflectivity',
      type: 'raster',
      source: 'nexrad',
      minzoom: 0,
      maxzoom: 19,
      paint: {}
    }
  ]
}

/* NOTE ABOUT DUMMYLAYERS
   To get around the limitations of sorting layers in maplibre-gl, I insert a dummy layer to ensure that layers that come after
   it are placed IN FRONT of the dummy layer. Any layer that has a `beforeId: 'dummylayer'` property will be placed BEHIND the dummylayer.
   This ensures that layers like nexrad will appear behind anything in front of the dummylayer.

   Example: ------------------------------------------------------------------------
   <Map style={mapStyle}>
      <Layer id="dummylayer" type='custom' render={() => null} />
      <Source id="flightPathLine" type="geojson" data={lineData}>
          <Layer
              id="flightPathLayer"
              type="line"
              source="flightPathLine"
              layout={{
                  "line-join": "round",
                  "line-cap": "round"
              }}
              paint={{
                  "line-color": "rgb(255, 0, 0)",
                  "line-width": 5
              }}
          />
      </Source>
    </Map>
    ---------------------------------------------------------------------------------

    The layer "flightPathLayer" will always appear in front of the dummy layer, and the nexrad layer (used in mapStyle) will appear behind
*/

const dev = {
  dashboardAPI: "https://8djmldiheg.execute-api.us-east-1.amazonaws.com/dev",
  pythonBackendApi: "https://ssg3m7m4k6.execute-api.us-east-1.amazonaws.com/dev",
  reduxDebug: true,
  appSync: {
    // url: 'https://7ufow67xpbf3rg37smjpect7um.appsync-api.us-east-1.amazonaws.com/graphql',
    url: 'https://qkpgl2wvzvgjbpvic6vfdqc3be.appsync-api.us-east-1.amazonaws.com/graphql',
    region: AUTH.REGION,
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS" /*AUTH_TYPE.AMAZON_COGNITO_USER_POOLS*/
      // Above commented out because the whole aws-appsync library is being included for just this one enum?
      // Hell naw, just use the string literal. Total waste of space.
    }
  },
  reactmapgl: REACT_MAP_GL_CONFIG,
  blockInfo: {
    url: 'https://www.blockinfo.com/'
  },
  reportS3Bucket: 'dataflyt-report-dev',
  userContentS3Bucket: 'usercontent.dataflyt.net',
  configS3Bucket: 'manifestcentral-config',
  configKey: 'public.config.dev.json',
  manifestPrintoutEndpoint: 'https://47enjocsvf.execute-api.us-east-1.amazonaws.com/api',
  dfS3WebImages: 'https://s3.amazonaws.com/df-web-img/'
}

const qas = {
  dashboardAPI: "https://95c9pweag9.execute-api.us-east-1.amazonaws.com/beta",
  pythonBackendApi: "https://ip1dgeshxj.execute-api.us-east-1.amazonaws.com/qas",
  reduxDebug: true,
  appSync: {
    url: 'https://evgehb3pmvd4lm2wjp3ec5weam.appsync-api.us-east-1.amazonaws.com/graphql',
    region: AUTH.REGION,
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS"
    }
  },
  reactmapgl: REACT_MAP_GL_CONFIG,
  blockInfo: {
    url: 'https://www.blockinfo.com/'
  },
  reportS3Bucket: 'dataflyt-report-dev',
  userContentS3Bucket: 'usercontent.dataflyt.net',
  configS3Bucket: 'manifestcentral-config',
  configKey: 'public.config.beta.json',
  manifestPrintoutEndpoint: 'https://dh4m0aztuf.execute-api.us-east-1.amazonaws.com/api',
  dfS3WebImages: 'https://s3.amazonaws.com/df-web-img/'
}

const prd = {
  dashboardAPI: "https://i0xxqx3n2b.execute-api.us-east-1.amazonaws.com/prd",
  pythonBackendApi: "https://oqdo5gybx5.execute-api.us-east-1.amazonaws.com/prd",
  reduxDebug: true,
  appSync: {
    url: 'https://vsmoskpulvbova532hd5k5edfi.appsync-api.us-east-1.amazonaws.com/graphql',
    region: AUTH.REGION,
    auth: {
      type: "AMAZON_COGNITO_USER_POOLS"
    }
  },
  reactmapgl: REACT_MAP_GL_CONFIG,
  blockInfo: {
    url: 'https://www.blockinfo.com/'
  },
  reportS3Bucket: 'dataflyt-report',
  userContentS3Bucket: 'usercontent.dataflyt.net',
  configS3Bucket: 'manifestcentral-config',
  configKey: 'public.config.prd.json',
  manifestPrintoutEndpoint: 'https://rz47darr9a.execute-api.us-east-1.amazonaws.com/api',
  dfS3WebImages: 'https://s3.amazonaws.com/df-web-img/'
}

var config = dev;
if (process.env.REACT_APP_STAGE === 'prd')
{
  config = prd;
}else if (process.env.REACT_APP_STAGE === 'qas'){
  config = qas;
}else if (process.env.REACT_APP_STAGE === 'dev'){
  config = dev
}else{
  throw Error("REACT_APP_STAGE has not been set to production, qas, or dev")
}

// const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

// export default {
//     awsExports: {
//       identityPoolId: 'us-east-1:7e643d76-8027-4747-b34a-365de2b204d7',   // REQUIRED - Amazon Cognito Identity Pool ID
//       region: 'us-east-1',                                                // REQUIRED - Amazon Cognito Region
//       userPoolId: 'us-east-1_gW9HU38wx',                                  // OPTIONAL - Amazon Cognito User Pool ID
//       userPoolWebClientId: '7ukil613rp4vm81gsnvvgpqaeo',                  // OPTIONAL - Amazon Cognito Web Client ID
//     },
//     ...config
//   }

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  defaultTheme: 'dark',
  amplify: {
    Auth: {
      identityPoolId: AUTH.IDENTITY_POOL_ID,
      region: AUTH.REGION,
      userPoolId: AUTH.USER_POOL_ID,
      userPoolWebClientId: AUTH.USER_POOL_WEB_CLIENT_ID
    },
  },
  ...config
}