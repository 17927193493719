import React, { useEffect, useState } from 'react';
import registerServiceWorker from '../registerServiceWorker';

interface ServiceWorkerProviderState extends ServiceWorker {
    updateAvailable: boolean,
    update: () => void
}

export const ServiceWorkerContext = React.createContext<ServiceWorkerProviderState>(null);

export const ServiceWorkerConsumer = ({ children }) => {
    return <ServiceWorkerContext.Consumer>
    {(serviceWorker: ServiceWorkerProviderState) => {
        if (!serviceWorker) return null;
        return children(serviceWorker)
    }}
    </ServiceWorkerContext.Consumer>
}

export default function ServiceWorkerProvider({ children }) {
    const [ sw, setSW ] = useState<ServiceWorkerProviderState>(null);
    useEffect(() => {
        registerServiceWorker((newSW: ServiceWorker) => {
            console.log('Service Worker Changed!', newSW.state);
            setSW({
                ...newSW,
                updateAvailable: newSW.state === 'installed',
                update: () => {
                    newSW.postMessage({ type: 'SKIP_WAITING' });
                    window.location.reload();
                }
            })
        })
    }, [])
    return <ServiceWorkerContext.Provider value={sw}>
        {children}
    </ServiceWorkerContext.Provider>
}