import React, { Component } from 'react';
import PassengerList from '../passenger/PassengerList';
import { Tabs } from 'antd';
import CargoList from '../cargo/CargoList';

class LegItem extends Component {
    render() { 
        const { data } = this.props;
        const pax = data.paxIDs || [];
        const cgo = data.cgoIDs || [];
        return (
            <div>
                <Tabs defaultActiveKey="pax" type="card">
                    <Tabs.TabPane key="pax" tab={`Passengers (${pax.length})`}>
                        {pax.length ? (
                            <PassengerList dataSource={pax} />
                        ) : 'No Passengers'}
                    </Tabs.TabPane>
                    <Tabs.TabPane key="cgo" tab={`Cargo (${cgo.length})`}>
                        {cgo.length ? (
                            <CargoList dataSource={cgo} />
                        ) : 'No Cargo'}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
}
 
export default LegItem