import {useState} from "react";

export default function usePromise<T extends (...args: any[]) => Promise<any>, E=any>(promiseFunc: T){

    const [ result, setResult ] = useState<Awaited<ReturnType<T>>>(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<E>(null);

    function resolve(result: Awaited<ReturnType<T>>){
        setLoading(false);
        setResult(result);
    }

    function reject(err: E){
        setLoading(false);
        setError(err);
    }

    return {
        call(...args) {
            setResult(null);
            setError(null);
            setLoading(true);

            const prom = promiseFunc(...args);
            prom
                .then(resolve)
                .catch(reject)
            return prom
        },
        result: loading ? result : null,
        error: loading ? error : null,
        loading
    }
}