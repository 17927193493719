import React, { ReactNode } from 'react';
import { UseMasterDataStateReturnProps, UseMasterDataStateReturnNoQueryHookProps } from "./hook";
import { AlertProps } from "antd/lib/alert";
import { Cell } from "@blueprintjs/table";
import { MDLayoutProps } from './layout';
import { NoResults } from '.';
import { MDHeaderProps } from './header';
import { BlueprintTableInfiniteScrollerProps } from 'components/BlueprintTableInfiniteScroller';
import { MDTableProps } from './table';
import { MDDetailsProps, getDetailsTitle } from './details';
import { formFieldsIsTouched } from 'common/form';
import { mapValues, isObject } from 'lodash';
import { cleanGraphQLErrorMsg } from 'common/util';
import { NetworkStatus } from 'apollo-client';

export function isNSLoading(networkStatus: number){
    return [1, 4].includes(networkStatus);
}

export function isNSReloading(networkStatus: number){
    return [2, 3, 6].includes(networkStatus);
}

export interface MasterDataProps {
    pollInterval?: number,
    dataPaginationLimit?: number
}

export function searchValuesToQuerySearchPayload(searchValues: {[key: string]: any}): { [key: string]: any } {
    let payloadArray = Object.entries(searchValues).filter((([_, value]) => value)).map(([key, value]) => {
        let valueJson: string;
        if (typeof value === 'string' && key !== '_id'){
            valueJson = value.toLowerCase();
        }
        else if (value?.key){
            // LabelInValue object
            valueJson = value.key;
        }
        else {
            valueJson = value;
        }
        return {
            key,
            value: JSON.stringify(valueJson)
        }
    })
    return payloadArray.length ? payloadArray : undefined
}

export function getAlertPropsFromMDState(MDState: UseMasterDataStateReturnProps | UseMasterDataStateReturnNoQueryHookProps): AlertProps{
    let alertProps: AlertProps;
    if (MDState.queryError){
        alertProps = {
            message: 'Failed to get entries',
            description: cleanGraphQLErrorMsg(MDState.queryError.message),
            type: 'error'
        }
    }
    if (MDState.saveError){
        let message = 'Failed to save entry';
        let description = cleanGraphQLErrorMsg(MDState.saveError.message);
        if (description.includes('Document update conflict')){
            message = 'Entry already exists';
            description = 'The entry that you are trying to create already exists';
        }
        if (description.includes('Not Authorized')){
            description = 'Not Authorized: You do not have permission to do this action.'
        }
        alertProps = {
            message,
            description,
            type: 'error'
        }
    }
    if (MDState.savedData && !MDState.saveError && !MDState.saving){
        alertProps = {
            type: 'success',
            message: 'Entry saved successfully!'
        }
    }
    if(MDState.deletePressed){
        if (MDState.deleteError){
            let message = 'Failed to delete entry';
            let description = MDState.deleteError.message;
            if (description.includes('Document update conflict')){
                message = 'Entry was edited before deletion.';
                description = 'The entry that you are trying to delete was edited before your deletion.';
            }
            alertProps = {
                message,
                description,
                type: 'error'
            }
        }
        if (MDState.deletedData && !MDState.deleteError && !MDState.deleting){
            alertProps = {
                type: 'success',
                message: 'Entry deleted successfully!'
            }
        }
    }
    return alertProps
}

export function findSelectedIndexByID(data: Array<any>, id: string){
    return data.findIndex((row) => row._id === id)
}

export function renderCellFromKeyIndex(data: Array<any>, key: string){
    return (idx: number) => <Cell>{data[idx][key]}</Cell>
}

export function MDStateHasNoData(MDState: UseMasterDataStateReturnProps | UseMasterDataStateReturnNoQueryHookProps){
    return !MDState.queryError && MDState.data.length <= 0 && !(MDState.queryNetworkStatus === NetworkStatus.loading || MDState.queryNetworkStatus === NetworkStatus.setVariables)
}

export function MDStateToLayoutProps(MDState: UseMasterDataStateReturnProps | UseMasterDataStateReturnNoQueryHookProps): Partial<MDLayoutProps>{
    return {
        alertProps: getAlertPropsFromMDState(MDState),
        displayNoData: MDStateHasNoData(MDState),
        detailsVisible: MDState.isEditing
    }
}

export function MDStateToHeaderProps(MDState: UseMasterDataStateReturnProps | UseMasterDataStateReturnNoQueryHookProps): Partial<MDHeaderProps>{
    return {
        onReload: () => MDState.reload(),
        reloading: MDState.reloading,
        onNewEntry: MDState.isEditing ? null : () => MDState.editFromNew()
    }
}

export function MDStateToTableScrollerProps(MDState: UseMasterDataStateReturnProps): Partial<BlueprintTableInfiniteScrollerProps>{
    return {
        loadMore: MDState.pagination.loadMore,
        hasMore: MDState.pagination.hasMore
    }
}

export function getMDTableProps(data: Array<any>, MDState: UseMasterDataStateReturnProps | UseMasterDataStateReturnNoQueryHookProps, pk: (string)='_id'): Partial<MDTableProps>{
    return {
        numRows: data.length,
        selectedRowIndex: findSelectedIndexByID(data, MDState.getEntryFieldValue(pk)),
        onEditRow: idx => MDState.editFromExisting(data[idx]),
        onEditCancel: () => MDState.cancelEditing()
    }
}

export function MDStateToDetailsProps(MDState: UseMasterDataStateReturnProps | UseMasterDataStateReturnNoQueryHookProps, detailsTitleEntryFieldName?: string | ((entryValues: any) => ReactNode)): Partial<MDDetailsProps>{
    let detailsTitle: ReactNode;
    if (typeof detailsTitleEntryFieldName === 'function'){
        detailsTitle = MDState.initEntryValues && detailsTitleEntryFieldName(MDState.initEntryValues)
    }
    else {
        detailsTitle = MDState.initEntryValues && MDState.initEntryValues[detailsTitleEntryFieldName]
    }
    return {
        title: detailsTitleEntryFieldName && getDetailsTitle(detailsTitle, MDState.isNewEntry),
        onCancel: () => MDState.cancelEditing(),
        saving: MDState.saving,
        deleting: MDState.deleting,
        showReset: formFieldsIsTouched(MDState.entryFields, true),
        onReset: () => MDState.resetEntryFields(),
        isNewEntry: MDState.isNewEntry
    }
}

export function renderNoData(MDState: UseMasterDataStateReturnProps | UseMasterDataStateReturnNoQueryHookProps, onNewEntry: () => Promise<any>){
    if (MDState.loading) return null
    return <NoResults
        onNewEntryButtonClicked={() => {
            onNewEntry && onNewEntry()
            .then(() => {
                MDState.clearSearchValues();
            })
            .catch(() => null);
        }}
    />
}

export function trimString(str: string){
    if (typeof str === 'string'){
        return str.trim();
    }
    return str
}

export function trimObjectStringValues<T extends object>(obj: T, recursive: boolean=false){
    if (!isObject(obj)){
        return obj
    }
    return mapValues(obj, (value: any) => {
        if (typeof value === 'string'){
            return value.trim();
        }
        if (recursive && isObject(value)){
            return trimObjectStringValues(value, recursive);
        }
        return value
    })
}

export function updateQueryWithFetchMoreResult(dataKey: string){
    return (prev: any, fetchMoreResult: any) => ({
        ...prev,
        [dataKey]: {
            ...prev[dataKey],
            docs: [
                ...prev[dataKey].docs,
                ...fetchMoreResult[dataKey].docs
            ]
        }
    })
}

export const renderOrgCell = (data: Array<any>, key: string) => (idx: number) => {
    let record = data[idx];
    return <Cell>{record && record[key] && record[key].name}</Cell>
}