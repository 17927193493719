import { Auth } from '@aws-amplify/auth';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Query: {
        userGroups: async () => {
            const session = await Auth.currentSession();
            console.log('resolver session', session)
            const groups = session.idToken.payload['cognito:groups'];
            if (Array.isArray(groups)){
                return groups
            }
            return []
        }
    }
}