import gql from "graphql-tag";
import {
    ScheduledCgoNodeFragment,
    ScheduledPaxNodeFragment,
    ScheduleHistoryDetailFragment
} from "../../../../Queries/Scheduling";

export const Queries = {
    GetScheduleHistory: gql`
        query GetScheduleHistory(
            $tpID: ID!
            $date: AWSDate!
            $groupName: String!
            $lastKnownController: ID!
            $customerID: ID!
        ){
            getTransporter(
                _id: $tpID
            ){
                _id
                name
            }
            getCustomer(
                _id: $customerID
            ){
                _id
                name
            }
            getLastKnownController: getLocation(
                _id: $lastKnownController
            ){
                ...LocationFragment
            }
            GetScheduleHistory(
                tpID: $tpID
                date: $date
                groupName: $groupName
                lastKnownController: $lastKnownController
            ){
                ...ScheduleHistoryDetailFragment
            }
        }
        ${ScheduleHistoryDetailFragment}
    `
}

export const Mutations = {
    SaveSchedulePaxCgoV2: gql`
        mutation SchedulePaxCgo_V2(
            $customerID: ID!
            $tpID: ID!
            $lastKnownController: ID!
            $scheduledFlightDate: AWSDate!
            $scheduledGroup: String!
            $modifiedPax: [ScheduledPassengerInput_V2!]!
            $modifiedCgo: [ScheduledCargoInput_V2!]!
            $deletedEntities: [DeletedScheduledEntityInput!]
        ){
            SchedulePaxCgo_V2(
                customerID: $customerID
                tpID: $tpID
                lastKnownController: $lastKnownController
                scheduledFlightDate: $scheduledFlightDate
                scheduledGroup: $scheduledGroup
                modifiedPax: $modifiedPax
                modifiedCgo: $modifiedCgo
                deletedEntities: $deletedEntities
            ){
                cgonodes {
                    ...ScheduledCgoNodeFragment
                }
                paxnodes {
                    ...ScheduledPaxNodeFragment
                }
            }
        }
        ${ScheduledPaxNodeFragment}
        ${ScheduledCgoNodeFragment}
    `
}