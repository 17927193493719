import { Card, Col, Collapse, Divider, Form, Icon, Input, Row, Tooltip } from 'antd';
import commonColumns from 'common/table/columns';
import { FilterableTable } from 'components/tables/FilterableTable';
import React, { Component } from 'react';

const { Panel } = Collapse;

const PanelHeader = (props) => (
    <Row type="flex" align="middle">
        <Col style={{ flex: 1 }}>
            <span>Leg {props.order || 1}</span>
            <Divider type="vertical" />
            <span>{props.departure}</span>
            <Icon style={{ margin: '0 1rem' }} type="arrow-right" />
            <span>{props.destination}</span>
            <Divider type="vertical" />
            <Tooltip title="Passenger Count">
                <Icon type="team" /> {props.paxCount || 0}
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Cargo Count">
                <Icon type="right-square" /> {props.cgoCount || 0}
            </Tooltip>
        </Col>
        <Col style={{marginRight: '1rem' }}>
            <Tooltip title="Flight Time (minutes)">
                <Form.Item label="Flight Time: ">
                    {props.form.getFieldDecorator('flightTime_' + ((props.order && props.order-1) || '0'), {
                        initialValue: props.flightTime
                    })(
                        <Input onClick={e => e.stopPropagation()} type="number" size="small" />
                    )}
                </Form.Item>
            </Tooltip>
        </Col>
    </Row>
)

class LegTimeEntry extends Component {
    render() { 
        let { data } = this.props;
        if (!data) data = []
        return (
            <div>
                {data.map((leg, idx) => (
                    this.props.pax ?
                        <Collapse style={{ marginBottom: '6px' }}>
                            <Panel header={
                                <PanelHeader 
                                    order={idx + 1} 
                                    departure={leg.departure} 
                                    destination={leg.destination} 
                                    bagCount={leg.bagCount || 0}
                                    paxCount={(leg.paxIDs && leg.paxIDs.length) || 0}
                                    cgoCount={(leg.cgoIDs && leg.cgoIDs.length) || 0}
                                    flightTime={leg.flightTime || 0}
                                    form={this.props.form}
                                />
                            }>
                                <FilterableTable
                                    dataSource={[...leg.paxIDs, ...leg.cgoIDs]}
                                    rowKey={record => record._id}
                                    size="small"
                                    pagination={false}
                                    columns={commonColumns.paxAndCgo.some(['name', 'departure', 'destination'])}
                                />
                            </Panel>
                        </Collapse>
                        :
                        <Card key={idx} style={{ marginBottom: '6px' }}>
                            <PanelHeader 
                                order={idx + 1} 
                                departure={leg.departure} 
                                destination={leg.destination} 
                                bagCount={leg.bagCount || 0}
                                paxCount={(leg.paxIDs && leg.paxIDs.length) || 0}
                                cgoCount={(leg.cgoIDs && leg.cgoIDs.length) || 0}
                                flightTime={leg.flightTime || 0}
                                form={this.props.form}
                            />
                        </Card>
                ))}
            </div>
        );
    }
}
 
export default LegTimeEntry;