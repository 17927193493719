import gql from "graphql-tag";

export default gql`
query CountryCodeQuery {
    getCountryCodes {
        _id
        countries {
            Code
            Name
        }
    }
}
`