import { Button } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal';
import MCIcon from 'components/icon';
import React, { PropsWithChildren, useRef, useState } from 'react';
import { BaseFeedbackForm } from '.';
import { FeedbackFormProps } from './form';
import { FeedbackInput, useFeedbackSubmitter } from './hook';

export interface FeedbackFormModalProps extends Omit<ModalProps, 'onOk'> {
    feedbackFormProps?: FeedbackFormProps,
    onSubmitSuccessful?: () => void
}

const FeedbackFormModal: React.FC<FeedbackFormModalProps> = (props) => {
    const [ received, setReceived ] = useState(false);
    const formRef = useRef(null);
    const [ submit, { loading: submitLoading } ] = useFeedbackSubmitter({
        onSubmitted: () => setReceived(true)
    });

    function handleOk(e){
        e.stopPropagation();
        formRef.current.validateFieldsAndScroll((err: any, values: FeedbackInput) => {
            if (!err){
                submit(values);
            }
        })
    }

    let modalProps: ModalProps & PropsWithChildren<any> = {
        bodyStyle: { minHeight: '50vh' },
        centered: true,
        destroyOnClose: true,
        ...props,
        title: "User Feedback" || props.title,
        afterClose: () => {
            setReceived(false);
            props.afterClose?.();
        }
    }

    if (received){
        modalProps.children = <div>
                <MCIcon type="smile-colored" style={{ fontSize: '6rem', textAlign: 'center', width: '100%', marginBottom: '1rem' }} />
                <h2 style={{ textAlign: 'center' }}>Thank you for your feedback!</h2>
                {formRef.current?.getFieldValue('responseType') && formRef.current?.getFieldValue('responseType') !== 'none' ? (
                    <div style={{ textAlign: 'center' }}>
                        We will be getting back to you soon {formRef.current?.getFieldValue('responseType') ? ` by ${formRef.current?.getFieldValue('responseType')}.` : '.'}
                    </div>
                ) : null}
        </div>
        modalProps.bodyStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
        modalProps.footer=<Button onClick={() => props.onCancel?.(null)}>Close</Button>
    }
    else
    {
        modalProps.children = <BaseFeedbackForm {...props.feedbackFormProps} ref={formRef} />
        modalProps.onOk = handleOk;
        modalProps.okText = !submitLoading ? props.okText || 'Send Feedback to DataFlyt' : 'Submitting'
        modalProps.okButtonProps = {
            disabled: submitLoading
        }
    }

    return <Modal
        {...modalProps}
    />
}

export function feedbackModalProps(): Partial<ModalProps>{
    return {
        okText: "Send Feedback To DataFlyt"
    }
}

export default FeedbackFormModal