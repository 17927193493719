import gql from "graphql-tag";

export default gql`
query LocationBlock($id: ID!){
    getLocation(_id: $id){
        _id
        block
        field
    }
}
`