import React from 'react';
import { Button, Popover } from 'antd';
import './DestinationList.less';
import cn from 'classnames';
import {exportTextInRef} from "common/util/export-text-in-ref";

const DestArrow = ({ children, order }) => (
    <div className="arrowbox">
        {order > 0 ? (
            <svg className="arrowbox-edge arrowbox-edge-begin" viewBox="0 0 10 22" version="1.1">
                <g transform="matrix(1.07029,0,0,1,0,0)">
                    <path className="arrowbox-edge-fill" d="M0.934,1L9.343,11L0.934,21L9.343,21L9.343,1L0.934,1Z" />
                </g>
                <g transform="matrix(1.07029,0,0,1,0,0)">
                    <path className="arrowbox-edge-stroke" d="M9.343,1L0.934,1.018L8.409,11L0.934,21L9.343,21" />
                </g>
            </svg>
        ) : null}
        <div className={cn({
            "arrowbox-body": true,
            "arrowbox-body-begin": order === 0
        })}>
            <span className="arrowbox-text">{children}</span>
        </div>
        <svg className="arrowbox-edge arrowbox-edge-end" viewBox="0 0 11 22" version="1.1">
            <g id="fill" transform="matrix(1,0,0,1,-55,0)">
                <path className="arrowbox-edge-fill" d="M55,1L55,21L64,11L55,1Z" />
            </g>
            <path className="arrowbox-edge-stroke" d="M0,0.036L10,11L0,22" />
        </svg>
    </div>
)

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{data: any}> & React.RefAttributes<HTMLDivElement>>}
 */
const DestinationList = React.forwardRef(({ data }, ref) => {
    const truncatedData = data.slice(0, 5);
    const isTruncated = truncatedData.length < data.length;

    const text = data?.map(dest => dest).join(' -> ');
    const newRef = exportTextInRef(text, ref);

    return (
        <div ref={newRef}>
            {truncatedData.map((dest, i) => (
                <DestArrow key={i} order={i} >{dest}</DestArrow>
            ))}
            {isTruncated && (
                <Popover content={
                    <div style={{marginRight: '-8px'}}>
                        {data.slice(5).map((dest, i) => (
                            <DestArrow key={i} order={1-i} >{dest}</DestArrow>
                        ))}
                    </div>
                }>
                    <Button className="mc-link-btn">See more</Button>
                </Popover>
            )}
        </div>
    )
})

export default DestinationList