import gql from "graphql-tag";

export default gql`
query GlobalFilter  {
    globalFilter @client {
        visible
        customer {
            key
            label
        }
        transporter {
            key
            label
        }
        departure {
            key
            label
        }
        destination {
            key
            label
        }
        employer {
            key
            label
        }
        pax {
            lastName
            firstName
        }
        cgo {
            name
        }
    }
}
`