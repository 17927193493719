import React from 'react';
import { withApollo } from 'react-apollo';
import WithOrgData from './WithOrgData';
import gql from 'graphql-tag';

const PAX_ISN_CHECK = gql`
mutation PaxIsnCheck($_id: ID! $tpID: ID!){
    setPassengerIsnCheck(_id: $_id tpID: $tpID){
        _id
        lastIsnResultWithHeader {
            isn_customer
            isn_ownerid
            success
            error_message
            quick_check_status
            reason
            qc_count
            qc_result {
                isnStatus {
                    shortLabel
                }
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
            selectedResult {
                isnStatus {
                    shortLabel
                }
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
        }
    }
}
`

const CREW_ISN_CHECK = gql`
mutation CrewIsnCheck($_id: ID! $tpID: ID!){
    setBoatCrewIsnCheck(_id: $_id tpID: $tpID){
        _id
        lastIsnResultWithHeader {
            isn_customer
            isn_ownerid
            success
            error_message
            quick_check_status
            reason
            qc_count
            qc_result {
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
            selectedResult {
                isnId
                lastName
                firstName
                companyID
                companyName
                trainingStatus
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                quickCheckStatus
                reasonsForNonCompliance
                trainingDeficiencies
                reason
            }
        }
    }
}
`

class IsnChecker extends React.Component {
    state = {
        results: []
    }
    isnCheckRunning = () => {
        const runningIsnChecks = this.state.results.filter(item => item && item.running)
        return runningIsnChecks.length > 0
    }
    run = async (passengers) => {
        if (this.isnCheckRunning()) return
        const outbound = passengers.filter(pax => pax.transitType !== 'INBOUND')
        const results = outbound.map(pax => ({
            _id: pax._id,
            running: true,
            error: null
        }))
        await this.setState({ results })
        const runPax = outbound.map(pax => {
            return this.checkPassenger(pax)
        })
        return Promise.all(runPax)
    }
    checkPassenger = (pax) => {
        const { client, orgData } = this.props;
        return client.mutate({
            mutation: pax.__typename === 'BoatCrew' ? CREW_ISN_CHECK : PAX_ISN_CHECK,
            variables: {
                _id: pax._id,
                tpID: orgData.transporter._id
            }
        })
        .then(result => {
            const newResults = this.state.results.map(item => {
                if (item && item._id === pax._id){
                    return {
                        _id: pax._id,
                        running: false,
                        error: null,
                        result
                    }
                }
                return item
            })
            this.setState({ results: newResults })
        })
        .catch(error => {
            const newResults = this.state.results.map(item => {
                if (item && item._id === pax._id){
                    return {
                        _id: pax._id,
                        running: false,
                        error
                    }
                }
                return item
            })
            this.setState({ results: newResults })
        })
    }
    clearResultError = (id) => {
        const results = this.state.results.map(result => {
            if (result._id === id){
                return {
                    ...result,
                    error: null
                }
            }
            return result
        })
        this.setState({ results })
    }
    render(){
        const { component: Component, ...rest } = this.props;
        return (
            <Component 
                {...rest}
                isncheck={{
                    running: this.isnCheckRunning(),
                    run: this.run,
                    results: this.state.results,
                    clearResultError: this.clearResultError
                }}
            />
        )
    }
}

const IsnCheckerWithData = WithOrgData( withApollo(IsnChecker) )

// eslint-disable-next-line import/no-anonymous-default-export
export default (component) => {
    return props => (
        <IsnCheckerWithData component={component} {...props} />
    )
}