import {Phase1HistoryEditorApi} from "./api";
import CloneForm from "./clone-form";
import {Button, Drawer, Form} from "antd";
import {tailFormItemLayout} from "../../../../common/form";
import React, {useContext} from "react";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import {
    Customer, Location,
    QueryGetScheduledPaxCgoV2Args, QueryGetScheduleHistoryArgs,
    ScheduledPaxCgoResult,
    ScheduleHistoryDetail, Transporter
} from "../../../../__generated__/graphql/types";
import {useQuery} from "react-apollo";
import {Queries} from "./graphql";
import {OrgDataContext} from "../../../../context/orgData";


export interface CloneFormDrawerProps {
    api: Phase1HistoryEditorApi
}

function useCheckExistingSchedule(api: Phase1HistoryEditorApi){

    const orgData = useContext(OrgDataContext);

    const vars: QueryGetScheduleHistoryArgs = {
        groupName: api.cloneSchedule.form.getValue('name'),
        date: api.cloneSchedule.form.getValue('date')?.format('YYYY-MM-DD'),
        lastKnownController: api.groupData?.lastKnownController,
        tpID: orgData.transporterID
    }

    const queryResult = useQuery<
        {
            GetScheduleHistory: ScheduleHistoryDetail
        },
        QueryGetScheduleHistoryArgs
    >(
        Queries.GetScheduleHistory,
        {
            variables: vars,
            fetchPolicy: 'network-only',
            skip: !vars.groupName || !vars.date
        }
    )

    const data = queryResult.data?.GetScheduleHistory;

    return {
        loading: queryResult.loading,
        exists: Boolean(data)
    };
}

const CloneFormDrawer: React.FC<CloneFormDrawerProps> = ({ api }) => {
    const bp = useBreakpoints();

    const { loading, exists } = useCheckExistingSchedule(api);

    return (
        <Drawer
            visible={api.cloneSchedule.drawer.isOpen()}
            width={bp.min('small') ? 500 : '100%'}
            closable
            onClose={api.cloneSchedule.drawer.close}
            title="Clone Schedule"
        >
            <CloneForm
                ref={api.cloneSchedule.ref}
                formData={api.cloneSchedule.form.get()}
                onFieldsChange={api.cloneSchedule.form.set}
            />
            <Form.Item
                {...tailFormItemLayout}
                validateStatus={exists ? "error" : undefined}
                hasFeedback={exists}
                help={exists ? "A schedule with this Name and Flight Date already exists. Choose another name or date." : undefined}
            >
                <Button
                    type="primary"
                    icon="copy"
                    loading={api.cloneSchedule.clone.isCloning}
                    onClick={api.cloneSchedule.clone.initiate}
                    disabled={exists || loading}
                >
                    Clone and Edit
                </Button>
            </Form.Item>
        </Drawer>
    )
}

export default CloneFormDrawer;