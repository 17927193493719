import React, { Component } from 'react';
import './Attribute.less';

class Atrribute extends Component {
    render() { 
        const { label, value, altVal = 'None' } = this.props;
        return (
            <div className="list-attribute">
                <div className="list-attribute-label">
                    {label}:
                </div>
                {value || altVal}
            </div>
        )
    }
}
 
export default Atrribute;