import { Select, Tooltip } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React, { useState } from 'react';
import { SearchSelectWithData } from '../SearchSelect';
import OrganizationQuery from './OrganizationQuery';

const { Option } = Select;

export interface OrganizationSelectProps extends SelectProps {
    classTypes?: Array<
                    'flytsuite.customer' |
                    'customer' |
                    'flytsuite.transporter' |
                    'transporter' | 
                    'flytsuite.employer' |
                    'employer'
                    >
    limit?: number,
    type?: string,
    organizations?: Array<string>
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = (props) => {
    const { classTypes, limit, type, organizations, ...restProps } = props;
    const [ searchValue, setSearchValue ] = useState<string>(null);

    const variables = {
        limit: limit || 10,
        filter: {
            type: type,
            name: searchValue,
            classTypes: classTypes,
            organizations: organizations
        }
    }
    var placeholderMsg = props.placeholder;
    if (!props.placeholder && classTypes){
        if (classTypes.length > 1){
            placeholderMsg = 'Search for a organization';
        }else if (classTypes.length > 0){
            switch (classTypes[0]) {
                case 'flytsuite.customer':
                case 'customer':
                    placeholderMsg = 'Search for a customer';
                    break;
                case 'flytsuite.transporter':
                case 'transporter':
                    placeholderMsg = 'Search for a transporter';
                    break;
                case 'flytsuite.employer':
                case 'employer':
                    placeholderMsg = 'Search for an employer';
                    break;
                default:
                    placeholderMsg = 'Search for a organization';
                    break;
            }
        }
    }

    return <Tooltip title={placeholderMsg} mouseEnterDelay={1}>
        <SearchSelectWithData
            {...restProps}
            query={OrganizationQuery}
            variables={variables}
            getData={(data) => data && data.organizations && data.organizations.docs}
            onDataItem={item => <Option key={item._id}>{item.name}</Option>}
            onSearch={(searchValue) => setSearchValue(searchValue)}
            placeholder={placeholderMsg}
        />
    </Tooltip>
}

// class OrganizationSelect extends Component<SelectProps> {
//     state = {
//         value: '',
//         searchValue: null
//     }
//     getValue = () => {
//         if (this.props.value){
//             if (this.state.value !== this.props.value){
//                 this.setState({ value: this.props.value });
//             }
//             return this.props.value
//         }
//         return this.state.value
//     }
//     handleChange = (value) => {
//         this.setState({ value });
//         this.props.onChange &&
//             this.props.onChange(value)
//     }
//     render() {
//         const variables = {
//             limit: this.props.limit || 10,
//             filter: {
//                 type: this.props.type,
//                 name: this.state.searchValue,
//                 classTypes: this.props.classTypes,
//                 organizations: this.props.organizations
//             }
//         }
//         var placeholderMsg = this.props.placeholder;
//         if (!this.props.placeholder){
//             if (this.props.classTypes.length > 1){
//                 placeholderMsg = 'Search for a organization';
//             }else if (this.props.classTypes.length > 0){
//                 switch (this.props.classTypes[0]) {
//                     case 'flytsuite.customer':
//                     case 'customer':
//                         placeholderMsg = 'Search for a customer';
//                         break;
//                     case 'flytsuite.transporter':
//                     case 'transporter':
//                         placeholderMsg = 'Search for a transporter';
//                         break;
//                     case 'flytsuite.employer':
//                     case 'employer':
//                         placeholderMsg = 'Search for an employer';
//                         break;
//                     default:
//                         placeholderMsg = 'Search for a organization';
//                         break;
//                 }
//             }
//         }

//         return (
//             <Tooltip title={placeholderMsg} mouseEnterDelay={1}>
//                 <SearchSelectWithData
//                     {...this.props}
//                     query={OrganizationQuery}
//                     variables={variables}
//                     value={this.getValue()}
//                     getData={(data) => data && data.organizations && data.organizations.docs}
//                     onDataItem={item => <Option key={item._id}>{item.name}</Option>}
//                     onChange={this.handleChange}
//                     onSearch={(searchValue) => this.setState({ searchValue })}
//                     placeholder={placeholderMsg}
//                 />
//             </Tooltip>
//         );
//     }
// }

// OrganizationSelect.propTypes = {
//     value: PropTypes.any,
//     onChange: PropTypes.func,
//     classTypes: PropTypes.arrayOf(PropTypes.oneOf(['flytsuite.customer', 'flytsuite.transporter', 'flytsuite.employer'])),
//     placeholder: PropTypes.string
// }
 
export default OrganizationSelect;