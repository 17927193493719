
import React from 'react';
import { Modal } from 'antd';
import pluralize from 'pluralize';

// eslint-disable-next-line import/no-anonymous-default-export
export default (passengers, callback) => {
    const invalidPax = passengers.filter(pax => {
        if (pax.bagWeight && !pax.bagCount){
            return true;
        }
        if (!pax.paxWeight || !pax.bagWeight){
            return true;
        }
        return false;
    });
    const paxWithNoPaxWeight = invalidPax.filter(pax => !pax.paxWeight);
    const paxWithNoBagWeight = invalidPax.filter(pax => !pax.bagWeight);
    const paxWithinvalidBagCount = invalidPax.filter(pax => pax.bagWeight && !pax.bagCount);
    const hasOrHave = (count) => count !== 1 ? 'have' : 'has';
    if (invalidPax.length > 0){
        Modal.confirm({
            title: 'Potentially invalid data detected',
            content: (
                <div>
                    <ul>
                        {paxWithNoPaxWeight.length ? <li>{paxWithNoPaxWeight.length} {pluralize('passenger', paxWithNoPaxWeight.length)} {hasOrHave(paxWithNoPaxWeight.length)} zero set as their weight.</li> : null}
                        {paxWithNoBagWeight.length ? <li>{paxWithNoBagWeight.length} {pluralize('passenger', paxWithNoBagWeight.length)} {hasOrHave(paxWithNoBagWeight.length)} zero set as their bag weight.</li> : null}
                        {paxWithinvalidBagCount.length ? <li>{paxWithinvalidBagCount.length} {pluralize('passenger', paxWithinvalidBagCount.length)} {hasOrHave(paxWithinvalidBagCount.length)} bag weight but {hasOrHave(paxWithinvalidBagCount.length)} no bags.</li> : null}
                    </ul>
                    <p>If this is intended, please click continue. Otherwise, please click cancel and correct these errors.</p>
                </div>
            ),
            onOk: () => callback(),
            okText: 'Continue',
            icon: 'exclamation-circle',
            autoFocusButton: 'cancel',
            okButtonProps: {
                type: 'default'
            },
            cancelButtonProps: {
                type: 'primary'
            }
        })
    }
    else
    {
        callback()
    }
}