import React, { useRef, CSSProperties } from 'react';
import { Input, Row, Col, DatePicker } from 'antd';
import moment from 'moment';

export interface CredentialInputProps {
    showKey?: boolean,
    showExpiration?: boolean,
    inputProps?: any,
    style?: CSSProperties,
    value?: any,
    onChange?(value: any): void
}

export const CredentialInput: React.FC<CredentialInputProps> = ({
    value={},
    onChange=() => null,
    showKey=true,
    showExpiration=true,
    inputProps={},
    style
}) => {
    const keyInput = useRef(null);
    const expInput = useRef(null);
    const inputStyle = inputProps.style || {};

    const onInputChange = (type, getValue=(e) => e.target.value) =>  (e) => {
        onChange({
            ...value,
            [type]: getValue(e)
        })
    }

    const renderInput = (title, render) => {
        return (
            <Row type="flex" gutter={12}>
                <Col span={6} style={{ textAlign: 'right' }}>
                    {title}
                </Col>
                <Col span={18}>
                    {render}
                </Col>
            </Row>
        )
    }

    return (
        <Row type="flex" style={style}>
            {showKey ? (
                <Col style={{ flex: 1 }}>
                    {renderInput('Key', (
                        <Input
                            {...inputProps}
                            style={{ width: 'auto', ...inputStyle }}
                            ref={keyInput}
                            value={value.key}
                            onChange={onInputChange('key')}
                        />
                    ))}
                </Col>
            ) : null}
            {showExpiration ? (
                <Col style={{ flex: 1 }}>
                    {renderInput('Expiration', (
                        <DatePicker
                            {...inputProps}
                            style={{ width: 'auto', ...inputStyle }}
                            ref={expInput}
                            value={value.expiration && moment(value.expiration)}
                            onChange={onInputChange('expiration', (value) => value && value.format('YYYY-MM-DD'))}
                            format="MM-DD-YYYY"
                        />
                    ))}
                </Col>
            ) : null}
        </Row>
    )
}