import React from 'react';
import {createFormField, FormField} from "../../../../common/form";
import {produce} from "immer";
import {Moment} from "moment";

export interface CloneFormFields {
    name: FormField<string>,
    date: FormField<Moment>
}

type CloneFormValues = {
    [K in keyof CloneFormFields]: CloneFormFields[K]['value'];
};

const DefaultForm = {
    name: createFormField(''),
    date: createFormField(null)
} as CloneFormFields

export const DefaultState = {
    cloneScheduleOpen: false,
    initCloneScheduleForm: DefaultForm,
    cloneScheduleForm: {...DefaultForm}
}

type State = typeof DefaultState;

type Action = {
    type: 'SET_CLONE_OPEN',
    payload: boolean
} | {
    type: 'UPDATE_FORM',
    payload: CloneFormFields
} | {
    type: 'INIT_FORM',
    payload: CloneFormValues
} | {
    type: 'RESET_FORM'
}

export const Reducer: React.Reducer<State, Action> = (_state, action) => {

    function formValuesToFields(values: CloneFormValues | CloneFormFields){
        const formFields = {};
        for (let key in values) {
            if (formFields[key] && typeof formFields[key] === 'object' && 'value' in formFields[key]){
                formFields[key] = createFormField(values[key].value);
            }
            else
            {
                formFields[key] = createFormField(values[key]);
            }
        }
        return formFields as CloneFormFields;
    }
    return produce(_state, (draft) => {

        function resetForm(){
            draft.cloneScheduleForm = formValuesToFields(draft.initCloneScheduleForm);
        }

        switch (action.type){
            case "SET_CLONE_OPEN":
                draft.cloneScheduleOpen = action.payload;
                if (!action.payload){
                    resetForm();
                }
                break;
            case "UPDATE_FORM":
                draft.cloneScheduleForm = action.payload;
                break;
            case "INIT_FORM":
                draft.initCloneScheduleForm = formValuesToFields(action.payload);
                draft.cloneScheduleForm = formValuesToFields(action.payload);
                break;
            case "RESET_FORM":
                resetForm();
                break
        }
    })
}