import React, { useRef, forwardRef, LegacyRef, useState, useEffect } from 'react';
import { EntryFormProps, entryFormOptions, useAutoFocus } from '.';
import { Form, Input, Select, Checkbox, Tooltip, Typography, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {FormItemClassNames, formItemLayout, tailFormItemLayout} from 'common/form';
import { composeValidators, validateAlphaNumeric, validateDecimal, validateOtherFieldIsFilledIn } from 'formValidators';
import useUserGroups from "../../../hooks/useUserGroups";

export interface LocationEntryFormProps extends EntryFormProps {
    areaBlockDuplicationChecker?: React.ReactElement,
    autoFocusLatitude?: boolean
}

const LocationEntryForm: React.FC<LocationEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>)=> {
    const [ nameInputRef, setNameInputRef ] = useState(null);
    const [ latitudeInputRef, setLatitudeInputRef ] = useState(null);
    const [ userGroups ] = useUserGroups();

    const isSuperUser = userGroups.includes('dataflyt.superuser');

    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    useAutoFocus(props.autoFocus && !props.autoFocusLatitude, nameInputRef);
    useAutoFocus(props.autoFocusLatitude, latitudeInputRef);

    useEffect(() => {
        let long = getFieldValue('longitude');

        if (typeof long === 'string' && long.length > 0 && !long.startsWith('-')){
            long = "-" + long; // Remove negative
            setFieldsValue({ longitude: long });
        }

    }, [ getFieldValue('longitude') ])

    // Type field needs to be disabled because all "rigs" must be an off-shore location.
    let isRig = getFieldValue('getRig') ? true : false;

    return <Form ref={ref}>
        <Form.Item label="Name" {...formItemLayout}>
            {getFieldDecorator('name', {
                rules: [ { required: true, message: 'Name is required' } ]
            })(<Input disabled={!props.isNewEntry} ref={(r) => setNameInputRef(r)} />)}
        </Form.Item>
        {props.dupeChecker}
        <Form.Item label="Organization" {...formItemLayout}>
            {getFieldDecorator('ownerOrg', {
                rules: [ { required: true, message: 'Organization is required' } ]
            })(<Input />)}
        </Form.Item>
        <Form.Item label="Type" {...formItemLayout}>
            <Tooltip title={isRig ? 'Rigs will always be off shore' : ''}>
                {getFieldDecorator('type', {
                    initialValue: null,
                    rules: [ { required: true, message: 'Type is required' } ]
                })(
                        <Select placeholder="Select a type" showAction={["focus", "click"]} disabled={isRig}>
                        <Select.Option value={null} >Select a type</Select.Option>
                        <Select.Option value="OFFSHORE">Off Shore</Select.Option>
                        <Select.Option value="ONSHORE">On Shore</Select.Option>
                    </Select>
                )}
            </Tooltip>
        </Form.Item>
        {isSuperUser ? (
            <Alert
                message="Admin Only"
                description={
                    <Form.Item label="Has DataFlyt Hardware" {...formItemLayout} className={FormItemClassNames.NoMargin}>
                        {getFieldDecorator('hasDataflytHardware', {
                            valuePropName: 'checked'
                        })(
                            <Checkbox />
                        )}
                    </Form.Item>
                }
                type="error"
                showIcon={false}
                style={{
                    marginBottom: 24
                }}
            />
        ) : null}
        {getFieldValue('type') === 'OFFSHORE' ? (
            <>
                <Form.Item label="Area" {...formItemLayout}>
                    {getFieldDecorator('block', {
                        rules: [
                            { required: true, message: 'Area is required' },
                            { validator: validateAlphaNumeric }
                        ]
                    })(<Input />)}
                </Form.Item>
                <Form.Item label="Block" {...formItemLayout}>
                    {getFieldDecorator('field', {
                        rules: [
                            { required: true, message: 'Block is required' },
                            { validator: validateAlphaNumeric }
                        ]
                    })(<Input />)}
                </Form.Item>
                {props.areaBlockDuplicationChecker}
                <Form.Item label="TWIC Required" {...formItemLayout}>
                    {getFieldDecorator('twicRequired', {
                        valuePropName: 'checked'
                    })(<Checkbox />)}
                </Form.Item>
            </>
        ) : null}
        <Form.Item label="Is OneOff" {...formItemLayout}>
            {getFieldDecorator('isOneOff', {
                valuePropName: 'checked'
            })(<Checkbox />)}
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
            <Alert
                type="info"
                message={<span><strong>NOTE: </strong>Changing the lat/long below will override the lat/long from BlockInfo. To update these coordinates on BlockInfo please go to <a href='http://www.blockinfo.com' target='_blank'>blockinfo.com</a></span>}
            />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
            <Alert
                type="warning"
                message={<span><strong>NOTE: </strong>Only western hemisphere locations are supported at this time.</span>}
            />
        </Form.Item>
        <Form.Item label="Latitude" {...formItemLayout} extra={<span>Ex: LCH is 30.123651125777588</span>}>
            {getFieldDecorator('latitude', {
                rules: [
                    { validator: composeValidators(validateDecimal, validateOtherFieldIsFilledIn(props.form, 'longitude', 'Longitude')) }
                ]
            })(<Input ref={(r) => setLatitudeInputRef(r)} />)}
        </Form.Item>
        <Form.Item label="Longitude" {...formItemLayout} extra={<span>Ex: LCH is -93.2210574153492</span>}>
            {getFieldDecorator('longitude', {
                rules: [
                    { validator: composeValidators(validateDecimal, validateOtherFieldIsFilledIn(props.form, 'latitude', 'Latitude')) }
                ]
            })(<Input />)}
        </Form.Item>
    </Form>
})

export default Form.create<LocationEntryFormProps & FormComponentProps>(entryFormOptions<LocationEntryFormProps>())(LocationEntryForm)