import React from 'react';
import {Icon, List, Table} from 'antd';
import moment from 'moment';
import {groupBy} from 'lodash';
import {castArray} from 'common/util';
import {ScheduledPacCgoGroupVersioned} from "./types";
import './style.less';
import useBreakpoints from "../../../../hooks/useBreakpoints";
import {ColumnProps} from "antd/lib/table";

export interface ScheduledGroupsListProps {
    onGroupClicked?: (group: ScheduledPacCgoGroupVersioned, index: number) => void,
    data?: ScheduledPacCgoGroupVersioned[],

    /**
     * Shows the "X on flight" next to pax/cgo counts
     * @default true
     */
    showOnFlight?: boolean
}

const BASE_CLS = 'mc-scheduled-groups-list';

function getFlightStatusClassName(paxCount: number, paxOnFlightCount: number){
    const base = BASE_CLS + "-pill";
    if (paxOnFlightCount <= 0){
        return `${base} ${base + '-no-pax'}`;
    }
    if (paxCount <= paxOnFlightCount){
        return `${base} ${base + '-all-pax'}`;
    }
    return `${base} ${base + '-some-pax'}`;
}

const ScheduledGroupsList: React.FC<ScheduledGroupsListProps> = (props) => {

    const bp = useBreakpoints();

    let grouped = groupBy(castArray(props.data), 'scheduledFlightDate');
    let data: [ string, ScheduledPacCgoGroupVersioned[] ][] = Object.entries(grouped);

    const columns: ColumnProps<ScheduledPacCgoGroupVersioned>[] = [
        {
            key: 'name',
            title: 'Schedule Name',
            dataIndex: 'scheduledGroup',
            width: bp.min("medium") ? 230 : undefined,
        },
        {
            key: 'flightLegs',
            title: 'Legs',
            width: bp.min("medium") ? 380 : undefined,
            render: (_, record) => {

                let legs = [];

                if (record.scheduledVersion === 'V2'){
                    legs = record?.legs?.map(leg => (
                        {
                            departureName: leg.departureDoc?.name,
                            destinationName: leg.destinationDoc?.name,
                            scheduledOrder: leg.scheduledOrder
                        })
                    ) || [];
                }
                else
                {
                    legs = [
                        {
                            departureName: record.departureID?.name,
                            destinationName: record.destinationID?.name,
                            scheduledOrder: 0
                        }
                    ]
                }

                legs.sort((a, b) => {
                    console.log(a.scheduledOrder - b.scheduledOrder, a ,b)
                    return a.scheduledOrder - b.scheduledOrder;
                })

                return legs?.map(leg => {
                    return <div>
                        <span title="Departure">{leg.departureName}</span> <Icon
                        type="arrow-right"/> <span title="Destination">{leg.destinationName}</span>
                    </div>
                }) || null
            }
        }
    ]

    if (bp.min('medium')){
        columns.push(...[
            {
                key: 'outbound',
                title: 'Outbound',
                render: (_, record) => {
                    const pax = record.paxOutbound;
                    const cgo = record.cgoOutbound;
                    const sum = pax + cgo;
                    const onFlight =
                        record.paxOutboundOnFlight + record.cgoOutboundOnFlight;
                    return <span>
                                        <strong>{pax}</strong> pax | <strong>{cgo}</strong> cgo
                        {props.showOnFlight ? (
                            <span style={{marginLeft: '12px'}}
                                  className={getFlightStatusClassName(sum, onFlight)}>{`${onFlight} on flight`}</span>
                        ) : null}
                                    </span>;
                }
            },
            {
                key: 'transfer',
                title: 'Transfer',
                render: (_, record) => {
                    const pax = record.paxTransfer;
                    const cgo = record.cgoTransfer;
                    const sum = pax + cgo;
                    const onFlight =
                        record.paxTransferOnFlight + record.cgoTransferOnFlight;
                    return <span>
                                        <strong>{pax}</strong> pax | <strong>{cgo}</strong> cgo
                        {props.showOnFlight ? (
                            <span style={{ marginLeft: '12px' }} className={getFlightStatusClassName(sum, onFlight)}>{`${onFlight} on flight`}</span>
                        ): null}
                                    </span>;
                }
            },
            {
                key: 'inbound',
                title: 'Inbound',
                render: (_, record) => {
                    const pax = record.paxInbound;
                    const cgo = record.cgoInbound;
                    const sum = pax + cgo;
                    const onFlight =
                        record.paxInboundOnFlight + record.cgoInboundOnFlight;
                    return <span>
                                        <strong>{pax}</strong> pax | <strong>{cgo}</strong> cgo
                        {props.showOnFlight ? (
                            <span style={{ marginLeft: '12px' }} className={getFlightStatusClassName(sum, onFlight)}>{`${onFlight} on flight`}</span>
                        ) : null}
                                    </span>;
                }
            }
        ])
    }

    return <List
        dataSource={data}
        renderItem={(item) => {
            let itemData = item[1];
            return <List.Item>
                <div style={{ width: '100%' }}>
                    <h3>{item[0] && moment(item[0]).format('MMMM DD, YYYY (dddd)')}</h3>
                    <Table
                        className="mc-table"
                        rowKey={(record) => {
                            if (!record) return undefined;
                            let dep = record.departureID && record.departureID._id;
                            let dest = record.destinationID && record.destinationID._id;
                            let lkc = record.lastKnownController && record.lastKnownController._id;
                            return `${record.scheduledFlightDate}${record.scheduledGroup}${dep}${dest}${lkc}`
                        }}
                        pagination={false}
                        dataSource={itemData}
                        onRow={() => {
                            return {
                                style: {
                                    cursor: props.onGroupClicked ? 'pointer' : 'default'
                                }
                            }
                        }}
                        onRowClick={(record, index) => {
                            if (props.onGroupClicked){
                                props.onGroupClicked(record, index);
                            }
                        }}
                        size="small"
                        columns={columns}
                    />
                </div>
            </List.Item>
        }}
    />
}

ScheduledGroupsList.defaultProps = {
    showOnFlight: true
}

export default ScheduledGroupsList