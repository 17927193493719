import { Radio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import cn from 'classnames';
import MCIcon from 'components/icon';
import React from 'react';
import './style.less';

export interface FeedbackMoodProps extends RadioGroupProps {

}

const FeedbackMood: React.FC<FeedbackMoodProps> = (props) => {
    return (
        <Radio.Group size="large" {...props} className={cn('mc-feedback-mood', props.className)}>
            <Radio.Button value="happy"><MCIcon type="smile-colored" /> I'm happy</Radio.Button>
            <Radio.Button value="frustrated"><MCIcon type="frown-colored" /> I'm frustrated</Radio.Button>
        </Radio.Group>
    )
}

export default FeedbackMood