import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Select, message, Input } from 'antd';
import { SelectProps } from 'antd/lib/select';

const { Option } = Select;

const GET_TRANSPORTER = gql`
query {
    transporter @client {
        _id
        name
    }
}
`

const GET_AIRCRAFTS = gql`
query GetPilots($tpID: ID!){
    getAircrafts(tpID: $tpID) {
        _id
        tailNum
    }
}
`

const AircraftSelect = React.forwardRef((props: SelectProps, ref?: React.LegacyRef<Select>) => {
    const renderSelect = (tpID: any) => (
        <Query 
            query={GET_AIRCRAFTS} 
            variables={{tpID}} 
            onError={() => message.error('Error: Could not load aircrafts')} 
            fetchPolicy="cache-and-network">
            {
                ({ data, loading }) => {
                    const noAircraft = !loading && data && data.getAircrafts && data.getAircrafts.length <= 0;
                    if (noAircraft){
                        return (
                            <Input disabled={true} value="No Aircraft Available" />
                        )
                    }
                    return (
                        <Select 
                            ref={ref}
                            defaultValue="" 
                            {...props}
                            showSearch
                            optionFilterProp="children"
                            showAction={["focus", "click"]}
                            filterOption={(input, option) => String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={(!loading && data && data.getAircrafts && data.getAircrafts.length && props.value) || ''}>
                            <Option value="">Choose a aircraft</Option>
                            {!loading && data && data.getAircrafts && data.getAircrafts.map((aircraft: any) => (
                                aircraft && <Option key={aircraft._id} value={aircraft._id}>{aircraft.tailNum}</Option>
                            ))}
                        </Select>
                    )
                }
            }
        </Query>
    )
    return (
        <Query query={GET_TRANSPORTER} onError={() => message.error("Error: Failed to get current transportation provider")}>
        {
            ({ data, error }: any) => {
                if (data && !error){
                    return renderSelect(data.transporter._id)
                }
                return null
            }
        }
        </Query>
    )
})

export default AircraftSelect