
const SchedulingSchema = `
enum Phase1Step {
    sets,
    history
}

type SchedulingState {
    phase1Step: Phase1Step
}

type Query {
    getPhase1Step: Phase1Step
}

type Mutation {
    setPhase1Step(step: Phase1Step): Phase1Step
}
`

export default SchedulingSchema;