import React from 'react';
import { Layout, Row, Col, Spin, Icon, Button, Menu } from "antd";
import './DetailHeader.less';

const { Header } = Layout;

const DetailHeader = ({ title, onBackClicked, loading, activeTabKey, onTabChange, extra, enableExport }) => {
    return (
        <Header className="reporting-detail-header">
            <Row gutter={12}>
                <Col span={4} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {onBackClicked ? (
                        <Button
                            icon="left"
                            size="large"
                            style={{ marginRight: 12 }}
                            onClick={onBackClicked}
                        />
                    ) : null}
                    <h2 style={{ margin: 0, display: 'inline' }}>
                        {loading ? (
                            <Spin size="small" indicator={<Icon type="loading" spin />} />
                        ) : title}
                    </h2>
                </Col>
                <Col span={12}>                
                    <Menu
                        mode="horizontal"
                        selectedKeys={[activeTabKey]}
                        style={{ lineHeight: '62px' }}
                        onClick={(e) => onTabChange(e.key)}
                    >
                        <Menu.Item key="details">Details</Menu.Item>
                        <Menu.Item key="summary">Summary</Menu.Item>
                        {enableExport ? <Menu.Item key="export">Export</Menu.Item> : null}
                    </Menu>
                </Col>
                <Col span={8} style={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
                    {extra}
                </Col>
            </Row>
            {/* <Row gutter={12}>
                <Col span={8}>
                    {onBackClicked ? (
                        <Button
                            icon="left"
                            size="large"
                            style={{ marginRight: 12 }}
                            onClick={onBackClicked}
                        />
                    ) : null}
                    <h2 style={{ margin: 0, display: 'inline' }}>
                        {loading ? (
                            <Spin size="small" indicator={<Icon type="loading" spin />} />
                        ) : title}
                    </h2>
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio.Group
                        value={activeTabKey}
                        onChange={(e) => onTabChange(e.target.value)}
                        buttonStyle="solid"
                    >
                        <Radio.Button value="details">
                            Details
                        </Radio.Button>
                        <Radio.Button value="summary">
                            Summary
                        </Radio.Button>
                        <Radio.Button value="export">
                            Export
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                {extra ? (
                    <Col span={8} style={{ flex: 1, display: 'flex', justifyContent: 'end' }} >
                        {extra}
                    </Col>
                ) : null}
            </Row> */}
        </Header>
    )
}

export default DetailHeader