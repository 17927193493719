import { Button, Icon, message, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import config from 'config';
import { AuthContext } from 'context/auth';
import React, { useContext } from 'react';
import Auth from '@aws-amplify/auth';

export interface UserContentUploaderProps extends UploadProps {

}

const UserContentUploader: React.FC<UserContentUploaderProps> = (props) => {
    const { user, refreshUser } = useContext(AuthContext);
    // const [ fileList, setFileList ] = useState<Array<UploadFile>>([]);

    // function modifyFile(filename: string, newFileProps: Partial<UploadFile>) {
    //     let newFileList = fileList.map(file => {
    //         if (file.name !== filename) return file;
    //         return {
    //             ...file,
    //             ...newFileProps
    //         }
    //     })
    //     setFileList(newFileList);
    // }

    let baseKey = 'cognito/attachments/' + user?.username + '/';

    let uploadProps: UploadProps = {
        multiple: true,
        withCredentials: true,
        // fileList: fileList,
        // onChange: (info) => {
        //     console.log('onChange', info);
        //     setFileList([ ...info.fileList, info.file ])
        // },
        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
            await refreshUser();
            let s3 = new S3Client({
                region: config.amplify.Auth.region,
                credentials: await Auth.currentCredentials()
            });

            // function handleError(err){
            //     message.error("File failed to upload: " + err.message);
            //     onError(err);
            // }

            function handleError(reason){
                message.error("File failed to upload: " + reason);
                onError(reason);
            }

            // function uploadCallback(err: Error, data: S3.ManagedUpload.SendData){
            //     if (err){
            //         console.error('customRequest error::', err);

            //         setTimeout(handleError, 100, err)
            //         // modifyFile(file.name, { error: err, status: 'error' });
            //     }
            //     else
            //     {
            //         onSuccess(data, file);
            //         // modifyFile(file.name, { error: null, response: data, status: 'done' });
            //     }
            // }

            let comm = new PutObjectCommand({
                ACL: 'private',
                Bucket: config.userContentS3Bucket,
                Key: baseKey + file.name,
                Body: file,
                ContentType: file.type
            });

            s3.send(comm)
            .then((value) => {
                onSuccess(value, file);
            })
            .catch((reason) => {
                console.error('customRequest error::', reason);

                setTimeout(handleError, 100, reason)
            })

            // s3.upload({
            //     ACL: "private",
            //     Bucket: config.userContentS3Bucket,
            //     Key: baseKey + file.name,
            //     Body: file,
            //     ContentType: file.type
            // }, uploadCallback)
            // .on('httpUploadProgress', function(progress){
            //     onProgress({ percent: progress.loaded/progress.total }, file);
            // })
        },
        // fileList: fileList,
        // onRemove: (file) => removeFile(file.fileName),
        // onChange: (info) => setFileList(info.fileList)
    }

    return (
        <Upload {...uploadProps} {...props}>
            <Button><Icon type="upload" /> Upload</Button>
        </Upload>
    )
}

export default UserContentUploader;