import MCIcon from 'components/icon';
import React, {HTMLAttributes} from 'react';
import cn from 'classnames';
import './style.less';
import {TransportStateEnum} from "common/types/transport";

export interface FlightStateProps extends HTMLAttributes<HTMLSpanElement> {
    state?: TransportStateEnum,
    size?: 'large'
}

const FlightState = React.forwardRef<HTMLSpanElement, FlightStateProps>((props, ref) => {
    const { state, size, ...restProps } = props;
    let iconName, iconClassName = 'mc-flight-state-icon mc-flight-state-icon-' + state;
    let textColor = null;

    if (size){
        iconClassName += ' mc-flight-state-icon-lg';
    }

    // Icon switch
    switch (state){
        case TransportStateEnum.LOCKED:
            iconName = 'lock';
            break;
        case TransportStateEnum.OVERNIGHT:
            iconName = 'moon';
            break;
        case TransportStateEnum.DEPARTED:
            iconName = 'helicopter-flight';
            break;
    }

    //Color switch
    switch (state){
        case TransportStateEnum.ACTIVE:
            textColor = 'green';
            break;
        case TransportStateEnum.OVERNIGHT:
            textColor = 'purple';
            break;
        case TransportStateEnum.LOCKED:
            textColor = 'yellow';
            break;
        case TransportStateEnum.DEPARTED:
        case TransportStateEnum.RETIRED:
        case TransportStateEnum.FINALIZED:
            textColor = 'red';
            break;
        case TransportStateEnum.SCHEDULED:
            textColor = 'blue';
            break;
    }

    if (iconName){
        return <span {...restProps} ref={ref} className={cn({
            "mc-flight-state": true,
            "mc-flight-state-lg": size === 'large',
            ["mc-flight-state-" + textColor]: true
        }, props.className)}>
            <MCIcon type={iconName} className={iconClassName} />
            <span>{state}</span>
        </span>
    }
    return <span {...props} ref={ref}>{state}</span>;
})

export default FlightState