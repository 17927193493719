import React from 'react';
import {Alert, Button, Drawer, Form, Icon, Layout, Spin} from 'antd';
import './style.less';
import MainLayout from "components/scheduling/MainLayout";
import MainHeader from "./main-header";
import {cleanGraphQLErrorMsg} from "common/util";
import ScheduleEditor from 'components/scheduling/editor/schedule-editor-v2';
import {usePhase1HistoryEditorApi} from "./api";
import {BASE_CLS} from "./util";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import CloneForm from "./clone-form";
import {tailFormItemLayout} from "../../../../common/form";
import ViewButton from "../../../../components/scheduling/editor/schedule-editor-v2/view-button";
import CloneFormDrawer from "./clone-form-drawer";

export interface Phase1HistoryEditorPageProps {}

/**
 * Phase 2 of the scheduling process. Handles scheduling of passengers and cargo before putting them on a flight.
 */
export const Phase1HistoryEditorPage: React.FC<Phase1HistoryEditorPageProps> = (props) => {

    const api = usePhase1HistoryEditorApi();

    // Query error alert ----------------------------------------------------------------------------------------------
    let queryErrorAlert = null;
    if (api.queryInfo.error){
        queryErrorAlert = (
            <Alert
                type="error"
                showIcon
                message="Failed to fetch schedule data"
                description={cleanGraphQLErrorMsg(api.queryInfo.error)}
                style={{ margin: 12 }}
            />
        )
    }
    // -----------------------------------------------------------------------------------------------------------------

    let pageContent = null;

    if (!api.queryInfo.error){
        pageContent = (
            <Spin
                indicator={<Icon type="loading" />}
                spinning={api.queryInfo.loading}
                wrapperClassName={BASE_CLS + '-spinner'}
            >
                <Layout className={BASE_CLS + '-layout'}>
                    <ScheduleEditor
                        api={api}
                        groupFormRef={api.forms.groupForm.ref}
                        showEditButton={false}
                        showFormInToolbar={true}
                        showViewButton={false}
                        toolbarExtra={
                            [
                                <ViewButton api={api} />,
                                <Button
                                    icon="copy"
                                    type="primary"
                                    onClick={api.cloneSchedule.drawer.open}
                                >
                                    Clone Schedule
                                </Button>
                            ]
                        }
                    />
                </Layout>
            </Spin>
        )
    }

    return <MainLayout>
        <MainHeader api={api} />
        {queryErrorAlert}
        {pageContent}
        <CloneFormDrawer api={api} />
    </MainLayout>
}

export default Phase1HistoryEditorPage;