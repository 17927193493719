import { Icon, Typography } from 'antd';
import Select, { SelectProps, SelectValue } from 'antd/lib/select';
import { castArray } from 'common/util';
import gql from 'graphql-tag';
import React, { LegacyRef } from 'react';
import { useQuery } from 'react-apollo';

export interface CovidVaxManufacturerSelectProps<T=SelectValue> extends SelectProps<T> {

}

const CovidVaxManufacturerSelect: React.FC<CovidVaxManufacturerSelectProps> = React.forwardRef(({ children, ...props }, ref: LegacyRef<Select>) => {
    const { data, loading, error } = useQuery(gql`
        query GetVaxManufacturers {
            GetCovidVaxManufacturers
        }
    `, {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
    })

    let manuList = castArray(data?.GetCovidVaxManufacturers);
    if (manuList.length > 0){
        manuList = [ ...manuList, "Other" ]
    }

    let errorText = <Typography.Text type="danger"><Icon type="exclamation-circle" style={{ marginRight: '6px' }} />Failed to load data</Typography.Text>;
    return (
        <Select placeholder="Select a manufacturer" {...props} loading={loading && !data} ref={ref} notFoundContent={error ? errorText : undefined} showAction={["focus", "click"]}>
            {manuList.map(manuStr => (
                <Select.Option key={manuStr} value={manuStr}>{manuStr}</Select.Option>
            ))}
            {children}
        </Select>
    );
})

export default CovidVaxManufacturerSelect;