import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import WithOrgData from '../WithOrgData';

/**
 * Initializes global filters with data from local state
 */
class GlobalFilterInitializer extends Component {
    state = {
        isFinished: false,
        error: null,
        loading: false
    }
    componentDidMount(){
        const { orgData: { customer, transporter, employer } } = this.props;
        this.setState({ loading: true });

        // Call mutation to update global filters
        this.props.initialize({
            variables: {
                customer: customer && {
                    key: customer._id,
                    label: customer.name,
                    __typename: 'SelectItem'
                },
                transporter: transporter && {
                    key: transporter._id,
                    label: transporter.name,
                    __typename: 'SelectItem'
                },
                employer: employer && [{
                    key: employer._id,
                    label: employer.name,
                    __typename: 'SelectItem'
                }]
            }
        })
        .then(() => this.setState({ isFinished: true }))
        .catch((error) => this.setState({ error }))
        .then(() => this.setState({ loading: false }))
    }

    /**
     * Check if the organization has changed.
     * @param prevData Previous organization data to be compared with new organization data
     * @param orgType Organization type to check that changed
     */
    orgChanged = (prevData, orgType) => {
        const currOrg = this.props.orgData[orgType];
        const currOrgID = currOrg && currOrg._id;
        const prevOrg = prevData[orgType];
        const prevOrgID = prevOrg && prevOrg._id;

        if (!currOrgID) return false;
        if (currOrgID !== prevOrgID) return true;
        return false
    }
    componentDidUpdate({ orgData: prevOrgData }){
        if (this.state.error) return
        const { orgData: { customer, transporter, employer } } = this.props;
        const changed = {
            customer: this.orgChanged(prevOrgData, 'customer'),
            transporter: this.orgChanged(prevOrgData, 'transporter'),
            employer: this.orgChanged(prevOrgData, 'employer')
        }

        // If anything changed, re-initialize global filters.
        if (changed.customer || changed.transporter || changed.employer){
            this.props.initialize({
                variables: {
                    customer: customer && {
                        key: customer._id,
                        label: customer.name,
                        __typename: 'SelectItem'
                    },
                    transporter: transporter && {
                        key: transporter._id,
                        label: transporter.name,
                        __typename: 'SelectItem'
                    },
                    employer: employer && [{
                        key: employer._id,
                        label: employer.name,
                        __typename: 'SelectItem'
                    }]
                }
            })
        }
    }
    render() { 
        const { children } = this.props;
        if (this.state.error) return (
            <div style={{ padding: '20rem', textAlign: 'center' }}>
                <h1 style={{ color: 'red' }} >An error occurred preventing Manifest Central from starting</h1>
                <h2>{this.state.error.message}</h2>
            </div>
        )
        if (this.state.isFinished) return children
        return null
    }
}
 
export default compose(
    WithOrgData,
    graphql(
        gql`
        mutation IntializeGlobalFilter(
            $customer: SelectItem
            $transporter: SelectItem
            $employer: [SelectItem]
        ){
            setGlobalFilter(
                customer: $customer
                transporter: $transporter
                employer: $employer
            ) @client{
                customer
                transporter
                employer
            }
        }
        `,
        {
            name: 'initialize'
        }
    )
)(GlobalFilterInitializer);