import React, { Component } from 'react';
import { Query } from 'react-apollo';
import CrewQuery from './CrewQuery';
import PropTypes from 'prop-types';
import { Alert, Spin } from 'antd';
import LoadingContent from '../../LoadingContent';
import { ISNManager } from '../ISNManager';

class CrewISN extends Component {
    render() {
        const { crewId, fetchPolicy, showISNCheck=true, ...restProps } = this.props;
        return (
            <Query
            query={CrewQuery}
            variables={{
                _id: crewId
            }}
            fetchPolicy={fetchPolicy || 'network-only'}
            pollInterval={10000}
            >
            {({ data, loading, error }) => {
                const crewMember = data && data.getBoatCrew;
                if (!crewMember && loading ) return <LoadingContent />
                return (
                    <Spin spinning={loading}>
                        {error ? (
                            <Alert type="error" showIcon message="Failed to load crew crewMember" />
                        ) : null}
                        {crewMember ? (
                            <ISNManager data={crewMember} {...restProps} showISNCheck={showISNCheck} />
                        ) : null}
                    </Spin>
                )
            }}
            </Query>
        );
    }
}

CrewISN.propTypes = {
    crewId: PropTypes.string.isRequired,
    fetchPolicy: PropTypes.string,
    showISNCheck: PropTypes.bool
}
 
export default CrewISN;