import { Button, Col, Collapse, Divider, Dropdown, Icon, List, Menu, message, Popconfirm, Row, Spin, Tooltip, Typography } from 'antd';
import { ApolloError, NetworkStatus } from 'apollo-boost';
import { DataProxy } from 'apollo-cache/lib/types/DataProxy';
import { getLabelInValueKey } from 'common/form';
import { castArray } from 'common/util';
import CognitoPhoneNumber from 'components/form/InputCognitoPhoneNumber/CognitoPhoneNumber';
import MDDetails, { MDDetailsProps } from 'components/masterdata-2-0/details';
import { UseMasterDataStateReturnNoQueryHookProps } from 'components/masterdata-2-0/hook';
import { MDStateToDetailsProps } from 'components/masterdata-2-0/util';
import gql from 'graphql-tag';
import useApplyAllContractsToUser from 'hooks/useApplyAllContractsToUser';
import React, { useReducer, useRef } from 'react';
import { useApolloClient, useMutation, useQuery } from 'react-apollo';
import UserContractManager from './userContractManager';
import UserEntryForm, { UserFormReducerState } from './userForm';
import UserGroupSelect from './userGroupSelect';

const QUERY_USER_DETAILS = gql`
query UserDetails($username: String!, $groupsLimit: Int, $groupsNextToken: String, $listGroupsLimit: Int, $listGroupsNextToken: String){
    AdminCognitoListUserGroups(username: $username, limit: $groupsLimit, nextToken: $groupsNextToken){
        groups {
            GroupName
            Description
        }
        nextToken
    }
    AdminCognitoListGroups(limit: $listGroupsLimit, nextToken: $listGroupsNextToken){
        groups {
            GroupName
            Description
        }
        nextToken
    }
    AdminCognitoGetUser(Username: $username) {
        Username
        approvalStatus
        mappedAttributes {
            email
            organization
            organizationName
        }
        userSettings {
            _id
            _rev
            contracts
            contractDocs {
                _id
                name
                tpID {
                    _id
                    name
                }
                customerID {
                    _id
                    name
                }
                locationID {
                    _id
                    name
                }
                startDate
                endDate
                active
            }
        }
    }
}
`

const MUTATION_UPDATE_USER_GROUPS = gql`
mutation UpdateUserGroups($username: String!, $addGroups: [String!], $removeGroups: [String!]){
    AdminCognitoUpdateUserGroups(username: $username, addGroups: $addGroups, removeGroups: $removeGroups)
}
`

const MUTATION_APPROVE_USER = gql`
mutation ApproveUser($username: String!){
    AdminCognitoApproveUser(Username: $username)
}
`

const MUTATION_REVOKE_APPROVAL = gql`
mutation RevokeApproval($username: String!){
    AdminCognitoRevokeApproval(Username: $username)
}
`

const MUTATION_DENY_USER = gql`
mutation DenyUser($username: String!){
    AdminCognitoDenyUser(Username: $username)
}
`

const MUTATION_UPDATE_USER_CONTRACTS = gql`
mutation UpdateUserContracts($username: String!, $email: String!, $contracts: [ID!]){
    AdminUpdateUserSettings(cognitoUsername: $username, email: $email, contracts: $contracts, createNew: false){
        _id
        _rev
        contracts
        contractDocs {
            _id
            name
            tpID {
                _id
                name
            }
            customerID {
                _id
                name
            }
            locationID {
                _id
                name
            }
            startDate
            endDate
            active
        }
    }
}
`

const contractFragment = gql`
    fragment contract on Contract {
        _id
        name
        active
        tpID {
            _id
            name
        }
        customerID {
            _id
            name
        }
        locationID {
            _id
            name
        }
        startDate
        endDate
    }
`

export interface UserMDDetailsProps extends MDDetailsProps {
    selectedUser?: any,
    MDState: UseMasterDataStateReturnNoQueryHookProps,
    onDisableUser?: () => void,
    disableUserLoading?: boolean,
    deleteUserLoading?: boolean,
    saveUserLoading?: boolean,
    onDeleteUser?: () => void,
    onUserGroupsChange?: (cache: DataProxy, userGroups: Array<string>) => void
}

interface ReducerState {
    nextToken?: string,
    groupSelectValues?: Array<string>,
    useCustomTempPass?: boolean,
    collapseActiveKeys?: string[],
    pswdVisibility?: boolean,

    /** Current state of the form. Cannot be modified by parent. */
    formState?: UserFormReducerState,
    userSaveError?: ApolloError
}

const INITIAL_STATE: ReducerState = {
    nextToken: null,
    groupSelectValues: [],
    useCustomTempPass: false,
    collapseActiveKeys: ['2', '3'],
    pswdVisibility: false,
    formState: null,
    userSaveError: null
}

const REDUCER_TYPES = {
    NextToken: 'NEXT_TOKEN',
    ClearToken: 'CLEAR_TOKEN',
    SetGroupSelect: 'SET_GROUP_SELECT',
    SetFormState: 'SET_FORM_STATE'
}

function reducer(state: ReducerState, action): ReducerState{
    switch(action.type){
        case REDUCER_TYPES.NextToken:
            return {...state, nextToken: action.value }
        case REDUCER_TYPES.ClearToken:
            return { ...state, nextToken: undefined }
        case REDUCER_TYPES.SetGroupSelect:
            return { ...state, groupSelectValues: action.value }
        case REDUCER_TYPES.SetFormState:
            return { ...state, formState: { ...state?.formState, ...action.value } }
        default:
            return state
    }
}

const UserMDDetails: React.FC<UserMDDetailsProps> = (props) => {
    const formRef = useRef(null);

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const client = useApolloClient();
    
    const { selectedUser, MDState, disableUserLoading, deleteUserLoading, saveUserLoading, extra=[] } = props;

    console.log(selectedUser?.mappedAttributes?.email, selectedUser);

    let queryVars = {
        username: selectedUser?.Username,
        groupsNextToken: state.nextToken
    }

    const { apply, canApply, isApplying } = useApplyAllContractsToUser(selectedUser?.Username);

    const { data, refetch, networkStatus } = useQuery(QUERY_USER_DETAILS, {
        variables: queryVars,
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        skip: !selectedUser,
        onError: () => message.error('Failed to load user details. Check console for details.')
    });

    console.log(data?.AdminCognitoGetUser)
    console.log('end query')

    const [ approveUser, { loading: approveUserLoading } ] = useMutation(MUTATION_APPROVE_USER, {
        onError: () => message.error('Failed to approve user. Check console for details.'),
        onCompleted: () => message.success('User approved successfully')
    })

    const [ revokeApproval, { loading: revokeApprovalLoading } ] = useMutation(MUTATION_REVOKE_APPROVAL, {
        onError: () => message.error('Failed to unapprove user. Check console for details.'),
        onCompleted: () => message.success('User unapproved successfully')
    })

    const [ denyUser, { loading: denyUserLoading } ] = useMutation(MUTATION_DENY_USER, {
        onError: () => message.error('Failed to deny user. Check console for details.'),
        onCompleted: () => message.success('User denied successfully')
    })

    const [ updateUserGroupsMut, { loading: updateUserGroupsLoading } ] = useMutation(MUTATION_UPDATE_USER_GROUPS, {
        onError: () => message.error('Failed to update user groups. Check console for details.'),
        onCompleted: () => message.success('User groups updated successfully')
    });

    const [ addUserContractsMut, { loading: isAddingContracts } ] = useMutation(MUTATION_UPDATE_USER_CONTRACTS, {
        onError: () => message.error('Failed to add user contracts. Check console for details.'),
        onCompleted: () => message.success('User contracts added successfully')
    });

    const [ removeUserContractsMut, { loading: isRemovingContracts } ] = useMutation(MUTATION_UPDATE_USER_CONTRACTS, {
        onError: () => message.error('Failed to update user contracts. Check console for details.'),
        onCompleted: () => message.success('User contracts updated successfully')
    });

    let user = data?.AdminCognitoGetUser;
    
    const groups = castArray(data?.AdminCognitoListUserGroups?.groups);
    const contractDocs = castArray(user?.userSettings.contractDocs);
    const contractIds = castArray(user?.userSettings.contracts)
        // Remove contract IDs that aren't in contractDocs. If a contractID is not in contractDocs is must've been deleted.
        .filter((id) => contractDocs.find(doc => doc._id === id) ? true : false)

    function reload(){
        dispatch({ type: 'CLEAR_TOKEN' })
        refetch();
    }

    function handleFormSubmit(){
        formRef.current.validateFieldsAndScroll()
        .then(values => {
            let mappedAttrs: any = {}
            if (selectedUser){
               mappedAttrs = {...selectedUser.mappedAttributes};
               mappedAttrs['custom:organization'] = mappedAttrs.organization;
               mappedAttrs['custom:company_phone_number'] = mappedAttrs.company_phone_number;
               delete mappedAttrs.organization;
               delete mappedAttrs.company_phone_number;
            }

            let attrs = {
                email: values.email,
                family_name: values.family_name,
                given_name: values.given_name,
                phone_number: CognitoPhoneNumber.clean(values.phone_number && values.phone_number.countryCode && values.phone_number.number ? '+' + values.phone_number.countryCode + values.phone_number.number : undefined),
                organization: getLabelInValueKey(values.organization),
                company_phone_number: CognitoPhoneNumber.clean(values.company_phone_number && values.company_phone_number.countryCode && values.company_phone_number.number ? '+' + values.company_phone_number.countryCode + values.company_phone_number.number : undefined),
                email_verified: 'true',
                phone_number_verified: 'true'
            }
            let localAttrs = {
                ...selectedUser?.mappedAttributes,
                ...attrs,
                __typename: 'DFCognitoUserMappedAtrributes',
                organizationName: values.organization?.label
            }

            attrs['custom:organization'] = attrs.organization;
            attrs['custom:company_phone_number'] = attrs.company_phone_number;
            attrs['custom:org_id'] = '1'
            delete attrs.organization
            delete attrs.company_phone_number

            let deletedAttrs = [];
            Object.entries(attrs).forEach(([k, v]) => {
                if (k in mappedAttrs){

                    // Add field to deletedAttrs
                    if (mappedAttrs[k] && !v){
                        deletedAttrs.push(k);
                        delete attrs[k];
                    }

                    // If the value did not change, do not add the entry to the attrs object
                    else if (mappedAttrs[k] === v){
                        delete attrs[k];
                    }
                }
            })
            MDState.save({
                attrs,
                localAttrs,
                deletedAttrs,
                tempPassword: MDState.isNewEntry && state.formState?.useCustomTempPass ? values.tempPassword : undefined,
                desiredDeliveryMediums: MDState.isNewEntry ? values.preferredDeliveryMediums : undefined
            }, () => localAttrs)
            .then(() => {

                let newMappedAttrs = {
                    ...localAttrs,
                    organization: getLabelInValueKey(values.organization),
                    organizationName: values.organization.label
                }

                client.writeQuery({
                    query: QUERY_USER_DETAILS,
                    variables: queryVars,
                    data: {
                        ...data,
                        AdminCognitoGetUser: {
                            ...data.AdminCognitoGetUser,
                            mappedAttributes: newMappedAttrs
                        }
                    }
                })
            });
        })
    }

    function renderActions(){
        let overlay = <Menu>
            <Menu.Item onClick={() => props.onDeleteUser?.()}>Delete Account</Menu.Item>
        </Menu>
        return <Dropdown overlay={overlay}>
            <Button>Actions <Icon type="down" /></Button>
        </Dropdown>
    }

    function addGroups(groups: Array<string>){
        updateUserGroupsMut({
            variables: {
                username: selectedUser?.Username,
                addGroups: groups
            },
            update: (cache) => {
                let newGroups = [
                    ...castArray(data?.AdminCognitoListUserGroups?.groups),
                    ...groups.map((group) => ({
                        __typename: 'CognitoUserGroup',
                        GroupName: group,
                        Description: castArray(data?.AdminCognitoListGroups?.groups)
                            .find(g => g.GroupName === group)?.Description
                    }))
                    .sort((a, b) => String(a.GroupName).localeCompare(b.GroupName))
                ]
                cache.writeQuery({
                    query: QUERY_USER_DETAILS,
                    variables: {
                        username: selectedUser?.Username,
                        groupsNextToken: state.nextToken
                    },
                    data: {
                        ...data,
                        AdminCognitoListUserGroups: {
                            ...data.AdminCognitoListUserGroups,
                            groups: newGroups
                        }
                    }
                })
                props.onUserGroupsChange?.(cache, newGroups);
            }
        })
        .then(() => dispatch({ type: 'SET_GROUP_SELECT', value: [] }))
    }

    function removeGroups(rGroups: string[]){
        updateUserGroupsMut({
            variables: {
                username: selectedUser?.Username,
                removeGroups: rGroups
            },
            optimisticResponse: {
                __typename: 'Mutation',
                AdminCognitoUpdateUserGroups: JSON.stringify({
                    removed: rGroups.map(group => {
                        return {
                            GroupName: group,
                            ok: true
                        }
                    })
                })
            },
            update: (cache) => {
                let newGroups = groups.filter((g) => rGroups.findIndex(rg => g.GroupName === rg) < 0);
                cache.writeQuery({
                    query: QUERY_USER_DETAILS,
                    variables: {
                        username: selectedUser?.Username,
                        groupsNextToken: state.nextToken
                    },
                    data: {
                        ...data,
                        AdminCognitoListUserGroups: {
                            ...data.AdminCognitoListUserGroups,
                            groups: newGroups
                        }
                    }
                });
                props.onUserGroupsChange?.(cache, newGroups);
            }
        })
    }

    function addUserContractsMutQueryCache(ids: string[]){
        let newContractDocs = ids.map((id) => client.readFragment({
            fragment: contractFragment,
            id: 'Contract:' + id
        }))

        let uniqueCDocs = new Map();

        const setCon = con => uniqueCDocs.set(con._id, con);

        contractDocs.forEach(setCon)
        newContractDocs.forEach(setCon);

        let mergedIDs = Array.from(uniqueCDocs.keys());
        let mergedDocs = Array.from(uniqueCDocs.values());

        let newUserSettings = {
            ...data.AdminCognitoGetUser.userSettings,
            contracts: mergedIDs,
            contractDocs: mergedDocs
        }
        return addUserContractsMut({
            variables: {
                username: selectedUser?.Username,
                email: selectedUser?.mappedAttributes.email,
                contracts: newUserSettings.contracts
            },
            optimisticResponse: {
                __typename: 'Mutation',
                AdminUpdateUserSettings: {
                    __typename: 'WebUserSettings',
                    ...newUserSettings
                }
            },
            update: (cache) => {
                let queryData = {
                    ...data,
                    AdminCognitoGetUser: {
                        ...data.AdminCognitoGetUser,
                        userSettings: newUserSettings
                    }
                }
                cache.writeQuery({
                    query: QUERY_USER_DETAILS,
                    variables: queryVars,
                    data: queryData
                })
            }
        })
    }

    function handleRemoveContracts(ctIds){
        let newUserSettings = {
            ...data.AdminCognitoGetUser.userSettings,
            contracts: contractIds.filter((idStr) => !ctIds.includes(idStr)),
            contractDocs: contractDocs.filter(doc => !ctIds.includes(doc._id))
        }
        return removeUserContractsMut({
            variables: {
                username: selectedUser?.Username,
                email: selectedUser?.mappedAttributes.email,
                contracts: newUserSettings.contracts
            },
            update: (cache) => {
                let queryData = {
                    ...data,
                    AdminCognitoGetUser: {
                        ...data.AdminCognitoGetUser,
                        userSettings: newUserSettings
                    }
                }
                cache.writeQuery({
                    query: QUERY_USER_DETAILS,
                    variables: queryVars,
                    data: queryData
                })
            }
        })
    }

    return <MDDetails
        {...MDStateToDetailsProps(MDState, (entryValues) => {
            if (entryValues){
                let name = `${entryValues.given_name} ${entryValues.family_name}`;
                if (selectedUser?.Enabled){

                    let approvalStatus = null;

                    if (user?.approvalStatus === 'awaiting_approval'){
                        approvalStatus = <Typography.Text type='danger'>AWAITING APPROVAL</Typography.Text>
                    }
                    else if (user?.approvalStatus === 'denied'){
                        approvalStatus = <Typography.Text type='danger'>DENIED</Typography.Text>
                    }
                    else if (user?.approvalStatus === 'approved'){
                        approvalStatus = <span style={{ color: 'limegreen' }}>APPROVED</span>
                    }

                    return <span>{name} <span style={{ marginLeft: '6px', fontSize: '1.1rem' }}>{approvalStatus}</span></span>
                }
                else
                {
                    return <Tooltip placement="bottom" title="User is disabled">
                        <Typography.Text type="secondary">{name}</Typography.Text>
                    </Tooltip>
                }
            }
            return null
        })}
        saving={saveUserLoading}
        contentWidth="45rem"
        hideSaveBtn={MDState.isNewEntry ? false : true}
        saveBtnText={MDState.isNewEntry ? 'Create User' : undefined}
        onSave={() => {
            handleFormSubmit();
        }}
        showReset={MDState.isNewEntry ? true : false}
        extra={[
            ...(props.MDState.isNewEntry ? [] : [
                <Popconfirm
                    title={selectedUser?.Enabled ? 'Are you sure you want to disable this account?' : 'Are you sure you want to re-enable this account?'}
                    okType="danger"
                    okText={selectedUser?.Enabled ? 'Disable' : 'Enable'}
                    onConfirm={() => props.onDisableUser?.()}
                >
                    <Button type="link" style={{ padding: 0 }} loading={disableUserLoading} >
                        {selectedUser?.Enabled ? 'Disable Account' : 'Enable Account'}
                    </Button>
                </Popconfirm>,
                renderActions(),
                <Button
                    icon={networkStatus < 6 && networkStatus !== NetworkStatus.fetchMore ? 'loading' : 'reload'}
                    onClick={() => reload()}
                />,
            ]),
            ...extra
        ]}
    >
        <Spin spinning={deleteUserLoading} indicator={<Icon type="loading" />} tip="Deleting user...">
            <UserEntryForm
                ref={formRef}
                MDState={MDState}
                formFields={MDState.entryFields}
                onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                autoFocus
                onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmit();
                }}
                onStateChange={(state) => dispatch({ type: REDUCER_TYPES.SetFormState, value: state })}
                saveUserLoading={saveUserLoading}
                collapsePanelChildren={
                    [
                    !props.MDState.isNewEntry ? (
                        <Collapse.Panel key="ct" header="Contracts">
                            <UserContractManager
                                username={selectedUser?.Username}
                                contractData={contractDocs}
                                loading={networkStatus === NetworkStatus.loading}
                                isAdding={isAddingContracts}
                                isRemoving={isRemovingContracts}
                                extraHeaderComponents={[
                                    <Tooltip
                                        title={<div>
                                            <p>Add all of {data?.AdminCognitoGetUser?.mappedAttributes?.organizationName}'s contracts to this user.</p>
                                            <p><strong>NOTE: </strong> If you changed this user's organization above, <Typography.Text type='warning'>click the save button first before clicking this!</Typography.Text></p>
                                        </div>}
                                    >
                                        <Button
                                            type='primary'
                                            disabled={!canApply || isAddingContracts || isRemovingContracts}
                                            loading={isApplying}
                                            onClick={() => apply()
                                                .then(addUserContractsMutQueryCache)
                                                .catch((err) => {
                                                    if (String(err.message).includes('No unassigned contracts')){
                                                        message.info(err.message);
                                                    }
                                                    else if (!String(err.message).includes('cancelled')){
                                                        message.error("Failed to apply all customer contracts due to an error: " + err.message);
                                                    }
                                                })}
                                        >
                                            Add Cust. Contracts
                                        </Button>
                                    </Tooltip>
                                ]}
                                onAddContracts={addUserContractsMutQueryCache}
                                onRemoveContracts={handleRemoveContracts}
                            />
                        </Collapse.Panel>
                    ) : null,
                    !props.MDState.isNewEntry ? (
                        <Collapse.Panel key="ug" header="User groups" extra={[
                            user?.approvalStatus === 'awaiting_approval' ? <>
                                <Tooltip title="Denies user approval.">
                                    <Popconfirm
                                        title="Are you sure you want to deny this user?"
                                        onConfirm={(e) => {
                                            e.stopPropagation();
                                            denyUser({
                                                variables: { username: selectedUser.Username }
                                            })
                                            .then(() => refetch())
                                        }}
                                        onCancel={e => e.stopPropagation()}
                                    >
                                        <Button
                                            onClick={e => e.stopPropagation()}
                                            type="danger"
                                            disabled={denyUserLoading || approveUserLoading}
                                            size="small"
                                            style={{ marginRight: '6px' }}
                                        >Deny User</Button>
                                    </Popconfirm>
                                </Tooltip>
                                <Tooltip title="Sets user as approved and adds flytsuite.default and flytsuite.flytwatch.view permission to user (Allows access to Manifest Central and FlytWatch)">
                                    <Button
                                        type="primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            approveUser({
                                                variables: { username: selectedUser.Username }
                                            })
                                            .then(() => refetch())
                                        }}
                                        disabled={approveUserLoading || denyUserLoading}
                                        size="small"
                                    >Approve User</Button>
                                </Tooltip>
                            </> : (
                                <Tooltip title="Revoke flytsuite.default and flytsuite.flytwatch.view permission from user (Disables access to Manifest Central and FlytWatch)">
                                    <Button
                                        type="link"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            revokeApproval({
                                                variables: { username: selectedUser.Username }
                                            })
                                            .then(() => refetch())
                                        }}
                                        disabled={revokeApprovalLoading}
                                        size="small"
                                    >{user?.approvalStatus === 'approved' ? 'Disapprove User' : 'Un-deny user'}</Button>
                                </Tooltip>
                            )
                        ]}>
                            <Row type="flex" gutter={12}>
                                <Col style={{ flex: 1 }}>
                                    <UserGroupSelect.NoQuery
                                        data={castArray(data?.AdminCognitoListGroups?.groups)}
                                        loading={networkStatus === NetworkStatus.loading}
                                        mode="multiple"
                                        placeholder="Select group"
                                        filterItem={(item) => {
                                            // Filter out user groups that are already applied
                                            return groups.findIndex((g) => g.GroupName === item.GroupName) === -1
                                        }}
                                        onChange={(value) => dispatch({ type: 'SET_GROUP_SELECT', value })}
                                        value={state.groupSelectValues}
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                                <Col>
                                    <Tooltip title="Add groups to user">
                                        <Button type="primary" icon="plus" onClick={() => addGroups(state.groupSelectValues)} disabled={updateUserGroupsLoading || state.groupSelectValues.length <= 0} />
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Divider type="horizontal" style={{ margin: '12px 0 0 0' }} />
                            <List
                                loading={networkStatus === NetworkStatus.loading}
                                dataSource={groups}
                                size="small"
                                renderItem={(item) => {
                                    return <List.Item
                                        actions={[
                                            <Tooltip title="Remove group">
                                                <Button type="link" icon="minus" onClick={() => removeGroups([item.GroupName])} />
                                            </Tooltip>
                                        ]}
                                    >
                                        <List.Item.Meta
                                            key={item.GroupName}
                                            title={item.GroupName}
                                            description={item.Description}
                                        />
                                    </List.Item>
                            }} />
                        </Collapse.Panel>
                    ) : null
                    ]
                }
            />
        </Spin>
    </MDDetails>
}

export default UserMDDetails;