import { Dispatch, SetStateAction, useState } from 'react';

export default function useControlledState<S>(initialState: S, controlState?: S, onSetState?: (value: S) => void, allowSetStateWhileControlled: boolean=false): [S, Dispatch<SetStateAction<S>>]{
    const [ state, setState ] = useState<S>(initialState);
    // useEffect(() => {
    //     if (controlState !== undefined){
    //         setState(controlState);
    //     }
    // }, [controlState])
    return [
        controlState !== undefined ? controlState : state,
        (value: S) => {
            if (onSetState){
                onSetState(value);
            }
            else if (controlState === undefined || allowSetStateWhileControlled){
                setState(value);
            }
        }
    ]
}