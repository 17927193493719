import { DatePicker, TimePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { TimePickerProps } from 'antd/lib/time-picker';
import { momentOrNull } from 'common/util';
import moment, { Moment } from 'moment';
import React, { HTMLAttributes } from 'react';
import './index.less';
import cn from 'classnames';

const BASE_CLS = "mc-date-time-picker"

// export interface DateTimePickerValue {
//     date: Moment,
//     time: Moment
// }

export class DateTimePickerValue {
    date: Moment
    time: Moment

    constructor(date: string | Moment, time: string | Moment){
        this.date = momentOrNull(date);
        this.time = momentOrNull(time);
    }

    valueOf(){
        return this.toString();
    }

    toMoment(){
        let date = this.date;
        date.set({
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        })

        let time = this.time;

        let t = moment(date);
        t.hour(time.hour());
        t.minute(time.minute());
        t.second(time.second());
        t.millisecond(time.millisecond());
        
        let timeDuration = t.diff(date);

        let dateTime = moment(date);
        dateTime.add(timeDuration);
        return dateTime;
    }

    toString(){
        return this.toMoment().toISOString(false);
    }

    static from(dt: string | any): DateTimePickerValue {
        let date: Moment, time: Moment;
        if (dt && typeof dt === 'object' && dt.date && dt.time){
            date = dt.date;
            time = dt.time;
        }
        else
        {
            let m = momentOrNull(dt);
            if (!m) return new DateTimePickerValue(null, null);
            date = moment(m);
            time = moment(m);
        }


        date.set({
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        })

        time.set({
            date: moment().date()
        })

        return new DateTimePickerValue(date, time);
    }
}

export interface DateTimePickerProps {
    value?: DateTimePickerValue,
    onChange?: (value: DateTimePickerValue) => void,
    datePickerProps?: DatePickerProps,
    timePickerProps?: TimePickerProps,
    showLabels?: boolean,
    dateLabelText?: string | JSX.Element,
    dateLabelProps?: HTMLAttributes<HTMLSpanElement>
    timeLabelText?: string | JSX.Element,
    timeLabelProps?: HTMLAttributes<HTMLSpanElement>
    style?: React.CSSProperties,
    size?: 'small' | 'default' | 'large'
}

const DateTimePicker: React.FC<DateTimePickerProps> = props => {
    let dateVal = momentOrNull(props.value?.date);
    let timeVal = momentOrNull(props.value?.time);

    // function handleChange(value: string){
    //     let momVal = momentOrNull(value);
    //     if (momVal){
    //         props.onChange?.(momVal)
    //     }
    //     else
    //     {
    //         props.onChange?.(null)
    //     }
    // }

    let getLabel = (props: any, text: any, defaultText: string) => <span className={cn(BASE_CLS + "-label", props.className)} {...props}>{text || defaultText}</span>

    return <span style={props.style}>
        {props.showLabels ? getLabel(props.dateLabelProps, props.dateLabelText, 'Date:') : null}
        <span>
            <DatePicker
                size={props.size || "small"}
                style={{ marginRight: '6px', width: 110 }}
                value={dateVal}
                format="MM/DD/YYYY"
                allowClear={false}
                onChange={(date) => {
                    props.onChange?.(new DateTimePickerValue(date, timeVal))
                }}
                placeholder=""
                {...props.datePickerProps}
            />
        </span>
        {props.showLabels ? getLabel(props.timeLabelProps, props.timeLabelText, 'Time:') : null}
        <span>
            <TimePicker
                size={props.size || "small"}
                value={timeVal}
                format="HH:mm"
                allowClear={false}
                style={{ width: 70 }}
                onChange={(time) => {
                    props.onChange?.(new DateTimePickerValue(dateVal, time))
                }}
                placeholder=""
                {...props.timePickerProps}
            />
        </span>
    </span>
}

export function strToDateTimePickerValue(dt: string | any): DateTimePickerValue {
    if (dt instanceof DateTimePickerValue){
        return dt;
    }
    return DateTimePickerValue.from(dt);
}

DateTimePicker.defaultProps = {
    showLabels: false
}

export default DateTimePicker