import React from 'react';
import { Form, Input } from 'antd';
import { formItemLayout } from '../../../common/form';
import AircraftSelect from '../../form/AircraftSelect';
import { FormComponentProps } from 'antd/lib/form';

export interface PrintoutFormProps extends FormComponentProps {
    onChange(changedFields: any): void,
    flightName: any,
    aircraft: any
}

const PrintoutForm = ({ form }) => {
    const { getFieldDecorator } = form;
    return (
        <Form>
        <Form.Item label="Flight Name" { ...formItemLayout }>    
            {getFieldDecorator('flightName', {
                rules: [
                    {required: true, message: 'Flight Name is required'}
                ]
            })(<Input />)}
        </Form.Item>
        <Form.Item label="Aircraft" { ...formItemLayout }>
            {getFieldDecorator('aircraft', {
                rules: [
                    {required: true, message: 'Aircraft is required'}
                ]
            })(<AircraftSelect labelInValue />)}
        </Form.Item>
        </Form>
    )
}

export default Form.create<PrintoutFormProps>({
    onFieldsChange(props, changedFields) {
        props.onChange && props.onChange(changedFields)
    },
    mapPropsToFields(props) {
        const flightName = props.flightName || {};
        const aircraft = props.aircraft || {};
        return {
            flightName: Form.createFormField({
                ...flightName,
                value: flightName.value
            }),
            aircraft: Form.createFormField({
                ...aircraft,
                value: aircraft.value
            })
        }
    }
})(PrintoutForm)