import { Cell, Column, RenderMode, SelectionModes, Table } from '@blueprintjs/table';
import { Alert, Button, Col, Icon, Input, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import { useColWidths } from 'common/table';
import { PersonColumnSet } from 'common/table/blueprintcolumns';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import NonIdealState from 'components/NonIdealState';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import gql from 'graphql-tag';
import useControlledState from 'hooks/useControlledState';
import { useMultiSelectByID } from 'hooks/useMultiSelectByID';
import { compact } from 'lodash';
import React from 'react';
import { useQuery } from 'react-apollo';
import { LoadingIndicator, shouldRenderLoadingIndicator } from '..';
import { SearchProps } from '../interface';
import '../search.less';
import SelectionCount from '../SelectionCount';

const PERSON_SEARCH_BY_CRED_QUERY = gql`
query PersonSearchByCred(
    $search: String!
) {
    getPersonsByCred(
        search: $search
    ){
        _id
        lastName
        firstName
        extraBroadState
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        }
        dob
        nation
        contactInfo {
            phone {
                number
                type
            }
        }
    }
}
`

export interface CredSearchState {
    credKey?: string,
    offset: number
}

export const initialSearchCredState: CredSearchState = {
    credKey: null,
    offset: 0
}

export interface PersonSearchByCredProps extends SearchProps, WithOrgDataProps, React.HTMLAttributes<HTMLDivElement> {
    filterResults?(rows: any[]): any[],
    showCustomerColumn?: boolean,
    showEmployerColumn?: boolean,

}

const PersonSearchByCred: React.FC<PersonSearchByCredProps> = ({
    pageLimit=50,
    onSelection,
    filterOptions,
    selectedItems: selectedItemsProps,
    orgData,
    showSelectedItemsCount=true,
    searchState: searchStateProp,
    onSearchStateChange,
    excludeCarrier,
    showCustomerColumn=true,
    showEmployerColumn=true,
    filterResults,
    ...restProps
}) => {
    const [ searchState, setSearchState ] = useControlledState<CredSearchState>(initialSearchCredState, searchStateProp, onSearchStateChange);
    const { isSelected, toggleSelection, selectedItems, clearAll } = useMultiSelectByID({ selectedItems: selectedItemsProps, onSelection });
  
    let colCount = 2;

    if (showCustomerColumn) colCount++;
    if (showEmployerColumn) colCount++;

    const inputDirty = (
        searchState.credKey
    ) ? true : false;

    let variables = {
        search: searchState.credKey
    }

    const queryResult = useQuery(PERSON_SEARCH_BY_CRED_QUERY, {
        variables,
        fetchPolicy: 'cache-and-network',
        context: {
            debounceKey: 'getPersonsByCred',
            debounceTimeout: 1000
        }
    })

    function handleRefresh(){
        setSearchState({ ...searchState, offset: 0 });
        queryResult.refetch()
    }

    function handleInputValueChange(key: string, value: string) {
        setSearchState({ ...searchState, [key]: value })
    }

    function handleInputPressEnter(key: string, value: string) {
        setSearchState({ ...searchState, [key]: value })
    }

    const [ colWidths, , updateColWidth ] = useColWidths(colCount, { 0: 40 }, 200);

    function renderTable(){
        const { data, error, networkStatus, loading} = queryResult;
        console.log(queryResult)
        if (loading || shouldRenderLoadingIndicator(networkStatus)){
            return <LoadingIndicator />
        }

        if (!inputDirty || (!data && networkStatus > 3 && !error)){
            return <NonIdealState icon="search" title="Search for personnel by ID" />
        }

        if (error) return <Alert
            banner
            type="error"
            showIcon
            message="Failed to search personnel"
            description={error.message}
        />

        let rows: any[] = data.getPersonsByCred

        if (!Array.isArray(rows)){
            rows = [];
        }
        if (!rows.length){
            return <NonIdealState icon="question" title="No Results" />
        }

        const ColumnSet = new PersonColumnSet(rows);
        let colNames = ['personName'];
        if (showEmployerColumn){
            colNames.push('employerName');
        }
        if (showCustomerColumn){
            colNames.push('customerName');
        }
        let columns = [
            <Column key="select" name="" cellRenderer={idx => {
                const record = rows[idx];
                if(record){
                    let checked = isSelected ? isSelected(record._id) : false
                    console.log('checked: ', checked);
                    return <Cell interactive>
                        <Checkbox
                            checked={checked}
                            onClick={() => {
                                if (toggleSelection){
                                    toggleSelection(record._id);
                                }
                            }}
                        />
                    </Cell>
                }
                return <Cell/>
            }} />,
            ...ColumnSet.some(colNames)
        ]
        
        return <BlueprintTableInfiniteScroller
            hasMore={rows.length >= pageLimit + searchState.offset}
        >
            <Table
                className="mc-table"
                numRows={rows.length}
                selectionModes={SelectionModes.NONE}
                renderMode={RenderMode.NONE}
                columnWidths={colWidths}
                onColumnWidthChanged={updateColWidth}
                numFrozenColumns={1}
                selectedRegions={compact(rows.map((row, idx) => {
                    if (isSelected(row._id)){
                        return {
                            rows: [ idx, idx ]
                        }
                    }
                    return null
                }))}
            >
                {columns}
            </Table>
        </BlueprintTableInfiniteScroller>
    }

    return <div className="search-wrapper" {...restProps}>
        <div className="search-header">
            <Row type="flex">
                <Col style={{ flex: 1 }} className="search-header-col">
                    <div className="search-header-input" style={{ minWidth: '22rem' }}>
                        <Input.Group className="search-header-input-group">
                            <Input
                                placeholder="ID Number"
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    handleInputValueChange('credKey', e.target.value)
                                }
                                }
                                onPressEnter={(e) => handleInputPressEnter('credKey', e.currentTarget.value)}
                                value={searchState.credKey}
                            />
                        </Input.Group>
                    </div>
                </Col>
                <Col className="search-header-col">
                    <Button onClick={handleRefresh}>{<Icon type="sync" spin={queryResult.networkStatus <= 4} />}</Button>
                </Col>
            </Row>
            <Row type="flex">
                <Col style={{ flex: 1, paddingTop: 0 }} className="search-header-col">
                    <Button className="mc-link-btn" onClick={(e) => {
                        e.preventDefault();
                        setSearchState({
                            ...searchState,
                            credKey: null
                        });
                    }}>Clear</Button>
                </Col>
                {selectedItems.length > 0 ? (
                    <Col className="search-header-col" style={{ paddingTop: 0 }}>
                        <SelectionCount items={selectedItems} onClear={() => clearAll()} />
                    </Col>
                ) : null}
            </Row>
        </div>
        <div className="search-body">
            {renderTable()}
        </div>
    </div>
}

export default WithOrgData(PersonSearchByCred)