import React from 'react';
import { Input, Select, Tooltip, Button } from 'antd';



const InputEmail = (props) => {
    let values = props.value || {};
    if (values === null){
        values = {};
    }
    const handleChange = (key) => (e) => {
        let val;
        if (e.target){
            val = e.target.value;
        }
        else
        {
            val = e;
        }
        if (typeof props.onChange === 'function'){
            props.onChange({
                type: values.type,
                address: values.address,
                [key]: val
            });
        }
    }
    const clear = () => {
        if (typeof props.onChange !== 'function') return;
        props.onChange({
            type: undefined,
            address: undefined
        })
    }
    return <Input.Group compact>
        <Select defaultValue="PERSONAL" value={values.type} style={{ minWidth: '5rem' }} onChange={handleChange('type')} showAction={["focus", "click"]}>
            <Select.Option key="PERSONAL">Personal</Select.Option>
            <Select.Option key="WORK">Work</Select.Option>
            <Select.Option key="OTHER">Other</Select.Option>
        </Select>
        <Input type="email" value={values.address} onChange={handleChange('address')} style={{ width: '12rem' }} />
        {values && (values.type || values.address) ? (
            <Tooltip title="Clear">
                <Button onClick={clear} icon="close" />
            </Tooltip>
        ) : null}
    </Input.Group>
}

InputEmail.validator = (rules, value, callback) => {
    if (value && (value.address || value.type)){
        if (!value.address){
            callback('You must enter an email address');
        }
        else if (!value.type){
            callback('You must choose an email type');
        }
        return;
    }
    callback();
}

export default InputEmail