import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import ISNResultQuery from '../../../Queries/ISNResultQuery';
import { QCResult } from '../QCResult';
import SetPaxSelectedISNResult from '../../../Mutations/SetPaxSelectedISNResult';
import { message, Alert, Spin, Button, Divider } from 'antd';
import LoadingContent from '../../LoadingContent';
import ISNCheck from '../../../Mutations/ISNCheck';
import WithOrgData from '../../WithOrgData';

class QCResultWithData extends Component {
    render() {
        const { paxId, fetchPolicy, showISNCheck=true, orgData, ...restProps } = this.props;
        return (
            <Query
                query={ISNResultQuery}
                variables={{
                    _id: paxId
                }}
                fetchPolicy={fetchPolicy || 'network-only'}
                errorPolicy="all"
            >
            {({ data, loading, error }) => {
                const passenger = data && data.getPassenger;
                if (!passenger && loading ) return <LoadingContent />
                const isnData = passenger && passenger.lastIsnResultWithHeader;
                const selectedResult = isnData?.selectedResult;
                if (selectedResult?.reason === 'WHITELISTED'){
                    return 'Whitelisted';
                }
                const renderQCResult = () => (
                    <Spin spinning={loading}>
                        {error ? (
                            <Alert type="error" showIcon message="Failed to load ISN Data" />
                        ) : null}
                        <Mutation
                            mutation={SetPaxSelectedISNResult}
                        >
                        {
                            (setActive) => (
                                <QCResult
                                    data={isnData}
                                    onResultActivated={(data) => {
                                        const payload = {
                                            allowed: data.allowed,
                                            assignedActivities: data.assignedActivities,
                                            companyID: data.companyID,
                                            companyName: data.companyName,
                                            dataAsOf: data.dataAsOf,
                                            errorMessage: data.errorMessage,
                                            firstName: data.firstName,
                                            isnId: data.isnId,
                                            lastName: data.lastName,
                                            mostRecentTrainingExpirationDate: data.mostRecentTrainingExpirationDate,
                                            mostRecentTrainingQualificationExpirationName: data.mostRecentTrainingQualificationExpirationName,
                                            quickCheckStatus: data.quickCheckStatus,
                                            reason: data.reason,
                                            trainingStatus: data.trainingStatus
                                        }
                                        setActive({
                                            variables: {
                                                _id: passenger._id,
                                                _rev: passenger._rev,
                                                payload: JSON.stringify(payload)
                                            },
                                            optimisticResponse: {
                                                __typename: 'Mutation',
                                                setIsnSelection: {
                                                    __typename: 'PaxNode',
                                                    _id: passenger._id,
                                                    lastIsnResultWithHeader: {
                                                        __typename: 'IsnResultWithHeader',
                                                        selectedResult: {
                                                            __typename: 'IsnQcResult',
                                                            ...payload
                                                        }
                                                    }
                                                }
                                            }
                                        })
                                        .then(() => message.success('Successfully updated selected ISN result'))
                                        .catch(() => message.error('Failed to update selected ISN result'))
                                    }}
                                    {...restProps}
                                />
                            )
                        }
                        </Mutation>
                    </Spin>
                )
                if (showISNCheck){
                    return (
                        <Mutation
                        mutation={ISNCheck}
                        variables={{
                            _id: paxId,
                            tpID: orgData.transporter._id
                        }}
                        >
                        {(runISNCheck, { loading, error }) => (
                            <div>
                                <div>
                                    {runISNCheck ? (
                                        <div style={{ marginBottom: 12 }}>
                                            <Button
                                                loading={loading}
                                                onClick={() => runISNCheck()}
                                            >Run ISN Check</Button>
                                        </div>
                                    ) : null}
                                    {!loading && error ? (
                                        <Alert 
                                            type="error"
                                            showIcon
                                            message="ISN Check failed"
                                            style={{ marginBottom: 12 }}
                                            closable
                                            description={
                                                typeof error.message === 'string' &&
                                                error.message.includes('No credentials') ?
                                                    'No ISN credential for this person has been found' : 
                                                    'Unknown Error'
                                                }
                                        />
                                    ) : null}
                                    <Divider style={{ margin: '12px 0' }} />
                                </div>
                                {renderQCResult()}
                            </div>
                            
                        )}
                        </Mutation>
                    )
                }
                return renderQCResult();
            }}
            </Query>
        );
    }
}

QCResultWithData.propTypes = {
    paxId: PropTypes.string.isRequired,
    fetchPolicy: PropTypes.string,
    showISNCheck: PropTypes.bool
}
 
export default WithOrgData(QCResultWithData);