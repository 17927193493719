import React from 'react';
import { Query, QueryResult } from 'react-apollo';
import gql from 'graphql-tag';
import { message } from 'antd';
import {getDisplayName, safeGet} from "../common/util";
import {OrganizationFragment} from "../Queries/Organization";

export type OrgTypes = 'employer' | 'transporter' | 'customer';

const query = gql`
    query GetOrgData {
        customer @client {
            ...OrganizationFragment
        }
        transporter @client {
            ...OrganizationFragment
        }
        employer @client {
            ...OrganizationFragment
        }
        organization @client {
            ...OrganizationFragment
        }
        tpList @client{
            _id
            name
        }
    }
    ${OrganizationFragment}
`

export interface OrgDataProviderContext {
    customer: any,
    customerID: string,
    customerName: string,
    transporter: any,
    transporterID: any,
    employer: any,
    employerID: string,
    employerName: string,
    organization: any,

    /**
     * List of transporters contracted with the current customer.
     */
    tpList: any[],

    /**
     * Get organization object by type of organization.
     * If no type is provided the plain organization is returned.
     * @param type Org type.
     */
    getOrgByType: (type?: OrgTypes) => any,

    /**
         * Get organization ID by type of organization.
         * If no type is provided the plain organization ID is returned.
         * @param type Org type.
         */
    getOrgIDByType: (type?: OrgTypes) => any,

    /**
     * Get the active organization if available.
     */
    getActiveOrg: () => any,

    /**
     * Get the active organization ID if available.
     */
    getActiveOrgID: () => any,
}

export const OrgDataContext: React.Context<OrgDataProviderContext> = React.createContext(null)

/**
 * Provides global access to organization data.
 */
export const OrgDataProvider = ({ children }) => {
    return <Query query={query}>
    {({ data, loading, error }: QueryResult) => {
        if (error){
            message.error('Failed to get organization data', 3)
            .then(() => message.error(error.message, 5), null)
        }
        if (loading || error) return null

        let getActiveOrg = () => {
            switch (safeGet(['organization', 'classType'], data)) {
                case 'flytsuite.employer':
                    return data.employer;
                case 'flytsuite.transporter':
                case 'flytsuite.customer':
                    return data.transporter;
                default:
                    return data.organization;
            }
        }

        const getOrgByType = (type?: OrgTypes) => {
            switch (type) {
                case 'employer':
                    return data?.employer
                case 'transporter':
                    return data?.transporter
                case 'customer':
                    return data?.customer
                default:
                    return data?.organization;
            }
        }

        return <OrgDataContext.Provider value={{
            employer: data?.employer,
            employerID: data?.employer?._id,
            employerName: data?.employer?.name,
            customer: data?.customer,
            customerID: data?.customer?._id,
            customerName: data?.customer?.name,
            transporter: data?.transporter,
            transporterID: data?.transporter?._id,
            organization: data?.organization,
            tpList: data?.tpList || [],
            getOrgByType,
            getOrgIDByType: (type: OrgTypes) => {
                let def:OrgTypes = 'transporter';
                let org = getOrgByType(type ? type : def);
                return org && org._id
            },
            getActiveOrg: () => {
                const activeOrg = getActiveOrg();
                return activeOrg;
            },
            getActiveOrgID: () => {
                const activeOrg = getActiveOrg();
                return activeOrg?._id;
            }
        }}>
            {children}
        </OrgDataContext.Provider>
    }}
    </Query>
}

/**
 * Provides global access to organization data.
 */
export const withOrgDataProvider = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    return (props: P) => {
        return class extends React.Component {
            static displayName = `withOrgDataprovider(${getDisplayName(WrappedComponent)})`
            render(){
                return <OrgDataProvider>
                    <WrappedComponent {...props} />
                </OrgDataProvider>
            }
        }
    }
}