import React from 'react';
import { Card, Row, Col } from 'antd';
import { Statistic } from 'antd';

const PaxSummary = ({ data }) => {
    const finalize_count = data.pax_finalized.value || 0;
    const not_finalized_count = data.pax_not_finalized.value || 0;
    const paxc = (data.pax_count && data.pax_count.value) || 0;
    return (
        <Row type="flex" gutter={12} style={{ alignItems: '' }}>
            <Col span={6}>
                <Card>
                    <Statistic title="Total PAX" value={paxc} />
                </Card>
            </Col>
            <Col span={6}>
                <Card>
                    <Statistic title="Finalized PAX" value={finalize_count} suffix={`(${not_finalized_count} not finalized)`} />
                </Card>
            </Col>
        </Row>
    )
}
 
export default PaxSummary;