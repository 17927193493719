import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const FINALIZED_FLIGHTS = gql`
query FinalizedFlights($tpID: ID!, $customerID: ID, $startDate: AWSDate!, $endDate: AWSDate!, $departureID: ID){
    getFinalizedFlights(
        tpID: $tpID
        customerID: $customerID
        startDate: $startDate
        endDate: $endDate
        departureID: $departureID
    ){
        execution_stats {
            execution_time_ms
            results_returned
            total_keys_examined
            total_docs_examined
            total_quorum_docs_examined
        }
        docs {
            _id
            modifiedTs
            modifiedBy
            ... on HistoryFlightNode {
            _id
            originalFlightID
            desig
            legs
            timeOfDeparture
            scheduledFlightDate
            scheduleType
            firstLegUsableWt
            lastKnownController {
                _id
                name
            }
            legsArray {
                flightTime
                order
                departure
                departureID
                destination
                destinationID
                paxCount
                paxWeight
                cgoCount
                paxIDs {
                    _id,
                    firstName,
                    lastName,
                    paxWeight,
                    chargeCode,
                    individualLegTime,
                    departureID {
                        _id
                        name
                    }
                    destinationID {
                        _id
                        name
                    }
                    transitType
                }
                cgoIDs {
                    _id
                    name
                    approvedBy
                    departureID {
                        _id
                        name
                    }
                    destinationID {
                        _id
                        name
                    }
                    weight
                    transitType
                    deliveredBy
                }
            },
            pilot {
                _id
                name {
                    firstName,
                    lastName
                },
            },
            copilot {
                _id
                name {
                    firstName,
                    lastName
                },
            },
            contract {
                _id
                name
            },
            paxIDList {
                _id,
                firstName,
                lastName,
                chargeCode,
                individualLegTime,
                departureID {
                    _id
                    name
                }
                employerID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                transitType
            },
            cgoIDList {
                _id
                name
                chargeCode,
                approvedBy
                individualLegTime,
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                weight
                transitType
                deliveredBy
            },
            aircraftID {
                _id,
                model,
                tailNum,
                maxSeats,
                maxLoad
            }
        }
    }
}}`

export interface WithAllFinalizedFlightsDataProps {
    variables?: any
}

const withAllFinalizedFlightsData = <P extends object>(Component: React.ComponentType<P>) => {
    return (props: P & WithAllFinalizedFlightsDataProps) => (
        <Query query={FINALIZED_FLIGHTS} variables={props.variables}>
            {
                ({ data, loading }) => {
                    if (!loading && data?.getFinalizedFlights){
                        return <Component {...props} />
                    }
                    return null
                }
            }
        </Query>
    )
}

export default withAllFinalizedFlightsData