import React from 'react';
import { withUserGroups, WithUserGroupsProps } from 'components/userGroup';
import { Tooltip, Button, Drawer, Alert } from 'antd';
import useControlledState from 'hooks/useControlledState';
import { DrawerProps } from 'antd/lib/drawer';
import { Mutation } from 'react-apollo';
import CreatePersonSetQuery from './mutation/CreatePersonSet';
import { SetForm } from '../SetForm';
import withCognitoUser, { WithCognitoUserProps } from 'components/WithCognitoUser';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import { GroupData } from '.';

export interface CreatePersonSetProps extends Partial<WithUserGroupsProps>, Partial<WithOrgDataProps>, Partial<WithCognitoUserProps> {
    drawerVisible?: boolean,
    onDrawerChange?(visible: boolean): void,
    drawerProps?: DrawerProps,
    buttonDisabled?: boolean,
    groupData: GroupData,
    paxData: any[],
    onNewSetClicked?(setID: string): void
}

const CreatePersonSet: React.FC<CreatePersonSetProps> = (props) => {
    const [ drawerVisible, setDrawerVisible ] = useControlledState(false, props.drawerVisible, props.onDrawerChange);

    if (!props.userGroups.includes('flytsuite.schedule.sets')){
        return <Tooltip title="You don't have permission to create sets">
            <Button disabled>Create Set</Button>
        </Tooltip>
    }
    return <>
        <Button
            disabled={props.buttonDisabled}
            onClick={() => setDrawerVisible(true)}
        >Create Set</Button>
        <Drawer
            title="Create Set"
            width="30rem"
            {...props.drawerProps}
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
        >
            <Mutation
                mutation={CreatePersonSetQuery}
            >
            {(createPersonSet, { loading, error, data }: any) => {
                const { orgData: { customer, transporter }, cognitoUser } = props;
                const alreadyExists = (error && error.message.includes('conflict')) || false;
                var errMessage = error && error.message;
                if (alreadyExists){
                    errMessage = "This set already exists"
                }
                return (
                    <React.Fragment>
                        {error ? (
                            <Alert
                                showIcon
                                type="error"
                                message="Failed to create set"
                                description={errMessage}
                                closable
                            />
                        ) : null}
                        {!error && !loading && data && props.onNewSetClicked ? (
                            <Alert
                                showIcon
                                type="success"
                                message="Set created successfully!"
                                description={(
                                    <span>Click <Button className="mc-link-btn" onClick={() => props.onNewSetClicked(data.setPersonSet._id)}>here</Button> to view the new set!</span>
                                )}
                            />
                        ) : null}
                        <SetForm
                            defaultName={props.groupData && props.groupData.name}
                            defaultDepartureID={props.groupData && props.groupData.departureID}
                            defaultDestinationID={props.groupData && props.groupData.destinationID}
                            loading={loading}
                            onSubmit={(values) => {
                                const payload = {
                                    customerID: customer && customer._id,
                                    tpID: transporter && transporter._id,
                                    name: values.name.trim(),
                                    departureID: values.departure.key,
                                    destinationID: values.destination.key,
                                    outbound: props.paxData.filter(pax => pax.transitType === 'OUTBOUND').map(pax => pax.personID && pax.personID._id),
                                    inbound: props.paxData.filter(pax => pax.transitType === 'INBOUND').map(pax => pax.personID && pax.personID._id),
                                    owner: cognitoUser.attributes.email
                                }
                                createPersonSet({
                                    variables: {
                                        payload
                                    }
                                })
                            }}
                        />
                    </React.Fragment>
                )
            }}
            </Mutation>
        </Drawer>
    </>
}

export default withUserGroups<CreatePersonSetProps>(
    WithOrgData<CreatePersonSetProps>(
    withCognitoUser<CreatePersonSetProps>(CreatePersonSet)
    )
)