import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PaxQuery from './PaxQuery';
import PropTypes from 'prop-types';
import { Alert, Spin } from 'antd';
import LoadingContent from '../../LoadingContent';
import PaxISN from './PaxISN';

class PaxISNWithData extends Component {
    render() {
        const { paxId, fetchPolicy, showISNCheck=true, ...restProps } = this.props;
        return (
            <Query
            query={PaxQuery}
            variables={{
                _id: paxId
            }}
            fetchPolicy={fetchPolicy || 'network-only'}
            pollInterval={10000}
            >
            {({ data, loading, error }) => {
                const passenger = data && data.getPassenger;
                if (!passenger && loading ) return <LoadingContent />
                return (
                    <Spin spinning={loading}>
                        {error ? (
                            <Alert type="error" showIcon message="Failed to load passenger" />
                        ) : null}
                        {passenger ? (
                            <PaxISN data={passenger} {...restProps} showISNCheck={showISNCheck} />
                        ) : null}
                    </Spin>
                )
            }}
            </Query>
        );
    }
}

PaxISNWithData.propTypes = {
    paxId: PropTypes.string.isRequired,
    fetchPolicy: PropTypes.string,
    showISNCheck: PropTypes.bool
}
 
export default PaxISNWithData;