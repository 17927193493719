import useControlledState from './useControlledState';

export interface MultiSelectOptions<T> {
    selectedItems?: T[],
    primaryKey?: any,
    onSelection?(selectedItems: T[]): void
}

export type IsSelected = (key: any) => boolean
export type ToggleSelection<T> = (item: T) => void
export type ClearAll = () => void
export type SetSelectedItems<T> = (items: T[]) => void
export type SelectedItems<T> = T[]

export interface MultiSelectReturn<T> {
    isSelected?: IsSelected,
    toggleSelection?: ToggleSelection<T>,
    clearAll?: ClearAll,
    setSelectedItems: SetSelectedItems<T>,
    selectedItems: SelectedItems<T>,
    selectedKeys: any[]
}

export function getPrimaryKey<T>(item: T, pk?: any): any {
    if (pk){
        return item[pk]
    }
    return item
}

export function useMultiSelect<T>(options?: MultiSelectOptions<T>): MultiSelectReturn<T> {
    const [ selectedItems, setSelectedItems ] = useControlledState<SelectedItems<T>>([], options && options.selectedItems, options && options.onSelection);

    function isSelected(key: any): boolean {
        let idx = selectedItems.findIndex((item) => {
            return getPrimaryKey<T>(item, options.primaryKey) === key;
        });
        return idx !== -1
    }
    function toggleSelection(item: T): void {
        let key = getPrimaryKey<T>(item, options.primaryKey);
        let selected = isSelected(key);
        if (selected){
            setSelectedItems(selectedItems.filter((si) => {
                return getPrimaryKey<T>(si, options.primaryKey) !== key
            }))
        }
        else
        {
            setSelectedItems([ ...selectedItems, item ])
        }
    }
    function clearAll(){
        setSelectedItems([]);
    }
    return {
        isSelected,
        toggleSelection,
        clearAll,
        setSelectedItems,
        selectedItems,
        selectedKeys: selectedItems.map((item) => getPrimaryKey<T>(item, options.primaryKey))
    }
}