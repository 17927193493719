import React from 'react';
import { Button, Row, Col } from 'antd';
import pluralize from 'pluralize';
import { classNames } from 'common/util';

class BoardingRestrictionButton extends React.Component {
    render(){
        const { data, ...rest } = this.props;
        const auth = data.authorization;
        const getRestrictions = () => {
            if (typeof auth !== 'object') return []
            const restrictions = Object.entries(auth).filter(
                r => r[0].startsWith('brdRestriction') && r[0] !== 'brdRestriction_Isn' && r[1] && r[1].status !== 'NOT_APPLICABLE' && r[1].status !== 'MET_REQUIREMENT'
            );
            return restrictions
        }
        const restrictions = getRestrictions();
        var buttonChildren;
        if (restrictions.length){
            const notOverridden = restrictions.filter(r => r[1].status !== 'OVERRIDDEN');
            const overridden = restrictions.filter(r => r[1].status === 'OVERRIDDEN');
            buttonChildren = (
                <Row type="flex" gutter={6}>
                    {notOverridden.length ? (
                        <Col style={{ width: '100%' }}>
                            <span style={{color: '#f5222d'}}>{notOverridden.length} {pluralize('RESTRICTION', notOverridden.length)}</span>
                        </Col>
                    ) : null}
                    {(!notOverridden.length && overridden.length) ? (
                        <Col style={{ width: '100%' }}>
                            <span style={{color: '#1890ff'}}>{overridden.length} {pluralize('OVERRIDE', overridden.length)}</span>
                        </Col>
                    ) : null}
                </Row>
            )
        }else{
            buttonChildren = 'NO RESTRICTIONS'
        }
        return (
            <Button {...rest} className={classNames('mc-boarding-restriction-button', rest.className)}>{buttonChildren}</Button>
        )
    }
}

export default BoardingRestrictionButton