import gql from "graphql-tag";

export const LocationFragment = gql`
    fragment LocationFragment on Location {
        _id
        _rev
        name
        block
        field
        areaBlockName
        deletedTs
        type
    }
`;

export const ResolveLocationIdsQuery = gql`
    query GetLocationsFromIds($ids: [ID!]!){
        resolve_entity_ids(
            ids: $ids,
            typename: "Location"
        ){
            ... on Location {
                ...LocationFragment
            }
        }
    }
    ${LocationFragment}
`