import { Alert, Button, Card, Col, Divider, Row, Spin, Tabs, Typography } from 'antd';
import FlightState from 'components/flight/FlightState';
import gql from 'graphql-tag';
import moment from 'moment';
import React from 'react';
import { Query } from 'react-apollo';
// import PassengerTable from '../tables/PassengerTable';
import commonColumns from '../../common/table/columns';
import { getFlightDeparture } from '../../common/util';
import CollapsibleLegs from '../lists/legs/CollapsibleLegs';
import { FilterableTable } from '../tables/FilterableTable';
import './FlightPreview.less';

const FLIGHT = gql`
query Flight($id: ID!){
    getFlight(_id: $id) {
        _id
        desig
        legs
        timeOfDeparture
        state
        legsArray {
            departure
            destination
            seats
            paxWeight
            bagWeight
            bagCount
            cgoWeight
            paxIDs {
                _id,
                firstName,
                lastName,
                paxWeight,
                bagWeight,
                bagCount,
                customerID {
                    _id
                    name
                },
                employerID {
                    _id
                    name
                }
                tpID {
                    _id
                    name
                }
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                checkedInTime
                transitType
                lastIsnResultWithHeader {
                    error_message
                    selectedResult {
                        quickCheckStatus
                        isnStatus {
                            shortLabel
                        }
                        reason
                    }
                }
                isnCheckError {
                    message
                }
                authorization {
                    onWhiteList
                    noFlyListed
                    brdRestriction_Isn {
                        status
                    }
                    brdRestriction_SafetyCard {
                        status
                    }
                    brdRestriction_HuetCard {
                        status
                    }
                    brdRestriction_SteelToes {
                        status
                    }
                    brdRestriction_Twic {
                        status
                    }
                    brdRestriction_NonPreferred {
                        status
                    }
                    brdRestriction_Hazmat {
                        status
                    }
                    brdRestriction_NoFlyListed {
                        status
                    }
                    brdRestriction_EbsCard {
                        status
                    }
                    brdRestriction_Illness {
                        status
                    }
                    brdRestriction_Quarantined {
                        status
                    }
                }
            }
            cgoIDs {
                _id
                name
                approvedBy
                customerID {
                    _id
                    name
                }
                departureID {
                    _id
                    name
                }
                destinationID {
                    _id
                    name
                }
                deliveredBy
                attentionTo
                weight
                hazmat
                hazmatUnCode
                checkedInTime
                transitType
                currentCarrierID {
                    _id
                    desig
                }
            }
        },
        pilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        copilot {
            _id
            name {
                firstName,
                lastName
            },
        },
        contract {
            _id
            name
        },
        sequence,
        paxIDList {
            _id,
            firstName,
            lastName,
            paxWeight,
            bagWeight,
            customerID {
                _id
                name
            },
            employerID {
                _id
                name
            }
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            checkedInTime
            transitType
            authorization {
                onWhiteList
                noFlyListed
                brdRestriction_Isn {
                    status
                }
                brdRestriction_SafetyCard {
                    status
                }
                brdRestriction_HuetCard {
                    status
                }
                brdRestriction_SteelToes {
                    status
                }
                brdRestriction_Twic {
                    status
                }
                brdRestriction_NonPreferred {
                    status
                }
                brdRestriction_Hazmat {
                    status
                }
                brdRestriction_NoFlyListed {
                    status
                }
                brdRestriction_EbsCard {
                    status
                }
                brdRestriction_Illness {
                    status
                }
                brdRestriction_Quarantined {
                    status
                }
            }
        },
        cgoIDList {
            _id
            name
            approvedBy
            customerID {
                _id
                name
            }
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            deliveredBy
            attentionTo
            weight
            hazmat
            hazmatUnCode
            checkedInTime
            transitType
            currentCarrierID {
                _id
                desig
            }
        },
        aircraftID {
            _id,
            model,
            tailNum,
            maxSeats,
            maxLoad
        }
    }
}
`

const Property = ({ field, value, altVal = 'None' }) => {
    return (
        <div className="flight-preview-property">
            <div className="flight-preview-label">
                {field}:
            </div>
            <Typography.Text type="secondary">{value || altVal}</Typography.Text>
        </div>
    )
}

// const renderPanel = ({ title, content, key, hideIfEmpty=false }) => {
//     if (!content && hideIfEmpty) return null
//     return (
//         <Panel header={title} key={key}>
//             {content ? content : 'Nothing to display'}
//         </Panel>
//     )
// }

const Section = ({ title, children }) => (
    <div className="flight-preview-section">
        <h3>{title}</h3>
        <div>{children}</div>
    </div>
)

// class FlightPreview extends Component {
//     render() {
//         if (!this.props.data) return null
//         const { getFlight } = this.props.data;
//         const pilot = getFlight.pilot;
//         const copilot = getFlight.copilot;
//         const departure = getFlightDeparture(getFlight);
//         const legs = getFlight.legsArray || [];
//         const destinations = legs.map(leg => leg.destination);
        
//         return (
//             <div>
//                 <h3 style={{ fontWeight: 'normal', marginBottom: '1rem' }} >{getFlight.desig}</h3>
//                 <Card style={{ marginBottom: '1rem' }}>
//                     <Row gutter={36} type="flex" style={{ marginBottom: '-1.5rem' }}>
//                         <Col>
//                             <Section title="Pilots">
//                                 <Property field="Pilot" value={(pilot && pilot.name && (pilot.name.lastName && pilot.name.firstName) && `${pilot.name.lastName}, ${pilot.name.firstName}`)} />
//                                 <Property field="Co-Pilot" value={(copilot && pilot.name && (pilot.name.lastName && pilot.name.firstName) && `${copilot.name.lastName}, ${copilot.name.firstName}`)} />
//                             </Section>
//                         </Col>
//                         <Col>
//                             <Section title="Flight Route">
//                                 <Property field="Departure" value={(departure && departure.name)} />
//                                 <Property field="Destinations" value={destinations.join(', ')} />
//                             </Section>
//                         </Col>
//                         <Col>
//                             <Section title="Other">
//                                 <Property field="Contract" value={(getFlight.contract && getFlight.contract.name)} />
//                             </Section>
//                         </Col>
//                         {getFlight.aircraftID ? (
//                             <Col>
//                                 <Section title="Aircraft">
//                                     <Property field="Model" value={getFlight.aircraftID.model} />
//                                     <Property field="Tail Num" value={getFlight.aircraftID.tailNum} />
//                                     <Property field="Max Seats" value={getFlight.aircraftID.maxSeats} altVal={0} />
//                                     <Property field="Useful Load" value={getFlight.aircraftID.maxLoad} altVal={0} />
//                                 </Section>
//                             </Col>
//                         ) : null}
                        
//                     </Row>
//                 </Card>
//                 <Tabs defaultActiveKey="legs" type="card">
//                     <Tabs.TabPane key="legs" tab={`Legs (${legs.length})`}>
//                         <CollapsibleLegs data={legs} />
//                     </Tabs.TabPane>
//                     <Tabs.TabPane key="pax" tab={`Passengers (${getFlight.paxIDList ? getFlight.paxIDList.length : 0})`}>
//                     {
//                             getFlight.paxIDList && getFlight.paxIDList.length ? (
//                             <FilterableTable
//                                 dataSource={getFlight.paxIDList}
//                                 rowKey={record => record._id}
//                                 size="small"
//                                 pagination={false}
//                                 scroll={{ x: 1500 }}
//                                 columns={commonColumns.pax.except(['assignedFlight'])}
//                             />
//                         ) : 'No Passengers'
//                     }
//                     </Tabs.TabPane>
//                     <Tabs.TabPane key="cgo" tab={`Cargo (${getFlight.cgoIDList ? getFlight.cgoIDList.length : 0})`}>
//                     {
//                         getFlight.cgoIDList && getFlight.cgoIDList.length ? (
//                             <FilterableTable
//                                 dataSource={getFlight.cgoIDList}
//                                 rowKey={record => record._id}
//                                 size="small"
//                                 pagination={false}
//                                 scroll={{ x: 1500 }}
//                                 columns={commonColumns.cgo.except(['assignedFlight'])}
//                             />
//                         ) : 'No Cargo'
//                     }
//                     </Tabs.TabPane>
//                 </Tabs>
//             </div>
//         )
//     }
// }

const FlightPreview = ({ id }) => {

    return (
        <Query
            query={FLIGHT}
            variables={{ id }}
            fetchPolicy="cache-and-network"
        >
        {
            ({ data, loading, error, refetch }) => {
                const getFlight = data && data.getFlight;
                if (loading && !getFlight) return (
                    <div style={{ textAlign: 'center', padding: '1rem' }} >
                        <Spin spinning={loading} tip="Loading" />
                    </div>
                )
                const pilot = getFlight && getFlight.pilot;
                const copilot = getFlight && getFlight.copilot;
                const departure = getFlight && getFlightDeparture(getFlight);
                const legs = (getFlight && getFlight.legsArray) || [];
                const destinations = getFlight && legs.map(leg => leg.destination);
                return (
                    <div>
                        {error ? <Alert type="error" showIcon message="Failed to load flight" /> : null}
                        {getFlight ? (
                            <div>
                                <Row type="flex" gutter={12} style={{ marginBottom: '1rem', alignItems: 'center' }}>
                                    <Col>
                                        <h3 style={{ marginBottom: 0 }}>{getFlight.desig}</h3>
                                    </Col>
                                    {getFlight.state !== 'ACTIVE' ? (
                                        <>
                                            <Col>
                                                <Divider type="vertical" />
                                            </Col>
                                            <Col>
                                                <FlightState
                                                    state={getFlight.state}
                                                    title="Flight Status"
                                                    size="large"
                                                    style={{ fontSize: '1.17em' }}
                                                />
                                            </Col>
                                        </>    
                                    ) : null}
                                    <Col style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button loading={loading} onClick={() => refetch()} >Refresh</Button>
                                    </Col>
                                </Row>
                                <Card style={{ marginBottom: '1rem' }}>
                                    <Row gutter={36} type="flex" style={{ marginBottom: '-1.5rem' }}>
                                        <Col>
                                            <Section title="Pilots">
                                                <Property field="Pilot" value={(pilot && pilot.name && (pilot.name.lastName && pilot.name.firstName) && `${pilot.name.lastName}, ${pilot.name.firstName}`)} />
                                                <Property field="Co-Pilot" value={(copilot && pilot.name && (pilot.name.lastName && pilot.name.firstName) && `${copilot.name.lastName}, ${copilot.name.firstName}`)} />
                                            </Section>
                                        </Col>
                                        <Col>
                                            <Section title="Flight Route">
                                                <Property field="Departure" value={(departure && departure.name)} />
                                                <Property field="Destinations" value={destinations.join(', ')} />
                                            </Section>
                                        </Col>
                                        <Col>
                                            <Section title="Other">
                                                <Property field="Contract" value={(getFlight.contract && getFlight.contract.name)} />
                                                <Property field="Time of Departure" value={(getFlight.timeOfDeparture && moment(getFlight.timeOfDeparture).format('YYYY-MM-DD HH:mm'))} />
                                            </Section>
                                        </Col>
                                        {getFlight.aircraftID ? (
                                            <Col>
                                                <Section title="Aircraft">
                                                    <Property field="Model" value={getFlight.aircraftID.model} />
                                                    <Property field="Tail Num" value={getFlight.aircraftID.tailNum} />
                                                    <Property field="Max Seats" value={getFlight.aircraftID.maxSeats} altVal={0} />
                                                    <Property field="Useful Load" value={getFlight.aircraftID.maxLoad} altVal={0} />
                                                </Section>
                                            </Col>
                                        ) : null}
                                        
                                    </Row>
                                </Card>
                                <Tabs defaultActiveKey="legs" type="card">
                                    <Tabs.TabPane key="legs" tab={`Legs (${legs.length})`}>
                                        <CollapsibleLegs data={legs} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="pax" tab={`Passengers (${getFlight.paxIDList ? getFlight.paxIDList.length : 0})`}>
                                    {
                                            getFlight.paxIDList && getFlight.paxIDList.length ? (
                                            <FilterableTable
                                                className="mc-table flight-preview-passengers-tb"
                                                dataSource={getFlight.paxIDList}
                                                rowKey={record => record._id}
                                                size="small"
                                                pagination={false}
                                                scroll={{ x: 1500 }}
                                                columns={[
                                                    ...commonColumns.pax.some(['name', 'transporter', 'employer']),
                                                    {
                                                        ...commonColumns.pax.columns.weight,
                                                        width: 100
                                                    },
                                                    {
                                                        ...commonColumns.pax.columns.bagCount,
                                                        width: 100
                                                    },
                                                    {
                                                        ...commonColumns.pax.columns.bagWeight,
                                                        width: 100
                                                    },
                                                    ...commonColumns.pax.some(['checkedIn', 'transitType', 'departure', 'destination', 'tsa', 'restrictions', 'isn'])
                                                ]}
                                            />
                                        ) : 'No Passengers'
                                    }
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="cgo" tab={`Cargo (${getFlight.cgoIDList ? getFlight.cgoIDList.length : 0})`}>
                                    {
                                        getFlight.cgoIDList && getFlight.cgoIDList.length ? (
                                            <FilterableTable
                                                dataSource={getFlight.cgoIDList}
                                                rowKey={record => record._id}
                                                size="small"
                                                pagination={false}
                                                scroll={{ x: 1500 }}
                                                columns={commonColumns.cgo.except(['assignedFlight'])}
                                            />
                                        ) : 'No Cargo'
                                    }
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        ) : null}
                    </div>
                )
            }
        }
        </Query>
    )
}

export default FlightPreview
 
// export default compose(
//     graphql(
//         FLIGHT,
//         {
//             options: ({ id }) => ({
//                 variables: {
//                     id
//                 },
//                 fetchPolicy: 'cache-and-network',
//                 pollInterval: 5000
//             }),
//             skip: props => !props.id
//         }
//     ),
//     withLoading({ style: { margin: '10rem 0' } }),
//     withError()
// )(FlightPreview)