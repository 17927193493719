import React from 'react';

import { ApolloProvider } from 'react-apollo';
import { ApolloLink, Observable} from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import { Auth } from '@aws-amplify/auth';
import { onError } from 'apollo-link-error';
import config from '../config';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-boost';
import { RetryLink } from 'apollo-link-retry';
import defaults from '../defaultState';
import DebounceLink from 'apollo-link-debounce';
import Resolvers from "resolvers";
import TypeDefs from "typeDefs";

const DEFAULT_DEBOUNCE_TIMEOUT = 1000;

const cache = new InMemoryCache({
    dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}`: null),
    cacheRedirects: {
        Query: {
            // If every item in the cache exists, then this is a cache hit...if the docs are right...
            resolve_entity_ids: (_, args, { getCacheKey }) => {
                return args.ids.map((id) => getCacheKey({ __typename: args.typename, _id: id }));
            }
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
          console.log('[Graphql Error]', err);
        switch (err.errorType) {
          case 'UnauthorizedException':
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver

            // modify the operation context with a new token
            return new Observable(async observer => {
                try{
                    // const creds = await Auth.currentUserCredentials();
                    // creds.refresh(err => {throw Error(err)});
                    const idToken = await Auth.currentSession().idToken;
                    console.log('currentSession', await Auth.currentSession());
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: idToken.jwtToken,
                        },
                    });
                    const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    }
                    forward(operation).subscribe(subscriber);
                }catch(error){
                    console.error('ID Token refresh failed', err)
                    observer.error(error)
                }
            })
          default:
            break;
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
);

const authLink = setContext((request) => new Promise( (resolve, reject) => {
    let promises = [];

    promises.push(
    Auth.currentSession()
    .then(session => {
      const token = session.idToken.jwtToken;
      return {
        headers: { Authorization: token }
      }
    })
    )

    Promise.all(promises)
        .then((values) => resolve(values[0]))
        .catch((err) => reject(err))

  }));

persistCache({
    cache,
    storage: window.localStorage
})

const client = new ApolloClient({
    cache,
    resolvers: Resolvers,
    typeDefs: TypeDefs,
    link: ApolloLink.from([
        errorLink,
        new RetryLink(),
        authLink,
        new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
        new HttpLink({
            uri: config.appSync.url
        })
    ])
})

cache.writeData({ data: defaults });

client.onResetStore(() => cache.writeData({ data: defaults }));

const withApolloProvider = (Component) => {
    return props => (
        <ApolloProvider client={client}>
                <Component {...props} />
        </ApolloProvider>
    )
}

export default withApolloProvider