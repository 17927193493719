import { useEffect } from "react";
import { useState } from "react";

interface WindowSize {
    width: number,
    height: number
}

function useWindowSize(): WindowSize {
    const [ size, setSize ] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    function onWindowSizeChange(){
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    useEffect(() => {
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, [])

    return size;
}

export default useWindowSize;