import gql from "graphql-tag";

export default gql`
mutation SaveCredentialMutation(
    $_id: ID
    $key: String
    $expiration: AWSDateTime
    $type: CredentialType
    $personID: ID!
    $tpID: ID!
    $customerID: ID
){
    credential(
        _id: $_id
        key: $key
        expiration: $expiration
        type: $type
        personID: $personID
        tpID: $tpID
        customerID: $customerID
    ){
        _id
        key
        expiration
        type
    }
}
`