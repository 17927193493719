import MainHeader, {MainHeaderProps} from "../../../../components/scheduling/MainHeader";
import {Button, Col, Icon, message, Modal, Row, Tooltip} from "antd";
import React, {useContext} from "react";
import * as Phase2State from './state';
import useUserGroups from "../../../../hooks/useUserGroups";
import {TransitType} from "../../../../common/types/carriable";
import * as Util from "./util";
import MCIcon from "../../../../components/icon";
import {getSNodeID} from "../../../../components/scheduling/util";

const BASE_CLS = Util.BASE_CLS;

interface Phase2MainHeaderProps {}

export interface Phase2MainHeaderProps_Internal extends Phase2MainHeaderProps {}

const Phase2MainHeader: React.FC<Phase2MainHeaderProps> = (props) => {

    const { api } = useContext(Phase2State.Context);
    const [ userGroups ] = useUserGroups();

    const outboundPaxData = api.getScheduledPaxNodes()
        .filter(pax => pax.transitType === TransitType.OUTBOUND);

    function runIsnCheck () {
        if (api.editMode.get()){
            message.error('Cannot run ISN check in edit mode');
            return;
        }

        api.bulkIsnChecker.runChecks(outboundPaxData.map((pax) => getSNodeID(pax)));
    }

    let mainHeaderProps: MainHeaderProps = {
        routes: api.mainHeader.routes,
        afterRoutes: (
            <span
                className={BASE_CLS + '-beta-tag'}
                style={{ marginLeft: '2rem' }}
                title="The V2 editor is currently in beta."
            >BETA</span>
        ),
        actions: (
            <Row type="flex" gutter={12}>
                <Col>
                    <Button onClick={api.schedule.cancelEdits}>Cancel</Button>
                </Col>
                <Col>
                    <Button
                        disabled={
                            (!api.groupData && api.data.modifiedEntities.get().length === 0) ||
                            ( api.groupData && (!api.data.modifiedEntities.get().length || api.queryInfo.loading || api.queryInfo.hasError))
                        }
                        onClick={api.schedule.save}
                        type="primary"
                    >
                        {api.saveInfo.saving ? (
                            <Icon type="loading" spin style={{ marginRight: 6 }} />
                        ) : null}
                        Save Changes
                    </Button>
                </Col>
            </Row>
        )
    }
    if (!api.editMode.get()){

        let deleteScheduleDisabled = false;

        let paxOnFlight = api.data.modifiedEntities.get().filter(pax => pax.currentCarrierID);

        if (api.bulkIsnChecker.running || paxOnFlight.length)
            deleteScheduleDisabled = true;

        let deleteScheduleBtn = (
            <Button
                disabled={deleteScheduleDisabled}
                type="danger"
                loading={api.saveInfo.saving}
                onClick={() => {
                    Modal.confirm({
                        title: 'Are you sure?',
                        content: `Are you sure you want to delete ${api.data.originalEntities.get().length} passengers/cargo?`,
                        onOk() {
                            api.schedule.deleteSchedule();
                            // props.onDeleteGroup(createSaveMutationVars(
                            //     orgData,
                            //     {
                            //         date: props.groupData.date,
                            //         lastKnownController: props.groupData.lastKnownController,
                            //         departureID: props.groupData.departureID,
                            //         destinationID: props.groupData.destinationID,
                            //         group: props.groupData.name
                            //     },
                            //     [],
                            //     data
                            // ))
                        }
                    })
                }}
            >
                <Icon type="delete" style={{ marginRight: 6 }} />Delete Schedule
            </Button>
        );
        let deleteScheduleBtnWrapped = deleteScheduleBtn;

        if (paxOnFlight.length){
            deleteScheduleBtnWrapped = (
                <Tooltip title={
                    <>
                        <p>{paxOnFlight.length} PAX/CGO are on a flight. They need to be removed from their flight before you can do this.</p>
                        <span>Click on the{'  '}<MCIcon type="helicopter-flight" />{'  '}icon to go to their assigned flight.</span>
                    </>
                }>
                    {deleteScheduleBtn}
                </Tooltip>
            )
        }

        if (!userGroups.includes('flytsuite.schedule.passenger')){
            deleteScheduleBtnWrapped = (
                <Tooltip title="You don't have permission to delete schedules">
                    {deleteScheduleBtn}
                </Tooltip>
            )
        }

        mainHeaderProps.actions = (
            <Row type="flex" gutter={12}>
                <Col>
                    {deleteScheduleBtnWrapped}
                </Col>
                <Col>
                    {/* TODO: Add V2 schedule support to the CreatePersonSet component */}
                    <Tooltip title="This feature is not available for multi-leg schedules yet." placement="bottom">
                        <Button
                            disabled={true}
                        >
                            Create Set
                        </Button>
                    </Tooltip>
                    {/*<CreatePersonSet*/}
                    {/*    groupData={api.groupData}*/}
                    {/*    paxData={api.getScheduledPaxNodes()}*/}
                    {/*    onNewSetClicked={api.schedule.notifySetCreated}*/}
                    {/*/>*/}
                </Col>
                {/* TODO: Add V2 schedule support to the ManifestPrintout component */}
                <Tooltip title="This feature is not available for multi-leg schedules yet." placement="bottom">
                    <Button
                        disabled={true}
                    >
                        Download Printout
                    </Button>
                </Tooltip>
                {/*<Col>*/}
                {/*    <ResolveEntityID*/}
                {/*        typename="Location"*/}
                {/*        ids={[api.groupData.departureID, api.groupData.destinationID]}*/}
                {/*        gqlFields={`*/}
                {/*            name*/}
                {/*            `}*/}
                {/*    >*/}
                {/*        {({ data, loading, error }: any) => {*/}
                {/*            return <Spin spinning={loading}>*/}
                {/*                <ManifestPrintout*/}
                {/*                    buttonProps={{*/}
                {/*                        disabled: (!data || !data[0] || !data[1] || error) ? true : false*/}
                {/*                    }}*/}
                {/*                    customerID={orgData.getOrgIDByType('customer')}*/}
                {/*                    groupData={api.groupData}*/}
                {/*                    paxData={api.getScheduledPaxNodes()}*/}
                {/*                    cgoData={api.getScheduledCgoNodes()}*/}
                {/*                    departureName={data && data[0] && data[0].name}*/}
                {/*                    destinationName={data && data[1] && data[1].name}*/}
                {/*                />*/}
                {/*            </Spin>*/}
                {/*        }}*/}
                {/*    </ResolveEntityID>*/}
                {/*</Col>*/}
                <Col>
                    {userGroups.includes('flytsuite.schedule.passenger') ? (
                        <Button
                            disabled={!Array.isArray(outboundPaxData) || outboundPaxData.length === 0}
                            loading={api.bulkIsnChecker.running} onClick={() => runIsnCheck()}>Run ISN Check</Button>
                    ) : (
                        <Tooltip title="You don't have permission to run ISN checks">
                            <Button disabled>Run ISN Check</Button>
                        </Tooltip>
                    )}
                </Col>
                <Col>
                    {userGroups.includes('flytsuite.schedule.passenger') ? (
                        <Button
                            disabled={api.bulkIsnChecker.running}
                            type="primary"
                            onClick={api.editMode.toggle}
                        >
                            <Icon type="edit" style={{ marginRight: 6 }} />Edit
                        </Button>
                    ) : (
                        <Tooltip title="You don't have permission to edit schedules">
                            <Button disabled><Icon type="edit" style={{ marginRight: 6 }} />Edit</Button>
                        </Tooltip>
                    )}
                </Col>
            </Row>
        )
    }
    return <MainHeader {...mainHeaderProps} />
}

export default Phase2MainHeader;