import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import React, { Component } from 'react';
import FlightPreview from './flight/FlightPreview';

export interface HasFlightDrawer {
    flightDrawer: {
        open: (flightId: string) => void,
        close: () => void
    }
}

export interface WithFlightDrawerOptions {
    drawerProps?: DrawerProps
}

export interface WithFlightDrawerProps {
    options?: WithFlightDrawerOptions,
    component?: any
}

class FlightDrawerWrapper extends Component<WithFlightDrawerProps> {
    state = {
        visible: false,
        flight: null
    }
    open = (flight) => this.setState({ flight, visible: true })
    close = () => this.setState({ visible: false })
    render(){
        const { component: Component, options } = this.props;
        const drawerProps = options ? options.drawerProps : {};
        return (
            <React.Fragment>
                <Component {...this.props} flightDrawer={{
                    open: this.open,
                    close: this.close   
                }} />
                <Drawer
                    title="View Flight"
                    width="50rem"
                    visible={this.state.visible}
                    onClose={this.close}
                    {...drawerProps}
                >
                    <FlightPreview id={this.state.flight} />
                </Drawer>
            </React.Fragment>
        )
    }
}

const withFlightDrawer = (options?: WithFlightDrawerOptions) => (Component) => (props) => (
    <FlightDrawerWrapper {...props} options={options} component={Component} />
)

export default withFlightDrawer