import { Alert, Button, Col, Divider, Layout, Popconfirm, Row, Tooltip } from 'antd';
import useUserGroups from 'hooks/useUserGroups';
import React, { CSSProperties, HTMLAttributes, ReactNode, useEffect } from 'react';
import './details.less';
import './masterdata.less';

const DEFAULT_MD_DEL_USR_GRPS = ['flytsuite.master.all', 'flytsuite.master.delete'];
const DEFAULT_MD_EDIT_USR_GRPS = ['flytsuite.master.all', 'flytsuite.master.edit', 'flytsuite.master.person.all'];

export interface MDDetailsProps extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style'> {
    title?: any,
    strTitle?: string,
    saving?: boolean,
    deleting?: boolean,
    onCancel?: () => void,
    onSave?: () => void,
    onDelete?:() => void,
    onReset?: () => void,
    showReset?: boolean,
    contentWidth?: string | number,
    contentStyle?: CSSProperties,
    disableSave?: boolean,
    isNewEntry?: boolean,
    hideSaveBtn?: boolean,
    saveBtnText?: ReactNode,
    deleteUserGroups?: string[],
    editUserGroups?: string[]
    /**
     * Add extra buttons or content to the right side of the header
     */
    extra?: Array<ReactNode>
}

export function getDetailsTitle(title: ReactNode, isNewEntry: boolean, newEntryText: string='New Entry') {
    if (isNewEntry) return newEntryText
    return title
}

const MDDetails: React.FC<MDDetailsProps> = (props) => {
    const [ userGroups ] = useUserGroups();
    const delUserGroups = props.deleteUserGroups ? [...props.deleteUserGroups, ...DEFAULT_MD_DEL_USR_GRPS] : DEFAULT_MD_DEL_USR_GRPS;
    const editUserGroups = props.editUserGroups  ? [...props.editUserGroups, ...DEFAULT_MD_EDIT_USR_GRPS] : DEFAULT_MD_EDIT_USR_GRPS;

    let userHasDeletePerm = false;
    for (const i in userGroups) {
        let group = userGroups[i];
        if (delUserGroups.includes(group)){
            userHasDeletePerm = true;
            break;
        }
    }

    let userHasEditPerm = false;
    for (const i in userGroups) {
        let group = userGroups[i];
        if (editUserGroups.includes(group)){
            userHasEditPerm = true;
            break;
        }
    }

    useEffect(() => {
        if (!props.onSave && !props.hideSaveBtn){
            console.warn('MDDetails must have the onSave prop defined to save.');
        }
    // eslint-disable-next-line
    }, [ props.onSave ])

    function renderDeleteBtn(){
        if (props.isNewEntry || !props.onDelete){
            return null;
        }
        if (userHasDeletePerm){
            return <Popconfirm
                title={`Are you sure you want to remove ${props.strTitle || props.title}?`}
                okText="Remove"
                onConfirm={props.onDelete}
                placement="bottomLeft"
            >
                <Button
                    disabled={props.deleting}
                >
                    {props.deleting ? 'Deleting' : 'Delete'}
                </Button>
            </Popconfirm>
        }
        return <Tooltip title="You do not have permission to delete master data">     
            <Button disabled>
                Delete
            </Button>
        </Tooltip>
    }

    const { style, className } = props;

    let contentStyle: CSSProperties = {
        width: props.contentWidth,
        ...props.contentStyle
    }

    return <Layout style={style} className={`mc-masterdata-details ${className || ''}`}>
        <Row type="flex" className="mc-masterdata-details-header mc-masterdata-header" gutter={12}>
            <Col>
                <div className="mc-masterdata-details-title">{props.title}</div>
            </Col>
            {props.onDelete && !props.isNewEntry ?
                <Col>
                    {renderDeleteBtn()}
                </Col>
            : null}
            <div style={{ flex: 1 }} />
            {props.extra?.length > 0 ? (
                <>
                {props.extra.map(node => <Col>{node}</Col>)}
                <Divider className='mc-masterdata-hide-small' type="vertical" style={{ height: '100%' }} />
                </>
            ) : null}
            {props.showReset ? (
                <Col>
                    <Button type="link" style={{ padding: 0 }} onClick={() => props.onReset && props.onReset()}>Reset</Button>
                </Col>
            ) : null}
            <Col className='mc-masterdata-details-cancel-save-col'>
                <Row type="flex" gutter={12} className='mc-masterdata-details-cancel-save-row'>
                    <Col>
                        <Button onClick={props.onCancel}>{props.hideSaveBtn ? 'Close' : 'Cancel'}</Button>
                    </Col>
                    {!props.hideSaveBtn ? (
                        <Col>
                            <Button
                                disabled={props.saving || !props.onSave || props.disableSave || !userHasEditPerm}
                                onClick={() => props.onSave()}
                                type="primary">{props.saving ? 'Saving' : (props.saveBtnText || 'Save')}</Button>
                        </Col>
                    ) : null}
                </Row>
            </Col>
        </Row>
        <div className="mc-masterdata-details-content" style={contentStyle}>
            {!userHasEditPerm ? (
                <>
                <Alert
                    message="Not Authorized"
                    description="You do not have permission to edit master data"
                    showIcon
                    type="error"
                    style={{ marginBottom: '1rem' }}
                />
                <div style={{ pointerEvents: 'none', opacity: 0.5, cursor: 'not-allowed' }}>
                    {props.children}
                </div>
                </>
            ) : props.children}
        </div>
    </Layout>
}

export default MDDetails