import { ColumnProps } from "antd/lib/table";

export interface Columns {
    [key: string]: ColumnProps<any>
}

export default class ColumnSet {
    columns: Columns = {}
    all = (): ColumnProps<any>[] => {
        const columns = Object.values(this.columns)
        return columns.filter(column => column.key)
    }
    some = (cols: string[]): ColumnProps<any>[] => {
        const columns = Object.entries(this.columns)
        const filtered = columns.filter(column => cols.includes(column[0]))
        return filtered.map(column => column[1])
    }
    except = (cols: string[]): ColumnProps<any>[] => {
        const columns = Object.entries(this.columns)
        const filtered = columns.filter(column => !cols.includes(column[0]))
        return filtered.map(column => column[1])
    }
}