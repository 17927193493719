import React from 'react';
import { Input, Select, Tooltip, Button } from 'antd';
import usStates from './usStates';
import pluralize from 'pluralize'
import { englishList } from 'common/util';

const stateOptions = Object.entries(usStates).map(([ key, value ]) => <Select.Option key={key}>{`${key} (${value})`}</Select.Option>);

const InputAddress = React.forwardRef((props, ref) => {
    // eslint-disable-next-line
    let { value={}, onChange, style={}, ...restProps } = props;
    if (value === null){
        value = {};
    }
    const handleChange = (fieldKey) => (event) => {
        if (event.target){
            var v = event.target.value;
        }
        else
        {
            v = event;
        }
        if (typeof onChange === 'function'){
            onChange({
                street: value.street,
                city: value.city,
                state: value.state,
                zipCode: value.zipCode,
                [fieldKey]: v 
            });
        }
    }
    const clear = () => {
        if (typeof onChange !== 'function') return;
        onChange({
            street: undefined,
            city: undefined,
            state: undefined,
            zipCode: undefined
        })
    }
    const createInput = (key, placeholder, cmpt, inputProps) => {
        let Component = cmpt || Input;
        let inputStyle = {
            display: 'inline-block',
            width: 'auto',
            ...((inputProps && inputProps.style) || {})
        }
        return <Component {...inputProps} placeholder={placeholder} value={value && value[key]} onChange={handleChange(key)} style={inputStyle} />
    }

    let stateSelectProps = {
        children: stateOptions,
        showSearch: true,
        optionFilterProp: '',
        showAction: "focus",
        filterOption: (input, option) => {
            console.log(option.props.children);
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        style: {
            minWidth: '10rem'
        }
    }

    let inputs = [
        createInput('street', 'Street'),
        createInput('city', 'City'),
        createInput('state', 'State', Select, stateSelectProps),
        createInput('zipCode', 'ZIP Code')
    ]
    return <Input.Group ref={ref} compact {...restProps}>
        {inputs}
        {value && (value.street || value.city || value.state || value.zipCode) ? (
            <Tooltip title="Clear">
                <Button onClick={clear} icon="close" />
            </Tooltip>
        ) : null}
    </Input.Group>
})

export const validator = (rules, value, callback) => {
    if (value && (value.street || value.city || value.state || value.zipCode)){
        let missingFields = Object.entries(value).filter(entry => !entry[1]).map(entry => entry[0]);
        if (missingFields.length){
            callback(`You must fill in the ${pluralize('field', missingFields.length)} ${englishList(missingFields)}`);
        }
        return;
    }
    callback();
}

InputAddress.validator = validator

export default InputAddress