import {FormComponentProps} from "antd/lib/form";
import React from "react";
import {Checkbox, Col, DatePicker, Form, Input, List, Row} from "antd";
import moment from "moment";
import {
    NonComplyBypass,
    NonComplyBypassCategory,
    NonComplyBypassCategoryHuman,
    NonComplyBypassInput,
} from "../../../common/types/non-comply-bypass";
import {momentOrNull} from "../../../common/util";
import {createFormField, FormItemClassNames} from "../../../common/form";

export interface WhitelistBypassFormIndividualProps extends FormComponentProps {
    initialData?: NonComplyBypass[],
    onFormFieldsChange?: (fields: any) => void,
    formFields?: any
}

/**
 * This form allows the user to type information for each non-comply type.
 *
 * To add a new non-comply category, please add a new entry into the NonComplyBypassCategory and
 * NonComplyBypassCategoryHuman enums.
 */
const WhitelistBypassFormIndividual: React.FC<WhitelistBypassFormIndividualProps> = (props) => {

    const {
        form,
        initialData=[]
    } = props;

    const renderDatePicker = (disabled: boolean) => (
        <DatePicker
            disabledDate={(current) => current.isBefore(moment().startOf('day'))}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder="No expiration (add one here)"
        />
    )

    function findBypass(category: NonComplyBypassCategory){
        return initialData?.find((nonComplyBypass) => nonComplyBypass.nonComplyCategory === category);
    }

    const dataSource = Object.keys(NonComplyBypassCategory).filter(c => c !== NonComplyBypassCategory.NONE);

    return <Form>
        <List
            dataSource={dataSource}
            renderItem={(category, index) => {

                const lastItem = index === dataSource.length - 1;
                const firstItem = index === 0;

                let checkboxText = NonComplyBypassCategoryHuman[category];

                const isEnabled = form.getFieldValue(`${category}:enabled`);

                return (
                    <List.Item key={category} style={{ padding: `${firstItem ? '0' : '12px'} 0 ${lastItem ? '0' : '12px'} 0`, display: 'block' }}>
                        <Form.Item className={FormItemClassNames.NoMargin}>
                            {form.getFieldDecorator(`${category}:enabled`, {
                                initialValue: !!findBypass(category as NonComplyBypassCategory),
                                valuePropName: 'checked'
                            })(<Checkbox>{checkboxText}</Checkbox>)}
                        </Form.Item>
                        <Row key={category} type="flex" gutter={12}>
                            <Col style={{ flex: 1 }}>
                                <Form.Item label="Reason" className={FormItemClassNames.NoMargin} style={{ opacity: isEnabled ? '1' : '0.5' }}>
                                    {form.getFieldDecorator(`${category}:reason`, {
                                        initialValue: findBypass(category as NonComplyBypassCategory)?.reason,
                                        rules: [{ required: isEnabled, message: 'Reason is required.' }]
                                    })(<Input.TextArea disabled={!isEnabled} />)}
                                </Form.Item>
                            </Col>
                            <Col style={{ flex: 1 }}>
                                <Form.Item label="Expiration" className={FormItemClassNames.NoMargin} style={{ opacity: isEnabled ? '1' : '0.5' }}>
                                    {form.getFieldDecorator(`${category}:expiresOn`, {
                                        initialValue: momentOrNull(findBypass(category as NonComplyBypassCategory)?.expiresOn)
                                    })(renderDatePicker(!isEnabled))}
                                </Form.Item>
                            </Col>
                        </Row>
                    </List.Item>
                )
            }}
        />
    </Form>
}

export default Form.create<WhitelistBypassFormIndividualProps>({
    onFieldsChange(props, fields, allFields){
        props.onFormFieldsChange?.(allFields)
    },
    mapPropsToFields(props){
        if (!Object.keys(props.formFields || {}).length && props.initialData?.length){
            // If formFields is empty, initialize form from initialData.
            const formFields: any = {};
            props.initialData.forEach((bypass) => {
                const cat = bypass.nonComplyCategory;
                formFields[`${cat}:enabled`] = createFormField(true);
                formFields[`${cat}:reason`] = createFormField(bypass.reason);
                formFields[`${cat}:expiresOn`] = createFormField(momentOrNull(bypass.expiresOn));
            })
            return formFields;
        }
        return props.formFields
    }
})(WhitelistBypassFormIndividual);

/**
 * Reads the dynamic form values and converts them into a list of NonComplyBypassInput objects
 * (with the nonComplyCategory included too so we can derive the non-comply type later)
 */
function parseFormValues(values: any): (NonComplyBypassInput & { nonComplyCategory: NonComplyBypassCategory })[] {
    const newBypassMapping = new Map<NonComplyBypassCategory, Partial<NonComplyBypassInput>>();

    function putBypassField(category: NonComplyBypassCategory, fieldName: string, value: any){
        if (newBypassMapping.has(category)){
            newBypassMapping.get(category)[fieldName] = value;
        }
        else
        {
            newBypassMapping.set(category, { [fieldName]: value });
        }
    }

    Object.entries(values)
        .forEach(([ key, value ]) => {
            const [ categoryStr, fieldName ] = key.split(":");
            const category = NonComplyBypassCategory[categoryStr];
            if (!category) return;

            putBypassField(category, fieldName, value);
        })

    const nonComplyList: (NonComplyBypassInput & { nonComplyCategory: NonComplyBypassCategory })[] = []

    newBypassMapping.forEach((value, key) => {
        if (!(value as any).enabled) return;

        const item = {
            ...(value as NonComplyBypassInput),
            expiresOn: momentOrNull(value.expiresOn)?.format('YYYY-MM-DD'),
            nonComplyCategory: key
        }
        delete (item as any).enabled;

        nonComplyList.push(item);
    })

    return nonComplyList;
}

export const WhitelistBypassFormUtils = {
    parseFormValues
}