import { Select } from 'antd';
import React from 'react';
import { SearchSelectWithData } from '../SearchSelect';
import CountryCodeQuery from './CountryCodeQuery';

const { Option } = Select;

export const CountryCodeSelect = ({
    value,
    ...restProps
}) => {
    return (
        <SearchSelectWithData
            {...restProps}
            mode="single"
            style={{ width: 200 }}
            query={CountryCodeQuery}
            value={value}
            getData={(data) => data && data.getCountryCodes && data.getCountryCodes.countries}
            onDataItem={item => <Option key={item.Code}>{item.Name}</Option>}
            filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
        />
    )
}