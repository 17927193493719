import WithOrgData, { WithOrgDataProps, OrgDataProps } from '../WithOrgData';
import { ReactElement } from 'react';

export interface CurrentOrgProps<T=any> extends WithOrgDataProps {
    children: (orgData: OrgDataProps) => ReactElement<T>
}

const CurrentOrg: React.FC<CurrentOrgProps> = ({ children, orgData }) => {
    return (
       children(orgData)
    )
}

export default WithOrgData(CurrentOrg)