import React from 'react';
import BoardingRestrictionsDrawer from './boarding-restrictions/BoardingRestrictionsDrawer';

class BoardingRestrictionDrawerWrapper extends React.Component {
    state = {
        visible: false,
        paxId: ''
    }
    open = (paxId, drawerComponent) => {
        if (drawerComponent){
            this.setDrawerComponent(drawerComponent)
        }
        this.setState({ paxId, visible: true })
    }
    close = () => {
        this.setState({ visible: false })
    }
    setDrawerComponent = (drawerComponent) => {
        this.setState({ drawerComponent })
    }
    render(){
        const { component: Component, ...rest } = this.props;
        return (
            <React.Fragment>
                <Component {...rest} boardingRestrictionsDrawer={{
                    open: this.open
                }} />
                <BoardingRestrictionsDrawer
                    id={this.state.paxId}
                    visible={this.state.visible}
                    closable
                    onClose={this.close}
                />
            </React.Fragment>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (Component) => {
    return props => (
        <BoardingRestrictionDrawerWrapper {...props} component={Component} />
    )
}