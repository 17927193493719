import React from 'react';
import LoadingContent from './LoadingContent';

const withLoading = (options) => (Component) => props => {
    const dataIndex = (options && options.dataIndex) || 'data';
    if (props[dataIndex] && props[dataIndex].loading){
        let props = options && options.loadingProps;
        return <LoadingContent {...props} />
    }
    return <Component {...props} />
}

export default withLoading