import gql from "graphql-tag";
import { useQuery, QueryResult } from "react-apollo";
import { castArray } from "common/util";

export type UseUserGroupsResult = [Array<string>, QueryResult<Array<string>>]

const QUERY = gql`
{
    userGroups @client
}
`

function useUserGroups(): UseUserGroupsResult {
    const { data, ...rest } = useQuery(QUERY);
    return [
        castArray(data && data.userGroups),
        {
            ...rest,
            data
        }
    ]
}

export default useUserGroups;