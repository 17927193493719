import React from 'react';
import { Auth } from '@aws-amplify/auth';
import NotAuthorized from './NotAuthorized';
// import hoistNonReactStatics from 'hoist-non-react-statics';

const withUserPermissions = (options={}, RenderComponent=NotAuthorized) => {
    const { requiredGroups, renderComponentProps={} } = options;
    return Component => {
        return class UserPermissions extends React.PureComponent {
            state = {
                userGroups: null
            }
            componentDidMount(){
                Auth.currentSession()
                .then(session => {
                    const userGroups = session.idToken.payload['cognito:groups']
                    this.setState({ userGroups })
                })
                .catch(error => console.error('Failed to get user', error))
            }
            render(){
                const { userGroups } = this.state;
                if (!userGroups) return null;
                const authorizedGroups = userGroups.filter(group => {
                    if (Array.isArray(requiredGroups)){
                        return requiredGroups.includes(group)
                    }
                    return group === requiredGroups
                });
                if (authorizedGroups.length){
                    return <Component {...this.props} />
                }else{
                    return <RenderComponent {...renderComponentProps} />
                }
            }
        }
    }
}

export default withUserPermissions