import React, { Component } from 'react';
import '../css/Login.css';
import { Auth } from '@aws-amplify/auth';
import { Input, Button, Form, Icon, Alert } from 'antd';
import mcLogo from '../img/mc-logo-wide-white.svg';

class ForceChangePassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            error: '',
            confirmDirty: false
        }
    }
    componentDidMount = () => {
        var { email } = this.props;
        this.props.form.setFieldsValue({email: email});
    }
    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const { newpassword } = values;
                Auth.completeNewPassword(this.props.user, newpassword)
                    .then(() => this.props.onSuccess && this.props.onSuccess(newpassword))
                    .catch(err => {
                            this.setState({
                                error: err.message
                            })
                        }
                    );
                    this.setState({ loading: false });
            }
        });
    }
    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('newpassword')) {
            callback('Confirm Password and Password are not the same');
        } else {
            callback();
        }
        }
    
    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmpassword'], { force: true });
        }
        callback();
    }
    render(){
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        return (
            <div className="login-page-wrapper">
                <div className="container">
                    <img src={mcLogo} className="mc-logo" alt="Manifest Central Logo"/>
                    <div className="login-box">
                        <h2 style={{fontWeight: 100, marginBottom: '1rem'}}>Create Password</h2>
                        <p>Your account has been set up by a DataFlyt admin. Please enter a new password that you will use for now on.</p>
                        {(this.state.error) ? 
                        <Alert
                            style={{marginBottom: '1rem'}}
                            message="Login error"
                            description={this.state.error}
                            type="error"
                            closable
                            onClose={evt =>
                                this.setState({
                                    error: ''
                                })
                            }
                        /> : ''
                        }
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item {...formItemLayout} label="New Password">
                                {getFieldDecorator('newpassword', {
                                    rules: [
                                        {required: true, message: 'Password is required'},
                                        {
                                            validator: this.validateToNextPassword,
                                        }
                                    ]
                                })(
                                    <Input type="password" size="large" onBlur={this.handleConfirmBlur} />
                                )}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Confirm Password">
                                {getFieldDecorator('confirmpassword', {
                                    rules: [
                                        {required: true, message: 'Confirm password is required'},
                                        {
                                            validator: this.compareToFirstPassword,
                                        }
                                    ]
                                })(
                                    <Input type="password" size="large" />
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" size="large" htmlType="submit" style={{width: '100%'}}>Submit</Button>
                            </Form.Item>
                        </Form>
                        <Button className="mc-link-btn" onClick={evt => this.props.onBack && this.props.onBack()}><Icon type="left" />Back</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form.create()(ForceChangePassword);
