import {State, Dispatcher, Reducer, DEFAULT_STATE, EntityId} from "./state";
import {useEffect, useReducer} from "react";
import {Button, Col, Collapse, Drawer, Icon, Input, List, Row, Typography} from "antd";
import CollapseClassNames from "common/style/collapse";
import { union, difference } from "lodash";
import cn from 'classnames';
import './style.less';
import MCIcon from "components/icon";
import * as Util from './util';
import NoteAssigner from "./note-assigner";
import {BASE_CLS} from "./util";
import {ScheduleNode} from "components/scheduling/types";
import {NotesForPilotEditorApi, useNotesForPilotEditorApi} from "./api";

export interface NotesForPilotEditorProps {

    entities: ScheduleNode[],

    // Enable read-only mode to prevent user edits.
    readOnly?: boolean,

    /**
     * Allows this component to display the NoteAssigner drawer.
     * If you want to control the PaxCgoAdder drawer externally, set this to false.
     * Defaults to true.
     */
    displayNoteAssignerDrawer?: boolean,

    // Allows the internal state to be overwritten with an external state
    overrideState?: {
        state: State,
        dispatch: Dispatcher
    },

    api?: NotesForPilotEditorApi
}

export const NotesForPilotEditor: React.FC<NotesForPilotEditorProps> = (props) => {
    const internalApi = useNotesForPilotEditorApi(props);
    let api = props.api ?? internalApi;

    const notes = api.getNotes();

    function renderEntity(entity: ScheduleNode){

        const name = Util.getEntityName(entity);

        const iconType = Util.getEntityIcon(entity);

        return (
            <List.Item
                key={entity._id}
                extra={
                    !props.readOnly ? (
                        <Button
                            type="danger"
                            ghost
                            icon="delete"
                            size="small"
                        />
                    ) : null
                }
            >
                <div>
                    <MCIcon
                        type={iconType}
                        style={{
                            marginRight: 12
                        }}
                    />
                    {name}
                </div>
            </List.Item>
        )
    }

    function renderNote(noteId: number){

        const message = api.getNoteById(noteId);
        const entities = api.getEntitiesWithNote(noteId);

        const visible = api.visibleNotes.get().includes(noteId);

        let body = (
            <Row type="flex" gutter={12}>
                <Col>
                    {entities.length} PAX/CGO Assigned
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Typography.Text
                        type="secondary"
                        style={{
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            minWidth: 0,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            verticalAlign: 'bottom'
                        }}
                    >
                        {entities.map(Util.getEntityName).join(', ')}
                    </Typography.Text>
                    <span
                        style={{ marginLeft: 6 }}
                    >
                        <Typography.Text
                            type="secondary"
                        >(Click to view names)</Typography.Text>
                    </span>
                </Col>
            </Row>
        );

        if (visible){
            body = (
                <>
                    {body}
                    <List>
                        {entities.map(renderEntity)}
                    </List>
                </>
            )
        }

        return (
            <Collapse.Panel
                key={noteId}
                showArrow={false}
                header={
                    <Row type="flex" gutter={12} align="middle">
                        <Col style={{ flex: 1 }}>
                            {!props.readOnly ? (
                                <Input
                                    placeholder="Type a short pilot note here"
                                    value={message}
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        api.updateNote(noteId, e.target.value);
                                    }}
                                />
                            ) : (
                                <span>{message}</span>
                            )}
                        </Col>
                        {!props.readOnly ? (
                            <Col>
                                <Button
                                    type="danger"
                                    ghost
                                    icon="delete"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        api.removeNote(noteId);
                                    }}
                                />
                            </Col>
                        ) : null}
                    </Row>
                }
            >
                <div
                    onClick={() => api.visibleNotes.toggle(noteId)}
                    className={BASE_CLS + '-collapse-handle'}
                >
                    <Icon
                        className={BASE_CLS + '-collapse-handle-icon'}
                        type={visible ? 'down' : 'right'}
                    />
                    <div className={BASE_CLS + '-collapse-handle-body'}>
                        {body}
                    </div>
                </div>
            </Collapse.Panel>
        )
    }

    const openKeys = Array.from(notes.keys());

    let noteAssigner = null;
    if (props.displayNoteAssignerDrawer){
        noteAssigner = (
            <Drawer
                title="Assign PAX/CGO to notes"
                visible={api.noteAssigner.isOpen()}
                width={500}
                onClose={api.noteAssigner.close}
                bodyStyle={{
                    padding: 0
                }}
                className='ant-drawer-flex'
            >
                <NoteAssigner
                    state={api.state}
                    dispatch={api.dispatch}
                />
            </Drawer>
        )
    }

    return (
        <>
            <Collapse
                className={cn({
                    [CollapseClassNames.Rounded]: true,
                    [BASE_CLS]: true
                })}
                activeKey={openKeys}
            >
                {Array.from(notes.keys()).map(renderNote)}
            </Collapse>
            {noteAssigner}
        </>
    )
}

NotesForPilotEditor.defaultProps = {
    displayNoteAssignerDrawer: true
}