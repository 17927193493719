import React, { useRef } from 'react';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import { MasterDataProps, searchValuesToQuerySearchPayload, MDStateToLayoutProps, renderNoData, MDStateToHeaderProps, MDStateToTableScrollerProps, getMDTableProps, renderCellFromKeyIndex, MDStateToDetailsProps, trimString } from '../util';
import gql from 'graphql-tag';
import { useMasterDataState, MDLayout, MDHeader, TableLoader, MDTable, MDDetails, DuplicationChecker } from '..';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import { Column, Cell } from '@blueprintjs/table';
import { getColWidths } from 'common/table';
import { List, Tooltip, Icon, Button } from 'antd';
import CustomerEntryForm from '../entryForms/customer';
import DeleteEntity from 'Mutations/DeleteEntity';
import { OrgEmailListItem } from 'common/types/org-email-list';
import { removeTypename } from 'common/util';

export interface MDCustomerProps extends WithOrgDataProps, MasterDataProps {

}

const QUERY = gql`
query CustomersQuery($filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $limit: Int, $skip: Int) {
    MasterDataCustomers(filter: $filter, search: $search, limit: $limit, skip: $skip) {
        docs {
            ... on Customer {
                _id
                _rev
                name
                extraBroadCheckRequired
                ebsAirCanisterRequired
                illnessCheckRequired
                quarantinedCheckRequired
                quarantineTravelPeriod
                quarantinedCountries
                askHaveYouBeenVaccinatedQuestion
                notificationEmailList {
                    email
                    options {
                        optInAll
                        optIn
                    }
                }
                steelToeIDDestinations {
                    _id
                    name
                }
            }
        }
    }
}
`

const MUTATION = gql`
mutation CustomerMutation(
        $_id: ID
        $name: String!
        $steelToeIDDestinations: [ID!]!
        $tpID: ID!
        $ebsAirCanisterRequired: Boolean
        $extraBroadCheckRequired: Boolean
        $illnessCheckRequired: Boolean
        $quarantinedCheckRequired: Boolean
        $quarantineTravelPeriod: Int
        $askHaveYouBeenVaccinatedQuestion: Boolean
        $quarantinedCountries: [String]
        $notificationEmailList: [OrgEmailListItemInput!]
    ){
    setCustomer(
        _id: $_id
        name: $name
        steelToeIDDestinations: $steelToeIDDestinations
        tpID: $tpID
        extraBroadCheckRequired: $extraBroadCheckRequired
        ebsAirCanisterRequired: $ebsAirCanisterRequired
        illnessCheckRequired: $illnessCheckRequired
        quarantinedCheckRequired: $quarantinedCheckRequired
        quarantineTravelPeriod: $quarantineTravelPeriod
        quarantinedCountries :$quarantinedCountries
        askHaveYouBeenVaccinatedQuestion: $askHaveYouBeenVaccinatedQuestion
        notificationEmailList: $notificationEmailList
    ){
        _id
        _rev
        name
        extraBroadCheckRequired
        ebsAirCanisterRequired
        illnessCheckRequired
        quarantinedCheckRequired
        quarantineTravelPeriod
        quarantinedCountries
        askHaveYouBeenVaccinatedQuestion
        steelToeIDDestinations {
            _id
            name
        }
    }
}
`

const MDCustomer: React.FC<MDCustomerProps> = (props) => {
    const formRef = useRef(null);
    let tpID = props.orgData.getOrgIDByType('transporter');

    const MDState = useMasterDataState({
        getQueryData: data => data.MasterDataCustomers.docs,
        getQueryVariables: (searchValues) => ({
            search: searchValuesToQuerySearchPayload(searchValues)
        }),
        queryGQL: QUERY,
        saveMutationGQL: MUTATION,
        deleteMutationGQL: DeleteEntity,
        tpID,
        paginationLimit: props.dataPaginationLimit,
        pollInterval: props.pollInterval,
        updateQueryAfterFetchMore: (prev, fetchMoreResult) => ({
            ...prev,
            MasterDataCustomers: {
                ...prev.MasterDataCustomers,
                docs: [
                    ...prev.MasterDataCustomers.docs,
                    ...fetchMoreResult.MasterDataCustomers.docs
                ]
            }
        }),
        transformEditFromExisting: (values) => {
            return {
                ...values,
                steelToeIDDestinations: values.steelToeIDDestinations && values.steelToeIDDestinations.map((item) => item && item._id).filter(id => id)
            }
        }
    })

    const data = MDState.data;

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                name: MDState.getSearchValue('name')
            })
        })}
        headerElement={<MDHeader
            {...MDStateToHeaderProps(MDState)}
            searchInputProps={{
                onChange: e => MDState.onSearchValueChange('name', e.target.value),
                placeholder: 'Search by name',
                value: MDState.getSearchValue('name'),
                style: { width: '30rem' },
                allowClear: true
            }}
        />}
        tableElement={<TableLoader mdState={MDState}>
            <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)}>
                <MDTable
                    {...getMDTableProps(data, MDState)}
                    columnWidths={getColWidths(2, {
                        0: 250,
                        1: 500
                    })}
                    hideDelete
                >
                    <Column name="Name" cellRenderer={renderCellFromKeyIndex(data, 'name')} />
                    <Column name="Steel Toe Destinations" cellRenderer={(idx) => {
                        let record = data[idx];
                        let destinations = record.steelToeIDDestinations;
                        return <Cell>
                            {destinations.map((item: any, idx: number) => {
                                let text = item && item.name;
                                if (!item){
                                    text = <Tooltip title="This location was not found in the database">
                                        <span style={{ opacity: 0.5 }}><Icon type="exclamation-circle" /> Deleted</span>
                                    </Tooltip>
                                }
                            return <span>{text}{idx !== destinations.length - 1 ? ', ' : null}</span>
                            })}
                        </Cell>
                    }} />
                </MDTable>
            </BlueprintTableInfiniteScroller>
        </TableLoader>}
        detailsElement={<MDDetails
            {...MDStateToDetailsProps(MDState, 'name')}
            contentWidth="45rem"
            onSave={() => {
                formRef.current.validateFieldsAndScroll((err, values) => {
                    if (!err){

                        let notificationEmailList: OrgEmailListItem[] = values.notificationEmailList;

                        if (notificationEmailList){
                            // Clean any entries that have blank email fields
                            notificationEmailList = notificationEmailList.filter(item => item.email);
                            notificationEmailList = removeTypename(notificationEmailList);
                        }

                        let saveData = {
                            ...values,
                            name: trimString(values.name),
                            steelToeIDDestinations: values.steelToeIDDestinations || [],
                            quarantinedCountries: values.quarantinedCountries || [],
                            _id: MDState.getEntryFieldValue('_id'),
                            ebsAirCanisterRequired: values.ebsAirCanisterRequired || false,
                            extraBroadCheckRequired: values.extraBroadCheckRequired || false,
                            illnessCheckRequired: values.illnessCheckRequired || false,
                            quarantinedCheckRequired: values.quarantinedCheckRequired || false,
                            askHaveYouBeenVaccinatedQuestion: values.askHaveYouBeenVaccinatedQuestion || false,
                            quarantineTravelPeriod: values.quarantineTravelPeriod || 14,
                            notificationEmailList: notificationEmailList
                        }
                        MDState.save(saveData)
                    }
                })
            }}
        >
            <CustomerEntryForm
                formFields={MDState.entryFields}
                onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                uppercaseFields={['name']}
                autoFocus
                ref={formRef}
                dupeChecker={MDState.isNewEntry ? (
                    <DuplicationChecker
                        skip={
                            !trimString(MDState.getEntryFieldValue('name'))
                        }
                        getData={data => data && data.MasterDataCustomers && data.MasterDataCustomers.docs}
                        query={QUERY}
                        variables={{
                            search: [
                                {
                                    key: 'name',
                                    value: JSON.stringify(trimString(MDState.getEntryFieldValue('name')))
                                }
                            ]
                        }}
                        renderItems={(items) => {
                            return <List size="small">
                            {items.map((customer, idx) => 
                                <List.Item key={idx}>
                                    <Button
                                        className="mc-link-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            MDState.editFromExisting(customer, false);
                                        }}
                                    >
                                        {customer.name}
                                    </Button>
                                </List.Item>)}
                            </List>
                        }}
                    />
                ) : null}
            />
            <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
        </MDDetails>}
    />
}

export default WithOrgData(MDCustomer)