import { Alert, Button, Drawer, message, Modal, Table } from 'antd';
import { CredentialForm } from 'components/CredentialForm';
import { SaveCredential } from 'components/CredentialManager/Mutations';
import credentialTypes from 'credentialTypes';
import gql from 'graphql-tag';
import compose from 'lodash.flowright';
import moment from 'moment';
import React from 'react';
import { graphql, Mutation } from 'react-apollo';
import LoadingContent from '../LoadingContent';
import { withUserGroups } from '../userGroup';
import WithOrgData from '../WithOrgData';
import BoardingRestrictionsOverride from './BoardingRestrictionsOverride';

const PASSENGER = gql`
query Passenger($_id: ID!){
    getPassenger(_id: $_id){
        _id
        lastName
        firstName
        personID {
            _id
        }
        authorization {
            onWhiteList
            brdRestriction_SafetyCard {
                status
                type
            }
            brdRestriction_HuetCard {
                status
                type
            }
            brdRestriction_SteelToes {
                status
                type
            }
            brdRestriction_Twic {
                status
                type
            }
            brdRestriction_NonPreferred {
                status
                type
            }
            brdRestriction_Hazmat {
                status
                type
            }
            brdRestriction_NoFlyListed {
                status
                type
            }
            brdRestriction_EbsCard {
                status
                type
            }
            brdRestriction_Illness {
                status
                type
            }
            brdRestriction_Quarantined {
                status
                type
            }
        }
    }
}
`

const restrictionMap = {
    'brdRestriction_SafetyCard': 'Safety Card',
    'brdRestriction_HuetCard': 'Huet Card',
    'brdRestriction_SteelToes': 'Steel Toes',
    'brdRestriction_Twic': 'TWIC',
    'brdRestriction_NonPreferred': 'Non-Preferred',
    'brdRestriction_Hazmat': 'Hazmat',
    'brdRestriction_NoFlyListed': 'No-Fly Listed',
    'brdRestriction_EbsCard': 'EBS Card',
    'brdRestriction_Illness': 'Illness',
    'brdRestriction_Quarantined': 'Quarantined'
}

class BoardingRestrictionDrawer extends React.Component {
    constructor(props){
        super(props);
        this.credFormRef = React.createRef();
    }
    state = {
        selectedRestriction: null,
        credForm: {
            show: false,
            type: null,
            formFields: {}
        }
    }
    renderOverride = () => {
        const { data: { getPassenger, loading, error }, orgData: { transporter } } = this.props;
        if (!transporter) return null
        if (loading || error) return null
        return (
            <BoardingRestrictionsOverride 
                id={getPassenger._id}
                restriction={this.state.selectedRestriction}
                onClose={() => this.setState({ selectedRestriction: null })} />
        )
    }
    renderContent = () => {
        const { getPassenger, loading, error } = this.props.data;
        const { transporter } = this.props.orgData;
        if (loading) return <LoadingContent />
        if (error) return <Alert type="error" title="Failed to load passenger" description={error.message} showIcon />

        if (!getPassenger.authorization) return 'No Restriction information available';
        const restrictions = Object.entries(restrictionMap).map(r => ({
            name: r[1],
            rType: r[0],
            ...getPassenger.authorization[r[0]]
        }))
        const hasPermission = this.props.userGroups.includes('flytsuite.restriction.override')

        return (
            <Table
                dataSource={restrictions}
                rowKey={(record) => record.rType}
                pagination={false}
                size="middle"
                columns={[
                    {
                        title: 'Restriction',
                        key: 'Restriction',
                        dataIndex: 'name'
                    },
                    {
                        title: 'Status',
                        key: 'status',
                        dataIndex: 'status',
                        render: (status, record) => {
                            const statusMap = {
                                'NOT_APPLICABLE': <span style={{ color: '#9c9c9c' }}>Not Applicable</span>,
                                'MET_REQUIREMENT': <span style={{ color: '#9c9c9c' }}>Met Requirement</span>,
                                'NEEDS_OVERRIDE': (
                                    <div>
                                        <span style={{ color: '#f5222d', marginRight: '1rem' }} >Needs Override</span>
                                        {hasPermission && transporter && <Button type="primary" size="small" onClick={() => this.setState({ selectedRestriction: record.rType })}>Override</Button>}
                                        {record.rType === 'brdRestriction_SafetyCard' ? (
                                        <Button
                                            type="primary"
                                            size="small"
                                            style={{ marginLeft: '0.5rem' }}
                                            onClick={() => this.setState({ credForm: { show: true, type: 'SAFETY_CARD' } })}
                                        >Add Safety Card</Button>
                                    ) : null}
                                    </div>
                                ),
                                'OVERRIDDEN': (
                                    <div>
                                        <span style={{ color: '#1890ff', marginRight: '1rem' }} >Overridden</span>
                                        {hasPermission && transporter && <Button type="primary" size="small" onClick={() => this.setState({ selectedRestriction: record.rType })}>Edit</Button>}
                                    </div>
                                )
                            }
                            return statusMap[status] || '-'
                        }
                    },
                    {
                        title: 'Override Type',
                        key: 'type',
                        dataIndex: 'type',
                        render: type => {
                            const typeMap = {
                                'MANUALLY_VERIFIED': 'Manually Verified',
                                'MANAGEMENT_OVERRIDE': 'Management Override',
                                'CUSTOMER_NOT_ENFORCING': 'Customer Not Enforcing',
                                'DO_NOT_OVERRIDE': 'Do Not Override',
                                'USER_ERROR': 'User Error'
                            }
                            return typeMap[type] || '-'
                        }
                    }
                ]}
            />
        )
    }
    setCredFormVisibility = (val) => this.setState({ credForm: { ...this.state.credForm, show: val } })
    resetCredFormFields = () => {
        this.setState({ credForm: { ...this.state.credForm, formFields: {} } })
    }
    handleCancelCredential = () => {
        this.setState({
            credForm: {
                ...this.state.credForm,
                show: false
            }
        })
    }
    render(){
        if (!this.props.data) return null
        const { getPassenger, loading, error } = this.props.data;
        const { orgData, userGroups, ...restProps } = this.props;
        var title = null;
        if (getPassenger && !error){
            title = `Boarding Restrictions for ${getPassenger.lastName}, ${getPassenger.firstName}`
        }
        else if (error){
            title = 'Error'
        }
        else if (!getPassenger && loading){
            title = 'Loading'
        }
        return (
            <Drawer title={title} width="50rem" {...restProps}>
                {this.renderContent()}
                {this.renderOverride()}
                <Mutation
                    mutation={SaveCredential}
                    onCompleted={() => {
                        message.success('Successfully added ' + credentialTypes[this.state.credForm.type]?.label + ' credential.')
                        this.setCredFormVisibility(false);
                        this.resetCredFormFields();
                        this.props.data.refetch();
                    }}
                    onError={(err) => {
                        console.error('Failed to save safety card: ' + err);
                        message.error('Failed to add credential. An error occurred');
                    }}
                >
                    {(save, { loading }) => (
                        <Modal
                            title="Add Credential"
                            visible={this.state.credForm.show}
                            onCancel={this.handleCancelCredential}
                            afterClose={() => this.resetCredFormFields()}
                            footer={[
                                <Button
                                    key="1"
                                    loading={loading}
                                    type="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.credFormRef.current.validateFieldsAndScroll((err, data) => {
                                            if (!err){
                                                this.setCredFormVisibility(false);
                                                const org = orgData.transporter || orgData.employer;
                                                save({
                                                    variables: {
                                                        key: data.key,
                                                        expiration: moment(data.expiration).format('YYYY-MM-DD'),
                                                        type: data.type,
                                                        tpID: org?._id,
                                                        customerID: orgData.customer?._id,
                                                        personID: getPassenger.personID._id,
                                                        paxID: getPassenger?._id
                                                    }
                                                })
                                                this.resetCredFormFields();
                                            }
                                        })
                                    }}
                                >Add {credentialTypes[this.state.credForm.type]?.label}</Button>,
                                <Button onClick={this.handleCancelCredential} >Cancel</Button>
                            ]}
                        >
                            <CredentialForm
                                lockType={this.state.credForm.type}
                                fields={this.state.credForm.formFields}
                                onChange={(fields) => this.setState({
                                    credForm: {
                                        ...this.state.credForm,
                                        formFields: {
                                            ...this.state.credForm.formFields,
                                            ...fields
                                        }
                                    }
                                })}
                                ref={this.credFormRef}
                            />
                        </Modal>
                    )}
                </Mutation>
            </Drawer>
        )
    }
}

export default compose(
    withUserGroups,
    WithOrgData,
    graphql(
        PASSENGER,
        {
            options: props => ({
                variables: {
                    _id: props.id
                },
                fetchPolicy: 'network-only',
                pollInterval: 5000
            }),
            skip: props => !props.id
        }
    )
)(BoardingRestrictionDrawer)