import { Button, Col, Icon, Popconfirm, Row, Table } from 'antd';
import { createNewLeg, locationListToFlightLegs, paxOnFlightLeg } from 'common/util';
import { LocationSelect } from 'components/form/select/LocationSelect';
import { DraggableBodyRow } from 'components/TableDragSorting';
import React, { useState } from 'react';
import { FlightLeg } from 'schema';

export interface LiveLegsAddProps extends React.HTMLAttributes<HTMLDivElement> {
    forceRefresh?: boolean,
    onForceRefresh?(fr: boolean): void,
    onSave?(ss: FlightLeg[]): void,
    resultLegs?: FlightLeg[],
}

const getLocationsFromLegs = (legs) => {
    var arr = [];

    legs.forEach(leg => {
        arr.push({
            key: leg.departureID,
            label: leg.departure,
            locked: leg.order === 0 ? true : false
        })
    })

    if(legs.length > 0){
        arr.push({
            key: legs[legs.length-1].destinationID,
            label: legs[legs.length-1].destination,
            locked: true
        })
    }
    
    return arr
}

const removeStopFromLeg = (legs, locs, index) => {
    var newLegs = []

    var oldLegs = [...legs]
    //index-1 is destination in Leg, index is Departure in Leg
    var samePax = oldLegs[index-1].paxIDs.filter(pax => oldLegs[index].paxIDs.map(p => p._id).includes(pax._id))
    var sameCgo = oldLegs[index-1].cgoIDs.filter(cgo => oldLegs[index].cgoIDs.map(c => c._id).includes(cgo._id))
    
    //Remove the location from the locs list
    locs.splice(index, 1)

    oldLegs.splice(index, 1)
    oldLegs[index-1] = paxOnFlightLeg(oldLegs[index-1], samePax, sameCgo)
    //Create new legs from those locations
    for(var i=0; i < locs.length-1; i++){
        newLegs.push(createNewLeg(i, locs[i], locs[i+1]))
    }
    newLegs.forEach(leg => {
        leg = {
            ...oldLegs[leg.order],
            destinationID: leg.destinationID,
            departureID: leg.departureID,
            departure: leg.departure,
            destination: leg.destination
        }
    })
    newLegs[index-1] = {
        ...oldLegs[index-1],
        destinationID: newLegs[index-1].destinationID,
        departureID: newLegs[index-1].departureID,
        departure: newLegs[index-1].departure,
        destination: newLegs[index-1].destination
    }

    return newLegs;
}

const addStopToLeg = (legs, locs, index) => {
    var result = []
    var newLegs = []

    var oldLegs = [...legs]
    
    //Create new legs from those locations
    for(var i=0; i < locs.length-1; i++){
        newLegs.push(createNewLeg(i, locs[i], locs[i+1]))
    }
    //Make the legs the same for everything up to index -1, which is always the second to last and last leg.
    newLegs.forEach(leg => {
        if(leg.order < index-1){
            result.push({
                ...oldLegs[leg.order],
                destinationID: leg.destinationID,
                departureID: leg.departureID,
                departure: leg.departure,
                destination: leg.destination
            })
        }
    })
    
    //Set the second to last leg. Basically copy all values except for order, dest, and depart
    result.push({
        ...oldLegs[index-1],
        order: newLegs[index-1].order,
        destinationID: newLegs[index-1].destinationID,
        departureID: newLegs[index-1].departureID,
        departure: newLegs[index-1].departure,
        destination: newLegs[index-1].destination
    })
    
    //Set the last leg. Basically copy all values except for order, dest, and depart
    result.push({
        ...oldLegs[index-1],
        order: newLegs[index].order,
        destinationID: newLegs[index].destinationID,
        departureID: newLegs[index].departureID,
        departure: newLegs[index].departure,
        destination: newLegs[index].destination
    })
    return result;
}

//Run Auto Assign
const moveStop = (legs, locs) => {

    var pax = [];
    var cgo = [];

    legs.forEach(leg => {
        pax.push(...leg.paxIDs.filter(px => !pax.map(p => p._id).includes(px._id)))
        cgo.push(...leg.cgoIDs.filter(cg => !cgo.map(c => c._id).includes(cg._id)))
    })

    var newLegs = locationListToFlightLegs(locs, pax, cgo)

    return newLegs;
}

const LiveLegsAdd: React.FC<LiveLegsAddProps> = (props) => {
    const {
        forceRefresh,
        onForceRefresh,
        resultLegs, 
        onSave,
        ...restProps 
    } = props;
    const [ resultingLegs, setResultingLegs ] = useState<FlightLeg[]>(resultLegs);
    const [resultingLocations, setResultinglocations] = useState<any[]>(getLocationsFromLegs(resultLegs))

    //Get locations from the legs. Use them in the table

    return <div {...restProps} >
        <Row>
            <Row type="flex" justify="center" gutter={20}>
                <Col>
                    Search: <LocationSelect onChange={value =>{
                        if(value){
                            value.locked = false;
                            var arr = [...resultingLocations]
                            arr.splice(arr.length-1, 0, value)
                            setResultinglocations(arr)
                            setResultingLegs(addStopToLeg(resultingLegs, arr, arr.length-2))
                        }
                    }} labelInValue style={{minWidth: '10rem'}}/>
                </Col>
                <Col>
                    <Button onClick={e => {
                        setResultingLegs(resultLegs)
                        setResultinglocations(getLocationsFromLegs(resultLegs))
                    }}>Reset</Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={e => {
                        onSave(resultingLegs)
                    }}>Save</Button>
                </Col>
            </Row>
        </Row>
        <Row gutter={20}>
            <Col span={8}>
                Resulting Legs
                <Table
                    dataSource={resultingLegs} 
                    columns={[{
                        title: '#',
                        key: '#',
                        render: (_, __, index) => index + 1,
                        width: 25
                    },{
                        title: "Departure",
                        key: "departure",
                        dataIndex: "departure"
                    },{
                        title: "Destination",
                        key: "destination",
                        dataIndex: "destination"
                    },{
                        title: "Pax #",
                        key: "pax",
                        dataIndex: "paxCount"
                    },{
                        title: "Cgo #",
                        key: "cgo",
                        dataIndex: "cgoCount"
                    }]} 
                    rowKey={(_, index) => String(index)} 
                    size="small" 
                    pagination={false}
                    />
            </Col>
            <Col span={12}>
                Travel Locations
                <Table
                    className="draggable-table"
                    dataSource={resultingLocations} 
                    columns={[{
                        title: '',
                        key: 'actions',
                        width: 15,
                        render: (rec, __, index) => {
                            if(!rec.locked){
                                return <Popconfirm title="Are you sure you want to remove this location?" onConfirm={e => {
                                    setResultingLegs(removeStopFromLeg(resultingLegs, resultingLocations, resultingLocations.indexOf(rec)))
                                }}>
                                    <Button type="danger" style={{ marginLeft: 12 }}>{<Icon type="delete" />}</Button>
                                </Popconfirm>
                            }
                            return null
                        }
                    },{
                        title: '#',
                        key: 'key',
                        width: '5em',
                        render: (_, __, index) => {
                            var ret;
                            if(index === 0){
                                ret = 'Depart'
                            }else {
                                ret = 'Stop ' + index
                            }
                            return ret
                        },
                    },{
                        title: "Name",
                        key: "name",
                        dataIndex: "label"
                    }]} 
                    rowKey={(_, index) => String(index)} 
                    size="small" 
                    pagination={false}
                    components={{
                        body: {
                            row: DraggableBodyRow
                        }
                    }}
                    onRow={(row, index) => {
                        const rowProps = {
                            moveRow: (dragIndex, hoverIndex) => {
                                var arr = resultingLocations
                                if(!arr[dragIndex].locked){
                                    if(arr[hoverIndex].locked){
                                        return;
                                    }
                                    arr.splice(hoverIndex, 0, arr.splice(dragIndex, 1)[0]);
                                    setResultinglocations(arr)
                                    setResultingLegs(moveStop(resultingLegs, arr))
                                }
                            },
                            index
                        }
                        return { ...rowProps}
                    }}/>
            </Col>
        </Row>
    </div>
}

export default LiveLegsAdd