import React from 'react';
import { QueryResult, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { WatchQueryFetchPolicy } from 'apollo-boost';

interface Result extends QueryResult {
    data: any[]
}

export interface QueryPersonnelIDsProps {
    ids: string[],
    children(result: Result): JSX.Element,
    fetchPolicy?: WatchQueryFetchPolicy
}

const QUERY = gql`
query ResolvePersonnelIDs($ids: [ID!]!){
    resolve_entity_ids(ids: $ids, typename: "Person"){
        ... on Person {
            _id
            lastName
            firstName
            classType
            extraBroadState
            employerID {
                _id
                name
            }
            customerID {
                _id
                name
            }
            nation
        }
    }
}
`

const QueryPersonnelIDs: React.FC<QueryPersonnelIDsProps> = (props) => {
    return <Query
        query={QUERY}
        variables={{ ids: props.ids }}
        fetchPolicy={props.fetchPolicy || "network-only"}
        onError={(error: Error) => console.error(error)}
    >
    {(result: QueryResult) => {
        if (!Array.isArray(result.data)){
            var data: any[] = [];
        }
        else
        {
            data = result.data;
        }
        if (result.data && result.data.resolve_entity_ids){
            data = result.data.resolve_entity_ids;
        }
        return props.children({
            ...result,
            data: data
        })
    }}
    </Query>
}

export default QueryPersonnelIDs