import React from 'react';
import { Input, Tooltip, Button } from 'antd';

const inputStyle = {
    width: '8rem'
}

function InputName(props){
    let values = props.value || {};
    if (values === null){
        values = {};
    }
    const handleChange = (key) => (e) => {
        if (typeof props.onChange === 'function'){
            props.onChange({
                firstName: values.firstName,
                lastName: values.lastName,
                [key]: e.target.value
            })
        }
    }
    const clear = () => {
        if (typeof props.onChange !== 'function') return;
        props.onChange({
            firstName: undefined,
            lastName: undefined
        })
    }
    return <Input.Group compact>
        <Input placeholder="First Name" value={values.firstName} onChange={handleChange('firstName')} style={inputStyle} />
        <Input placeholder="Last Name" value={values.lastName} onChange={handleChange('lastName')} style={inputStyle} />
        {values && (values.firstName || values.lastName) ? (
            <Tooltip title="Clear">
                <Button onClick={clear} icon="close" />
            </Tooltip>
        ) : null}
    </Input.Group>
}

export default InputName