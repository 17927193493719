import React from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';

const ButtonLink = (props) => {
    const { children, to, staticContext, ...rest } = props;
    return (
        <Button {...rest} onClick={() => props.history.push(to)}>
            {children}
        </Button>
    )
}

export default withRouter(ButtonLink)