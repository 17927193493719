import React, { useRef, useState } from 'react';
import GetCustomerManifestPrintout from './query/GetCustomerManifestPrintout';
import { Query } from 'react-apollo';
import { safeGet } from 'common/util';
import { Button, Modal, Alert } from 'antd';
import PrintoutForm from '../Printout/PrintoutForm';
import useManifestPrintout from '../Printout/useManifestPrintout';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import withCognitoUser, { WithCognitoUserProps } from 'components/WithCognitoUser';
import { GroupData } from '.';
import { ButtonProps } from 'antd/lib/button';

export interface ManifestPrintoutProps extends WithOrgDataProps, WithCognitoUserProps {
    customerID: string,
    paxData: any[],
    cgoData: any[],
    departureName: string,
    destinationName: string,
    groupData: GroupData,
    buttonProps?: ButtonProps
}

const ManifestPrintout: React.FC<ManifestPrintoutProps> = (props) => {
    const printoutForm = useRef(null);

    const [ fetchPrintout, printoutLoading, printoutError, clearPrintoutError ] = useManifestPrintout('BasicFlight', 'Hess');

    const [ printoutFormFields, setPrintoutFormFields ] = useState({
        flightName: null,
        aircraft: null
    });

    const [ modalVisible, setModalVisible ] = useState<boolean>();

    return <Query
        query={GetCustomerManifestPrintout}
        variables={{ id: props.customerID }}
    >
    {({ data, loading, error }: any) => {
        const manifestPrintouts = safeGet(['getCustomer', 'manifestPrintout'], data) || [];
        const flightManifest = manifestPrintouts.find((m) => m.type === 'Flight'); 
        const hessLayout = flightManifest && flightManifest.layouts.find((l) => l.name === 'Hess');
        const logoURL = hessLayout && hessLayout.logo;
        if (props.buttonProps && props.buttonProps.disabled){
            var buttonDisabled = props.buttonProps && props.buttonProps.disabled;
        }
        else
        {
            buttonDisabled = (!Array.isArray(props.paxData)) || (loading && error && !data && !data.getCustomer)
        }
        return (
            <React.Fragment>
                <Button
                    {...props.buttonProps}
                    disabled={buttonDisabled}
                    onClick={() => setModalVisible(true)}
                >Download Printout</Button>
                <Modal
                    title="Download Printout"
                    visible={modalVisible}
                    onCancel={() => {
                        setModalVisible(false);
                        // setPrintoutFormFields(null);
                        clearPrintoutError();
                    }}
                    // cancelButtonDisabled={printoutLoading}
                    cancelButtonProps={{
                        disabled: printoutLoading
                    }}
                    onOk={() => {
                        printoutForm.current.validateFieldsAndScroll((err: any, values: { flightName: any; aircraft: { label: any; }; }) => {
                            const { customer } = props.orgData;
                            const { attributes: userAttr={} } = props.cognitoUser;
                            const payload = {
                                orgName: customer.name,
                                logoURL: logoURL,
                                desig: values.flightName,
                                issuedBy: `${userAttr.given_name} ${userAttr.family_name}`,
                                departure: props.departureName,
                                destination: props.destinationName,
                                aircraftNo: values.aircraft.label,
                                scheduledFlightDate: props.groupData.date,
                                paxList: props.paxData.map((pax) => {
                                    return {
                                        _id: pax._id,
                                        lastName: pax.lastName,
                                        firstName: pax.firstName,
                                        employer: pax.employerID && pax.employerID.name,
                                        chargeCode: pax.chargeCode && pax.chargeCode.toUpperCase(),
                                        paxWeight: pax.paxWeight,
                                        bagWeight: pax.bagWeight,
                                        transitType: pax.transitType,
                                        departure: pax.departureID && pax.departureID.name,
                                        destination: pax.destinationID && pax.destinationID.name,
                                        dob: pax.personID && pax.personID.dob,
                                        nation: pax.personID && pax.personID.nation,
                                        credentials: pax.personID && pax.personID.credentials
                                    }
                                }),
                                cgoList: props.cgoData.map((cgo) => {
                                    return {
                                        _id: cgo._id,
                                        name: cgo.name,
                                        weight: cgo.weight,
                                        departure: cgo.departureID && cgo.departureID.name,
                                        destination: cgo.destinationID && cgo.destinationID.name,
                                        attentionTo: cgo.attentionTo,
                                        deliveredBy: cgo.deliveredBy,
                                        transitType: cgo.transitType
                                    }
                                })
                            }
                            if (!err){
                                fetchPrintout(payload, true, `${customer && customer.name} Flight Manifest.xlsx`)
                                .then(() => {
                                    setModalVisible(false);
                                    // setPrintoutFormFields(null);
                                })
                            }
                        })
                    }}
                    okText="Download"
                    confirmLoading={printoutLoading}
                >
                    {printoutError ? (
                        <Alert
                            style={{ marginBottom: 12 }}
                            type="error"
                            showIcon
                            message="Failed to download printout"
                            description={printoutError.message}
                            closable
                            onClose={() => clearPrintoutError()}
                        />
                    ) : null}
                    <PrintoutForm
                        ref={printoutForm}
                        {...printoutFormFields}
                        onChange={(changedFields) => setPrintoutFormFields({ ...printoutFormFields, ...changedFields })}
                    />
                </Modal>
            </React.Fragment>
        )
    }}
    </Query>
}

export default WithOrgData<ManifestPrintoutProps>(
    withCognitoUser<ManifestPrintoutProps>(
    ManifestPrintout
    )
)