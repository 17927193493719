import React from 'react';
import { Row, Col, Layout, Button, Icon, Alert, Spin, Form, Select, DatePicker, Tooltip, Input } from 'antd';
import moment from 'moment';
import { Query, withApollo } from 'react-apollo';
import withCustomer from '../../WithCustomer';
import withTransporter from '../../WithTransporter';

const { Header, Sider, Content } = Layout;
const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;

const siderStyle = {
    borderStyle: 'solid',
    borderWidth: '0 1px',
    borderColor: '#ededed',
    background: '#ffffff',
    overflow: 'auto'
}

const HeaderRowLayout = {
    type: 'flex',
    style: {
        alignItems: 'center'
    }
}

export const formItemLayout = {
    style: {
        marginBottom: 0
    },
};

const dateFormat = 'MM/DD/YYYY';

export class ReportLayout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            siderHidden: false
        }
    }
    componentDidMount() {
        const { siderHidden } = this.props;
        if (siderHidden) {
            this.setState({ siderHidden });
        }
    }
    toggleSider = () => {
        this.setState({ siderHidden: !this.state.siderHidden })
    }
    render(){
        const { title, renderSiderContent, renderReport } = this.props;
        const { siderHidden } = this.state;
        return <Layout style={{margin: -24, height: 'calc(100% + 48px)'}}>
            <Header style={{ background: '#fff', padding: '0 1rem', border: '1px solid #ededed', borderWidth: '0 0 1px 1px'}}>
                <Row type="flex" style={{alignItems: 'center'}}>
                    <Col span={8}>
                        <Row {...HeaderRowLayout}>
                        <Button onClick={this.toggleSider}>
                            <Icon
                                className="trigger"
                                type={siderHidden ? 'menu-unfold' : 'menu-fold'}
                                />
                            <span>{siderHidden ? 'Show Form' : 'Hide Form'}</span>
                        </Button>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row {...HeaderRowLayout} justify="center">
                            <h3 style={{margin: 0}}>{title}</h3>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row {...HeaderRowLayout} justify="end" gutter={8}>
                            {/* {print && <Col>
                                <Button disabled>
                                    <Icon type="printer" /> Print Report
                                </Button>
                            </Col>}
                            {csv && <Col>
                                <Button disabled>
                                    <Icon type="file-excel" /> Export to Excel
                                </Button>
                            </Col>} */}
                        </Row>
                    </Col>
                </Row>

            </Header>
            <Layout>
                <Sider style={{...siderStyle, display: (siderHidden) ? 'none' : 'block'}} width={350} trigger={null}>
                    <Content style={{padding: '1rem', backgroundColor: '#ffffff'}}>
                        {renderSiderContent && renderSiderContent()}
                    </Content>
                </Sider>    
                <Content style={{padding: '1rem', overflow: 'auto', background: 'white'}}>
                    {renderReport && renderReport()}
                </Content>
            </Layout>
        </Layout>
    }
}

export const ReportLoading = () => (
    <span><Spin style={{marginRight: '1rem'}} />Generating Report...</span>
)

export const ReportNoData = () => 'No data returned from your criteria'

export const ReportError = ({error}) => (
    <Alert type="error" message="An error occurred while generating the report" description={error.message} closable showIcon />
)

export const CustomerFormItem = ({ form: { getFieldDecorator }, customerID, customerList }) => (
<FormItem {...formItemLayout} label="Customer">
    {getFieldDecorator('customerID', {
        initialValue: customerID || customerList[0]._id,
        rules: [
            {
                required: true, message: 'Customer is required'
            }
        ]
    })(
        <Select showAction={["focus", "click"]}>
            {customerList.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
        </Select>
    )}
</FormItem>
)
export class DateRangeFormItem extends React.Component {
    componentDidMount(){
        this.initialize()
    }
    componentDidUpdate(){
        if (!this.props.form.getFieldValue('daterange')){
            this.initialize()
        }
        
    }
    initialize = () => {
        this.props.form.setFieldsValue({
            daterange: [moment().startOf('month'), moment()]
        })
    }
    render(){
        const { form: {getFieldDecorator}, includeReset = true } = this.props;
        return (
        <FormItem {...formItemLayout} label="Date Range">
            <div style={{display: 'flex'}}>
                {getFieldDecorator('daterange', {
                    rules: [
                        {type: 'array', required: true, message: "Date range is required"}
                    ]
                })(
                    <RangePicker disabledDate={current => current && current > moment().endOf('day').add(1, 'day')} format={dateFormat}/>
                )} 
                {includeReset && <Tooltip title="Reset to beginning of month to today" >
                    <Button onClick={this.initialize} style={{marginLeft: '0.25rem'}}><Icon type="clock-circle" /></Button>
                </Tooltip>}
            </div>
        </FormItem>
        )
    }
}
export class TimeRangeFormItem extends React.Component {
    componentDidMount(){
        this.initialize()
    }
    componentDidUpdate(){
        if (!this.props.form.getFieldValue('timerange')){
            this.initialize()
        }
        
    }
    initialize = () => {
        this.props.form.setFieldsValue({
            timerange: [moment().startOf('month'), moment()]
        })
    }
    render(){
        const { form: {getFieldDecorator}, includeReset = true } = this.props;
        return (
        <FormItem {...formItemLayout} label="Time Range">
            <div style={{display: 'flex'}}>
                {getFieldDecorator('timerange', {
                    rules: [
                        {type: 'array', required: true, message: "Time range is required"}
                    ]
                })(
                    <RangePicker showTime disabledDate={current => current && current > moment().endOf('day').add(1, 'day')} format="YYYY-MM-DD HH:mm:ss"/>
                )} 
                {includeReset && <Tooltip title="Reset to beginning of month to today" >
                    <Button onClick={this.initialize} style={{marginLeft: '0.25rem'}}><Icon type="clock-circle" /></Button>
                </Tooltip>}
            </div>
        </FormItem>
        )
    }
}
export const GenerateReportFormItem = ({ resetReport }) => (
<Form.Item style={{marginTop: '0.5rem'}}>
    <Button type="primary" htmlType="submit">Generate Report</Button>
    <Button style={{marginLeft: '1rem'}} onClick={() => resetReport()}>Reset</Button>
</Form.Item>
)

const selectProps = {
    showSearch: true,
    optionFilterProp: "children",
    filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const DepartureFormItem = ({ form: { getFieldDecorator }, departureList }) => (
<FormItem {...formItemLayout} label="Base">
    {getFieldDecorator('departureID', {
        initialValue: ""
    })(
        <Select showAction={["focus", "click"]} {...selectProps}>
            <Option value="">Choose a base</Option>
            {departureList.map(({ _id, name }) => <Option key={_id} value={_id}>{name}</Option>)}
        </Select>
    )}
</FormItem>
)

export const DestinationFormItem = ({ form: { getFieldDecorator }, destinationList }) => (
    <FormItem {...formItemLayout} label="Destination">
        {getFieldDecorator('destinationID', {
            initialValue: ""
        })(
            <Select showAction={["focus", "click"]} {...selectProps}>
                <Option value="">Choose a destination</Option>
                {destinationList.map(({ _id, name }) => <Option key={_id} value={_id}>{name}</Option>)}
            </Select>
        )}
    </FormItem>
)

export const EmployerFormItem = ({ form: { getFieldDecorator }, employerList }) => (
    <FormItem {...formItemLayout} label="Employer">
        {getFieldDecorator('employerID', {
            initialValue: ""
        })(
            <Select showAction={["focus", "click"]} {...selectProps}>
                <Option value="">Choose an employer</Option>
                {employerList.map(({ _id, name }) => <Option key={_id} value={_id}>{name}</Option>)}
            </Select>
        )}
    </FormItem>
)

export const FinalizeFormItem = ({ form: { getFieldDecorator }, label="Finalized", fieldName="finalized" }) => (
    <FormItem {...formItemLayout} label={label}>
        {getFieldDecorator(fieldName, {
            initialValue: 'FINALIZED'
        })(
            <Select showAction={["focus", "click"]}>
                {/* <Option value="ANY">Any</Option> */}
                <Option value="FINALIZED">Finalized</Option>
                <Option value="NOT_FINALIZED">Not Finalized</Option>
            </Select>
        )}
    </FormItem>
)

export const PaxNameFormItem = ({ form: { getFieldDecorator } }) => (
    <FormItem {...formItemLayout} label="Passenger Name">
        {getFieldDecorator('paxname', {
            initialValue: ""
        })(
            <Input />
        )}
    </FormItem>
)

export const CgoNameFormItem = ({ form: { getFieldDecorator } }) => (
    <FormItem {...formItemLayout} label="Cargo Name">
        {getFieldDecorator('cgoname', {
            initialValue: ""
        })(
            <Input />
        )}
    </FormItem>
)

export const ReportResult = ({header: { title, dateRange, sortedBy }, render}) => (
    <div>
        <Row type="flex" style={{alignItems: 'baseline', marginBottom: '1rem'}}>
            <Col span="12"><h2 style={{margin: 0}}>{title}</h2></Col>
            <Col span="12">
                {dateRange &&
                <Row gutter={12} type="flex" justify="end">
                    {sortedBy && <Col>Sorted By: {sortedBy}</Col>}
                    <Col>From {moment(dateRange.start).format('YYYY-MM-DD')} to {moment(dateRange.end).format('YYYY-MM-DD')}</Col>
                </Row>
                }
            </Col>
        </Row>
        {render}
    </div>
)

class BaseReport extends React.Component {
    constructor(props){
        super(props);
        this.state = this.getInitialState()
    }
    getInitialState = () => ({
        report: null
    })
    componentDidMount(){
        this.initializeDateRange()
    } 
    initializeDateRange = () => {
        this.props.form.setFieldsValue({
            daterange: [moment().startOf('month'), moment()]
        })
    }
    query = async (variables) => {
        const { reportQuery } = this.props;
        if (reportQuery){
            const { data, loading, error } = await this.props.client.query({
                query: reportQuery,
                variables,
                fetchPolicy: 'network-only'
            })
            this.setState({ report: {data, loading, error, variables} });
        }
    }
    renderReport = () => {
        const { renderReport } = this.props;
        const { report } = this.state;
        if (report){
            const { data, loading, error } = this.state.report;
            try{
                if (loading) return 'Generating Report...'
                if (error) throw Error(error)
                if (!data) return null 
                const report = renderReport({data})
                if (report) return report
                return 'No Data'
            }
            catch(error){
                console.error(error)
                return <Alert type="error" message="An error occurred while generating the report" description={error.message} closable showIcon />
            }
        }
        return null
    }
    resetReport = () => {
        this.setState(this.getInitialState());
        this.props.form.resetFields()
        this.initializeDateRange()
    }
    renderSiderContent = () => {
        const { customer, transporter, form: { getFieldDecorator }, formQuery: { query, variables }, renderSiderContent } = this.props;
        if (!query) return null
        return (
        <Query query={query} variables={{ tpID: transporter._id, customerID: customer._id, ...variables }}>
            {({ data, loading, error }) => {
                try{
                    if (loading) return <Spin />
                    if (error) throw Error(error)
                    return (
                        <React.Fragment>
                            <Form onSubmit={(e) => this.props.generateReport(e, this.props.form, this.query)}>
                                <FormItem {...formItemLayout} label="Customer">
                                    {getFieldDecorator('customerID', {
                                        initialValue: customer._id,
                                        rules: [
                                            {
                                                required: true, message: 'Customer is required'
                                            }
                                        ]
                                    })(
                                        <Select showAction={["focus", "click"]}>
                                            <Option value={customer._id}>{customer.name}</Option>
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="Date Range">
                                    {getFieldDecorator('daterange', {
                                        rules: [
                                            {type: 'array', required: true, message: "Date range is required"}
                                        ]
                                    })(
                                        <RangePicker disabledDate={current => current && current > moment().endOf('day').add(1, 'day')} format={dateFormat}/>
                                    )}  
                                </FormItem>
                                {renderSiderContent && renderSiderContent(data, getFieldDecorator, formItemLayout)}
                                <Form.Item style={{marginTop: '0.5rem'}}>
                                    <Button type="primary" htmlType="submit">Generate Report</Button>
                                    <Button style={{marginLeft: '1rem'}} onClick={() => this.resetReport()}>Reset</Button>
                                </Form.Item>
                            </Form>
                        </React.Fragment>
                    )
                }
                catch(error){
                    console.error(error)
                    return <Alert type="error" message="An error occurred while creating the form" description={error.message} closable showIcon />
                }
            }}
        </Query>
    )}
    render(){
        const { title } = this.props;
        return (
            <ReportLayout title={title} renderSiderContent={this.renderSiderContent} renderReport={this.renderReport} />
        )
    }
}

export default withApollo(withCustomer(withTransporter(Form.create()(BaseReport))))