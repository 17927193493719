import { Empty, Layout, Tabs, Alert, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ReportForm, ReportFormSubmitter } from '../ReportForm';
import { ReportListWithData } from '../ReportList';
import { getReportForm, getFieldFormmatter } from '../ReportForms';
import { ReportDetailsWithData } from '../ReportDetails';
import { renderSummary } from '../summaries';
import { renderDetail } from '../detail';
import { renderExport } from '../export';
import './ReportingInterface.less';

class ReportingInterface extends Component {
    state = {
        selectedReport: {
            id: null,
            name: null
        },
        activeTab: 'new'
    }
    static propTypes = {
        title: PropTypes.any,
        type: PropTypes.oneOf(['MANIFEST', 'PAX', 'CGO', 'COVID', 'PAX_COVID19_VAX', 'PERSON_COVID19_VAX_DETAILS'])
    }  
    getActiveTab = () => {
        if (this.props.activeTab){
            return this.props.activeTab
        }
        return this.state.activeTab
    }

    setActiveTab = (activeTab) => {
        this.setState({ activeTab });
        if (this.props.onTabChange)
            this.props.onTabChange(activeTab);
    }

    getSelectedReport = () => {
        if (this.props.selectedReport){
            return this.props.selectedReport
        }
        return this.state.selectedReport
    }

    setSelectedReport = (id, name) => {
        this.setState({ selectedReport: { id, name } });
        if (this.props.onSelectedReportChange){
            this.props.onSelectedReportChange(id, name);
        }
    }

    render() {
        const { title, type, description, onManifestExportClick} = this.props;
        return (
            <Layout style={{margin: -24, height: 'calc(100% + 48px)'}}>
                {!this.state.selectedReport.id ? (
                    <React.Fragment>
                    <Layout.Header className="reporting-interface-header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                            <h2 style={{ margin: 0 }}>{title || 'Report'}</h2>
                            {description ? (
                                <span style={{ marginLeft: '1rem' }}>
                                    <Typography.Text type="secondary">{description}</Typography.Text>
                                </span>
                            ) : null}
                    </Layout.Header>
                    <Layout.Content className="reporting-interface-content">
                        <Tabs
                            activeKey={this.getActiveTab()}
                            type="card"
                            onChange={this.setActiveTab}
                        >
                            <Tabs.TabPane key="new" tab="Run Report" >
                                <ReportFormSubmitter
                                    type={type}
                                    onCompleted={({ id, name }) => {
                                        this.setSelectedReport(id, name);
                                    }}
                                >
                                {(submitReport, { loading, error }) => (
                                    <div>
                                        {error ? <Alert style={{ marginBottom: 12 }} type="error" message="Failed to submit report" /> : null}
                                        <ReportForm
                                            onSubmit={submitReport}
                                            submitting={loading}
                                            renderFormItems={getReportForm(type)}
                                            fieldFormatter={getFieldFormmatter(type)}
                                        />
                                    </div>
                                )}
                                </ReportFormSubmitter>
                            </Tabs.TabPane>
                            <Tabs.TabPane key="history" tab="Report History">
                                <ReportListWithData
                                    type={type}
                                    onItemClick={this.setSelectedReport}
                                    renderEmpty={
                                        <Empty description="You have not created any reports yet" />
                                    }
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Layout.Content>
                    </React.Fragment>
                ) : (
                    <ReportDetailsWithData
                        onBackClicked={() => this.setState({ selectedReport: null, selectedReportName: null })}
                        id={this.state.selectedReport.id}
                        style={{ height: '100%'}}
                        renderSummary={(data) => data && renderSummary(type, data)}
                        renderDetail={(data) => data && renderDetail(type, data, onManifestExportClick)}
                        renderExport={(reportId) => reportId && renderExport(type, reportId)}
                    />
                )}
            </Layout>
        )
    }
}

export default ReportingInterface