import { Auth } from '@aws-amplify/auth';
import { Component } from 'react';
import { withRouter } from "react-router-dom";
import '../css/App.css';

class Home extends Component {
  componentDidMount() {
    Auth.currentAuthenticatedUser()
    .then(user => this.props.history.push('/app'))
    .catch(err => this.props.history.push('/login'))
  }
  render = () => null;
}

export default withRouter(Home);
