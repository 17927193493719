import { Descriptions, Switch, Tooltip, Typography } from 'antd';
import { ThemeContext } from 'context/theme';
import React, { useContext } from 'react';

const { Text: T } = Typography;

const ShortcutDescriptions: React.FC<{}> = () => {
    const { themeName, changeTheme } = useContext(ThemeContext);

    return <Descriptions title="CONTROLS" bordered size='middle' column={1}>
        <Descriptions.Item label="Adjust zoom">
            Press <T code>Ctrl and +</T> to zoom in or <T code>Ctrl and -</T> to zoom out.
        </Descriptions.Item>
        <Descriptions.Item label="Adjust scroll speed">
            Press <T code>+</T> to increase scroll speed or <T code>-</T> to decrease scroll speed.
        </Descriptions.Item>
        <Descriptions.Item label="Toggle automatic scrolling">
            Press <T code>S</T> to toggle automatic scrolling (on by default)
        </Descriptions.Item>
        <Descriptions.Item label="Toggle fullscreen">
            Press <T code>F11</T> to toggle fullscreen
        </Descriptions.Item>
        <Descriptions.Item label="Toggle dark theme">
            <div>
                <span>Press <T code>D</T> to toggle the dark theme</span>
                <Tooltip title={themeName === 'dark' ? 'Switch to light theme' : 'Switch to dark theme'}>
                    <Switch
                        checked={themeName === 'dark'}
                        onChange={(checked) => changeTheme(checked ? 'dark' : 'light')}
                        style={{ float: 'right' }}
                    />
                </Tooltip>
            </div>
        </Descriptions.Item>
    </Descriptions>
}

export default ShortcutDescriptions