import {default as useInfiniteScrollLib, UseInfiniteScrollHookArgs as LibArgs} from "react-infinite-scroll-hook";
import {Dispatch, useEffect, useReducer} from "react";

export type LoadArgs = Pick<LibArgs, 'loading' | 'hasNextPage' | 'onLoadMore' | 'disabled'>;

export type SideEffect = {
    /**
     * When these variables change, callback will be called.
     */
    deps: any[],

    /**
     * Executes when any value in deps changes.
     */
    callback: () => void
}

export interface InfiniteScrollAdapter<S=object, A=object> {
    /**
     * Initial state of the state machine
     */
    initState: S,

    /**
     * Implements state transitions
     * @param prevState Previous state.
     * @param action Action to be performed to change the state.
     */
    reducer: (prevState: S, action: A) => S,

    /**
     * This is where the pagination is actually implemented. Returns arguments for the react-infinite-scroll-hook
     * to read.
     * @param state Current state of the state machine.
     * @param dispatcher Dispatch actions for the state machine.
     */
    getLoadArgs: (state: S, dispatcher: Dispatch<A>) => LoadArgs,

    /**
     * Execute side effects when the query or state changes. Works much like useEffect.
     */
    sideEffect?: SideEffect
}

export interface UseInfiniteScrollHookArgs extends Pick<LibArgs, 'disabled' | 'delayInMs' | 'rootMargin'> {
    adapterImpl: InfiniteScrollAdapter
}

/**
 * Wrapper for react-infinite-scroll-hook library. Uses "adapters" to implement various pagination systems.
 * @param adapterImpl Concrete implementation of InfiniteScrollAdapter class.
 * @param rest The rest of the props are sent to the react-infinite-scroll-hook library.
 * @example
 * const mangoAdapter = new CouchdbMangoAdapter({
 *
 *     // Pass the query used for the useQuery hook. Will be used for updating the cache later.
 *     query: FINALIZED_FLIGHTS,
 *
 *     // Retrieves the actual MangoQueryResult structure from the query result.
 *     getMangoQueryResult: (data) => data?.getFinalizedFlights,
 *
 *     // queryResult from useQuery hook.
 *     queryResult: queryResult
 * });
 *
 * const [ sentryRef ] = useInfiniteScroll({ adapterImpl: mangoAdapter });
 */
export function useInfiniteScroll({ adapterImpl, ...rest }: UseInfiniteScrollHookArgs){

    const [ state, dispatch ] = useReducer(adapterImpl.reducer, adapterImpl.initState);

    const loadArgs = adapterImpl.getLoadArgs(state, dispatch);

    useEffect(() => {
        adapterImpl.sideEffect?.callback();
    }, adapterImpl.sideEffect?.deps);

    const [ sentryRef ] = useInfiniteScrollLib({
        ...rest,
        disabled: rest.disabled || loadArgs.disabled,
        loading: loadArgs.loading,
        hasNextPage: loadArgs.hasNextPage,
        onLoadMore: loadArgs.onLoadMore
    });

    return {
        sentryRef: sentryRef,
        loading: loadArgs.loading,
        hasNextPage: loadArgs.hasNextPage
    }
}