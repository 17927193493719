import {ColumnConfig, exportToFile, resolveColumnValue} from "./index";

export interface CsvConfig {
    columns: ColumnConfig[]
    delimiter?: string
}

/**
 * Converts array of objects to a CSV string
 * @param data List of objects
 * @param config Set up column configurations
 */
function csvConverter<T>(data: T[], config: CsvConfig) {

    const { columns } = config;

    const delimiter = config?.delimiter || '\t';

    let csvRows = [];
    csvRows.push("sep=" + delimiter);

    // Write header row
    const names = columns.map(config => config.name);
    csvRows.push(names.join(delimiter));

    function processRow(row: T, rowIndex: number){

        let csvRow = [];

        for (let colConfig of config.columns){
            const value = resolveColumnValue(row, colConfig, rowIndex);
            csvRow.push(value);
        }

        csvRows.push(csvRow.join(delimiter));
    }

    // Build data rows
    for (let i = 0; i < data.length; i++) {
        processRow(data[i], i);
    }

    // Returning the array joining with new line
    return csvRows.join('\n');
}

/**
 * Exports an array of objects into a CSV file and downloads it in the browser.
 * @param data Array of objects
 * @param filename Filename without extension (.csv will be added)
 * @param config Set up column configurations
 */
export function exportDataAsCsv<T>(data: T[], filename: string, config: CsvConfig){
    let convertedCsv = csvConverter(data, config);

    filename = filename.replace('.csv', '');
    filename = filename + '.csv';

    exportToFile(convertedCsv, filename, 'text/csv');
}