import React, { Component } from 'react';
import { Card, Spin, Alert } from 'antd';
import EditControl from './EditControl';
import PropTypes from 'prop-types';
import EditForm from './EditForm';
import moment from 'moment';

const CredItem = ({ label, value }) => (
    <div>
        <strong>{label}:</strong> <span>{value}</span>
    </div>
)

const DATE_FORMAT = 'YYYY-MM-DD'

class Credential extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        error: PropTypes.object,
        credKey: PropTypes.string,
        person: PropTypes.object,
        expiration: PropTypes.string,
        showPerson: PropTypes.bool,
        disableEditing: PropTypes.bool,
        cardProps: PropTypes.object,
        editing: PropTypes.bool,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        hasExpiration: PropTypes.bool,
        needsButton: PropTypes.bool,
        hasKey: PropTypes.bool,
        title: PropTypes.any
    }
    state = {
        editing: false,
        fields: {}
    }
    onSave = () => {
        const { form } = this.refs;
        form.validateFieldsAndScroll((err, values) => {
            if (!err){
                this.props.onSave && this.props.onSave({
                    key: values.key,
                    expiration: values.expiration && values.expiration.format(DATE_FORMAT)
                })
            }
        })
        
    }
    editDisabled = () => this.props.disableEditing ? true : false
    isEditing = () => {
        if (typeof this.props.editing === "boolean"){
            return this.props.editing;
        }else{
            return this.state.editing
        }
    }
    setEditing = (value) => {
        if (this.props.onChange){
            this.props.onChange({
                editing: value
            })
        }
        this.setState({ editing: value })
    }
    onFormChange = (fields) => {
        this.setState({ fields: {
            ...this.state.fields,
            ...fields
        } })
    }
    setFieldsFromProps = () => {
        this.setState({
            fields: {
                key: {
                    value: this.props.credKey
                },
                expiration: {
                    value: moment(this.props.expiration)
                }
            }
        })
    }
    componentDidMount(){
        this.setFieldsFromProps()
    }
    render() {
        const {
            loading=false,
            error,
            type,
            credKey,
            person,
            expiration,
            showPerson,
            disableEditing,
            onSave,
            onChange,
            hasKey=true,
            hasExpiration=true,
            needsButton=false,
            title='Unknown Credential',
            ...restProps
        } = this.props;

        if (error){
            return (
                <Card title="Error loading credential">
                    <Alert showIcon banner message="Failed to load credential" />
                </Card>
            )
        }
        const editing = this.isEditing();
        const extra = !disableEditing ? (
            <EditControl
                disabled={this.editDisabled()}
                editing={editing}
                onChange={(v) => this.setEditing(v)}
                onSave={this.onSave}
                onCancel={this.setFieldsFromProps}
                onRemove={() => this.props.onRemove && this.props.onRemove()}
            />
        ) : null
        return (
            <Spin spinning={loading}>
                <Card
                    title={title}
                    extra={extra}
                    {...restProps}
                >
                    {editing ? (
                        <div>
                            <EditForm
                                ref="form"
                                hasKey={hasKey}
                                hasExpiration={hasExpiration}
                                needsButton={needsButton}
                                credKey={this.state.fields.key}
                                expiration={this.state.fields.expiration}
                                onChange={this.onFormChange}
                                dateFormat={DATE_FORMAT}
                            />
                        </div>
                    ) : (
                        <div>
                            {hasKey ? <CredItem label="Key" value={credKey} /> : null}
                            {hasExpiration ? <CredItem label="Expiration" value={expiration} /> : null}
                        </div>
                    )}
                </Card>
            </Spin>
        );
    }
}

export default Credential;