import { message } from 'antd';
import { createFormField, FormFields } from 'common/form';
import getGenericStateReducer from 'common/reducer/generic-state-reducer';
import { maxTimeMoment, momentOrNull, zeroTimeMoment } from 'common/util';
import useReportDetails, { ReportDetailsResult } from 'components/reporting/hooks/report-details';
import useReportSubmitter from 'components/reporting/hooks/report-submitter';
import { OrgDataContext } from 'context/orgData';
import usePersistence from 'hooks/persistence';
import moment from 'moment-timezone';
import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { fieldLabelMap } from './criteria-form';

export interface FWBRContextInterface {
    criteriaFormFields?: { [key: string]: any },
    onCriteriaFieldsChange?: (values: { [key: string]: any }) => void,
    resetCriteriaForm?: () => void,
    formRef?: any,
    reportID?: string,
    displayReportCriteria?: boolean,
    reportSubmitting?: boolean,
    submitReport?: (reportData: any) => Promise<boolean>,
    reportDetails?: ReportDetailsResult
}

const DEFAULT_CONTEXT_VALUE: FWBRContextInterface = {
    
}

export const FWBRContext = createContext<FWBRContextInterface>(DEFAULT_CONTEXT_VALUE);

export interface FWBRContextProviderProps {
    reportID?: string
}

interface ReducerState {
    reportId: string
}

const ACTION_TYPES = {
    SET_CRITERIA_FORM_FIELDS: 'SET_CRITERIA_FORM_FIELDS'
}

function reducer(state: ReducerState, type: string, payload: any){
    switch (type){
        case ACTION_TYPES.SET_CRITERIA_FORM_FIELDS:
            return {
                ...state,
                criteriaFormFields: payload
            }
        default:
            return state;
    }
}

const DEFAULT_STATE: ReducerState = {
    reportId: null
}

export const FWBRContextProvider: React.FC<PropsWithChildren<FWBRContextProviderProps>> = (props) => {
    const [ state, dispatch ] = useReducer(getGenericStateReducer<ReducerState>(reducer), DEFAULT_STATE);
    const { transporterID } = useContext(OrgDataContext);

    const reportDetails = useReportDetails(state.reportId);
    const [ submitReport, { loading: reportSubmitting } ] = useReportSubmitter('DISPATCH_BILLING', transporterID, {
        onCompleted: ({ id }) => {
            console.log('Report submitted. report id: ' + id)
            dispatch({ newState: { reportId: id } })
        },
        onError: (error) => {
            console.error('Failed to submit report: ', error);
            message.error("Failed to submit report due to an error");
        }
    });

    const { lastUpdate, persistentData, setPersistentValues, updateTs } = usePersistence('flytwatch.billing.criteria-form');

    const formRef = useRef();

    return <FWBRContext.Provider value={{
        criteriaFormFields: {
            ...persistentData,
            startDate: {
                ...persistentData?.startDate,
                value: momentOrNull(persistentData?.startDate?.value)
            },
            endDate: {
                ...persistentData?.endDate,
                value: momentOrNull(persistentData?.endDate?.value)
            }
        },
        onCriteriaFieldsChange: (fields) => {
            setPersistentValues(fields);
        },
        resetCriteriaForm: () => {
            setPersistentValues({});
        },
        formRef,
        reportID: state.reportId,
        submitReport: (formValues: any) => {

            dispatch({ newState: { reportId: null } })

            return submitReport({
                name: 'FlytWatch Billing Report for ' + formValues.transporter.label,
                criteria: Object.entries<any>(formValues).map(([ key, value ]) => {
                    let display_value;
                    let c_val = value;

                    if (key === 'transporter'){
                        c_val = value.key;
                        display_value = value.label;
                    }

                    return {
                        key,
                        value: c_val,
                        label: fieldLabelMap[key],
                        display_value: display_value
                    }
                })
            })
        },
        reportSubmitting: reportSubmitting,
        reportDetails: reportDetails

    }}>
        {props.children}
    </FWBRContext.Provider>
}