import { Cell, Column } from '@blueprintjs/table';
import { Button, Checkbox, Divider, Input, List, Tooltip, Typography } from 'antd';
import { getLabelInValue, getLabelInValueKey } from 'common/form';
import { safeGet } from 'common/util';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import { OrganizationSelect } from 'components/form/select/OrganizationSelect';
import gql from 'graphql-tag';
import moment from 'moment';
import DeleteEntity from 'Mutations/DeleteEntity';
import React, { useRef } from 'react';
import {
    DuplicationChecker, MDDetails, MDHeader, MDLayout, MDTable, TableLoader, useMasterDataState
} from '..';
import WithOrgData, { WithOrgDataProps } from '../../WithOrgData';
import ContractEntryForm from '../entryForms/contract';
import {
    getMDTableProps, MasterDataProps, MDStateToDetailsProps, MDStateToHeaderProps, MDStateToLayoutProps, MDStateToTableScrollerProps, renderCellFromKeyIndex, renderNoData, searchValuesToQuerySearchPayload, trimString
} from '../util';




export interface MDContractsProps extends WithOrgDataProps, MasterDataProps {
    startDateFormat?: string,
    endDateFormat?: string
}

const QUERY = gql`
query ContractsQuery($currDate: AWSDate!, $filter: [FilterByKeyValueInput!], $search: [FilterByKeyValueInput!], $limit: Int, $skip: Int) {
    MasterDataContracts(currDate: $currDate, filter: $filter, search: $search, limit: $limit, skip: $skip) {
        docs {
            ... on Contract {
                _id
                _rev
                name
                customerID {
                    _id
                    name
                }
                startDate
                endDate
                locationID {
                    _id
                    name
                }
                active
            }
        }
    }
}
`

const MUTATION = gql`
    mutation ContractMutation(
        $_id: ID
        $name: String!
        $customerID: ID!
        $startDate: AWSDate!
        $endDate: AWSDate!
        $locationID: ID
        $active: Boolean!
        $tpID: ID!
    ){
        setContract(
            _id: $_id
            name: $name
            customerID: $customerID
            startDate: $startDate
            endDate: $endDate
            locationID: $locationID
            active: $active
            tpID: $tpID
        ){
            _id
            _rev
            name
            customerID {
                _id
                name
            }
            startDate
            endDate
            locationID {
                _id
                name
            }
            active
        }
    }
`

const MDContract: React.FC<MDContractsProps> = (props) => {
    const formRef = useRef(null);
    let tpID = props.orgData.getOrgIDByType('transporter');

    const MDState = useMasterDataState({
        getQueryData: (data: any) => data.MasterDataContracts.docs,
        getQueryVariables: (searchValues) => ({
            filter: [
                {
                    key: 'tpID',
                    value: JSON.stringify(tpID)
                }
            ],
            search: searchValuesToQuerySearchPayload(searchValues),
            currDate: moment().format('YYYY-MM-DD')
        }),
        queryGQL: QUERY,
        saveMutationGQL: MUTATION,
        deleteMutationGQL: DeleteEntity,
        tpID: tpID,
        paginationLimit: props.dataPaginationLimit,
        pollInterval: props.pollInterval,
        updateQueryAfterFetchMore: (prev, fetchMoreResult) => ({
            ...prev,
            MasterDataContracts: {
                ...prev.MasterDataContracts,
                docs: [
                    ...prev.MasterDataContracts.docs,
                    ...fetchMoreResult.MasterDataContracts.docs
                ]
            }
        }),
        transformEditFromExisting: (values) => {
            return {
                ...values,
                startDate: values.startDate && moment(values.startDate),
                endDate: values.endDate && moment(values.endDate),
                customerID: getLabelInValue(values.customerID, 'name'),
                locationID: getLabelInValue(values.locationID, 'name')
            }
        }
    })
    const data = MDState.data;
    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        noDataElement={renderNoData(MDState, () => {
            return MDState.editFromNew({
                name: MDState.getSearchValue('name')
            })
        })}
        headerElement={<MDHeader
            {...MDStateToHeaderProps(MDState)}
            inputElement={<div style={{ display: 'flex', gap: '12px' }}>
                <Input.Search
                    onChange={(e) => MDState.onSearchValueChange('name', e.target.value)}
                    placeholder="Search by name"
                    value={MDState.getSearchValue('name')}
                    style={{ width: '15rem' }}
                    allowClear
                />
                <OrganizationSelect
                    onChange={(value) => MDState.onSearchValueChange('customerID', value)}
                    classTypes={['flytsuite.customer']}
                    placeholder="Filter customer"
                    value={MDState.getSearchValue('customerID')}
                    style={{ width: '15rem' }}
                    labelInValue
                    allowClear
                />
                <Tooltip
                    title="If checked, only active contracts where the current date is in between the start and end date will be shown."
                    placement="bottom"
                >
                    <Checkbox
                        onChange={(e) => MDState.onSearchValueChange('showGood', e.target.checked)}
                        checked={MDState.getSearchValue('showGood')}
                    >Show 'good' contracts</Checkbox>
                </Tooltip>
            </div>}
        />}
        tableElement={<TableLoader mdState={MDState}>
            <BlueprintTableInfiniteScroller {...MDStateToTableScrollerProps(MDState)} >
                <MDTable
                    {...getMDTableProps(data, MDState)}
                    hideDelete
                    columnWidths={[134, 134, 134, 180, 134, 134]}
                >
                    <Column name="Name" cellRenderer={renderCellFromKeyIndex(data, 'name')} />
                    <Column name="Customer" cellRenderer={(idx) => {
                        let record = data[idx];
                        let customerName = record && record.customerID && record.customerID.name;
                        return <Cell>{customerName}</Cell>
                    }} />
                    <Column name="Start Date" cellRenderer={idx => {
                        let record = data[idx];
                        return <Cell>{moment(record.startDate).format('YYYY-MM-DD')}</Cell>
                    }} />
                    <Column name="End Date" cellRenderer={idx => {
                        let record = data[idx];
                        let content = [<span>{moment(record.endDate).format('YYYY-MM-DD')}{` `}</span>]
                        if (moment().startOf('day').isAfter(moment(record.endDate).startOf('day'))){
                            content.push(<Typography.Text type='danger'>(Expired)</Typography.Text>)
                        }
                        else if (moment().startOf('day').isSame(moment(record.endDate).startOf('day'))){
                            content.push(<Typography.Text type='danger' mark>(Expires TODAY)</Typography.Text>)
                        }
                        else if (moment().add(1, 'month').isAfter(moment(record.endDate))){
                            content.push(<Typography.Text type='warning'>(Expires in {moment(record.endDate).diff(moment().startOf('day'), 'days')} days)</Typography.Text>)
                        }
                        return <Cell>{content}</Cell>
                    }} />
                    <Column name="Heliport" cellRenderer={(idx) => {
                        let record = data[idx];
                        let locationName = safeGet(['locationID', 'name'], record);
                        return <Cell>{locationName}</Cell>
                    }} />
                    <Column name="Active" cellRenderer={(idx) => {
                        let record = data[idx];
                        let active = record && record.active;
                        return <Cell intent={active ? 'success' : 'danger'}>{active ? 'Yes' : 'No'}</Cell>
                    }} />
                </MDTable>
            </BlueprintTableInfiniteScroller>
        </TableLoader>}
        detailsElement={<MDDetails
            {...MDStateToDetailsProps(MDState, 'name')}
            onSave={() => {
                formRef.current.validateFieldsAndScroll((err, values) => {
                    if (!err){
                        let saveData = {
                            ...values,
                            _id: MDState.getEntryFieldValue('_id'),
                            name: trimString(values.name),
                            startDate: values.startDate && moment(values.startDate).format(props.startDateFormat || 'YYYY-MM-DD'),
                            endDate: values.endDate ? 
                                moment(values.endDate).endOf('day').format(props.endDateFormat || 'YYYY-MM-DD') :
                                moment().add(100, 'years').format(props.endDateFormat || 'YYYY-MM-DD'),
                            customerID: getLabelInValueKey(values.customerID),
                            locationID: getLabelInValueKey(values.locationID),
                            active: values.active ? true : false
                        }
                        MDState.save(saveData)
                    }
                })
            }}
        >
            <ContractEntryForm
                formFields={MDState.entryFields}
                onFieldsChange={(_, fields) => MDState.setEntryFields(fields)}
                uppercaseFields={['name']}
                autoFocus
                ref={formRef}
                dupeChecker={MDState.isNewEntry ? (
                    <DuplicationChecker
                        skip={
                            !trimString(MDState.getEntryFieldValue('name')) ||
                            !getLabelInValueKey(MDState.getEntryFieldValue('customerID')) ||
                            !getLabelInValueKey(MDState.getEntryFieldValue('locationID'))
                        }
                        getData={data => data && data.MasterDataContracts && data.MasterDataContracts.docs}
                        query={QUERY}
                        variables={{
                            filter: [
                                {
                                    key: 'tpID',
                                    value: JSON.stringify(tpID)
                                }
                            ],
                            search: [
                                {
                                    key: 'name',
                                    value: JSON.stringify(trimString(MDState.getEntryFieldValue('name')))
                                },
                                {
                                    key: 'customerID',
                                    value: JSON.stringify(getLabelInValueKey(MDState.getEntryFieldValue('customerID')))
                                },
                                {
                                    key: 'locationID',
                                    value: JSON.stringify(getLabelInValueKey(MDState.getEntryFieldValue('locationID')))
                                }
                            ]
                        }}
                        renderItems={(items) => {
                            return <List size="small">
                            {items.map((contract, idx) => 
                                <List.Item key={idx}>
                                    <Button
                                        className="mc-link-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            MDState.editFromExisting(contract, false);
                                        }}
                                    >
                                        <strong>Name: </strong>{contract.name}
                                        <Divider type="vertical" />
                                        <strong>Customer: </strong>{safeGet(['customerID', 'name'], contract)}
                                        <Divider type="vertical" />
                                        <strong>Heliport: </strong>{safeGet(['locationID', 'name'], contract)}
                                        <Divider type="vertical" />
                                        <span>
                                            {contract && contract.startDate && moment(contract).format('YYYY-MM-DD')}
                                            <span> - </span>
                                            {contract && contract.endDate && moment(contract).format('YYYY-MM-DD')}
                                        </span>
                                    </Button>
                                </List.Item>)}
                            </List>
                        }}
                    />
                ) : null}
            >

            </ContractEntryForm>
            <label style={{fontSize:'5px'}}>{MDState.getEntryFieldValue('_id')}</label>
        </MDDetails>
    }
    />
}

export default WithOrgData(MDContract)