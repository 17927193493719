import { Alert, Button, Card, Col, DatePicker, Form, Input, Radio, Row, Select, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import RadioGroup from 'antd/lib/radio/group';
import { getLabelInValueKey } from 'common/form';
import { getOrgISNName } from 'common/util';
import CovidVaxDatesForm from 'components/covid-vax-date-form';
import { PersonCovidVaxRecord } from 'components/covid-vax-date-list';
import { OrganizationSelect } from 'components/form/select/OrganizationSelect';
import RigSelect from 'components/form/select/RigSelect';
import ISNChecker from 'components/ISNChecker';
import {validateCredWithExpiration, validateISNNumber} from 'formValidators';
import gql from 'graphql-tag';
import React, { forwardRef, LegacyRef } from 'react';
import { Query } from 'react-apollo';
import { entryFormOptions, EntryFormProps } from '..';
import ContactInfoCards from './ContactInfoCards';
import { CredentialInput } from './CredentialInput';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
    style: {
        marginBottom: 0
    }
};

export interface PersonEntryFormProps extends EntryFormProps {
    disableCustomer?: boolean
    disableEmployer?: boolean,
    showCovidForm?: boolean,
    disableCovidForm?: boolean,
    covid19VaxDateRecords?: PersonCovidVaxRecord[]
    
}

const PersonEntryForm_Internal: React.FC<PersonEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {

    let showCovidForm = props.showCovidForm || false;
    let disableCovidForm = props.disableCovidForm || false;
    
    const { getFieldDecorator, getFieldValue , setFieldsValue} = props.form;

    const hasAtLeastOneCred = (
        (
            getFieldValue('credential-US_DRIVERS_LICENSE') &&
            getFieldValue('credential-US_DRIVERS_LICENSE').key &&
            getFieldValue('credential-US_DRIVERS_LICENSE').key !== "" &&
            getFieldValue('credential-US_DRIVERS_LICENSE').expiration &&
            getFieldValue('credential-US_DRIVERS_LICENSE').expiration !== ""
        ) ||
        (
            getFieldValue('credential-PASSPORT') &&
            getFieldValue('credential-PASSPORT').key &&
            getFieldValue('credential-PASSPORT').key !== "" &&
            getFieldValue('credential-PASSPORT').expiration &&
            getFieldValue('credential-PASSPORT').expiration !== ""
        ) ||
        (
            getFieldValue('credential-PASSPORT_CARD') &&
            getFieldValue('credential-PASSPORT_CARD').key &&
            getFieldValue('credential-PASSPORT_CARD').key !== "" &&
            getFieldValue('credential-PASSPORT_CARD').expiration &&
            getFieldValue('credential-PASSPORT_CARD').expiration !== ""
        ) ||
        (
            getFieldValue('credential-TWIC') &&
            getFieldValue('credential-TWIC').key &&
            getFieldValue('credential-TWIC').key !== "" &&
            getFieldValue('credential-TWIC').expiration &&
            getFieldValue('credential-TWIC').expiration !== ""
        )
    );

    return <Form ref={ref}>
        <Row gutter={12} style={{ marginBottom: 6 }}>
            <Col>
                <Card size="small" title="Personal">
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="Last Name" {...formItemLayout}>
                            {getFieldDecorator('lastName', {
                                rules: [
                                    {required: true, message: 'Last name is required'}
                                ]
                            })(<Input size="small" disabled={!props.isNewEntry} />)}
                            </Form.Item>
                            <Form.Item label="Customer" {...formItemLayout}>
                            {getFieldDecorator('customerID', {
                                rules: [
                                    {required: true, message: 'Customer is required'}
                                ]
                            })(
                                <OrganizationSelect
                                    classTypes={['flytsuite.customer']}
                                    labelInValue={true}
                                    size="small"
                                    allowClear
                                    disabled={props.disableCustomer}
                                />
                            )}
                            </Form.Item>
                            <Form.Item label="DOB" {...formItemLayout}>
                            {getFieldDecorator('dob', {
                                rules: [
                                    {required: true, message: 'DOB is required'}
                                ]
                            })(
                                <DatePicker style={{ width: '100%' }} size="small" format="MM-DD-YYYY" disabled={!props.isNewEntry} />
                            )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="First Name" {...formItemLayout}>
                                {getFieldDecorator('firstName', {
                                    rules: [
                                        {required: true, message: 'First name is required'}
                                    ]
                                })(<Input size="small" disabled={!props.isNewEntry} />)}
                            </Form.Item>
                            <Form.Item label="Employer" {...formItemLayout}>
                            {getFieldDecorator('employerID', {
                                rules: [
                                    {required: true, message: 'Employer is required'}
                                ]
                            })(
                                <OrganizationSelect
                                    classTypes={['flytsuite.employer']}
                                    labelInValue={true}
                                    size="small"
                                    allowClear
                                    disabled={props.disableEmployer}
                                />
                            )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        {props.dupeChecker}
        <ContactInfoCards form={props.form} style={{ marginBottom: 6 }} />
        {showCovidForm ? (
            <Card title="Covid-19 Vaccine Dates" size="small" style={{ marginBottom: 6 }}>
                {disableCovidForm ? (
                    <Alert
                        showIcon
                        type="warning"
                        message="You do not have permission to edit the data below"
                        description="You are missing either edit or delete permissions or both"
                        style={{ marginBottom: '6px' }}
                    />
                ) : null}
                <CovidVaxDatesForm
                    form={props.form}
                    data={props.covid19VaxDateRecords}
                    disabled={disableCovidForm}
                    allowRemoveOrClearRecord={!disableCovidForm}
                />
            </Card>
        ) : null}
        <Row gutter={6}>
            <Col xl={14}>
                <Card size="small" title="Credentials">
                    <Form.Item label="Driver License" {...formItemLayout}>
                        {getFieldDecorator('credential-US_DRIVERS_LICENSE', {
                            rules: [{
                                validator: validateCredWithExpiration,
                                required: !hasAtLeastOneCred
                            }]
                        })(
                            <CredentialInput
                                inputProps={{ size: 'small' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Passport" {...formItemLayout}>
                        {getFieldDecorator('credential-PASSPORT', {
                            rules: [{
                                validator: validateCredWithExpiration,
                                required: !hasAtLeastOneCred
                            }]
                        })(
                            <CredentialInput
                                inputProps={{ size: 'small' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Passport Card" {...formItemLayout}>
                        {getFieldDecorator('credential-PASSPORT_CARD', {
                            rules: [{
                                validator: validateCredWithExpiration,
                                required: !hasAtLeastOneCred
                            }]
                        })(
                            <CredentialInput
                                inputProps={{ size: 'small' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="TWIC" {...formItemLayout}>
                        {getFieldDecorator('credential-TWIC', {
                            rules: [{
                                validator: validateCredWithExpiration,
                                required: !hasAtLeastOneCred
                            }]
                        })(
                            <CredentialInput
                                inputProps={{ size: 'small' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Safety Card" {...formItemLayout}>
                        {getFieldDecorator('credential-SAFETY_CARD')(
                            <CredentialInput
                                inputProps={{ size: 'small' }}
                                showKey={false}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="Huet Card" {...formItemLayout}>
                        {getFieldDecorator('credential-HUET_CARD')(
                            <CredentialInput
                                inputProps={{ size: 'small' }}
                                showKey={false}
                            />
                        )}
                    </Form.Item>
                        <Form.Item label="EBS Card" {...formItemLayout}>
                            {getFieldDecorator('credential-EBS_CARD')(
                                <CredentialInput
                                    inputProps={{ size: 'small' }}
                                    showKey={false}
                                />
                            )}
                    </Form.Item>
                    <Form.Item label="ISN Card" {...formItemLayout}>
                        {getFieldDecorator('credential-ISN', {
                            rules: [
                                {validator: (rule, value, callback) => validateISNNumber(rule, value && value.key, callback)}
                            ]
                        })(
                            <CredentialInput
                                inputProps={{ size: 'small' }}
                                showExpiration={false}
                            />
                        )}
                    </Form.Item>
                </Card>
                <Card size="small" title="Miscellaneous Requirements" style={{ marginTop: 6 }}>
                    <Form.Item label="Extra Broad" {...formItemLayout}>
                        {getFieldDecorator('extraBroadState')(
                            <RadioGroup >
                                <Radio value="FAIL">YES</Radio>
                                <Radio value="PASS">NO</Radio>
                            </RadioGroup>
                        )}
                        <Button onClick={() => setFieldsValue({"extraBroadState": "NOT_CHECKED"})}>Clear</Button>
                    </Form.Item>
                </Card>
                <Card size="small" title="Rig" style={{ marginTop: 6 }}>
                    <Form.Item label="Essential on" {...formItemLayout}>
                        {getFieldDecorator('essentialOn')(
                            <RigSelect allowClear labelInValue />
                        )}
                    </Form.Item>
                </Card>
            </Col>
            <Col xl={10}>
                <Card title="ISN Result" size="small">
                    <ISNChecker
                        customerID={getLabelInValueKey(getFieldValue('customerID'))}
                        isnNumber={getFieldValue('credential-ISN') && getFieldValue('credential-ISN').key}  
                    />
                </Card>
            </Col>
        </Row>
    </Form>
})
const PersonEntryForm = Form.create<PersonEntryFormProps & FormComponentProps>(entryFormOptions<PersonEntryFormProps>())(PersonEntryForm_Internal);

PersonEntryForm.displayName = "PersonEntryForm";

export default PersonEntryForm