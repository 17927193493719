import React, { Component } from 'react';
import '../css/Login.css';
import { Auth } from '@aws-amplify/auth';
import { Input, Button, Form, Icon, Alert } from 'antd';
import mcLogo from '../img/mc-logo-wide-white.svg';

class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            error: '',
            email: '',
            showCode: false,
            confirmDirty: false
        }
    }
    componentDidMount = () => {
        var { email } = this.props;
        this.props.form.setFieldsValue({email: email});
    }
    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const { code, newpassword } = values;
                Auth.forgotPasswordSubmit(this.state.email, code, newpassword)
                    .then(user => this.props.setforgotpassword(false))
                    .catch(err => {
                            this.setState({
                                error: err.message
                            })
                        }
                    );
                    this.setState({ loading: false });
            }
        });
    }
    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('newpassword')) {
            callback('Confirm Password and Password are not the same');
        } else {
            callback();
        }
        }
    
    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmpassword'], { force: true });
        }
        callback();
    }
    handleEmail = (e) => {
        e.preventDefault();
        e.stopPropagation()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const { email } = values;
                Auth.forgotPassword(email)
                    .then(resp => this.setState({ email: email, showCode: true }))
                    .catch(err => this.setState({ error: err.message }))
                this.setState({ loading: false });
            }
        });
    }
    render(){
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };

        if (!this.state.showCode)
        {
            return (
                <div className="login-page-wrapper">
                    <div className="container">
                        <img src={mcLogo} className="mc-logo" alt="Manifest Central Logo"/>
                        <div className="login-box">
                            <h2 style={{fontWeight: 100, marginBottom: '1rem'}}>Reset Password</h2>
                            {(this.state.error) ? 
                            <Alert
                                style={{marginBottom: '1rem'}}
                                message="Reset Password error"
                                description={this.state.error}
                                type="error"
                                closable
                                onClose={evt =>
                                    this.setState({
                                        error: ''
                                    })
                                }
                            /> : ''
                            }
                            <Form onSubmit={this.handleEmail}>
                                <Form.Item>
                                    {getFieldDecorator('email', {
                                        rules: [{required: true, message: 'Email is required'}]
                                    })(
                                        <Input type="email" size="large" placeholder="Email" />
                                    )}
                                </Form.Item>
                                <Form.Item >
                                    <Button type="primary" size="large" htmlType="submit" style={{width: '100%'}}>Submit</Button>
                                </Form.Item>
                            </Form>
                            <Button className="mc-link-btn" onClick={evt => this.props.setforgotpassword(false)}><Icon type="left" />Back</Button>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="login-page-wrapper">
                    <div className="container">
                        <img src={mcLogo} className="mc-logo" alt="Manifest Central Logo"/>
                        <div className="login-box">
                            <h2 style={{fontWeight: 100, marginBottom: '1rem'}}>Reset Password</h2>
                            <p>A verification code has been emailed to <strong>{this.state.email}</strong>. Please copy and paste the code below and enter a new password.</p>
                            {(this.state.error) ? 
                            <Alert
                                style={{marginBottom: '1rem'}}
                                message="Login error"
                                description={this.state.error}
                                type="error"
                                closable
                                onClose={evt =>
                                    this.setState({
                                        error: ''
                                    })
                                }
                            /> : ''
                            }
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Item {...formItemLayout} label="Verification Code">
                                    {getFieldDecorator('code', {
                                        rules: [{required: true, message: 'Verification code is required'}]
                                    })(
                                        <Input size="large" />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="New Password">
                                    {getFieldDecorator('newpassword', {
                                        rules: [
                                            {required: true, message: 'Password is required'},
                                            {
                                                validator: this.validateToNextPassword,
                                            }
                                        ]
                                    })(
                                        <Input type="password" size="large" onBlur={this.handleConfirmBlur} />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Confirm Password">
                                    {getFieldDecorator('confirmpassword', {
                                        rules: [
                                            {required: true, message: 'Confirm password is required'},
                                            {
                                                validator: this.compareToFirstPassword,
                                            }
                                        ]
                                    })(
                                        <Input type="password" size="large" />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" size="large" htmlType="submit" style={{width: '100%'}}>Submit</Button>
                                </Form.Item>
                            </Form>
                            <Button className="mc-link-btn" onClick={evt => {
                                if (this.state.showCode){
                                    this.props.onBack && this.props.onBack();
                                }
                                else
                                {
                                    this.setState({ showCode: false });
                                }
                            }}><Icon type="left" />Back</Button>
                        </div>
                    </div>
                </div>
            )
        }
        
    }
}

export default Form.create()(ForgotPassword);
