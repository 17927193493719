import React from 'react';
import { graphql, Query } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import withUserPermissions from '../withUserPermissions';
import moment from 'moment';
import WithOrgData from '../WithOrgData';
import LoadingContent from '../LoadingContent';
import { Table, Radio, Row, Col, DatePicker, Alert, message } from 'antd';
import commonColumns from '../../common/table/columns';

const PASSENGERS = gql`
query Passengers(
    $tpID: ID!
    $scheduledFlightDate: AWSDate!
){
    getTSAPassengers(
        tpID: $tpID
        scheduledFlightDate: $scheduledFlightDate
    ){
        _id
        lastName
        firstName
        employerID {
            _id
            name
        }
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        authorization {
            onWhiteList
            noFlyListed
        }
    }
}
`

const SET_NO_FLY = gql`
mutation SetNoFly(
    $_id: ID!
    $tpID: ID!
    $value: Boolean!
){
    setPassengerNoFlyCheck(
        _id: $_id
        tpID: $tpID
        value: $value
    ){
        _id
        authorization {
            noFlyListed
        }
    }
}
`

const DATE_FORMAT = "YYYY-MM-DD"

class TsaCheck extends React.Component {
    state = {
        date: moment().add(1, 'day')
    }
    renderContent = () => {
        const { transporter } = this.props.orgData;
        return (
            <Query 
            query={PASSENGERS} 
            pollInterval={5000}
            fetchPolicy="network-only"
            variables={{
                tpID: transporter._id,
                scheduledFlightDate: this.state.date.format(DATE_FORMAT)
            }} >
                {
                    ({ data, loading, error }) => {
                        if (loading) return <LoadingContent />
                        if (error) return <Alert type="error" message="Failed to load passengers" description={error.message} showIcon />
                        return (
                            <Table
                                className="mc-table"
                                dataSource={data.getTSAPassengers}
                                rowKey={record => record._id}
                                size="middle"
                                pagination={false}
                                columns={[
                                    ...commonColumns.pax.some([ "name", "employer", "departure", "destination" ]),
                                    {
                                        title: 'No Fly List',
                                        key: 'noFlyList',
                                        render: (_, record) => {
                                            const noFlyListed = (record.authorization && record.authorization.noFlyListed) ? true : false
                                            const value = noFlyListed.toString()
                                            return (
                                                <Radio.Group defaultValue="false" value={value} size="small" buttonStyle="solid" onChange={(e) => this.setNoFly(record._id, e.target.value)}>
                                                    <Radio.Button value="false">Not On List</Radio.Button>
                                                    <Radio.Button value="true">On List</Radio.Button>
                                                </Radio.Group>
                                            )
                                        }
                                    }
                                ]}
                            />
                        )
                    }
                }
            </Query>
        )
    }
    setNoFly = async (_id, value) => {
        const { transporter } = this.props.orgData;
        this.props.setNoFly({
            variables: {
                _id,
                tpID: transporter._id,
                value
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setPassengerNoFlyCheck: {
                    _id,
                    __typename: 'PaxNode',
                    authorization: {
                        __typename: 'Authorization',
                        noFlyListed: value === 'true'
                    }
                }
            }
        })
        .then(() => {
            message.success('Passenger successfully updated')
        })
        .catch((error) => {
            console.error(error)
            message.error('Failed to update passenger')
        })
    }
    handleDateChange = (date) => {
        this.setState({ date: date ? date : moment() })
    }
    render(){
        return (
            <React.Fragment>
                <Row style={{marginBottom: '1rem'}} type="flex" gutter={12}>
                    <Col>
                        <h2>TSA Check</h2>
                    </Col>
                    <Col>
                        <DatePicker 
                            value={this.state.date}
                            format={DATE_FORMAT}
                            onChange={this.handleDateChange}
                            allowClear={false}
                            disabledDate={current => current < moment().startOf('day')} />
                    </Col>
                </Row>
                {this.renderContent()}
            </React.Fragment>
        )
    }
}

export default compose(
    withUserPermissions({
        requiredGroups: 'flytsuite.tsa'
    }),
    WithOrgData,
    graphql(
        SET_NO_FLY,
        {
            name: 'setNoFly'
        }
    )
)(TsaCheck)