import React from 'react';
import {Icon} from "antd";

/**
 * Computes the possible width of a LegRoute component given a departure and destination location name.
 * @see {LegRoute}
 * @param items
 * @returns Width in pixels
 */
export function approximateLegRouteColumnWidth(items: { departureName: string, destinationName: string }[]){
    const charWidth = 9.2;
    const arrowWidth = 38;
    const sidePadding = 24;

    let maxWidth = 0;

    for (let item of items) {
        const numChars = String(item.departureName).length + String(item.destinationName).length;
        const width = (numChars * charWidth) + arrowWidth + (sidePadding * 2);
        maxWidth = Math.max(width, maxWidth);
    }

    return maxWidth;
}

/**
 * Displays an arrow pointing from the departure name to the destination name.
 * Example: SS198 -> WC122
 * @param props
 * @constructor
 */
export const LegRoute = (props: { departureName: React.ReactNode, destinationName: React.ReactNode }) => {
    return <>
        <span>{props.departureName}</span>
        <Icon type="arrow-right" style={{margin: '0 24px'}}/>
        <span>{props.destinationName}</span>
    </>
}

