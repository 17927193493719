import { Alert, Button, Card, DatePicker, Drawer, Form, Icon, Input, InputNumber, Layout, message, Popconfirm, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import { NetworkStatus } from 'apollo-client';
import { castArray, cleanGraphQLErrorMsg, dateMtimeMtoMoment } from 'common/util';
import CenteredLoadingScreen from 'components/CenteredLoadingScreen';
import DateTimePicker, { strToDateTimePickerValue } from 'components/date-time-picker';
import ETable, { ETableTotals } from 'components/enchanced-antd-table';
import AircraftSelect from 'components/form/AircraftSelect';
import ContractSelect from 'components/form/ContractSelect';
import LocationSelect2 from 'components/form/select/LocationSelect/LocationSelect2';
import NonIdealState from 'components/NonIdealState';
import { DataExporter, REColumnSet } from 'components/reporting/export';
import { OrgDataContext } from 'context/orgData';
import { validateDateTimePicker } from 'formValidators';
import gql from 'graphql-tag';
import useDelayedEffect from 'hooks/useDelayedEffect';
import moment, { Moment } from 'moment-timezone';
import React, { CSSProperties, useContext, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import AddFuelTakenForm from './add-fuel-taken-form';
import useDeleteFuelTaken from './delete-fuel-taken';
import { getValidateAmountValidator, getValidatePSIDValidator } from './form';
import './fuel-taken.less';
import useUpdateFuelTaken from './update-fuel-taken';

export interface FuelTakenPageProps {
    style?: CSSProperties,
    date?: Moment | string,
    reportType: 'detail' | 'summary',
    tz: string,
    onDateChange?: (date: Moment) => void,
    onReportTypeChange: (reportType: 'detail' | 'summary') => void,
    onTzChange: (tz: string) => void
}

const BASE_CLS = 'mc-fw-fuel-taken';

const POLL_INTERVAL = 10000;

const QUERY = gql`
query GetFuelTaken($tpID: String!, $startDateTime: String!, $endDateTime: String! $reportType: String!){
    GetFuelTaken(tpID: $tpID, startDateTime: $startDateTime, endDateTime: $endDateTime, reportType: $reportType)
}`

const FuelTakenPage_Internal: React.FC<FuelTakenPageProps & FormComponentProps> = (props) => {

    const history = useHistory();
    const orgData = useContext(OrgDataContext);
    const [ exportOpen, setExportOpen ] = useState(false);
    const [ addFuelTakenVisible, setAddFuelTakenVisible ] = useState(false);

    const [ editing, setEditing ] = useState<{ locID: string, datetime: string }>();

    function isRowEditing(data: any){
        return data.locationId === editing?.locID && data.dateTimeTaken === editing?.datetime
    }

    let date = moment();

    if (moment.isMoment(props.date) && props.date){
        date = props.date;
    }
    else if (typeof props.date === 'string'){
        date = moment(props.date);
    }

    let queryVars = {
        tpID: orgData.transporterID,
        startDateTime: moment.utc(date).startOf('day').toISOString(),
        endDateTime: moment.utc(date).endOf('day').toISOString(),
        reportType: props.reportType
        // startDateTime: moment().utcOffset(0).startOf('day').toISOString(),
        // endDateTime: moment().utcOffset(0).endOf('day').toISOString()
    }

    const { data: queryData, error, networkStatus, refetch, startPolling, stopPolling } = useQuery(QUERY,
    {
        variables: queryVars,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: POLL_INTERVAL
    })

    const [ deleteFuelTaken, deleteResult, deletedFuelTaken ] = useDeleteFuelTaken({
        onCompleted: () => refetch(),
        onError: (e) => message.error("Failed to delete due to an error: " + cleanGraphQLErrorMsg(e.message), 5),
        additionalMutationOptions: {
            optimisticResponse: undefined,
            update: (cache, { data: { DeleteFuelTaken }, }) => {

                let parsedFuelTaken = queryData.GetFuelTaken ? JSON.parse(queryData.GetFuelTaken) : null;

                if (!parsedFuelTaken) return;

                let newFuelTaken = parsedFuelTaken.filter(ft => !(ft.locationId === DeleteFuelTaken?.locationId && ft.dateTimeTaken === DeleteFuelTaken?.dateTimeTaken))

                cache.writeQuery({
                    query: QUERY,
                    variables: queryVars,
                    data: {
                        ...queryData,
                        GetFuelTaken: JSON.stringify(newFuelTaken)
                    }
                })
            }
        }
    })

    const [ updateFuelTaken, updateResult, updatedFuelTaken ] = useUpdateFuelTaken({
        onCompleted: () =>{ setEditing(undefined); refetch(); },
        onError: (e) => message.error("Failed to update due to an error: " + cleanGraphQLErrorMsg(e.message), 5),
        additionalMutationOptions: {
            optimisticResponse: undefined,
            update: (cache, { data: { UploadFuelTaken }, }) => {

                let parsedFuelTaken = queryData.GetFuelTaken ? JSON.parse(queryData.GetFuelTaken) : null;

                if (!parsedFuelTaken) return;

                let newFuelTaken = parsedFuelTaken.map(ft => {
                    if (ft.locationId === UploadFuelTaken?.locationId && ft.dateTimeTaken === UploadFuelTaken?.dateTimeTaken){
                        return { ...UploadFuelTaken }
                    }
                    return ft;
                })

                cache.writeQuery({
                    query: QUERY,
                    variables: queryVars,
                    data: {
                        ...queryData,
                        GetFuelTaken: JSON.stringify(newFuelTaken)
                    }
                })
            }
        }
    })

    function handleEditFuelTaken(){
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err){
                let dtt = dateMtimeMtoMoment(values.dateTimeTaken).format('YYYY-MM-DD  HH:mm');
                updateFuelTaken({
                    ...values,
                    locationId: values.locationId?.key,
                    contractId: values.contractId?.key,
                    aircraftId: values.aircraftId?.key,
                    dateTimeTaken: dtt
                })
            }
        })
    }

    function isBeingDeleted(record){
        if (!deletedFuelTaken || !deleteResult.loading){
            return false;
        }
        return record.locationId === deletedFuelTaken.locationId && record.dateTimeTaken === deletedFuelTaken.dateTimeTaken
    }

    function isBeingUpdated(record){
        if (!updatedFuelTaken || !updateResult.loading){
            return false;
        }
        return record.locationId === updatedFuelTaken.locationId && record.dateTimeTaken === updatedFuelTaken.dateTimeTaken
    }

    function editRow(data: any){
        stopPolling();
        setEditing({
            locID: data.locationId,
            datetime: data.dateTimeTaken
        })
    }

    function cancelEdit(){
        startPolling(POLL_INTERVAL);
        setEditing(undefined);
    }

    let reportType = props.reportType;

    function getColumnSets(data){
        const d_columnSets: REColumnSet[] = [{
            setName: 'Billing Report',
            key: 'billing',
            columns: [
                {
                    label: 'Location',
                    key: 'name'
                },
                {
                    label: 'Amount',
                    key: 'amount'
                },
                {
                    label: 'Date/Time Taken (Local Time)',
                    key: 'dateTimeTaken'
                },
                {
                    label: 'Flight ID',
                    key: 'flightIdName'
                },
                {
                    label: 'Contract',
                    key: 'contractName'
                },
                {
                    label: 'AC Tail',
                    key: 'aircraftName'
                },
                {
                    label: 'PSID',
                    key: 'psid'
                }
            ]
        }];

        const s_columnSets: REColumnSet[] = [{
            setName: 'Billing Report',
            key: 'billing',
            columns: [
                {
                    label: 'Location',
                    key: 'name'
                },
                {
                    label: 'Total Amount',
                    key: 'totalAmount'
                }
            ]
        }]

        let columnSets = d_columnSets;

        if (reportType === "summary"){
            columnSets = s_columnSets;
        }

        return columnSets;
    }

    const reduceDetail = (prev, curr) => {
        if (isRowEditing(curr) && typeof props.form.getFieldValue('amount') === 'number'){
            return prev + props.form.getFieldValue('amount');
        }
        return prev + curr.amount;
    };
    const reduceSummary = (prev, curr) => prev + curr.totalAmount;

    // Validate form when amount or psid field changes

    const values = props.form.getFieldsValue();

    useDelayedEffect(() => {
        props.form.validateFields();
    }, [ values.amount, values.psid ])

    // -------------------------------------------------

    function renderContent(data){
        if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables){
            return <CenteredLoadingScreen />
        }

        const d_columns: ColumnProps<any>[] = [
            {
                title: 'Location',
                key: 'location',
                dataIndex: 'locationName',
                render: (name, record) => isRowEditing(record) ? 
                <Form.Item className="mc-form-item-no-margin">
                    {props.form.getFieldDecorator('locationId', {
                        initialValue: { key: record.locationId, label: name },
                        rules: [{ required: true, message: 'Location is required' }]
                    })(
                        <LocationSelect2 labelInValue />
                    )}
                </Form.Item>
                 : (name || record.name)
            },
            {
                title: 'Amount',
                key: 'amount',
                dataIndex: 'amount',
                render: (amount, record) => isRowEditing(record) ?
                <Form.Item className="mc-form-item-no-margin">
                    {props.form.getFieldDecorator('amount', { initialValue: amount, rules: [{ validator: getValidateAmountValidator(props.form) }] })(
                        <InputNumber style={{ width: '60px' }} />
                    )}
                </Form.Item>
                : amount
            },
            {
                title: 'Date/Time Taken (Local Time)',
                key: 'dateTimeTaken',
                dataIndex: 'dateTimeTaken',
                render: (dt, record) => isRowEditing(record) ?
                    <Form.Item className="mc-form-item-no-margin">
                        {props.form.getFieldDecorator('dateTimeTaken', {
                            initialValue: strToDateTimePickerValue(dt),
                            rules: [{ required: true, message: 'This field is required', validator: validateDateTimePicker }] 
                        })(
                            <DateTimePicker datePickerProps={{ size: 'default' }} timePickerProps={{ size: 'default' }} />
                        )}
                    </Form.Item>
                    : dt ? moment(dt).format('MMM Do, YYYY H:mm') : null
            },
            {
                title: 'Flight ID',
                key: 'flightid',
                dataIndex: 'flightIdName',
                render: (text, record) => isRowEditing(record) ?
                    <Form.Item className="mc-form-item-no-margin">
                        {props.form.getFieldDecorator('flightIdName', { initialValue: text })(
                            <Input style={{ width: 'initial' }} />
                        )}
                    </Form.Item>
                     : text
            },
            {
                title: 'Contract',
                key: 'contract',
                dataIndex: 'contractName',
                render: (name, record) => isRowEditing(record) ? 
                    <Form.Item className="mc-form-item-no-margin">
                        {props.form.getFieldDecorator('contractId', {
                            initialValue: { key: record.contractId, label: name }
                        })(
                            <ContractSelect style={{ width: '120px' }} labelInValue dropdownMatchSelectWidth={false} />
                        )}
                    </Form.Item>
                    : name
            },
            {
                title: 'AC Tail',
                key: 'tail',
                dataIndex: 'aircraftName',
                render: (name, record) => isRowEditing(record) ? 
                <Form.Item className="mc-form-item-no-margin">
                    {props.form.getFieldDecorator('aircraftId', {
                        initialValue: { key: record.aircraftId, label: name }
                    })(
                        <AircraftSelect style={{ width: '90px' }} labelInValue dropdownMatchSelectWidth={false} />
                    )}
                </Form.Item>
                : name
            },
            {
                title: 'PSID',
                key: 'psid',
                dataIndex: 'psid',
                render: (psid, record) => isRowEditing(record) ?
                <Form.Item className="mc-form-item-no-margin">
                    {props.form.getFieldDecorator('psid', { initialValue: psid, rules: [{ validator: getValidatePSIDValidator(props.form) }] })(
                        <InputNumber style={{ width: '60px' }} />
                    )}
                </Form.Item>
                : psid
            },
            {
                title: '',
                key: 'edit',
                render: (_, record) => {
                    if (isRowEditing(record)){
                        return <div>
                            <Button
                                type='danger'
                                onClick={() => cancelEdit()}
                            >Cancel</Button>
                            <Button
                                type="primary"
                                style={{ marginLeft: '6px', width: '61px' }}
                                onClick={() => handleEditFuelTaken()}
                                disabled={isBeingUpdated(record)}
                            >{isBeingUpdated(record) ? <Icon type="loading" /> : "Save"}</Button>
                        </div>
                    }
                    return <div>
                        <Tooltip title="Edit">
                            <Button
                                size='small'
                                icon="edit"
                                onClick={() => editRow(record)}
                                type="primary"
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Popconfirm
                                title={<span>Are you sure you want to delete this record? <u>THIS CANNOT BE UNDONE!!</u></span>}
                                onConfirm={() => deleteFuelTaken({
                                    locationId: record.locationId,
                                    dateTimeTaken: record.dateTimeTaken
                                })}
                                placement="topLeft"
                            >
                                <Button
                                    size='small'
                                    icon={isBeingDeleted(record) ? "loading" : "delete"}
                                    style={{ marginLeft: '6px' }}
                                    type="danger"
                                    disabled={isBeingDeleted(record)}
                                />
                            </Popconfirm>
                        </Tooltip>
                    </div>
                }
            }
        ]

        const s_columns: ColumnProps<any>[] = [
            {
                title: 'Location',
                key: 'location',
                dataIndex: 'name',
                width: 40
            },
            {
                title: 'Total Amount',
                key: 'totalAmount',
                dataIndex: 'totalAmount',
                width: 500
            }
        ]

        const d_totals: ETableTotals = {
            label: 'Total Fuel Taken:',
            masterLabel: 'Grand Total:',
            colNum: 0,
            masterSticky: true,
            renderTdFromGroup: (data) => {
                return [<td>{data.reduce(reduceDetail, 0)}</td>]
            },
            renderMasterTotalTd: (data) => {
                return [<td>{data.reduce(reduceDetail, 0)}</td>]
            }
        }

        const s_totals: ETableTotals = {
            label: 'Grand Total:',
            colNum: 0,
            sticky: true,
            td: [<td>{data.reduce(reduceSummary, 0)}</td>]
        }

        let columns = d_columns;
        let totals = d_totals;

        if (reportType === 'summary'){
            columns = s_columns;
            totals = s_totals;
        }
    
        return <ETable
            rowKey="key"
            dataSource={data}
            columns={columns}
            pagination={false}
            size="small"
            className="mc-table"
            flexMode
            stickyHeader
            groupSpacing
            groupByColKey={reportType === 'detail' ? 'name' : undefined}
            totals={totals}
            squareCorners
        />
    }

    let data = queryData?.GetFuelTaken;

    if (typeof data == 'string'){
        data = JSON.parse(data);
    }

    data = castArray(data);

    let total = 0;

    if (reportType === 'summary'){
        total = data.reduce(reduceSummary, 0);
    }
    else
    {
        total = data.reduce(reduceDetail, 0);
    }

    return <Layout style={props.style}>
        <Layout.Header className={BASE_CLS + '-header'}>
            <h2 className={BASE_CLS + '-title'}>Fuel Taken</h2>
            <Button.Group size='large' style={{ marginLeft: '1rem' }}>
                <a
                    className='ant-btn ant-btn-primary'
                    href="/app/flytwatch/fuel-taken"
                    onClick={(e) => { e.preventDefault(); history.push('/app/flytwatch/fuel-taken') }}
                >Daily</a>
                <a
                    className='ant-btn'
                    href="/app/flytwatch/fuel-taken/report"
                    onClick={(e) => { e.preventDefault(); history.push('/app/flytwatch/fuel-taken/report') }}
                >Report</a>
            </Button.Group>
            <DatePicker
                style={{ marginLeft: '12px' }}
                size='large'
                value={date}
                format="M/D/YYYY"
                onChange={(d) => props?.onDateChange?.(d)}
            />
            <Button.Group size='large' style={{ marginLeft: '1rem' }}>
                <a
                    className={`ant-btn ${props.reportType === 'detail' ? 'ant-btn-primary' : ''}`}
                    href="/app/flytwatch/fuel-taken"
                    onClick={(e) => { e.preventDefault(); props.onReportTypeChange?.('detail') }}
                >Detail</a>
                <a
                    className={`ant-btn ${props.reportType === 'summary' ? 'ant-btn-primary' : ''}`}
                    href="/app/flytwatch/fuel-taken/report"
                    onClick={(e) => { e.preventDefault(); props.onReportTypeChange?.('summary') }}
                >Summary</a>
            </Button.Group>
            {networkStatus !== NetworkStatus.loading ? <h3 style={{ marginLeft: '1rem', marginBottom: 0 }}>Grand Total: {total}</h3> : null}
            <Button
                style={{marginLeft: 'auto', marginRight: '12px' }}
                onClick={() => refetch()}
                icon={ networkStatus === NetworkStatus.loading || networkStatus == NetworkStatus.refetch || networkStatus == NetworkStatus.poll ? 'loading' : 'reload'}
            />
            <Button
                type="primary"
                icon="plus"
                style={{ marginRight: '12px' }}
                className="mc-btn-green"
                onClick={() => setAddFuelTakenVisible(!addFuelTakenVisible)}
            >
                Add Fuel Taken
            </Button>
            <Button disabled={networkStatus === NetworkStatus.loading} type="primary" icon="export" onClick={() => setExportOpen(true)}>
                Export
            </Button>
        </Layout.Header>
        <Layout.Content style={{ margin: 0, display: 'flex', flexDirection: 'column' }}>
            {error ? <Alert showIcon type='error' message="Failed to get fuel taken data" description={cleanGraphQLErrorMsg(error.message)} /> : null}
            {addFuelTakenVisible ? (
                <Card title="Add Fuel Taken" style={{ margin: 12 }} size="small">
                    <AddFuelTakenForm.Submitter
                        onSuccess={() => {
                            setAddFuelTakenVisible(false);
                            refetch();
                        }}
                    >
                        <AddFuelTakenForm
                            date={date}
                            onCancel={() => setAddFuelTakenVisible(false)}
                        />
                    </AddFuelTakenForm.Submitter>
                </Card>
            ) : null}
            {(!data || data.length === 0) && networkStatus === NetworkStatus.loading ? (
                <CenteredLoadingScreen />
            ) : null}
            {data && data.length > 0 ? 
                renderContent(data) :
                <NonIdealState
                    title={`No Fuel Taken data for ${date.format('MMMM Do')}`}
                    icon="question"
                    style={{ height: '100%' }}
                />
            }
        </Layout.Content>
        <Drawer
            visible={exportOpen}
            onClose={() => setExportOpen(false)}
            title={<span><Icon type="export" style={{ marginRight: '0.5rem' }} /><span>Export Report</span></span>}
            width={600}
            bodyStyle={{ height: 'calc(100% - 56px)' }}
        >
            <DataExporter
                data={data}
                titleRowText={`Fuel taken report for ${date.format('MMM Do, YYYY')}`}
                export_name={`Fuel Taken Report ${date.format('MMM Do, YYYY')}`}
                column_sets={getColumnSets(data)}
                report_type="DISPATCH_FUEL_TAKEN_S3"
                criteria={[]}
            />
        </Drawer>
    </Layout>
}

const FuelTakenPage = Form.create<FuelTakenPageProps & FormComponentProps>()(FuelTakenPage_Internal);

FuelTakenPage.defaultProps = {
    reportType: 'detail'
}

export default FuelTakenPage;