import React from 'react';
import { Card, Row, Col } from 'antd';
import { Statistic } from 'antd';

const PersonHotFuel = ({ data }) => {
    const person_count = data.person_count.value || 0;
    return (
        <Row type="flex" gutter={12} style={{ alignItems: '' }}>
            <Col span={6}>
                <Card>
                    <Statistic title="Personnel Count" value={person_count} />
                </Card>
            </Col>
        </Row>
    )
}
 
export default PersonHotFuel;