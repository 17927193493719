import { Icon, Input, Popover, Tooltip, Typography } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { TooltipPlacement } from 'antd/lib/tooltip';
import useControlledState from 'hooks/useControlledState';
import React, { useEffect, useRef, useState } from 'react';
import './style.less';

interface CustomInputProps {
    setInputValue: React.Dispatch<React.SetStateAction<string>>,
    className: string,
    value: string | number | string[],
    ref: React.MutableRefObject<any>,
    onChange: (e: any) => void,
    onBlur: () => void,
    onPressEnter: () => void,
    onKeyDown: (e: any) => void,
    hasValueChanged: boolean,
    confirm: (enterKeyPressed?: boolean, newValue?: any) => void,
    cancel: () => void,
    isEditing: boolean
}

export interface EditableTextProps {
    isEditing?: boolean,
    value?: string,
    onValueChange?: (value: string) => void,
    onEditingChange?: (editing: boolean) => void,
    renderValue?: (value: string) => React.ReactNode,
    editTooltipMessage?: string,
    tooltipPlacement?: TooltipPlacement,
    showEditIcon?: boolean,
    inputComponent?: React.ReactElement,
    renderInput?: (props: CustomInputProps) => React.ReactElement,
    disableEdit?: boolean,
    form?: {
        formFieldName: string,
        form: WrappedFormUtils
    }
}

const EditableText: React.FC<EditableTextProps> = React.forwardRef<{}, EditableTextProps>(({
    isEditing,
    value,
    onValueChange,
    onEditingChange,
    renderValue=(value) => value,
    showEditIcon=true,
    inputComponent,
    renderInput,
    form,
    disableEdit,
    editTooltipMessage="ESC: Cancel | Enter/Click away: Confirm",
    tooltipPlacement="top"
}, _) => {

    const [ editing, setEditing ] = useControlledState(false, isEditing, onEditingChange);
    const [ inputValue, setInputValue ] = useState<string>(value);

    const inputRef = useRef<any>();

    function confirm(fromEnterKey: boolean, newValue?: any){
        let isValid = true;
        if (form){
            if (form.formFieldName){
                form.form.validateFields([form.formFieldName], (err) => {
                    if (err){
                        // Field failed validation. Prevent confirming.
                        isValid = false;
                    }
                });
            }
            else {
                console.warn("EditableText: form.formFieldName prop required to validate field");
            }
        }
        if (!isValid) {
            if (!fromEnterKey){  // This allows you to click away without confirming the field if there is a validation error. If you pressed ENTER it won't un-edit the field.
                setEditing(false);
            }
            else
            {
                return false;
            }
        };
        if ((newValue || inputValue) !== value){
            onValueChange?.(newValue || inputValue);
        }
        setEditing(false);
        return true
    }
    
    useEffect(() => {
        // Auto-focus input field
        if (editing && inputRef.current){
            inputRef.current.focus();
        }
        if (editing){
            setInputValue(value);
        }
    // eslint-disable-next-line
    }, [ editing ])

    let inputElement = (
        <Input
            className="mc-editable-text-input"
            value={inputValue}
            ref={inputRef}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            onBlur={() => confirm(false)}
            onPressEnter={() => confirm(true)}
            onKeyDown={(e) => {
                e.stopPropagation();
                if (e.keyCode === 27){ // ESC key
                    setEditing(false);
                }
            }}
        />
    );

    let customInputProps: CustomInputProps = {
        className: "mc-editable-text-input",
        value: inputValue,
        ref: inputRef,
        setInputValue: setInputValue,
        onChange: (e: any) => {
            try{
                setInputValue(e.currentTarget.value);
            }
            catch(err){
                try{
                    setInputValue(e);
                }
                catch(err){
                    console.error("Failed to update inputValue: ", err);
                }
            }
        },
        onBlur: () => confirm(false),
        onPressEnter: () => confirm(true),
        onKeyDown: (e) => {
            e.stopPropagation();
            if (e.keyCode === 27){
                setEditing(false);
            }
        },
        hasValueChanged: value !== inputValue,
        confirm: confirm,
        cancel: () => setEditing(false),
        isEditing: editing
    }

    if (inputComponent && !renderInput){
        inputElement = React.cloneElement(inputComponent, customInputProps)
    }
    else if (renderInput){
        inputElement = renderInput(customInputProps);
    }

    if (editing){
        if (editTooltipMessage !== null){
            return <Popover placement="right" content={editTooltipMessage} visible={true}>
                {inputElement}
            </Popover>
        }
        else
        {
            return inputElement
        }
    }

    if (disableEdit){
        return <span>{renderValue(value)}</span>
    }

    return <Tooltip title="Edit" placement={tooltipPlacement}>
        <button
            className={"mc-editable-text-editing"}
            onClick={() => {
                if (!disableEdit){
                    setEditing(true);
                }
            }}>
            {renderValue(value)}
            {showEditIcon ? (
                <Typography.Text type="secondary"><Icon type="edit" style={{ marginLeft: '6px' }} /></Typography.Text>
            ) : null}
        </button>
    </Tooltip>
})

EditableText.displayName = 'EditableText'

export default EditableText;