import { Col, Row } from 'antd';
import 'css/Border.less';
import React, { useContext } from 'react';
import EmployerEmailTriggers from './employer-email-triggers';
import UserOrgEmailTriggers from './user-org-email-triggers';
import { OrgDataContext } from 'context/orgData';

export interface EmailTriggersPageProps { }

const EmailTriggersPage: React.FC<EmailTriggersPageProps> = () => {

    const orgData = useContext(OrgDataContext);

    return <div>
        <h2>Email Triggers</h2>
        <Row gutter={12}>
            <Col md={24} lg={12} xxl={8}>
                <UserOrgEmailTriggers />
            </Col>
            {orgData.customerID ? (
                <Col md={24} lg={12} xxl={8}>
                    <EmployerEmailTriggers />
                </Col>
            ) : null}
        </Row>
    </div>
}

export default EmailTriggersPage