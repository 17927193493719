import { Cell, Column, RenderMode, SelectionModes, Table } from '@blueprintjs/table';
import { Alert, Button, Divider, Icon, Input, message, Modal, PageHeader, Popover, Select, Spin, Tooltip, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { NetworkStatus } from 'apollo-boost';
import { DataProxy } from 'apollo-cache/lib/types/DataProxy';
import { getFormFieldValue, getLabelInValue } from 'common/form';
import { useColWidths } from 'common/table';
import BlueprintTableInfiniteScroller from 'components/BlueprintTableInfiniteScroller';
import CognitoPhoneNumber from 'components/form/InputCognitoPhoneNumber/CognitoPhoneNumber';
import { MDLayout, MDTable } from 'components/masterdata-2-0';
import { useMasterDataStateNoQueryHook } from 'components/masterdata-2-0/hook';
import { MDStateToLayoutProps } from 'components/masterdata-2-0/util';
import gql from 'graphql-tag';
import useAutoPaginatedQuery from 'hooks/apollo-extensions/useAutoPaginatedQuery';
import { isEmpty } from 'lodash';
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { MutationFunctionOptions, useMutation } from 'react-apollo';
import UserMDDetails from './userDetails';
import { CognitoUser } from 'common/user/user';

const MAX_PAGES = 100;

const QUERY_USERS = gql`
query ListUsers($filter: String, $paginationToken: String){
    AdminCognitoListUsers(filter: $filter, paginationToken: $paginationToken) {
        users {
            Attributes {
                Name
                Value
            }
            mappedAttributes {
                organization
                organizationName
                organizationClassType
                family_name
                given_name
                phone_number
                phone_number_verified
                company_phone_number
                email
                email_verified
                sub
            }
            Enabled
            UserCreateDate
            UserLastModifiedDate
            UserStatus
            Username
            approvalStatus
        }
        paginationToken
    }
}
`

const MUTATION_DISABLE_USER = gql`
mutation DisableUser($username: String!, $enableUser: Boolean){
    AdminCognitoDisableUser(username: $username, enableUser: $enableUser)
}
`

const MUTATION_SAVE_USER = gql`
mutation SaveUser($username: String!, $attributes: AWSJSON, $deletedAttributeNames: [String!]){
    AdminCognitoUpdateUserAttributes(username: $username, attributes: $attributes, deletedAttributeNames: $deletedAttributeNames)
}
`

const MUTATION_CREATE_USER = gql`
mutation CreateUser($username: String!, $attributes: AWSJSON!, $tempPassword: String, $desiredDeliveryMediums: [String!]){
    AdminCognitoCreateUser(username: $username, attributes: $attributes, tempPassword: $tempPassword, desiredDeliveryMediums: $desiredDeliveryMediums)
}
`

const MUTATION_DELETE_USER = gql`
mutation DeleteUser($username: String!){
    AdminCognitoDeleteUser(username: $username)
}
`

function getUserMappedAttrFn(fieldKey: string) {
    return (user) => user.mappedAttributes[fieldKey];
}

function booleanRenderer(value: boolean) {
    if (value !== false && value !== true) return null;
    return value ? <Icon type="check" style={{ color: 'limegreen' }} /> : <Icon type="close" style={{ color: 'red' }} />
}

const fieldMapping = [
    {
        key: 'email',
        display: 'Email',
        getValue: getUserMappedAttrFn('email')
    },
    {
        key: 'family_name',
        display: 'Family Name',
        getValue: getUserMappedAttrFn('family_name')
    },
    {
        key: 'given_name',
        display: 'Given Name',
        getValue: getUserMappedAttrFn('given_name')
    },
    {
        key: 'organizationName',
        display: 'Organization',
        getValue: getUserMappedAttrFn('organizationName')
    },
    {
        key: 'organizationClassType',
        display: 'Org Class Type',
        getValue: (user) => String(getUserMappedAttrFn('organizationClassType')(user))
            .replace('flytsuite.', '')
            .split('')
            .map((char, idx) => idx === 0 ? char.toUpperCase() : char)
            .join('')
    },
    {
        key: 'phone_number',
        display: 'Phone Number',
        getValue: getUserMappedAttrFn('phone_number')
    },
    {
        key: 'company_phone_number',
        display: 'Company Phone Number',
        getValue: getUserMappedAttrFn('company_phone_number')
    },
    {
        key: 'UserStatus',
        display: 'User Status',
        getValue: user => user.UserStatus,
        render: (value: any) => {
            if (value === 'CONFIRMED') return <span style={{ color: 'limegreen' }}>{value}</span>
            if (value === 'UNCONFIRMED') return <Typography.Text type='warning'>{value}</Typography.Text>
            return <Typography.Text type='warning'>{value}</Typography.Text>
        }
    },
    {
        key: 'approvalStatus',
        display: 'Approval Status',
        getValue: (user: CognitoUser) => user.approvalStatus,
        render: (value: any) => {
            if (value === 'approved') return <span style={{ color: 'limegreen' }}>Approved</span>
            if (value === 'awaiting_approval') return <Typography.Text type='danger'>Awaiting Approval</Typography.Text>
            if (value === 'denied') return <Typography.Text type='danger'>Denied</Typography.Text>
            return null
        }
    },
    {
        key: 'email_verified',
        display: 'Email Verified',
        getValue: getUserMappedAttrFn('email_verified'),
        render: booleanRenderer
    },
    {
        key: 'phone_number_verified',
        display: 'Phone Number Verified',
        getValue: getUserMappedAttrFn('phone_number_verified'),
        render: booleanRenderer
    },
    {
        key: 'sub',
        display: 'Sub (user ID)',
        getValue: getUserMappedAttrFn('sub')
    },
    {
        key: 'Enabled',
        display: 'Enabled',
        getValue: user => user.Enabled,
        render: booleanRenderer
    },
    {
        key: 'UserCreateDate',
        display: 'User Create Date',
        getValue: user => user.UserCreateDate
    },
    {
        key: 'UserLastModifiedDate',
        display: 'User Last Modified Date',
        getValue: user => user.UserLastModifiedDate
    }
]

function addUserColumnData(user: any) {
    if (!user) return;
    let columnData = {};
    for (let index = 0; index < fieldMapping.length; index++) {
        const column = fieldMapping[index];
        let val = null;
        if (column.getValue) {
            val = column.getValue(user);
        }
        columnData[column.key] = val;
    }
    user.columnData = columnData;
}

function renderColumns(users: Array<any>): Array<React.ReactElement<ColumnProps<any>>> {

    const cellRenderer = (fieldKey: string, render?: (val: any) => React.ReactElement) => (idx: number) => {
        let val = users[idx].columnData[fieldKey];
        let cellContent: any;
        if (render) cellContent = render(val);
        else cellContent = val != null ? `${val}` : null;
        return <Cell>{cellContent}</Cell>
    }

    return fieldMapping.map(mapping => {
        return <Column
            key={mapping.key}
            name={mapping.display}
            cellRenderer={cellRenderer(mapping.key, mapping.render)}
        />
    })
}

const FilterTypeFunctions = {
    confirmed: user => user?.columnData?.UserStatus === 'CONFIRMED',
    unconfirmed: user => user?.columnData?.UserStatus === 'UNCONFIRMED',
    disabled: user => user?.columnData?.Enabled === false,
    enabled: user => user?.columnData?.Enabled === true,
    approved: user => user?.columnData?.approvalStatus === 'approved',
    waitingForApproval: user => user?.columnData?.approvalStatus === 'awaiting_approval',
    deniedApproval: user => user?.columnData?.approvalStatus === 'denied'
}

const AdminUserPage: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const [search, setSearch] = useState('');
    const [sortType, setSortType] = useState('none');
    const [filterType, setFilterType] = useState('all');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [sortAscending, setSortAscending] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userToBeDeleted, setUserToBeDeleted] = useState(null);

    function filterRequiresSearchInput() {
        return !(filterType in FilterTypeFunctions);
    }

    function removeFilters() {
        setSearch('');
        setFilterType('all');
    }

    const filtersApplied = search ? true : false || !filterRequiresSearchInput();
    const sortApplied = sortType && sortType !== 'none';

    const fetchMoreUsers = async (fetchMore: any, pageToken: string): Promise<any> => {
        let newResult;
        await fetchMore({
            variables: {
                paginationToken: pageToken
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                console.debug('new token: ' + fetchMoreResult.AdminCognitoListUsers.paginationToken);
                if (!fetchMoreResult) return prev;
                newResult = {
                    ...prev,
                    AdminCognitoListUsers: {
                        ...prev.AdminCognitoListUsers,
                        users: [
                            ...prev.AdminCognitoListUsers.users,
                            ...fetchMoreResult.AdminCognitoListUsers.users
                        ],
                        paginationToken: fetchMoreResult.AdminCognitoListUsers.paginationToken
                    }
                }
                return newResult;
            }
        })
        return newResult;
    }

    const { data, error, networkStatus, fetchMore, paginating, pageCount, paginationError, refetchAndPaginate, startAutoPagination, stopAutoPagination, ...restQueryResult } = useAutoPaginatedQuery(
        QUERY_USERS,
        {
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
            errorPolicy: 'all',
            getNextToken: async (token) => {
                let result = await fetchMoreUsers(fetchMore, token);
                return result.AdminCognitoListUsers.paginationToken;
            },
            getTokenFromData: (data) => data.AdminCognitoListUsers.paginationToken,
            maxPages: MAX_PAGES,
            autoPaginate: false
        }
    );

    useEffect(() => {
        if (filtersApplied || sortApplied) {
            startAutoPagination();
        }
        // eslint-disable-next-line
    }, [filtersApplied, sortApplied])

    function filterUserBySearchInput(user: any) {
        if (!search) return true;
        let matched = false;
        for (let prop in user.columnData) {
            const value = String(user.columnData[prop]).toLowerCase();
            let searchVal = search.toLowerCase();
            if (
                filterType === 'all' ||
                (!filterRequiresSearchInput() && searchVal) ||
                (prop === filterType)
            ) {
                matched = value.includes(searchVal);
            }
            if (matched)
                break;
        }
        return matched;
    }

    function compareUsers(userA: any, userB: any) {
        const valueA = String(userA.columnData && userA.columnData[sortType]).toLowerCase();
        const valueB = String(userB.columnData && userB.columnData[sortType]).toLowerCase();
        return valueA.localeCompare(valueB);
    }

    const users = data && data.AdminCognitoListUsers ? data.AdminCognitoListUsers.users : []
    if (users.length > 0) {
        users.forEach(addUserColumnData);
    }
    const paginationToken = data && data.AdminCognitoListUsers ? data.AdminCognitoListUsers.paginationToken : []

    // TODO: Fix this useEffect that causes an infinte loop. It's supposed to update the current selectedUser state when it detects that the
    // user query has changed, but somehow it appears calling setSelectedUser will trigger an infinite loop.
    // useEffect(() => {
    //     if (networkStatus === NetworkStatus.loading || error || !data || !selectedUser) return;
    //     if (!Array.isArray(users)) return;
    //     for (let idx in users){
    //         if (users[idx].Username === selectedUser.Username){
    //             setSelectedUser(users[idx])
    //         }
    //     }
    // // eslint-disable-next-line
    // }, [data?.AdminCognitoListUsers?.users])

    // Save user mutation
    const [saveUser, { loading: _userSaveLoading, ...saveUserMutationResult }] = useMutation(MUTATION_SAVE_USER, {
        onError: (e) => {
            message.error("Failed to save user changes. Check console for details.");
        },
        onCompleted: () => {
            message.success('User saved successfully');
            refetchAndPaginate();
        }
    })

    const [createUser, { loading: userCreateLoading, ...createUserMutationResult }] = useMutation(MUTATION_CREATE_USER, {
        onError: (e) => {
            message.error("Failed to create user. Check console for details.");
        },
        onCompleted: () => {
            message.success('User created successfully');
            refetchAndPaginate();
        }
    })

    let userSaveLoading = _userSaveLoading || userCreateLoading;

    const MDState = useMasterDataStateNoQueryHook({
        data: users,
        saveMutationResult: selectedUser ? {
            loading: _userSaveLoading,
            ...saveUserMutationResult
        } : {
            loading: userCreateLoading,
            ...createUserMutationResult
        },
        queryResult: {
            data,
            error,
            networkStatus,
            fetchMore,
            ...restQueryResult
        },
        transformEditFromExisting: (values) => {
            return {
                ...values,
                organization: getLabelInValue(values, 'organizationName', 'organization'),
                phone_number: CognitoPhoneNumber.parseString(values.phone_number),
                company_phone_number: CognitoPhoneNumber.parseString(values.company_phone_number)
            }
        },
        onCancel: () => {
            console.log('onCancel called!');
            setSelectedUser(null);
        },
        onSave: ({ attrs, localAttrs, deletedAttrs, tempPassword, desiredDeliveryMediums }) => {

            let attrArray = Object.entries(attrs)
                .map(([k, v]) => ({
                    __typename: 'CognitoUserAttribute',
                    Name: k,
                    Value: v
                }))
                .filter((attr) => attr.Value !== undefined)

            let options: MutationFunctionOptions = {
                variables: {
                    username: MDState.isNewEntry ? attrs.email : selectedUser.Username,
                    attributes: isEmpty(attrs) ? undefined : JSON.stringify(attrs),
                    deletedAttributeNames: deletedAttrs ? deletedAttrs : undefined,
                }
            }

            if (MDState.isNewEntry) {
                options.variables.tempPassword = tempPassword;
                options.variables.desiredDeliveryMediums = desiredDeliveryMediums;
                options.update = (cache, result) => {
                    const userCreateResult = JSON.parse(result.data.AdminCognitoCreateUser);
                    let newUser = {
                        ...userCreateResult.User,
                        mappedAttributes: localAttrs
                    }
                    if (newUser.Attributes) {
                        newUser.Attributes.map((att) => ({
                            ...att,
                            __typename: 'CognitoUserAttribute'
                        }))
                    }
                    let newData = {
                        ...data,
                        AdminCognitoListUsers: {
                            ...data.AdminCognitoListUsers,
                            users: [
                                newUser,
                                ...data.AdminCognitoListUsers.users
                            ]
                        }
                    }

                    try {
                        cache.writeQuery({
                            query: QUERY_USERS,
                            data: newData
                        })
                    }
                    catch (err) {
                        console.log('Could not add user to table UI:', err);
                    }
                    MDState.editFromExisting(newUser);
                    setSelectedUser(newUser);
                }
                return createUser(options);
            }
            options.update = (cache) => {
                cache.writeQuery({
                    query: QUERY_USERS,
                    data: {
                        AdminCognitoListUsers: {
                            ...data.AdminCognitoListUsers,
                            users: data.AdminCognitoListUsers.users.map(user => {
                                if (user.Username === selectedUser?.Username) {

                                    const toObj = (attrs: any[]): any => {
                                        let obj = {};
                                        attrs.forEach(attr => obj[attr.Name] = attr.Value);
                                        return obj;
                                    }

                                    // Merge the old Atrributes array with the new one
                                    let newAttrs = user.Attributes.map(attr => {
                                        if (attrs[attr.Name]) {
                                            attr.Value = attrs[attr.Name]
                                        }
                                        return attr
                                    });
                                    let newAttrsMap = toObj(newAttrs);

                                    attrArray.forEach(attr => {
                                        if (!(attr.Name in newAttrsMap)) {
                                            newAttrs.push(attr)
                                        }
                                    });
                                    let newUser = {
                                        ...user,
                                        Attributes: newAttrs,
                                        mappedAttributes: {
                                            ...user.mappedAttributes,
                                            ...localAttrs
                                        }
                                    }

                                    return newUser;
                                }
                                return user;
                            })
                        }
                    }
                })
            }
            return saveUser(options)
        }
    })

    // Delete user mutation
    const [deleteUser, { loading: deleteUserLoading }] = useMutation(MUTATION_DELETE_USER, {
        variables: {
            username: selectedUser?.Username
        },
        onCompleted: () => {
            setUserToBeDeleted(null);
            MDState.cancelEditing(false);
            message.success("User successfully deleted.");
        },
        onError: () => {
            setUserToBeDeleted(null);
            message.error("Failed to delete user. Check console for details.");
        },
        update: (cache) => {
            let newUsers = data.AdminCognitoListUsers.users.filter(user => {
                return user.Username !== userToBeDeleted?.Username
            });
            cache.writeQuery({
                query: QUERY_USERS,
                data: {
                    AdminCognitoListUsers: {
                        ...data.AdminCognitoListUsers,
                        users: newUsers
                    }
                }
            })
        }
    })

    useEffect(() => {
        if (userToBeDeleted) {
            deleteUser({
                variables: {
                    username: userToBeDeleted?.Username
                }
            });
        }
        // eslint-disable-next-line
    }, [userToBeDeleted?.Username])

    // // Reset error message after selected user is changed
    // useEffect(() => {
    //     setUserSaveError(null);
    // }, [ selectedUser?.Username ])

    // Apply filters every time the page is advanced
    useEffect(() => {
        if (error || paginationError) return;
        if ((!users || users.length <= 0) && filteredUsers.length <= 0) return;
        let fUsers = users;
        if (filtersApplied) {
            if (filterType in FilterTypeFunctions) {
                fUsers = fUsers.filter(FilterTypeFunctions[filterType]);
            }
            if (search) {
                fUsers = fUsers.filter(filterUserBySearchInput);
            }
        }
        if (sortApplied) {
            fUsers = fUsers.sort(compareUsers);
            if (sortAscending) {
                fUsers = fUsers.reverse();
            }
        }
        if (filtersApplied || sortApplied) {
            setFilteredUsers(fUsers);
        }
        else {
            setFilteredUsers(users);
        }
        // eslint-disable-next-line
    }, [paginationToken, search, sortType, filterType, paginating, sortAscending, users?.length])

    // useEffect(() => {
    //     if (filtersApplied && sortType && sortType !== 'none'){
    //         setFilteredUsers(filteredUsers.sort(compareUsers));
    //     }
    // }, [ paginationToken, sortType, filtersApplied ])

    let initialColWidths = [64]

    const renderedColumns = renderColumns(filteredUsers);

    const colWidthValues = useColWidths(renderedColumns.length + 1, initialColWidths);

    function renderTable() {
        if (networkStatus === NetworkStatus.refetch) {
            return <Spin style={{ display: 'flex', height: '100%' }} indicator={<Icon type="loading" />}>
                <Table className="mc-table" numRows={60}>
                    {fieldMapping.map((col) => <Column name={col.display} />)}
                </Table>
            </Spin>
        }

        return <BlueprintTableInfiniteScroller
            hasMore={paginationToken ? true : false && networkStatus !== NetworkStatus.loading && !paginating}
            loadMore={() => {
                return fetchMoreUsers(fetchMore, paginationToken);
            }}
        >
            <MDTable
                numRows={filteredUsers.length}
                selectedRowIndex={filteredUsers.findIndex((user) => {
                    return user.mappedAttributes && MDState.entryFields && user.mappedAttributes.sub === getFormFieldValue(MDState.entryFields.sub);
                })}
                onEditRow={(idx) => {
                    MDState.editFromExisting(filteredUsers[idx].mappedAttributes);
                    setSelectedUser(filteredUsers[idx]);
                }}
                onEditCancel={() => MDState.cancelEditing()}
                className="mc-table"
                selectionModes={SelectionModes.ROWS_ONLY}
                renderMode={RenderMode.BATCH}
                numFrozenColumns={1}
                onDelete={(idx) => {
                    setUserToBeDeleted(filteredUsers[idx]);
                }}
                deleteLoadingIdx={deleteUserLoading ? filteredUsers.findIndex((user) => user?.Username === userToBeDeleted?.Username) : -1}
                colWidthsHookValues={colWidthValues}
            >
                {renderedColumns}
            </MDTable>
        </BlueprintTableInfiniteScroller>
    }

    function renderBody() {
        const render = [];
        if (paginating) {
            let msgText = 'filters';
            if (!filtersApplied && sortApplied) {
                msgText = 'sort'
            }
            render.push(
                <Alert
                    type="info"
                    icon={<Icon type="loading" spin />}
                    message={`Applying ${msgText}`}
                    description={<span>{`Retrieved ${pageCount} pages of data (max ${MAX_PAGES})`}<Divider type="vertical" /><Button className="mc-link-btn" onClick={() => stopAutoPagination()}>Stop</Button></span>}
                    banner
                />
            )
        }
        if (filtersApplied && !paginating) {
            render.push(
                <Alert
                    type="info"
                    showIcon
                    message="Filter is applied"
                    description={<span>{`${filteredUsers.length} results found.`}{restQueryResult.wasStopped ? <><Divider type="vertical" /><Button className="mc-link-btn" onClick={() => startAutoPagination(true)}>Continue Pagination</Button></> : null}<Divider type="vertical" /><Button className="mc-link-btn" onClick={() => removeFilters()}>Remove Filter</Button></span>}
                    banner
                />
            )
        }
        if (error || paginationError) {
            render.push(
                <Alert
                    type="error"
                    showIcon
                    message="An error occurred"
                    description={`${error || paginationError}`}
                    banner
                />
            )
        }
        render.push(renderTable())
        return render;
    }

    function toggleAscending() {
        setSortAscending(!sortAscending);
    }

    function renderExtra() {
        const filterLabel = <strong>Filter:</strong>
        const refetchBtn = <Tooltip placement="bottomLeft" title="Refresh">
            <Button icon={networkStatus < 6 && networkStatus !== NetworkStatus.fetchMore ? 'loading' : 'reload'} onClick={() => refetchAndPaginate()} />
        </Tooltip>
        const filterExtra = [
            <Tooltip title="Quickly applies filters and sorts to see all unapproved users sorted from newest to oldest.">
                <Button
                    type='primary'
                    onClick={() => { setSortType("UserCreateDate"); setSortAscending(true); setFilterType("waitingForApproval"); }}
                >
                    New User Applications
                </Button>
            </Tooltip>,
            <Tooltip title={` ${sortAscending ? 'Ascending' : 'Descending'}`} placement="bottom">
                <Button disabled={sortType === 'none'} icon={sortAscending ? 'up' : 'down'} onClick={toggleAscending} />
            </Tooltip>,
            <Select
                defaultValue="email"
                style={{ width: '10rem' }}
                onChange={setSortType}
                value={sortType}
                disabled={networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch}
                showAction={["focus", "click"]}
            >
                <Select.Option value="none">Do not sort</Select.Option>
                <Select.Option value="UserCreateDate">Sort by Created Date</Select.Option>
                <Select.Option value="email">Sort by Email</Select.Option>
                <Select.Option value="family_name">Sort by Family Name</Select.Option>
                <Select.Option value="given_name">Sort by Given Name</Select.Option>
                <Select.Option value="organizationName">Sort by Organization</Select.Option>
            </Select>,
            <Select
                defaultValue="all"
                style={{ width: '10rem' }}
                onChange={setFilterType}
                value={filterType}
                disabled={networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch}
                showAction={["focus", "click"]}
                dropdownMatchSelectWidth={false}
            >
                <Select.Option value="all">Filter all columns</Select.Option>
                <Select.Option value="organizationName">Filter organization</Select.Option>
                <Select.Option value="confirmed">Confirmed</Select.Option>
                <Select.Option value="unconfirmed">Unconfirmed</Select.Option>
                <Select.Option value="disabled">Disabled</Select.Option>
                <Select.Option value="enabled">Enabled</Select.Option>
                <Select.Option value="approved">Approved</Select.Option>
                <Select.Option value="waitingForApproval">Waiting for approval</Select.Option>
                <Select.Option value="deniedApproval">Denied approval</Select.Option>
            </Select>,
            <Input.Search
                onChange={e => setSearch(e.target.value)}
                value={search}
                placeholder="Type filter query"
                style={{ width: '20rem' }}
                enterButton="Filter"
                allowClear
                disabled={networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch}
            />
        ]


        if (MDState.isEditing) {
            const content = <div style={{ display: 'flex' }}>
                {filterExtra.map((el, idx) => <div key={idx} style={{ marginLeft: idx > 0 ? '8px' : undefined }}>{el}</div>)}
            </div>
            const filterBtn = <Popover content={content} trigger="click">
                <Button icon="filter" />
            </Popover>
            return [
                filterLabel,
                filterBtn,
                refetchBtn
            ]
        }
        return [
            filterLabel,
            ...filterExtra,
            refetchBtn,
            <Divider type="vertical" style={{ height: '1.8rem', margin: '0 8px' }} />,
            <Button
                style={{ margin: 0 }}
                type="primary"
                onClick={() => MDState.editFromNew()}
            >New User</Button>
        ]
    }

    // Disable user mutation
    const [disableUser, { loading: disableUserLoading }] = useMutation(MUTATION_DISABLE_USER, {
        variables: {
            enableUser: !selectedUser?.Enabled,
            username: selectedUser?.Username
        },
        onError: () => message.error("Failed to disable/enable user. Check console for details."),
        update: (cache, { data: { AdminCognitoDisableUser: disabled } }) => {
            cache.writeQuery({
                query: QUERY_USERS,
                data: {
                    AdminCognitoListUsers: {
                        ...data.AdminCognitoListUsers,
                        users: data.AdminCognitoListUsers.users.map(user => {
                            if (user.Username === selectedUser?.Username) {
                                return {
                                    ...user,
                                    Enabled: !disabled
                                }
                            }
                            return user;
                        })
                    }
                }
            })
            setSelectedUser({
                ...selectedUser,
                Enabled: !disabled
            })
        }
    });

    function confirmDeleteAccount() {
        Modal.confirm({
            title: 'Are you sure you want to delete this user account?',
            content: 'THIS ACTION CANNOT BE REVERSED!',
            okText: 'Delete',
            okType: 'danger',
            onOk: () => {
                setUserToBeDeleted(selectedUser);
            }
        })
    }

    function handleUserGroupChange(cache: DataProxy, userGroups: Array<string>) {
        cache.writeQuery({
            query: QUERY_USERS,
            data: {
                AdminCognitoListUsers: {
                    ...data.AdminCognitoListUsers,
                    users: data.AdminCognitoListUsers.users.map(user => {
                        if (user.Username === selectedUser?.Username) {

                            let newUser = { ...user };
                            user.groups = userGroups;

                            return newUser;
                        }
                        return user;
                    })
                }
            }
        })
    }

    return <MDLayout
        {...MDStateToLayoutProps(MDState)}
        style={{
            height: 'calc(100% + 48px)',
            margin: '-24px',
            ...props.style
        }}
        headerElement={
            <PageHeader
                className="mc-page-header-extra-flex"
                title="User Management"
                style={{
                    padding: '0.5rem'
                }}
                extra={renderExtra()}
            />
        }
        detailsElement={
            <UserMDDetails
                selectedUser={selectedUser}
                MDState={MDState}
                onDeleteUser={() => confirmDeleteAccount()}
                onDisableUser={() => disableUser()}
                disableUserLoading={disableUserLoading}
                deleteUserLoading={deleteUserLoading}
                saveUserLoading={userSaveLoading}
                onUserGroupsChange={handleUserGroupChange}
            />
        }
        tableElement={renderBody()}
    />

}

export default AdminUserPage;