import { Alert, Icon, List } from 'antd';
import { FlightCgo, FlightPax } from 'common/flight-leg/flight-leg-manager';
import { getFlightLegManagerFromFlightQuery } from 'common/flight-leg/util';
import { getPersonFullName } from 'common/util';
import React, { useState } from 'react';

export interface UnassignedPaxCgoWarningProps {
    flightData: any
}

interface State {
    showPaxList: boolean,
    showCgoList: boolean
}

const UnassignedPaxCgoWarning: React.FC<UnassignedPaxCgoWarningProps> = (props) => {
    let [ state, _setState ] = useState<State>({
        showPaxList: false,
        showCgoList: false
    })

    function setState(newState) { _setState({ ...state, ...newState }) }

    function resolvePax(p: FlightPax): any{ props.flightData.paxIDList.find(pax => pax._id === p._id) }
    function resolveCgo(c: FlightCgo): any{ props.flightData.cgoIDList.find(cgo => cgo._id === c._id) }

    let flManager = getFlightLegManagerFromFlightQuery(props.flightData);
    let { pax, cgo } = flManager.findUnassigned();

    let render = [];

    if (pax.length > 0 || cgo.length > 0) return <></>;
    
    if (pax.length > 0){
        let paxData = pax.map(resolvePax);

        render.push(
            <Alert
                message={paxData.length + ' passengers are not assigned to a leg'}
                description={
                    <List
                        dataSource={paxData}
                        renderItem={(pax) => <List.Item extra={
                            <span>
                                {pax.departureID?.name}
                                <Icon type='right-arrow' />
                                {pax.destinationID?.name}
                            </span>
                        }>{getPersonFullName(pax)}</List.Item>}
                    />
                }
            />
        )
    }

    return <>{render}</>;
}

export default UnassignedPaxCgoWarning