import { Calendar, Select } from 'antd';
import 'css/Calendar.less';
import 'css/Drawer.css';
import moment, { Moment } from 'moment';
import React from 'react';
import { zerofixed } from '../util';
import './ScheduleCalendar.css';


// Renders a list of select options of the current month and all months ahead.
// Does not list months before the current month.
export function getMonthOptions(date: Moment){
    let monthOptions = [];

    for (let index = -12; index <= 12; index++) {
        let offset = date.clone().add(index, 'months');
        if (offset.isBefore(moment(), 'month')){
            continue
        }
        let text = offset.format('MMMM - YY');
        monthOptions.push(
          <Select.Option key={offset.format('YYYY-MM')}>
            {text}
          </Select.Option>,
        );
    }

    return monthOptions;
}

export interface SchedulingCalendarProps<DataType=any> {
    value?: moment.Moment,
    data?: Array<DataType>,
    renderDateCell?(data: Array<DataType>): { title: React.ReactElement | React.ReactText, content: React.ReactElement },
    onCellClick?(date: moment.Moment, data: Array<DataType>): void,
    getDataDate(dataItem: DataType): moment.Moment | string
}

const SchedulingCalendar: <DataType=any>(props: SchedulingCalendarProps<DataType>) => React.ReactElement<SchedulingCalendarProps<DataType>> = (props) => {
    
    function getData(date: moment.Moment){
        return props.data.filter((dataItem) => {
            if (dataItem && date.isSame(props.getDataDate(dataItem), 'day')){
                return true
            }
            return false
        })
    }

    return <>
    <Calendar
        className="mc-calendar"
        mode="month"
        headerRender={() => {
            return null
        }}
        value={props.value}
        dateFullCellRender={(date: moment.Moment) => {
            let dateData = getData(date);
            let cellData: { title: React.ReactElement | React.ReactText, content: React.ReactElement };
            if (props.renderDateCell && dateData.length)
                cellData = props.renderDateCell(dateData);
            
            return <div
                    className={`ant-fullcalendar-date ${!dateData.length ? 'scheduling-date-no-data' : ''}`}
                    onClick={() => {
                        if (dateData.length && props.onCellClick){
                            props.onCellClick(date, dateData)
                        }
                    }}
                >
                <div style={{ display: 'flex' }}>
                {(cellData && cellData.title) ? <div>{cellData.title}</div> : null}
                    <div style={{ flex: 1, cursor: 'default' }} className={`ant-fullcalendar-value`}>{zerofixed(date.date())}</div>
                </div>
                <div className={`ant-fullcalendar-content`}>
                    {cellData && cellData.content}
                </div>
            </div>
        }}
    />
    </>
}

export default SchedulingCalendar