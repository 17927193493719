import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import CountryCheckTransfer from 'components/transfer/CountryCheckTransfer';
import SteelToeDestinationsTransfer from 'components/transfer/SteelToeDestinationsTransfer';
import React, { forwardRef, LegacyRef, useRef } from 'react';
import { entryFormOptions, EntryFormProps, useAutoFocus } from '.';
import OrgNotifEmailListEditor, { validateOrgNotifEmailListEditor } from 'components/org-notif-email-list-editor';
import useUserGroups from 'hooks/useUserGroups';

export interface CustomerEntryFormProps extends EntryFormProps {

}

const CustomerEntryForm: React.FC<CustomerEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const [ userGroups ] = useUserGroups();

    const nameInputRef = useRef(null);
    const ebsAirCanisterRequiredCheckBoxRef = useRef(null);
    const extraBroadCheckRequiredCheckBoxRef = useRef(null);
    const illnessCheckRequiredCheckBoxRef = useRef(null);
    const quarantinedCheckRequiredCheckBoxRef = useRef(null);
    const quarantineTravelPeriodInputRef = useRef(null);
    const askHaveYouBeenVaccinatedQuestionCheckBoxRef = useRef(null);
    const { getFieldDecorator, getFieldValue } = props.form;

    useAutoFocus(props.autoFocus, nameInputRef.current);

    return <Form ref={ref}>
        <Form.Item label="Name">
        {getFieldDecorator('name', {
            rules: [
                { required: true, message: 'Name is required' }
            ]
        })(<Input ref={nameInputRef} />)}
        </Form.Item>
        {userGroups.includes('dataflyt.superuser') ?
            <Form.Item label="Email Triggers">
                {getFieldDecorator('notificationEmailList', {
                    rules: [{
                        validator: validateOrgNotifEmailListEditor
                    }]
                })
                (<OrgNotifEmailListEditor />)}
            </Form.Item>
        : null}
        {props.dupeChecker}
        <Row gutter={24}>
            <Col span={8}>
                <Form.Item>
                    {getFieldDecorator('ebsAirCanisterRequired')(<Checkbox checked={getFieldValue('ebsAirCanisterRequired')}ref={ebsAirCanisterRequiredCheckBoxRef}>EBS Air Canister Required</Checkbox>)}
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item>
                    {getFieldDecorator('illnessCheckRequired')(<Checkbox checked={getFieldValue('illnessCheckRequired')}ref={illnessCheckRequiredCheckBoxRef}>Illness Check Required</Checkbox>)}
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item>
                    {getFieldDecorator('askHaveYouBeenVaccinatedQuestion')(<Checkbox checked={getFieldValue('askHaveYouBeenVaccinatedQuestion')}ref={askHaveYouBeenVaccinatedQuestionCheckBoxRef}>Vaccine Check Required</Checkbox>)}
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={24}>
            <Col span={12}>
                <Form.Item>
                    {getFieldDecorator('extraBroadCheckRequired')(<Checkbox checked={getFieldValue('extraBroadCheckRequired')} ref={extraBroadCheckRequiredCheckBoxRef}>Extra Broad Check Required</Checkbox>)}
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item>
                    {getFieldDecorator('quarantinedCheckRequired')(<Checkbox checked={getFieldValue('quarantinedCheckRequired')}ref={quarantinedCheckRequiredCheckBoxRef}>Quarantine Check Required</Checkbox>)}
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={24}>
            <Col span={12}>
            </Col>
            <Col span={12}>
                <Form.Item label="Quarantine Travel Period">
                {getFieldDecorator('quarantineTravelPeriod', {
                    initialValue: 14
                })(<InputNumber ref={quarantineTravelPeriodInputRef}/>)}
                </Form.Item>
            </Col>
        </Row>
        <Form.Item label="Select Quarantined Countries">
            {getFieldDecorator('quarantinedCountries', {
                valuePropName: 'targetKeys'
            })(
                <CountryCheckTransfer
                    listStyle={{
                        width: '20rem',
                        height: '20rem'
                    }}
                />
            )}
        </Form.Item>
        <Form.Item label="Select Locations that Require Steel Toe">
            {getFieldDecorator('steelToeIDDestinations', {
                valuePropName: 'targetKeys'
            })(
                <SteelToeDestinationsTransfer
                    listStyle={{
                        width: '20rem',
                        height: '40rem',
                        overflow: 'auto'
                    }}
                />
            )}
        </Form.Item>
    </Form>
})

export default Form.create<CustomerEntryFormProps & FormComponentProps>(entryFormOptions<CustomerEntryFormProps>())(CustomerEntryForm)