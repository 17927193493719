import { DatePicker, Form, Input, Select } from "antd";
import moment from 'moment';
import React from 'react';
import { LocationSelect } from '../../form/select/LocationSelect';
import { OrganizationSelect } from '../../form/select/OrganizationSelect';
import { getFieldKey } from '../fieldTypes';

const { RangePicker } = DatePicker;

const DATERANGE = getFieldKey('dateRange')
const CUSTOMERS = getFieldKey('customers');
const NAME = getFieldKey('name');
const EMPLOYERS = getFieldKey('employers');

export const formatFields = (fields) => {
    var [ startDay, endDay ] = fields.dateRange;
    startDay = moment(startDay).startOf('day');
    endDay = moment(endDay).endOf('day');
    return {
        ...fields,
        dateRange: [startDay, endDay]
    }
}

const PaxIsnEventsForm = (form, fieldProps={}, currentCustomer, _, currentEmployer) => {

    const { getFieldDecorator } = form;

    return [
        <Form.Item key={DATERANGE} label="Date Range" {...fieldProps} >
            {getFieldDecorator(DATERANGE, {
                rules: [
                    {required: true, message: 'You must specify a date range'}
                ]
            })(<RangePicker format="YYYY-MM-DD" />)}
        </Form.Item>,
        <Form.Item key={CUSTOMERS} label="Customer" {...fieldProps} >
            {getFieldDecorator(CUSTOMERS, {
                initialValue: currentCustomer ? currentCustomer._id : [],
                rules: [
                    {required: currentCustomer ? true : false, message: 'Customer is required'}
                ]
            })(currentCustomer ? (
                <Select showAction={["focus", "click"]}>
                    <Select.Option value={currentCustomer._id}>{currentCustomer.name}</Select.Option>
                </Select>
            ) : (
            <OrganizationSelect
                classTypes={['flytsuite.customer']}
                mode="multiple"
                labelInValue={false}
            />)
            )}
        </Form.Item>,
        <Form.Item key={NAME} label="Passenger Name" {...fieldProps} >
        {getFieldDecorator(NAME)(<Input />)}
        </Form.Item>,
        <Form.Item key={EMPLOYERS} label="Employer" {...fieldProps}>
            {getFieldDecorator(EMPLOYERS, {
                initialValue: []
            })(currentEmployer ? (
                <Select showAction={["focus", "click"]}>
                    <Select.Option value={currentEmployer._id}>{currentEmployer.name}</Select.Option>
                </Select>
            ) : (
            <OrganizationSelect
                classTypes={['flytsuite.employer']}
                mode="multiple"
                labelInValue={false}
            />))
        }
        </Form.Item>
    ]
}

export default PaxIsnEventsForm