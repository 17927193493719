import React from 'react';
import { Button, Alert, Select, Input, Form, Popconfirm, Icon } from 'antd';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import WithOrgData from './WithOrgData';
import { UserGroup } from './userGroup';
import NotAuthorized from './NotAuthorized';
import withUserInfo from 'components/withUserInfo';
import { validateFirstLastName } from 'formValidators';
import IsnOverrideFileUploader from 'components/isn-override-file-uploader';

const OVERRIDE_MUTATION = gql`
mutation OverrideMutation($_id: ID!, $_rev: ID!, $notes: String, $reason: String, $type: BrdRestrictionType, $orgID: ID!,
            $overriderTitle: String, $overriderReason: String, $overriderFirstName: String, $overriderLastName: String,
            $redWhiteOverride: String, $approverFirstName: String, $approverLastName: String){
    paxOverride(_id: $_id,_rev: $_rev, notes: $notes, reason: $reason, type: $type, orgID: $orgID,
            overriderTitle: $overriderTitle, overriderReason: $overriderReason, overriderFirstName: $overriderFirstName, overriderLastName: $overriderLastName,
            redWhiteOverride: $redWhiteOverride, approverFirstName: $approverFirstName, approverLastName: $approverLastName){
        _id
        _rev
        authorization{
            brdRestriction_Isn {
                type
                notes
                status
                reason
                overriderTitle
                overriderReason
                redWhiteOverride
                overriderFirstName
                overriderLastName
                approverFirstName
                approverLastName
                createdBy
                createdTs
            }
        }
        lastIsnResultWithHeader {
            success
            qc_count
            isn_customer
            isn_ownerid
            qc_result {
                isnStatus {
                    shortLabel
                }
                trainingStatus
                allowed
                companyID
                quickCheckStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
            },
            selectedResult {
                isnStatus {
                    shortLabel
                }
                allowed
                companyID
                quickCheckStatus
                trainingStatus
                errorMessage
                isnId
                firstName
                lastName
                companyName
                mostRecentTrainingQualificationExpirationName
                mostRecentTrainingExpirationDate
                assignedActivities
                dataAsOf
                reason
            }
        }
    }
}`

const FirstLastNameInput = (props) => {

    let { value, onChange } = props;

    const handleChange = (type) => (e) => {
        onChange?.({ ...value, [type]: e.target.value });
    }

    return <Input.Group style={{ display: 'flex' }}>
        <Input value={value?.firstName} onChange={handleChange('firstName')} placeholder="First name" />
        <Input value={value?.lastName}  onChange={handleChange('lastName')}  placeholder="Last Name"  />
    </Input.Group>
}

class Override extends React.Component {
    requireNewFields = () => {
        return this.props.orgData.organization.requireOverrideFields1;
    }
    submit = (paxOverride) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const { isnData: {_id, _rev} } = this.props;
                const { type, notes, overriderTitle, overriderReason, overriderName, approverName, redWhiteOverride } = values;
                const orgID = this.props.orgData.organization._id;
                paxOverride({ 
                    variables: {
                        _id, _rev, type, notes, orgID,
                        overriderTitle, overriderReason, redWhiteOverride,
                        overriderFirstName: overriderName?.firstName,
                        overriderLastName: overriderName?.lastName,
                        approverFirstName: approverName?.firstName,
                        approverLastName: approverName?.lastName
                    }
                });
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 18 },
            },
          };
        const tailFormItemLayout = {
            wrapperCol: {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 20,
                offset: 6,
              },
            },
          };
        const statusList = [
            {
                value: 'MANUALLY_VERIFIED',
                text: 'Manually Verified'
            },
            {
                value: 'MANAGEMENT_OVERRIDE',
                text: 'Management Override'
            },
            {
                value: 'CUSTOMER_NOT_ENFORCING',
                text: 'Customer Not Enforcing'
            },
            {
                value: 'DO_NOT_OVERRIDE',
                text: 'Do Not Override'
            }
        ]
        try{
            return (
                <Mutation mutation={OVERRIDE_MUTATION} onCompleted={(data) => console.log('Override Complete:', data)}>
                    {
                        (paxOverride, { error, loading, data }) => {
                            if (error) {console.error(error)}
                            if (data) {console.log("DATA", data)}
                            return (
                                <React.Fragment>
                                    {error && <Alert style={{marginBottom: '1rem'}} type="error" message="Override Error" description={error.message} closable showIcon />}
                                    <Form>
                                        <Form.Item {...formItemLayout} label="Override Type">
                                            {getFieldDecorator('type', {
                                                rules: [
                                                    {
                                                        required: true, message: 'Please choose a reason for the override'
                                                    }
                                                ]
                                            })(
                                                <Select showAction={["focus", "click"]}>
                                                    {statusList.map((item, i) => <Select.Option key={i} value={item.value}>{item.text}</Select.Option>)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Overrider Title">
                                            {getFieldDecorator('overriderTitle', {
                                                rules: [{ required: this.requireNewFields(), message: 'This organization requires an Overrider Title' }]
                                            })(
                                                <Select showAction={["focus", "click"]}>
                                                    <Select.Option value="OIM">OIM</Select.Option>
                                                    <Select.Option value="CLERK">Clerk</Select.Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Training or Whitelist Override">
                                            {getFieldDecorator('redWhiteOverride', {
                                                rules: [{ required: this.requireNewFields(), message: 'This organization requires a Training or Whitelist Override' }]
                                            })(
                                                <Select showAction={["focus", "click"]}>
                                                    <Select.Option value="RED">RED (training)</Select.Option>
                                                    <Select.Option value="WHITE">WHITE (white listed)</Select.Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Overrider Reason">
                                            {getFieldDecorator('overriderReason', {
                                                rules: [{ required: this.requireNewFields(), message: 'This organization requires an Overrider Reason' }]
                                            })(
                                                <Input.TextArea autosize={{ minRows: 3, maxRows: 10 }} maxLength={300} />
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Overrider First/Last Name">
                                            {getFieldDecorator('overriderName', {
                                                rules: [{
                                                    required: this.requireNewFields(),
                                                    message: 'This organization requires an Overrider First/Last Name',
                                                    validator: validateFirstLastName
                                                }],
                                                initialValue: {
                                                    firstName: this.props.userinfo.user?.attributes.given_name,
                                                    lastName: this.props.userinfo.user?.attributes.family_name
                                                }
                                            })(
                                                <FirstLastNameInput />
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Approver First/Last Name">
                                            {getFieldDecorator('approverName', {
                                                rules: [{
                                                    required: this.requireNewFields(),
                                                    message: 'This organization requires an Approver First/Last Name',
                                                    validator: validateFirstLastName
                                                }]
                                            })(
                                                <FirstLastNameInput />
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Notes">
                                            {getFieldDecorator('notes')(
                                                <Input.TextArea placeholder="Enter notes here" autosize={{ minRows: 3, maxRows: 10 }}/>
                                            )
                                            }
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Upload Attachments">
                                            <IsnOverrideFileUploader paxID={this.props.isnData?._id} />
                                        </Form.Item>
                                        <Form.Item {...tailFormItemLayout} >
                                            <Popconfirm 
                                                title={<span>Are you sure you want to override?<br/>This action <strong>cannot</strong> be undone.</span>} 
                                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                                okText="OVERRIDE" cancelText="Cancel"
                                                onConfirm={() => this.submit(paxOverride)}>
                                                <Button type="danger" loading={loading}>OVERRIDE</Button>
                                            </Popconfirm>
                                        </Form.Item>
                                    </Form>
                                </React.Fragment>
                            )
                        }
                    }
                    
                </Mutation>
            )
        }catch(err){
            return <Alert type="error" message="Failed to load override" description={err.message} closable showIcon />
        }
    }
}


// class Override extends React.Component {
//     constructor(props) {
//         super(props);
//         this.form = React.createRef();
//         this.state = {
//             graphErrors: [],
//             isSubmitting: false
//         }
//     }
//     handleSubmit = () => {
//         this.props.form.validateFieldsAndScroll((err, values) => {
//             if (!err) {
//                 const { currentIsn: {_id, _rev} } = this.props;
//                 this.setState({ isSubmitting: true, graphErrors: [] });

//                 API.graphql(graphqlOperation(overrideMutation, {_id, _rev, ...values}))
//                 .then(resp => {
//                     this.setState({ isSubmitting: false });
//                     // console.log('overridden', resp);
//                     this.props.setPax(resp.data.paxOverride);
//                 })
//                 .catch(({errors}) => {
//                     console.error(errors);
//                     this.setState({ isSubmitting: false, graphErrors: errors });
//                 });
//             }
//         });
//     }
//     render() {
//         const { getFieldDecorator } = this.props.form;
//         const formItemLayout = {
//             labelCol: {
//               xs: { span: 24 },
//               sm: { span: 4 },
//             },
//             wrapperCol: {
//               xs: { span: 24 },
//               sm: { span: 20 },
//             },
//           };
//         const tailFormItemLayout = {
//             wrapperCol: {
//               xs: {
//                 span: 24,
//                 offset: 0,
//               },
//               sm: {
//                 span: 20,
//                 offset: 4,
//               },
//             },
//           };
//         const statusList = [
//             {
//                 value: 'MANUALLY_VERIFIED',
//                 text: 'Manually Verified'
//             },
//             {
//                 value: 'MANAGEMENT_OVERRIDE',
//                 text: 'Management Override'
//             },
//             {
//                 value: 'CUSTOMER_NOT_ENFORCING',
//                 text: 'Customer Not Enforcing'
//             },
//             {
//                 value: 'DO_NOT_OVERRIDE',
//                 text: 'Do Not Override'
//             }
//         ]
//         try{
//             return (
//                 <React.Fragment>
//                     {this.state.graphErrors.map((err, i) => 
//                         <Alert key={i} style={{marginBottom: '1rem'}} type="error" message="Override Error" description={err.message} closable showIcon />
//                     )}
//                     <Form>
//                         <Form.Item {...formItemLayout} label="Reason">
//                             {getFieldDecorator('type', {
//                                 rules: [
//                                     {
//                                         required: true, message: 'Please choose a reason for the override'
//                                     }
//                                 ]
//                             })(
//                                 <Select>
//                                     {statusList.map((item, i) => <Select.Option key={i} value={item.value}>{item.text}</Select.Option>)}
//                                 </Select>
//                             )}
//                         </Form.Item>
//                         <Form.Item {...formItemLayout} label="Notes">
//                             {getFieldDecorator('notes')
//                                 (<Input.TextArea placeholder="Enter notes here" autosize={{ minRows: 3, maxRows: 10 }}/>)
//                             }
//                         </Form.Item>
//                         <Form.Item {...tailFormItemLayout} >
//                             <Popconfirm 
//                                 title={<span>Are you sure you want to override?<br/>This action <strong>cannot</strong> be undone.</span>} 
//                                 icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
//                                 okText="OVERRIDE" cancelText="Cancel"
//                                 onConfirm={this.handleSubmit}>
//                                 <Button type="danger" loading={this.state.isSubmitting} >OVERRIDE</Button>
//                             </Popconfirm>
//                         </Form.Item>
//                     </Form>
//                 </React.Fragment>
//             )
//         }catch(err){
//             return <Alert type="error" message="Failed to load override" description={err.message} closable showIcon />
//         }


//     }
// }

// const mapStateToProps = state => ({
//     currentIsn: state.appPages.passenger.currentIsn
// })

// const mapDispatchToProps = dispatch => ({
//     setPax: (payload) => dispatch(setCurrentIsn(payload))
// })

const OverrideWithWrappers = withUserInfo(WithOrgData(Form.create()(Override)))

const WithPermissions = (props) => (
    <UserGroup.Flytsuite.Isn.Override renderNotAuthorized={
        <NotAuthorized desciption="You are not authorized to initiate overrides." style={{marginTop: '5rem'}} />
    }>
        <OverrideWithWrappers {...props} />
    </UserGroup.Flytsuite.Isn.Override>
)

export default WithPermissions