import React, { useRef, forwardRef, LegacyRef } from 'react';
import { EntryFormProps, entryFormOptions, useAutoFocus } from '.';
import { Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout } from 'common/form';
import { InputPhone } from 'components/form/InputPhone';
import emailValidator from 'email-validator';
import InputEmail from 'components/form/InputEmail';

export interface PilotEntryFormProps extends EntryFormProps {

}

const PilotEntryForm: React.FC<PilotEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const lastNameInputRef = useRef(null);
    const { getFieldDecorator } = props.form;

    useAutoFocus(props.autoFocus, lastNameInputRef.current);

    return <Form ref={ref}>
        <Form.Item label="Last Name" {...formItemLayout}>
            {getFieldDecorator('lastName', {
                rules: [ { required: true, message: 'Last Name is required' } ]
            })(<Input ref={lastNameInputRef} />)}
        </Form.Item>
        <Form.Item label="First Name" {...formItemLayout}>
            {getFieldDecorator('firstName', {
                rules: [ { required: true, message: 'First Name is required' } ]
            })(<Input />)}
        </Form.Item>
        {props.dupeChecker}
        <Form.Item label="Phone Number" {...formItemLayout}>
            {getFieldDecorator('phoneNumber', {
                rules: [ { required: true, message: 'Phone number is required' } ]
            })(<InputPhone />)}
        </Form.Item>
        <Form.Item label="Email" {...formItemLayout}>
            {getFieldDecorator('email', {
                rules: [
                    {
                        validator: (_, value, callback) => {
                            if (value && (value.address || value.type)){
                                if (!value.address){
                                    callback('You must enter an email address');
                                }
                                else if (!value.type){
                                    callback('You must choose an email type');
                                }
                            }
                            if (!value || (!value.address && !value.type) || emailValidator.validate(value.address)){
                                callback();
                            }
                            else
                            {
                                callback('Please use a valid email address');
                            }
                        }
                    }
                ]
            })(<InputEmail />)}
        </Form.Item>
    </Form>
})

export default Form.create<PilotEntryFormProps & FormComponentProps>(entryFormOptions<PilotEntryFormProps>())(PilotEntryForm)