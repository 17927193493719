import { Alert, Button, Col, Dropdown, Icon, Input, Layout, Menu, Modal, Radio, Row, Spin, Tabs } from 'antd';
import CargoSiderTab from 'components/scheduling/PaxCgoScheduler/CargoSiderTab';
import QueryPersonnelIDs from 'components/scheduling/PaxCgoScheduler/QueryPersonnelIDs';
import PaxCgoSchedulerTable from 'components/scheduling/PaxCgoScheduler/table';
import SetFormShedule from 'components/scheduling/SetsEditor/SetFormShedule';
import PersonSearch from 'components/search/PersonSearch';
import PersonSearchByCred from 'components/search/PersonSearchByCred';
import gql from 'graphql-tag';
import { useMultiSelect } from 'hooks/useMultiSelect';
import React, { useEffect, useRef, useState } from 'react';
import { Query } from 'react-apollo';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { safeGet } from '../../../common/util';
import MainHeader from '../MainHeader';
import MainLayout from '../MainLayout';
import SetForm from './SetForm';
import './SetsEditor.less';
// import PersonSearch from '../../PersonSearch/PersonSearch';
import SetsTable from './SetsTable';

const defaultTableRowKeys = {
    OUTBOUND: [],
    INBOUND: []
}

const SetsEditor = ({
    setData,
    isEditing=false,
    isNew=true,
    isScheduling=false,
    onEditChange=() => null,
    onSave=() => null,
    renderTable=null,
    onPersonsAdd,
    onPersonsRemove,
    onPersonsMove,
    onPersonsReorder,
    onFormChange,
    saving=false,
    saveError=null,
    mainHeaderRoutes,
    onScheduleChange,
    addPersonsLoading=false,
    selectedPersonKeys=[],
    selectedTableRowKeys=defaultTableRowKeys,
    transitType="OUTBOUND",
    onSelectedPersonKeysChange=() => null,
    onSelectedTableRowKeysChange=() => null,
    onTransitTypeChange=() => null,
    personsToSchedule=[],
    cargoToSchedule=[],
    onScheduledUpdate=() => null,
    onPersonsToScheduleChange=() => null,
    onPersonsToScheduleMove=() => null,
    onCargoToScheduleChange=() => null,
    onSchedule=() => null,
    onDefaultChargeCodeChange=() => null,
    defaultChargeCode,
    onDelete,
    deleting=false
}) => {
    const setsForm = useRef(null);
    
    const multiSelect = useMultiSelect({ primaryKey: '_id' });
    
    const [ cargoEditState, setCargoEditState ] = useState();

    const initialFormFields = {
        name: {
            value: safeGet(['data', 'getPersonSet', 'name'], setData)
        },
        departure: {
            value: {
                key: safeGet(['data', 'getPersonSet', 'departureID', '_id'], setData),
                label: safeGet(['data', 'getPersonSet', 'departureID', 'name'], setData)
            }
        },
        destination: {
            value: {
                key: safeGet(['data', 'getPersonSet', 'destinationID', '_id'], setData),
                label: safeGet(['data', 'getPersonSet', 'destinationID', 'name'], setData)
            }
        }
    }

    const [ formFields, setFormFields ] = useState(initialFormFields)

    const handleFormChange = (_, newFields) => {
        setFormFields(newFields);
        if (onFormChange){
            onFormChange(newFields);
        }
    }

    useEffect(() => {
        setFormFields(initialFormFields);
    // eslint-disable-next-line
    }, [ setData.loading ])

    useEffect(() => {
        if (!isEditing) {
            if (selectedPersonKeys.length > 0 ){
                onSelectedPersonKeysChange([]);
            }
            onSelectedTableRowKeysChange(defaultTableRowKeys);
            setFormFields(initialFormFields);
        }
    // eslint-disable-next-line
    }, [isEditing])

    const handlePersonsAdd = () => {
        if (onPersonsAdd){
            onPersonsAdd(selectedPersonKeys, transitType);
        }
    }

    const handlePersonsRemove = () => {
        if (onPersonsRemove) {
            onPersonsRemove(selectedTableRowKeys[transitType], transitType);
        }
    }

    const handleSchedulingRemove = () => {
        if(onPersonsToScheduleChange){
            onPersonsToScheduleChange(personsToSchedule[transitType].filter(item => !multiSelect.selectedKeys.includes(item._id)))
            onCargoToScheduleChange(cargoToSchedule["INBOUND"].filter(item => !multiSelect.selectedKeys.includes(item._id)))
        }
        multiSelect.clearAll()
    }

    const handleSchedulingPersonsMove = () => {
        if (onPersonsToScheduleMove){
            const destTT = transitType === 'OUTBOUND' ? 'INBOUND' : 'OUTBOUND';
            const selected = personsToSchedule[transitType].filter(item => multiSelect.selectedKeys.includes(item._id))
            onPersonsToScheduleMove(personsToSchedule[destTT].concat(selected), destTT)
        }
        multiSelect.clearAll()
    }

    const handlePersonsMove = () => {
        if (onPersonsMove){
            const destTT = transitType === 'OUTBOUND' ? 'INBOUND' : 'OUTBOUND';
            onPersonsMove(selectedTableRowKeys[transitType], destTT);
        }
    }

    const handleMoveRow = (dragIndex, hoverIndex) => {
        if (onPersonsReorder){
            onPersonsReorder(dragIndex, hoverIndex, transitType)
        }
    }

    const handleSave = () => {
        setsForm.current.validateFields((err, values) => {
            if (!err){
                onSave(values)
            }
        })
    }

    const handleSchedule = () => {
        setsForm.current.validateFields((err) => {
            if (!err){
                onSchedule({
                    date: formFields.date.value,
                    name: formFields.name.value.trim(),
                    lastKnownController: isScheduling ? safeGet(['lastKnownController', 'value', 'key'], formFields) : undefined,
                    departureID: safeGet(['departure', 'value', 'key'], formFields),
                    destinationID: safeGet(['destination', 'value', 'key'], formFields)
                })
            }
        })
    }

    let personList;
    try{
        personList = setData.data.getPersonSet[transitType.toLowerCase()] || []
        personList = personList.filter(p => p);
    }
    catch(err){
        personList = []
    }

    let mainHeaderProps = {
        routes: mainHeaderRoutes,
        actions: (
            <Row type="flex" gutter={12}>
                {onDelete ? (
                    <Col>
                        <Button
                            type="danger"
                            loading={deleting}
                            onClick={() => Modal.confirm({
                                title: 'Are you sure?',
                                content: 'Are you sure you want to delete this set?',
                                onOk: () => onDelete(setData.data.getPersonSet._id)
                            })}
                        >
                            <Icon type="delete" style={{ marginRight: 6 }} />Delete
                        </Button>
                    </Col>
                ) : null}
                {onScheduleChange ? (
                    <Col>
                        <Button onClick={() => onScheduleChange(true)}>Schedule</Button>
                    </Col>
                ) : null}
                <Col>
                    <Button onClick={() => onEditChange(true)} type="primary">
                        <Icon type="edit" style={{ marginRight: 6 }} />Edit
                    </Button>
                </Col>
            </Row>
        )
    }

    if (isEditing){
        mainHeaderProps.actions = (
            <Row type="flex" gutter={12}>
                <Col>
                    <Button onClick={() => onEditChange(false)}>Cancel</Button>
                </Col>
                {isScheduling ? (
                    <Col>
                        <Button loading={saving} disabled={/*disableSave || */setData.loading || setData.error} type="primary" onClick={handleSchedule} >
                            Schedule Set
                        </Button>
                    </Col>
                ) : (
                    <Col>
                        <Button loading={saving} disabled={/*disableSave || */setData.loading || setData.error} type="primary" onClick={handleSave} >
                            {isNew ? 'Save New Set' : 'Save Changes'}
                        </Button>
                    </Col>
                )}
            </Row>
        )
    }



    const renderContent = () => {
        if (setData.loading){
            return (
                <div style={{ textAlign: 'center', marginTop: '10rem' }}>
                    <Spin size="large" indicator={<Icon type="loading" spin />} />
                </div>
            )
        }

        let outbound = (setData.data && setData.data.getPersonSet && setData.data.getPersonSet.outbound) || [];
        outbound = outbound.filter(p => p);
        let inbound = (setData.data && setData.data.getPersonSet && setData.data.getPersonSet.inbound) || [];
        inbound = inbound.filter(p => p);

        return (
            <React.Fragment>
                {setData.error ? (
                    <Alert style={{ marginBottom: 12 }} type="error" showIcon message="Failed to load set" description={setData.error.message} />
                ) : null}
                <Row type="flex" gutter={12} style={{ marginBottom: 12 }}>
                    {!isScheduling ? (<Col>
                        <Radio.Group buttonStyle="solid" value={transitType} onChange={(e) => onTransitTypeChange(e.target.value)}>
                            <Radio.Button value="OUTBOUND">{`Outbound (${outbound.length})`}</Radio.Button>
                            <Radio.Button value="INBOUND">{`Inbound (${inbound.length})`}</Radio.Button>
                        </Radio.Group>
                    </Col>) : null}
                    <Col style={{ flex: 1 }}>
                        {isEditing && selectedTableRowKeys[transitType].length ? (
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <Menu>
                                        <Menu.Item onClick={handlePersonsRemove} key="remove" disabled={!selectedTableRowKeys[transitType].length}>Remove</Menu.Item>
                                        <Menu.Item onClick={handlePersonsMove} key="move" disabled={!selectedTableRowKeys[transitType].length}>
                                            {transitType === 'OUTBOUND' ? 'Move to inbound' : 'Move to outbound'}
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button>
                                    Actions <Icon type="down" />
                                </Button>
                            </Dropdown>
                        ) : isScheduling && multiSelect.selectedItems.length ? (
                                <Dropdown
                                    trigger={['click']}
                                    overlay={
                                        <Menu>
                                            <Menu.Item onClick={handleSchedulingRemove} key="remove" disabled={!multiSelect.selectedItems.length}>Remove</Menu.Item>
                                            <Menu.Item onClick={handleSchedulingPersonsMove} key="move" disabled={!multiSelect.selectedItems.length}>
                                                {transitType === 'OUTBOUND' ? 'Move to inbound' : 'Move to outbound'}
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                            <Button>
                                Actions <Icon type="down" />
                            </Button>
                        </Dropdown>) : null}
                    </Col>
                    {isScheduling ? (
                         <Col>
                            <span>Default Charge Code: </span>
                            <Input
                                style={{ width: 'auto', marginLeft: '0.25rem' }}
                                size="small"
                                onChange={e => onDefaultChargeCodeChange(e.target.value.split(" ").join(""))}
                                value={defaultChargeCode}
                            />
                        </Col>
                    ) : null}
                    {!isScheduling ? (selectedPersonKeys.length ? (
                        <Col>
                            <Button
                                loading={addPersonsLoading}
                                type="primary"
                                onClick={handlePersonsAdd}
                            >{`Add ${selectedPersonKeys.length} people to ${transitType.toLowerCase()}`}</Button>
                        </Col>
                    ) : null): null}
                </Row>
                {renderTable ? renderTable({
                    data: setData.data.getPersonSet[transitType.toLowerCase()]
                }) :
                    <Query
                    variables={{
                        ids: selectedPersonKeys
                    }}
                    query={gql`
                    query ResolvePersons($ids: [ID!]!){
                        resolve_entity_ids(
                            ids: $ids
                            typename: "Person"
                        ){
                            ... on Person {
                                _id
                                firstName
                                lastName
                                employerID {
                                    _id
                                    name
                                }
                                vip
                                dob
                            }
                        }
                    }
                    `}
                    >
                    {({ data, loading }) => {
                        return ( isScheduling ?(
                            <React.Fragment>
                                <PaxCgoSchedulerTable
                                    editMode={true}
                                    paxEdit={false}
                                    defaultChargeCode={defaultChargeCode}
                                    onCargoEdit={(data) => {
                                        if (!data){
                                            setCargoEditState(undefined);
                                        }
                                        else
                                        {
                                            setCargoEditState(data);
                                        }
                                    }}
                                    cargoEditID={cargoEditState && cargoEditState._id}
                                    paxData={personsToSchedule[transitType]}
                                    cgoData={cargoToSchedule["INBOUND"]}
                                    multiSelect={{
                                        toggleSelection: multiSelect.toggleSelection,
                                        isSelected: multiSelect.isSelected
                                    }}
                                    onCellEdit={(id, fieldKey, value) => {
                                        onScheduledUpdate(personsToSchedule[transitType].map((row) => {
                                            if (row._id === id){
                                                return {
                                                    ...row,
                                                    [fieldKey]: value
                                                }
                                            }
                                            return row
                                        }), transitType, "PAX")
                                        onScheduledUpdate(cargoToSchedule["INBOUND"].map((row) => {
                                            if (row._id === id){
                                                return {
                                                    ...row,
                                                    [fieldKey]: value
                                                }
                                            }
                                            return row
                                        }), "INBOUND", "CGO")
                                    }}
                                    renderPaxPanelExtra={selectedPersonKeys.length ? (
                                        <QueryPersonnelIDs ids={selectedPersonKeys}>
                                        {({ data, loading, error }) => {
                                            return <Spin spinning={loading} indicator={<Icon type="loading" />}>
                                                {error ? (
                                                    <span style={{ color: 'red', marginRight: '1rem' }}>
                                                        Failed to load selected personnel
                                                    </span>
                                                ) : null}
                                                <Button
                                                    disabled={error ? true : false}
                                                    size="small"
                                                    type="primary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePersonsAdd(e)
                                                    }}
                                                >
                                                    Add {selectedPersonKeys.length} PAX to {transitType.charAt(0).toUpperCase() + transitType.slice(1).toLowerCase()}
                                                </Button>
                                            </Spin>
                                        }}
                                        </QueryPersonnelIDs>
                                    ) : null}
                                    paxPanelHeader={
                                        <>
                                            <span style={{ marginRight: '1rem' }}>Passengers</span>
                                            <Radio.Group size="small" buttonStyle="solid" value={transitType} onChange={(e) => {
                                                    onTransitTypeChange(e.target.value)}
                                                }>
                                                <Radio.Button value="OUTBOUND">{`Outbound (${personsToSchedule["OUTBOUND"].length})`}</Radio.Button>
                                                <Radio.Button value="INBOUND">{`Inbound (${personsToSchedule["INBOUND"].length})`}</Radio.Button>
                                            </Radio.Group>
                                        </>
                                    }
                                />
                            </React.Fragment>) : (
                                <React.Fragment>
                                    <SetsTable
                                        data={isScheduling ? personsToSchedule[transitType] : personList}
                                        isScheduling={isScheduling}
                                        previewData={data && data.resolve_entity_ids}
                                        previewLoading={loading}
                                        selectedRowKeys={selectedTableRowKeys[transitType]}
                                        isEditing={isEditing}
                                        onRowSelectChange={(sr) => onSelectedTableRowKeysChange({ ...selectedTableRowKeys, [transitType]: sr })}
                                        onMoveRow={handleMoveRow}
                                        onPersonsToScheduleChange={onPersonsToScheduleChange}
                                        defaultChargeCode={defaultChargeCode}
                                    />
                                </React.Fragment>
                            )
                        )
                    }}
                    </Query>
                }
            </React.Fragment>
        )
    }

    // const getPersonId = (per) => per && per._id;

    // let outbound = safeGet(['getPersonSet', 'outbound'], setData) || [];
    // let inbound = safeGet(['getPersonSet', 'inbound'], setData) || [];
    // outbound = outbound.map(getPersonId);
    // inbound = inbound.map(getPersonId);

    return (
        <MainLayout>
            <MainHeader {...mainHeaderProps} />
            <Layout className="scheduling-sets-layout">
                <ReflexContainer orientation="vertical">
                    <ReflexElement minSize={isEditing ? 300 : null} flex={isEditing ? 0.30 : 0}>
                        {isScheduling ? (<Tabs
                        type="card"
                        className="ant-tabs-flex"
                        defaultActiveKey="personsearch"
                        tabBarGutter={8}
                        tabBarStyle={{ padding: '8px 8px 0 8px', margin: 0 }}
                        style={{
                            height: '100%'
                        }}
                    >
                        <Tabs.TabPane key="personsearch" tab="Personnel Search">
                            <Tabs
                                type="card"
                                className="ant-tabs-flex"
                                defaultActiveKey="name"
                                tabBarGutter={8}
                                tabBarStyle={{ padding: '8px 8px 0 8px', margin: 0 }}
                                style={{
                                    height: '100%'
                                }}
                            >
                                <Tabs.TabPane key="name" tab="By Name">
                                    <PersonSearch
                                        style={{
                                            height: '100%'
                                        }}
                                        selectedItems={selectedPersonKeys}
                                        onSelection={(sp) => onSelectedPersonKeysChange(sp)}
                                    />
                                </Tabs.TabPane>
                                <Tabs.TabPane key="cred" tab="By ID">
                                    <PersonSearchByCred
                                        style={{
                                            height: '100%'
                                        }}
                                        selectedItems={selectedPersonKeys}
                                        onSelection={(sp) => onSelectedPersonKeysChange(sp)}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="cgo" tab={'Add Inbound Cargo'}>
                            <CargoSiderTab
                                clearOnSubmit
                                onSubmit={(values) => {
                                    let obj = {
                                        ...values,
                                        classType: 'flytsuite.cgonode'
                                    }
                                    onCargoToScheduleChange(cargoToSchedule["INBOUND"].concat(obj))
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>) : (isEditing ? (
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <h3 style={{ margin: '0.5rem 0.5rem 0 0.5rem' }}>Search Personnel</h3>
                                <Tabs
                                    type="card"
                                    className="ant-tabs-flex"
                                    defaultActiveKey="name"
                                    tabBarGutter={8}
                                    tabBarStyle={{ padding: '8px 8px 0 8px', margin: 0 }}
                                    style={{
                                        height: '100%'
                                    }}
                                >
                                    <Tabs.TabPane key="name" tab="By Name">
                                        <PersonSearch
                                            style={{ flex: 1 }}
                                            selectedItems={selectedPersonKeys}
                                            onSelection={(sp) => onSelectedPersonKeysChange(sp)}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="cred" tab="By ID">
                                        <PersonSearchByCred
                                            style={{ flex: 1 }}
                                            selectedItems={selectedPersonKeys}
                                            onSelection={(sp) => onSelectedPersonKeysChange(sp)}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        ) : null)}
                    </ReflexElement>
                    {isEditing ? <ReflexSplitter /> : null}
                    <ReflexElement flex={isEditing ? 0.70 : 1} style={{ padding: 24 }}>
                        {setData.loading ? (
                            <div style={{ textAlign: 'center', marginTop: '10rem' }}>
                                <Spin size="large" indicator={<Icon type="loading" />} />
                            </div>
                        ) : null}
                        {saveError && isEditing && !setData.loading ? (
                            <Alert style={{ marginBottom: 12 }} type="error" showIcon message={`Failed to ${isScheduling ? 'schedule' :'save'} set.`} description={saveError.message} />
                        ) : null}
                        {/* {disableSave && isEditing && !setData.loading && !setData.error ? (
                            <Alert style={{ marginBottom: 12 }} type="warning" message={disableSaveMessage} showIcon />
                        ) : null} */}
                        {!setData.loading ? (
                            <React.Fragment>
                                {isScheduling ? (
                                    <SetFormShedule
                                        {...formFields}
                                        disabled={!isEditing}
                                        onChange={handleFormChange}
                                        ref={setsForm}
                                        showDate={isScheduling}
                                        useGroupName={isScheduling}
                                    />
                                ) : (
                                    <SetForm
                                        {...formFields}
                                        disabled={!isEditing}
                                        onChange={handleFormChange}
                                        ref={setsForm}
                                        showDate={isScheduling}
                                        useGroupName={isScheduling}
                                    />
                                )}
                                {renderContent()}
                            </React.Fragment>
                        ) : null}
                    </ReflexElement>
                </ReflexContainer>
            </Layout>
        </MainLayout>
    )
}

export default SetsEditor