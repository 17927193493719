import { Cell, Column, SelectionModes, Table } from '@blueprintjs/table';
import { Button } from 'antd';
import { getFlightDeparture, getFlightDestinations } from 'common/flight';
import { useColWidths } from 'common/table';
import React, { forwardRef, LegacyRef } from 'react';
import { ScheduledFlight } from '../interface';

const tableCellStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
}

export interface ScheduledFlightsTableProps {
    data: Array<ScheduledFlight>,
    onViewFlight?(group: ScheduledFlight): void
}

const ScheduledFlightsTable: React.FC<ScheduledFlightsTableProps> = forwardRef((props, ref: LegacyRef<Table>) => {
    const [ colWidths, , updateColWidth ] = useColWidths(5, [200, 200, 200, 200, 200])
    return <Table
        selectionModes={SelectionModes.NONE}
        numRows={props.data.length}
        defaultRowHeight={30}
        columnWidths={colWidths}
        onColumnWidthChanged={updateColWidth}
        ref={ref}
    >
        <Column name="Flight Designation" cellRenderer={(idx) => {
            const record = props.data[idx];
            return <Cell style={tableCellStyle}>{record && record.desig}</Cell>
        }} />
        <Column name="Aircraft Tail Num" cellRenderer={(idx) => {
            let record = props.data[idx];
            return <Cell style={tableCellStyle}>{record && record.aircraftID && record.aircraftID.tailNum}</Cell>
        }} />
        <Column name="Departure" cellRenderer={(idx) => {
            return <Cell style={tableCellStyle}>{getFlightDeparture(props.data[idx])}</Cell>
        }} />
        <Column name="Destinations" cellRenderer={(idx) => {
            let dests = getFlightDestinations(props.data[idx]);
            let destNames = dests.map(dest => dest.name).join(', ');
            return <Cell style={tableCellStyle}>{destNames}</Cell>
        }} />
        <Column name="Action" cellRenderer={(idx) => {
            return <Cell interactive style={tableCellStyle}>
                {props.onViewFlight ? (
                    <Button
                        type="link"
                        onClick={() => props.onViewFlight(props.data[idx])}
                        block
                        size="small"
                    >View Schedule</Button>
                ) : null}
            </Cell>
        }} />
    </Table>
})

export default ScheduledFlightsTable