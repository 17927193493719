import React, { CSSProperties } from 'react';
import './NonIdealState.less';
import { Icon } from 'antd';
import cn from 'classnames';
import { IconProps } from 'antd/lib/icon';

export interface NonIdealStateProps {
    icon?: string | JSX.Element,
    iconProps?: Omit<IconProps, 'type' | 'className'>,
    type?: 'primary',
    title: string | JSX.Element,
    description?: string | JSX.Element,
    style?: CSSProperties
}

const BASE_CN = "nonidealstate"

const NonIdealState: React.FC<NonIdealStateProps> = (props) => {

    let typeCNIcon = undefined;

    if (props.type){
        typeCNIcon = BASE_CN + '-icon-' + props.type;
    }

    const renderIcon = (icon: string | JSX.Element) => {
        if (typeof icon === 'string'){
            return <Icon {...props.iconProps} className={BASE_CN + "-icon"} type={icon} />
        }
        if (React.isValidElement(icon)){
            let iconProps: any = icon.props;
            let exisClassName = iconProps.className;
            return React.cloneElement<any>(icon, { className: cn(exisClassName, BASE_CN + "-icon", typeCNIcon) })
        }
    }

    return <div className={BASE_CN + "-wrapper"} style={props.style}>
        <div className={BASE_CN + "-content-wrapper"}>
            {props.icon ? renderIcon(props.icon) : null}
            <h3 className={BASE_CN + "-title"}>{props.title}</h3>
            {props.description ? (
                <div className={BASE_CN + "-description"}>{props.description}</div>
            ) : null}
        </div>
    </div>
}

export default NonIdealState