import { Alert } from 'antd';
import { NetworkStatus } from 'apollo-client';
import { getPilotName } from 'common/util';
import { getAreaBlock } from 'common/util-ts';
import CenteredLoadingScreen from 'components/CenteredLoadingScreen';
import AircraftDetails from 'components/flytwatch/aircraft-details';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';

interface FWAircraftDetailsPageProps {
    dispatchID: string
}

const QUERY_AIRCRAFT_DETAILS = gql`
query AircraftDetails($id: ID!){
    GetDispatchByID(id: $id){
        _id
        status
        ata
        eta
        ato
        pob
        checkTime
        aircraftID
        createdBy
        aircraftDoc {
            _id
            tailNum
        }
        contractDoc {
            _id
            name
        }
        departingID
        departingDoc {
            _id
            name
            block
            field
            type
            resolvedCoordinates {
                latitude
                longitude
            }
        }
        destinationID
        destinationDoc {
            _id
            name
            block
            field
            type
            resolvedCoordinates {
                latitude
                longitude
            }
        }
        pilotDoc {
            _id
            name {
                firstName
                lastName
            }
        }
        name
    }
}
`

const FWAircraftDetailsPage: React.FC<FWAircraftDetailsPageProps> = (props) => {

    const { data, networkStatus, error } = useQuery(QUERY_AIRCRAFT_DETAILS, {
        variables: {
            id: props.dispatchID   
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: 10000
    })

    let af = data?.GetDispatchByID;

    if (!af && networkStatus === NetworkStatus.loading){
        return <CenteredLoadingScreen />
    }

    if (error){
        return <Alert
            showIcon
            type='error'
            message="An error occurred while loading this flight"
        />
    }

    if (!af){
        return <Alert
            showIcon
            type="error"
            message="No data returned"
        />
    }

    let acData = {
        aircraftID: af.aircraftID,
        tailNum: af.aircraftDoc.tailNum,
        departure: af.departingDoc?.name,
        departureID: af.departingDoc?._id,
        departureAreaBlock: getAreaBlock(af.departingDoc),
        departureCoordinates: af.departingDoc?.resolvedCoordinates,
        destination: af.destinationDoc?.name,
        destinationID: af.destinationDoc?._id,
        destinationAreaBlock: getAreaBlock(af.destinationDoc),
        destinationCoordinates: af.destinationDoc?.resolvedCoordinates,
        ato: af.ato,
        eta: af.eta,
        ata: af.ata,
        pob: af.pob,
        contract: af.contractDoc?.name,
        pilot: getPilotName(af.pilotDoc),
        status: af.status,
        createdBy: af.createdBy,
        name: af.name
    }

    let title = 'Flight details for ' + acData?.tailNum;

    document.title = title;

    return <div style={{ padding: '6px', height: '100%' }}>
        <AircraftDetails
            data={acData}
            title={title}
            historyDrawerProps={{ width: '100%' }}
        />
    </div>
}

export default FWAircraftDetailsPage;