import gql from 'graphql-tag';

export default gql`
query PersonSetCached($setId: ID!){
    getPersonSet(_id: $setId) @client{
        _id
        name
        outbound {
            _id
            firstName
            lastName
            employerID {
                _id
                name
            }
            vip
            dob
        }
        inbound {
            _id
            firstName
            lastName
            employerID {
                _id
                name
            }
            vip
            dob
        }
        owner
        departureID {
            _id
            name
        }
        destinationID {
            _id
            name
        }
        owner
    }
}
`