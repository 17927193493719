import React, { Component } from 'react';
import { Tabs, Card, Col, Checkbox, Table, Avatar, Button } from 'antd';
import PropTypes from 'prop-types';
import commonColumns from '../../common/table/columns';
import { CredentialManagerWithData } from '../CredentialManager';
import moment from 'moment';


class PobViewer extends Component {
    state = {
        visible: false,
        error: false
    }
    setPicError = () => {
        this.setState({ error: true })
    }
    setPictureVisible = () => {
        this.setState({ visible: true })
    }
    renderPicture = () => {
        const { data } = this.props;
        const personId = data.personID._id;
        const path = "https://s3.amazonaws.com/df-web-img/pax/" + personId + "/" + personId + "_prof.jpeg";
        if(this.state.error){
            return(
                <Avatar shape="square" size={300} style={{ marginBottom: 20 }}>No Image Found</Avatar> 
            )
        }
        else{
            return (
                this.state.visible? 
                <Avatar shape="square" size={300} style={{ marginBottom: 20 }} src={path} onError={this.setPicError} /> 
                : <Avatar shape="square" size={300} style={{ marginBottom: 20 }} icon="user" /> 
                )
        }
    }
    renderPersonalInfo = () => {
        const { data } = this.props;
        return (
            <Tabs.TabPane key="personalInfo" tab="Personal Information">
            <Col span={12}>   
                <Card title="Personal Information" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}> 
                    <Button onClick={this.setPictureVisible} disabled={this.state.visible}> Click here to show image </Button>
                    {this.renderPicture()}
                    <br />
                    <b style={formStyle}>Date of Birth: </b> <span style={infoStyle}>{data.personID.dob}</span>  <br />
                    <b style={formStyle}>Phone Number:</b> <span style={infoStyle}>{data.personID.contactInfo.phone.number}</span>
                    <br />
                    <b style={formStyle}>Email Address: </b> <span style={infoStyle}>{data.personID.contactInfo.email.address}</span>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Address" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}>
                    <b style={formStyle}>Street:</b> <span style={infoStyle}>{data.personID.contactInfo.address.street}</span>
                    <br />
                    <b style={formStyle}>City:</b> <span style={infoStyle}>{data.personID.contactInfo.address.city}</span>
                    <br />
                    <b style={formStyle}>State:</b> <span style={infoStyle}>{data.personID.contactInfo.address.state}</span>
                    <br />
                    <b style={formStyle}>ZipCode:</b> <span style={infoStyle}>{data.personID.contactInfo.address.zipCode}</span>
                </Card>
                <Card title="Organizational Information" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}> 
                    <b style={formStyle}>Customer:</b>  <span style={infoStyle}>{data.customerID.name}</span> <br />
                    <b style={formStyle}>Employee Number: </b> <span style={infoStyle}>{data.personID.employeeNumber}</span> <br />
                    <b style={formStyle}>Employer:</b> <span style={infoStyle}>{data.employerID.name}</span>
                </Card>
            </Col>
        </Tabs.TabPane>
        )
    }
    renderPobInfo = () => {
        const { data } = this.props;
        return (
            <Tabs.TabPane key="pobInfo" tab="POB Information">
            <Col span={12}>    
                <Card title="Room Assignment" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}> 
                    <b style={formStyle}>Room Assignment:</b>
                </Card>
                <Card title="Emergency Information" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}> 
                    <b style={formStyle}>Lifeboat Number:</b>  <span style={infoStyle}>{data.lifeBoatNumber}</span>  <br />
                    <b style={formStyle}>Essential Personnel: </b> <Checkbox disabled />
                </Card>
            </Col>
            <Col span={12}>
                
                <Card title="Check Ins" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}>
                    <b style={formStyle}>Base Checkin:</b> <br />
                    <b style={formStyle}>Offshore Check In: </b> <span style={infoStyle}>{moment(data.offshoreCheckIn).format('YYYY-MM-DD HH:mm:ss')}</span>

                </Card>
            </Col>
        </Tabs.TabPane>
        )
    }
    renderCred = () => {
        const { data } = this.props;
        return (
            <Tabs.TabPane key="cred" tab="Credentials">
                <Card title="Credentials" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={true}>
                    <CredentialManagerWithData showCount personId={
                            data.personID && data.personID._id
                        }/>
                </Card>
        </Tabs.TabPane>
        )
    }
    renderEmergencyContact = () => {
        const { data } = this.props;
        return (
            <Tabs.TabPane key="emContact" tab="Emergency Contact">
            <Col span={12}>    
                <Card title="Emergency Contact" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}>
                    <b style={formStyle}>First Name: </b> <span style={infoStyle}>{data.personID.emergencyContactInfo.name.firstName}</span>
                    <br />
                    <b style={formStyle}>last Name: </b> <span style={infoStyle}>{data.personID.emergencyContactInfo.name.lastName}</span>
                </Card>
                <Card title="Contact Information" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}> 
                    <b style={formStyle}>Phone Number:</b> <span style={infoStyle}>{data.personID.emergencyContactInfo.phone.number}</span>
                    <br />
                    <b style={formStyle}>Email Address: </b> <span style={infoStyle}>{data.personID.emergencyContactInfo.email.address}</span>
                </Card>
            </Col>
            <Col span={12}>
                <Card title="Address" headStyle={{color: 'white', fontSize:'20px', backgroundColor:'#108ee9'}} bordered={false}>
                    <b style={formStyle}>Street:</b>
                    <br />
                    <b style={formStyle}>City:</b>
                    <br />
                    <b style={formStyle}>State:</b> 
                    <br />
                    <b style={formStyle}>ZipCode:</b>
                </Card>
                
            </Col>
        </Tabs.TabPane>
        )
    }
    renderWorkHistory = (workHistory) => {
        return (
            <Tabs.TabPane key="workHist" tab="Work History">
                <Table
                    dataSource={workHistory}
                    size="middle"
                    pagination={false}
                    columns={commonColumns.workHist.all()}
                />
            </Tabs.TabPane>
        )
    }
    render() { 
        const { data } = this.props;
        return (
            <div>
                {data && data && (
                    <h2 style={{color: "black", fontWeight: 'bold'}}>{`${data.lastName}, ${data.firstName}`}</h2>
                )}
                <Tabs type="card" defaultActiveKey="personalInfo">
                    {this.renderPersonalInfo()}
                    {this.renderPobInfo()}
                    {this.renderWorkHistory(data.shiftTimes)}
                    {this.renderCred()}
                    {this.renderEmergencyContact()}
                    
                </Tabs>
            </div>
        );
    }
}
const formStyle = {
    fontSize: '16px',
    marginTop: '25px',
  };
const infoStyle = {
    fontSize: '16px',
    color: 'black'
    
  }  
PobViewer.propTypes = {
    data: PropTypes.object.isRequired
}
export default PobViewer;