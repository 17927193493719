import React, { CSSProperties } from 'react';
import { Cell, ICellProps } from '@blueprintjs/table';
import { getISNStatus } from 'common/isn';
import { Spin, Icon } from 'antd';
import './IsnStatusCell.less';
import { classNames } from 'common/util';

export interface IsnStatusCellProps {
    data: any,
    isnCheckRunningKey?: string,
    disableClick?: boolean,
    onClick?: (id: string) => void
}

const OnClickChild = props => (
    <div onClick={props.handleClick}>
        {props.isnStatus.text}
    </div> 
);

function getClassNameFromISNStatusText(text: string): string {
    let classNamePrefix = 'cell-isn-status-';
    let mapping = {
        'RED': classNamePrefix + 'red',
        'GREEN': classNamePrefix + 'green',
        'MULTIPLE RESULTS': classNamePrefix + 'multi',
        'OVERRIDDEN': classNamePrefix + 'override'
    }
    return mapping[text] || ''
}

const IsnStatusCell: React.FC<IsnStatusCellProps & ICellProps> = (props) => {
    const { data, isnCheckRunningKey, onClick, ...cellProps } = props;
    let checking: boolean = data[isnCheckRunningKey || 'isnCheckRunning'];
    function handleClick(){
        if (onClick && !props.disableClick){
            onClick(data._id);
        }
    }

    let isnStatus = getISNStatus(data);
    let style: CSSProperties = {
        ...cellProps.style
    }
    let children: string | JSX.Element;
    if (!data){
        children = 'NO DATA'
    }
    else if (checking){
        children = <Spin size="small" indicator={<Icon type="loading" />} />
    }
    else {
        children = <OnClickChild handleClick={handleClick} isnStatus={isnStatus} />
    }

    let className = 'cell-isn-status'
    if (onClick && !props.disableClick){
        className = classNames(className, 'cell-isn-status-selectable');
    }
    className = classNames(className, getClassNameFromISNStatusText(isnStatus.text), props.className);
    
    return <Cell
            {...cellProps}
            style={style}
            className={className}
            interactive
        >
            {children}
        </Cell>
}

export default IsnStatusCell