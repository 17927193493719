import React from 'react';

const highlightText = (content='', filterText='') => (
    <span>
        {content.split(new RegExp(`(${filterText})`, 'gi')).map((fragment, i) => (
            fragment.toLowerCase() === filterText.toLowerCase()
                ? <span key={i} className="filter-highlight">{fragment}</span> : fragment
        ))}
    </span>
)

export default highlightText