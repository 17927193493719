import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import Pob from './pages/pob';
import { Layout } from 'antd';
import PobNav from './nav';
import RigDetail from './pages/rigDetail';
import cn from 'classnames';
import { GlobalAppStateContext } from 'context/global-app-state';

const basePath = '/app/'

const { Content } = Layout;

/**
 * Handles navigation and routes of POB branch of the application.
 */
const PobMain = () => {
    const { state: { sidebarCollapsed }, setState } = useContext(GlobalAppStateContext);
    return <Layout className={cn({
        "mc-main-app-layout": true,
        "mc-main-app-layout-sider-open": (!sidebarCollapsed)
    })}>
        <div className="mc-main-app-layout-click-receiver" onClick={() => setState({ sidebarCollapsed: true })} />
        <PobNav />
        <Content className="mc-main-app-layout-content" style={{padding: 24, margin: 0}}>
            <Switch>
                <PrivateRoute exact path={basePath + 'pob'} component={Pob} />
                <PrivateRoute exact path={basePath + 'pob/:rigid'} component={RigDetail} />
                <Redirect to={basePath + 'pob'} />
            </Switch>
        </Content>
    </Layout>
}

export default PobMain