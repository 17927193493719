import React, { Component } from 'react';
import { Card, Row, Col, Tooltip } from 'antd';
import { RadialChart, DiscreteColorLegend, Hint } from 'react-vis';
import PropTypes from 'prop-types';
import './style.less';

class ChartItem extends Component {
    state = {
        value: false
    }
    render(){
        const { colors, showHint=false, ...rest } = this.props;
        const { value } = this.state;
        const getPercentage = (color) => {
            if (colors[color] === 0){
                return ''
            }
            const total = Number(colors.green) + Number(colors.red) + Number(colors.yellow);
            return `${((colors[color] / total) * 100).toFixed(2)}%`;
        }
        return (
            <RadialChart
                height={100}
                width={100}
                colorType="literal"
                onValueMouseOver={v => {
                    this.setState({value: v})
                }}
                onSeriesMouseOut={v => this.setState({value: false})}
                data={[
                    {
                        angle: colors.green || 0,
                        paxCount: colors.green || 0,
                        label: getPercentage('green'),
                        subLabel: colors.green ? 'Success' : null,
                        color: '#009933',
                        key: 'green'
                    },
                    {
                        angle: colors.red || 0,
                        paxCount: colors.red || 0,
                        label: getPercentage('red'),
                        subLabel: colors.red ? 'Failed' : null,
                        color: '#cc0000',
                        key: 'red'
                    },
                    {
                        angle: colors.yellow || 0,
                        paxCount: colors.yellow || 0,
                        label: getPercentage('yellow'),
                        subLabel: colors.yellow ? 'No Status' : null,
                        color: '#ffcc00',
                        key: 'yellow'
                    }
                ]}
            {...rest}
        >
            {value !== false && showHint === true && (
                <Hint value={value}>
                    <div style={{
                        background: 'rgba(50, 50, 50)',
                        borderRadius: '0.25rem',
                        padding: '0.50rem'
                    }}>
                        <h3 style={{color: '#ffffff', margin: 0}}>{value.label}</h3>
                        <div>{value.subLabel}</div>
                        <div>{value.paxCount} PAX</div>
                    </div>
                </Hint>
            )}
        </RadialChart>
        )
    }
}

class ISNChartSelector extends Component {
    state = {
        selected: 'total'
    }
    componentDidUpdate(prevProps, prevState){
        const selected = this.getSelected();
        if (!selected){
            const firstContent = this.props.data.content[0];
            if (this.props.includeTotal && prevState.selected !== 'total'){
                this.setSelected('total')
            }
            else if (prevState.selected !== (firstContent ? firstContent.id : null))
            {
                this.setSelected(firstContent && firstContent.id);
            }
        }
    }
    getSelected = () => {
        if (this.state.selected === 'total'){
            return this.props.data.total;
        }else{
            return this.props.data.content.find(item => item.id === this.state.selected);
        }
    }
    setSelected = (id) => this.setState({ selected: id })
    getLegend = (id, key) => {
        var item;
        if (id === 'total'){
            item = this.props.data.total;
        }else{
            item = this.props.data.content.find(item => item.id === id);
        }
        if (item){
            return item.legend.find(l => l.key === key);
        }
    }
    render() {
        var { data } = this.props;
        if (this.props.includeTotal){
            data = [
                data.total,
                ...data.content
            ]
        }else{
            data = data.content;
        }
        const selected = this.getSelected();
        const total = {
            red: this.getLegend(this.state.selected, 'Red'),
            green: this.getLegend(this.state.selected, 'Green'),
            yellow: this.getLegend(this.state.selected, 'No Status')
        }
        return (
            <div>
                <div style={{marginBottom: 12}} >
                    <DiscreteColorLegend
                        orientation="horizontal"
                        items={[
                            {
                                title: 'Success',
                                color: '#009933'
                            },
                            {
                                title: 'Failed',
                                color: '#cc0000'
                            },
                            {
                                title: 'No Status',
                                color: '#ffcc00'
                            }
                        ]}
                    />
                </div>
                <Row gutter={12}>
                    <Col span={12}>
                        <Card>
                                {data.map(item => {
                                    const red = this.getLegend(item.id, 'Red');
                                    const green = this.getLegend(item.id, 'Green');
                                    const yellow = this.getLegend(item.id, 'No Status');
                                    const isSelected = item.id === this.state.selected;
                                    return (
                                        <Card.Grid key={item.id} style={{
                                            height: '11rem',
                                            width: '25%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            background: isSelected ? 'rgba(24, 144, 255, 0.25)' : null,
                                            cursor: 'pointer'
                                        }}
                                        className="mc-dashboard-chart"
                                        onClick={() => this.setSelected(item.id)}
                                        >
                                            <ChartItem
                                                colors={{
                                                    green: green ? green.value : 0,
                                                    red: red ? red.value : 0,
                                                    yellow: yellow ? yellow.value : 0
                                                }}
                                                showHint={true}
                                            />
                                            <Tooltip title={item.name} mouseEnterDelay={1}>
                                                <div style={{textAlign: 'center'}}>{item.name}</div>
                                            </Tooltip>
                                        </Card.Grid>
                                    )
                                })}
                        </Card>
                    </Col>
                    <Col span={12}>
                        {selected ? (
                            <Card title={selected.name}>
                                <ChartItem
                                    className="mc-dashboard-chart"
                                    height={400}
                                    width={400}
                                    colors={{
                                        green: total.green ? total.green.value : 0,
                                        red: total.red ? total.red.value : 0,
                                        yellow: total.yellow ? total.yellow.value : 0
                                    }}
                                    showLabels={true}
                                    showHint={true}
                                />
                            </Card>
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    }
}

ISNChartSelector.propTypes = {
    data: PropTypes.object
}
 
export default ISNChartSelector;