import {exportDataAsCsv} from "./csv-converter";
import {convertAntdColumn, registerAntdColumn} from "./antd-converter";


export type ExportDataFunction<T> = (data: T[], filename: string, config: any) => void

export type ColumnConfigRenderFunction = (value: any, row: any, rowIndex: number) => string

export interface ColumnConfig {
    /** Human-readable name of the column. */
    name: string,

    /** Path to find the value to display. */
    path?: string[],

    /** Overrides behavior of path. Allows to pass custom function to display the value. */
    render?: ColumnConfigRenderFunction
}

/**
 * Recursively traverses object in accordance to the path specified. Returns the value if found.
 * @param path List of strings representing the object keys to traverse.
 * @param obj Object to traverse.
 */
export function resolvePath(path: string[], obj: any){
    if (path.length == 0 || !obj)
        return obj;

    let currProp = path[0];

    if (!obj[currProp])
        return null;

    if (obj[currProp] && typeof obj[currProp] === 'object'){
        return resolvePath(path.slice(1, path.length), obj[currProp]);
    }

    if (path.length !== 1)
        return null;

    return obj[currProp] || null;
}

/**
 * Retrieves a value based on the {@link ColumnConfig}. First it calls {@link resolvePath}, then it calls the render function if available.
 * @param obj Object to process
 * @param config The {@link ColumnConfig}
 * @param rowIndex Index of the current row
 */
export function resolveColumnValue(obj: any, config: ColumnConfig, rowIndex: number){
    if (!config.path && !config.render)
        return '';

    const value = config.path ? resolvePath(config.path, obj) : null;

    if (config.render){
        return config.render(value, obj, rowIndex);
    }
    return value;
}

/**
 * Creates a file out of string and downloads it in the browser.
 * @param text Contents of file
 * @param filename File name
 * @param contentType Mime Type (e.g. "text/csv" or "application/json")
 * @param target Target window (current window by default)
 */
export function exportToFile(text: string, filename: string, contentType: string, target=window){
    const blob = new Blob([text], { type: contentType + ";charset=UTF-8" });
    const downloadLink = target.document.createElement('a');

    downloadLink.download = filename;
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.style.display = "none";
    target.document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
}

const DataExport = {
    exportDataAsCsv,
    Utils: {
        convertAntdColumn,
        registerAntdColumn
    }
}

export default DataExport