import React from 'react';
import '../search.less';
import { Row, Col, Button, Icon, Spin } from 'antd';
import SelectionCount from '../SelectionCount';

export interface ISearchHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    forceRefresh?: boolean,
    onForceRefresh?(fr: boolean): void,
    selectedItems?: any[],
    showSelectedItemsCount?: boolean,
    children?: React.ReactNode,
    networkStatus?: number,
    onRefresh?(): void,
    onClear?(): void
}

const SearchHeader: React.FC<ISearchHeaderProps> = (props) => {
    const { forceRefresh, onForceRefresh, selectedItems, showSelectedItemsCount, children, networkStatus, onRefresh, onClear, ...restProps } = props;

    if(forceRefresh){
        onForceRefresh(false)
        onRefresh()
    }
    const shouldRenderSI = selectedItems && selectedItems.length > 0 && showSelectedItemsCount;
    const shouldRenderRefresh = onRefresh ? true : false;

    const shouldRenderLoadingIcon = networkStatus <= 4;

    function renderLoadingIcon(){
        if (shouldRenderLoadingIcon){
            return <Spin className="search-loading-icon" indicator={<Icon type="loading" />} />
        }
        return null
    }
    return <div className="search-header" {...restProps}>
        <Row type="flex">
            <Col style={{ flex: 1 }} className="search-header-content search-header-col">
                {children}
            </Col>
            {shouldRenderSI || shouldRenderRefresh || shouldRenderLoadingIcon ? (
                <Col className="search-header-col">
                    {shouldRenderSI ? (
                        <SelectionCount style={{ marginRight: '0.5rem' }} items={selectedItems} onClear={onClear} />
                    ) : null}
                    {shouldRenderRefresh ? (
                        <Button onClick={onRefresh}>{<Icon type="sync" spin={networkStatus <= 4} />}</Button>
                    ) : renderLoadingIcon()}
                </Col>
            ) : null}
        </Row>
    </div>
}

export default SearchHeader