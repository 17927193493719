import { List, Table } from 'antd';
import { castArray } from 'common/util';
import DestinationList from 'components/DestinationList';
import { groupBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { ScheduledFlight } from '../interface';

export interface ScheduledGroupsListProps {
    onFlightClicked?(flight: ScheduledFlight),
    data?: Array<ScheduledFlight>
}

const ScheduledGroupsList: React.FC<ScheduledGroupsListProps> = (props) => {
    let grouped = groupBy(castArray(props.data), 'scheduledFlightDate');
    let data: Array<any> = Object.entries(grouped);
    return <List
        dataSource={data}
        renderItem={(item) => {
            let itemData: Array<ScheduledFlight> = item[1];
            return <List.Item>
                <div style={{ width: '100%' }}>
                    <h3>{item[0] && moment(item[0]).format('MMMM DD, YYYY (dddd)')}</h3>
                    <Table
                        rowKey={(record) => record && record._id}
                        pagination={false}
                        dataSource={itemData}
                        onRow={() => {
                            return {
                                style: {
                                    cursor: 'pointer'
                                }
                            }
                        }}
                        onRowClick={(record) => {
                            if (props.onFlightClicked){
                                props.onFlightClicked(record)
                            }
                        }}
                        size="small"
                        className="mc-table"
                        columns={[
                            {
                                title: 'Flight Designation',
                                key: 'desig',
                                dataIndex: 'desig'
                            },
                            {
                                title: 'Aircraft',
                                key: 'aircraft',
                                render: (_, record) => record.aircraftID ? record.aircraftID.tailNum : <span style={{color: 'red'}}>Not Found</span>
                            },
                            {
                                title: 'Departure',
                                key: 'departure',
                                render: (_, record) => {
                                    const legs = record.legsArray;
                                    if (legs && legs.length){
                                        return legs[0].departure;
                                    }
                                    return null
                                }
                            },
                            {
                                title: 'Destinations',
                                key: 'destinations',
                                render: (_, record) => {
                                    const legs = record.legsArray;
                                    if (legs && legs.length){
                                        return <DestinationList data={legs.map(leg => leg.destination)} />
                                    }
                                    return null
                                }
                            }
                        ]}
                    />
                </div>
            </List.Item>
        }}
    />
}

export default ScheduledGroupsList