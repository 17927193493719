import React from 'react';
import { withRouter } from 'react-router-dom';

class LinkWrapper extends React.Component {
    handleClick = () => {
        const { to, history } = this.props;
        history.push(to);
    }
    render(){
        return (
            <div onClick={this.handleClick}>
                {this.props.children}
            </div>
        )
    }
}

export default withRouter( LinkWrapper )