import gql from "graphql-tag";

export default gql`
query ReportQuery($id: ID!) {
    report(id: $id){
        _id
        name
        startTs
        endTs
        createdTs
        status
        type
        result {
            total_docs
            summary
        }
        criteria {
            key
            label
            display_value
            value
        }
        error {
            type
            message
            detailMessage
            args
        }
    }
}
`

export const S3ReportQuery = gql`
query S3ReportQuery($id: ID!, $encoding: String) {
    s3Report(id: $id, encoding: $encoding) @client{
        _id
        name
        startTs
        endTs
        createdTs
        status
        type
        result {
            docs
            total_docs
            summary
        }
        criteria {
            key
            label
            display_value
            value
        }
        error {
            type
            message
            args
        }
    }
}
`