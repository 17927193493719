import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, List, Badge, Empty, Icon } from 'antd';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import '../../../css/List.css';

class ReportList extends Component {
    state = {
        currentTime: moment()
    }
    static propTypes = {
        reports: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.any
        }).isRequired).isRequired,
        onItemClick: PropTypes.func.isRequired
    }
    updateCurrentTime = () => {
        this.setState({ currentTime: moment() });
    }
    componentDidMount(){
        this.interval = setInterval(this.updateCurrentTime, 1000);
    }
    render() {
        const { reports, renderEmpty, showWarningAtRunTime=120 } = this.props;
        const groupedReports = Object.entries(groupBy(reports, (r) => moment(r.createdTs).calendar(null, {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'MMMM D, YYYY'
        })));
        if (!reports || !reports.length){
            return (
                renderEmpty || 
                <Empty
                    description="No Reports"
                />
            )
        }
        return (
            <div>
                {groupedReports.map(([day, repList], i) => (
                    <div key={i}>
                        <h3 style={{ marginTop: i > 0 ? 20 : 0, marginBottom: 12 }}>{day}</h3>
                        <List
                        key={day}
                        bordered
                        dataSource={repList}
                        renderItem={(report) => {
                            const isRunning = report.status === 'IN_PROGRESS' || report.status === 'INITIALIZING';
                            const getStatus = () => {
                                const statusMap = {
                                    'INITIALIZING': ['In Progress', 'processing'],
                                    'IN_PROGRESS': ['In Progress', 'processing'],
                                    'COMPLETE': ['Complete', 'success'],
                                    'ERROR': ['Failed', 'error']
                                }
                                return statusMap[report.status]
                            }
                            const startMoment = moment(report.startTs);
                            const endMoment = moment(report.endTs || this.state.currentTime);
                            const runTime = endMoment.diff(startMoment, 'milliseconds') / 1000;
                            return (
                                <List.Item
                                    key={report._id}
                                    className="clickable-list-item"
                                    onClick={() => this.props.onItemClick(report._id, report.name)}
                                >
                                    <Row style={{width: '100%'}}>
                                        <Col span={12} >
                                            <Row type="flex" gutter={12}>
                                                <Col>
                                                    <span>
                                                        <h4>{report.name}</h4>
                                                    </span>
                                                    <span>
                                                        <Badge status={getStatus()[1]} text={getStatus()[0]} />
                                                        {isRunning && runTime >= showWarningAtRunTime ? (
                                                            <span style={{ marginTop: 12 }}>
                                                                <Icon type="exclamation-circle" style={{ color: 'red', marginRight: 6, marginLeft: 12 }} />
                                                                This report is taking longer than usual to run
                                                            </span>
                                                        ) : null}
                                                    </span>
                                                </Col>
                                                {report.result ? (
                                                    <Col>
                                                        <div style={{ lineHeight: '3rem', borderLeft: '1px solid #e8e8e8', marginLeft: '0.5rem', paddingLeft: '1rem' }} >
                                                            <span style={{ fontSize: '1.3rem' }} >{report.result.total_docs || 0}</span> records
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row type="flex" justify="end">
                                                <Col style={{textAlign: 'end'}}>
                                                    <div>{`Created at ${moment(report.createdTs).format('YYYY-M-D H:m')}`}</div>
                                                    <div>
                                                        Run Time: {`${runTime} seconds`}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </List.Item>
                            )
                        }}
                        />
                    </div>
                )
                )}
            </div>
        )
    }
}
 
export default ReportList;