import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const query = gql`
{
    cognitoUser @client
}
`

export interface WithCognitoUserProps {
    cognitoUser?: any
}

const withCognitoUser = <P extends object>(Component: React.ComponentType<P>) => {
    return (props: P & WithCognitoUserProps) => (
        <Query query={query}>
            {
                ({ data, loading }) => {
                    if (!loading && data.cognitoUser){
                        const user = JSON.parse(data.cognitoUser)
                        return <Component cognitoUser={user} {...props} />
                    }
                    return null
                }
            }
        </Query>
    )
}

export default withCognitoUser