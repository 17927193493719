import gql from "graphql-tag";

export default gql`
query CredentialQuery(
    $filter: CredentialFilterInput!
    $withPerson: Boolean!
){
    credentials(
        filter: $filter
        limit: 1
    ){
        docs {
            _id
            ... on Credential {
                key
                type
                expiration
                personID @include(if: $withPerson) {
                    _id
                    lastName
                    firstName
                }
            }
        }
    }
}
`