// eslint-disable-next-line import/no-anonymous-default-export
export default `
enum ReduceTypes {
    count
}
input DashboardInputVariables {
    tpID: ID!
    customerID: ID
    startDate: String
    endDate: String
}
input FilterInput {
    label: String!
    key: String!
    value: String
    value_delimiter: String
}
input SummaryInput {
    label: String!
    type: String!
    key: String!
    value: String
    group_by: String
    reduce: ReduceTypes
    force_group_uppercase: Boolean
    default_group_if_null: String
    include_total: Boolean
}
input FilterResultInput {
    key: String
    value: String
}
type Legend {
    key: String
    value: String
}
type PercentageChart {
    id: ID
    name: String
    type: String
    legend: [Legend]
}
type DashboardSummary {
    label: String
    total: PercentageChart
    content: [PercentageChart]
}
type DashboardChoice {
    key: ID
    value: String
}
type DashboardFilter {
    label: String
    choices: [DashboardChoice]
}
type DashboardResponse {
    filter: [DashboardFilter]
    summary: [DashboardSummary]
}
type Query {
    dashboard(
        type: String!
        variables: DashboardInputVariables!
        filter_result: [FilterResultInput]
        filter: FilterInput
        summary: SummaryInput
    ): DashboardResponse
}
`