// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'HUET_CARD': {
        hasKey: false,
        hasExpiration: true,
        label: 'HUET Card'
    },
    'EBS_CARD': {
        hasKey: false,
        hasExpiration: true,
        label: 'EBS Card'
    },
    'ISN': {
        hasKey: true,
        hasExpiration: false,
        label: 'ISN'
    },
    'PASSPORT': {
        hasKey: true,
        hasExpiration: true,
        label: 'Passport'
    },
    'PASSPORT_CARD': {
        hasKey: true,
        hasExpiration: true,
        label: 'Passport Card'
    },
    'SAFETY_CARD': {
        hasKey: false,
        hasExpiration: true,
        label: 'Safety Card',
        needsButton: true
    },
    'TWIC': {
        hasKey: true,
        hasExpiration: true ,
        label: 'TWIC Card'     
    },
    'US_DRIVERS_LICENSE': {
        hasKey: true,
        hasExpiration: true,
        label: 'U.S. Driver\'s License'
    },
}