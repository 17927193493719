import Auth from '@aws-amplify/auth';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { Button, Icon, message, Modal, Spin, Typography, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import getGenericStateReducer from 'common/reducer/generic-state-reducer';
import config from 'config';
import { AuthContext } from 'context/auth';
import React, { useContext, useEffect, useReducer } from 'react';
import cn from 'classnames';
import './isn-override-file-uploader.less';

export interface IsnOverrideFileUploaderProps extends UploadProps {
    paxID: string,
    readOnly?: boolean,
    addOnly?: boolean
}

interface S3FileListItem {
    Key: string,
    LastModified: string,
    Size: number
}

interface State {
    fileList: any[],
    loading: boolean,
    error?: string
}

const DEFAULT_STATE: State = {
    fileList: [],
    loading: true,
    error: null
}

const IsnOverrideFileUploader: React.FC<IsnOverrideFileUploaderProps> = (props) => {
    const { user, refreshUser } = useContext(AuthContext);
    const [ state, dispatch ] = useReducer(getGenericStateReducer<State>(), DEFAULT_STATE);

    async function getHeaders(_refreshUser=true){
        let currUser = user;
        if (_refreshUser){
            currUser = await refreshUser();
        }
        if (!currUser || !currUser.signInUserSession) return {}
        const idToken = currUser.signInUserSession.idToken.jwtToken;

        return {
            'Authorization': idToken
        }
    }

    async function downloadImagePreview(url: string): Promise<string> {
        let res = await fetch(url, { headers: await getHeaders(false) });
        let arrBuf = await res.arrayBuffer();
        let b64Str = Buffer.from(arrBuf).toString('base64');
        let contentType = res.headers.get('Content-Type');
        return `data:${contentType};base64, ${b64Str}`
    }

    function convertS3FileList(list: S3FileListItem[]): Promise<Partial<UploadFile<any>>>[]{
        if (!Array.isArray(list)) return []
        return list.map(s3 => {
            let split = s3.Key.split('/');
            let fileName = split[split.length-1];
            fileName = decodeURIComponent(fileName);
            let url = config.pythonBackendApi + "/isn-override/" + props.paxID + "/" + fileName;

            return new Promise((resolve) => {
                downloadImagePreview(url)
                    .then((urlStr) => resolve({
                        uid: s3.Key,
                        name: fileName,
                        url: url,
                        preview: urlStr,
                        lastModifiedDate: new Date(s3.LastModified),
                        size: s3.Size
                    }))
                    .catch(() => resolve({
                        uid: s3.Key,
                        name: fileName,
                        url: url,
                        size: s3.Size
                    }))
            })
        })
    }

    async function listFiles(){
        dispatch({ newState: { loading: true, error: null } })
        if (!props.paxID){
            return;
        }
        fetch(config.pythonBackendApi + "/isn-override/list-files/" + props.paxID, {
            headers: await getHeaders(),
            method: 'get'
        })
        .then(async resp => {
            if (resp.ok){
                return await resp.json();
            }
            else
            {
                throw Error(await resp.text());
            }
        })
        .then(async data => dispatch({ newState: { fileList: await Promise.all(convertS3FileList(data)), loading: false, error: null } }))
        .catch((err) => {
            console.error('Failed to load file list:', err);
            dispatch({ newState: { loading: false, error: 'Failed to list files' } })
            refreshUser();
        })
    }

    useEffect(() => {
        listFiles();
    }, [ props.paxID ])

    let uploadProps: UploadProps = {
        className: cn({
            'mc-iofu': true,
            'mc-iofu-readonly': props.readOnly,
            'mc-iofu-addonly': props.addOnly
        }),
        multiple: true,
        disabled: props.readOnly,
        onChange: (info) => {
            dispatch({ newState: { fileList: info.fileList } })
        },
        fileList: state.fileList,
        onRemove: (file) => {
            if (props.addOnly){
                console.warn("Attempt to remove file in addOnly mode was prevented.");
            }
            if (file.status === 'error'){
                return true;
            }

            return new Promise(async (resolve, reject) => {
                // Assume successful delete and immediately remove file from fileList
                let fileIdx = state.fileList.findIndex(f => f.name === file.name);
                let prevStatus = 'done';
                if (fileIdx > -1){
                    let newFileList = [...state.fileList];
                    prevStatus = newFileList[fileIdx].status;
                    newFileList[fileIdx] = {
                        ...newFileList[fileIdx],
                        status: 'removed'
                    }
                    dispatch({ newState: { fileList: newFileList } })
                }

                // If delete request fails, add the file back to the fileList
                function handleFail(){
                    if (fileIdx <= -1) return;

                    let newFileList = [...state.fileList];
                    newFileList[fileIdx] = {
                        ...newFileList[fileIdx],
                        status: prevStatus
                    }
                    dispatch({ newState: { fileList: newFileList } })
                }
                Modal.confirm({
                    title: 'Are you sure?',
                    content: 'Are you sure you want to remove this file? This action cannot be undone!!',
                    okButtonProps: {
                        type: 'danger',
                        children: 'Delete'
                    },
                    onOk: async () => {
                        return fetch(config.pythonBackendApi + "/isn-override/" + props.paxID + '/' + file.name, {
                            method: 'delete',
                            headers: await getHeaders()
                        })
                        .then(resp => {
                            let success = resp.status >= 200 && resp.status < 300;
                            if (!success){
                                message.error('Failed to remove file due to an error.');
                                console.error("Failed to remove file:", resp.text());
                                reject('API returned ' + resp.status);
                                handleFail();
                            }
                            else
                            {
                                resolve(success);
                                message.success(`Successfully deleted '${file.name}'`);
                            }
                        })
                        .catch((err) => {
                            console.error("Failed to remove file:", err);
                            message.error('Failed to remove file due to an error.');
                            reject(err);
                            handleFail();
                        })
                    }
                })
            })
        },
        onPreview: async (file) => {
            console.log('Downloading file', file.name);
            fetch(config.pythonBackendApi + "/isn-override/" + props.paxID + "/" + file.name, {
                method: 'get',
                headers: await getHeaders()
            })
            .then((resp) => resp.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                anchor.href = url;
                anchor.download = file.name;
                anchor.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(anchor);
            })
        },
        onDownload: async (file) => {
            console.log('Downloading file', file.name);
            fetch(config.pythonBackendApi + "/isn-override/" + props.paxID + "/" + file.name, {
                method: 'get',
                headers: await getHeaders()
            })
            .then((resp) => resp.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                anchor.href = url;
                anchor.download = file.name;
                anchor.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(anchor);
            })
        },
        listType: 'picture',
        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
            if (props.readOnly){
                console.error('Uploading is not allowed after override is complete.')
                return;
            }
            fetch(config.pythonBackendApi + "/isn-override/" + props.paxID + "/" + file.name, {
                method: 'post',
                body: file,
                headers: await getHeaders()
            })
            .then(async resp => {
                if (resp.ok){
                    onSuccess(resp, file);
                    message.success("Upload successful!");
                }
                else
                {
                    throw new Error(await resp.text());
                }
            })
            .catch((err) => {
                onError(err);
                console.log(err);
                message.error("Failed to upload due to an error. Make sure the file size doesn't exceed 4MB.");
            })
        }
    }

    if (state.loading){
        return <Spin indicator={<Icon type="loading" />} />
    }

    return (
        <Upload
            {...uploadProps}
            {...props}
        >
            <Button><Icon type="upload" /> Upload</Button>
            {state.error ? <div><Typography.Text type='danger'>{state.error}</Typography.Text></div> : null}
            {props.readOnly ? null : <div><Typography.Text type='secondary'>File size limit: 4MB</Typography.Text></div>}
        </Upload>
    )
}

IsnOverrideFileUploader.defaultProps = {
    readOnly: false
}

export default IsnOverrideFileUploader;