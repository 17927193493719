import {
    Badge,
    Button,
    Collapse,
    Divider,
    Drawer,
    Dropdown,
    Icon,
    Input,
    InputNumber,
    Menu,
    Modal,
    Popconfirm,
    Tooltip
} from "antd";
import {BASE_CLS} from "./util";
import React, {useContext, useEffect} from "react";
import * as Phase2State from './state';
import {DepDestListItem} from "./types";
import ETable from "../../../../components/enchanced-antd-table";
import IsnCheckStatusButton from "../../../../components/IsnCheckStatusButton";
import {PaxISNWithData} from "../../../../components/isn/PaxISN";
import {PassengerEditor} from "../../../../components/PassengerEditor";
import NonIdealState from "../../../../components/NonIdealState";
import MCIcon from "../../../../components/icon";
import {stopPropagationProps} from "../../../../common/util-ts";
import {Location} from "../../../../common/types/location";
import {useHistory} from "react-router-dom";
import {
    approximateLegRouteColumnWidth,
    LegRoute
} from "../../../../components/flights/scheduling/util";
import {calcBagCount, calcBagWeight, calcTotalWeight} from "../../../../components/flights/scheduling/math";
import {getTransitType} from "../../../../common/carriable/util";
import * as NotesForPilot from "../../../../components/flights/scheduling/notes-for-pilot";
import {ScheduleEditorApi} from "./api";
import {ScheduledCgoNode, ScheduledPaxNode, ScheduleNode} from "../../../../components/scheduling/types";
import {getSNodeID} from "../../../../components/scheduling/util";

export interface Phase2TableProps {
    api: ScheduleEditorApi,
    containerElement?: HTMLElement
}

function NotesForPilotWrapper(props: { api: ScheduleEditorApi, entity: ScheduleNode, popupContainer: HTMLElement }){

    const { api, entity, popupContainer } = props;
    const note = entity.notesForPilot;
    const noteMap = api.data.getNotesForPilot();

    const entitiesWithNote = (note ? noteMap[note] : undefined) || [];

    const renderAccept: NotesForPilot.QuickEdit.QuickEditProps['renderAccept'] =
        ({ onConfirm, buttonProps }) => {

            function handleConfirm(updateAllWithNote?: boolean){

                if (!entity){
                    return;
                }

                let newEntities: ScheduleNode[] = [];

                function updateEntity(entity: ScheduleNode){
                    return {
                        ...entity,
                        notesForPilot: api.notesForPilot.getModifiedText()
                    }
                }

                if (updateAllWithNote){
                    const oldNote = entity.notesForPilot;

                    const noteMap = api.data.getNotesForPilot();
                    if (!noteMap[oldNote]){
                        return;
                    }

                    newEntities = noteMap[oldNote].map(updateEntity)
                }
                else
                {
                    newEntities = [updateEntity(entity)];
                }

                api.data.modifiedEntities.add(newEntities);
                onConfirm();
            }

        if (entitiesWithNote.length > 1){
            const overlay = (
                <Menu>
                    <Menu.Item
                        onClick={() => handleConfirm()}
                    >Update this item only</Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            Modal.confirm({
                                title: 'Review affected items',
                                content: <>
                                    <p>The following PAX/CGO will have their pilot notes changed:</p>
                                    <ul>
                                        {entitiesWithNote.map(entity => {
                                            if (entity.classType === 'flytsuite.paxnode'){
                                                const pax = entity as ScheduledPaxNode;
                                                return <li>{pax.lastName}, {pax.firstName}</li>
                                            }
                                            else
                                            {
                                                const cgo = entity as ScheduledCgoNode;
                                                return <li>{cgo.name}</li>
                                            }
                                        })}
                                    </ul>
                                </>,
                                okText: `Update ${entitiesWithNote.length} items`,
                                onOk: () => handleConfirm(true)
                            })
                        }}
                    >Update {entitiesWithNote.length} items</Menu.Item>
                </Menu>
            )

            return (
                <Dropdown
                    overlay={overlay}
                    trigger={['click']}
                >
                    <Button
                        {...buttonProps}
                    />
                </Dropdown>
            )
        }

        return (
            <Button
                {...buttonProps}
                onClick={() => handleConfirm()}
            />
        );
    }

    const readOnly = Boolean(entity.currentCarrierID) || !api.editMode.get();

    return (
        <NotesForPilot.QuickEdit.QuickEdit
            readOnly={readOnly}
            scheduleNodeData={entity}
            popupContainer={popupContainer}
            renderAccept={renderAccept}
            placeholderText={readOnly ? '—' : undefined}
            {...api.notesForPilot.getQuickEditProps(getSNodeID(entity))}
        />
    )
}

const Phase2Table: React.FC<Phase2TableProps> = (props) => {

    // Hooks -----------------------------------------------------------------------------------------------------------
    const { api } = props;
    const history = useHistory();
    // -----------------------------------------------------------------------------------------------------------------

    // Event handlers---------------------------------------------------------------------------------------------------
    function handleCellEdit(entityId: string, fieldKey: string, value: any) {
        api.data.modifyEntity(entityId, { [fieldKey]: value });
    }
    // -----------------------------------------------------------------------------------------------------------------

    // State helpers ---------------------------------------------------------------------------------------------------
    const hasSelectedPersonnel = !!api.selection.personnelIds.get().length;
    // -----------------------------------------------------------------------------------------------------------------

    const data = api.data.getLegs();

    const entityCount = data.reduce((count, leg) => {
        return count + leg.all.length
    }, 0)

    // Utils -----------------------------------------------------------------------------------------------------------
    const legRouteColumnWidth = approximateLegRouteColumnWidth(
        data.map((item) => ({
            departureName: item.departure?.name,
            destinationName: item.destination?.name
        }))
    );
    // -----------------------------------------------------------------------------------------------------------------

    function renderPanel(item: DepDestListItem){

        const selectedItems = item.all
            .filter((entity) => api.selection.entityIds.get().includes(getSNodeID(entity)));

        const itemsOnFlight = item.all
            .filter((entity) => entity.currentCarrierID);

        const depName = item.departure?.name;
        const destName = item.destination?.name;

        const pax = item.pax;
        const cgo = item.cgo;

        const totWt = calcTotalWeight(item.all);
        const totPaxWt = calcTotalWeight(item.pax);
        const totCgoWt = calcTotalWeight(item.cgo);

        const totBagWt = calcBagWeight(item.pax);
        const totBagCt = calcBagCount(item.pax);

        let tooltipTitle = 'Click to show table';
        if (api.editor.visibleLegs.isLegVisible(item.departure._id, item.destination._id)){
            tooltipTitle = 'Click to hide table';
        }

        const tooltipContent = <>
            <p>{tooltipTitle}</p>
            <table className={BASE_CLS + '-table-collapse-panel-tooltip-table'}>
                <tbody>
                    <tr>
                        <th>PAX</th>
                        <td>{pax.length}</td>
                    </tr>
                    <tr>
                        <th>CGO</th>
                        <td>{cgo.length}</td>
                    </tr>
                    <tr>
                        <th>Transit</th>
                        <td>{getTransitType(item.departure, item.destination)}</td>
                    </tr>
                    <tr>
                        <th>Total Weight</th>
                        <td>{totWt}</td>
                    </tr>
                    <tr>
                        <th>Total PAX Weight</th>
                        <td>{totPaxWt}</td>
                    </tr>
                    <tr>
                        <th>Total CGO Weight</th>
                        <td>{totCgoWt}</td>
                    </tr>
                    <tr>
                        <th>Total Bag Weight</th>
                        <td>{totBagWt}</td>
                    </tr>
                    <tr>
                        <th>Total Bag Count</th>
                        <td>{totBagCt}</td>
                    </tr>
                </tbody>
            </table>
        </>

        const header = (
            <Tooltip
                className={
                    BASE_CLS + '-table-collapse-panel-header ' +
                    (selectedItems.length ? BASE_CLS + '-table-collapse-panel-tooltip-has-selected-personnel' : '')
                }
                title={tooltipContent}
                placement="leftTop"
                mouseEnterDelay={1}
            >
                {selectedItems.length ? (
                    <Tooltip title={`${selectedItems.length} selected`}>
                        <div className={BASE_CLS + '-table-collapse-panel-counter-wrapper'}>
                            <Badge
                                className={BASE_CLS + '-table-collapse-panel-counter'}
                                count={selectedItems.length}
                            />
                        </div>
                    </Tooltip>
                ) : null}
                {itemsOnFlight.length && !selectedItems.length ? (
                    <Tooltip title={`${itemsOnFlight.length} on flight`}>
                        <div className={BASE_CLS + '-table-collapse-panel-counter-wrapper'}>
                            <Badge
                                className={BASE_CLS + '-table-collapse-panel-counter'}
                                count={itemsOnFlight.length}
                            />
                        </div>
                    </Tooltip>
                ) : null}
                <div
                    className={BASE_CLS + '-table-collapse-panel-section'}
                    style={{
                        width: legRouteColumnWidth,
                        maxWidth: legRouteColumnWidth,
                        minWidth: legRouteColumnWidth
                    }}
                >
                    <LegRoute departureName={depName} destinationName={destName}/>
                </div>
                <div
                    className={BASE_CLS + '-table-collapse-panel-section'}
                    style={{minWidth: 110}}
                >
                    <span>{getTransitType(item.departure, item.destination)}</span>
                </div>
                <div
                    className={BASE_CLS + '-table-collapse-panel-section'}
                    style={{minWidth: 130}}
                >
                    <span><strong>{pax.length}</strong> PAX</span>
                    <Divider type="vertical"/>
                    <span><strong>{cgo.length}</strong> CGO</span>
                </div>
                <div
                    className={
                        BASE_CLS + '-table-collapse-panel-section ' +
                        BASE_CLS + '-table-collapse-panel-section-totals'
                    }
                    style={{minWidth: 410}}
                >
                    <strong style={{marginRight: '12px'}}>Totals:</strong>
                    <span>{totWt} Wt</span>
                    <Divider type="vertical"/>
                    <span>{totPaxWt} Pax Wt</span>
                    <Divider type="vertical"/>
                    <span>{totCgoWt} Cgo Wt</span>
                    <Divider type="vertical"/>
                    <span>{totBagWt} Bag Wt</span>
                    <Divider type="vertical"/>
                    <span>{totBagCt} Bag Ct</span>
                </div>
            </Tooltip>
        )

        const deleteBtn = (
            <Popconfirm
                title="Are you sure you want to remove this leg?"
                okText="Remove"
                okButtonProps={{
                    type: 'danger'
                }}
                onConfirm={() => {
                    api.data.removeLeg(item.departure._id, item.destination._id);
                }}
                placement="left"
            >
                <Button
                    type="danger"
                    icon="delete"
                    size="small"
                    ghost
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            </Popconfirm>
        )

        let addBtn = null;
        if (hasSelectedPersonnel && api.carriableSelector.tabs.get().upperTab === 'personSearch'){
            addBtn = (
                <Button
                    type="primary"
                    icon="plus"
                    size="small"
                    style={{ marginRight: 6 }}
                    onClick={() => api.selection.personnelIds.addToSchedule(
                        item.departure._id,
                        item.destination._id
                    )}
                >Add {api.selection.personnelIds.get().length} PAX</Button>
            )
        }
        else if (api.carriableSelector.tabs.get().upperTab === 'cargoForm'){
            addBtn = (
                <Button
                    type="primary"
                    icon="plus"
                    size="small"
                    style={{ marginRight: 6 }}
                    onClick={(e) => {
                        e.stopPropagation();
                        api.forms.cargoForm.submit(
                            item.departure._id,
                            item.destination._id
                        )
                    }}
                >Add Cargo</Button>
            )
        }

        const reorderBtns = (
            <Button.Group
                style={{ marginRight: 6 }}
            >
                <Tooltip
                    title="Reorder down"
                    mouseEnterDelay={1}
                >
                    <Button
                        icon="arrow-down"
                        size="small"
                        disabled={item.rangeEnd >= entityCount-1}
                        onClick={(e) => {
                            e.stopPropagation();
                            api.data.swapLegs(item, 'down');
                        }}
                    />
                </Tooltip>
                <Tooltip
                    title="Reorder up"
                    mouseEnterDelay={1}
                >
                    <Button
                        icon="arrow-up"

                        size="small"
                        disabled={item.rangeStart === 0}
                        onClick={(e) => {
                            e.stopPropagation();
                            api.data.swapLegs(item, 'up');
                        }}
                    />
                </Tooltip>
            </Button.Group>
        )

        let moreBtn: JSX.Element;

        {
            const overlay = (
                <Menu>
                    <Menu.Item
                        disabled={selectedItems.length === item.all.length}
                        onClick={(clickParam) => {
                            clickParam.domEvent.stopPropagation();
                            api.editor.visibleLegs.show(item.departure?._id, item.destination?._id);
                            api.selection.entityIds.add(item.all.map(e => getSNodeID(e)));
                        }}
                    >
                        Select all
                    </Menu.Item>
                    <Menu.Item
                        disabled={selectedItems.length === 0}
                        onClick={(clickParam) => {
                            clickParam.domEvent.stopPropagation();
                            api.selection.entityIds.remove(item.all.map(e => getSNodeID(e)));
                        }}
                    >
                        De-select all
                    </Menu.Item>
                    <Divider type="horizontal" style={{ margin: 0 }} />
                    <Menu.Item
                        onClick={(clickParam) => {
                            clickParam.domEvent.stopPropagation();
                            const entityIds = item.all.map(e => getSNodeID(e));
                            api.data.setEntityLegs(
                                entityIds,
                                item.destination,
                                item.departure);
                        }}
                    >
                        Swap departure & destination
                    </Menu.Item>
                    <Divider type="horizontal" style={{ margin: 0 }} />
                    <Menu.Item
                        onClick={(clickParam) => {
                            clickParam.domEvent.stopPropagation();
                            const callback = (departure: Location, destination: Location) => {
                                api.data.setEntityLegs(
                                    item.all.map(e => getSNodeID(e)),
                                    departure,
                                    destination
                                )
                            }

                            api.modal.newLegFormModal.open(
                                item.departure,
                                item.destination,
                                callback
                            )
                        }}
                    >
                        Change departure & destination...
                    </Menu.Item>
                </Menu>
            )

            moreBtn = (
                <Dropdown
                    overlay={overlay}
                    trigger={['click']}
                >
                    <Button
                        icon="more"
                        size="small"
                        style={{
                            marginRight: 6
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Dropdown>
            )
        }

        let extra = [];

        if (api.editMode.get()){
            extra = [
                addBtn,
                reorderBtns,
                moreBtn,
                deleteBtn
            ]
        }

        return <Collapse.Panel
            key={`${item.departure._id}::${item.destination._id}`}
            header={header}
            extra={extra}
            className={
                BASE_CLS + '-table-collapse-panel ' +
                (itemsOnFlight.length && !selectedItems.length ? BASE_CLS + '-table-collapse-panel-has-on-flight ' : '') +
                (selectedItems.length ? BASE_CLS + '-table-collapse-panel-has-selection' : '')
            }
        >
            <div className={BASE_CLS + '-table-container'}>
                <ETable.Group>
                    {renderPaxTable(item)}
                    {renderCgoTable(item)}
                </ETable.Group>
            </div>
        </Collapse.Panel>
    }

    function renderPaxTable(item: DepDestListItem){

        const columns = [
            {
                title: <strong><i>PAX ({item.pax.length})</i></strong>,
                key: 'action',
                width: 73,
                fixed: 'left' as 'left',
                render: (_, row) => {

                    if (!api.editMode.get()) return (
                        <div className={BASE_CLS + '-icon-container'}>
                            <MCIcon type="user" />
                        </div>
                    );

                    const reorderBtns = [
                        <Tooltip
                            title="Reorder down"
                            mouseEnterDelay={1}
                        >
                            <Button
                                icon="arrow-down"
                                size="small"
                                disabled={row.scheduledOrder + 1 - item.rangeStart >= item.pax.length}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    api.data.swapEntities(row.scheduledOrder, row.scheduledOrder + 1)
                                }}
                            />
                        </Tooltip>
                        ,
                        <Tooltip
                            title="Reorder up"
                            mouseEnterDelay={1}
                        >
                            <Button
                                icon="arrow-up"
                                size="small"
                                style={{ marginRight: 6 }}
                                disabled={row.scheduledOrder - 1 - item.rangeStart < 0}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    api.data.swapEntities(row.scheduledOrder, row.scheduledOrder - 1)
                                }}
                            />
                        </Tooltip>
                    ]

                    return (
                        <Button.Group style={{ whiteSpace: 'nowrap' }}>
                            {reorderBtns}
                        </Button.Group>
                    )
                }
            },
            {
                title: (
                    <Tooltip title="Order of Importance" mouseEnterDelay={0.25}>
                        <span
                            style={{
                                cursor: 'help'
                            }}
                        >OI <Icon type="question-circle" /></span>
                    </Tooltip>
                ),
                key: 'oi',
                dataIndex: 'scheduledOrder',
                render: (order) => order + 1,
                width: 60
            },
            {
                title: 'Name',
                key: 'name',
                width: 200,
                render: (_, record) => {
                    let xbr;

                    let icon = null;

                    let spanClassName = record.currentCarrierID ? BASE_CLS + '-table-on-flight' : '';

                    if (record.currentCarrierID){
                        icon = <Tooltip title="This passenger is currently on a flight. Editing is disabled.">
                            <a
                                href={"/app/scheduling/flight/" + record.currentCarrierID?._id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/app/scheduling/flight/" + record.currentCarrierID?._id)
                                }}
                                style={{ marginRight: '6px', position: 'relative' }}
                            >
                                <MCIcon type="helicopter-flight" />
                            </a>
                        </Tooltip>
                    }

                    if(!api.editMode.get()){
                        var data;
                        if (record.__typename === 'Person')
                        {
                            data = {
                                ...record,
                                personID: { ...record }
                            }
                        }
                        else
                        {
                            data = record
                        }
                        if(data.personID){
                            xbr = data.personID.extraBroadState;
                        }else {
                            xbr = record.extraBroadState;
                        }
                        let name = `${record.lastName}, ${record.firstName}`
                        if(xbr === 'FAIL'){
                            name = `\u2194 ${record.lastName}, ${record.firstName}`
                        }else if(xbr === 'PASS'){
                            name = `\uc6c3 ${record.lastName}, ${record.firstName}`
                        }
                        return <span className={spanClassName}>
                        {icon}
                            <Button className={'mc-link-btn ' + spanClassName} onClick={() => {
                                api.modal.paxModal.open(record);
                            }}>{name}</Button>
                    </span>
                    }
                    xbr = record.extraBroadState || (record.personID && record.personID.extraBroadState)

                    let text;
                    if(xbr === 'FAIL'){
                        text = `\u2194 ${record.lastName}, ${record.firstName}`
                    }else if(xbr === 'PASS'){
                        text = `\uc6c3 ${record.lastName}, ${record.firstName}`
                    }else {
                        let name = record.lastName + ", " + record.firstName
                        text = <span>{name}</span>
                    }

                    return (
                        <span className={spanClassName}>
                            {icon}
                            {text}
                        </span>
                    );
                }
            },
            {
                title: 'Notes for Pilot',
                key: 'notesForPilot',
                width: 150,
                render: (_, row) => (
                    <NotesForPilotWrapper
                        entity={row}
                        popupContainer={props.containerElement}
                        api={api}
                    />
                )
            },
            {
                title: 'Nation',
                key: 'nation',
                width: 70,
                render: (_, row) => row.personID && row.personID.nation
            },
            {
                title: 'Employer',
                key: 'employer',
                width: 120,
                render: (_, record) => record.employerID && record.employerID.name
            },
            {
                title: 'Pax Wt',
                key: 'paxWeight',
                dataIndex: 'paxWeight',
                width: 50,
                editable: true,
                render: (weight: number, row) => {
                    if (!api.editMode.get() || row.currentCarrierID) return weight || 0;
                    return <span {...stopPropagationProps()}>
                        <InputNumber
                            min={0}
                            style={{
                                maxWidth: 50
                            }}
                            onChange={(value) => handleCellEdit(getSNodeID(row), 'paxWeight', value || 0)}
                            size="small"
                            value={weight || 0}
                            {...stopPropagationProps()}
                        />
                    </span>
                }
            },
            {
                title: 'Bag Wt',
                key: 'bagWeight',
                dataIndex: 'bagWeight',
                width: 50,
                editable: true,
                render: (weight, row) => {
                    if (!api.editMode.get() || row.currentCarrierID) return weight || 0;
                    return <span {...stopPropagationProps()}>
                        <InputNumber
                            min={0}
                            style={{
                                maxWidth: 50
                            }}
                            onChange={(value) => handleCellEdit(getSNodeID(row), 'bagWeight', value || 0)}
                            size="small"
                            value={weight || 0}
                        />
                    </span>
                }
            },
            {
                title: 'Bag Ct',
                key: 'bagCount',
                dataIndex: 'bagCount',
                width: 50,
                editable: true,
                render: (count, row) => {
                    if (!api.editMode.get() || row.currentCarrierID) return count || 0;
                    return <span {...stopPropagationProps()}>
                        <InputNumber
                            min={0}
                            style={{
                                maxWidth: 50
                            }}
                            onChange={(value) => handleCellEdit(getSNodeID(row), 'bagCount', value || 0)}
                            size="small"
                            value={count || 0}
                        />
                    </span>
                }
            },
            {
                title: 'Charge Code',
                key: 'chargeCode',
                dataIndex: 'chargeCode',
                editable: true,
                width: 130,
                render: (code, row) => {
                    if (!api.editMode.get() || row.currentCarrierID) return code && code.toUpperCase();
                    return (
                        <Input
                            min={0}
                            value={code}
                            style={{
                                minWidth: 70,
                            }}
                            placeholder={String(api.chargeCodes.getDefault()).toUpperCase()}
                            onChange={(event) => handleCellEdit(getSNodeID(row), 'chargeCode', event.target.value && event.target.value.split(" ").join("").toUpperCase())}
                            {...stopPropagationProps()}
                            size="small"
                        />
                    )
                }
            },
            {
                title: 'ISN Status',
                key: 'isnStatus',
                render: (_, row) => {

                    if (row.transitType !== 'OUTBOUND') return '—';

                    const pax = {
                        ...row,
                        isnCheckError: api.bulkIsnChecker.checkError(getSNodeID(row)),
                        isnCheckRunning: api.bulkIsnChecker.checkLoading(getSNodeID(row._id))
                    }

                    return <IsnCheckStatusButton
                        style={{
                            pointerEvents: api.editMode.get() ? 'none' : undefined,
                            minWidth: 120,
                            maxWidth: 200
                        }}
                        pax={pax}
                        size="small"
                        block
                        checking={pax.isnCheckRunning}
                        onClick={(e) => {
                            e.stopPropagation();
                            api.drawer.isnDrawer.open(row);
                        }}
                    />
                }
            }
        ]

        if (!item.pax.length) return null;

        return <ETable
            className={BASE_CLS + '-table'}
            scroll={{
                x: 'max-content'
            }}
            dataSource={item.pax}
            columns={columns}
            rowSelection={api.editMode.get() ? ({
                selectedRowKeys: api.selection.entityIds.get(),
                onChange: api.selection.entityIds.set,
                columnWidth: 32
            }) : null}
            onRow={(record: ScheduleNode) => {
                return {
                    onClick: () => {
                        if (!api.editMode.get()) return;
                        api.selection.entityIds.toggle(getSNodeID(record));
                    },
                    className: api.editMode.get() ? BASE_CLS + '-table-row' : '',
                    disabled: !!record.currentCarrierID
                }
            }}
            pagination={false}
            rowKey={record => getSNodeID(record)}
            size="small"
        />
    }

    function renderCgoTable(item: DepDestListItem){

        const cgo = item.cgo.filter(e => e.classType === 'flytsuite.cgonode');

        const columns = [
            {
                title: <strong><i>CGO ({cgo.length})</i></strong>,
                key: 'action',
                width: 73,
                fixed: 'left' as 'left',
                render: (_, row) => {

                    if (!api.editMode.get()) return (
                        <div className={BASE_CLS + '-icon-container'}>
                            <MCIcon type="cargo" />
                        </div>
                    );

                    const reorderBtns = [
                        <Tooltip
                            title="Reorder down"
                            mouseEnterDelay={1}
                        >
                            <Button
                                icon="arrow-down"
                                size="small"
                                disabled={row.scheduledOrder + 1 - item.rangeStart >= item.all.length}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    api.data.swapEntities(row.scheduledOrder, row.scheduledOrder + 1);
                                }}
                            />
                        </Tooltip>
                        ,
                        <Tooltip
                            title="Reorder up"
                            mouseEnterDelay={1}
                        >
                            <Button
                                icon="arrow-up"
                                size="small"
                                style={{ marginRight: 6 }}
                                disabled={row.scheduledOrder - 1 - item.rangeStart < item.pax.length}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    api.data.swapEntities(row.scheduledOrder, row.scheduledOrder - 1);
                                }}
                            />
                        </Tooltip>
                    ]

                    return (
                        <Button.Group style={{ whiteSpace: 'nowrap' }}>
                            {reorderBtns}
                        </Button.Group>
                    )
                }
            },
            {
                title: (
                    <Tooltip title="Order of Importance" mouseEnterDelay={0.25}>
                        <span
                            style={{
                                cursor: 'help'
                            }}
                        >OI <Icon type="question-circle" /></span>
                    </Tooltip>
                ),
                key: 'oi',
                dataIndex: 'scheduledOrder',
                render: (order) => order + 1,
                width: 60
            },
            {
                title: 'Name',
                key: 'name',
                width: 200,
                render: (_, record) => {
                    let spanClassName = record.currentCarrierID ? BASE_CLS + '-table-on-flight' : '';

                    if (record.currentCarrierID){
                        return <span className={spanClassName}>
                            <a
                                href={"/app/scheduling/flight/" + record.currentCarrierID?._id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/app/scheduling/flight/" + record.currentCarrierID?._id)
                                }}
                                style={{marginRight: '6px', position: 'relative'}}
                            >
                                <Tooltip title="This cargo is currently on a flight. Editing is disabled.">
                                    <MCIcon type="helicopter-flight" style={{marginRight: '6px', position: 'relative'}}/>
                                </Tooltip>
                            </a>
                            <span>{record.name}</span>
                        </span>
                    }

                    return record.name
                }
            },
            {
                title: 'Notes for Pilot',
                key: 'notesForPilot',
                width: 150,
                render: (_, row) => (
                    <NotesForPilotWrapper
                        entity={row}
                        popupContainer={props.containerElement}
                        api={api}
                    />
                )
            },
            {
                title: 'Weight',
                key: 'weight',
                dataIndex: 'weight',
                width: 70,
                editable: true,
                render: (weight, row) => {
                    if (!api.editMode.get() || row.currentCarrierID) return weight || 0;
                    return <span {...stopPropagationProps()}>
                        <InputNumber
                            min={0}
                            onChange={(value) => handleCellEdit(getSNodeID(row), 'weight', value || 0)}
                            size="small"
                            value={weight || 0}
                        />
                    </span>
                }
            },
            {
                title: 'HazMat',
                key: 'hazmat',
                width: 120,
                render: (_, record) => record.hazmat ? record.hazmatUnCode || "YES" : null
            },
            {
                title: 'Attention',
                key: 'attentionTo',
                width: 200,
                render: (_, record) => record.attentionTo
            },
            {
                title: 'Initials',
                key: 'initials',
                width: 130,
                render: (_, record) => record.initials
            },
            {
                title: 'Notes',
                key: 'notes',
                render: (_, record) => record.notes
            }
        ]

        if (!cgo.length) return null;

        return <ETable
            className={BASE_CLS + '-table'}
            scroll={{
                x: 'max-content'
            }}
            dataSource={cgo}
            columns={columns}
            rowSelection={api.editMode.get() ? ({
                selectedRowKeys: api.selection.entityIds.get(),
                onChange: api.selection.entityIds.set,
                columnWidth: 32
            }) : null}
            onRow={(record: ScheduleNode) => {
                return {
                    onClick: () => {
                        if (!api.editMode.get()) return;
                        api.selection.entityIds.toggle(getSNodeID(record));
                    },
                    className: api.editMode.get() ? BASE_CLS + '-table-row' : '',
                    disabled: !!record.currentCarrierID
                }
            }}
            pagination={false}
            rowKey={record => getSNodeID(record)}
            size="small"
        />
    }

    function renderIsnDrawer(){
        let pax = api.drawer.isnDrawer.get().pax;
        let title = pax ? `${pax.lastName}, ${pax.firstName}` : '';
        return (
            <Drawer
                title={title}
                visible={api.drawer.isnDrawer.get().visible}
                width="50rem"
                onClose={api.drawer.isnDrawer.close}
                closable
            >
                <PaxISNWithData paxId={getSNodeID(pax)} />
            </Drawer>
        )
    }

    function renderPaxModal(){
        const modalState = api.modal.paxModal.get();
        const modalActions = api.modal.paxModal;
        return (
            <Modal
                title="Edit Passenger"
                visible={modalState.visible}
                onCancel={modalActions.close}
                footer={null}
                width="50rem"
            >
                <PassengerEditor
                    title="Edit Passenger"
                    edit={true}
                    data={modalState.pax}
                    onCancel={modalActions.close}
                />
            </Modal>
        )
    }

    const panels = data.map(renderPanel);

    let noDataMsg = null;
    let collapse = null;
    if (api.data.modifiedEntities.get().length) {
        collapse = (
            <Collapse
                className={BASE_CLS + '-table-collapse'}
                activeKey={api.editor.visibleLegs.getAsStrings()}
                onChange={api.editor.visibleLegs.setAsStrings}
            >
                {panels}
            </Collapse>
        )
    }
    else if (api.editMode.get() && !api.queryInfo.loading)
    {
        noDataMsg = <NonIdealState
            title="Add Passengers and Cargo"
            description={
                <>
                    <p>Use the left panel to add PAX/CGO</p>
                    {api.carriableSelector.hideCarriablePanel.get() ? (
                        <Button
                            icon="menu-unfold"
                            type="primary"
                            onClick={api.carriableSelector.hideCarriablePanel.toggle}
                        >Show Panel</Button>
                    ) : null}
                </>
            }
            icon="usergroup-add"
            style={{ flex: 1 }}
        />
    }
    else if (!api.queryInfo.loading) {
        noDataMsg = <NonIdealState
            title="No Data"
            description="This schedule has no PAX/CGO"
            icon="exclamation-circle"
            style={{ flex: 1 }}
        />
    }

    return <>
        {collapse}
        {noDataMsg}
        {renderIsnDrawer()}
        {renderPaxModal()}
    </>
}

export default Phase2Table