import React from 'react';
import PaxDetail from './Pax';
import CgoDetail from './Cgo';
import ManifestDetail from './Manifest';
import ISNDetail from './ISN';
import CovidDetail from './Covid';
import { Empty } from 'antd';
import CovidVaccineDetail from 'components/reporting/detail/CovidVaccine';
import CovidVaccineDetailedDetail from 'components/reporting/detail/CovidVaccineDetailed';
import PersonHotFuelDetail from 'components/reporting/detail/PersonHotFuel';
import PaxIsnEventsDetail from 'components/reporting/detail/PaxIsnEvents';

/**
 * Renders report details components depending on the report type specified. Also renders number of records.
 * @param {string} report_type
 * @param {import('common/types/report-types').Report} report
 * @param {(flightId: string, flightDesig: string)} onManifestExportClick
 * @returns {ReactNode}
 */
export const renderDetail = (report_type, report, onManifestExportClick) => {
	if (!report || !report.result || !report.result.docs || !report.result.docs.length) {
		return (
			<div style={{ marginTop: '5rem' }}>
				<Empty description='No data to display' />
			</div>
		);
	}
	const data = report.result.docs;

	const renderDetailByType = () => {
		switch (report_type) {
			case 'PAX':
				return <PaxDetail data={data} />;
			case 'CGO':
				return <CgoDetail data={data} />;
			case 'MANIFEST':
				return <ManifestDetail onManifestExportClick={onManifestExportClick} data={data} />;
			case 'ISN':
				return <ISNDetail data={data} />;
			case 'COVID':
				return <CovidDetail data={data} />;
			case 'PAX_COVID19_VAX':
				return <CovidVaccineDetail data={data} />;
			case 'PERSON_COVID19_VAX_DETAILS':
				return <CovidVaccineDetailedDetail data={data} />;
			case 'PERSON_HOTFUEL':
				return <PersonHotFuelDetail data={data} />;
			case 'PAX_ISN_EVENTS':
				return <PaxIsnEventsDetail data={data} />;
			default:
				return undefined;
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% + 2rem)', overflow: 'hidden', margin: '-1rem' }}>
			<div style={{ flex: 1, minHeight: 0, minWidth: 0 }}>
				{renderDetailByType()}
			</div>
		</div>
	);
};
