import { AutoPaginator } from "hooks/apollo-extensions/useAutoPaginatedQuery";

export async function fetchMoreAndGetToken(fetchMore: any, queryVars: any, token: string, queryKey: string){
    let newVars = {
        ...queryVars,
        bookmark: token,
    }
    let newResult;
    await fetchMore({
        variables: newVars,
        updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult[queryKey]) return prev;
            if (AutoPaginator.isOnLastPage(prev[queryKey].bookmark, fetchMoreResult[queryKey].bookmark)){
                newResult = prev;
                return prev;
            }
            newResult = {
                ...prev,
                [queryKey]: {
                    ...prev[queryKey],
                    ...fetchMoreResult[queryKey],
                    docs: [
                        ...prev[queryKey].docs,
                        ...fetchMoreResult[queryKey].docs
                    ]
                }
            }
            return newResult;
        }
    })
    let newToken = newResult?.[queryKey]?.bookmark;
    if (newToken === 'nil'){
        newToken = null;
    }
    return newToken;
}