import { useApolloClient, useQuery } from "@apollo/react-hooks";
import defaultState from "defaultState";
import gql from "graphql-tag";
import moment, { MomentInput } from "moment";

const QUERY_CACHE = gql`
{
    reportDateRange @client
}
`

export default function useCachedDateRange(defaultStartDate?: MomentInput, defaultEndDate?: MomentInput){
    let { data, loading } = useQuery(QUERY_CACHE);
    const client = useApolloClient();

    let reportDateRange = data?.reportDateRange;

    function cleanDateRange(dateRange: [MomentInput, MomentInput]){
        if (!dateRange || !dateRange[0] || !dateRange[1]){
            if (defaultStartDate && defaultEndDate){
                dateRange = [ moment(defaultStartDate), moment(defaultEndDate) ]
            }
            else
            {
                dateRange = [ moment(defaultState.reportDateRange[0]), moment(defaultState.reportDateRange[1]) ];
            }
        }
        else {
            dateRange = [ moment(dateRange[0]), moment(dateRange[1]) ]
        }
        return dateRange;
    }

    reportDateRange = cleanDateRange(reportDateRange);

    return {
        dateRange: reportDateRange,
        setDateRange: (newDateRange) => {
            newDateRange = cleanDateRange(newDateRange);
            newDateRange = [moment(newDateRange[0]).format('YYYY-MM-DD'), moment(newDateRange[1]).format('YYYY-MM-DD')]
            client.writeData({
                data: {
                    reportDateRange: newDateRange
                }
            })
        },
        loaded: !loading
    }
}