import React from 'react';
import UserPreferencesModal from './preferences/Preferences';

class UserPreferencesWrapper extends React.Component {
    state = {
        visible: false
    }
    open = () => this.setState({ visible: true })
    close = () => this.setState({ visible: false })
    render(){
        const { component: Component, ...rest } = this.props;
        return (
            <React.Fragment>
                <Component 
                {...rest}
                userprefs={{
                    open: this.open,
                    close: this.close
                }}
                />
                <UserPreferencesModal
                    onCancel={this.close}
                    visible={this.state.visible}
                />
            </React.Fragment>
        )
    }
}

const withUserPreferences = (component) => {
    return props => (
        <UserPreferencesWrapper {...props} component={component} />
    )
};

export default withUserPreferences