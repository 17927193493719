import useControlledState from './useControlledState';

export interface MultiSelectByIDOptions {
    selectedItems?: string[],
    onSelection?(selectedItems: string[]): void
}

export type IsSelected = (name: string) => boolean
export type ToggleSelection = (name: string) => void
export type ClearAll = () => void
export type SetSelectedItems = (items: string[]) => void
export type SelectedItems = string[]

export interface MultiSelectByIDReturn {
    isSelected?: IsSelected,
    toggleSelection?: ToggleSelection,
    clearAll?: ClearAll,
    setSelectedItems: SetSelectedItems,
    selectedItems: SelectedItems
}

export function useMultiSelectByID(options?: MultiSelectByIDOptions): MultiSelectByIDReturn {
    const [ selectedItems, setSelectedItems ] = useControlledState<SelectedItems>([], options && options.selectedItems, options && options.onSelection);
    
    function isSelected(name: string): boolean {
        return selectedItems.findIndex((item) => {
            return item === name;
        }) !== -1;
    }
    function toggleSelection(name: string): void {
        let selected = isSelected(name);
        if (selected){
            setSelectedItems(selectedItems.filter((item) => {
                return item !== name;
            }))
        }
        else
        {
            setSelectedItems([ ...selectedItems, name ])
        }
    }
    function clearAll(){
        setSelectedItems([]);
    }
    return {
        isSelected,
        toggleSelection,
        clearAll,
        setSelectedItems,
        selectedItems
    }
}