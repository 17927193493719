import { useQuery } from '@apollo/react-hooks';
import { Alert, Button, Card, Divider, Form, Icon, message, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { NetworkStatus } from 'apollo-client';
import { castArray, getPersonFullName } from 'common/util';
import CovidVaxDatesForm, { CovidVaxDatesFormProps } from 'components/covid-vax-date-form';
import { useCovidVaxDateFormSubmitter } from 'components/covid-vax-date-form/submitter';
import gql from 'graphql-tag';
import useUserGroups from 'hooks/useUserGroups';
import React, { useRef, useState } from 'react';

export interface CovidVaccineDatesProps {
    personID: string,
    personName: string
}

const HEALTH_INFO_QUERY = gql`
query GetHealthInfo($personID: ID!) {
    person(id: $personID){
        _id
        lastName
        firstName
        customerID {
            _id
            name
        }
        employerID {
            _id
            name
        }
    }
    GetPersonCov19VaxRecords(personID: $personID) {
        personID
        personName
        customerID
        customerName
        label
        order
        comment
        manufacturer
        date
    }
}
`

const WrappedCovidVaxDatesForm = Form.create<FormComponentProps & CovidVaxDatesFormProps & { onFieldsChange: (fields: any) => void }>({
    onFieldsChange(props, _, allFields) {
        props.onFieldsChange(allFields);
    }
})(CovidVaxDatesForm)

const CovidVaccineDates: React.FC<CovidVaccineDatesProps & FormComponentProps> = (props) => {

    const [ userGroups ] = useUserGroups();

    let canEditOrDelete = userGroups.includes('flytsuite.covid19vaxrecords.edit') && userGroups.includes('flytsuite.covid19vaxrecords.delete');

    const [ , setVaxFormFields ] = useState<any>();

    let vaxFormRef = useRef(null);
    let resetFormFuncRef = useRef(null);

    const { data, error, refetch, networkStatus } = useQuery(HEALTH_INFO_QUERY, {
        variables: {
            personID: props.personID
        },
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
    })

    let personData = data?.person;

    let records = castArray(data?.GetPersonCov19VaxRecords);

    const [ submitVaxDates, { loading: submitVaxDatesLoading } ] = useCovidVaxDateFormSubmitter(records, {
        personID: props.personID,
        personName: getPersonFullName(personData),
        customerID: personData?.customerID?._id,
        customerName: personData?.customerID?.name,
        employerID: personData?.employerID?._id,
        employerName: personData?.employerID?.name,
        useOptimisticResponse: false,
        onMutationUpdate: (cache, newRecords) => {
            let vars = { personID: props.personID }
            let queryData: any = cache.readQuery({
                query: HEALTH_INFO_QUERY,
                variables: vars
            })

            let newQueryData = {
                ...queryData,
                GetPersonCov19VaxRecords: newRecords
            }

            cache.writeQuery({
                query: HEALTH_INFO_QUERY,
                variables: vars,
                data: newQueryData
            })
            refetch()
        },
        onCompleted: () => message.success('Successfully updated vaccine date information'),
        onError: () => message.error('Failed to update vaccine date info due to an error')
    })

    return <div>
        {error ? <Alert style={{ marginBottom: '12px' }} type="error" showIcon message="Failed to load health info" description={error?.message} /> : null}
        <Spin spinning={networkStatus === NetworkStatus.loading && (records.length === 0)} indicator={<Icon type="loading" />}>
            <Card title="Covid-19 Vaccine Dates" size="small" extra={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {vaxFormRef.current?.isFieldsTouched() ? <>
                        <Button
                            style={{ marginRight: '6px' }}
                            size="small"
                            onClick={() => {
                                if (resetFormFuncRef.current){
                                    resetFormFuncRef.current();
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            icon={submitVaxDatesLoading ? undefined : 'save'}
                            style={{ width: '70px' }}
                            disabled={submitVaxDatesLoading}
                            onClick={() => submitVaxDates(vaxFormRef.current)}
                        >
                            {submitVaxDatesLoading ? 'Saving' : 'Save'}
                        </Button>
                        <Divider type="vertical" style={{ marginLeft: '16px' }} />
                    </> : null}
                    <Button
                        className="mc-btn-transparent"
                        size="small"
                        icon={networkStatus === NetworkStatus.refetch ? 'loading' : 'reload'}
                        onClick={() => refetch()}
                    />
                </div>
            }
            style={{ height: '100%' }}
            >
                {!canEditOrDelete ? (
                    <Alert
                        showIcon
                        type="warning"
                        message="You do not have permission to edit the data below"
                        description="You are missing either edit or delete permissions or both"
                        style={{ marginBottom: '6px' }}
                    />
                ) : null}
                <WrappedCovidVaxDatesForm
                    onFieldsChange={(fields) => setVaxFormFields(fields)}
                    ref={vaxFormRef}
                    data={records}
                    allowRemoveOrClearRecord={canEditOrDelete}
                    disabled={!canEditOrDelete}
                    resetFormFunc={(resetFormFunc) => resetFormFuncRef.current = resetFormFunc}
                />
            </Card>
        </Spin>
    </div>
}

export default CovidVaccineDates;