import React from 'react';
import { Query, Mutation } from 'react-apollo';
import compose from 'lodash.flowright';
// import CredentialGrid from './CredentialGrid';
// import withLoading from '../WithLoading';
// import withError from '../WithError';
import withErrorCatch from '../withErrorCatch';
import Credentials from '../../Queries/Credentials';
import PropTypes from 'prop-types';
import LoadingContent from '../LoadingContent';
import { Alert, message } from 'antd';
import CredentialManager from './CredentialManager';
import withOrgData from '../WithOrgData';
import { SaveCredential, RemoveCredential } from './Mutations';
import gql from 'graphql-tag';
import { withUserGroups } from '../userGroup';

const credTypePrefix = {
    'US_DRIVERS_LICENSE': 'DRV',
    'TWIC': 'TWI',
    'SAFETY_CARD': 'SFC',
    'PASSPORT': 'PSP',
    'PASSPORT_CARD': 'PPC',
    'HUET_CARD': 'HUT',
    'ISN': 'ISN',
    'EBS_CARD': 'EBS'
}

const computeID = (type, personId) => {
    const idEnd = personId.replace('PER-', '');
    const prefix = credTypePrefix[type];
    if (prefix){
        return `${prefix}-${idEnd}`
    }
    throw Error(`Could not find prefix for type '${type}'`)
}
 
const CredentialManagerWithData = ({ personId, paxID, orgData, requireUserGroups=['flytsuite.master.all', 'flytsuite.master.credential.edit'], userGroups, ...restProps }) => {
    const variables = {
        personId,
        limit: 999
    }
    const hasPermission = requireUserGroups.find((group) => userGroups.includes(group)) ? true : false;
    return (
        <Query query={Credentials} variables={variables} fetchPolicy="network-only" >
        {
            ({ data, loading, error }) => {
                if (loading) return <LoadingContent />
                if (error) return (
                    <Alert showIcon type="error" message="Could not load credentials due to error" />
                )
                var credentials;
                try{
                    credentials = data.personCredentials;
                }
                catch(err){
                    credentials = [];
                }
                return (
                    <Mutation mutation={SaveCredential}>
                    {(save) => (
                        <Mutation mutation={RemoveCredential}>
                        {(remove) => (
                            <div>
                                {!hasPermission ? (
                                    <Alert type="warning" showIcon message="You do not have permission to edit credentials" style={{marginBlottom: 12}} />
                                ) : null}
                                <CredentialManager
                                    credentials={credentials}
                                    disabled={!hasPermission}
                                    onSave={(data, isNew=false) => {
                                        const credId = isNew ? computeID(data.type, personId) : data._id;
                                        const org = orgData.transporter || orgData.employer;
                                        const orgID = org && org._id;
                                        save({
                                            variables: {
                                                _id: isNew ? undefined : credId,
                                                key: data.key,
                                                expiration: data.expiration,
                                                type: data.type,
                                                tpID: orgID,
                                                customerID: orgData.customer && orgData.customer._id,
                                                personID: personId,
                                                paxID: paxID || undefined
                                            },
                                            update: (cache, { data: { setCredential } }) => {
                                                if (isNew){
                                                    const { personCredentials } = cache.readQuery({
                                                        query: Credentials,
                                                        variables
                                                    });
                                                    const newCreds = personCredentials.concat([setCredential])
                                                        .sort((a, b) => ('' + a.type).localeCompare('' + b.type))
                                                    
                                                    cache.writeQuery({
                                                        query: Credentials,
                                                        variables,
                                                        data: {
                                                            personCredentials: newCreds
                                                        }
                                                    })
                                                }else{
                                                    cache.writeFragment({
                                                        id: credId,
                                                        fragment: gql`
                                                            fragment Cred on Credential {
                                                                _id
                                                                type
                                                                personID {
                                                                    _id
                                                                }
                                                                key
                                                                expiration
                                                            }
                                                        `,
                                                        data: {
                                                            __typename: 'Credential',
                                                            _id: credId,
                                                            key: data.key,
                                                            expiration: data.expiration,
                                                            type: data.type,
                                                            personID: {
                                                                __typename: 'Person',
                                                                _id: personId
                                                            }
                                                        }
                                                    })
                                                }
                                            },
                                            optimisticResponse: {
                                                __typename: 'Mutation',
                                                setCredential: {
                                                    __typename: 'Credential',
                                                    _id: credId,
                                                    key: data.key,
                                                    expiration: data.expiration,
                                                    type: data.type,
                                                    personID: {
                                                        __typename: 'Person',
                                                        _id: personId
                                                    }
                                                }
                                            }
                                        })
                                        .then(() => message.success('Credential saved successfully'))
                                        .catch(() => message.error('Failed to save credential'))
                                    }}
                                    onRemove={(credId) => {
                                        const cred = credentials.find(({ _id }) => _id === credId) || {};
                                        const org = orgData.transporter || orgData.employer;
                                        const orgID = org && org._id;
                                        remove({
                                            variables: {
                                                _id: credId,
                                                tpID: orgID
                                            },
                                            optimisticResponse: {
                                                __typename: 'Mutation',
                                                setCredential: {
                                                    __typename: 'Credential',
                                                    ...cred,
                                                    personID: {
                                                        __typename: 'Person',
                                                        _id: personId
                                                    }
                                                }
                                            },
                                            update: (cache, { data: { setCredential } }) => {
                                                const { personCredentials } = cache.readQuery({
                                                    query: Credentials,
                                                    variables
                                                });
                                                
                                                cache.writeQuery({
                                                    query: Credentials,
                                                    variables,
                                                    data: {
                                                        personCredentials: personCredentials.filter(cred => cred._id !== setCredential._id)
                                                    }
                                                })
                                            }
                                        })
                                        .then(() => message.success('Credential removed successfully'))
                                        .catch(() => message.error('Failed to remove credential'))
                                    }}
                                    {...restProps}
                                />
                            </div>
                            )
                        }    
                        </Mutation>
                    )}
                    </Mutation>
                )
            }
        }
        </Query>
    )
}

CredentialManagerWithData.propTypes = {
    personId: PropTypes.string.isRequired
}

export default compose(
    withErrorCatch(),
    withOrgData,
    withUserGroups
)(CredentialManagerWithData)