import { Table } from 'antd';
import React, { HTMLAttributes } from 'react';
import packageJson from '../../package.json';

const AboutApp: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    let data = [
        {
            name: 'VERSION',
            value: packageJson.version
        }
    ]
    return <div {...props}>
        <Table
            dataSource={data}
            columns={[
                {
                    key: 'name',
                    title: 'Name',
                    dataIndex: 'name',
                    render: (text) => <strong>{text}</strong>
                },
                {
                    key: 'value',
                    title: 'Value',
                    dataIndex: 'value'
                }
            ]}
            pagination={false}
            showHeader={false}
        />
    </div>
}

export default AboutApp