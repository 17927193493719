import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PassengerEditor from './PassengerEditor';
import PassengerQuery from './PassengerQuery';
import PropTypes from 'prop-types';
import LoadingContent from '../LoadingContent';
import { Alert } from 'antd';

/*this is a WRAPPER around the PassengerEditor.js (only this includes a QUERY around it)*/
class PassengerEditorWithData extends Component {
    render() {
        const { paxId, edit, onCancel, loadingStyle, ...restProps } = this.props;
        return (
            <Query
                query={PassengerQuery}
                variables={{ _id: paxId }}
                fetchPolicy="cache-and-network"
            >
            {({ data, loading, error }) => {
                if (loading && (!data || !data.getPassenger)) return <LoadingContent style={loadingStyle} />
                return (
                    <>
                        {error ? (
                            <Alert style={{ marginBottom: 12 }} showIcon type="error" message="Failed to load passenger" />
                        ) : null}
                        {data && data.getPassenger ? <PassengerEditor {...restProps} edit={edit} data={data.getPassenger} onCancel={onCancel}  /> : null}
                    </>
                )
            }}
            </Query>
        );
    }
}

PassengerEditorWithData.propTypes = {
    paxId: PropTypes.string.isRequired
}
 
export default PassengerEditorWithData;