import {CargoFormData} from "./types";
import * as CargoUtils from "components/form/CargoForm";
import {OrgDataProviderContext} from "context/orgData";
import * as Util from './util';
import {Location} from "common/types/location";
import {getTransitType} from "../../../../common/carriable/util";
import {ScheduledCgoNode} from "../../../../components/scheduling/types";

export function cargoFormToCargoNode(
    formValues: CargoFormData,
    orgData: OrgDataProviderContext,
    departure: Location,
    destination: Location
): ScheduledCgoNode {
    const formattedValues = CargoUtils.formatCargoFormValues(formValues);

    const node: ScheduledCgoNode = {
        _id: undefined, // ID will be assigned in backend

        __temp_id: Util.genTempID(),
        ...formattedValues,
        customerID: orgData.getOrgIDByType('customer'),
        tpID: orgData.getOrgIDByType('transporter'),
        classType: 'flytsuite.cgonode',
        departureID: departure,
        destinationID: destination,
        transitType: getTransitType(departure, destination),
        scheduledOrder: null,
        notesForPilot: null
    }

    return node;
}