import { Alert, Button, Col, Icon, Layout, Row, Spin, Tooltip } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import NonIdealState from 'components/NonIdealState';
import _keyBy from 'lodash/keyBy';
import React, { useEffect, useRef, useState } from 'react';
import { Query } from 'react-apollo';
import { PersonSearch } from '../PersonSearch';
import { formatPersonToPassengerValues, PersonToPassenger } from '../PersonToPassenger';
import PersonQuery from './PersonQuery';
import './style.less';

const { Content, Header } = Layout;

const PaxCheckIn = (props) => {
    const [ selectedPerson, setSelectedPerson ] = useState(props.selectedPerson || null);
    const [ hasSubmitError, setHasSubmitError ] = useState(false);
    const [ hasSubmitSuccess, setHasSubmitSuccess ] = useState(false);
    const [ refetchPerson, setRefetchPerson ] = useState(false);
    const [ hasSearched, setHasSearched ] = useState(false);
    const [ addPax, setAddPax ] = useState(false);

    const paxEditor = useRef(null);
    const type = props.type;
    useEffect(() => {
        setSelectedPerson(props.selectedPerson);
    }, [props.selectedPerson])

    useEffect(() => {
        setHasSubmitError(props.submitError ? true : false);
    }, [props.submitError])

    useEffect(() => {
        setHasSubmitSuccess(props.submitSuccess ? true : false);
        if (props.submitSuccess){
            paxEditor.current.resetFields();
            setRefetchPerson(true);
        }
    }, [props.submitSuccess])

    const validatePaxEditor = (additionalFields) => (err, values) => {
        if (!err){
            const formattedValues = formatPersonToPassengerValues(values);
            props.onSubmit && props.onSubmit({
                ...formattedValues,
                personID: selectedPerson,
                ...additionalFields
            });
        }
    }

    function handleSubmitBtnClick(additionalFields) {
        setHasSubmitError(false);
        setHasSubmitSuccess(false);
        paxEditor.current.validateFieldsAndScroll(validatePaxEditor(additionalFields));
    }

    let submitBtn = (
        <Button
            onClick={() => handleSubmitBtnClick()}
            style={{ marginLeft: 12 }}
            loading={props.submitting}
            type="primary">Check In
        </Button>
    )

    if (typeof props.renderSubmitBtn === 'function'){
        submitBtn = props.renderSubmitBtn(handleSubmitBtnClick);
    }

    function renderContent(){
        if (addPax){
            return <Content className="paxcheckin-content">
                <Layout style={{ height: '100%' }}>
                    <Header
                        className="ant-layout-header-small ant-layout-header-bordered"
                    >
                        <Row type="flex" gutter={12}>
                            <Col>
                                <h3 style={{ margin: 0 }}>Passenger Editor</h3>
                            </Col>
                            <Col style={{ marginLeft: 'auto' }}>
                                <Button
                                    onClick={() => paxEditor.current.resetFields()}
                                >Reset</Button>
                                {submitBtn}
                            </Col>
                        </Row>
                    </Header>
                    <Content style={{ padding: 12, overflow: 'auto' }}>
                        {hasSubmitSuccess ? (
                            <Alert
                                closable
                                showIcon
                                type={props.submittedPassengerData ? 'success' : 'warning'}
                                style={{ marginBottom: 12 }}
                                message={
                                    props.submittedPassengerData ?
                                        `Passenger ${props.submittedPassengerData.lastName}, ${props.submittedPassengerData.firstName} successfully checked in`
                                        :
                                        'Got a successful response, but an empty result. Passenger may or may not have been created.'
                                    }
                                onClose={() => setHasSubmitSuccess(false)}
                            />
                        ) : null}
                        {hasSubmitError ? (
                            <Alert
                                closable
                                showIcon
                                type="error"
                                message="Failed to submit due to an error"
                                style={{ marginBottom: 12 }}
                                onClose={() => setHasSubmitError(false)}
                            />
                        ) : null}
                        <Query
                            query={PersonQuery}
                            variables={{ id: selectedPerson }}
                            skip={!selectedPerson}
                            fetchPolicy="cache-and-network"
                        >
                        {({ data, loading, error, refetch }) => {
                            if(refetchPerson){
                                setRefetchPerson(false);
                                refetch()
                            }
                            if (loading) return (
                                <div style={{ textAlign: 'center', marginTop: '10rem' }}>
                                    <Spin indicator={<Icon type="loading" style={{ fontSize: '3rem' }} />} />
                                </div>
                            )
                            if (error){
                                return <Alert type="error" showIcon message="Failed to load person" />
                            }
                            return (
                                <PersonToPassenger
                                    type={type}
                                    ref={paxEditor}
                                    person={data && data.person}
                                    credentials={data && data.personCredentials && _keyBy(data.personCredentials, 'type')}
                                />
                            )
                        }}
                        </Query>
                    </Content>
                </Layout>
            </Content>
        }
        if (hasSearched){
            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <NonIdealState
                    title="Can't find the person you are looking for?"
                    description={<Button type="primary" onClick={() => setAddPax(true)}>Add New Passenger</Button>}
                />
            </div>
        }
        return null;
    }

    return (
        <Layout className="ant-layout-has-sider">
            <Sider
                className="ant-layout-sider-bordered-right paxcheckin-sider"
                width={500}
            >
                <Layout style={{ height: '100%' }}>
                    <Header
                        className="ant-layout-header-small ant-layout-header-bordered"
                    >
                        <Row type="flex" gutter={12}>
                            {props.onBackClicked ? (
                                <Col>
                                    <Tooltip placement="bottomRight" title="Go back" mouseEnterDelay={1} mouseLeaveDelay={1}>
                                        <Button icon="arrow-left" onClick={() => props.onBackClicked()} />
                                    </Tooltip>
                                </Col>
                            ) : null}
                            <Col>
                                <h3 style={{ margin: 0 }}>Find a Person</h3>
                            </Col>
                            <Col style={{ marginLeft: 'auto' }}>
                                {selectedPerson ? (
                                    <Button
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => props.onClearSelection && props.onClearSelection(selectedPerson)}
                                    >Clear Selection</Button>
                                ) : null}
                            </Col>
                        </Row>
                    </Header>
                    <Content>
                        <PersonSearch
                            style={{ height: '100%' }}
                            selectedPerson={selectedPerson}
                            onPersonSelect={(personId) => {
                                setAddPax(true);
                                if (props.onPersonSelect){
                                    props.onPersonSelect(personId);
                                }else{
                                    setSelectedPerson(personId);
                                }
                            }}
                            tableMinHeight={750}
                            onSearch={() => setHasSearched(true)}
                        />
                    </Content>
                </Layout>
            </Sider>
            {renderContent()}
        </Layout>
    )
}
 
export default PaxCheckIn;