import { FlightLeg } from 'schema';
import forEachRight from 'lodash/forEachRight';
import uniqBy from 'lodash/uniqBy';
import { safeGet } from 'common/util';
import {Location} from "../types/location";

export interface Entity {
    departure: Location,
    destination: Location,
    transitType: string,
    classType: string,
    [x: string]: any
}

export function entitiesToFlightLegs(entities: Entity[]){
    if (!entities || !entities.length) return [];
    let flightPath: Location[] = [];
    entities.forEach(entity => {
        const isInbound = entity.transitType === 'INBOUND';
        let departure = isInbound ? entity.destination : entity.departure;
        let destination = isInbound ? entity.departure : entity.destination;
        if (flightPath.length === 0){
            flightPath.push(...[
                departure,
                destination
            ])
            return;
        }
        const departureIndex = flightPath.findIndex(p => p._id === departure._id);
        const destinationIndex = flightPath.findIndex(p => p._id === destination._id);
        if (departureIndex === -1){
            flightPath.push(departure)
        }
        if (destinationIndex === -1){
            flightPath.push(destination)
        }
    });
    let origin = flightPath[0];
    // let endpoint = flightPath[flightPath.length - 1];
    // function returnsToOrigin(){
    //     return endpoint._id === origin._id
    // }
    // if (!returnsToOrigin()){
    //     flightPath.forEach((p, idx) => {
    //         if (idx > 0 && p._id === origin._id){
    //             if (idx !== flightPath.length - 1){
    //                 flightPath.splice(idx, 1);
    //                 flightPath.push(p);
    //             }
    //         }
    //     })
    // }
    // if (!returnsToOrigin()){
    //     flightPath.push(origin);
    // }
    let legs: FlightLeg[] = [];
    flightPath.forEach((loc, pathIdx) => {
        let nextLoc = flightPath[pathIdx + 1] || origin;
        // if (!nextLoc){
        //     return;
        // }
        let entitiesOnLeg = entities.filter((entity) => {
            if (entity.transitType === 'INBOUND') return false;
            let depIdx = flightPath.findIndex(p => p._id === entity.departure._id);
            let destIdx = flightPath.findIndex(p => p._id === entity.destination._id);
            let isOnLeg = pathIdx >= depIdx && pathIdx < destIdx;
            return isOnLeg;
        });
        let pax = entitiesOnLeg.filter((entity) => entity.classType === 'flytsuite.paxnode');
        let cgo = entitiesOnLeg.filter((entity) => entity.classType === 'flytsuite.cgonode');
        let leg: FlightLeg = {
            order: pathIdx,
            departure: loc.name,
            departureID: loc._id,
            destination: nextLoc.name,
            destinationID: nextLoc._id,
            paxIDs: pax,
            cgoIDs: cgo,
            paxCount: pax.length,
            cgoCount: cgo.length,
            bagCount: pax.reduce((acc, { bagCount=0 }) => acc + bagCount, 0),
            paxWeight: pax.reduce((acc, { paxWeight=0 }) => acc + paxWeight, 0),
            bagWeight: pax.reduce((acc, { bagWeight=0 }) => acc + bagWeight, 0),
            cgoWeight: cgo.reduce((acc, { weight=0 }) => acc + weight, 0)
        }
        legs[pathIdx] = leg
    })
    forEachRight(flightPath, (_, pathIdx) => {
        let entitiesOnLeg = entities.filter((entity) => {
            if (entity.transitType !== 'INBOUND') return false;
            const depIdx = flightPath.findIndex(p => p._id === entity.departure._id)
            var destIdx = flightPath.length;
            if (entity.destination._id !== origin._id){
                destIdx = flightPath.findIndex(p => p._id === entity.destination._id)
            }
            const isOnLeg = pathIdx >= depIdx && pathIdx < destIdx;
            return isOnLeg;
        })
        let existingLeg = legs[pathIdx];
        let pax = [...existingLeg.paxIDs, ...entitiesOnLeg.filter((entity) => entity.classType === 'flytsuite.paxnode')];
        let cgo = [...existingLeg.cgoIDs, ...entitiesOnLeg.filter((entity) => entity.classType === 'flytsuite.cgonode')];
        let leg: FlightLeg = {
            ...existingLeg,
            paxIDs: pax,
            cgoIDs: cgo,
            paxCount: pax.length,
            cgoCount: cgo.length,
            bagCount: pax.reduce((acc, { bagCount=0 }) => acc + bagCount, 0),
            paxWeight: pax.reduce((acc, { paxWeight=0 }) => acc + paxWeight, 0),
            bagWeight: pax.reduce((acc, { bagWeight=0 }) => acc + bagWeight, 0),
            cgoWeight: cgo.reduce((acc, { weight=0 }) => acc + weight, 0)
        }
        legs[pathIdx] = leg;
    })
    return legs
}

export const getDestinationsFromEntities = (entities: Entity[]) => {
    let origin = entities[0].departure;
    var locations = []
    entities.forEach(entity => {
        const destinationIndex = locations.findIndex(p => p._id === entity.destination._id);
        if (destinationIndex === -1 && entity.destination._id !== origin._id){
            locations.push(entity.destination);
        }

    })
    locations.push(origin);
    return locations;
}

export function reorderFlightLegs(initialLegs: FlightLeg[], dragIndex: number, hoverIndex: number){
    let entities: Entity[] = [];
    initialLegs.forEach((leg) => {
        if (Array.isArray(leg && leg.paxIDs) && leg.paxIDs.length){
            entities.push(...leg.paxIDs);
        }
        if (Array.isArray(leg && leg.cgoIDs) && leg.cgoIDs.length){
            entities.push(...leg.cgoIDs);
        }
    })
    entities = uniqBy(entities, (e: Entity) => e._id);
    let reorderError = new Error();
    reorderError.name = 'ReorderError';
    if (dragIndex === initialLegs.length -1){
        reorderError.message = 'The last flight leg cannot be reordered';
        throw reorderError
    }
    if (hoverIndex === initialLegs.length - 1){
        reorderError.message = 'The last flight leg must be a return to the flight departure location';
        throw reorderError;
    }
    let destinations = getDestinationsFromEntities(entities);
    const startSlice = dragIndex < hoverIndex ? dragIndex : hoverIndex;
    const endSlice = dragIndex > hoverIndex ? dragIndex : hoverIndex;
    let reorderedDestinations: Location[] = [
        ...destinations.slice(0, startSlice),
        destinations[endSlice],
        ...destinations.slice(startSlice + 1, endSlice),
        destinations[startSlice], ...destinations.slice(endSlice + 1)
    ]
    reorderedDestinations = reorderedDestinations.filter(loc => loc !== undefined && loc !== null);
    let newEntityList = new Array(entities.length);
    console.log('reorderedDestinations', reorderedDestinations);
    reorderedDestinations.forEach(location => {
        function filterTransitType(tt: string){
            return function (entity: Entity){
                return entity.transitType === tt;
            }
        }
        let entitiesInLeg = entities.filter(entity => safeGet(['destination', '_id'], entity) === location._id);
        let outbound = entitiesInLeg.filter(filterTransitType('OUTBOUND'));
        let inbound = entitiesInLeg.filter(filterTransitType('INBOUND'));
        let transfer = entitiesInLeg.filter(filterTransitType('TRANSFER'));
        let sortedEntities: Entity[] = [...outbound, ...transfer, ...inbound];
        newEntityList.push(...sortedEntities);
    })
    return entitiesToFlightLegs(newEntityList);
}

export function getEntitiesFromFlightLegs<T extends { _id: string }>(legs: FlightLeg[]): T[]{
    let entities: T[] = [];
    legs.forEach((leg) => {
        if (Array.isArray(leg && leg.paxIDs) && leg.paxIDs.length){
            entities.push(...leg.paxIDs);
        }
        if (Array.isArray(leg && leg.cgoIDs) && leg.cgoIDs.length){
            entities.push(...leg.cgoIDs);
        }
    })
    entities = uniqBy(entities, (e: T) => {
        if (typeof e === 'object'){
            return e && e._id
        }
        return e
    });
    return entities
}

export function getFlightDeparture(flight: any): { _id: string, name: string } {
    let legs: Array<FlightLeg>;
    if (Array.isArray(flight.legsArray)){
        legs = flight.legsArray;
    }
    else if (typeof flight.legs === 'string')
    {
        legs = Object.values(JSON.parse(flight.legs))
    }
    else if (typeof flight.legs === 'object' && flight.legs !== null){
        legs = Object.values(flight.legs);
    }
    if (legs){
        let firstLeg = legs[0];
        if (!firstLeg){
            return;
        }
        return {
            _id: legs[0].departureID,
            name: legs[0].departure
        }
    }
}

export function getFlightDestinations(flight: any): Array<{ _id: string, name: string }> {
    let legs: Array<FlightLeg> = [];
    if (Array.isArray(flight.legsArray)){
        legs = flight.legsArray;
    }
    else if (typeof flight.legs === 'string')
    {
        legs = Object.values(JSON.parse(flight.legs))
    }
    else if (typeof flight.legs === 'object' && flight.legs !== null){
        legs = Object.values(flight.legs);
    }
    return legs.map(leg => {
        return {
            _id: leg.destinationID,
            name: leg.destination
        }
    })
}