import Select, { SelectProps } from 'antd/lib/select';
import { omit, padStringInt } from 'common/util';
import React from 'react';
import { useState, useEffect } from 'react';

export interface DateLabelSelectProps extends SelectProps {
    minDateNum?: number,
    maxDateNum?: number
}

const staticOptions = [
    <Select.Option key="shot1" value="Shot 1">Shot 1</Select.Option>,
    <Select.Option key="shot2" value="Shot 2">Shot 2</Select.Option>,
    <Select.Option key="vaxon" value="Vaccinated on">Vaccinated on</Select.Option>,
    <Select.Option key="postest" value="POS Test">POS Test</Select.Option>,
    <Select.Option key="rtw" value="RTW">RTW</Select.Option>,
    <Select.Option key="rtw2" value="RTW 2">RTW 2</Select.Option>
]

const DateLabelSelect: React.FC<DateLabelSelectProps> = (props) => {

    const [ options, setOptions ] = useState([]);

    useEffect(() => {
        let opt = [ ...staticOptions ];
        let maxDateLen = String(props.maxDateNum).length;
        for (let i = props.minDateNum; i <= props.maxDateNum; i++){
            opt.push(<Select.Option key={i} value={"Booster " + padStringInt(i, maxDateLen)}>Booster {i}</Select.Option>)
        }
        setOptions(opt)
    }, [ props.minDateNum, props.maxDateNum, setOptions ])
    
    return <Select {...omit(props, 'minDateNum', 'maxDateNum')} showAction={["focus", "click"]}>
        {options}
    </Select>
}

DateLabelSelect.defaultProps = {
    ...DateLabelSelect.defaultProps,
    minDateNum: 1,
    maxDateNum: 99
}

export default DateLabelSelect