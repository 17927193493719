import { Cell, Column } from '@blueprintjs/table';
import { Checkbox, Icon, Spin, Tooltip } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { safeGet } from 'common/util';
import { IsSelected, ToggleSelection } from 'hooks/useMultiSelectByID';
import React from 'react';

export function hasCarrier(entity: any, carrier: string, include: boolean=false){
    let carrierId = safeGet(['currentCarrierID', '_id'], entity);
    if (!carrier){
        return true;
    }
    if(!include){
        if (carrierId === carrier){
            return false
        }
    }else {
        if(carrierId && carrierId !== carrier){
            return false
        }
    }
    return true;
}

export function renderCheckboxColumn(rows: any[], isSelected?: IsSelected, toggleSelection?: ToggleSelection, checkboxDisabledText?: string, carrierID?: string){
    return <Column key="select" name="" cellRenderer={idx => {
        const record = rows[idx];
        if(record){
            let checked = isSelected ? isSelected(record._id) : false;
            const checkProps: CheckboxProps = {
                checked,
                onClick: () => {
                    if (toggleSelection){
                        toggleSelection(record._id);
                    }
                }
            }
            let checkbox = <Checkbox {...checkProps} />;
            if (record && record.currentCarrierID && record.currentCarrierID['_id'] !== carrierID){
                checkbox = <Checkbox disabled />
                if (checkboxDisabledText){
                    checkbox = <Tooltip mouseEnterDelay={1} mouseLeaveDelay={1} title={checkboxDisabledText}>{checkbox}</Tooltip>;
                }
            }
            return <Cell>{checkbox}</Cell>
        }
        return <Cell/>
    }} />
}

export function renderAssignedFlightColumn(rows: any[]){
    return <Column key="assignedToFlight" name="Assigned To Flight" cellRenderer={(idx) => {
        const record = rows[idx];
        let text = safeGet(['currentCarrierID', 'desig'], record);
        let cellStyle: React.CSSProperties = {};
        if (!text){
            text = 'Not Assigned'
        }
        else
        {
            cellStyle = {
                background: '#ff000054'
            }
        }
        return <Cell style={cellStyle}>{text}</Cell>
    }} />
}

export function LoadingIndicator(){
    return <div style={{ textAlign: 'center', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin size="large" indicator={<Icon type="loading" />} />
    </div>
}

export function shouldRenderLoadingIndicator(networkStatus: number){
    return networkStatus === 1 || networkStatus === 4;
}