import React from 'react';
import { Row, Col, Button, Table, Form, Input, Popconfirm } from 'antd';
import commonColumns from 'common/table/columns';
import { paxOnFlightLeg } from 'common/util';
import { FilterableTable } from 'components/tables/FilterableTable';

class FlightManualLegsAssign extends React.Component {
    onAddToLegs = () => {
        const getFlight = this.props.flight;
        let resultLegs
        if(this.props.type === 'PAX'){
            const passengers = getFlight.paxIDList.filter(pax => this.props.selectedPaxCgo.includes(pax._id))
            
            resultLegs = getFlight.legsArray;
            this.props.selectedLegs.forEach(idx => {
                var leg = resultLegs[idx]
                //This sets up the leg, but it was made to save it directly after
                resultLegs[idx] = paxOnFlightLeg(leg, passengers.concat(leg.paxIDs), leg.cgoIDs)
                //This un maps the pax and cgo from _id only back to their object
                resultLegs[idx].paxIDs = passengers.concat(leg.paxIDs)
                resultLegs[idx].cgoIDs = leg.cgoIDs
                
            })
        }else if (this.props.type === 'CGO'){
            const cgo = getFlight.cgoIDList.filter(cgo => this.props.selectedPaxCgo.includes(cgo._id))
            
            resultLegs = getFlight.legsArray;
            this.props.selectedLegs.forEach(idx => {
                var leg = resultLegs[idx]
                //This sets up the leg, but it was made to save it directly after
                resultLegs[idx] = paxOnFlightLeg(leg, leg.paxIDs, cgo.concat(leg.cgoIDs))
                //This un maps the pax and cgo from _id only back to their object
                resultLegs[idx].cgoIDs = cgo.concat(leg.cgoIDs)
                resultLegs[idx].paxIDs = leg.paxIDs
                
            })
        }
        
        getFlight.legsArray = resultLegs;

        this.props.editFlight(getFlight)
        this.props.clearSelection('pax')
    }

    onRemoveFromLegs = () => {
        const getFlight = this.props.flight;
        let resultLegs;
        if(this.props.type === 'PAX'){
            const passengers = getFlight.paxIDList.filter(pax => this.props.selectedPaxCgo.includes(pax._id))
            
            resultLegs = getFlight.legsArray;
            getFlight.legsArray.forEach((leg, idx) => {
                //This sets up the leg, but it was made to save it directly after
                resultLegs[idx] = paxOnFlightLeg(leg, leg.paxIDs.filter(pax => !passengers.map(p => p._id).includes(pax._id)), leg.cgoIDs)
                //This un maps the pax and cgo from _id only back to their object
                resultLegs[idx].paxIDs = leg.paxIDs.filter(pax => !passengers.map(p => p._id).includes(pax._id))
                resultLegs[idx].cgoIDs = leg.cgoIDs
                
            })
        }else if (this.props.type === 'CGO'){
            const cgo = getFlight.cgoIDList.filter(cgo => this.props.selectedPaxCgo.includes(cgo._id))
            
            resultLegs = getFlight.legsArray;
            getFlight.legsArray.forEach((leg, idx) => {
                //This sets up the leg, but it was made to save it directly after
                resultLegs[idx] = paxOnFlightLeg(leg, leg.paxIDs, leg.cgoIDs.filter(cgos => !cgo.map(c => c._id).includes(cgos._id)))
                //This un maps the pax and cgo from _id only back to their object
                resultLegs[idx].cgoIDs = leg.cgoIDs.filter(cgos => !cgo.map(c => c._id).includes(cgos._id))
                resultLegs[idx].paxIDs = leg.paxIDs
                
            })
        }
        
        getFlight.legsArray = resultLegs;

        this.props.editFlight(getFlight)
        this.props.clearSelection('pax')
    }
    render(){
        const { title = 'Flight Legs', flight, selectedLegs = [0], selectedPaxCgo, paxRowSelection, legRowSelection, type} = this.props;

        const legColumns = [
            {
                title: '#',
                key: '#',
                render: (_, __, index) => index + 1
            },
            {
                title:  'Dep',
                key: 'dep',
                dataIndex: 'departure'
            },
            {
                title: 'Dest',
                key: 'dest',
                dataIndex: 'destination'
            },
            {
                title: 'Pax',
                key: 'pax',
                dataIndex: 'paxCount'
            },
            {
                title: 'Cgo',
                key: 'cgo',
                dataIndex: 'cgoCount'
            }
        ]

        var columns;
        if(type === 'PAX'){
            columns = commonColumns.historyPax.some(['name', 'employer', 'departure', 'destination']);
        }else if (type === 'CGO'){
            columns = commonColumns.historyCgo.some(['name', 'weight', 'departure', 'destination'])
        }
        columns.unshift({
            title: 'Legs',
            key: 'legs',
            width: 50,
            render: (val, row) => {
                var legs;
                this.props.flight.legsArray.forEach((leg, idx) => {
                    if(type === 'PAX'){
                        if(leg.paxIDs.map(pax => pax._id).includes(row._id)){
                            if(legs){
                                legs = legs + ", " + (idx+1)
                            }else {
                                legs = (idx+1)
                            }
                        }
                    }else if(type === 'CGO'){
                        if(leg.cgoIDs.map(cgo => cgo._id).includes(row._id)){
                            if(legs){
                                legs = legs + ", " + (idx+1)
                            }else {
                                legs = (idx+1)
                            }
                        }
                    }
                })
                return legs
            }
        })
        columns.push({
            title: 'Charge Code',
            key: 'chargeCode',
            dataIndex: 'chargeCode',
            editable: true,
            render: (code, row) => {
                return (
                    <Form.Item>{this.props.form.getFieldDecorator('chargeCode_' + row._id, {
                        initialValue: code && code.toUpperCase()
                    })(
                        <Input
                        placeholder={this.props.form.getFieldValue('defaultChargeCode') && this.props.form.getFieldValue('defaultChargeCode').toUpperCase()}
                        size="small"
                    />
                    )}</Form.Item>
                )
            }
        })
    
        return (
            <React.Fragment>
                <Row type="flex" style={{marginBottom: '1rem'}}>
                    {selectedLegs.length > 0 && selectedPaxCgo.length > 0 && (
                        <Col>
                            <Button type="primary" onClick={this.onAddToLegs}>Add to Legs</Button>
                        </Col>
                        )
                    }
                    {selectedPaxCgo.length > 0 && (
                        <Col>
                            <Popconfirm title="Are you sure you want to remove this item?" okText="Remove" onConfirm={this.onRemoveFromLegs}>
                                <Button type="danger">Remove from Legs</Button>
                            </Popconfirm>
                        </Col>
                    )}
                </Row>
                <Row gutter={24}>
                    <Col span={16} style={{minHeight: '30rem'}}>
                        <Col style={{flex: 1}}>
                            <h3 style={{margin: 0}}>{type}</h3>
                        </Col>
                        <FilterableTable
                            rowSelection={paxRowSelection} 
                            dataSource={type === 'PAX' ? flight.paxIDList : type === 'CGO' ? flight.cgoIDList : []} 
                            columns={columns} 
                            rowKey={(record) => record._id} 
                            size="small" 
                            pagination={false}
                            applyChargeCode={this.props.applyChargeCode}
                        />
                    </Col>
                    <Col span={8} style={{minHeight: '30rem', borderRight: '1px solid #e8e8e8'}}>
                        <Col style={{flex: 1}}>
                            <h3 style={{margin: 0}}>{title}</h3>
                        </Col>
                        <Table
                            rowSelection={legRowSelection}
                            dataSource={flight.legsArray} 
                            columns={legColumns} 
                            rowKey={(_, index) => index} 
                            size="small" 
                            pagination={false}
                            />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default FlightManualLegsAssign