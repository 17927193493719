import uuidv4 from 'uuid/v4';

function personToPassenger(
    person: any,
    paxData: {
        scheduledOrder?: number,
        transitType?: 'OUTBOUND' | 'INBOUND' | 'TRANSFER',
        customerID?: any,
        tpID?: any,
        departureID?: any,
        destinationID?: any,
        paxWeight?: number,
        bagWeight?: number,
        bagCount?: number,
        chargeCode?: string
    }
    ){
        const {
            scheduledOrder=null,
            transitType,
            customerID,
            tpID,
            departureID,
            destinationID,
            paxWeight,
            bagCount,
            bagWeight,
            chargeCode
        } = paxData;
    const pax = {
        ...person,

        // ID is temporary and should not saved in database
        _id: `PAX-${uuidv4()}`,

        personID: person,
        classType: 'flytsuite.paxnode',
        scheduledOrder,
        transitType,
        customerID,
        tpID,
        departureID,
        destinationID,
        paxWeight: paxWeight || person.lastPaxWeight || 0,
        bagWeight: bagWeight || person.lastBagWeight || 0,
        bagCount: bagCount || person.lastBagCount || 0,
        chargeCode
    }
    if ('_rev' in person){
        delete pax['_rev'];
    }
    return pax
}

export default personToPassenger