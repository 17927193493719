import gql from "graphql-tag";

export default gql`
mutation SchedulePaxInbCgo(
    $customerID: ID!
    $orgID: ID!
    $departureID: ID!
    $destinationID: ID!
    $lastKnownController: ID!
    $scheduledFlightDate: AWSDate!
    $scheduledGroup: String!
    $passengers: [ScheduledPassengerInput!]!
    $inboundCargo: [ScheduledInboundCargoInput!]!
    $deletedEntities: [DeletedScheduledEntityInput!]
){
    schedulePaxInbCgo(
        customerID: $customerID
        orgID: $orgID
        departureID: $departureID
        destinationID: $destinationID
        lastKnownController: $lastKnownController
        scheduledFlightDate: $scheduledFlightDate
        scheduledGroup: $scheduledGroup
        passengers: $passengers
        inboundCargo: $inboundCargo
        deletedEntities: $deletedEntities
    ){
        cgonodes {
            _id
            _rev
            name
            classType
            transitType
            scheduledOrder
            weight
            hazmat
            hazmatUnCode
            lastKnownController {
                _id
                name
            }
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            attentionTo
            initials
            notes
            customerID {
                _id
                name
            }
            tpID {
                _id
                name
            }
        }
        paxnodes {
            _id
            _rev
            lastName
            firstName
            transitType
            scheduledOrder
            classType
            paxWeight
            bagWeight
            bagCount
            chargeCode
            lastKnownController {
                _id
                name
            }
            personID {
                _id
                dob
                extraBroadState
            }
            customerID {
                _id
                name
            }
            tpID {
                _id
                name
            }
            employerID {
                _id
                name
            }
            departureID {
                _id
                name
            }
            destinationID {
                _id
                name
            }
            authorization {
                onWhiteList
                brdRestriction_Isn {
                    status
                    type
                    notes
                    reason
                }
            }
            isnCheckError {
                message
            }
            lastIsnResultWithHeader {
                isn_customer
                isn_ownerid
                success
                error_message
                quick_check_status
                reason
                qc_count
                qc_result {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
                selectedResult {
                    isnStatus {
                        shortLabel
                    }
                    isnId
                    lastName
                    firstName
                    companyID
                    companyName
                    trainingStatus
                    mostRecentTrainingQualificationExpirationName
                    mostRecentTrainingExpirationDate
                    assignedActivities
                    quickCheckStatus
                    reasonsForNonCompliance
                    trainingDeficiencies
                    reason
                }
            }
        }
    }
}
`