import gql from 'graphql-tag';
import {LocationFragment} from "Queries/Location";
import {
    ScheduledCgoNodeFragment,
    ScheduledPaxCgoGroupV2Fragment,
    ScheduledPaxNodeFragment
} from "Queries/Scheduling";


export const Queries = {
    GetScheduledPaxCgoGroups: gql`
        query GetScheduledPaxCgoGroups(
            $tpID: ID!
            $customerID: ID!
            $startDate: AWSDate!
            $endDate: AWSDate!
        ){
            getScheduledPaxInbCgoGroups(
                tpID: $tpID
                customerID: $customerID
                startDate: $startDate
                endDate: $endDate
            ){
                scheduledFlightDate
                scheduledGroup
                departureID {
                    ...LocationFragment
                }
                destinationID {
                    ...LocationFragment
                }
                lastKnownController {
                    ...LocationFragment
                }
                paxOutbound
                paxTransfer
                paxInbound
                cgoOutbound
                cgoTransfer
                cgoInbound
                paxOutboundOnFlight
                paxTransferOnFlight
                paxInboundOnFlight
                cgoOutboundOnFlight
                cgoTransferOnFlight
                cgoInboundOnFlight
            }
            GetScheduledPaxCgoGroupsV2(
                tpID: $tpID
                customerID: $customerID
                startDate: $startDate
                endDate: $endDate
            ){
                ...ScheduledPaxCgoGroupV2Fragment
            }
        }
        ${LocationFragment}
        ${ScheduledPaxCgoGroupV2Fragment}
    `,

    GetScheduledPaxCgoV2: gql`
        query GetScheduledPaxCgoV2(
            $tpID: ID!
            $customerID: ID!
            $scheduledFlightDate: AWSDate!
            $scheduledGroup: String!
            $lastKnownController: ID!
        ){
            getTransporter(
                _id: $tpID
            ){
                _id
                name
            }
            getCustomer(
                _id: $customerID
            ){
                _id
                name
            }
            getLastKnownController: getLocation(
                _id: $lastKnownController
            ){
                ...LocationFragment
            }
            GetScheduledPaxCgoV2(
                tpID: $tpID
                customerID: $customerID
                scheduledFlightDate: $scheduledFlightDate
                scheduledGroup: $scheduledGroup,
                lastKnownController: $lastKnownController
            ){
                cgonodes {
                    ...ScheduledCgoNodeFragment
                }
                paxnodes {
                    ...ScheduledPaxNodeFragment
                }
            }
        }
        ${LocationFragment}
        ${ScheduledPaxNodeFragment}
        ${ScheduledCgoNodeFragment}
    `
}

export const Mutations = {
    SaveSchedulePaxCgoV2: gql`
        mutation SchedulePaxCgo_V2(
            $customerID: ID!
            $tpID: ID!
            $lastKnownController: ID!
            $scheduledFlightDate: AWSDate!
            $scheduledGroup: String!
            $modifiedPax: [ScheduledPassengerInput_V2!]!
            $modifiedCgo: [ScheduledCargoInput_V2!]!
            $deletedEntities: [DeletedScheduledEntityInput!]
        ){
            SchedulePaxCgo_V2(
                customerID: $customerID
                tpID: $tpID
                lastKnownController: $lastKnownController
                scheduledFlightDate: $scheduledFlightDate
                scheduledGroup: $scheduledGroup
                modifiedPax: $modifiedPax
                modifiedCgo: $modifiedCgo
                deletedEntities: $deletedEntities
            ){
                cgonodes {
                    ...ScheduledCgoNodeFragment
                }
                paxnodes {
                    ...ScheduledPaxNodeFragment
                }
            }
        }
        ${ScheduledPaxNodeFragment}
        ${ScheduledCgoNodeFragment}
    `
}