import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Spin, Icon, Alert } from 'antd';
import moment from 'moment';
import DetailHeader from './DetailHeader';
import { Criteria } from './Criteria';
import './ReportDetails.less';

const { Sider, Content } = Layout;



const ErrorMessage = ({ error }) => {
    let message = error ? error.message : 'An unknown error has occurred';
    if (!message){
        message = error.detailMessage;
    }
    return (
        <div style={{ textAlign: 'center', marginTop: '5rem' }} >
            <Icon style={{ color: '#f5222d', fontSize: '3rem', marginBottom: 12 }} type="exclamation-circle" />
            <h2 style={{ color: '#f5222d' }}>An error occurred while running the report</h2>
            <Alert type="error" message={message} />
        </div>
    )
}

// const ReportHeaderStat = ({ title, children }) => (
//     <div style={{ lineHeight: '18px' }} >
//         <h5 style={{ color: 'rgba(0,0,0,0.5)', fontWeight: 'bold', margin: 0, marginRight: 6 }} >{('' + title).toUpperCase()}</h5>
//         <span>{children}</span>
//     </div>
// )

// const HeaderSeparator = (style) => (
//     <div
//         style={{
//             borderRight: '1px solid rgb(237, 237, 237)',
//             height: '64px',
//             margin: '0 1rem',
//             ...style
//         }}
//     />
// )



class ReportDetails extends Component {
    state = {
        activeTab: 'details',
        currentTime: moment()
    }
    static propTypes = {
        data: PropTypes.object.isRequired,
        onBackClicked: PropTypes.func,
        renderSummary: PropTypes.func,
        renderDetail: PropTypes.func
    }

    componentDidMount(){
        setInterval(() => this.setState({ currentTime: moment() }), 1000);
    }

    getActiveTab = () => {
        if (this.props.activeTab) {
            return this.props.activeTab;
        }
        return this.state.activeTab
    }

    setActiveTab = (activeTab) => {
        this.setState({ activeTab });
        if (this.props.onTabChange){
            this.props.onTabChange(activeTab);
        }
    }

    render() {
        const { data, style, loading, error, onBackClicked, renderSummary, enableExport=true, renderDetail, renderExport, runTimeErrorMessage, runTimeErrorDescription, showErrorAtRunTime=120 } = this.props;
        const isInProgress = data.status === 'IN_PROGRESS' || data.status === 'INITIALIZING';
        const summary = data.result && JSON.parse(data.result.summary);
        const startMoment = moment(data.startTs);
        const runTime = this.state.currentTime.diff(startMoment, 'milliseconds') / 1000;
        return (
            <Layout style={style}>
                <Layout>
                    <DetailHeader
                        title={data && data.name}
                        loading={loading}
                        onBackClicked={onBackClicked}
                        onTabChange={this.setActiveTab}
                        activeTabKey={this.getActiveTab()}
                        enableExport={enableExport}
                        extra={<span><span style={{ fontSize: '1.3rem' }}>{data?.result?.total_docs || 0}</span> records</span>}
                        // extra={
                        //     <Row type="flex" gutter={24} align="middle">
                        //         <HeaderSeparator />
                        //         <Col>
                        //             <ReportHeaderStat title="status">
                        //                 <ReportStatus status={data.status} style={{ fontSize: 'inherit' }} />
                        //             </ReportHeaderStat>
                        //         </Col>
                        //         <Col>
                        //             <ReportHeaderStat title="run time">
                        //             {!isInProgress ? `${runTime} seconds` : <Spin style={{ marginRight: 6 }} indicator={<Icon type="loading" spin />} />}
                        //             </ReportHeaderStat>
                        //         </Col>
                        //         <Col>
                        //             <ReportHeaderStat title="created">
                        //                 {created}
                        //             </ReportHeaderStat>
                        //         </Col>
                        //         <HeaderSeparator />
                        //     </Row>
                        // }
                    />
                    <Content
                        className="reporting-details-content"
                    >
                        {error ? <Alert type="error" showIcon message="Failed to load report" /> : null}
                        {loading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Spin indicator={<Icon type="loading" />} size="large" style={{ marginBottom: '1rem' }} />
                                    <h3 style={{ textAlign: 'center' }}>Downloading Report</h3>
                                </div>
                            </div>
                        ) : null}
                        {!loading ? 
                            isInProgress ? (
                                <div style={{ textAlign: 'center', marginTop: '5rem' }}>
                                    <div style={{ margin: '1rem 0' }} >
                                        <Spin
                                            size="large"
                                            indicator={<Icon type="loading" spin />}
                                        />
                                    </div>
                                    <div style={{ marginTop: 24, fontSize: '1.2rem' }}>
                                        Report is being generated. Please wait.
                                    </div>
                                    {runTime >= showErrorAtRunTime ? (
                                        <Alert
                                            style={{ marginTop: 12 }}
                                            type="error"
                                            showIcon
                                            message={runTimeErrorMessage || 'It is taking longer than usual to generate this report.'}
                                            description={runTimeErrorDescription || `
                                                Something might have gone wrong.
                                                If your report seems to be stuck in this state,
                                                try running a new report with more specific criteria or please contact DataFlyt.
                                            `}
                                        />
                                    ) : null}
                                </div>
                            ) : (
                                data.status === 'ERROR' ? (
                                    <ErrorMessage error={data.error} />
                                ) : (
                                <div style={{ height: '100%' }} >
                                    {this.getActiveTab() === 'details' && renderDetail ? renderDetail(data) : null}
                                    {this.getActiveTab() === 'summary' && renderSummary ? renderSummary(summary) : null}
                                    {this.getActiveTab() === 'export' && renderExport && enableExport ? renderExport(data._id) : null}
                                </div>
                                )
                            ) : null}
                    </Content>
                </Layout>
                {data.criteria ? (
                        <Sider
                        className="reporting-details-criteria-sider"
                        theme="light"
                        width="20rem"
                        >
                        <div style={{ margin: '-1rem' }} >
                            <Criteria data={data} isInProgress={isInProgress} />
                        </div>
                        </Sider>
                    ) : null}
            </Layout>
        );
    }
}
 
export default ReportDetails;