import React from 'react';
import { Card, Row, Col } from 'antd';
import { Statistic } from 'antd';
import { RadialChart, DiscreteColorLegend } from 'react-vis';

const ISNSummary = ({ data }) => {
    const getValue = (key) => (data[key] && data[key].value) || 0;
    const getPercentage = (val) => (val / getValue('pax_count') * 100).toFixed(1);
    const pax_no_isn = getValue('pax_count') - getValue('pax_with_isn_result');
    const colors = {
        green: '#8ef771',
        red: '#f77171',
        yellow: '#f7ee71',
        purple: '#9b71f7'
    }
    const statusChartData = [
        {
            angle: getValue('pax_green_quick_check_status'),
            color: colors.green
        },
        {
            angle: getValue('pax_red_quick_check_status'),
            color: colors.red
        },
        {
            angle: getValue('pax_no_quick_check_status'),
            color: colors.yellow
        }
    ]
    const legenditems = [
        {
            title: 'Green',
            color: colors.green
        },
        {
            title: 'Red',
            color: colors.red
        },
        {
            title: 'No Status',
            color: colors.yellow
        }
    ]
    if (getValue('pax_multi_quick_check_status') > 0){
        statusChartData.push({
            angle: getValue('pax_multi_quick_check_status'),
            color: colors.purple
        });
        legenditems.push({
            title: 'Multiple Results',
            color: colors.purple
        })
    }
    return (
        <React.Fragment>
            <Row type="flex" gutter={12} style={{ marginBottom: 12 }}>
                <Col span={8}>
                    <Card>
                        <Statistic title="Total PAX" value={getValue('pax_count')} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic title="Total PAX with ISN result" value={getValue('pax_with_isn_result')} suffix={`(${pax_no_isn} with no result)`} />
                    </Card>
                </Col>
            </Row>
            <h3>ISN Status</h3>
            <Row type="flex" gutter={12}>
                <Col span={10}>
                    <Card style={{ height: '100%' }}>
                        <div className="ant=statistic=title">ISN Status</div>
                        <Row type="flex">
                            <Col>
                                <RadialChart
                                    innerRadius={100}
                                    colorType="literal"
                                    animation={true}
                                    radius={140}
                                    data={statusChartData}
                                    width={300}
                                    height={300}
                                    padAngle={0.04}
                                />
                            </Col>
                            <Col>
                                <DiscreteColorLegend
                                    height={300}
                                    width={200}
                                    items={legenditems}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={6}>
                    <Row type="flex" style={{ flexDirection: 'column', alignContent: 'stretch' }} >
                        <Card style={{ marginBottom: 12 }}>
                            <Statistic 
                                title="Green Status" 
                                value={getValue('pax_green_quick_check_status')} 
                                suffix="passengers"
                                valueStyle={{ color: '#2fb20a' }}
                            />
                            <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }} >{getPercentage(getValue('pax_green_quick_check_status'))}%</div>
                        </Card>
                        <Card style={{ marginBottom: 12 }}>
                            <Statistic 
                                title="Red Status" 
                                value={getValue('pax_red_quick_check_status')} 
                                suffix="passengers"
                                valueStyle={{ color: "#b70b0b" }}
                            />
                            <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }} >{getPercentage(getValue('pax_red_quick_check_status'))}%</div>
                        </Card>
                        <Card>
                            <Statistic 
                                title="No Status" 
                                value={getValue('pax_no_quick_check_status')} 
                                suffix="passengers"
                                valueStyle={{ color: "#dfce16" }}
                            />
                            <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }} >{getPercentage(getValue('pax_no_quick_check_status'))}%</div>
                        </Card>
                        {getValue('pax_multi_quick_check_status') ? (
                            <Card style={{ marginTop: 12 }}>
                                <Statistic 
                                    title="Multiple Results" 
                                    value={getValue('pax_multi_quick_check_status')} 
                                    suffix="passengers"
                                    valueStyle={{ color: '#9b71f7' }}
                                />
                                <div style={{ fontSize: '1.1rem', fontWeight: 'bold' }} >{getPercentage(getValue('pax_multi_quick_check_status'))}%</div>
                            </Card>
                        ) : null}
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
}
 
export default ISNSummary;