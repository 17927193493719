import React from 'react';
import { HTMLAttributes } from 'react';
import { Icon, Spin } from 'antd';
import cn from 'classnames';
import './style.less';

export interface CenteredLoadingScreenProps extends HTMLAttributes<HTMLDivElement> {
    message?: React.ReactNode,
    icon?: React.ReactNode
}

const CenteredLoadingScreen: React.FC<CenteredLoadingScreenProps> = ({
    message,
    icon=<Spin size="large" indicator={<Icon type="loading" />} />,
    className,
    ...restProps
}) => {
    return <div {...restProps} className={cn("mc-centered-loading-screen", className)}>
        <div className="mc-centered-loading-screen-indicator-wrapper">
            {icon}
            {message ? <span>{message}</span> : null}
        </div>
    </div>
}

export default CenteredLoadingScreen;