import React, { createContext, useReducer } from 'react';
import { Modal, Tabs } from 'antd';
import AccountAttributes from './AccountAttributes';
import ChangePassword from 'components/preferences/ChangePassword';
import VerifyEmailAlert from 'components/preferences/VerifyEmailAlert';
import useWindowSize from 'hooks/useWindowSize';
import windowSizes from 'common/windowSizes';

export const NavContext = createContext({ state: null, dispatch: null })

export function navReducer(state, action) {
    switch (action.type) {
        case 'push':
            return [...state, action.payload];
        case 'replace':
            const route = [...state];
            route.pop();
            route.push(action.payload);
            return route;
        case 'goBack':
            return [...state].pop();
        default:
            return state;
    }
}

const UserPreferencesModal = (props) => {
    const [state, dispatch] = useReducer(navReducer, ['/account']);
    const windowSize = useWindowSize();

    const renderContent = () => (
        <Tabs
            tabPosition={windowSize.width >= windowSizes.large ? "left" : "top"}
            activeKey={state[state.length-1].split('/')[1]}
            onChange={(key) => dispatch({ type: 'replace', payload: '/' + key })}
        >
            <Tabs.TabPane key="account" tab="Account">
                <VerifyEmailAlert />
                <AccountAttributes />
            </Tabs.TabPane>
            <Tabs.TabPane key="changepassword" tab="Change Password">
                <VerifyEmailAlert />
                <ChangePassword />
            </Tabs.TabPane>
        </Tabs>
    )

    return (
        <NavContext.Provider value={{ state, dispatch }}>
            <Modal
                title="Account Settings"
                width="55rem"
                footer={null}
                destroyOnClose={true}
                {...props}>
                {renderContent()}
            </Modal>
        </NavContext.Provider>
    )
}

// const WithMemoryRouter = (props) => {
//     return <MemoryRouter
//         initialEntries={['/account']}
//         initialIndex={0}
//     >
//         <UserPreferencesModal {...props} />
//     </MemoryRouter>
// }

export default UserPreferencesModal