import { ApolloError } from 'apollo-client';
import { OrgDataContext } from 'context/orgData';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { MutationHookOptions, MutationResult, OperationVariables, useMutation } from 'react-apollo';

const MUTATION = gql`
mutation UpdateFuelTaken($tpID: String!, $fuelTaken: FuelTakenInput!){
        UploadFuelTaken(
            tpID: $tpID
            fuelTaken: $fuelTaken
            overwriteExisting: true
        ){
            locationId
            dateTimeTaken
            flightIdName
            amount
            psid
            aircraftId
            contractId
            name
        }
    }
`

interface UseUpdateFuelTakenOptions {
    onCompleted?: () => void,
    onError?: (err: ApolloError) => void,
    additionalMutationOptions?: MutationHookOptions<any, OperationVariables>
}

type FuelTakenObj = any & { locationId: string, dateTimeTaken: string };

interface ItemUpdating {
    locationId: string,
    dateTimeTaken: string
}

type ReturnType = [ (fuelTaken: FuelTakenObj) => void, MutationResult<any>, ItemUpdating ];

function useUpdateFuelTaken(options?: UseUpdateFuelTakenOptions): ReturnType {

    const { transporterID } = useContext(OrgDataContext);
    const [ itemUpdating, setItemUpdating ] = useState(null);
    
    const [ mutate, mutationResult ] = useMutation(MUTATION, {
        onCompleted: options?.onCompleted,
        onError: options?.onError,
        ...options.additionalMutationOptions
    });

    function updateFunc(fuelTaken: FuelTakenObj){
        setItemUpdating({
            locationId: fuelTaken.locationId,
            dateTimeTaken: fuelTaken.dateTimeTaken
        })
        mutate({
            variables: {
                tpID: transporterID,
                fuelTaken: {
                    ...fuelTaken,
                    dateTimeTaken: fuelTaken.dateTimeTaken
                }
            },
            optimisticResponse: 'optimisticResponse' in options.additionalMutationOptions ? options.additionalMutationOptions.optimisticResponse :
            {
                __typename: 'Mutation',
                UploadFuelTaken: fuelTaken
            }
        })
    }

    return [ updateFunc, mutationResult, itemUpdating ]
}

export default useUpdateFuelTaken