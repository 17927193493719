import React from "react";
import { Cell, Column, SelectionModes, Table } from "@blueprintjs/table";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";
import useSortableData from "hooks/useSortableData";

const CovidVaccineDetail = (props) => {
  const initialData = props.data.map((row) => {
    const getVal = (obj, field) => obj && obj[field];
    return {
      ...row,
      customerName: getVal(row.customer, "name"),
      employerName: getVal(row.employer, "name"),
    };
  });

  const [data, { setSortKey, currentSortKey, currentSortDir }] =
    useSortableData(initialData);

  const columns = [
    {
      name: "Last Name",
      key: "lastName",
    },
    {
      name: "First Name",
      key: "firstName",
    },
    {
      name: "Is Vaccinated",
      key: "isVaccinated",
      formatter: (value) => {
        const colorMap = {
          true: "green",
          false: "red",
        };
        let v = value ? "Yes" : "No";
        return (
          <span style={{ color: colorMap[String(value)] || "red" }}>{v}</span>
        );
      },
    },
    {
      name: "Customer",
      key: "customerName",
    },
    {
      name: "Employer",
      key: "employerName",
    },
    {
      name: "Essential On",
      key: "essentialOn",
    },
  ].map((col) => {
    const cellRenderer = (i) => {
      const value = col.formatter
        ? col.formatter(data[i]?.[col.key])
        : data[i]?.[col.key];
      return (
        <Cell tooltip={value} style={{ display: "flex", alignItems: "center" }}>
          {value}
        </Cell>
      );
    };
    return (
      <Column
        key={col.key}
        name={col.name}
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={() => (
          <SortableColumnHeaderCell
            name={col.name}
            sortKey={col.key}
            onSort={setSortKey}
            currentSortedKey={currentSortKey}
            currentSortDir={currentSortDir}
          />
        )}
      />
    );
  });

  return (
    <Table
      selectionModes={SelectionModes.NONE}
      className="mc-table"
      numRows={data?.length}
    >
      {columns}
    </Table>
  );
};

export default CovidVaccineDetail