import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card } from 'antd';
import { QCCard } from '../QCCard';

class QCResult extends Component {
    render() {
        const { data, allowSelection=true } = this.props;
        var selectedIndex = -1;
        var error;
        
        if (data){
            try{
            data.qc_result.forEach((result, i) => {
                if (data.selectedResult && result){
                    if (data.selectedResult.isnId === result.isnId && 
                        data.selectedResult.quickCheckStatus === result.quickCheckStatus &&
                        data.selectedResult.companyID === result.companyID){
                        selectedIndex = i;
                    }
                }
            });

            if (selectedIndex === -1) {
                var qcs;
                if (data.selectedResult){
                    qcs = data.selectedResult.quickCheckStatus;
                }
                if (typeof qcs === 'string') {
                    qcs = qcs.toLowerCase();
                }
                if (data.qc_result.length <= 0)
                if (qcs !== 'multi'){
                    switch (data.qc_result.length) {
                        case 0:
                            selectedIndex = -2;
                            break;
                        case 1:
                            selectedIndex = 0;
                            break;
                        default:
                            selectedIndex = -1;
                            break;
                    }
                }
            }
            }
            catch(err)
            {
                selectedIndex = -2;
                try{
                    error = err.stack;
                }catch(err2){
                    error = err.message;
                }
            }

            const renderAlert = () => {
                var style = {
                    marginBottom: '1rem'
                }
                if (selectedIndex === -2 && data.qc_result.length){
                    return <Alert style={style}
                    type="error"
                    message="The ISN result is empty or malformed and an ISN status could not be derived"
                    description={error} />
                }else if (selectedIndex === -1 && allowSelection){
                    return <Alert style={style} type="warning" title="Multiple ISN Results Exist" description="Please make a selection from the available ISN results." />
                }
            }
        
            return <React.Fragment>
                {renderAlert()}
                <Card style={{ marginBottom: '1rem' }} title="ISN Result Info" size="small">
                    <div>
                        <strong>Customer: </strong><span>{data.isn_customer}</span>
                    </div>
                    <div>
                        <strong>Success: </strong><span>{data.success ? 'Yes' : 'No'}</span>
                    </div>
                    {data.error_message ? <div>
                        <strong>Error Message: </strong><span style={{ color: 'red' }}>{data.error_message}</span>
                    </div> : null}
                    {data.isn_ownerid ? <div>
                        <strong>Owner ID: </strong><span>{data.isn_ownerid}</span>
                    </div> : null}
                    <div>
                        <strong>Result Count: </strong><span>{data.qc_count}</span>
                    </div>
                </Card>
                {(data.qc_result.length) === 0 ? (
                    <Card size="small">
                        No Results
                    </Card>
                ) : null}
                {(selectedIndex !== -2) ? data.qc_result.map((result, i) => {
                    const active = i === selectedIndex;
                    return (
                        <QCCard 
                            key={i}
                            active={active}
                            data={result}
                            onActivated={this.props.onResultActivated}
                            allowSetActive={allowSelection}
                        />
                    )
                }) : null}
            </React.Fragment>
        }
        return 'No ISN data available'
    }
}

QCResult.propTypes = {
    data: PropTypes.object,
    onResultActivated: PropTypes.func,
    runISNCheck: PropTypes.func,
    isnCheckRunning: PropTypes.bool,
    allowSelection: PropTypes.bool
}

export default QCResult