import { Form, Input, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { formItemLayout } from 'common/form';
import React, { forwardRef, LegacyRef, useRef } from 'react';
import { entryFormOptions, EntryFormProps, useAutoFocus } from '.';

export interface AircraftEntryFormProps extends EntryFormProps {

}

const AircraftEntryForm: React.FC<AircraftEntryFormProps> = forwardRef((props, ref: LegacyRef<Form>) => {
    const inputRef = useRef(null);
    const { getFieldDecorator } = props.form;

    useAutoFocus(props.autoFocus, inputRef.current)

    return <Form ref={ref}>
        <Form.Item label="Tail Num" {...formItemLayout}>
        {getFieldDecorator('tailNum', {
            rules: [
                { required: true, message: 'Tail Num is required' }
            ]
        })(<Input ref={inputRef} />)}
        </Form.Item>
        <Form.Item label="Model" {...formItemLayout}>
        {getFieldDecorator('model', {
            rules: [
                { required: true, message: 'Model is required' }
            ]
        })(<Input />)}
        </Form.Item>
        {props.dupeChecker}
        <Form.Item label="Max Seats" {...formItemLayout}>
        {getFieldDecorator('maxSeats', {
            initialValue: 0
        })(<InputNumber />)}
        </Form.Item>
        <Form.Item label="Max Load" {...formItemLayout}>
        {getFieldDecorator('maxLoad', {
            initialValue: 0
        })(<InputNumber />)}
        </Form.Item>
    </Form>
})

export default Form.create<AircraftEntryFormProps & FormComponentProps>(entryFormOptions<AircraftEntryFormProps>())(AircraftEntryForm);