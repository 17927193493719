import gql from "graphql-tag";

const SetNonPreferredMutation = gql`
  mutation SetNonPreferredList(
    $_id: ID
    $customerID: ID!
    $tpID: ID!
    $personIds: [NonPreferredPersonInput!]
    $organizationIds: [NonPreferredOrganizationInput!]
  ) {
    setNonPreferred(
        _id: $_id
        customerID: $customerID
        tpID: $tpID
        personIds: $personIds
        organizationIds: $organizationIds
    ) {
      _id
      personIds {
        id {
          _id
          lastName
          firstName
          employerID {
            _id
            name
          }
          contactInfo {
            address {
              city
              state
            }
          }
        }
        startDate
        endDate
        reason
        modifiedBy
      }
      organizationIds {
        id {
          ... on Customer {
            _id
            name
            classType
          }
          ... on Employer {
            _id
            name
            classType
          }
        }
        startDate
        endDate
        reason
        modifiedBy
      }
    }
  }
`;

export default SetNonPreferredMutation