/*
Written by Austin Thibodeaux in February 2019
Documented by Austin Thibodeaux in May 2023

Component name:     PaxDetail
Purpose:            Displays data grid for Passenger report.
*/

import React from 'react';
import {Cell, Column, SelectionModes, Table} from '@blueprintjs/table';
import {momentOrNull} from "common/util";
import useSortableData from "hooks/useSortableData";
import SortableColumnHeaderCell from "components/blueprinttable/SortableColumnHeaderCell";

const PaxDetail = (props) => {
	const initialData = props.data.map((row) => {
		const getVal = (obj, field) => obj && obj[field];
		//console.log(row);

		//console.log( row._id  + " -- " + row.departure['name'] + "---->" + row.destination['name']);
		return {
			...row,
			currentCarrierName: getVal(row.currentCarrier, 'desig'),
			//currentCarrierName: getVal(row.currentCarrier, 'desig'),
			departureName: getVal(row.departure, 'name'),
			destinationName: getVal(row.destination, 'name'),
			customerName: getVal(row.customer, 'name'),
			transporterName: getVal(row.transporter, 'name'),
			employerName: getVal(row.employer, 'name'),
			finalizationReasonType: row.finalizationReasonType,
			//finalized: row.nodeState === 'FINALIZED'
		};
	});

	const [ data, { setSortKey, currentSortKey, currentSortDir } ] = useSortableData(initialData);

	const columns = [
		['Last Name', 			'lastName'],
		['First Name', 			'firstName'],
		['Flight', 				'currentCarrierName'],
		['Departure', 			'departureName'],
		['Destination', 		'destinationName'],
		['Pax Wt', 				'paxWeight'],
		['Bag Wt', 				'bagWeight'],
		['Bad Ct', 				'bagCount'],
		['Transporter', 		'transporterName'],
		['Customer', 			'customerName'],
		['Check-In-Time', 		'checkedInTime', (i) => momentOrNull(data[i]?.checkedInTime)?.format('YYYY-MM-DD HH:mm:ss')],
		['Finalization Reason', 'finalizationReasonType']
	]
		.map((col) => {
			const cellRenderer = (i) => {
				const value = col.length > 2 ?
					col[2](i) :
					data[i]?.[col[1]]
				return <Cell tooltip={value} style={{ display: 'flex', alignItems: 'center' }}>{value}</Cell>
			}
			return (
				<Column
					key={col[1]}
					name={col[0]}
					cellRenderer={cellRenderer}
					columnHeaderCellRenderer={
						() => (
							<SortableColumnHeaderCell
								name={col[0]}
								sortKey={col[1]}
								onSort={setSortKey}
								currentSortedKey={currentSortKey}
								currentSortDir={currentSortDir}
							/>
						)
					}
				/>
			)
		})

	return (
		<Table
			selectionModes={SelectionModes.NONE}
			className="mc-table"
			numRows={data?.length}
		>
			{columns}
		</Table>
	);
};

// const PaxDetail = ({ data }) => {
//     return (
//         <Table
//             className="mc-report"
//             bordered
//             dataSource={data}
//             columns={[
//                 {
//                     title: 'Flight',
//                     key: 'flight',
//                     dataIndex: 'currentCarrier',
//                     render: flight => flight && flight.desig,
//                     width: 100
//                 },
//                 {
//                     title: 'Base',
//                     key: 'base',
//                     dataIndex: 'departure',
//                     render: dep => dep && dep.name,
//                     width: 200
//                 },
//                 {
//                     title: 'Passenger Name',
//                     key: 'passengerName',
//                     render: (_, record) => {
//                         const { firstName, lastName } = record;
//                         return `${lastName}, ${firstName}`
//                     },
//                     width: 200
//                 },
//                 {
//                     title: 'Pax Weight',
//                     key: 'paxWeight',
//                     dataIndex: 'paxWeight',
//                     width: 100
//                 },
//                 {
//                     title: 'Bag Weight',
//                     key: 'bagWeight',
//                     dataIndex: 'bagWeight',
//                     width: 100
//                 },
//                 {
//                     title: 'Bag Count',
//                     key: 'bagCount',
//                     dataIndex: 'bagCount',
//                     width: 100
//                 },
//                 {
//                     title: 'Check-In-Time',
//                     key: 'checkInTime',
//                     dataIndex: 'checkedInTime',
//                     render: time => time && moment(time).format('YYYY-MM-DD hh:mm:ss'),
//                     width: 200
//                 },
//                 {
//                     title: 'Employer',
//                     key: 'employer',
//                     dataIndex: 'employer',
//                     render: emp => emp && emp.name,
//                     width: 200
//                 },
//                 {
//                     title: 'Destination',
//                     key: 'destination',
//                     dataIndex: 'destination',
//                     render: dest => dest && dest.name,
//                     width: 200
//                 },
//                 {
//                     title: 'Customer',
//                     key: 'customer',
//                     dataIndex: 'customer',
//                     render: cust => cust && cust.name,
//                     width: 200
//                 },
//                 {
//                     title: 'Finalized',
//                     key: 'finalized',
//                     dataIndex: 'nodeState',
//                     render: final => YesNo(final === 'FINALIZED'),
//                     width: 100
//                 }
//             ]}
//             size="small"
//             pagination={false}
//             rowKey={record => record._id}
//         />
//     )
// }

// const PaxDetail = ({ data }) => {
//     const tableRef = useRef(null);
//     return (
//         <Layout style={{ height: '100%' }} >
//             <Layout.Header style={{ background: 'white', padding: 0, lineHeight: '24px', height: '45px'}}>
//                 <Button onClick={() => tableRef.current.table.clearFilter(true)}>Clear Filters</Button>
//             </Layout.Header>
//             <Layout.Content>
//                 <ReactTabulator
//                     ref={tableRef}
//                     height="100%"
//                     data={data}
//                     layout="fitDataFill"
//                     columns={[
//                         {
//                             title: '#',
//                             formatter: 'rownum',
//                             width: 50
//                         },
//                         {
//                             title: 'Flight',
//                             field: 'currentCarrier.desig',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Last Name',
//                             field: 'lastName',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'First Name',
//                             field: 'firstName',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Employer',
//                             field: 'employer.name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Customer',
//                             field: 'customer.name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Pax Weight',
//                             field: 'paxWeight',
//                             headerFilter: 'number'
//                         },
//                         {
//                             title: 'Bag Weight',
//                             field: 'bagWeight',
//                             headerFilter: 'number'
//                         },
//                         {
//                             title: 'Bag Count',
//                             field: 'bagCount',
//                             headerFilter: 'number'
//                         },
//                         {
//                             title: 'Departure',
//                             field: 'departure.name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Destination',
//                             field: 'destination.name',
//                             headerFilter: 'input'
//                         },
//                         {
//                             title: 'Check-In-Time',
//                             field: 'checkInTime',
//                             formatter: (cell) => {
//                                 return moment(cell.getValue()).format('YYYY-MM-DD hh:mm:ss')
//                             }
//                         },
//                         {
//                             title: 'State',
//                             field: 'nodeState',
//                             headerFilter: 'select',
//                             headerFilterParams: {
//                                 values: true
//                             }
//                         }
//                     ]}
//                 />
//             </Layout.Content>
//         </Layout>
//     )
// }

export default PaxDetail