import {NonComplyBypass} from "common/types/non-comply-bypass";
import pluralize from 'pluralize';
import {Button, Divider, Icon, List, Row, Tag, Tooltip, Typography} from "antd";
import {momentOrNull} from "../../../../common/util";
import {TooltipProps} from "antd/lib/tooltip";
import moment from 'moment';

export interface ConditionCountProps {
    personID: string,
    nonComplyBypasses: NonComplyBypass[],
    tooltipProps?: TooltipProps,
    onEdit?: () => void
}

/**
 * Displays a button with the text "1 Condition" or "2 Conditions" and displays a tooltip with more detailed Non-Comply bypass information
 */
const ConditionCount: React.FC<ConditionCountProps> = (props) => {
    const count = props.nonComplyBypasses.length;

    const currentDate = moment();

    // If there is no bypasses, then display "Add Condition"
    if (count <= 0){
        return <Button
            className="mc-btn-transparent"
            type="link"
            size="small"
            onClick={() => props.onEdit?.()}
        >Add Condition</Button>
    }

    function isBypassExpired(bypass: NonComplyBypass){
        const exp = momentOrNull(bypass.expiresOn)?.endOf('day');
        return exp?.isBefore(currentDate) || false;
    }

    function renderBypassOverlay(bypass: NonComplyBypass, key: string | number) {

        const isExpired = isBypassExpired(bypass);

        const expiredBadge = isExpired ? <Tag color="#f5222d" style={{ float: 'right' }}>EXPIRED</Tag> : null

        return <List.Item key={key}>
            Person will be <u>whitelisted</u> for <Typography.Text strong type="warning">{bypass.nonComplyCategory}</Typography.Text>{' '}
            status until <Typography.Text strong type="warning">{momentOrNull(bypass.expiresOn)?.format('YYYY-MM-DD')}</Typography.Text>
            <br/>
            <Typography.Text type="secondary">
                Added by <Typography.Text strong type="secondary">{bypass.cognitoUser?.givenName} {bypass.cognitoUser?.familyName}</Typography.Text> at {momentOrNull(bypass.timestamp)?.format('YYYY-MM-DD HH:mm')}
            </Typography.Text>
            {expiredBadge}
        </List.Item>
    }

    const hasExpired = !!props.nonComplyBypasses.find(isBypassExpired);

    /**
     * This overlay shows when you hover over the button.
     * Displays detailed information about NonComplyBypasses.
     */
    const overlay = (
        <div>
            <div style={{ maxHeight: '20rem', overflow: 'auto' }}>
                <List>
                    {props.nonComplyBypasses.map(renderBypassOverlay)}
                </List>
            </div>
            <div style={{ margin: '0 -8px' }}>
                <Divider type="horizontal" style={{ margin: 0, marginBottom: '4px' }} />
                <Row type="flex" style={{ padding: '0 8px' }}>
                    <Button
                        icon="edit"
                        type="primary"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => props.onEdit?.()}
                    >Edit</Button>
                </Row>
            </div>
        </div>
    )

    const textType = hasExpired ? 'danger' : 'warning';
    const icon = hasExpired ? <Icon type="exclamation-circle" style={{ marginLeft: 6 }} /> : <Icon style={{ marginLeft: 6 }} type="down" />

    return <Tooltip overlayStyle={{ width: 'fit-content', maxWidth: 'fit-content', padding: 0 }} {...props?.tooltipProps} overlay={overlay}>
        <Button className="mc-btn-transparent" size="small" onClick={() => props.onEdit?.()}>
            <Typography.Text type={textType}>{`${count} ${pluralize('Condition', count)}`}{icon}</Typography.Text>
        </Button>
    </Tooltip>
}

export default ConditionCount