import React from 'react';
import { Select, Input, Button, Tooltip } from 'antd';
import { InputPhoneNumber } from '../InputPhoneNumber';
import globalPhoneTypes from './phoneTypes';
import { SelectProps } from 'antd/lib/select';

export interface Phone {
    type: string,
    number: string
}

export interface PhoneType {
    key: string,
    label: String
}

export interface InputPhoneProps {
    types?: Array<PhoneType>,
    inputPhoneNumberProps?: any,
    phoneTypeProps?: SelectProps,
    onChange?(value: Phone): void,
    value?: Phone
}

const InputPhone: React.FC<InputPhoneProps> & { validator: (rules: any, value: any, callback: any) => void } = ({ types, onChange, value, inputPhoneNumberProps={}, phoneTypeProps={}, ...restProps }) => {
    const handleChange = (key, getValue=(v) => v) => (obj) => {
        if (typeof onChange !== 'function') return;
        onChange && onChange({
            type: value && value.type,
            number: value && value.number,
            [key]: getValue(obj)
        })
    }
    const clear = () => {
        if (typeof onChange !== 'function') return;
        onChange({
            type: undefined,
            number: undefined
        })
    }
    var phTypes = types;
    if (!phTypes){
        phTypes = globalPhoneTypes;
    }
    return (
        <Input.Group compact { ...restProps }>
            <Select
                value={value && value.type}
                defaultValue="WORK"
                style={{
                    minWidth: '5rem'
                }}
                onChange={handleChange('type', e => e)}
                showAction={["focus", "click"]}
                {...phoneTypeProps}
            >
                {phTypes.map(t => {
                    return <Select.Option key={t.key}>{t.label}</Select.Option>
                })}
            </Select>
            <InputPhoneNumber 
                value={value && value.number}
                onChange={handleChange('number', v => v)}
                style={{
                    width: '8rem'
                }}
                {...inputPhoneNumberProps}
            />
            {value && (value.type || value.number) ? (
                <Tooltip title="Clear">
                    <Button onClick={clear} icon="close" />
                </Tooltip>
            ) : null}
        </Input.Group>
    )
}

InputPhone.validator = (rules, value, callback) => {
    if (value && (value.number || value.type)){
        if (!value.number){
            callback('You must enter a phone number');
        }
        else if (!value.type){
            callback('You must choose a phone type');
        }
        return;
    }
    callback();
}

export default InputPhone