import { useRef, useEffect, EffectCallback, DependencyList } from 'react';

/**
 * Extension of the useEffect hook. Runs only when the dependencies change, not on mount.
 *
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list change.
 *
 * @version 16.8.0
 * @see https://reactjs.org/docs/hooks-reference.html#useeffect
 */
function useDelayedEffect(effect: EffectCallback, deps?: DependencyList): void{

    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current === false){
            didMount.current = true;
            return;
        }

        return effect();
    }, deps);
}

export default useDelayedEffect;