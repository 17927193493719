import { Alert, Button, Checkbox, Divider, Dropdown, Icon, Menu, Spin, Tooltip } from 'antd';
import { ApolloError, NetworkStatus } from 'apollo-boost';
import getGenericStateReducer from 'common/reducer/generic-state-reducer';
import commonColumns from 'common/table/columns';
import {cleanGraphQLErrorMsg, reactNodeTextGetter} from 'common/util';
import { GlobalFilterWithWrapper } from 'components/GlobalFilter';
import { FilterableTable } from 'components/tables/FilterableTable';
import withFlightDrawer, { HasFlightDrawer } from 'components/WithFlightDrawer';
import { GlobalAppStateContext } from 'context/global-app-state';
import { GlobalFilterContext } from 'context/global-filter';
import { OrgDataContext } from 'context/orgData';
import useAutoPaginatedQuery from 'hooks/apollo-extensions/useAutoPaginatedQuery';
import pluralize from 'pluralize';
import React, { useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import ColorKey from '../ColorKey';
import LiveCard from '../LiveCard';
import LiveColoredButton from '../LiveColoredButton';
import '../style.less';
import { fetchMoreAndGetToken } from '../util';
import FLIGHTS_QUERY from './query';
import './style.less';
import DataExportWrapper from "../../../component-wrappers/data-export-wrapper/data-export-wrapper";
import DataExport from "../../../common/data-export";
import {ColumnProps} from "antd/lib/table";

export interface LiveFlightsProps extends HasFlightDrawer {

}

interface ReducerState {
    error: ApolloError,
    tableSettingsDropdownVisible: boolean,
    errorClosed: boolean
}

let DEFAULT_STATE: ReducerState = {
    error: null,
    tableSettingsDropdownVisible: false,
    errorClosed: false
}

const LiveFlights: React.FC<LiveFlightsProps> = (props) => {
    const globalFilter = useContext(GlobalFilterContext);
    const globalAppState = useContext(GlobalAppStateContext);
    const { customer, transporter } = useContext(OrgDataContext);
    const [ state, dispatch ] = useReducer(getGenericStateReducer<ReducerState>(), DEFAULT_STATE);
    const history = useHistory();

    const queryVars = {
        filter: {
            customers: globalFilter.customer && globalFilter.customer.length ? globalFilter.customer.map(({ key }) => key) : customer && [customer._id],
            transporter: globalFilter.transporter && globalFilter.transporter.length ? globalFilter.transporter.map(({ key }) => key)[0] : transporter && transporter._id,
            departures: globalFilter.departure && globalFilter.departure.map(({ key }) => key),
            onlyShowLive: true
        },
        limit: 100
    }

    const {
        data, fetchMore, 
        networkStatus, paginating, 
        pageCount, refetch,
        error, paginationError
    } = useAutoPaginatedQuery(FLIGHTS_QUERY, {
        variables: queryVars,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
        pollInterval: 20000,
        supressDataUpdates: true,   
        onError: (err) => dispatch({ newState: { error: err, errorClosed: false } }),
        onPaginationError: (err) => dispatch({ newState: { error: err, errorClosed: false } }),
        getNextToken: async (token) => await fetchMoreAndGetToken(fetchMore, queryVars, token, 'flights'),
        getTokenFromData: (data) => {
            let token = data?.flights?.bookmark;
            if (token === 'nil'){
                token = null;
            }
            return token;
        }
    })

    let flights: any[] = (data?.flights?.docs) || []
    if (globalFilter.destination && globalFilter.destination.length){
        flights = flights.filter(flight => {
            if (flight?.legsArray){
                return flight.legsArray.findIndex(leg => globalFilter.destination.map(dest => dest?.key).includes(leg.destinationID)) > -1;
            }
            return false;
        })
    }
    const tableSettings = globalAppState.getTableSettings('liveFlights');

    function getColumns() {
        const columns = [
            {
                ...commonColumns.flight.columns.designation,
                render: (desig, record) => {
                    const handleClick = () => {
                        if (customer){
                            history.push(`/app/flights/${record._id}`)
                        }
                    }
                    let btn = <LiveColoredButton
                        onClick={handleClick}
                        type={record.state}
                        clickable={customer ? true : false}
                    >{desig}</LiveColoredButton>

                    if (record.state){
                        return <Tooltip
                            title={"Flight status is " + record.state}
                            placement="right"
                            mouseEnterDelay={1}
                        >{btn}</Tooltip>
                    }
                    return btn
                }
            },
            ...commonColumns.flight.some(['departureWeight', 'passengers', 'departure', 'destinations', 'seatPercent', 'weightPercent', 'status']),
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: (text, record) => (
                    <Button onClick={() => props.flightDrawer.open(record._id)} size="small" type="primary">
                        <span>Flight Details</span>
                        <Icon style={{ marginLeft: '6px' }} type="right" />
                    </Button>
                )
            
            }
        ].filter(col => col)
        return columns as ColumnProps<any>[]
    }

    function renderOptionsContent(){
        function isChecked(key: string){
            return tableSettings
                ?.settings?.hiddenColumns?.includes(key)
                ? false : true
        }

        let columns = getColumns().map(col => ({ key: String(col.key), title: String(col.title) }))
            .filter(col => col.key !== 'action');

        return <Menu
            onClick={(cp) => {
                cp.domEvent.stopPropagation();
                globalAppState.setTableColumnVisibility(
                    'liveFlights',
                    cp.key,
                    !isChecked(cp.key)
                );
            }}
            style={{ display: 'flex' }}
        >
            <Menu.ItemGroup title="Desig Color Legend">
                <div style={{ margin: '0 4px' }}>
                    <ColorKey type="overnight" text="Overnight" />
                </div>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Show Columns">
                {columns.map(col => (
                    <Menu.Item key={col.key}>
                        {(
                            <Checkbox
                                style={{ marginRight: '12px' }}
                                checked={isChecked(col.key)}
                            />
                        )}
                        {col.title}
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>
        </Menu>
    }

    let columns = getColumns().filter((col: any) => {
        if (col.key === 'action') return true;
        return !tableSettings?.settings?.hiddenColumns?.includes(col.key)
    });

    const { getNodeText, registerNode }  = reactNodeTextGetter();
    columns = columns.map((col) => DataExport.Utils.registerAntdColumn(col, registerNode));

    let rightContent = [
        <Divider type="vertical" style={{ height: '100%' }} />,
    ];

    if (![NetworkStatus.setVariables, NetworkStatus.loading].includes(networkStatus)){
        rightContent.unshift(
            <span>{`${flights.length} ${pluralize('Result', flights.length)}`}</span>
        );
    }

    rightContent = [
        ...rightContent,
        <Dropdown
            overlay={renderOptionsContent()}
            placement="bottomLeft"
            trigger={['click']}
            visible={state.tableSettingsDropdownVisible}
            onVisibleChange={(visible) => dispatch({ newState: { tableSettingsDropdownVisible: visible } })}
        >
            <Button icon="setting" />
        </Dropdown>,
        <Tooltip title="Export as CSV" placement="bottomLeft">
            <Button
                icon="export"
                onClick={() => {
                    const config = {
                        columns: columns
                            .filter(col => col.key !== "action")
                            .map((col) => (
                                DataExport.Utils.convertAntdColumn(col, getNodeText)
                            ))
                    }
                    DataExport.exportDataAsCsv(flights, "Flights", config);
                }}
            />
        </Tooltip>,
        <Button
            icon="reload"
            loading={networkStatus === NetworkStatus.refetch}
            disabled={networkStatus === NetworkStatus.loading}
            onClick={() => refetch()}
        />
    ]


    if ([NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus) || paginating){
        rightContent.unshift(
            <span><Icon type="loading" spin /> {`Fetching page ${pageCount}`}</span>
        )
    }

    return <div className="mc-live-flights" style={{ display: 'flex', flexDirection: 'column', margin: -24, height: 'calc(100% + 48px)' }}>
        <GlobalFilterWithWrapper
            wrapperProps={{ style: { margin: 0 } }}
            showFields={[
                'customer',
                'departure',
                'destination'
            ]}
        />
        {(error || paginationError) && !state.errorClosed ? (
                <Alert
                    message="Failed to load flights"
                    description={cleanGraphQLErrorMsg((error?.message) || paginationError?.message)}
                    style={{ margin: '12px', marginBottom: 0 }}
                    showIcon
                    type="error"
                    closable
                    afterClose={() => dispatch({ newState: { errorClosed: true } })}
                />
            ) : null}
        <LiveCard
            contentNoPadding
            animation={{
                slideIn: true
            }}
            hasTable
            headerProps={{
                title: 'Flights',
                verticallyCenterTitle: true,
                rightContent
            }}
        >
            <Spin
                indicator={<Icon type="loading" />}
                spinning={
                    networkStatus === NetworkStatus.loading && flights.length <= 0
                }
                style={{ overflowX: 'auto' }}
            >
                <FilterableTable
                    className="mc-table mc-table-scroll-auto mc-table-header-nowrap"
                    pagination={false}
                    rowKey={record => record._id}
                    size="small"
                    dataSource={flights}
                    columns={columns}
                    filterBarProps={{
                        style: { margin: 0 },
                        banner: true,
                        className: "mc-live-filtered-table-filter-bar"
                    }}
                    showHighlightMatches={false}
                />
            </Spin>
        </LiveCard>
    </div>
}

export default withFlightDrawer()(LiveFlights)