import { message } from 'antd';
import gql from 'graphql-tag';
import update from 'immutability-helper';
import lodash from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { compose } from 'recompose';
import { safeGet } from '../common/util';
import checkScheduledPax from '../components/scheduling/checkScheduledPax';
import SetsEditor from '../components/scheduling/SetsEditor/SetsEditor';
import withCognitoUser from '../components/WithCognitoUser';
import WithOrgData from '../components/WithOrgData';
import DeleteEntity from '../Mutations/DeleteEntity';
import SchedulePassengersMutation from '../Mutations/SchedulePassengersMutation';
import SetPersonSet from '../Mutations/SetPersonSet';
import GetPersonSetQuery from '../Queries/GetPersonSetQuery';
import GetPersonSetQueryCached from '../Queries/GetPersonSetQueryCached';

const defaultTableRowKeys = {
    OUTBOUND: [],
    INBOUND: []
}

const SetsEditorContainer = ({
    setId: origSetId,
    isEditing=false,
    isScheduling=false,
    isNew=true,
    createRoutes=() => null,
    onEditChange,
    onScheduleChange,
    onSaved,
    onScheduled,
    orgData,
    cognitoUser,
    onDeleted
}) => {

    const [ addPersonsLoading, setAPL ] = useState(false);
    const [ selectedPersonKeys, setSPK ] = useState([]);
    const [ selectedTableRowKeys, setSTRK ] = useState(defaultTableRowKeys);
    const [ transitType, setTT ] = useState('OUTBOUND');

    const [ unsavedCreated, setUnsavedCreated ] = useState(false);

    const [ personsToSchedule, setPTS ] = useState({
        OUTBOUND: [],
        INBOUND: []
    });

    const [ cargoToSchedule, setCTS ] = useState({
        INBOUND: []
    });

    const [ defaultChargeCode, setDefaultCC ] = useState(null);

    useEffect(() => {
        if (!isEditing){
            setUnsavedCreated(false);
        }
    }, [isEditing])

    return (
        <Query
            query={GetPersonSetQuery}
            variables={{ setId: origSetId }}
            fetchPolicy="cache-and-network"
            skip={!origSetId}
        >
        {
            props => {
                let setId = origSetId;

                const getExistingPTS = (perId, transitType) => {
                    return personsToSchedule[transitType].find(per => per._id === perId);
                }

                const initScheduledPerson = (tt) => (person) => {
                    return getExistingPTS(person._id, tt) || {
                        ...person,
                        _id: person._id,
                        lastName: person.lastName,
                        firstName: person.firstName,
                        paxWeight: 0,
                        bagWeight: 0,
                        bagCount: 0,
                        chargeCode: null
                    }
                }

                const initPersonsToSchedule = () => {
                    const { getPersonSet } = props.client.readQuery({
                        query: GetPersonSetQueryCached,
                        variables: { setId }
                    })
                    const { outbound, inbound } = getPersonSet;
    
                    setPTS({
                        OUTBOUND: outbound.filter(p => p).map(initScheduledPerson('OUTBOUND')),
                        INBOUND: inbound.filter(p => p).map(initScheduledPerson('INBOUND'))
                    })
                }

                const routes = createRoutes({
                    loading: props.loading,
                    setName: safeGet(['data', 'getPersonSet', 'name'], props)
                })
                if (isEditing){
                    setId = 'unsaved-person-set';
                }
                if (!isScheduling && personsToSchedule.OUTBOUND.length !== 0 && personsToSchedule.INBOUND.length !== 0){
                    setPTS({
                        OUTBOUND: [],
                        INBOUND: []
                    })
                }
                if (isEditing && !unsavedCreated) {

                    /*
                    Create and use an unsaved version of the person set in the cache.
                    Ensures that the original version of the person set is preserved in case the user cancels changes.
                    */
                    let personSet = {
                        name: null,
                        outbound: [],
                        inbound: [],
                        departureID: null,
                        destinationID: null,
                        owner: null
                    }
                    if (!isNew){
                        personSet = props.data && props.data.getPersonSet
                    }
                    if (!props.loading && !props.error){
                        props.client.writeQuery({
                            query: GetPersonSetQuery,
                            variables: { setId },
                            data: {
                                getPersonSet: {
                                    ...personSet,
                                    __typename: 'PersonSet',
                                    _id: setId
                                }
                            }
                        });
                        setUnsavedCreated(true);
                    }
                    if (isScheduling && !props.loading && !props.error){
                        initPersonsToSchedule()
                    }
                }

                const addPersons = () => {
                    setAPL(true);
                    props.client.query({
                        variables: { ids: selectedPersonKeys },
                        query: gql`
                        query ResolvePersons($ids: [ID!]!){
                            resolve_entity_ids(
                                ids: $ids
                                typename: "Person"
                            ){
                                ... on Person {
                                    _id
                                    firstName
                                    lastName
                                    employerID {
                                        _id
                                        name
                                    }
                                    vip
                                    dob
                                }
                            }
                        }
                        `
                    })
                    .then(({ data }) => {
                        setAPL(false);
                        const personsToAdd = (data && data.resolve_entity_ids) || [];
                
                        const { getPersonSet } = props.client.readQuery({
                            query: GetPersonSetQuery,
                            variables: {
                                setId
                            }
                        })
                    
                        let outbound = getPersonSet.outbound;
                        let inbound = getPersonSet.inbound;
                    
                        if (transitType === 'OUTBOUND'){
                            outbound = [...outbound, ...personsToAdd];
                            outbound = lodash.uniqBy(outbound, (per) => per._id);
                        }
                        else
                        {
                            inbound = [...inbound, ...personsToAdd];
                            inbound = lodash.uniqBy(inbound, (per) => per._id);
                        }
                    
                        props.client.writeQuery({
                            query: GetPersonSetQuery,
                            variables: {
                                setId
                            },
                            data: {
                                getPersonSet: {
                                    ...getPersonSet,
                                    inbound,
                                    outbound
                                }
                            }
                        })
                        initPersonsToSchedule();
                        setSPK([]);
                    })
                    .catch((err) => {
                        message.error('Failed to add people to set', 3)
                        .then(() => message.error(err.message, 5));
                        setAPL(false)
                    })
                }
            
                const removePersons = () => {
                    const { getPersonSet } = props.client.readQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        }
                    })

                    const personKeys = selectedTableRowKeys[transitType];
                
                    let outbound = getPersonSet.outbound;
                    let inbound = getPersonSet.inbound;
                
                    if (transitType === 'OUTBOUND'){
                        outbound = outbound.filter(per => !personKeys.includes(per._id));
                    }
                    else
                    {
                        inbound = inbound.filter(per => !personKeys.includes(per._id));
                    }
                
                    props.client.writeQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        },
                        data: {
                            getPersonSet: {
                                ...getPersonSet,
                                inbound,
                                outbound
                            }
                        }
                    })
                    initPersonsToSchedule();
                    setSTRK(defaultTableRowKeys);
                }

                const movePersons = (selectedKeys = selectedTableRowKeys[transitType]) => {
                    const { getPersonSet } = props.client.readQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        }
                    })

                    const toTT = transitType === 'OUTBOUND' ? 'INBOUND' : 'OUTBOUND';

                    const personKeys = selectedKeys;
                    
                    let personData = {
                        outbound: getPersonSet.outbound,
                        inbound: getPersonSet.inbound
                    }
                
                    if (toTT === 'OUTBOUND'){
                        const personList = personData.inbound.filter(per => personKeys.includes(per._id));
                        personData.inbound = personData.inbound.filter(per => !personKeys.includes(per._id));
                        personData.outbound = [...personData.outbound, ...personList];
                    }
                    else
                    {
                        const personList = personData.outbound.filter(per => personKeys.includes(per._id));
                        personData.outbound = personData.outbound.filter(per => !personKeys.includes(per._id));
                        personData.inbound = [...personData.inbound, ...personList];
                    }
                
                    props.client.writeQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        },
                        data: {
                            getPersonSet: {
                                ...getPersonSet,
                                inbound: personData.inbound,
                                outbound: personData.outbound
                            }
                        }
                    })
                    if (isScheduling){
                        const personsToAdd = personData[toTT.toLowerCase()].filter(per => personKeys.includes(per._id)).map(initScheduledPerson(transitType));
                        setPTS({
                            [transitType]: personsToSchedule[transitType].filter(per => !personKeys.includes(per._id)),
                            [toTT]: [ ...personsToSchedule[toTT], ...personsToAdd ]
                        })
                    }
                    setSTRK(defaultTableRowKeys)
                    setTT(toTT);
                }

                const reorderPersons = (dragIndex, hoverIndex) => {
                    const { getPersonSet } = props.client.readQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        }
                    })
                
                    const listKey = transitType === 'OUTBOUND' ? 'outbound' : 'inbound';
                
                    const data = getPersonSet[listKey] || [];
                
                    const dragRow = data[dragIndex];
                    const newData = update(data, {
                        $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]]
                    });
                
                    const newPersonSet = {
                        ...getPersonSet,
                        [listKey]: newData
                    }
                
                    props.client.writeQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        },
                        data: {
                            getPersonSet: newPersonSet
                        }
                    })
                    initPersonsToSchedule()
                }

                const updateAttributes = ({ name, departure, destination, lastKnownController }) => {
                    const { getPersonSet } = props.client.readQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        }
                    })
                    props.client.writeQuery({
                        query: GetPersonSetQuery,
                        variables: {
                            setId
                        },
                        data: {
                            getPersonSet: {
                                ...getPersonSet,
                                name,
                                departureID: {
                                    __typename: 'Location',
                                    ...departure,
                                    _id: departure._id,
                                    name: departure.name
                                },
                                lastKnownController: {
                                    __typename: 'Location',
                                    ...departure,
                                    _id: lastKnownController._id,
                                    name: lastKnownController.name
                                },
                                destinationID: {
                                    __typename: 'Location',
                                    ...destination,
                                    _id: destination._id,
                                    name: destination.name
                                }
                            }
                        }
                    })
                }
                
                const handleFormChange = (formFields) => {
                    const name = safeGet(['name', 'value'], formFields)
                    const lkc = {
                        _id: safeGet(['lastKnownController', 'value', 'key'], formFields),
                        name: safeGet(['lastKnownController', 'value', 'label'], formFields)
                    }
                    const departure = {
                        _id: safeGet(['departure', 'value', 'key'], formFields),
                        name: safeGet(['departure', 'value', 'label'], formFields)
                    }
                    const destination = {
                        _id: safeGet(['destination', 'value', 'key'], formFields),
                        name: safeGet(['destination', 'value', 'label'], formFields)
                    }
                    updateAttributes({name, departure, destination, lastKnownController: lkc});
                }

                const handleScheduledUpdate = (updatedItemList, TT = transitType, type) => {
                    if(type === "PAX"){
                        setPTS({
                            ...personsToSchedule,
                            [TT]: updatedItemList
                        })
                    }else {
                        setCTS({
                            ...cargoToSchedule,
                            [TT]: updatedItemList
                        })
                    }
                }

                const handlePersonsToScheduleChange = (persons, TT = transitType) => {
                    setPTS({
                        ...personsToSchedule,
                        [TT]: persons
                    })
                }

                const handlePersonsToScheduleMove = (persons, destTT) => {
                    setPTS({
                        [transitType]: personsToSchedule[transitType].filter(item => !persons.map(i => i._id).includes(item._id)),
                        [destTT]: persons
                    })
                    setTT(destTT);
                }

                const handleCargoToScheduleChange = (cargo) => {
                    setCTS({
                        ...cargoToSchedule,
                        INBOUND: cargo
                    })
                }

                return (
                    <Query
                        query={GetPersonSetQueryCached}
                        variables={{ setId }}
                        skip={!isEditing || props.loading || props.error}
                    >
                    {(cachedData) => {
                        const setData = { ...props, data: isEditing ? cachedData.data : props.data};
                        return (
                            <Mutation mutation={SetPersonSet} onCompleted={onSaved}>
                            {(saveSet, saveProps) => {
                                return (
                                    <Mutation mutation={SchedulePassengersMutation}>
                                    {(scheduleSet, scheduleProps) => {
                                        return (
                                            <Mutation mutation={DeleteEntity} onCompleted={onDeleted}>
                                            {(deleteSet, deleteProps) => {
                                                const getPersonId = (per) => per._id
                                                const handleSave = () => {
                                                    const inbound = setData.data.getPersonSet.inbound.map(getPersonId);
                                                    const outbound = setData.data.getPersonSet.outbound.map(getPersonId);
                                                    saveSet({
                                                        variables: {
                                                            payload: {
                                                                _id: origSetId,
                                                                name: setData.data.getPersonSet.name.trim(),
                                                                tpID: orgData.transporter && orgData.transporter._id,
                                                                customerID: orgData.customer && orgData.customer._id,
                                                                outbound: outbound,
                                                                inbound: inbound,
                                                                owner: !isNew ? setData.data.getPersonSet.owner : cognitoUser.attributes.email,
                                                                departureID: safeGet(['data', 'getPersonSet', 'departureID', '_id'], setData),
                                                                destinationID: safeGet(['data', 'getPersonSet', 'destinationID', '_id'], setData)
                                                            }
                                                        }
                                                    })
                                                }
                                                const handleSchedule = (scheduleData) => {
                                                    const dateString = moment(scheduleData.date).format('YYYY-MM-DD');
                                                    const toPersonScheduleInput = (tt) => (per, i) => {
                                                        return {
                                                            personID: per._id,
                                                            paxWeight: per.paxWeight || 0,
                                                            bagWeight: per.bagWeight || 0,
                                                            bagCount: per.bagCount || 0,
                                                            chargeCode: (per.chargeCode && per.chargeCode.toUpperCase()) || (defaultChargeCode && defaultChargeCode.toUpperCase()),
                                                            scheduledOrder: i,
                                                            transitType: tt
                                                        }
                                                    }
                                                    const outbound = personsToSchedule.OUTBOUND.map(toPersonScheduleInput('OUTBOUND'));
                                                    const inbound = personsToSchedule.INBOUND.map(toPersonScheduleInput('INBOUND'));
                                                    const persons = [...outbound, ...inbound];
                                                    const { customer, transporter } = orgData;
                                                    const cargo = cargoToSchedule["INBOUND"].map(item => {
                                                        return {
                                                            ...item,
                                                            receivedLocationID: safeGet(['data', 'getPersonSet', 'departureID', '_id'], setData)
                                                        }
                                                    })
                                                    checkScheduledPax(persons, () => {
                                                        scheduleSet({
                                                            variables: {
                                                                scheduledFlightDate: dateString,
                                                                scheduledGroup: scheduleData.name,
                                                                lastKnownController: scheduleData.lastKnownController,
                                                                departureID: scheduleData.departureID,
                                                                destinationID: scheduleData.destinationID,
                                                                persons: persons,
                                                                inboundCargo: cargo,
                                                                customerID: customer && customer._id,
                                                                tpID: transporter && transporter._id
                                                            }
                                                        })
                                                        .then(() => {
                                                            if (!onScheduled) return;
                                                            console.log('onScheduled', scheduleData.lastKnownController);
                                                            onScheduled({
                                                                date: dateString,
                                                                group: scheduleData.name,
                                                                lastKnownController: scheduleData.lastKnownController,
                                                                departureID: scheduleData.departureID,
                                                                destinationID: scheduleData.destinationID
                                                            })
                                                        })
                                                    })
                                                }
                                                const handleDelete = () => {
                                                    deleteSet({
                                                        variables: {
                                                            _id: setData.data.getPersonSet._id,
                                                            tpID: orgData.transporter && orgData.transporter._id
                                                        }
                                                    })
                                                }
                                                return (
                                                    <SetsEditor
                                                        setData={setData}
                                                        isEditing={isEditing}
                                                        isNew={isNew}
                                                        isScheduling={isScheduling}
                                                        mainHeaderRoutes={routes}
                                                        onEditChange={onEditChange}
                                                        onPersonsAdd={() => addPersons()}
                                                        onPersonsRemove={() => removePersons()}
                                                        onPersonsMove={() => movePersons()}
                                                        onPersonsReorder={(dragIndex, hoverIndex) => reorderPersons(dragIndex, hoverIndex)}
                                                        onFormChange={handleFormChange}
                                                        onSave={handleSave}
                                                        saving={saveProps.loading || scheduleProps.loading}
                                                        saveError={saveProps.error || scheduleProps.error}
                                                        onScheduleChange={onScheduleChange}
                                                        addPersonsLoading={addPersonsLoading}
                                                        selectedPersonKeys={selectedPersonKeys}
                                                        selectedTableRowKeys={selectedTableRowKeys}
                                                        onSelectedPersonKeysChange={setSPK}
                                                        onSelectedTableRowKeysChange={setSTRK}
                                                        transitType={transitType}
                                                        onTransitTypeChange={setTT}
                                                        onSchedule={handleSchedule}
                                                        personsToSchedule={personsToSchedule}
                                                        cargoToSchedule={cargoToSchedule}
                                                        onPersonsToScheduleChange={handlePersonsToScheduleChange}
                                                        onScheduledUpdate={handleScheduledUpdate}
                                                        onPersonsToScheduleMove={handlePersonsToScheduleMove}
                                                        onCargoToScheduleChange={handleCargoToScheduleChange}
                                                        defaultChargeCode={defaultChargeCode}
                                                        onDefaultChargeCodeChange={setDefaultCC}
                                                        onDelete={handleDelete}
                                                        deleting={deleteProps.loading}
                                                    />
                                                )
                                            }}
                                            </Mutation>
                                        )
                                    }}
                                    </Mutation>
                                )
                            }}
                            </Mutation>
                        )
                    }}
                    </Query>
                )
            }
        }
        </Query>
    )
}

export default compose(
    WithOrgData,
    withCognitoUser
)(SetsEditorContainer)