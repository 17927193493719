import React from 'react';
import { Table, Alert } from 'antd';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import WithOrgData from '../../WithOrgData';
import LoadingContent from '../../LoadingContent';
import { withRouter } from 'react-router-dom';
import commonColumns from '../../../common/table/columns';

class Pob extends React.Component {
    renderContent = () => {
        const { data: { getRigs, loading, error } } = this.props;
        if (error) return <Alert type="error" message="Failed to load pob" description={error.message} showIcon />
        if (loading) return <LoadingContent />
        if (getRigs){
            return (
                <Table
                    dataSource={getRigs}
                    className="mc-table"
                    rowKey={record => record._id}
                    pagination={false}
                    size="middle"
                    onRow={record => ({
                        onClick: () => this.props.history.push(`/app/pob/${record._id}`),
                        style: {
                            cursor: 'pointer'
                        }
                    })}
                    columns={commonColumns.rig.all()}
                />
            )
        }
    }
    render(){
        return (
            <React.Fragment>
                <h2>Live POB</h2>
                <div>
                    {this.renderContent()}
                </div>
            </React.Fragment>
        )
    }
}
const RIGS = gql`
query Pob($customerID: ID!){
    getRigs(customerID: $customerID){
        _id
        customerID {
            _id
            name
        }
        locationID {
            _id
            name
        }
        onboardCount,
        inboundCount,
        outboundCount,
        onboardCargoCount,
        inboundCargoCount,
        outboundCargoCount
    }
}
`

const RigsWithData = graphql(
    RIGS,
    {
        options: props => {
            const {customer } = props.orgData;
            return {
                fetchPolicy: 'network-only',
                pollInterval: 5000,
                variables: {
                    customerID: customer && customer._id
                }
            }
        }
    }
)(Pob)

export default withRouter( WithOrgData(RigsWithData) )